import React, { useState, useEffect } from "react";
import { Button, ColorPicker, ImageUpload, Link, Card } from "components";
import Input from "components/Input";
import { useUpdateAppMutation } from "features";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useToastAlertContext } from "components/toastAlert";
import {
  useGetBrandingQuery,
  useUpdateBrandingMutation,
} from "features/branding";
import { isError } from "helpers/typeguards";
import useAppNavigator from "hooks/useAppNavigator";
import SettingsBar from "components/SettingsBar";
import { ImageListType, ImageType } from "react-images-uploading";
import styled from "styled-components";
import BackGradient from "assets/images/gradient.png";
import Skeleton from "react-loading-skeleton";
import { ImageUploadSkeleton } from "components/ImageUpload/ImageUpload";
import InputLoading from "components/Input/Input.loading";
import CreateAccount from "./CreateAccount";
import { PreviewModal } from "./PreviewModal";

const CarouselContainer = styled.div`
  display: flex;
  background: url(${BackGradient});
  background-size: cover;
  height: 100%;
  width: 100%;
  & > div:first-of-type {
    margin: auto;
  }
  @media (max-width: 1280px) {
    display: none;
  }
`;

const initialImageState = {
  dataURL: "",
  file: new File([""], "logo"),
};

const Design = () => {
  const [brandColor, setBrandColor] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [image, setImage] = useState<ImageType>(initialImageState);
  const [wasImageChanged, setWasImageChanged] = useState<boolean>(false);

  const { createToastAlert } = useToastAlertContext();
  const { setHasUnsavedChanges } = useAppNavigator();

  const { data, loading, refetch } = useGetBrandingQuery();

  const { appData } = useAppDataContext();

  useEffect(() => {
    setBrandColor(data?.getBranding.colors.light.primaryButton);
    setImage(appData?.image as unknown as ImageType);
  }, [appData?.image, data?.getBranding]);

  useEffect(() => {
    if (brandColor !== data?.getBranding.colors.light.primaryButton) {
      return setHasUnsavedChanges(true);
    }
    return setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandColor, data?.getBranding.colors.light.primaryButton]);

  const [updateBranding, { loading: loadingBrandUpdate }] =
    useUpdateBrandingMutation({
      variables: {
        input: { colors: { light: { primaryButton: brandColor } } },
      },
    });

  const [updateImage, { loading: loadingImageUpdate }] = useUpdateAppMutation({
    variables: {
      input: {
        image: image?.file,
      },
    },
  });

  const handleOnImageChange = (imageList: ImageListType) => {
    setImage(imageList[0]);
    setWasImageChanged(true);
  };

  const handleInputChange = ({ target: { value } }) => {
    if (!value.split("").includes("#")) {
      return setBrandColor(`#${value}`);
    }
    return setBrandColor(value);
  };

  const handleSave = async () => {
    createToastAlert({ processing: true });

    try {
      if (wasImageChanged) {
        await updateImage();
      }
      await updateBranding();
      await refetch();
      createToastAlert({
        alertType: "success",
        message: "Branding color was successfully updated.",
      });
    } catch (e) {
      if (isError(e)) {
        createToastAlert({
          alertType: "error",
          message: e.message,
        });
      }
    }
  };

  const wasInputsChanges =
    data?.getBranding.colors.light.primaryButton !== brandColor ||
    wasImageChanged;

  return (
    <>
      <Card className="flex flex-row h-full">
        <Button
          type="submit"
          text="Save"
          tw="absolute right-5 top-5"
          onClick={handleSave}
          isLoading={loadingBrandUpdate || loadingImageUpdate}
          isDisabled={!wasInputsChanges}
        />
        <SettingsBar tw="w-[320px] min-w-[320px]">
          <div className="flex flex-col p-7">
            <h1 className="mb-2 font-bold text-h3">Design</h1>
            <p className="text-body-sm text-app-gray600 whitespace-pre-line mb-7">
              Style the &nbsp;
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/12971481752091"
                underline
                isExternal
                target="_blank"
              >
                default modals
              </Link>
              &nbsp; and &nbsp;
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/13254139687067-Emails"
                underline
                isExternal
                target="_blank"
              >
                email templates.
              </Link>
            </p>
            <div className="flex content-center mb-7">
              {loading ? (
                <ImageUploadSkeleton />
              ) : (
                <ImageUpload
                  text={appData?.image ? "Replace Image" : "Upload Image"}
                  onChange={handleOnImageChange}
                  image={image}
                  label="App Image"
                  id="app-image"
                />
              )}
            </div>
            {loading ? (
              <InputLoading />
            ) : (
              <Input
                id="primary-color"
                label="Primary Color"
                leadingInline
                leading={
                  <ColorPicker
                    value={brandColor}
                    onChange={(hex) => setBrandColor(hex)}
                  />
                }
                value={brandColor}
                onChange={handleInputChange}
                required
                description={
                  <>
                    Used in&nbsp;
                    <Link
                      to="https://docs.memberstack.com/hc/en-us/articles/12971481752091"
                      target="_blank"
                      isExternal
                      underline
                      tw="text-app-gray600"
                    >
                      default modals
                    </Link>
                    &nbsp; & &nbsp;
                    <Link
                      to="https://docs.memberstack.com/hc/en-us/articles/13254139687067-Emails"
                      target="_blank"
                      isExternal
                      underline
                      tw="text-app-gray600"
                    >
                      emails
                    </Link>
                  </>
                }
              />
            )}
          </div>
        </SettingsBar>
        <CarouselContainer>
          {loading ? (
            <div>
              <Skeleton width={384} height={475} />
            </div>
          ) : (
            <CreateAccount
              color={brandColor}
              imageSrc={image?.dataURL || (image as unknown as string)}
            />
          )}
        </CarouselContainer>
        <div
          className="xl:hidden flex relative justify-center items-center h-full w-full bg-cover"
          style={{
            background: `url(${BackGradient})`,
          }}
        >
          <Button
            text="View preview"
            buttonStyle="skeleton"
            onClick={() => setShowPreview(true)}
          />
        </div>
      </Card>
      {!loading && (
        <PreviewModal
          color={brandColor}
          imageSrc={image?.dataURL || image}
          setShowModal={setShowPreview}
          showModal={showPreview}
        />
      )}
    </>
  );
};

export default Design;
