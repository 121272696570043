export const getInitials = (firstName: string, lastName: string) =>
  `${firstName?.charAt(0)}${lastName?.charAt(0)}`;

export const DEFAULT_AVATAR_SIZE = {
  xSmall: 24,
  small: 28,
  medium: 32,
  large: 40,
  xLarge: 48,
};
