/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { Modal, ModalTypes } from "components/Modal";
import { Link } from "components";
import useMembersImport from "hooks/useMembersImport";
import { useMembersContext } from "../context/MembersContextProvider";

const ImportMembersModal = ({ showModal, setShowModal }: ModalTypes) => {
  const { refetch: refetchMembers } = useMembersContext();
  const {
    openImportTool,
    importInProgress,
    totalPendingJobs,
    estimatedTime,
    importJobData,
    closeModal,
  } = useMembersImport();

  useEffect(() => {
    if (importJobData.statusPercentage === 100) {
      refetchMembers();
    }
  }, [importJobData.statusPercentage]);

  const handleConfirmButton = () => {
    if (importInProgress) {
      setShowModal(false);
      return;
    }
    openImportTool();
  };

  return (
    <Modal
      name="import-members"
      setShowModal={setShowModal}
      showModal={showModal}
      title="Import Members"
      showDivider
      width="500px"
      hideCancel={importInProgress}
      onModalClose={() => closeModal()}
      actionButtons={{
        confirm: {
          label: importInProgress ? "Close" : "Continue",
          onConfirm: () => handleConfirmButton(),
        },
      }}
    >
      <p tw="text-base text-left mt-0 text-app-gray600">
        1. Please read the instructions found{" "}
        <Link
          target="_blank"
          rel="noreferrer"
          to="https://docs.memberstack.com/hc/en-us/articles/7789851571099-Import-Free-Paying-Members"
          isExternal
          underline
        >
          here
        </Link>
        .
      </p>
      <p tw="text-base text-left mt-3 text-app-gray600">
        2. Please be aware that it takes roughly 1 min per 400 members. You do{" "}
        <strong>not</strong> need to keep the tab open.
      </p>
      <p tw="text-base text-left mt-3 text-app-gray600">
        3. Sit back, relax, and grab some 🍿
      </p>
      <p tw="text-base text-left mt-2 text-app-gray600">
        4. You'll receive an email when the import is done 🎉
      </p>
      {importInProgress && (
        <>
          <div tw="border-b border-app-gray300 w-full mt-4" />
          {totalPendingJobs === 0 ? (
            <>
              {importJobData.statusPercentage === 100 ? (
                <p tw="text-base font-bold text-center mt-4 text-app-gray600">
                  Your import is finished 🎉
                </p>
              ) : (
                <p tw="text-base font-bold text-center mt-4 text-app-gray600">
                  Your import is processing now.
                </p>
              )}

              {importJobData.statusPercentage !== 100 && (
                <p tw="text-base text-center mt-1 text-app-gray600">
                  The estimated completion time is <b>{estimatedTime}</b> minute
                  {estimatedTime > 1 ? "s" : ""}.
                </p>
              )}

              <div tw="w-full bg-gray-200 rounded-full h-2.5 mt-4 light:bg-gray-700">
                <div
                  tw="bg-blue-600 h-2.5 rounded-full light:bg-blue-500"
                  style={{ width: `${importJobData.statusPercentage}%` }}
                />
              </div>
              <p tw="text-sm text-center text-app-gray600">
                {importJobData.statusPercentage}% complete
              </p>
            </>
          ) : (
            <>
              <p tw="text-base text-center mt-4 text-app-gray600">
                There are <b>{totalPendingJobs}</b> imports in front of yours.
              </p>
              <p tw="text-sm text-center text-app-gray600">
                Imports are proccessed in the order they are received.
              </p>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ImportMembersModal;
