import React, { useCallback } from "react";
import { Card } from "components";
import { Modal, ModalTypes } from "components/Modal";
import { RadioButton } from "components/RadioButton";
import { CheckCircle, Verified } from "@mui/icons-material";
import { useCreateCheckoutSessionMutation } from "features";
import { useToastAlertContext } from "components/toastAlert";

const plans = {
  commenting: [
    { title: "Members", value: "200 Members" },
    { title: "Page Views", value: "10,000 Monthly Page Views" },
    { title: "Components", value: "Pre-built Modals" },
    { title: "Main feature", value: "Public Commenting" },
    { title: "Sub feature", value: "Social Authentication" },
    { title: "branding", value: "Memberstack Branding" },
  ],
  basic: [
    { title: "Members", value: "1,000+ Members" },
    { title: "Page Views", value: "Unlimited Page Views" },
    { title: "Components", value: "Custom Forms" },
    { title: "Main feature", value: "Email Verification" },
    { title: "Sub feature", value: "Plans & Prices" },
    { title: "branding", value: "Custom Branding" },
  ],
};

const PRICE_IDS = {
  dev: {
    commenting: "price_1OlwA5KQYPov2Q6Dj0n1xH4t",
    basic: "price_1Jro9EKQYPov2Q6DOYTMGxZu",
  },
  prod: {
    commenting: "price_1Om00YKQYPov2Q6D8jcLuCA1",
    basic: "price_1LNeGeKQYPov2Q6DMoicDgRX",
  },
};

const domain = window.location.href
  .split("/apps")[0]
  .includes("app.memberstack.com")
  ? "prod"
  : "dev";

const CommentingBilling = ({ setShowModal, showModal }: ModalTypes) => {
  const [selected, setSelected] = React.useState<string>("commenting");
  const { createToastAlert } = useToastAlertContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value);
  };

  const [createCheckoutSession, { loading }] =
    useCreateCheckoutSessionMutation();

  const handleOnSubscribe = useCallback(
    (priceType) => async () => {
      try {
        await createCheckoutSession({
          variables: {
            input: {
              priceId: PRICE_IDS[domain][priceType],
            },
          },
        }).then(({ data }) => {
          window.open(data.createCheckoutSession.url, "_self");
        });
      } catch (e) {
        createToastAlert({
          alertType: "error",
          message:
            e.graphQLErrors?.[0]?.message ||
            "An unexpected error occurred. Please try again.",
        });
      }
    },
    [createCheckoutSession, createToastAlert]
  );

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      width="560px"
      title="Get started with Commenting"
      description="Commenting is included for FREE in plans over $29/mo, or start with the Commenting Plan for projects that only need commenting features."
      actionButtons={{
        confirm: {
          isLoading: loading,
          isDisabled: !selected,
          label: "Continue to Payment",
          onConfirm: handleOnSubscribe(selected),
        },
        cancel: { label: "Back" },
      }}
    >
      <div className="flex gap-4">
        <RadioButton
          name="commenting"
          value="commenting"
          selectedValue={selected}
          component={
            <Card className="p-4 bg-white" selected={selected === "commenting"}>
              <div className="flex justify-between">
                <h3 className="font-bold text-utility-md mb-2">
                  Commenting Plan
                </h3>
                {selected === "commenting" ? (
                  <CheckCircle
                    style={{ fontSize: 16, fill: "rgba(41, 98, 255, 1)" }}
                  />
                ) : (
                  <div className="border border-app-gray400 w-4 h-4 rounded-full" />
                )}
              </div>
              <p className="text-utility-md mb-4">$2/month</p>
              <p className="text-utility-md mb-4 text-app-gray600">
                Billed annually
              </p>
              <div className="flex gap-2 flex-col text-body-sm">
                {plans.commenting.map((plan) => (
                  <div className="flex gap-2 items-center" key={plan.title}>
                    <Verified
                      style={{ fontSize: 16, fill: "rgba(41, 98, 255, 1)" }}
                    />
                    <p>{plan.value}</p>
                  </div>
                ))}
              </div>
            </Card>
          }
          onChange={handleOnChange}
        />
        <RadioButton
          name="basic"
          value="basic"
          selectedValue={selected}
          component={
            <Card className="p-4 bg-white" selected={selected === "basic"}>
              <div className="flex justify-between">
                <h3 className="font-bold text-utility-md mb-2">
                  Standard Pricing
                </h3>
                {selected === "basic" ? (
                  <CheckCircle
                    style={{ fontSize: 16, fill: "rgba(41, 98, 255, 1)" }}
                  />
                ) : (
                  <div className="border border-app-gray400 w-4 h-4 rounded-full" />
                )}
              </div>
              <p className="text-utility-md mb-4">$29/month</p>
              <p className="text-utility-md mb-4 text-app-gray600">
                Billed monthly
              </p>
              <div className="flex gap-2 flex-col text-body-sm">
                {plans.basic.map((plan) => (
                  <div className="flex gap-2 items-center" key={plan.title}>
                    <Verified
                      style={{ fontSize: 16, fill: "rgba(41, 98, 255, 1)" }}
                    />
                    <p>{plan.value}</p>
                  </div>
                ))}
              </div>
            </Card>
          }
          onChange={handleOnChange}
        />
      </div>
    </Modal>
  );
};

export default CommentingBilling;
