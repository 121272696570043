import React from "react";
import Input from "components/Input";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { Textarea } from "components";

export const ResetPasswordRqForm = ({
  handleSubmit,
  disableInputs,
  formProgress,
  formInputs,
  handleInputChange,
}) => (
  <form onSubmit={(e) => handleSubmit(e)} className="py-7 px-5">
    <ProgressBar progress={formProgress} tw="mb-5" />
    <Input
      disabled={disableInputs}
      name="reset_your_password"
      label="Reset your password"
      placeholder="Reset your password"
      tw="mb-5"
      hideIndicators
      value={formInputs.reset_your_password}
      onChange={handleInputChange}
    />
    <Textarea
      name="reset_instructions"
      label="We’ll email you a secure link to reset the password for your account."
      value={formInputs.reset_instructions}
      disabled={disableInputs}
      rows={2}
      required
      onChange={handleInputChange}
      placeholder="We’ll email you a secure link to reset the password for your account."
    />
    <Input
      disabled={disableInputs}
      name="email_address"
      label="Email Address"
      placeholder="Email Address"
      tw="mb-5"
      hideIndicators
      value={formInputs.email_address}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="reset_email_placeholder"
      label="Enter your email address"
      placeholder="Enter your email address"
      tw="mb-5"
      hideIndicators
      value={formInputs.reset_email_placeholder}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="reset"
      label="Send link"
      placeholder="Send link"
      tw="mb-5"
      hideIndicators
      value={formInputs.reset}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="reset_already_have_code"
      label="I already have a code"
      placeholder="I already have a code"
      tw="mb-5"
      hideIndicators
      value={formInputs.reset_already_have_code}
      onChange={handleInputChange}
    />
  </form>
);
