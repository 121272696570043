import { createContext, useContext } from "react";

const defaultValues = {
  activeItem: undefined,
  setToggle: undefined,
  activeFilters: undefined,
  setActiveFilters: undefined,
};

export const FilterContext = createContext(defaultValues);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    // Error message should be more descriptive
    throw new Error("No context found for Filter");
  }
  return context;
};
