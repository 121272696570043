import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const cache = new InMemoryCache({
  // addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        getMembers: relayStylePagination(["first"]),
        getMemberEvents: relayStylePagination(["first"]),
        getPosts: relayStylePagination(["first"]),
      },
    },
  },
});
