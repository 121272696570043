import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RouteName } from "enums/routes";
import { UserSessionAcr } from "generatedTypes";
import ErrorPage from "containers/ErrorPage";
import Loader from "components/Loader";
import { UserContextProvider, useUserContext } from "./UserContextProvider";

const Component = () => {
  const { currentUser, error, isLoading, currentAppId } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { search, pathname } = location;

  useEffect(() => {
    if (error?.graphQLErrors[0]?.extensions?.code === "auth/user-not-found") {
      localStorage.setItem("redirectTo", `${pathname}${search}`);
      navigate(`/${RouteName.login}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const is2FaEnabled = currentUser?.auth?.twoFactorSettings?.enabled;
    const isSessionPassword =
      currentUser?.session?.acr === UserSessionAcr.Password;
    const isTwoFactorAuthPage = location.pathname.includes(
      RouteName.twoFactorAuthenticator
    );
    if (is2FaEnabled && isSessionPassword && !isTwoFactorAuthPage) {
      navigate(`/${RouteName.twoFactorAuthenticator}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);

  useEffect(() => {
    // Force user to create an app before proceedeing to dashboard if user doesn't have one
    if (
      currentUser?.id &&
      !currentAppId &&
      (pathname !== "/create-app" || pathname !== "/create-app")
    ) {
      navigate("/create-app", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser, currentAppId]);

  useEffect(() => {
    if (currentAppId) {
      const [, rootPath] = location.pathname.split("/");
      if (location.pathname === "/apps" || location.pathname === "/apps/") {
        navigate(`/apps/${currentAppId}/dashboard${location.search}`);
      } else if (
        [
          "getting-started",
          "dashboard",
          "members",
          "plans",
          "gated-content",
          "community",
          "event-log",
          "devtools",
          "settings",
        ].includes(rootPath)
      ) {
        navigate(`/apps/${currentAppId}${location.pathname}${location.search}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentAppId, navigate]);

  // Entire app relies on user data. Show loader while data is loading.
  if (isLoading) {
    return <Loader isFullScreen />;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return <ErrorPage isFullScreen />;
  }

  return <Outlet />;
};

const PrivateLayout = () => {
  return (
    <UserContextProvider>
      <Component />
    </UserContextProvider>
  );
};

export default PrivateLayout;
