import styled, { css } from "styled-components";

export const ChartContainer = styled.div(
  () =>
    ({ theme: { colors } }) =>
      css`
        & > .top_section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          @media (max-width: 1204px) {
            margin-bottom: 4px;
          }
        }
        & > .dates {
          align-items: center;
          height: 33px;
          @media (max-width: 1204px) {
            height: 28px;
          }
        }
        & > .top_section > h4 {
          font-weight: 600;
          font-size: 14px;
          line-height: 25px;
          color: ${colors.grey500};
        }
        & > .top_section > h1 {
          font-weight: 700;
          font-size: 48px;
          line-height: 56px;
        }
      `
);
