import styled, { css } from "styled-components";

export const Container = styled.div<{ $hasDescription: boolean }>(
  ({ $hasDescription }) => css`
    display: flex;
    cursor: pointer;
    align-items: ${$hasDescription ? "flex-start" : "center"};
  `
);

export const DescriptionContainer = styled.div`
  margin-top: 4px;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
`;

export const RoundedCheckbox = styled.div<{
  enhanced?: boolean;
  isActive?: boolean;
}>(
  ({ enhanced, isActive, theme: { colors } }) => css`
    position: relative;
    height: 20px;
    width: 20px;

    & label {
      background-color: ${enhanced ? colors?.grey050 : "white"};
      border-width: ${enhanced ? "2px" : "1px"};
      border-style: solid;
      border-color: ${colors?.grey300};
      border-radius: 50%;
      cursor: pointer;
      left: 0;
      position: absolute;
      top: 0;
      height: 20px;
      width: 20px;
      user-select: none;
      ${isActive &&
      css`
        background-color: ${colors?.blue050};
        border-color: ${colors?.blue300};
      `}
    }

    & label:after {
      border: ${enhanced ? "1px solid #5C5C5C" : " 2px solid #fff"};
      border-top: none;
      border-right: none;
      content: "";
      height: ${enhanced ? "4px" : "6px"};
      left: 4px;
      opacity: 0;
      position: absolute;
      top: 5px;
      transform: rotate(-45deg);
      width: ${enhanced ? "9px" : "10px"};
    }

    & > input[type="checkbox"] {
      visibility: hidden;
    }

    & > input[type="checkbox"]:checked + label {
      background-color: ${enhanced ? "#DDDDDD" : "#66bb6a"};
      border-color: ${enhanced ? "#C9C9C9" : "#66bb6a"};
    }

    & > input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  `
);

export const Visibility = styled.label<{ $checked: boolean }>(
  ({ theme: { colors }, $checked }) => css`
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    color: ${$checked && colors.grey500};
    & > input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    & > svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  `
);

export const RegularCheckbox = styled.input<{ $hasDescription: boolean }>(
  ({ theme: { colors }, $hasDescription }) => css`
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: ${colors?.grey500};
    border-radius: 4px;
    margin-top: ${$hasDescription ? "6px" : "0"};
    :focus {
      color: ${colors?.grey500};
    }
  `
);
