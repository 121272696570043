import React, { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "components/Loader";
import { useGenerateZendeskTokenMutation } from "features";
import { UsersContextProvider } from "containers/App/UsersContextProvider";
import { shareableLinks } from "./SharableRoutes";
import PublicPages, { publicPages } from "./PublicPages";
import ProtectedPages, { protectedPages } from "./ProtectedPages";

const publicPaths = publicPages.map(({ path }) => path);
const protectedPaths = protectedPages
  .map(({ path }) => path)
  .concat(shareableLinks.map(({ path }) => path));

export const MainRoutes = () => {
  const [generateZendeskToken] = useGenerateZendeskTokenMutation();
  const hadBrandId = Boolean(window.location.search.includes("brand_id"));
  const isLogInPath = window.location.pathname === "/login";

  useEffect(() => {
    if (hadBrandId && isLogInPath) {
      const generateToken = async () => {
        const {
          data: { generateZendeskToken: token },
        } = await generateZendeskToken();
        let zendeskUrl = `https://memberstack-v2.zendesk.com/access/jwt?jwt=${token}`;
        const params = new URLSearchParams(window.location.search);
        const returnTo = params.get("return_to");
        if (returnTo) zendeskUrl += `&return_to=${returnTo}`;
        window.location.replace(zendeskUrl);
      };
      // eslint-disable-next-line no-console
      generateToken().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hadBrandId, isLogInPath]);

  return (
    <Suspense fallback={<Loader isFullScreen />}>
      <Switch>
        <Route path={publicPaths} component={PublicPages} />
        <Route
          path={protectedPaths}
          render={() => (
            <UsersContextProvider>
              <ProtectedPages />
            </UsersContextProvider>
          )}
        />
      </Switch>
    </Suspense>
  );
};
