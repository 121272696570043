/**
 * This function trims all values (values must be string)
 * Useful for avoiding irrelevant whitespace
 * @param obj object whose keys are to be trimmed
 * @returns object with trimmed values
 */
export const trimObjectStringValues = (obj: { [key: string]: string | number }) => {
  return Object.entries(obj || {}).reduce((acc, [key, value]) => {
    if (!value) {
      acc[key] = value;
      return acc;
    }
    // if it is a string, trim it but if it number, leave it as it is
    if (typeof value === "string") {
      acc[key] = value.trim();
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};
