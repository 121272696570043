import React, { useCallback, useState } from "react";
import { Search, ExpandMore } from "@mui/icons-material";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Dropdown } from "../../Dropdown";

const SearchByContainer = styled.div`
  & > div {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

const TargetContainer = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  background: #f6f6f6;
  border: 1px solid #dddddd;
  border-radius: 8px 0px 0px 8px;
  padding: 6px 12px;
  gap: 4px;
  height: 100%;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
    color: #000;
  }
`;

interface Props {
  options: {
    text: string;
    onClick: (event?: React.MouseEventHandler<HTMLDivElement>) => void;
    dataCy?: string;
  }[];
}

export const SearchBy = ({ options }: Props) => {
  const [isToggled, setToggle] = useState(false);
  const [selected, setSelected] = useState(options[0].text);
  const { y } = useSpring({
    y: isToggled ? 180 : 0,
  });
  const targetElement = useCallback(
    () => (
      <TargetContainer onClick={() => setToggle(!isToggled)}>
        <Search />
        {selected}
        <animated.div
          style={{
            transform: y.interpolate((_y) => `rotateX(${_y}deg)`),
          }}
        >
          <ExpandMore />
        </animated.div>
      </TargetContainer>
    ),
    [isToggled, selected, y]
  );
  return (
    <SearchByContainer data-cy="search-filter">
      <Dropdown
        options={options}
        targetElement={targetElement}
        optionsTextAlign="left"
        onSelect={(text) => setSelected(text)}
        className="h-full"
      />
    </SearchByContainer>
  );
};
