export enum localStorageKeys {
  appId = "last_app",
  planId = "last_plan",
  memberId = "last_member",
  showInstallCode = "show_install_code",
  initialUrl = "initial_url",
}

export enum sessionStorageKeys {
  plansAccordion = "plans_accordion",
  memberPaneAccordion = "member_pane_accordion",
  createPlanAccordion = "create_plan_accordion",
}

export const setIds = ({
  appId,
  planId,
  memberId,
}: {
  appId?: string;
  planId?: string;
  memberId?: string;
}) => {
  if (appId) {
    window.localStorage.setItem(localStorageKeys.appId, appId);
  }
  if (planId) {
    window.localStorage.setItem(localStorageKeys.planId, planId);
  }
  if (memberId) {
    window.localStorage.setItem(localStorageKeys.memberId, memberId);
  }
};

// eslint-disable-next-line consistent-return
export const getId = (key) => {
  switch (key) {
    case "appId":
      return window.localStorage.getItem(localStorageKeys.appId);
    case "planId":
      return window.localStorage.getItem(localStorageKeys.planId);
    case "memberId":
      return window.localStorage.getItem(localStorageKeys.memberId);
    default:
  }
};
