import React, { Suspense } from "react";
import Skeleton from "react-loading-skeleton";

interface Props {
  children: React.ReactNode;
  height?: number | string;
  width?: number | string;
}

const SuspenseWrapper = ({
  children,
  height = "100%",
  width = "100%",
}: Props) => (
  <Suspense fallback={<Skeleton height={height} width={width} />}>
    {children}
  </Suspense>
);

export default SuspenseWrapper;
