/**
 * This function gets extracts all key/value pairs from an object that matches the keys
 * passed in
 * @param obj object whose key/value pairs are to be extracted
 * @param keys reference keys to be used for extraction
 * @returns object with keys specified
 */
export const getObjectWithKeys = (obj, keys: string[]) => {
  return keys.reduce((acc, k) => {
    acc[k] = obj[k];
    return acc;
  }, {});
};
