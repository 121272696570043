import styled, { css } from "styled-components";

export const PercentageChangeText = styled.div<{}>`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 16px;
    color: ${colors?.grey400};
    & > div > svg {
      width: 16px;
      height: 16px;

      margin-right: 4px;
    }
  `}
`;

export const MetricContainer = styled.div`
  ${({ theme: { colors } }) => css`
    border: 1px solid ${colors?.grey100};
    border-radius: 8px;
    padding: 16px;
    background: white;
    & > .title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 14px;
      color: ${colors?.grey500};
      display: flex;
      align-items: center;

      & > svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
    & > .value {
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      margin-bottom: 14px;
    }
  `}
`;
