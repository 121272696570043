import React, { useState } from "react";
import { Modal, ModalTypes } from "components/Modal";
import { Form, TypeAheadDropdown } from "components";
import { useEnvContext } from "containers/App/contexts/Environment";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { isError } from "helpers/typeguards";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";
import { useGetLoginWithMemberTokenLazyQuery } from "features";

interface Props extends ModalTypes {
  memberId: string;
}

const LoginAsMemberModal = ({ showModal, setShowModal, memberId }: Props) => {
  const [domainUrl, setDomainUrl] = useState<string>("");

  const { env } = useEnvContext();
  const { appData } = useAppDataContext();
  const { createToastAlert } = useToastAlertContext();
  const [modalError, setModalError] = useState(null);

  const domainUrls =
    appData?.domains
      // @ts-ignore
      ?.filter((domain) => domain.mode === env)
      .map((domain) => domain.url) || [];

  const [getMemberLoginToken, { loading }] =
    useGetLoginWithMemberTokenLazyQuery({
      variables: {
        input: {
          memberId,
          domainUrl,
        },
      },
      onCompleted: ({ getLoginWithMemberToken }) => {
        if (getLoginWithMemberToken) {
          window.open(getLoginWithMemberToken, "_blank");
        }
        setShowModal(false);
        createToastAlert({
          alertType: "success",
          message: "You will be redirected to your site shortly.",
        });
      },
      onError: (error) => {
        setModalError(isError(error) ? error.message : "Something went wrong.");
      },
    });

  const handleSubmit = async () => {
    await getMemberLoginToken();
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Login as Member"
      showDivider
      hideCancel
      actionButtons={{
        confirm: {
          label: "Login as Member",
          onConfirm: handleSubmit,
          isLoading: loading,
          isDisabled: domainUrl === "" || loading,
        },
      }}
      nonScrollable
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form onSubmit={undefined}>
        <TypeAheadDropdown
          items={domainUrls}
          placeholder="Enter a domain"
          label="Select Domain"
          onChange={(value) => setDomainUrl(value)}
        />
      </Form>
    </Modal>
  );
};

export default LoginAsMemberModal;
