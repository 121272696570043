import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { InstallCodeRoutes } from "routes/SettingsRoutes";
import { useUpdateAppMutation } from "features";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { Button, Card } from "components";
import { Stack } from "generatedTypes";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { TabItem, TabNav, Tabs } from "components/Tabs";

const InstallCode = () => {
  const { url } = useRouteMatch();
  const { appData } = useAppDataContext();
  const { stack } = appData || {};

  const stackFromPath = window.location.pathname.split("/").pop().toUpperCase();

  const [activeTab, setActiveTab] = useState<Stack>(stack || Stack.React);

  const [updateAppInfo, { loading }] = useUpdateAppMutation({
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(appData),
        fields: {
          stack() {
            return data?.updateApp?.stack;
          },
        },
      });
    },
  });

  const { submit: changeDefaultStack } = useOnSubmit({
    action: updateAppInfo,
    fields: {
      stack: activeTab,
    },
    successMsg: "Default stack was successfully updated.",
    errorMsg: "Failed to update default stack.",
  });

  const tabs = [
    {
      label: "Webflow",
      url: `${url}/webflow`,
      value: Stack.Webflow,
    },
    {
      label: "React",
      url: `${url}/react`,
      value: Stack.React,
    },
    {
      label: "Vanilla",
      url: `${url}/vanilla`,
      value: Stack.Vanilla,
    },
  ];

  return (
    <Card className="p-4 flex flex-col">
      <div className="flex items-center justify-between border-b">
        <Tabs
          isExpanded={false}
          showBottomBorder={false}
          onTabChange={(value) => setActiveTab(value as Stack)}
        >
          <TabNav>
            {tabs.map((tab) => (
              <TabItem
                key={tab.label}
                id={tab.value}
                label={tab.label}
                url={tab.url}
              />
            ))}
          </TabNav>
        </Tabs>
        <Button
          text="Set as Default"
          isLoading={loading}
          isDisabled={!stack ? true : stackFromPath === stack}
          onClick={() => changeDefaultStack()}
        />
      </div>
      <div className="mt-4">
        <InstallCodeRoutes selectedStack={stack?.toString().toLowerCase()} />
      </div>
    </Card>
  );
};

export default InstallCode;
