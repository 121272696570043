import React from "react";
import { colors as c } from "styles";
import { css, styled } from "twin.macro";
import { fontStyleMap } from "../../constants/fontStyleMap";

export interface TextProps {
  bold: boolean;
  children: React.ReactNode;
  className?: string;
  color?: keyof typeof c;
  ellipsisOverflow?: boolean;
  italic?: boolean;
  noMargin?: boolean;
  textAlign?: "left" | "center" | "right";
  type: keyof typeof fontStyleMap;
  underline?: boolean;
}

export const StyledText = styled.p<TextProps>(() => [
  ({ type }) => fontStyleMap[type],
  ({ bold, italic, textAlign, color, underline, ellipsisOverflow }) => css`
    font-weight: ${bold ? "bold" : "normal"};
    font-style: ${italic ? "italic" : "normal"};
    text-align: ${textAlign};
    color: ${c[color] || "inherit"};
    white-space: pre-line;
    text-decoration: ${underline ? "underline" : "none"};
    word-break: break-word;
    ${ellipsisOverflow &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  `,
]);
