import { Card, Link } from "components";
import Input from "components/Input";
import { Switch } from "components/Switch";
import React, { useState } from "react";
import HCaptchaImage from "assets/images/hCaptcha.jpg";
import { EnableHCaptchaModal } from "./HCaptchaModal";
import { ConcurrentLoginModal } from "./ConcurrentLoginModal";

const Security = ({
  formValues,
  handleOnChange,
  captchaEnabled,
  setCaptchaEnabled,
  disableConcurrentLogins,
  setDisableConcurrentLogins,
  preventDisposableEmails,
  setPreventDisposableEmails,
}) => {
  const [showHCaptchaPreview, setShowHCaptchaPreview] = useState(false);
  const [showEnableHCaptchaModal, setShowEnableHCaptchaModal] = useState(false);
  const [showConcurrentLoginModal, setShowConcurrentLoginModal] =
    useState(false);

  return (
    <section id="other">
      <Card className="p-4 flex flex-col">
        <h3 className="text-base font-bold mb-4">Security</h3>
        <div className="flex flex-col gap-7">
          <Switch
            className="max-w-xl"
            label="Require hCaptcha during signup"
            isChecked={captchaEnabled}
            id="captchaEnabled"
            dataCy="captcha-enabled-switch"
            onChange={() => {
              if (!captchaEnabled) {
                setShowEnableHCaptchaModal(true);
                return;
              }
              setCaptchaEnabled(false);
            }}
            description={
              <p className="text-body-sm text-app-gray600 whitespace-pre-line">
                hCaptcha is an anti-bot solution that protects user privacy and
                is the most popular reCAPTCHA alternative.{" "}
                <Link
                  to="https://docs.memberstack.com/hc/en-us/articles/11398218093595-Prevent-Spam-with-hCaptcha"
                  target="_blank"
                  isExternal
                  showAsLink
                >
                  Learn more.
                </Link>{" "}
                or{" "}
                <span
                  tw="text-app-blue400"
                  tabIndex={0}
                  role="button"
                  aria-hidden="true"
                  onClick={() => setShowHCaptchaPreview(!showHCaptchaPreview)}
                >
                  Preview hCaptcha
                </span>
              </p>
            }
          />
          {showHCaptchaPreview && (
            <img
              src={HCaptchaImage}
              alt="hCaptcha Preview"
              className="lg:w-custom-560 w-full"
              loading="lazy"
            />
          )}
          <Switch
            className="max-w-xl"
            label="Disable concurrent logins"
            isChecked={disableConcurrentLogins}
            onChange={() => {
              if (!disableConcurrentLogins) {
                setShowConcurrentLoginModal(true);
                return;
              }
              setDisableConcurrentLogins(false);
            }}
            id="disableConcurrentLogins"
            dataCy="disable-concurrent-logins-switch"
            description={
              <p className="text-body-sm text-app-gray600 whitespace-pre-line">
                Disabling concurrent logins will prevent members from sharing
                their login credentials. A single account can only be logged in
                from one device at a time.
              </p>
            }
          />
          <Switch
            label="Prevent disposable email address signups (e.g. user@burnemail.io)"
            isChecked={preventDisposableEmails}
            onChange={() =>
              setPreventDisposableEmails(!preventDisposableEmails)
            }
            id="preventDisposableEmails"
            dataCy="prevent-disposable-emails-switch"
          />
          <Input
            className="lg:w-custom-560 w-full"
            label="Session Duration"
            onChange={handleOnChange("memberSessionDurationDays")}
            value={formValues?.memberSessionDurationDays}
            type="number"
            min="1"
            trailingInline
            trailing="days"
            description={
              <>
                Automatically logout members after{" "}
                {formValues?.memberSessionDurationDays} days of inactivity{" "}
                <Link
                  to="https://docs.memberstack.com/enabling-automated-logouts"
                  target="_blank"
                  isExternal
                  showAsLink
                >
                  Help Docs.
                </Link>
              </>
            }
            dataCy="session-duration"
          />
          <Input
            className="lg:w-custom-560 w-full"
            label="Signup URL"
            placeholder="https://www.yoursite.com/signup"
            value={formValues?.ssoSignupButtonURL}
            onChange={handleOnChange("ssoSignupButtonURL")}
            description="Add your signup URL here to enable signup via the SSO hosted login UI."
            dataCy="signup-url"
          />
        </div>
      </Card>
      <EnableHCaptchaModal
        showModal={showEnableHCaptchaModal}
        setShowModal={setShowEnableHCaptchaModal}
      />
      <ConcurrentLoginModal
        showModal={showConcurrentLoginModal}
        setShowModal={setShowConcurrentLoginModal}
      />
    </section>
  );
};

export default Security;
