import { useMemo, useState } from "react";

const useSearch = ({ input, map }) => {
  const [searchString, setSearchString] = useState("");
  return useMemo(
    () => ({
      searchString,
      setSearchString,
      output: input?.filter((value) =>
        map(value)?.toLowerCase()?.includes(searchString?.toLowerCase())
      ),
    }),
    [input, map, searchString]
  );
};

export default useSearch;
