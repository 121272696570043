import React, { useState } from "react";
import Input from "components/Input";
import { Modal, ModalTypes } from "components/Modal";
import { List } from "components";
import { UserTwoFactorAuthPayload } from "generatedTypes";
import QRCode from "react-qr-code";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { SaveBackupCodeModal } from "containers/2FactorAuth/SaveBackupCodeModal";
import { useAuthenticateUserWith2FaMutation } from "features";

interface Props extends ModalTypes {
  responseData: UserTwoFactorAuthPayload;
  closeModal?: () => void;
}

export const SetUp2FAModal = ({
  showModal,
  setShowModal,
  responseData: resData,
  closeModal,
}: Props) => {
  const [showBackupCodeModal, setShowBackupCodeModal] = useState(false);
  const [code, setCode] = useState<string>("");
  const [responseData, setResponseData] = useState<string[]>();

  const [authenticateUserWith2FA, { loading }] =
    useAuthenticateUserWith2FaMutation();
  const { submit: handleAuthentication } = useOnSubmit({
    action: authenticateUserWith2FA,
    fields: { code },
    onSuccess: ({
      data: {
        authenticateUserWith2FA: {
          auth: {
            twoFactorSettings: { backupCodes },
          },
        },
      },
    }) => {
      if (backupCodes) {
        setResponseData(backupCodes);
        setShowBackupCodeModal(true);
      }
    },
    showToastProcessing: false,
    errorMsg: "Unable to authenticate user.",
  });

  const handleOnSubmit = (
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    handleAuthentication();
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Set up Two Factor Authentication"
      showDivider
      actionButtons={{
        confirm: {
          label: "Verify Code",
          onConfirm: () => handleAuthentication(),
          isDisabled: !code,
          isLoading: loading,
        },
        cancel: { label: "Cancel" },
      }}
    >
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <List>
          <List.Item listText="1" className="mb-8">
            <List.Content>
              <List.Header>Download an authenticator app</List.Header>
              <List.Description>
                We recommend Google Authenticator, 1Password or Authy.
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item listText="2" className="mb-8">
            <List.Content>
              <List.Header>
                Scan this barcode using your authenticator app
              </List.Header>
              <List.Description>
                <QRCode value={resData.url} tw="w-[144px] h-[145px] my-4" />
                Alternatively you can manually enter this code
                <Input
                  value={resData.secret}
                  hideIndicators
                  label="code"
                  hideLabel
                  className="mt-4"
                  allowCopy
                  readOnly
                  inverted={false}
                />
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item listText="3">
            <List.Content>
              <List.Header>
                Enter the security code generated by your app
              </List.Header>
              <List.Description>
                <Input
                  required
                  label="Security code"
                  hideLabel
                  value={code}
                  placeholder="e.g. 715241"
                  onChange={(e) => setCode(e.target.value)}
                />
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
        <button
          type="submit"
          className="sr-only"
          onSubmit={(e) => handleOnSubmit(e)}
        >
          Submit
        </button>
      </form>
      <SaveBackupCodeModal
        showModal={showBackupCodeModal}
        setShowModal={setShowBackupCodeModal}
        responseData={responseData}
        closeModal={closeModal}
      />
    </Modal>
  );
};
