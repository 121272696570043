import React from "react";
import { InfoTooltip } from "components/Tooltip";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";

interface LabelProps {
  text: string;
  hint?: string;
  className?: string;
  htmlFor?: string;
}

export const Label = ({ text, hint, className, htmlFor }: LabelProps) => {
  return (
    <label
      className={mergeClassNames(
        "flex items-center text-body-sm font-bold",
        className
      )}
      htmlFor={htmlFor}
    >
      {text}
      {hint && (
        <InfoTooltip
          size="md"
          className="ml-1"
          content={hint}
          variant="outline"
        />
      )}
    </label>
  );
};

Label.defaultProps = {
  hint: undefined,
  className: undefined,
};
