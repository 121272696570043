import {
  ArrowUpward,
  ContentCopy,
  PlayCircleOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionItem,
  Banner,
  Button,
  Card,
  Link,
} from "components";
import Input from "components/Input";
import { useAppDataContext } from "routes/AppDataContextProvider";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { RouteName } from "enums/routes";
import { Stack } from "generatedTypes";
import {
  VanillaJSCode,
  WebflowCode,
} from "containers/GettingStarted/InstallMemberstack/InstallCodes";
import { MemberstackProviderCodeBlock } from "containers/Settings/install-code/ReactCode";
import OnboardingModal from "components/Modal/OnboardingModal";
import { DnsRecordsModal } from "./DnsRecordsModal";

const DnsSettings = ({
  rootDomain,
  setRootDomain,
  authSubdomain,
  setAuthSubdomain,
  domainRef,
}) => {
  const history = useHistory();
  const { canAccessLiveFeatures, appData } = useAppDataContext();
  const [showDnsRecords, setShowDnsRecords] = useState<boolean>(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const renderInstallCode = useMemo(() => {
    switch (appData?.stack) {
      case Stack.React:
        return (
          <MemberstackProviderCodeBlock domain={rootDomain} onlyShowCode />
        );
      case Stack.Webflow:
        return <WebflowCode domain={rootDomain} onlyShowCode />;
      default:
        return <VanillaJSCode domain={rootDomain} onlyShowCode />;
    }
  }, [appData?.stack, rootDomain]);

  return (
    <>
      <Card className="p-4 flex flex-col">
        <h3 className="text-base font-bold mb-4">DNS Settings</h3>
        <div tw="max-w-[556px]" className="">
          <h4 className="font-bold text-body-sm">Custom Domain</h4>
          <p className="text-body-sm text-app-gray600 mt-2 mb-4">
            Use your own your domain for social auth (e.g.{" "}
            <i>continue to mydomain.com</i>), use your own domain for the SSO
            login page (e.g.
            <i>login.mydomain.com</i>), and the option to set member token
            cookie on your own domain.
          </p>
          <div className="flex items-center mb-4">
            <Button
              text="Learn More"
              buttonStyle="skeleton"
              className="mr-3"
              onClick={() =>
                window.open(
                  "https://docs.memberstack.com/hc/en-us/articles/15793943107995-DNS-Settings-Custom-Domains",
                  "_blank"
                )
              }
            />
            <Button
              text="Watch Video"
              leftIcon={<PlayCircleOutlined />}
              buttonStyle="transparent"
              onClick={() => setShowOnboardingModal(true)}
            />
          </div>

          <Accordion
            name={`${appData?.name}dns_settings`}
            striped={false}
            disabled={!canAccessLiveFeatures}
            className="custom-domain-accordion"
          >
            <AccordionItem
              label="Step 1 - Enter your Custom Domains"
              value="step1"
              removeSectionPadding
              dataCy="custom-domains-accordion-item"
            >
              <div className="p-2">
                <Input
                  disabled={!canAccessLiveFeatures}
                  leading="https://"
                  leadingInline
                  hideIndicators
                  placeholder="mydomain.com"
                  label="Root Domain"
                  subLabel="Please enter your root domain only. (e.g. mydomain.com)"
                  inputWidth={342}
                  value={rootDomain}
                  onChange={(e) => setRootDomain(e.target.value)}
                  className="mb-5"
                  dataCy="root-domain-input"
                />
                <Input
                  disabled={!canAccessLiveFeatures}
                  leading="https://"
                  leadingInline
                  trailingInline
                  hideIndicators
                  placeholder="auth"
                  label="Member-facing Subdomain"
                  subLabel="We’ll use this domain for any pages hosted by Memberstack. SSO, etc."
                  trailing={rootDomain ? `.${rootDomain}` : ".mydomain.com"}
                  inputWidth={342}
                  value={authSubdomain}
                  onChange={(e) => setAuthSubdomain(e.target.value)}
                  className="mb-5"
                />
                <div className="flex items-center gap-3 bg-orange-light text-orange-fill p-3 rounded-lg">
                  <WarningOutlined /> <p>You must complete all 4 steps</p>
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              label="Step 2 - Update your DNS Records"
              value="step2"
              removeSectionPadding
              dataCy="dns-records-accordion-item"
            >
              <div className="p-2">
                {!canAccessLiveFeatures ? (
                  <Banner
                    tw="w-max"
                    text={
                      <>
                        To configure DNS settings please{" "}
                        <Link to="/settings/billing" isBold>
                          upgrade your plan
                        </Link>
                      </>
                    }
                    leftPlugin={<ArrowUpward />}
                  />
                ) : (
                  domainRef.current?.rootDomain && (
                    <Button
                      text="Copy DNS Records"
                      leftIcon={<ContentCopy />}
                      buttonStyle="skeleton"
                      onClick={() => setShowDnsRecords(true)}
                      dataCy="copy-dns-records-button"
                    />
                  )
                )}
                <div className="flex items-center gap-3 bg-orange-light text-orange-fill p-3 rounded-lg mt-5">
                  <WarningOutlined /> <p>You must complete all 4 steps</p>
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              label="Step 3 - Replace Memberstack Header Script"
              value="step3"
              removeSectionPadding
            >
              <div className="p-2">
                {renderInstallCode}
                <div className="flex items-center gap-3 bg-orange-light text-orange-fill p-3 rounded-lg mt-5">
                  <WarningOutlined /> <p>You must complete all 4 steps</p>
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              label="Step 4 - Update Social Auth Providers & SSO Connection"
              value="step4"
              removeSectionPadding
            >
              <div className="p-2">
                <p className="text-body-sm text-app-gray600 mb-2">
                  If you have any social auth providers connected you will have
                  to update the redirect URIs for each. Learn more
                </p>
                <div className="flex items-center">
                  <Button
                    text="Edit Auth Providers"
                    buttonStyle="skeleton"
                    className="mr-3"
                    onClick={() => history.push(RouteName.authProviders)}
                  />
                </div>
                <div className="flex items-center gap-3 bg-orange-light text-orange-fill p-3 rounded-lg mt-5">
                  <WarningOutlined /> <p>You must complete all 4 steps</p>
                </div>
              </div>
            </AccordionItem>
          </Accordion>

          {!canAccessLiveFeatures && (
            <Banner
              tw="w-max mt-5"
              text={
                <>
                  To configure DNS settings please{" "}
                  <Link to="/settings/billing" isBold>
                    upgrade your plan
                  </Link>
                </>
              }
              leftPlugin={<ArrowUpward />}
            />
          )}
        </div>
      </Card>
      <DnsRecordsModal
        rootDomain={rootDomain}
        authSubdomain={authSubdomain}
        showModal={showDnsRecords}
        setShowModal={setShowDnsRecords}
      />
      <OnboardingModal
        title="Configure DNS"
        showModal={showOnboardingModal}
        setShowModal={setShowOnboardingModal}
        videoSrc="https://www.loom.com/embed/3c5503cd729c477a8746553ba595166c"
        docsLink="https://docs.memberstack.com/hc/en-us/articles/15793943107995-DNS-Settings-Custom-Domains"
      />
    </>
  );
};

export default DnsSettings;
