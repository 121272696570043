import { useMemo } from "react";

export enum ErrorTypes {
  Duplicate = "DUPLICATE",
  Multiple = "MULTIPLE_EQUAL",
}

const getFirstWordInURL = (url) =>
  url.replace(/^\/+/g, "").trim().split("/")[0];

const useDuplicateError = (urls) => {
  const errorMessage = useMemo(() => {
    if (!urls) {
      return null;
    }
    const unique = [];
    let error = "";
    if (urls.length > 1) {
      urls.forEach(({ url, filter }) => {
        const workingUrl = getFirstWordInURL(url);

        const isEqual = workingUrl
          ? unique.some((word) => getFirstWordInURL(word) === workingUrl)
          : false;
        if (isEqual && filter === "EQUALS") {
          error = ErrorTypes.Multiple;
        } else if (unique.includes(url) && Boolean(url)) {
          error = ErrorTypes.Duplicate;
        } else {
          unique.push(url);
        }
      });
    }
    return error;
  }, [urls]);

  return { errorMessage };
};

export default useDuplicateError;
