import React from "react";
import { Link } from "components";
import { Warning } from "@mui/icons-material";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";

const ErrorPage = ({ isFullScreen }) => {
  const fullScreen = isFullScreen ? "h-screen" : "";
  return (
    <div
      className={mergeClassNames(
        "flex flex-col justify-center items-center mx-auto w-full  text-gray-500",
        fullScreen
      )}
    >
      <Warning tw="w-6! h-6! text-orange-fill" />
      <h3 className="text-h3 text-app-gray400 mb-1">
        Oops! Something went wrong
      </h3>
      <p className="text-body-sm mb-6 w-64 text-center">
        Try to refresh the page or contact{" "}
        <Link
          showAsLink
          isExternal
          target="_blank"
          to="https://docs.memberstack.com/hc/en-us"
        >
          support
        </Link>{" "}
        if the problem persists.
      </p>
    </div>
  );
};

export default ErrorPage;
