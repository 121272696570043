import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation LoginUserEmailPassword($input: LoginUserEmailPasswordInput!) {
    loginUserEmailPassword(input: $input) {
      id
      profile {
        firstName
      }
      auth {
        email
        twoFactorSettings {
          enabled
        }
      }
    }
  }
`;

export const LOGIN_USER_GOOGLE = gql`
  mutation LoginUserGoogle($input: LoginUserGoogleInput!) {
    loginUserGoogle(input: $input) {
      id
      profile {
        firstName
      }
      auth {
        email
        twoFactorSettings {
          enabled
        }
      }
    }
  }
`;

export const GENERATE_ZEDESK_TOKEN = gql`
  mutation GenerateZendeskToken {
    generateZendeskToken
  }
`;

export const LOGOUT_USER = gql`
  mutation LogoutUser {
    logoutUser {
      id
    }
  }
`;

export const SETUP_2FA = gql`
  mutation SetupUser2FA {
    setupUser2FA {
      secret
      url
    }
  }
`;

export const VERIFY_2FA = gql`
  mutation AuthenticateUserWith2FA($input: AuthenticateUserWith2FAInput!) {
    authenticateUserWith2FA(input: $input) {
      auth {
        twoFactorSettings {
          backupCodes
        }
      }
    }
  }
`;

export const ENABLE_2FA = gql`
  mutation EnableUser2FA {
    enableUser2FA {
      id
    }
  }
`;

export const DISABLE_2FA = gql`
  mutation DisableUser2FA {
    disableUser2FA {
      id
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation SignupUserEmailPassword($input: SignupUserEmailPasswordInput!) {
    signupUserEmailPassword(input: $input) {
      id
      auth {
        email
      }
    }
  }
`;

export const SIGNUP_USER_GOOGLE = gql`
  mutation SignupUserGoogle($input: LoginUserGoogleInput!) {
    signupUserGoogle(input: $input) {
      id
      auth {
        email
      }
    }
  }
`;
