import React from "react";
import Input from "components/Input";
import { ProgressBar } from "components/ProgressBar/ProgressBar";

export const TranslateAuthForm = ({
  handleSubmit,
  disableInputs,
  formProgress,
  formInputs,
  handleInputChange,
}) => (
  <form onSubmit={(e) => handleSubmit(e)} className="py-7 px-5">
    <ProgressBar progress={formProgress} tw="mb-5" />
    <Input
      disabled={disableInputs}
      name="auth"
      label="Authorize"
      placeholder="Authorize"
      tw="mb-5"
      hideIndicators
      value={formInputs.auth}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="auth_info"
      label="will share the following info with"
      placeholder="will share the following info with"
      tw="mb-5"
      hideIndicators
      value={formInputs.auth_info}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="continue"
      label="Continue"
      placeholder="Continue"
      tw="mb-5"
      hideIndicators
      value={formInputs.continue}
      onChange={handleInputChange}
    />
  </form>
);
