import React from "react";
import cn from "helpers/cn";
import { Link } from "../Link/Link";

interface Props {
  children: React.ReactNode;
  showBottomInfo?: boolean;
  className?: string;
  bannerInfo?: {
    label?: string;
    linkText?: string;
    link?: string;
    children?: React.ReactNode;
  };
}

const SettingsBar = ({
  showBottomInfo,
  children,
  className,
  bannerInfo,
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-col h-full rounded-none relative border-r border-app-gray100",
        className
      )}
    >
      {children}
      {showBottomInfo && (
        <>
          <div className="flex-1" />
          <div className="bottom-0 rounded p-3 m-5 bg-app-gray50 border border-app-gray100 text-base">
            <p>{bannerInfo.label}</p>
            <Link
              to={bannerInfo.link}
              target="_blank"
              isExternal
              showAsLink
              isBold
            >
              {bannerInfo.linkText}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SettingsBar;
