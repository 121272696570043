import { gql } from "@apollo/client";

export const GET_CONTENT_GROUPS = gql`
  query GetContentGroups {
    getContentGroups {
      id
      key
      name
      redirect
      allowAllMembers
      urls {
        id
        url
        filter
      }
      plans {
        id
        name
      }
      customContent {
        key
        id
        name
        content
        type
        restrictedUrlGroupId
      }
    }
  }
`;

export const GET_CONTENT_GROUP = gql`
  query GetContentGroup($id: ID!) {
    getContentGroup(id: $id) {
      id
      key
      name
      redirect
      allowAllMembers
      urls {
        id
        url
        filter
      }
      plans {
        id
        name
      }
      customContent {
        key
        id
        name
        content
        type
        restrictedUrlGroupId
      }
    }
  }
`;
