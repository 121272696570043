import useGenerateComponentID from "hooks/useComponentId";
import React, { useMemo } from "react";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import * as S from "./styles";

export interface RadioButtonProps {
  id?: string;
  name: string;
  value: string;
  selectedValue: string;
  onChange?: (event) => void;
  className?: string;
  disabled?: boolean;
  label?: string;
  readOnly?: boolean;
  description?: React.ReactNode;
  imgSrc?: string;
  component?: React.ReactNode;
  labelClassName?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const {
    id,
    className = "",
    description,
    disabled = false,
    label,
    name,
    readOnly = false,
    value = "",
    selectedValue = undefined,
    onChange = () => {},
    component = undefined,
    imgSrc = undefined,
    labelClassName,
  } = props;

  const inputId = useGenerateComponentID();

  const renderLabel = useMemo(() => {
    if (imgSrc) {
      return <img src={imgSrc} alt={label} />;
    }
    return component;
  }, [component, imgSrc, label]);

  if (imgSrc || component) {
    return (
      <S.RadioButtonImageContainer>
        <input
          type="radio"
          name={name}
          id={inputId}
          className="input-hidden"
          onChange={onChange}
          value={value}
          checked={selectedValue && value === selectedValue}
        />
        <label htmlFor={inputId}>{renderLabel}</label>
      </S.RadioButtonImageContainer>
    );
  }

  // if no label and description are provided, render default label
  if (!label && !description) {
    return (
      <input
        id={inputId}
        readOnly={readOnly}
        className="h-4 w-4 text-app-blue500 focus:ring-transparent cursor-pointer"
        disabled={disabled}
        name={name}
        type="radio"
        value={value}
        checked={selectedValue && value === selectedValue}
        onChange={onChange}
      />
    );
  }

  return (
    <S.Container className={className}>
      <input
        id={id || inputId}
        readOnly={readOnly}
        className="h-4 w-4 text-app-blue500 focus:ring-transparent cursor-pointer"
        disabled={disabled}
        name={name}
        type="radio"
        value={value}
        checked={selectedValue && value === selectedValue}
        onChange={onChange}
      />
      <label
        className={mergeClassNames(
          "block text-utility-md cursor-pointer",
          labelClassName
        )}
        htmlFor={id || inputId}
      >
        {label}
      </label>
      {description && (
        <S.DescriptionContainer className="text-app-gray500 cursor-default">
          {description}
        </S.DescriptionContainer>
      )}
    </S.Container>
  );
};
