import React, { useMemo, useCallback, useState, Fragment } from "react";
import CreateAppModal from "components/Sidebar/AppSelector/CreateAppModal/CreateAppModal";
import { Add } from "@mui/icons-material";
import { Popover, Transition } from "@headlessui/react";
import { getUIAvatarLink } from "helpers/getUIAvatarLink";
import { useUserContext } from "containers/Layouts/PrivateLayout/UserContextProvider";
import { slugify } from "helpers/slugify";
import { Button } from "components/Button";
import { Avatar } from "components/Avatar";
import { NavLink, useParams } from "react-router-dom";
import { useSidebarContext } from "../context/sidebar.context";
import { Trigger } from "./Trigger";

const AppSelector = () => {
  const { isExpanded } = useSidebarContext();
  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useUserContext();
  const { appId } = useParams();
  const currentAppId = window.location.pathname
    .split("/")
    .find((val) => val.includes("app_"));

  const handleShowModal = useCallback(() => setShowModal(true), []);

  const currentApp = currentUser?.appConnections.find(
    (appConnection) => appConnection.app.id === currentAppId
  );

  const currentAppImage = getUIAvatarLink({
    name: currentApp?.app.name,
    color: currentApp?.app?.branding?.colors?.light?.primaryButton,
  });

  const appLinks = useMemo(() => {
    const appConnections = currentUser?.appConnections || [];
    return appConnections
      .filter((appConnection) => !(appConnection?.app.status === "DELETED"))
      .map((appConnection) => {
        const appImageLink = getUIAvatarLink({
          name: appConnection?.app.name,
          color: appConnection?.app?.branding?.colors?.light?.primaryButton,
        });
        return (
          <li key={appConnection.app.id}>
            <Popover.Button
              as={NavLink}
              to={`/apps/${appConnection?.app.id}`}
              data-cy={slugify(appConnection.app.name)}
              className={`p-3 m-1 rounded-md cursor-pointer flex items-center ${appConnection.app.id === appId ? "text-white bg-app-blue400" : "text-black bg-white hover:bg-app-gray50"}`}
            >
              {" "}
              <Avatar
                src={appConnection?.app?.image ?? appImageLink}
                alt={appConnection.app.name}
                className="mr-2"
              />
              <p className="text-body-sm overflow-ellipsis whitespace-nowrap overflow-hidden truncate w-32 text-left">
                {appConnection.app.name}
              </p>
            </Popover.Button>
          </li>
        );
      });
  }, [currentAppId, currentUser?.appConnections]);

  return (
    <>
      <Popover className="relative">
        {({ open }) => {
          return (
            <>
              <Popover.Button data-cy="change-app-button" className="w-full">
                <Trigger
                  appImageAlt="current app logo"
                  appImageSrc={currentApp?.app?.image ?? currentAppImage}
                  appName={currentApp?.app.name}
                  isExpanded={isExpanded}
                />
              </Popover.Button>
              <Popover.Overlay
                className={`${
                  open ? "opacity-30 fixed inset-0 z-10" : "opacity-0"
                } transparent`}
              />
              {open && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel tw="fixed z-10 transform bg-white mt-1 rounded shadow-lg border border-app-gray100 w-custom-228">
                    <ul
                      className="overflow-y-scroll list-none"
                      style={{ maxHeight: "65vh" }}
                    >
                      {appLinks.length ? (
                        appLinks
                      ) : (
                        <div className="w-full text-app-gray4 p-7 text-center bg-app-gray2 rounded-lg text-[18px]">
                          No other apps
                        </div>
                      )}
                    </ul>
                    <div className="p-1 border-t">
                      <Button
                        leftIcon={<Add />}
                        text="New App"
                        buttonStyle="skeleton"
                        onClick={handleShowModal}
                        tw="w-full justify-start p-3"
                        dataCy="new-app-button"
                      />
                    </div>
                  </Popover.Panel>
                </Transition>
              )}
            </>
          );
        }}
      </Popover>
      <CreateAppModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default AppSelector;
