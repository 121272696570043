import React from "react";
import { DomainMode } from "generatedTypes";
import {
  useAddAppDomainMutation,
  useRemoveDomainMutation,
  useUpdateDomainMutation,
} from "features";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useToastAlertContext } from "components/toastAlert";
import { Card } from "components";
import { AppDomains } from "./AppDomains";

const ApplicationDomains = () => {
  const { appData, refetch } = useAppDataContext();
  const { createToastAlert } = useToastAlertContext();

  const [updateDomain, { loading: updatingDomain }] = useUpdateDomainMutation();
  const [addDomain, { loading: addingDomain }] = useAddAppDomainMutation();
  const [removeDomain, { loading: removingDomain }] = useRemoveDomainMutation();

  const handleUpdateDomain = async (
    domainId: string,
    url: string,
    mode: DomainMode
  ) => {
    createToastAlert({ processing: true });
    try {
      await updateDomain({
        variables: { input: { id: domainId, domain: url, mode } },
      });

      createToastAlert({
        alertType: "success",
        message: "Domain successfully updated",
      });
      window.CommandBar?.trackEvent("domain-updated", {});
      await refetch();
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: e.message,
      });
    }
  };

  const handleAddAppDomain = async (url: string, mode: DomainMode) => {
    createToastAlert({ processing: true });

    try {
      await addDomain({
        variables: { input: { domain: url, mode } },
      });

      createToastAlert({
        alertType: "success",
        message: `${url} was added to your domains list.`,
      });
      window.CommandBar?.trackEvent("new-domain-added", {});
      await refetch();
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: e.message,
      });
    }
  };

  const handleRemoveAppDomain = async (url: string) => {
    createToastAlert({ processing: true });
    try {
      await removeDomain({
        variables: { input: { domain: url } },
      });

      createToastAlert({
        alertType: "success",
        message: `${url} was removed from your domains list.`,
      });
      await refetch();
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: e.message,
      });
    }
  };
  return (
    <Card className="p-4 flex flex-col">
      <h3 className="text-base font-bold mb-4">Application Domains</h3>
      <AppDomains
        label="App Domains"
        domains={appData?.domains}
        onAddDomain={handleAddAppDomain}
        onRemoveDomain={handleRemoveAppDomain}
        onUpdateDomain={handleUpdateDomain}
        isLoading={updatingDomain || addingDomain || removingDomain}
      />
    </Card>
  );
};

export default ApplicationDomains;
