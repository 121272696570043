import React, { useMemo } from "react";
import { useGetAppEventsQuery } from "features";

import { Card } from "components";
import Background from "assets/images/empty_state_bg.png";
import { format } from "date-fns";
import { AppEventType } from "generatedTypes";
import Tag from "components/Tag";
import {
  AttachMoney,
  DeleteOutline,
  MoneyOff,
  PersonAddOutlined,
  PersonRemoveOutlined,
  Refresh,
  RemoveCircleOutline,
  UpgradeOutlined,
} from "@mui/icons-material";
import { TeamMemberTableSkeleton } from "./TeamSettingsLoader";

const TypeTag = ({ type }: { type: AppEventType }) => {
  const convertedString = type
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const variant = useMemo(() => {
    switch (type) {
      case AppEventType.OwnerChanged:
        return "secondary";
      case AppEventType.AdminRemoved:
      case AppEventType.AppDeleted:
      case AppEventType.CustomDomainUpdated:
      case AppEventType.LiveMembersDeleted:
      case AppEventType.StripeDisconnected:
      case AppEventType.TestMembersDeleted:
        return "danger";
      default:
        return "default";
    }
  }, [type]);

  const icon = useMemo(() => {
    switch (type) {
      case AppEventType.AppDeleted:
      case AppEventType.LiveMembersDeleted:
      case AppEventType.TestMembersDeleted:
        return <DeleteOutline />;
      case AppEventType.AdminAdded:
        return <PersonAddOutlined />;
      case AppEventType.AdminRemoved:
        return <PersonRemoveOutlined />;
      case AppEventType.StripeConnected:
        return <AttachMoney />;
      case AppEventType.StripeDisconnected:
        return <MoneyOff />;
      case AppEventType.CustomDomainUpdated:
        return <UpgradeOutlined />;
      case AppEventType.OwnerChanged:
        return <Refresh />;
      default:
        return <RemoveCircleOutline />;
    }
  }, [type]);

  return <Tag text={convertedString} variant={variant} icon={icon} isClear />;
};

const AppEvents = () => {
  const { data, loading, error } = useGetAppEventsQuery();

  if (loading) return <TeamMemberTableSkeleton />;

  const appEvents = data?.getAppEvents;

  return (
    <Card>
      <div className="p-4 border-b">
        <h3 className="text-base font-bold">App Events</h3>
      </div>
      <div className="max-h-80 h-80 flex flex-col overflow-auto">
        <table className="w-full" id="app-events-table">
          <thead className="sr-only">
            <tr className="text-utility-md">
              <th className="p-4">IP Address</th>
              <th className="p-4">Type</th>
              <th className="p-4">Name</th>
              <th className="p-4">email</th>
              <th className="p-4">Date</th>
            </tr>
          </thead>
          <tbody>
            {appEvents?.map(({ ip, user, timestamp, type }, idx) => {
              const name = `${user?.profile?.firstName} ${user?.profile?.lastName}`;
              const date = new Date(timestamp);
              const key = `${user?.id}-${idx}`;

              return (
                <tr
                  key={key}
                  className="text-utility-md border-solid border-b last:border-b-0"
                >
                  <td className="p-4">{ip}</td>
                  <td className="p-4">
                    <TypeTag type={type} />
                  </td>
                  <td className="p-4 capitalize">{name}</td>
                  <td className="p-4">{user?.auth?.email}</td>
                  <td className="p-4">
                    {format(date, "yyyy-MM-dd, h:mm aaaa")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {appEvents?.length <= 2 && (
          <div
            className="h-full flex items-center justify-center"
            style={{ background: `url(${Background})` }}
          >
            <p className="text-body-sm text-app-gray500">
              You’ll be able see app events here
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default AppEvents;
