import { gql } from "@apollo/client";

export const CREATE_RESTRICTED_URL = gql`
  mutation CreateRestrictedUrl($input: CreateRestrictedUrlInput!) {
    createRestrictedUrl(input: $input) {
      id
      url
      filter
    }
  }
`;

export const DELETE_RESTRICTED_URL = gql`
  mutation DeleteRestrictedUrl($input: DeleteRestrictedUrlInput!) {
    deleteRestrictedUrl(input: $input) {
      id
    }
  }
`;

export const CREATE_RESTRICTED_URL_GROUP = gql`
  mutation CreateRestrictedUrlGroup($input: CreateRestrictedUrlGroupInput!) {
    createRestrictedUrlGroup(input: $input) {
      id
      name
      redirect
      allowAllMembers
      urls {
        id
        url
        filter
      }
      plans {
        id
      }
    }
  }
`;

export const UPDATE_RESTRICTED_URL_GROUP = gql`
  mutation UpdateRestrictedUrlGroup($input: UpdateRestrictedUrlGroupInput!) {
    updateRestrictedUrlGroup(input: $input) {
      id
      name
      redirect
      allowAllMembers
      urls {
        id
        url
        filter
      }
      plans {
        id
      }
    }
  }
`;

export const UPDATE_RESTRICTED_URL = gql`
  mutation UpdateRestrictedUrl($input: UpdateRestrictedUrlInput!) {
    updateRestrictedUrl(input: $input) {
      id
      url
      filter
    }
  }
`;

export const DELETE_RESTRICTED_URL_GROUP = gql`
  mutation DeleteRestrictedUrlGroup($input: DeleteRestrictedUrlGroupInput!) {
    deleteRestrictedUrlGroup(input: $input) {
      id
    }
  }
`;

export const LINK_RESTRICTED_URLS_TO_RESTRICTED_URL_GROUP = gql`
  mutation LinkRestrictedUrlsToRestrictedUrlGroup(
    $input: LinkRestrictedUrlsToRestrictedUrlGroupInput!
  ) {
    linkRestrictedUrlsToRestrictedUrlGroup(input: $input) {
      id
      name
      redirect
      urls {
        id
        url
        filter
      }
      plans {
        id
      }
    }
  }
`;

export const DETACH_RESTRICTED_URLS_TO_RESTRICTED_URL_GROUP = gql`
  mutation DetachRestrictedUrlsToRestrictedUrlGroup(
    $input: DetachRestrictedUrlsFromRestrictedUrlGroupInput!
  ) {
    detachRestrictedUrlsFromRestrictedUrlGroup(input: $input) {
      id
      name
      redirect
      urls {
        id
        url
        filter
      }
      plans {
        id
      }
    }
  }
`;

export const LINK_PLANS_TO_RESTRICTED_URL_GROUP = gql`
  mutation LinkPlansToRestrictedUrlGroup(
    $input: LinkPlansToRestrictedUrlGroupInput!
  ) {
    linkPlansToRestrictedUrlGroup(input: $input) {
      id
    }
  }
`;

export const DETACH_PLANS_FROM_RESTRICTED_URL_GROUP = gql`
  mutation DetachPlansFromRestrictedUrlGroup(
    $input: DetachPlansFromRestrictedUrlGroupInput!
  ) {
    detachPlansFromRestrictedUrlGroup(input: $input) {
      id
    }
  }
`;

export const CREATE_CUSTOM_CONTENT = gql`
  mutation CreateCustomContent($input: CreateCustomContentExistingGroupInput!) {
    createCustomContent(input: $input) {
      id
      name
      content
      type
    }
  }
`;

export const UPDATE_CUSTOM_CONTENT = gql`
  mutation UpdateCustomContent($input: UpdateCustomContentInput!) {
    updateCustomContent(input: $input) {
      id
      name
      content
      type
    }
  }
`;

export const DELETE_CUSTOM_CONTENT = gql`
  mutation DeleteCustomContent($input: DeleteCustomContentInput!) {
    deleteCustomContent(input: $input) {
      id
    }
  }
`;
