import React, { useMemo, useState } from "react";
import useGenerateComponentID from "hooks/useComponentId";
import { InfoTooltip } from "components/Tooltip";
import Text from "components/Text";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { StyledTextarea, Description } from "./Textarea.styles";

interface Props {
  label?: string;
  name?: string;
  value?: string;
  toolTip?: string;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  description?: string;
  disabled?: boolean;
  className?: string;
  type?: "regular" | "note";
  rightText?: string;
  hideIndicator?: boolean;
}

export const Textarea = (props: Props) => {
  const {
    label,
    name,
    value,
    toolTip,
    readOnly = false,
    required = false,
    placeholder,
    rows = 3,
    description,
    disabled = false,
    onChange,
    className,
    type = "regular",
    rightText,
    hideIndicator,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const inputId = useGenerateComponentID();

  const renderRightText = useMemo(() => {
    if (type === "note" && rightText) {
      return (
        <Text noMargin tw="text-body-sm text-app-gray500">
          {rightText}
        </Text>
      );
    }
    if (hideIndicator) {
      return null;
    }
    return (
      !required && (
        <Text noMargin tw="text-body-sm text-app-gray500">
          Optional
        </Text>
      )
    );
  }, [hideIndicator, required, rightText, type]);

  return (
    <div className={mergeClassNames("sm:col-span-6", className)}>
      {label && (
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center gap-1">
            <label
              htmlFor={inputId}
              tw="flex items-center text-body-sm font-bold"
            >
              {label}
            </label>
            {toolTip && (
              <InfoTooltip
                content={toolTip}
                variant="outline"
                iconType="questionMark"
              />
            )}
          </div>
          {renderRightText}
        </div>
      )}
      <div>
        <StyledTextarea
          className="shadow-sm focus:ring-app-blue400 focus:border-app-blue400"
          id={inputId}
          name={name || inputId}
          rows={rows}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          $type={type}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      {description && <Description $show={isFocused} >
        {description}
      </Description>}
    </div>
  );
};
