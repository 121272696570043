import React from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { Permission } from "generatedTypes";
import { useGetPermissionsQuery } from "../queries.generated";

interface PermissionsType {
  error: ApolloError;
  permissions: Permission[];
  refetchPermissions: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  loadingPermissions: boolean;
}
const defaultPermissionsValues: PermissionsType = {
  permissions: undefined,
  loadingPermissions: undefined,
  error: undefined,
  refetchPermissions: undefined,
};
const PermissionsContext = React.createContext(defaultPermissionsValues);
const usePermissionsContext = () => React.useContext(PermissionsContext);
const PermissionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loading, data, refetch, error } = useGetPermissionsQuery();

  return (
    <PermissionsContext.Provider
      value={{
        permissions: data?.getPermissions,
        loadingPermissions: loading,
        error,
        refetchPermissions: refetch,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export { usePermissionsContext, PermissionsContextProvider };
