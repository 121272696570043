import React, {
  Fragment,
  ReactChild,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Menu, Transition } from "@headlessui/react";
import { MoreVert, MoreHoriz } from "@mui/icons-material";
import useClickout from "hooks/useClickout";
import { Tooltip } from "components/Tooltip";
import * as S from "./Dropdown.styles";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export type DropdownOptions = {
  id?: string;
  Icon?: React.ReactNode;
  text: string;
  onClick: (event?: React.MouseEventHandler<HTMLDivElement>) => void;
  isDangerText?: boolean;
  dataCy?: string;
  disabled?: boolean;
  tooltipText?: ReactChild;
};

interface DropdownProps {
  className?: string;
  options: DropdownOptions[];
  targetElement?: (isOpen?: boolean) => React.ReactNode;
  isMenu?: boolean;
  itemWidth?: string;
  position?: "top" | "bottom";
  horizontal?: boolean;
  onToggle?: () => void;
  onSelect?: (value: string) => void;
  dataCy?: string;
  fullLength?: boolean;
  optionsTextAlign?: "right" | "left";
  centralMenu?: boolean;
  hasLastChildDivider?: boolean;
}

export const Dropdown = ({
  className,
  options,
  targetElement,
  isMenu,
  itemWidth,
  position,
  horizontal,
  onToggle,
  onSelect,
  dataCy,
  fullLength,
  optionsTextAlign = "right",
  centralMenu = false,
  hasLastChildDivider = false,
}: DropdownProps) => {
  const { ref, isVisible, setIsVisible } = useClickout(false);

  useEffect(() => {
    onToggle();
  }, [isVisible, onToggle]);

  const renderMenuItems = useMemo(
    () =>
      options.map((option) => {
        const { id, text, onClick } = option;

        const handleClick = (e) => {
          setIsVisible(false);
          onClick(e);
        };

        return (
          <div key={id || text} aria-hidden id={id}>
            <Menu.Item
              as="div"
              data-cy={option.dataCy}
              onClick={(e) => handleClick(e)}
              data-testid="dropdown-click"
              disabled={option.disabled}
            >
              {({ active }) =>
                option.tooltipText ? (
                  <Tooltip content={option.tooltipText} placement="bottom">
                    <S.ItemContainer
                      $optionsTextAlign={optionsTextAlign}
                      $active={active}
                      $hasIcon={Boolean(option.Icon)}
                      $disabled={option.disabled}
                      $isDangerText={option.isDangerText}
                      onClick={() => onSelect(text)}
                    >
                      {option.Icon && option.Icon}
                      {text}
                    </S.ItemContainer>
                  </Tooltip>
                ) : (
                  <S.ItemContainer
                    $optionsTextAlign={optionsTextAlign}
                    $active={active}
                    $hasIcon={Boolean(option.Icon)}
                    $disabled={option.disabled}
                    $isDangerText={option.isDangerText}
                    onClick={() => onSelect(text)}
                  >
                    {option.Icon && option.Icon}
                    {text}
                  </S.ItemContainer>
                )
              }
            </Menu.Item>
          </div>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, optionsTextAlign]
  );

  const renderElement = useCallback(
    (open: boolean) => {
      const handleClick = () => {
        setIsVisible(!isVisible);
      };
      if (targetElement) {
        return targetElement(open);
      }
      return (
        <S.IconContainer>
          {horizontal ? (
            <MoreHoriz aria-hidden="true" onClick={handleClick} />
          ) : (
            <MoreVert aria-hidden="true" onClick={handleClick} />
          )}
        </S.IconContainer>
      );
    },
    [horizontal, isVisible, setIsVisible, targetElement]
  );

  return (
    <div
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      aria-hidden
      className={classNames(fullLength && "w-full leading-none")}
    >
      <Menu as="div" className={classNames("relative text-left", className)}>
        {({ open }) => (
          <>
            <Menu.Button
              data-testid="button-as-div"
              as="div"
              className={classNames(
                "rounded-sm flex items-center text-app-gray400 hover:text-app-gray600 focus:outline-none h-full",
                isMenu && open && "bg-app-gray100"
              )}
              data-cy={dataCy}
            >
              <span className="sr-only">Open options</span>
              {renderElement(open)}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={classNames(
                  isMenu
                    ? "right-auto origin-top shadow-lg"
                    : "origin-top-right right-0",
                  centralMenu && "right-auto w-full",
                  position === "top" ? "m-0 -top-custom-100" : "mt-2",
                  "absolute rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                )}
              >
                <S.ItemsContainer
                  $isMenu={isMenu}
                  $itemWidth={itemWidth}
                  $hasLastChildDivider={hasLastChildDivider}
                >
                  {renderMenuItems}
                </S.ItemsContainer>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

Dropdown.defaultProps = {
  className: undefined,
  isMenu: false,
  itemWidth: undefined,
  position: "bottom",
  horizontal: false,
  onToggle: () => undefined,
  onSelect: () => undefined,
};
