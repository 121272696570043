import React, { useMemo } from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { useGetSsoTextQuery } from "features/translations";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";
import { useGetBrandingQuery } from "features/branding";

interface DefaultContext {
  refetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  error: ApolloError;
  loading: boolean;
  brandColor: string;
  appImage: string;
  translations: { [key: string]: string };
}

const defaultContext: DefaultContext = {
  loading: false,
  error: undefined,
  refetch: undefined,
  brandColor: undefined,
  appImage: undefined,
  translations: undefined,
};

const HostedPagesContext = React.createContext(defaultContext);

const useHostedPagesContext = () => {
  return React.useContext(HostedPagesContext);
};

const HostedPageContextProvider = ({ children }) => {
  const {
    loading: loadingSSOtext,
    refetch,
    data,
    error: ssoTextError,
  } = useGetSsoTextQuery();
  const { appData, isLoading } = useAppDataContext();
  const {
    data: brandData,
    loading: loadingBrand,
    error: brandError,
  } = useGetBrandingQuery();

  const translations = useMemo(
    () => data?.getSSOText.textObject,
    [data?.getSSOText.textObject]
  );

  const loading = loadingSSOtext || loadingBrand || isLoading;

  const error = brandError || ssoTextError;

  const brandColor = brandData?.getBranding.colors.light.primaryButton;
  const appImage = appData?.image;

  return (
    <HostedPagesContext.Provider
      value={{
        error,
        refetch,
        loading,
        brandColor,
        appImage,
        translations,
      }}
    >
      {children}
    </HostedPagesContext.Provider>
  );
};

export { useHostedPagesContext, HostedPageContextProvider };
