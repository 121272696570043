import React, { useCallback, useMemo, useState, lazy } from "react";
import { Stack } from "generatedTypes";
import { useHistory } from "react-router-dom";
import Input from "components/Input";
import { useCreateAppMutation } from "features/apps";
import { Modal } from "components/Modal";
import { Form, Label, Link, Switch } from "components";
import Spacer from "components/Spacer";
import { useToastAlertContext } from "components/toastAlert";
import { useEnvContext } from "containers/Environment";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { Background, StackItem } from "./CreateAppModal.style";

const Template = lazy(() => import("assets/images/template.svg"));

const options = [
  {
    label: "Webflow",
    value: Stack.Webflow,
  },
  {
    label: "React",
    value: Stack.React,
  },
  {
    label: "Other",
    value: Stack.Vanilla,
  },
];

const CreateAppModal = ({
  showModal,
  setShowModal = () => {},
  hideCancel = false,
  withBackground = false,
  identifier,
}: {
  showModal: boolean;
  setShowModal?: (value) => void;
  hideCancel?: boolean;
  withBackground?: boolean;
  identifier?: string;
}) => {
  const [templateId, setTemplateId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTemplateInfo, setShowTemplateInfo] = useState(false);
  const { createToastAlert } = useToastAlertContext();
  const [name, setName] = useState("");
  const [stack, setStack] = useState(options[0].value);
  const [create] = useCreateAppMutation();
  const { refetch } = useUsersContext();
  const { switchToSandbox } = useEnvContext();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      createToastAlert({
        processing: true,
      });
      try {
        await create({
          variables: {
            input: { name: name.trim() || identifier, stack, templateId },
          },
        }).then(
          ({
            data: {
              createApp: { id },
            },
          }) => {
            createToastAlert({
              alertType: "success",
              message: "App created successfully.",
            });
            switchToSandbox();
            history.push(`/apps/${id}/getting-started`);
          }
        );
      } catch (e) {
        createToastAlert({
          alertType: "success",
          message: "Unable to create APP",
        });
      } finally {
        setIsSubmitting(false);
        setShowModal(false);
        refetch();
      }
    },
    [
      create,
      createToastAlert,
      name,
      identifier,
      refetch,
      setShowModal,
      stack,
      switchToSandbox,
      templateId,
      history,
    ]
  );

  const createApp = useMemo(() => {
    return (
      <Modal
        showOverlay={!withBackground}
        setShowModal={setShowModal}
        showModal={showModal}
        title="Create a New App"
        showDivider
        width="494px"
        name={identifier || "create-app"}
        hideCancel={hideCancel}
        actionButtons={{
          confirm: {
            label: "Create App",
            onConfirm: handleSubmit,
            isLoading: isSubmitting,
            isDisabled: !isFormValid || !stack,
          },
        }}
      >
        <Form onValidityCheck={setIsFormValid} className="flex gap-6">
          <Input
            required
            value={name}
            type="text"
            name="name"
            placeholder="e.g. SPI Community"
            data-testid="NewAppModal__name-input"
            onChange={(e) => setName(e.target.value)}
            minLength={4}
            maxLength={24}
            label="Name your App"
            description="Visible to members. You can change this later."
          />
          <div>
            <Label
              text="Where do you plan to install Memberstack?"
              hint="Your Memberstack dashboard will only show you functionality that’s compatible with your stack."
            />
            <div className="flex mt-1">
              {options.map((option, idx) => (
                <React.Fragment key={option.value}>
                  {idx ? <Spacer spacing="xSmall" isVertical={false} /> : null}
                  <StackItem
                    label={option.label}
                    selected={option.value === stack}
                    onClick={() => setStack(option.value)}
                    key={option.value}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
          {stack === Stack.Webflow && (
            <>
              <Switch
                label="Start from template"
                onChange={(value) => setShowTemplateInfo(!showTemplateInfo)}
                id="templates"
                name="templates"
                isChecked={showTemplateInfo}
              />
              {showTemplateInfo && (
                <>
                  <div className="flex flex-col gap-1">
                    <p className="text-body-sm text-app-gray600">
                      If you’re using a Memberstack Webflow template you can add
                      it’s ID to enable pre-configured settings.{" "}
                      <Link
                        underline
                        target="_blank"
                        to="https://docs.memberstack.com/hc/en-us/articles/8463428799131"
                        rel="noreferrer"
                        isExternal
                      >
                        Learn more.
                      </Link>
                    </p>
                    <Input
                      tw="mt-2"
                      value={templateId}
                      onChange={(e) => setTemplateId(e.target.value)}
                      placeholder="e.g. MS-927363822"
                      description="Enter the template ID. e.g. template_238098120980m3"
                    />
                  </div>
                  <div className="p-3 rounded-md bg-app-gray50">
                    <div className="flex items-center justify-between mb-3">
                      <h2 className="text-base font-bold">
                        Browse Memberstack Templates
                      </h2>
                      <Link
                        to="https://www.memberstack.com/templates"
                        target="_blank"
                        rel="noreferrer"
                        isExternal
                        showAsLink
                        isBold
                      >
                        View all
                      </Link>
                    </div>
                    <Template />
                  </div>
                </>
              )}
            </>
          )}
        </Form>
      </Modal>
    );
  }, [
    handleSubmit,
    hideCancel,
    isFormValid,
    isSubmitting,
    name,
    setShowModal,
    showModal,
    showTemplateInfo,
    stack,
    templateId,
    withBackground,
  ]);

  if (withBackground) {
    return <Background className="w-screen h-screen">{createApp}</Background>;
  }
  return createApp;
};

export default CreateAppModal;
