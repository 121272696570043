import React, { useCallback, useMemo, useRef } from "react";
import {
  AlternateEmailOutlined,
  CategoryOutlined,
  ClearAllOutlined,
  DateRange,
  FormatListBulleted,
} from "@mui/icons-material";
import PlanStatusTag from "components/PlanStatusTag";
import { Member, PlanConnectionStatus } from "generatedTypes";
import { sortBy } from "lodash";
import { OverflowList } from "react-overflow-list";
import { PLAN_CONNECTION_STATUS_MAPPING } from "constants/plans";
import { formatDate } from "helpers/formatDate";
import { Avatar } from "components";
import { generateEmailColor } from "helpers/generateEmailColor";
import TrustLevelTag from "components/Tag/TrustLevelTag";
import Tag from "components/Tag";

export const DEFAULT_COLUMNS_KEYS = [
  "email",
  "plans",
  "createdAt",
  "lastLogin",
  "selection",
  "trustStatus",
];

export const DEFAULT_COLUMNS = [
  {
    key: "email",
    label: "Email Address",
    icon: <AlternateEmailOutlined className="icon" />,
  },
  {
    key: "plans",
    label: "Plans",
    icon: <CategoryOutlined className="icon" />,
  },
  {
    key: "createdAt",
    label: "Date Created",
    icon: <DateRange className="icon" />,
  },
  {
    key: "trustStatus",
    label: "Trust Status",
    icon: <FormatListBulleted className="icon" />,
  },
  {
    key: "lastLogin",
    label: "Last Login",
    icon: <DateRange className="icon" />,
  },
];

type TableRow = Member["customFields"] & { id: string };

export const PlanTags = ({ planConnections }) => {
  const plansData = useMemo(() => {
    const sortedPlans = sortBy(planConnections, [(o) => o.plan.name]);

    return sortedPlans;
  }, [planConnections]);

  const ItemRenderer = ({ plan, status, team }) => (
    <PlanStatusTag
      key={plan?.id}
      status={status}
      planName={plan?.name}
      textSize="utilityMedium"
      showMemberIcon={team?.isTeamPlan}
    />
  );

  const OverflowRenderer = (plans) => (
    <PlanStatusTag overflow={plans} textSize="utilityMedium" />
  );

  const Overflow = ({ plans }) => (
    <OverflowList
      collapseFrom="end"
      minVisibleItems={0}
      items={plans}
      itemRenderer={ItemRenderer}
      overflowRenderer={OverflowRenderer}
      className="gap-2"
    />
  );

  return (
    <div className="flex items-center">
      {plansData.length > 0 ? <Overflow plans={plansData} /> : null}
    </div>
  );
};

export const useGetTableData = ({ members, customFields }) => {
  const data: TableRow[] = useMemo(
    () =>
      members.map(
        ({
          id,
          auth,
          customFields: memberCustomFields,
          createdAt,
          lastLogin,
          planConnections,
          profileImage,
          trustStatus,
          isNew,
        }) => {
          const avatarColor = generateEmailColor(auth.email);
          return {
            // ensure memberCustomFields values are strings
            ...Object.keys(memberCustomFields || {}).reduce(
              (acc, key) => ({
                ...acc,
                [key]: memberCustomFields[key]?.toString(),
              }),
              {}
            ),
            id,
            isNew,
            email: (
              <div
                className="member-email flex items-center gap-3"
                data-cy={auth.email}
              >
                <Avatar
                  alt="member-avatar"
                  src={profileImage}
                  firstName={auth.email}
                  lastName=""
                  size="xSmall"
                  textColor={avatarColor.text}
                  bgColor={avatarColor.bg}
                />
                {auth.email}
                {isNew && (
                  <Tag text="NEW" variant="fadedBlue" size="small" isBold />
                )}
              </div>
            ),
            plans: <PlanTags planConnections={planConnections} />,
            createdAt: formatDate(createdAt),
            trustStatus: <TrustLevelTag status={trustStatus} />,
            lastLogin: lastLogin ? formatDate(lastLogin) : "-",
          };
        }
      ),
    [members]
  );

  const sanitizedCustomFields = useMemo(
    () =>
      sortBy(customFields, "order")
        .filter((field) => !field.tableHidden)
        .sort((field1, field2) => field1.tableOrder - field2.tableOrder),
    [customFields]
  );

  const columns = useMemo(
    () => [
      ...DEFAULT_COLUMNS.map(({ label, key, icon }) => ({
        accessor: key,
        key,
        label,
        icon,
      })),
      ...sanitizedCustomFields.map(
        ({ label, key, id, icon, tableOrder, visibility }) => ({
          accessor: key,
          key,
          label,
          customFieldId: id,
          tableOrder,
          icon: <ClearAllOutlined className="icon" />,
          visibility,
        })
      ),
    ],
    [sanitizedCustomFields]
  );

  return { columns, data };
};

export const useFiltering = ({ plans, searchValue, refetch }) => {
  const planIds = useRef(undefined);
  const status = useRef(undefined);
  const comments = useRef(undefined);

  const plansFilterItems = useMemo(() => {
    if (!plans) return [];
    const mappedPlans = plans.map((plan) => {
      const label =
        plan?.status !== "INACTIVE" ? plan.name : `${plan.name} (Archived)`;
      return {
        id: plan.id,
        label,
      };
    });

    // order mappedPlans where archived plans are at the bottom
    const archivedPlans = mappedPlans.filter((plan) =>
      plan.label.includes("Archived")
    );
    const activePlans = mappedPlans.filter(
      (plan) => !plan.label.includes("Archived")
    );
    const orderedPlans = [...activePlans, ...archivedPlans];
    return orderedPlans;
  }, [plans]);

  const planStatusFilterItems = Object.keys(PlanConnectionStatus).map((key) => {
    const statusId = PlanConnectionStatus[key];
    return {
      id: statusId,
      label: PLAN_CONNECTION_STATUS_MAPPING[statusId].name,
    };
  });

  const commentingItems = useMemo(
    () => [
      { id: "hasComments", label: "Has Comments" },
      // { id: "noComments", label: "No Comments" },
    ],
    []
  );

  const handleFilter = useCallback(
    (type) => (itemSelected) => {
      if (type === "plans") {
        planIds.current = itemSelected;
      }
      if (type === "commenting") {
        const hasComments = itemSelected.includes("hasComments");
        const noComments = itemSelected.includes("noComments");
        comments.current = { noComments, hasComments };
      }
      if (type === "planStatus") {
        status.current = itemSelected;
      }

      return refetch({
        search: searchValue,
        filters: {
          planIds: planIds.current || [],
          status: status.current || [],
          hasComments: comments.current?.hasComments || false,
          noComments: comments.current?.noComments || false,
        },
      });
    },
    [planIds, refetch, searchValue, status, comments]
  );

  const filterOptions = useMemo(
    () =>
      [
        // if plans are available, show plans filter
        plansFilterItems.length > 0 && {
          label: "Plans",
          value: "plans",
          items: plansFilterItems,
          onFilter: handleFilter("plans"),
        },
        {
          label: "Plan Status",
          value: "planStatus",
          items: planStatusFilterItems,
          onFilter: handleFilter("planStatus"),
        },
        {
          label: "Commenting",
          value: "commenting",
          items: commentingItems,
          onFilter: handleFilter("commenting"),
        },
      ].filter(Boolean),
    [handleFilter, planStatusFilterItems, plansFilterItems, commentingItems]
  );

  return {
    filterOptions,
    planIds,
    status,
    comments,
  };
};

export const determineColumnClassName = (key) => {
  if (key === "email") return "email";
  if (key === "plans") return "plans";
  if (key === "selection") return "selection";
  return "";
};
