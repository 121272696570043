import React from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { useAppDataContext } from "routes/AppDataContextProvider";
import {
  GetDefaultAuthProvidersQuery,
  GetSsoClientsQuery,
  useGetDefaultAuthProvidersQuery,
  useGetSsoClientsQuery,
} from "features/sso";
import { App } from "generatedTypes";
import AuthIntegrationLoader from "../AuthIntegrationLoader";

interface ContextValueT {
  appData: App;
  defaultProviderError: ApolloError;
  defaultAuthProviders: GetDefaultAuthProvidersQuery["defaultAuthProviders"];
  ssoClientsError: ApolloError;
  ssoClients: GetSsoClientsQuery["getSSOClients"];
  refetchSSOClient: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  loading: boolean;
  isStripeConnected: boolean;
}

const defaultPlansValues: ContextValueT = {
  appData: undefined,
  defaultProviderError: undefined,
  defaultAuthProviders: undefined,
  refetchSSOClient: undefined,
  ssoClients: undefined,
  ssoClientsError: undefined,
  loading: undefined,
  isStripeConnected: false,
};

const AuthPageContext = React.createContext(defaultPlansValues);

const useAuthPageContext = () => React.useContext(AuthPageContext);

const AuthPageContextProvider = ({ children }) => {
  const { appData, isLoading } = useAppDataContext();

  const {
    data: ssoClientsData,
    loading: loadingSSOClients,
    error: ssoClientsError,
    refetch: refetchSSOClient,
  } = useGetSsoClientsQuery();

  const {
    data: defaultProvidersData,
    loading: loadingDefaultProviders,
    error: defaultProviderError,
  } = useGetDefaultAuthProvidersQuery();

  const loading = isLoading || loadingSSOClients || loadingDefaultProviders;

  if (loading) return <AuthIntegrationLoader />;

  if (defaultProviderError || ssoClientsError)
    return <div>{defaultProviderError || ssoClientsError}</div>;

  const { defaultAuthProviders } = defaultProvidersData;
  const ssoClients = ssoClientsData.getSSOClients;
  const isStripeConnected = Boolean(appData.stripeConnection?.accountId);

  return (
    <AuthPageContext.Provider
      value={{
        appData,
        defaultAuthProviders,
        defaultProviderError,
        ssoClients,
        ssoClientsError,
        refetchSSOClient,
        loading,
        isStripeConnected,
      }}
    >
      {children}
    </AuthPageContext.Provider>
  );
};

export { useAuthPageContext, AuthPageContextProvider };
