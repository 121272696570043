import React from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { InfoTooltip } from "components/Tooltip";
import { Button } from "../Button";
import * as S from "./BillingCard.styles";

export enum PricingPlans {
  basic = "basic",
  professional = "professional",
  business = "business",
  established = "established",
}

let memberHoverText = "$49/mo per additional 20,000 members.";

const getPlanBenefits = (plan: PricingPlans) => {
  switch (plan) {
    case "basic":
      return [
        { name: "1,000 Members", active: true },
        { name: "Social Logins", active: true },
        { name: "Stripe Payments", active: true },
        { name: "All Integrations", active: true },
        { name: "Custom SSO", active: true },
        { name: "White-labeled Emails", active: true },
      ];
    case "professional":
      return [
        { name: "5,000 Members", active: true },
        { name: "Social Logins", active: true },
        { name: "Stripe Payments", active: true },
        { name: "All Integrations", active: true },
        { name: "Custom SSO", active: true },
        { name: "White-labeled Emails", active: true },
      ];
    case "business":
      return [
        { name: "10,000+ Members", active: true, hoverText: memberHoverText },
        { name: "Social Logins", active: true },
        { name: "Stripe Payments", active: true },
        { name: "All Integrations", active: true },
        { name: "Custom SSO", active: true },
        { name: "White-labeled Emails", active: true },
      ];
    case "established":
      return [
        { name: "10,000+ Members", active: true, hoverText: memberHoverText },
        { name: "Social Logins", active: true },
        { name: "Stripe Payments", active: true },
        { name: "All Integrations", active: true },
        { name: "Custom SSO", active: true },
        { name: "White-labeled Emails", active: true },
      ];
    default:
      return [];
  }
};

export type IntervalT = "monthly" | "yearly";

interface Props {
  interval?: IntervalT;
  plan: PricingPlans;
  onSubscribe: () => void;
  transactionFee?: string;
  price?: string;
  buttonText?: string;
  isLoading?: boolean;
  isSubscribed?: boolean;
}

export const BillingCard = ({
  interval,
  plan,
  onSubscribe,
  transactionFee,
  price,
  buttonText,
  isLoading,
  isSubscribed,
}: Props) => {
  let pricingHeader;
  if (plan === "established") {
    pricingHeader = price;
  } else {
    pricingHeader = (
      <>
        ${price}
        <span className="text-sm">
          /mo {interval === "yearly" && "billed yearly"}
        </span>
      </>
    );
  }

  if (interval === "yearly") {
    memberHoverText = "$39/mo per additional 20,000 members.";
  } else {
    memberHoverText = "$49/mo per additional 20,000 members.";
  }

  const percentDiscount = plan === "basic" ? 14 : 20;

  return (
    <div tw="bg-white px-7 py-[30px]">
      {interval === "yearly" && (
        <span tw="rounded bg-[#E7F1FF] py-0.5 px-1 mb-3 inline-block">
          Save <b>{percentDiscount}%</b> vs monthly
        </span>
      )}
      <h2 className="text-h3 font-bold mb-3 capitalize">{plan}</h2>

      <p tw="text-[28px] leading-8 font-medium mb-3">{pricingHeader}</p>

      <p className="text-base mb-5">
        <span className="font-bold">{transactionFee}</span> transaction fee
      </p>

      <div className="flex flex-col">
        {getPlanBenefits(plan).map(({ name, active, hoverText }) => (
          <S.Benefit key={name} $isActive={active}>
            <VerifiedIcon />
            {name}
            {hoverText && (
              <InfoTooltip content={hoverText} tw="ml-1" size="md" />
            )}
          </S.Benefit>
        ))}
      </div>

      {!isSubscribed && (
        <Button
          text={`Activate ${buttonText}`}
          onClick={onSubscribe}
          isLoading={isLoading}
          className="mt-8"
        />
      )}
    </div>
  );
};
