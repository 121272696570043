import React from "react";
import { useGetAccessLogsQuery } from "features";

import { Card } from "components";
import Background from "assets/images/empty_state_bg.png";
import { format } from "date-fns";
import { TeamMemberTableSkeleton } from "./TeamSettingsLoader";

const AccessLog = () => {
  const { data, loading, error } = useGetAccessLogsQuery();

  if (loading) return <TeamMemberTableSkeleton />;

  const accessLogs = data?.getAccessLogs;

  return (
    <Card>
      <div className="p-4 border-b">
        <h3 className="text-base font-bold">App Access Log</h3>
      </div>
      <div className="max-h-80 h-80 flex flex-col overflow-auto">
        <table className="w-full">
          <thead className="sr-only">
            <tr className="text-utility-md">
              <th className="p-4">IP Address</th>
              <th className="p-4">Name</th>
              <th className="p-4">email</th>
              <th className="p-4">Date</th>
            </tr>
          </thead>
          <tbody>
            {accessLogs?.map(({ ip, user, timestamp }, idx) => {
              const name = `${user?.profile?.firstName} ${user?.profile?.lastName}`;
              const date = new Date(timestamp);
              const key = `${user?.id}-${idx}`;

              return (
                <tr
                  key={key}
                  className="text-utility-md border-solid border-b last:border-b-0"
                >
                  <td className="p-4">{ip}</td>
                  <td className="p-4 capitalize">{name}</td>
                  <td className="p-4">{user?.auth?.email}</td>
                  <td className="p-4">
                    {format(date, "yyyy-MM-dd, h-h:59 aaaa")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {accessLogs?.length <= 2 && (
          <div
            className="h-full flex items-center justify-center"
            style={{ background: `url(${Background})` }}
          >
            <p className="text-body-sm text-app-gray500">
              You’ll be able see team member activity here
            </p>
          </div>
        )}
      </div>
      <div className="border-t p-4">
        <p className="text-utility-tag-md text-app-gray500">
          Retention history for activity logs is 2 weeks
        </p>
      </div>
    </Card>
  );
};

export default AccessLog;
