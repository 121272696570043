import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { ChevronRight, PlaylistAddCheck } from "@mui/icons-material";
import { RouteName } from "enums/routes";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";
import { Stack } from "generatedTypes";
import cn from "helpers/cn";
import { useSidebarContext } from "./context/sidebar.context";
import { NavContainer } from "./styles";

const GetStartedLink = () => {
  const { appData } = useAppDataContext();
  const { isExpanded, checkedItems } = useSidebarContext();

  const progress = useMemo(() => {
    const numberOfItems = appData?.stack === Stack.Webflow ? 7 : 5;
    return (checkedItems.length / numberOfItems) * 100;
  }, [checkedItems.length, appData?.stack]);

  return (
    <NavContainer label="Get Started" isExpanded={isExpanded}>
      <NavLink
        className={cn(
          "p-2 flex gap-2 items-center justify-between mt-4 rounded-lg border border-app-gray50 drop-shadow bg-white [&.active]:bg-app-blue50 hover:bg-app-gray50 group"
        )}
        to={RouteName.gettingStarted}
      >
        <div className="flex items-center gap-2">
          <div className="p-1 rounded bg-white">
            <PlaylistAddCheck className="w-5 h-5 shrink-0 text-app-gray600 group-[.active]:text-app-blue400" />
          </div>
          {isExpanded && (
            <div className="flex items-start flex-col">
              <p className="font-bold text-body-sm mb-1">
                Get Started &nbsp;
                <span className="font-normal">
                  {checkedItems.length} of{" "}
                  {appData?.stack && (appData?.stack === Stack.Webflow ? 7 : 5)}
                </span>
              </p>
              <progress
                className="border border-app-gray100 bg-white h-2 rounded-full [&::-webkit-progress-bar]:rounded-full [&::-webkit-progress-bar]:bg-white [&::-webkit-progress-value]:rounded-full [&::-webkit-progress-value]:bg-app-blue400 [&::-moz-progress-bar]:rounded-full [&::-moz-progress-bar]:bg-white"
                value={progress}
                max="100"
              />
            </div>
          )}
        </div>
        {isExpanded && <ChevronRight className="w-5 h-5" />}
      </NavLink>
    </NavContainer>
  );
};

export default GetStartedLink;
