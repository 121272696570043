import { gql } from "@apollo/client";

export const GET_PLANS = gql`
  query GetPlans {
    getPlans {
      id
      name
      icon
      description
      status
      permissions {
        id
        name
        description
      }
      prices {
        id
        name
        amount
        isPersisted @client
        active
        status
        type
      }
      isPaid
      isPersisted @client
      memberCount
      priority
      allowedDomains
    }
  }
`;

export const GET_PLAN = gql`
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      icon
      description
      status
      permissions {
        id
        name
        description
      }
      permissionGroups {
        id
        name
        permissions {
          id
          name
          description
        }
      }
      prices {
        id
        amount
        name
        isPersisted @client
        type
        description
        active
        status
        currency
        interval {
          type
          count
        }
        setupFee {
          enabled
          name
          amount
        }
        freeTrial {
          enabled
          days
        }
        expiration {
          count
          interval
        }
        memberCount
        taxType
        maxTeamMembers
        teamAccountsEnabled
      }
      isPaid
      isPersisted @client
      copiedToLive
      redirects {
        afterSignup
        afterLogin
        afterLogout
        afterPurchase
        afterCancel
        afterReplace
        verificationRequired
      }
      memberCount
      priority
      allowedDomains
      limitMembers
      memberLimit
      logic {
        addedLogic {
          add
          remove
          cancelRecurring
          removeAllFree
          cancelAllRecurring
        }
        removedLogic {
          add
          remove
          cancelRecurring
          removeAllFree
          cancelAllRecurring
        }
      }
      teamAccountsEnabled
      teamAccountUpgradeLink
      teamAccountInviteSignupLink
    }
  }
`;

export const GET_HAS_PAID_PLANS = gql`
  query getHasPaidPlans {
    getHasPaidPlans
  }
`;
