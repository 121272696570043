import React from "react";
import Skeleton from "react-loading-skeleton";

interface Props {
  className?: string;
  row?: number;
}

const InputLoading = ({ className, row = 1 }: Props) => (
  <div className={className}>
    <Skeleton width={80} height={24} className="mb-1" />
    <div>
      <Skeleton height={32 * row} />
    </div>
  </div>
);

export default InputLoading;
