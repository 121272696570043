import React from "react";
import Input from "components/Input";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { Textarea } from "components";

export const TranslateLoginForm = ({
  handleSubmit,
  disableInputs,
  formProgress,
  formInputs,
  handleInputChange,
}) => (
  <form onSubmit={(e) => handleSubmit(e)} className="py-7 px-5 overflow-auto">
    <ProgressBar progress={formProgress} tw="mb-5" />
    <Input
      disabled={disableInputs}
      name="login_to_your_account"
      label="Login to your account"
      placeholder="Login to your account"
      tw="mb-5"
      hideIndicators
      value={formInputs.login_to_your_account}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="email_address"
      label="Email Address"
      placeholder="Email Address"
      tw="mb-5"
      hideIndicators
      value={formInputs.email_address}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="login_email_placeholder"
      label="Enter your email address"
      placeholder="Enter your email address"
      tw="mb-5"
      hideIndicators
      value={formInputs.login_email_placeholder}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="password"
      label="Password"
      placeholder="Password"
      tw="mb-5"
      hideIndicators
      value={formInputs.password}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="login_password_placeholder"
      label="Enter password"
      placeholder="Enter password"
      tw="mb-5"
      hideIndicators
      value={formInputs.login_password_placeholder}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="forgot_password"
      label="Forgot Password?"
      placeholder="Forgot Password?"
      tw="mb-5"
      hideIndicators
      value={formInputs.forgot_password}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="login"
      label="Log in"
      placeholder="Log in"
      tw="mb-5"
      hideIndicators
      value={formInputs.login}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="login_with_email"
      label="Login with Email"
      placeholder="Login with Email"
      tw="mb-5"
      hideIndicators
      value={formInputs.login_with_email}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="login_with_password"
      label="Login with Password"
      placeholder="Login with Password"
      tw="mb-5"
      hideIndicators
      value={formInputs.login_with_password}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="continue_with"
      label="Continue with"
      placeholder="Continue with"
      tw="mb-5"
      hideIndicators
      value={formInputs.continue_with}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="or"
      label="or"
      placeholder="or"
      tw="mb-5"
      hideIndicators
      value={formInputs.or}
      onChange={handleInputChange}
    />

    <Input
      disabled={disableInputs}
      name="login_verification_code"
      label="Verification Code"
      placeholder="Verification Code"
      tw="mb-5"
      hideIndicators
      value={formInputs.login_verification_code}
      onChange={handleInputChange}
    />

    <Textarea
      disabled={disableInputs}
      name="login_passwordless_instructions"
      label="Check your inbox and paste in the verification code below."
      placeholder="Check your inbox and paste in the verification code below."
      rows={2}
      tw="mb-5"
      value={formInputs.login_passwordless_instructions}
      required
      onChange={handleInputChange}
    />

    <Input
      disabled={disableInputs}
      name="login_verify_email"
      label="Verify Email"
      placeholder="Verify Email"
      tw="mb-5"
      hideIndicators
      value={formInputs.login_verify_email}
      onChange={handleInputChange}
    />
  </form>
);
