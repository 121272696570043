import { Card } from "components/Card/Card";
import styled from "styled-components";
import tw, { css } from "twin.macro";

export const StyledCard = styled(Card)(() => [
  tw`shadow-sm`,
  ({ theme: { colors } }) => css`
    position: relative;
    padding: 8px 12px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    border-color: ${colors.grey200};
    & .hoverState {
      display: none;
      opacity: 0;
      transition: opacity 0.1s ease-in;
    }
    &:hover .hoverState {
      display: block;
      opacity: 1;
    }
  `,
]);

export const PlansContainer = styled.div`
  p::after {
    content: "\a";
    white-space: pre;
  }
`;
