import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Types.Maybe<(
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { profile: (
      { __typename?: 'UserProfile' }
      & Pick<Types.UserProfile, 'firstName' | 'lastName'>
    ), auth: (
      { __typename?: 'UserAuth' }
      & Pick<Types.UserAuth, 'commandBarHMAC' | 'email' | 'connectedToGoogle'>
      & { twoFactorSettings?: Types.Maybe<(
        { __typename?: 'UserTwoFactorSettings' }
        & Pick<Types.UserTwoFactorSettings, 'enabled' | 'validated' | 'backupCodes'>
      )> }
    ), session?: Types.Maybe<(
      { __typename?: 'UserSession' }
      & Pick<Types.UserSession, 'acr' | 'connectedToWebflow'>
    )>, appConnections?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'UserAppConnection' }
      & Pick<Types.UserAppConnection, 'role'>
      & { app: (
        { __typename?: 'App' }
        & Pick<Types.App, 'id' | 'name' | 'status' | 'stack' | 'stripeSubscriptionStatus' | 'stripePriceId' | 'stripeAppFeePercent' | 'image' | 'webflowSiteId' | 'webflowSiteName'>
        & { branding: (
          { __typename?: 'Branding' }
          & { colors: (
            { __typename?: 'Colors' }
            & { light: (
              { __typename?: 'BrandingColors' }
              & Pick<Types.BrandingColors, 'primaryButton'>
            ) }
          ) }
        ) }
      ) }
    )>>> }
  )> }
);

export type UpdateUserProfileMutationVariables = Types.Exact<{
  input: Types.UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { auth: (
      { __typename?: 'UserAuth' }
      & Pick<Types.UserAuth, 'email'>
    ), profile: (
      { __typename?: 'UserProfile' }
      & Pick<Types.UserProfile, 'firstName' | 'lastName'>
    ) }
  ) }
);

export type UpdateUserAuthMutationVariables = Types.Exact<{
  input: Types.UpdateUserAuthInput;
}>;


export type UpdateUserAuthMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAuth: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type ResetUserPasswordMutationVariables = Types.Exact<{
  input: Types.UserResetPasswordInput;
}>;


export type ResetUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetUserPassword: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type SendUserResetPasswordEmailMutationVariables = Types.Exact<{
  input: Types.UserResetPasswordEmailInput;
}>;


export type SendUserResetPasswordEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'sendUserResetPasswordEmail'>
);

export type ConnectUserToGoogleMutationVariables = Types.Exact<{
  input: Types.LoginUserGoogleInput;
}>;


export type ConnectUserToGoogleMutation = (
  { __typename?: 'Mutation' }
  & { connectUserToGoogle: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type DisconnectUserFromGoogleMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DisconnectUserFromGoogleMutation = (
  { __typename?: 'Mutation' }
  & { disconnectUserFromGoogle: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type VerifyUrlQueryVariables = Types.Exact<{
  url: Types.Scalars['String'];
}>;


export type VerifyUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getWebflowURLVerification'>
);

export type GetUsersQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>;
  order?: Types.Maybe<Types.OrderByInput>;
  first?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers?: Types.Maybe<(
    { __typename?: 'UserConnection' }
    & { edges: Array<(
      { __typename?: 'UserEdge' }
      & Pick<Types.UserEdge, 'cursor'>
      & { node: (
        { __typename?: 'AppUserConnection' }
        & Pick<Types.AppUserConnection, 'role'>
        & { user: (
          { __typename?: 'User' }
          & Pick<Types.User, 'id'>
          & { auth: (
            { __typename?: 'UserAuth' }
            & Pick<Types.UserAuth, 'email'>
          ), profile: (
            { __typename?: 'UserProfile' }
            & Pick<Types.UserProfile, 'firstName' | 'lastName'>
          ) }
        ) }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor' | 'totalCount'>
    ) }
  )> }
);


export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    profile {
      firstName
      lastName
    }
    auth {
      commandBarHMAC
      email
      connectedToGoogle
      twoFactorSettings {
        enabled
        validated
        backupCodes
      }
    }
    session {
      acr
      connectedToWebflow
    }
    appConnections {
      role
      app {
        id
        name
        status
        stack
        stripeSubscriptionStatus
        stripePriceId
        stripeAppFeePercent
        branding {
          colors {
            light {
              primaryButton
            }
          }
        }
        image
        webflowSiteId
        webflowSiteName
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    id
    auth {
      email
    }
    profile {
      firstName
      lastName
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateUserAuthDocument = gql`
    mutation UpdateUserAuth($input: UpdateUserAuthInput!) {
  updateUserAuth(input: $input) {
    id
  }
}
    `;
export type UpdateUserAuthMutationFn = Apollo.MutationFunction<UpdateUserAuthMutation, UpdateUserAuthMutationVariables>;

/**
 * __useUpdateUserAuthMutation__
 *
 * To run a mutation, you first call `useUpdateUserAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAuthMutation, { data, loading, error }] = useUpdateUserAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAuthMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAuthMutation, UpdateUserAuthMutationVariables>) {
        return Apollo.useMutation<UpdateUserAuthMutation, UpdateUserAuthMutationVariables>(UpdateUserAuthDocument, baseOptions);
      }
export type UpdateUserAuthMutationHookResult = ReturnType<typeof useUpdateUserAuthMutation>;
export type UpdateUserAuthMutationResult = Apollo.MutationResult<UpdateUserAuthMutation>;
export type UpdateUserAuthMutationOptions = Apollo.BaseMutationOptions<UpdateUserAuthMutation, UpdateUserAuthMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation ResetUserPassword($input: UserResetPasswordInput!) {
  resetUserPassword(input: $input) {
    id
  }
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, baseOptions);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const SendUserResetPasswordEmailDocument = gql`
    mutation SendUserResetPasswordEmail($input: UserResetPasswordEmailInput!) {
  sendUserResetPasswordEmail(input: $input)
}
    `;
export type SendUserResetPasswordEmailMutationFn = Apollo.MutationFunction<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>;

/**
 * __useSendUserResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendUserResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserResetPasswordEmailMutation, { data, loading, error }] = useSendUserResetPasswordEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUserResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>) {
        return Apollo.useMutation<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>(SendUserResetPasswordEmailDocument, baseOptions);
      }
export type SendUserResetPasswordEmailMutationHookResult = ReturnType<typeof useSendUserResetPasswordEmailMutation>;
export type SendUserResetPasswordEmailMutationResult = Apollo.MutationResult<SendUserResetPasswordEmailMutation>;
export type SendUserResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>;
export const ConnectUserToGoogleDocument = gql`
    mutation ConnectUserToGoogle($input: LoginUserGoogleInput!) {
  connectUserToGoogle(input: $input) {
    id
  }
}
    `;
export type ConnectUserToGoogleMutationFn = Apollo.MutationFunction<ConnectUserToGoogleMutation, ConnectUserToGoogleMutationVariables>;

/**
 * __useConnectUserToGoogleMutation__
 *
 * To run a mutation, you first call `useConnectUserToGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserToGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserToGoogleMutation, { data, loading, error }] = useConnectUserToGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectUserToGoogleMutation(baseOptions?: Apollo.MutationHookOptions<ConnectUserToGoogleMutation, ConnectUserToGoogleMutationVariables>) {
        return Apollo.useMutation<ConnectUserToGoogleMutation, ConnectUserToGoogleMutationVariables>(ConnectUserToGoogleDocument, baseOptions);
      }
export type ConnectUserToGoogleMutationHookResult = ReturnType<typeof useConnectUserToGoogleMutation>;
export type ConnectUserToGoogleMutationResult = Apollo.MutationResult<ConnectUserToGoogleMutation>;
export type ConnectUserToGoogleMutationOptions = Apollo.BaseMutationOptions<ConnectUserToGoogleMutation, ConnectUserToGoogleMutationVariables>;
export const DisconnectUserFromGoogleDocument = gql`
    mutation DisconnectUserFromGoogle {
  disconnectUserFromGoogle {
    id
  }
}
    `;
export type DisconnectUserFromGoogleMutationFn = Apollo.MutationFunction<DisconnectUserFromGoogleMutation, DisconnectUserFromGoogleMutationVariables>;

/**
 * __useDisconnectUserFromGoogleMutation__
 *
 * To run a mutation, you first call `useDisconnectUserFromGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectUserFromGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectUserFromGoogleMutation, { data, loading, error }] = useDisconnectUserFromGoogleMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectUserFromGoogleMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectUserFromGoogleMutation, DisconnectUserFromGoogleMutationVariables>) {
        return Apollo.useMutation<DisconnectUserFromGoogleMutation, DisconnectUserFromGoogleMutationVariables>(DisconnectUserFromGoogleDocument, baseOptions);
      }
export type DisconnectUserFromGoogleMutationHookResult = ReturnType<typeof useDisconnectUserFromGoogleMutation>;
export type DisconnectUserFromGoogleMutationResult = Apollo.MutationResult<DisconnectUserFromGoogleMutation>;
export type DisconnectUserFromGoogleMutationOptions = Apollo.BaseMutationOptions<DisconnectUserFromGoogleMutation, DisconnectUserFromGoogleMutationVariables>;
export const VerifyUrlDocument = gql`
    query verifyUrl($url: String!) {
  getWebflowURLVerification(url: $url)
}
    `;

/**
 * __useVerifyUrlQuery__
 *
 * To run a query within a React component, call `useVerifyUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useVerifyUrlQuery(baseOptions: Apollo.QueryHookOptions<VerifyUrlQuery, VerifyUrlQueryVariables>) {
        return Apollo.useQuery<VerifyUrlQuery, VerifyUrlQueryVariables>(VerifyUrlDocument, baseOptions);
      }
export function useVerifyUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyUrlQuery, VerifyUrlQueryVariables>) {
          return Apollo.useLazyQuery<VerifyUrlQuery, VerifyUrlQueryVariables>(VerifyUrlDocument, baseOptions);
        }
export type VerifyUrlQueryHookResult = ReturnType<typeof useVerifyUrlQuery>;
export type VerifyUrlLazyQueryHookResult = ReturnType<typeof useVerifyUrlLazyQuery>;
export type VerifyUrlQueryResult = Apollo.QueryResult<VerifyUrlQuery, VerifyUrlQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($after: String, $order: OrderByInput, $first: Int) {
  getUsers(after: $after, order: $order, first: $first) {
    edges {
      cursor
      node {
        user {
          id
          auth {
            email
          }
          profile {
            firstName
            lastName
          }
        }
        role
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      totalCount
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;