import { gql } from "@apollo/client";

export const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input) {
      url
    }
  }
`;

export const CREATE_BILLING_PORTAL = gql`
  mutation CreateBillingPortalSession(
    $input: CreateBillingPortalSessionInput!
  ) {
    createBillingPortalSession(input: $input) {
      url
    }
  }
`;
