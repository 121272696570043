import styled from "styled-components";

interface Props {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const PulsingDot = styled.div<Props>`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #2962ff;
  margin: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

  top: ${({ top }) => (top ? `${top}px` : "auto")};
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "auto")};
  left: ${({ left }) => (left ? `${left}px` : "auto")};
  right: ${({ right }) => (right ? `${right}px` : "auto")};

  z-index: 1000;
  transform: scale(1);
  animation: tourDot 2s infinite;

  @keyframes tourDot {
    0% {
      box-shadow: 0 0 0 0px rgba(41, 98, 255, 0.8);
    }
    100% {
      box-shadow: 0 0 0 25px rgba(41, 98, 255, 0);
    }
  }
`;
