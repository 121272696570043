import React, { useState } from "react";
import { Background, Button } from "components";
import { SetUp2FAModal } from "containers/2FactorAuth/SetUp2FAModal";
import { UserTwoFactorAuthPayload } from "generatedTypes";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { useSetupUser2FaMutation } from "features";

export const TwoFactorAuthRequired = () => {
  const [show2FAModal, setShow2FAModal] = useState(false);
  const [responseData, setResponseData] = useState<UserTwoFactorAuthPayload>({
    secret: "",
    url: "",
  });

  const [setUpUser2FA, { loading }] = useSetupUser2FaMutation();

  const { submit: handleSetup2FA } = useOnSubmit({
    action: setUpUser2FA,
    onSuccess: ({ data: { setupUser2FA } }) => setResponseData(setupUser2FA),
    cleanup: () => setShow2FAModal(true),
    errorMsg: "Unable to set up 2FA.",
    showToastProcessing: false,
  });

  return (
    <Background
      tw="w-full h-full"
      className="border border-app-gray100 shadow-regular box-border rounded-xl"
    >
      <div className="bg-white border border-app-gray100 shadow-lg rounded-lg">
        <div className="p-5">
          <h3 tw="text-h3 font-bold mb-1">
            Two-factor authentication required
          </h3>
          <p className="text-body-sm">
            This app requires all team members to enable
            <br /> two-factor authentication.
          </p>
        </div>
        <div className="p-5 border-t flex justify-between items-center">
          <div>
            <h3 tw="text-h3 font-bold mb-1">App authentication</h3>
            <p className="text-body-sm">
              Security codes will be generated by
              <br />
              your preferred authenticator app.
            </p>
          </div>
          <Button
            text="Set up"
            onClick={() => handleSetup2FA()}
            isDisabled={loading}
            isLoading={loading}
          />
        </div>
      </div>
      <SetUp2FAModal
        setShowModal={setShow2FAModal}
        showModal={show2FAModal}
        responseData={responseData}
        closeModal={() => setShow2FAModal(false)}
      />
    </Background>
  );
};
