import React, { useState, useEffect, useMemo } from "react";
import { CustomContent, CustomContentType } from "generatedTypes";
import { AccordionItem, Button, EmptyState, Link } from "components";
import { Add } from "@mui/icons-material";
import HostedContentEntry from "./HostedContentEntry";
import {
  formatEntriesFromBackend,
  formatEntries,
  formatEntry,
} from "./hosted-contents-utils";

type EntryType = Omit<CustomContent, "id" | "restrictedUrlGroupId" | "key"> & {
  id?: string;
};

const emptyCustomContent = {
  name: "",
  content: "",
  type: CustomContentType.Html,
  id: "1",
  key: "",
};

interface HostedContentProps {
  isEditMode?: boolean;
  customContents: CustomContent[];
  onChange: (entries: EntryType[]) => void;
  onAddContent?: (entries: EntryType) => void;
  onDeleteContent?: (id: string) => void;
  onUpdateContent?: (entry: EntryType) => void;
  setCustomContents: React.Dispatch<React.SetStateAction<unknown[]>>;
}

const HostedContent = ({
  isEditMode = false,
  customContents,
  onChange,
  onAddContent,
  onDeleteContent,
  onUpdateContent,
  setCustomContents,
}: HostedContentProps) => {
  const [entries, setEntries] = useState([]);
  const defaultEntries = useMemo(
    () =>
      customContents.length ? formatEntriesFromBackend(customContents) : [],
    [customContents]
  );

  useEffect(() => {
    setEntries(defaultEntries);
  }, [defaultEntries]);

  // create a function that updates an entry
  const updateEntry = (index: number, entry) => {
    const newEntries = [...entries];
    newEntries[index] = entry;
    setEntries(newEntries);
    if (isEditMode) {
      onChange(formatEntries(newEntries, isEditMode));
      return onUpdateContent(formatEntry(entry, "update"));
    }
    return onChange(formatEntries(newEntries, isEditMode));
  };

  // create a function that removes an entry
  const removeEntry = (index: number, id: string) => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
    if (isEditMode) {
      onChange(formatEntries(newEntries, isEditMode));
      return onDeleteContent(id);
    }
    return onChange(formatEntries(newEntries, isEditMode));
  };

  // create a function to add a new entry
  const addEntry = (entry) => {
    const updatedEntry = [entry, ...entries];
    const formattedEntries = formatEntries(updatedEntry, isEditMode);
    setEntries(updatedEntry);
    if (isEditMode) {
      onChange(formattedEntries);
      return onAddContent(formatEntry(entry, "create"));
    }
    return onChange(formattedEntries);
  };

  return (
    <AccordionItem
      label="Hosted Content"
      value="hosted-contents"
      rightComponent={
        <Button
          leftIcon={<Add />}
          text="Add"
          buttonStyle="skeleton"
          onClick={(e) => {
            e.stopPropagation();
            setCustomContents([...customContents, emptyCustomContent]);
          }}
        />
      }
    >
      <div>
        <p className="text-body-sm text-app-gray600 mb-4">
          Securely host text-based content inside Memberstack.{" "}
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/15074205669403-Hosted-Content"
            target="_blank"
            isExternal
            underline
          >
            Help Docs →
          </Link>
        </p>
        {customContents.length === 0 ? (
          <EmptyState
            text="Add Hosted Content"
            description="Securely host text-based content inside Memberstack."
            buttonText="Add Content"
            docsLink="https://docs.memberstack.com/hc/en-us/articles/15074205669403"
            onCreateClick={() =>
              setCustomContents([...customContents, emptyCustomContent])
            }
          />
        ) : (
          entries.map(({ type, name, content, id, key }, index) => (
            <HostedContentEntry
              mode={name ? "view" : "create"}
              customId={key}
              key={
                Date.now().toString(36) +
                Math.random().toString(36).substring(2)
              }
              name={name}
              id={id || index}
              type={type.label}
              content={content}
              updateEntry={(entry) => updateEntry(index, entry)}
              removeEntry={() => removeEntry(index, id)}
              addEntry={addEntry}
            />
          ))
        )}
      </div>
    </AccordionItem>
  );
};

export default HostedContent;
