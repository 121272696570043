import React from "react";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, ButtonProps } from "./Button";

type Props = Pick<ButtonProps, "text" | "noOutline" | "className"> & {
  value: string | number | readonly string[];
  message?: string;
  noPadding?: boolean;
  inverted?: boolean;
  isPulsing?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const CopyButton = ({
  value,
  text,
  message = "Text copied.",
  noOutline = true,
  className,
  noPadding,
  inverted,
  isPulsing,
  onClick,
}: Props) => {
  const { createToastAlert } = useToastAlertContext();

  const handleClick = async (e) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(value.toString());
      createToastAlert({
        alertType: "success",
        message,
      });
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: `Failed to copy: ${err}`,
      });
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      className={className}
      text={text}
      onClick={handleClick}
      leftIcon={
        <ContentCopyIcon className={inverted ? "white" : "text-black"} />
      }
      buttonStyle={inverted ? "inverted" : "skeleton"}
      noOutline={noOutline}
      noPadding={noPadding}
      dataCy="copy-button"
      dataTestId="copy-button"
      isPulsing={isPulsing}
    />
  );
};
