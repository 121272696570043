import { PlanConnection, PlanConnectionType } from "generatedTypes";
import React, { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import PlanStatusTag from "components/PlanStatusTag";
import { StyledCard } from "components/PermissionCard/styles";
import CreateIcon from "@mui/icons-material/Create";
import PauseIcon from "@mui/icons-material/Pause";
import { formatDate } from "helpers/formatDate";
import { isBefore } from "date-fns";
import { Button } from "components/Button";
import { useNavigate, useParams } from "react-router-dom";

type PlanCardType = Pick<
  PlanConnection,
  "status" | "payment" | "plan" | "team"
> & {
  onClick?: () => void;
  className?: string;
  type: PlanConnectionType;
  onEditTeam?: () => void;
};

const intervalTypeMap = {
  WEEKLY: "weeks",
  MONTHLY: "months",
};

export const PlanCard = ({
  payment,
  status,
  plan,
  onClick,
  className,
  type,
  team,
  onEditTeam,
}: PlanCardType) => {
  const navigate = useNavigate();
  const { appId } = useParams<{ appId: string }>();
  const displayCost = useMemo(() => {
    if (!payment) {
      return <>free</>;
    }
    return (
      <>
        <FormattedNumber
          value={payment.amount}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={payment.currency}
        />
        {type === PlanConnectionType.Onetime || payment.interval?.count === 1
          ? `/${
              type === PlanConnectionType.Onetime
                ? "onetime"
                : payment.interval?.type.toLowerCase()
            }`
          : ` every ${payment.interval?.count} ${
              intervalTypeMap[payment.interval?.type]
            }`}
      </>
    );
  }, [payment, type]);

  const renderCancelText = useMemo(() => {
    if (!payment?.cancelAtDate) {
      return null;
    }
    const cancelDate = new Date(payment?.cancelAtDate * 1000);
    if (isBefore(cancelDate, new Date())) {
      return `Canceled: ${formatDate(cancelDate, "dd/MM/yy")}`;
    }
    if (type === PlanConnectionType.Onetime) {
      return `Expires: ${formatDate(cancelDate, "dd/MM/yy")}`;
    }
    return `Cancels: ${formatDate(cancelDate, "dd/MM/yy")}`;
  }, [payment?.cancelAtDate, type]);

  return (
    <StyledCard
      onClick={() => onClick && onClick()}
      className={className}
      hasHoverState
    >
      <div className="flex items-center w-full justify-between">
        <p className="text-sm font-bold">{plan.name}</p>
        <div className="flex items-center gap-3">
          <PlanStatusTag showTooltip={false} status={status} />
          <CreateIcon fill="black" className="hoverState" tw="!h-4 !w-4" />
        </div>
      </div>
      <div className="flex items-center w-full justify-between">
        <p className="text-utility-tag-md font-semibold text-app-gray500 truncate w-custom-228 mr-3">
          {displayCost}&nbsp;
          {payment?.cancelAtDate && <>•&nbsp;{renderCancelText}</>}
          {payment?.pauseCollection && (
            <>
              <PauseIcon tw="w-4! h-4! mr-1" /> Paused until&nbsp;
              {payment?.pauseResumeAt &&
                formatDate(payment?.pauseResumeAt * 1000)}
            </>
          )}
        </p>
        {team?.isTeamPlan && (
          <div className="flex items-center gap-2 justify-between">
            {team.teamId && (
              <Button
                buttonStyle="transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  onEditTeam();
                }}
                text="Edit Team"
              />
            )}
            {team?.isTeamOwner ? (
              <p className="font-semibold text-utility-tag-md whitespace-nowrap text-app-gray600">
                Team Admin
              </p>
            ) : (
              <Button
                buttonStyle="transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/apps/${appId}/members/${team?.teamOwnerId}/connections`
                  );
                }}
                text="View Admin"
              />
            )}
          </div>
        )}
      </div>
    </StyledCard>
  );
};
