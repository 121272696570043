import React, { useCallback, useMemo, useState } from "react";
import template1 from "assets/images/real-estate-template.png";
import template2 from "assets/images/content-monetization-template.png";
import template3 from "assets/images/asset-marketplace-template.png";
import { Stack } from "generatedTypes";
import { useNavigate } from "react-router-dom";
import Input from "components/Input";
import { useCreateAppMutation } from "features/apps";
import { Modal } from "components/Modal";
import { Form, Label, Link, Switch } from "components";
import Spacer from "components/Spacer";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { useEnvContext } from "containers/App/contexts/Environment";
import { useUserContext } from "containers/Layouts/PrivateLayout/UserContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import Text from "components/Text";
import { getResponseError } from "helpers/getResponseError";

const options = [
  {
    label: "Webflow",
    value: Stack.Webflow,
  },
  {
    label: "React",
    value: Stack.React,
  },
  {
    label: "Other",
    value: Stack.Vanilla,
  },
];

const CreateAppModal = ({
  showModal,
  setShowModal = () => {},
  hideCancel = false,
  cancelButton,
  identifier,
}: {
  showModal: boolean;
  setShowModal?: (value) => void;
  hideCancel?: boolean;
  cancelButton?: { label: string; onClick?: () => void };
  identifier?: string;
}) => {
  const [templateId, setTemplateId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTemplateInfo, setShowTemplateInfo] = useState(false);
  const { createToastAlert } = useToastAlertContext();
  const [name, setName] = useState("");
  const [stack, setStack] = useState(options[0].value);
  const [create] = useCreateAppMutation();
  const { switchToSandbox } = useEnvContext();
  const { refetch } = useUserContext();
  const [modalError, setModalError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      createToastAlert({
        processing: true,
      });
      try {
        const {
          data: {
            createApp: { id },
          },
        } = await create({
          variables: {
            input: { name: name.trim() || identifier, stack, templateId },
          },
        });

        createToastAlert({
          alertType: "success",
          message: "App created successfully.",
        });
        switchToSandbox();
        setIsSubmitting(false);
        setShowModal(false);
        await refetch();
        navigate(`/apps/${id}/getting-started`);
      } catch (e) {
        setModalError(getResponseError(e));
      }
    },
    [
      create,
      name,
      identifier,
      setShowModal,
      stack,
      switchToSandbox,
      templateId,
      navigate,
      setModalError,
    ]
  );

  const createApp = useMemo(() => {
    return (
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title="Create a New App"
        showDivider
        width="494px"
        name={identifier || "create-app"}
        hideCancel={hideCancel}
        actionButtons={{
          confirm: {
            label: "Create App",
            onConfirm: handleSubmit,
            isLoading: isSubmitting,
            isDisabled: !isFormValid || !stack,
          },
          cancel: undefined || cancelButton,
        }}
        onErrorClose={() => setModalError(null)}
        errorMessage={modalError}
      >
        <Form onValidityCheck={setIsFormValid} className="flex gap-6">
          <Input
            required
            value={name}
            type="text"
            name="name"
            placeholder="e.g. SPI Community"
            data-testid="NewAppModal__name-input"
            onChange={(e) => setName(e.target.value)}
            minLength={4}
            maxLength={24}
            label="Name your App"
            description="Visible to members. You can change this later."
          />
          <div>
            <Label
              text="Where do you plan to install Memberstack?"
              hint="Your Memberstack dashboard will only show you functionality that’s compatible with your stack."
            />
            <div className="flex mt-1 w-full">
              {options.map((option, idx) => (
                <React.Fragment key={option.value}>
                  {idx ? <Spacer spacing="xSmall" isVertical={false} /> : null}
                  <button
                    type="button"
                    onClick={() => setStack(option.value)}
                    className={`p-3 rounded cursor-pointer border shadow-sm flex flex-1 justify-center ${option.value === stack ? "text-white bg-app-blue400" : ""}`}
                  >
                    <div className="relative">
                      {option.value === stack ? (
                        <CheckIcon tw="!h-4 !w-4 absolute -left-6" />
                      ) : null}
                      <Text className="text-utility-md">{option.label}</Text>
                    </div>
                  </button>
                </React.Fragment>
              ))}
            </div>
          </div>
          {stack === Stack.Webflow && (
            <>
              <Switch
                label="Start from template"
                onChange={(value) => setShowTemplateInfo(!showTemplateInfo)}
                id="templates"
                name="templates"
                isChecked={showTemplateInfo}
              />
              {showTemplateInfo && (
                <>
                  <div className="flex flex-col gap-1">
                    <p className="text-body-sm text-app-gray600">
                      If you’re using a Memberstack Webflow template you can add
                      it’s ID to enable pre-configured settings.{" "}
                      <Link
                        underline
                        target="_blank"
                        to="https://docs.memberstack.com/hc/en-us/articles/8463428799131"
                        rel="noreferrer"
                        isExternal
                      >
                        Learn more.
                      </Link>
                    </p>
                    <Input
                      tw="mt-2"
                      value={templateId}
                      onChange={(e) => setTemplateId(e.target.value)}
                      placeholder="e.g. MS-927363822"
                      description="Enter the template ID. e.g. template_238098120980m3"
                    />
                  </div>
                  <div className="p-3 rounded-md bg-app-gray50">
                    <div className="flex items-center justify-between mb-3">
                      <h2 className="text-base font-bold">
                        Browse Memberstack Templates
                      </h2>
                      <Link
                        to="https://www.memberstack.com/templates"
                        target="_blank"
                        rel="noreferrer"
                        isExternal
                        showAsLink
                        isBold
                      >
                        View all
                      </Link>
                    </div>
                    <div>
                      <div className="flex items-center gap-3 w-full">
                        <a
                          className="w-1/3 flex flex-col gap-2"
                          href="https://www.memberstack.com/webflow-templates/luxury-real-estate-rentals"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="h-24"
                            src={template1}
                            alt="Real Estate Template"
                          />
                          <div className="text-utility-sm">Real Estate</div>
                        </a>

                        <a
                          className="w-1/3 flex flex-col gap-2"
                          href="https://www.memberstack.com/webflow-templates/content-monetization-template"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="h-24"
                            src={template2}
                            alt="Content Monetization Template"
                          />
                          <div className="text-utility-sm">
                            Content Monetization
                          </div>
                        </a>
                        <a
                          className="w-1/3 flex flex-col gap-2"
                          href="https://www.memberstack.com/webflow-templates/asset-marketplace-template"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="h-24"
                            src={template3}
                            alt="Asset Marketplace Template"
                          />
                          <div className="text-utility-sm">
                            Asset Marketplace
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Form>
      </Modal>
    );
  }, [
    handleSubmit,
    hideCancel,
    isFormValid,
    isSubmitting,
    name,
    setShowModal,
    showModal,
    showTemplateInfo,
    stack,
    templateId,
    identifier,
    cancelButton,
  ]);

  return createApp;
};

export default CreateAppModal;
