import * as yup from "yup";

export const formValidationSchema = yup.object().shape({
  email: yup.string().when("code", {
    is: (code) => !code?.length,
    then: yup
      .string()
      .email("Invalid email")
      .required("Please provide an email."),
  }),
  password: yup.string().when("code", {
    is: (code) => !code?.length,
    then: yup
      .string()
      .min(8, "Must be at least 8 characters.")
      .required("No password provided."),
  }),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("No password provided.")
    .min(8, "Must be at least 8 characters."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match")
    .required("Confirm Password is required"),
});

export const createMemberSchema = yup.object().shape({
  email: yup.string().when("code", {
    is: (code) => !code?.length,
    then: yup
      .string()
      .email("Invalid email")
      .required("Please provide an email."),
  }),
  password: yup.string().when("code", {
    is: (code) => !code?.length,
    then: yup
      .string()
      .min(8, "Must be at least 8 characters.")
      .required("No password provided."),
  }),
  plans: yup
    .array()
    .min(1, "You have to select one or more plans!")
    .required("No password provided.")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
});

export const signupFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Please provide an email."),
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  password: yup
    .string()
    .min(8, "Must be at least 8 characters.")
    .required("No password provided."),
  consent: yup
    .boolean()
    .oneOf([true], "You must accept the term and conditions of use"),
});

// yup's default URL matcher is too strict.
const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/;

export const domainSchema = yup.object().shape({
  domain: yup
    .string()
    .matches(urlRegex, "Please provide a valid domain")
    .required("Please provide a domain."),
});
