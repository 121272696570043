export enum RouteName {
  abandonedCart = "abandoned-cart",
  apps = "apps",
  application = "application",
  authentication = "authentication",
  billing = "billing",
  community = "community",
  coupons = "coupons",
  customFields = "custom-fields",
  connectWebflow = "connect-webflow",
  commentingBilling = "commenting-billing",
  dashboard = "dashboard",
  developers = "developers",
  devtools = "devtools",
  editor = "editor",
  emails = "emails",
  emailVerification = "email-verification",
  eventLog = "event-log",
  gatedContent = "gated-content",
  gettingStarted = "getting-started",
  home = "/",
  helloAndWelcome = "hello-and-welcome",
  installCode = "install-code",
  integrations = "integrations",
  invoices = "invoices",
  login = "login",
  members = "members",
  passwordResetEmail = "password-reset-email",
  passwordlessEmail = "passwordless-email",
  permissions = "permissions",
  plan = "plan",
  redirects = "redirects",
  reset = "reset",
  settings = "settings",
  signup = "signup",
  stripeConnect = "stripe/callback",
  templates = "templates",
  webhooks = "devtools/webhooks",
  translations = "translations",
  errorMessages = "error-messages",
  resetPasswordRequest = "reset-password-request",
  translateLogin = "translate-login",
  changePassword = "change-password",
  translateAuth = "translate-auth",
  team = "team",
  authProviders = "auth-providers",
  design = "design",
  dangerZone = "danger-zone",
  stripeSettings = "stripe-settings",
  twoFactorAuthenticator = "two-factor-authenticator",
  notifications = "notifications",
  commentingNotification = "commenting-notification",
}
