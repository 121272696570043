import React from "react";
// import { OverflowList } from "react-overflow-list";
import { FolderOutlined, InsertDriveFileOutlined } from "@mui/icons-material";
import {
  FilterType,
  PlanConnectionStatus,
  RestrictedUrl,
} from "generatedTypes";
import Tag from "components/Tag";
import PlanStatusTag from "components/PlanStatusTag";
import { Switch } from "components/Switch";
import { Card } from "components/Card";
import styled from "styled-components";
import { CopyButton } from "../Button";

const FilterTypeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  column-gap: 4px;
  row-gap: 12px;
  margin-top: 12px;
  & svg {
    fill: #b47118;
  }
`;

const Container = styled(Card)`
  border: 1px solid;
  border-color: ${({ selected }) => (selected ? "#2962FF" : "#eebf8154")};
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.04),
    inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  :hover {
    background-color: #f8f8f8b4;
  }
`;

interface SwitchT {
  isChecked: boolean;
  onChange?: (isChecked: boolean) => void;
  disabled?: boolean;
  id?: string;
}
interface Props {
  onClick?: () => void;
  name: string;
  urls?: RestrictedUrl[];
  contentId: string;
  plans?: { id: string; name: string }[];
  className?: string;
  switchProps?: SwitchT;
  dataCy?: string;
  selected?: boolean;
}

const filterTypes = {
  [FilterType.Starts]: "Starting with",
  [FilterType.Equals]: "Equal to",
};

export const GatedContentCard = ({
  onClick,
  name,
  contentId,
  urls = [],
  plans = [],
  switchProps = null,
  className,
  dataCy,
  selected,
}: Props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };
  return (
    <Container
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e)}
      className={className}
      data-testid="gatedContentContainer"
      data-cy={dataCy}
      selected={selected}
    >
      <div tw="flex justify-between items-center">
        <div className="flex flex-wrap items-center justify-between w-full">
          {switchProps ? (
            <div className="flex items-center">
              <Switch
                isChecked={switchProps.isChecked}
                onChange={switchProps.onChange}
                disabled={switchProps.disabled}
                id={switchProps.id}
                tw="mr-3"
              />
              <h2 className="text-base font-bold">{name}</h2>
            </div>
          ) : (
            <h2 tw="text-base font-bold">{name}</h2>
          )}

          <CopyButton
            value={contentId}
            text={contentId}
            message="ID was successfully copied"
            inverted
            tw="font-medium! text-white! items-center h-[32px]"
          />
        </div>
      </div>
      {plans?.length > 0 && (
        <div className="flex flex-wrap justify-start items-center gap-1 mt-3">
          {plans.map((plan) => (
            <PlanStatusTag
              planName={plan.name}
              key={plan.id}
              status={PlanConnectionStatus.Trialing}
              showTooltip={false}
            />
          ))}
        </div>
      )}
      {urls?.length > 0 && (
        <FilterTypeContainer>
          {urls.map((url) => (
            <Tag
              key={url.id}
              size="medium"
              text={`${filterTypes[url.filter]} ${url.url}`}
              variant="tan"
              icon={
                url.filter === "EQUALS" ? (
                  <FolderOutlined tw="h-4! w-4!" />
                ) : (
                  <InsertDriveFileOutlined tw="h-4! w-4!" />
                )
              }
            />
          ))}
        </FilterTypeContainer>
      )}
    </Container>
  );
};
