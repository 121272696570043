import React from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import { Button, Background } from "components";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { useParams } from "react-router-dom";
import InputLoading from "components/Input/Input.loading";

export const HostedPagesLoader = ({ title, children }) => {
  const { appId } = useParams<{ appId: string }>();

  return (
    <>
      <SettingsBar>
        <TitleSection
          title={title}
          backLink={`/${appId}/settings/translations`}
          backLinkTitle="Translations"
          emphasize
          gutter="medium"
          buttons={<Button text="Save" onClick={undefined} />}
        />
        <form onSubmit={undefined} className="py-7 px-5">
          <ProgressBar progress={0} tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
        </form>
      </SettingsBar>
      <Background>
        <div tw="p-5">{children}</div>
      </Background>
    </>
  );
};
