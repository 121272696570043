import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  ContentWrapper,
  EmptyState,
  EnvSwitcher,
  Table,
} from "components";
import { useEnvContext } from "containers/App/contexts/Environment";
import SearchBar from "components/SearchBar";
import { useGetMemberEventsQuery } from "features/events";
import Tag from "components/Tag";
import { TagVariants } from "components/Tag/styles";
import { MemberEvent } from "generatedTypes";
import { debounce } from "lodash";
import useWebSockets from "hooks/useWebsockets";
import { formatDate } from "helpers/formatDate";
import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";
import { EventDetailsModal } from "./EventDetailsModal";
import {
  initialEventState,
  MapEventToTag,
  useEventFiltering,
} from "./event-log.util";
import EventLogSkeleton from "./EventLogSkeleton";

const EmptyEventLogIcon = lazy(
  () => import("assets/images/empty_eventlog2.svg")
);

const EventLog = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("memberId");
  const { env } = useEnvContext();
  const [showEventModal, setShowEventModal] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState<MemberEvent>(
    initialEventState as MemberEvent
  );
  const appWebSockets = useWebSockets();

  const {
    data,
    loading: lazyLoading,
    refetch,
    fetchMore,
  } = useGetMemberEventsQuery({
    variables: {
      first: 25,
    },
  });

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    refetch().then();
  }, [env]);

  useEffect(() => {
    if (appWebSockets) {
      try {
        appWebSockets.then((channel) => {
          channel.listen("dashboard.event", (_data) => {
            if (env === _data.env) {
              logInDevelopmentEnvironment(
                "🚨 Event log refetched: a new event was been logged"
              );
              refetch().then();
            }
          });
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [appWebSockets]);

  const memberId = new URLSearchParams(window.location.search).get("member_id");

  const { events, filterOptions, source } = useEventFiltering({
    refetch,
    searchValue,
    searchBy,
  });

  const handleSearch = useCallback(
    (inputValue) => {
      refetch({
        filters: {
          eventTypes: events.current,
          sourceTypes: source.current,
          [searchBy]: inputValue,
        },
      });
    },
    [events, searchBy, source]
  );

  const debounceSearch = useCallback(debounce(handleSearch, 900), [searchBy]);

  const changeHandler = useCallback(
    (value) => {
      setSearchValue(value);
      debounceSearch(value);
    },
    [debounceSearch]
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => debounceSearch.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchByFilter = useCallback(
    (type) => {
      if (searchValue !== "") {
        refetch({
          filters: {
            eventTypes: events.current,
            sourceTypes: source.current,
            [type]: searchValue,
          },
        });
      }
      setSearchBy(type);
    },
    [events, searchValue, source]
  );

  useEffect(() => {
    if (memberId) {
      setSearchValue(memberId);
      fetchMore({ variables: { filters: { memberId } } });
    }
  }, [fetchMore, memberId]);

  const rows = useMemo(() => {
    if (data?.getMemberEvents?.edges.length < 25) {
      setHasMore(false);
    }
    return data?.getMemberEvents?.edges?.map((edge) => edge.node);
  }, [data?.getMemberEvents?.edges]);

  if (lazyLoading || !data) return <EventLogSkeleton />;

  const fetchMoreData = () => {
    if (hasMore) {
      return fetchMore({
        variables: {
          after: data?.getMemberEvents?.pageInfo?.endCursor,
        },
      });
    }

    return null;
  };

  const searchByOptions = [
    {
      text: "By Member ID",
      onClick: () => handleSearchByFilter("memberId"),
      dataCy: "search-by-member-id",
    },
    {
      text: "By Event ID",
      onClick: () => handleSearchByFilter("eventId"),
      dataCy: "search-by-event-id",
    },
  ];

  const content = () => {
    if (rows?.length === 0 && !searchValue) {
      return (
        <EmptyState
          isFullScreen
          text="Member Event Log"
          description="You will be able to see all Member events here e.g. Signups, Logins cancellations and more."
          svgImage={<EmptyEventLogIcon />}
        />
      );
    }
    if (rows?.length === 0 && searchValue) {
      return (
        <div className="">
          <p className="text-gray-500 p-5 text-base">No events found</p>
        </div>
      );
    }

    return (
      <Table
        isHeadersInteractive={false}
        dataTestId="event-log-table"
        fetchMoreData={fetchMoreData}
        hasMoreData={hasMore}
        numberOfData={rows?.length}
      >
        <thead>
          <tr>
            <th>Event</th>
            <th>Event ID</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, i) => {
            const { label, icon, variant } = MapEventToTag[row.event];
            return (
              <tr
                data-cy="event-row"
                id={row.id}
                key={row.id}
                onClick={() => {
                  setSelectedEvent(row);
                  setShowEventModal(true);
                }}
              >
                <td>
                  <Tag
                    text={label}
                    icon={icon}
                    variant={variant as TagVariants}
                    isClear
                  />
                </td>
                <td>{row.id}</td>
                <td>{formatDate(row.createdAt, "yyyy-LL-dd, K:m:s aaaa")}</td>
              </tr>
            );
          })}
        </tbody>

        {!rows && (
          <div className="">
            <p className="text-gray-500 p-5 text-base">No events found</p>
          </div>
        )}
      </Table>
    );
  };

  return (
    <>
      <ContentWrapper className="flex-col">
        <div className="p-5">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-h3 font-bold flex items-center">Event Log</h3>
            <EnvSwitcher onChangeMode={() => setSearchValue("")} />
          </div>
          <SearchBar
            value={searchValue}
            onChange={changeHandler}
            placeholder="Search by id"
            filterOptions={filterOptions}
            searchBy={searchByOptions}
            className="w-full"
          />
        </div>
        {content()}
      </ContentWrapper>
      <EventDetailsModal
        setShowModal={setShowEventModal}
        showModal={showEventModal}
        event={selectedEvent}
      />
    </>
  );
};

export default EventLog;
