import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { RouteName } from "enums/routes";
import { localStorageKeys } from "helpers/cacheIds";
import { useLogoutUserMutation } from "features";

const useAuth = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [logoutUserMutation] = useLogoutUserMutation();

  const logout = async () => {
    await logoutUserMutation();
    client.resetStore();
    window.localStorage.removeItem(localStorageKeys.appId);
    history.push(`/${RouteName.login}`);
  };

  return { logout };
};

export default useAuth;
