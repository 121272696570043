import React from "react";
import { Modal } from "components/Modal";
import { Background } from "components";
import CreateAccount from "./CreateAccount";

export const PreviewModal = ({ showModal, setShowModal, color, imageSrc }) => {
  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Preview"
      showDivider
      showBottomSection={false}
      removePadding
    >
      <Background className="p-10">
        <CreateAccount color={color} imageSrc={imageSrc?.dataURL || imageSrc} />
      </Background>
    </Modal>
  );
};
