import React, { useCallback, useState } from "react";
import { ArrowRight, ChevronLeft } from "@mui/icons-material";
import { removeItem, uniqueItem } from "helpers/string-manipulations";
import { CheckboxProps } from "../../Checkbox/Checkbox.types";
import { CheckboxList } from "../../Checkbox/CheckboxList";
import { useFilterContext } from "./Filter.context";
import { ExpandableSection, Item, FilterMenuItem } from "./Filter.styles";

interface Props {
  label: string;
  value: string;
  items: CheckboxProps[];
  onFilter: (items: string[]) => void;
}

function FilterItem({ label, value, items, onFilter }: Props) {
  const [hasNotification, setHasNotification] = useState(false);
  const { activeItem, setToggle, setActiveFilters, activeFilters } =
    useFilterContext();

  const handleOnToggle = useCallback(
    () => setToggle(value),
    [setToggle, value]
  );

  const handleOnFilter = useCallback(
    (checkedItems) => {
      onFilter(checkedItems);
      if (checkedItems.length > 0) {
        setHasNotification(true);
        setActiveFilters(uniqueItem([...activeFilters, label]));
      } else {
        setHasNotification(false);
        setActiveFilters(removeItem(label, activeFilters));
      }
    },
    [onFilter, setActiveFilters, activeFilters, label]
  );

  const isActive = activeItem === value;

  const showActive = () => {
    if (!activeItem) {
      return true;
    }
    return activeItem === value;
  };

  return (
    <Item selected={showActive()}>
      <FilterMenuItem
        data-testid="filter-menu-item"
        aria-controls={`${value}-panel`}
        aria-disabled="false"
        aria-expanded={value === activeItem}
        id={`${value}-header`}
        onClick={handleOnToggle}
        selected={value === activeItem}
      >
        {isActive && (
          <div className="chevron-left">
            <ChevronLeft />
          </div>
        )}
        <div className="flex items-center text-base capitalize">
          {hasNotification && (
            <div className="notification bg-app-blue400 rounded-full w-2 h-2 mr-2" />
          )}
          {label}
          <ArrowRight className="arrow-right" />
        </div>
      </FilterMenuItem>
      <ExpandableSection
        aria-hidden={!isActive}
        aria-labelledby={`${value}-header`}
        expanded={isActive}
        id={`${value}-panel`}
      >
        <CheckboxList
          checkboxes={items}
          type="regular"
          onCheck={handleOnFilter}
          enableSelectAll
        />
      </ExpandableSection>
    </Item>
  );
}

export default FilterItem;
