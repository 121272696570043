import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreatePermissionMutationVariables = Types.Exact<{
  input: Types.CreatePermissionInput;
}>;


export type CreatePermissionMutation = (
  { __typename?: 'Mutation' }
  & { createPermission: (
    { __typename?: 'Permission' }
    & Pick<Types.Permission, 'id' | 'name' | 'description'>
  ) }
);

export type UpdatePermissionMutationVariables = Types.Exact<{
  input: Types.UpdatePermissionInput;
}>;


export type UpdatePermissionMutation = (
  { __typename?: 'Mutation' }
  & { updatePermission: (
    { __typename?: 'Permission' }
    & Pick<Types.Permission, 'id' | 'name' | 'description'>
  ) }
);

export type DeletePermissionMutationVariables = Types.Exact<{
  input: Types.DeletePermissionInput;
}>;


export type DeletePermissionMutation = (
  { __typename?: 'Mutation' }
  & { deletePermission: (
    { __typename?: 'Permission' }
    & Pick<Types.Permission, 'id' | 'name' | 'description'>
  ) }
);

export type LinkPermissionsToPlanMutationVariables = Types.Exact<{
  input: Types.LinkPermissionsToPlanInput;
}>;


export type LinkPermissionsToPlanMutation = (
  { __typename?: 'Mutation' }
  & { linkPermissionsToPlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
    & { permissions?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Permission' }
      & Pick<Types.Permission, 'id'>
    )>>> }
  ) }
);

export type DetachPermissionFromPlanMutationVariables = Types.Exact<{
  input: Types.DetachPermissionFromPlanInput;
}>;


export type DetachPermissionFromPlanMutation = (
  { __typename?: 'Mutation' }
  & { detachPermissionFromPlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
    & { permissions?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Permission' }
      & Pick<Types.Permission, 'id'>
    )>>> }
  ) }
);

export type LinkPermissionsToMemberMutationVariables = Types.Exact<{
  input: Types.LinkPermissionsToMemberInput;
}>;


export type LinkPermissionsToMemberMutation = (
  { __typename?: 'Mutation' }
  & { linkPermissionsToMember: (
    { __typename?: 'Member' }
    & Pick<Types.Member, 'id'>
    & { permissions?: Types.Maybe<(
      { __typename?: 'Permissions' }
      & Pick<Types.Permissions, 'all' | 'manuallyGranted'>
      & { planInherited?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'PlanInheritedPermission' }
        & Pick<Types.PlanInheritedPermission, 'permission'>
        & { plans?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Plan' }
          & Pick<Types.Plan, 'id' | 'name'>
        )>>> }
      )>>> }
    )> }
  ) }
);

export type DetachPermissionFromMemberMutationVariables = Types.Exact<{
  input: Types.DetachPermissionFromMemberInput;
}>;


export type DetachPermissionFromMemberMutation = (
  { __typename?: 'Mutation' }
  & { detachPermissionFromMember: (
    { __typename?: 'Member' }
    & Pick<Types.Member, 'id'>
  ) }
);


export const CreatePermissionDocument = gql`
    mutation CreatePermission($input: CreatePermissionInput!) {
  createPermission(input: $input) {
    id
    name
    description
  }
}
    `;
export type CreatePermissionMutationFn = Apollo.MutationFunction<CreatePermissionMutation, CreatePermissionMutationVariables>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePermissionMutation, CreatePermissionMutationVariables>) {
        return Apollo.useMutation<CreatePermissionMutation, CreatePermissionMutationVariables>(CreatePermissionDocument, baseOptions);
      }
export type CreatePermissionMutationHookResult = ReturnType<typeof useCreatePermissionMutation>;
export type CreatePermissionMutationResult = Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<CreatePermissionMutation, CreatePermissionMutationVariables>;
export const UpdatePermissionDocument = gql`
    mutation UpdatePermission($input: UpdatePermissionInput!) {
  updatePermission(input: $input) {
    id
    name
    description
  }
}
    `;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<UpdatePermissionMutation, UpdatePermissionMutationVariables>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>) {
        return Apollo.useMutation<UpdatePermissionMutation, UpdatePermissionMutationVariables>(UpdatePermissionDocument, baseOptions);
      }
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>;
export const DeletePermissionDocument = gql`
    mutation DeletePermission($input: DeletePermissionInput!) {
  deletePermission(input: $input) {
    id
    name
    description
  }
}
    `;
export type DeletePermissionMutationFn = Apollo.MutationFunction<DeletePermissionMutation, DeletePermissionMutationVariables>;

/**
 * __useDeletePermissionMutation__
 *
 * To run a mutation, you first call `useDeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionMutation, { data, loading, error }] = useDeletePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePermissionMutation(baseOptions?: Apollo.MutationHookOptions<DeletePermissionMutation, DeletePermissionMutationVariables>) {
        return Apollo.useMutation<DeletePermissionMutation, DeletePermissionMutationVariables>(DeletePermissionDocument, baseOptions);
      }
export type DeletePermissionMutationHookResult = ReturnType<typeof useDeletePermissionMutation>;
export type DeletePermissionMutationResult = Apollo.MutationResult<DeletePermissionMutation>;
export type DeletePermissionMutationOptions = Apollo.BaseMutationOptions<DeletePermissionMutation, DeletePermissionMutationVariables>;
export const LinkPermissionsToPlanDocument = gql`
    mutation linkPermissionsToPlan($input: LinkPermissionsToPlanInput!) {
  linkPermissionsToPlan(input: $input) {
    id
    permissions {
      id
    }
  }
}
    `;
export type LinkPermissionsToPlanMutationFn = Apollo.MutationFunction<LinkPermissionsToPlanMutation, LinkPermissionsToPlanMutationVariables>;

/**
 * __useLinkPermissionsToPlanMutation__
 *
 * To run a mutation, you first call `useLinkPermissionsToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPermissionsToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPermissionsToPlanMutation, { data, loading, error }] = useLinkPermissionsToPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkPermissionsToPlanMutation(baseOptions?: Apollo.MutationHookOptions<LinkPermissionsToPlanMutation, LinkPermissionsToPlanMutationVariables>) {
        return Apollo.useMutation<LinkPermissionsToPlanMutation, LinkPermissionsToPlanMutationVariables>(LinkPermissionsToPlanDocument, baseOptions);
      }
export type LinkPermissionsToPlanMutationHookResult = ReturnType<typeof useLinkPermissionsToPlanMutation>;
export type LinkPermissionsToPlanMutationResult = Apollo.MutationResult<LinkPermissionsToPlanMutation>;
export type LinkPermissionsToPlanMutationOptions = Apollo.BaseMutationOptions<LinkPermissionsToPlanMutation, LinkPermissionsToPlanMutationVariables>;
export const DetachPermissionFromPlanDocument = gql`
    mutation detachPermissionFromPlan($input: DetachPermissionFromPlanInput!) {
  detachPermissionFromPlan(input: $input) {
    id
    permissions {
      id
    }
  }
}
    `;
export type DetachPermissionFromPlanMutationFn = Apollo.MutationFunction<DetachPermissionFromPlanMutation, DetachPermissionFromPlanMutationVariables>;

/**
 * __useDetachPermissionFromPlanMutation__
 *
 * To run a mutation, you first call `useDetachPermissionFromPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachPermissionFromPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachPermissionFromPlanMutation, { data, loading, error }] = useDetachPermissionFromPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachPermissionFromPlanMutation(baseOptions?: Apollo.MutationHookOptions<DetachPermissionFromPlanMutation, DetachPermissionFromPlanMutationVariables>) {
        return Apollo.useMutation<DetachPermissionFromPlanMutation, DetachPermissionFromPlanMutationVariables>(DetachPermissionFromPlanDocument, baseOptions);
      }
export type DetachPermissionFromPlanMutationHookResult = ReturnType<typeof useDetachPermissionFromPlanMutation>;
export type DetachPermissionFromPlanMutationResult = Apollo.MutationResult<DetachPermissionFromPlanMutation>;
export type DetachPermissionFromPlanMutationOptions = Apollo.BaseMutationOptions<DetachPermissionFromPlanMutation, DetachPermissionFromPlanMutationVariables>;
export const LinkPermissionsToMemberDocument = gql`
    mutation linkPermissionsToMember($input: LinkPermissionsToMemberInput!) {
  linkPermissionsToMember(input: $input) {
    id
    permissions {
      all
      planInherited {
        permission
        plans {
          id
          name
        }
      }
      manuallyGranted
    }
  }
}
    `;
export type LinkPermissionsToMemberMutationFn = Apollo.MutationFunction<LinkPermissionsToMemberMutation, LinkPermissionsToMemberMutationVariables>;

/**
 * __useLinkPermissionsToMemberMutation__
 *
 * To run a mutation, you first call `useLinkPermissionsToMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPermissionsToMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPermissionsToMemberMutation, { data, loading, error }] = useLinkPermissionsToMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkPermissionsToMemberMutation(baseOptions?: Apollo.MutationHookOptions<LinkPermissionsToMemberMutation, LinkPermissionsToMemberMutationVariables>) {
        return Apollo.useMutation<LinkPermissionsToMemberMutation, LinkPermissionsToMemberMutationVariables>(LinkPermissionsToMemberDocument, baseOptions);
      }
export type LinkPermissionsToMemberMutationHookResult = ReturnType<typeof useLinkPermissionsToMemberMutation>;
export type LinkPermissionsToMemberMutationResult = Apollo.MutationResult<LinkPermissionsToMemberMutation>;
export type LinkPermissionsToMemberMutationOptions = Apollo.BaseMutationOptions<LinkPermissionsToMemberMutation, LinkPermissionsToMemberMutationVariables>;
export const DetachPermissionFromMemberDocument = gql`
    mutation detachPermissionFromMember($input: DetachPermissionFromMemberInput!) {
  detachPermissionFromMember(input: $input) {
    id
  }
}
    `;
export type DetachPermissionFromMemberMutationFn = Apollo.MutationFunction<DetachPermissionFromMemberMutation, DetachPermissionFromMemberMutationVariables>;

/**
 * __useDetachPermissionFromMemberMutation__
 *
 * To run a mutation, you first call `useDetachPermissionFromMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachPermissionFromMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachPermissionFromMemberMutation, { data, loading, error }] = useDetachPermissionFromMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachPermissionFromMemberMutation(baseOptions?: Apollo.MutationHookOptions<DetachPermissionFromMemberMutation, DetachPermissionFromMemberMutationVariables>) {
        return Apollo.useMutation<DetachPermissionFromMemberMutation, DetachPermissionFromMemberMutationVariables>(DetachPermissionFromMemberDocument, baseOptions);
      }
export type DetachPermissionFromMemberMutationHookResult = ReturnType<typeof useDetachPermissionFromMemberMutation>;
export type DetachPermissionFromMemberMutationResult = Apollo.MutationResult<DetachPermissionFromMemberMutation>;
export type DetachPermissionFromMemberMutationOptions = Apollo.BaseMutationOptions<DetachPermissionFromMemberMutation, DetachPermissionFromMemberMutationVariables>;