import React, { useMemo } from "react";
import { AccordionItem, Button } from "components";
import Tag from "components/Tag";
import AddIcon from "@mui/icons-material/Add";

interface Props {
  total?: number;
  onAdd?: () => void;
  onSave?: () => void;
  onReset?: () => void;
  children: React.ReactNode;
  label: string;
  value: string;
  buttonLoading?: boolean;
  isSaveDisabled?: boolean;
}

export const PlanAccordionItem = ({
  total,
  onAdd = undefined,
  onSave = undefined,
  onReset = undefined,
  children,
  label,
  value,
  buttonLoading = false,
  isSaveDisabled = false,
}: Props) => {
  const buttonPlugin = useMemo(() => {
    const handleClick = (e) => {
      e.stopPropagation();
      if (onAdd) {
        onAdd();
      }
      if (onSave) {
        onSave();
      }
    };
    if (onAdd) {
      return (
        <Button
          buttonStyle="skeleton"
          text="Add"
          leftIcon={<AddIcon />}
          onClick={(e) => handleClick(e)}
        />
      );
    }
    if (onSave) {
      return (
        <div className="flex items-center">
          {onReset && (
            <Button
              buttonStyle="skeleton"
              text="Reset"
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
              className="mr-2"
              isDisabled={isSaveDisabled || buttonLoading}
            />
          )}
          <Button
            buttonStyle="default"
            text="Save"
            onClick={(e) => handleClick(e)}
            isLoading={buttonLoading}
            isDisabled={isSaveDisabled}
          />
        </div>
      );
    }
    return null;
  }, [buttonLoading, isSaveDisabled, onAdd, onReset, onSave]);

  return (
    <AccordionItem
      label={label}
      value={value}
      rightComponent={total >= 0 && <Tag variant="secondary" text={total} />}
      ghostComponent={buttonPlugin}
      dataCy={`plan-${value}`}
    >
      {children}
    </AccordionItem>
  );
};
