import { useCallback, useMemo } from "react";

export const useFormTranslations = ({ form, setForm }) => {
  const formProgress = useMemo(
    () =>
      Math.floor(
        Math.abs(
          (Object.values(form).filter((val) => !val).length /
            Object.values(form).length) *
            100 -
            100
        ) / 10
      ) * 10,
    [form]
  );

  const handleInputChange = useCallback(
    ({ target: { value, name } }) => {
      setForm({ ...form, [name]: value });
    },
    [form]
  );

  return { formProgress, handleInputChange };
};
