import React, { useEffect, useMemo, useState } from "react";
import { Button, AttributeInstallationBox } from "components";
import { HandymanOutlined, Edit } from "@mui/icons-material";
import Popover from "components/Popover";
import { Tooltip } from "components/Tooltip";
import { CustomContentType } from "generatedTypes";
import { EntryInput } from "./EntryInput";
import { generateCustomContentId } from "./hosted-contents-utils";

const defaultEntry = {
  type: { label: "HTML", value: CustomContentType.Html },
  name: "",
  content: "",
};

type Modes = "view" | "edit" | "create";

interface Props {
  name: string;
  type: CustomContentType;
  content: string;
  id: string;
  customId: string;
  updateEntry?: (entry) => void;
  removeEntry?: () => void;
  addEntry?: (entry) => void;
  mode: Modes;
}

const HostedContentEntry = ({
  id,
  name,
  type,
  mode,
  content,
  customId,
  updateEntry,
  removeEntry,
  addEntry,
}: Props) => {
  const [internalMode, setInternalMode] = useState(mode);

  useEffect(() => {
    setInternalMode(mode);
  }, [mode]);

  const customIdDisplay = customId || generateCustomContentId(name);

  const InstallAttributes = useMemo(
    () => (
      <Popover
        buttonLeftIcon={
          <Tooltip
            content="Click to install"
            showArrow
            placement="left"
            interactive={false}
            offset={[0, 15]}
          >
            <HandymanOutlined className="text-white" />
          </Tooltip>
        }
        buttonStyle="inverted"
        panelSize={400}
        style={{ padding: "0", right: "0" }}
        className="mr-1"
      >
        <AttributeInstallationBox
          description="Use these data attributes to tag hosted content on your site. Memberstack will hide or show the element based on the member’s plans."
          docsLink="https://docs.memberstack.com/hc/en-us/articles/15074205669403"
          nameValue={`data-ms-secure-${type.toLowerCase()}`}
          idValue={customIdDisplay}
        />
      </Popover>
    ),
    [customIdDisplay, type]
  );

  if (internalMode === "edit") {
    return (
      <EntryInput
        editMode
        reset={() => setInternalMode("view")}
        currentEntry={{ name, type, content, id, key: customId }}
        updateEntry={(entry) => {
          setInternalMode("view");
          updateEntry(entry);
        }}
        removeEntry={() => {
          setInternalMode("view");
          removeEntry();
        }}
      />
    );
  }

  if (internalMode === "create") {
    return (
      <EntryInput
        addEntry={addEntry}
        updateEntry={(entry) => {
          setInternalMode("view");
          updateEntry(entry);
        }}
        // @ts-ignore
        currentEntry={defaultEntry}
      />
    );
  }

  return (
    <div className="flex items-center justify-between text-body-sm py-3 border-b cursor-default">
      <span>{customIdDisplay}</span>
      <div className="flex items-center">
        <span className="mr-4">{type}</span>
        {InstallAttributes}
        <Button
          leftIcon={<Edit />}
          buttonStyle="icon"
          onClick={() => setInternalMode("edit")}
        />
      </div>
    </div>
  );
};

export default HostedContentEntry;
