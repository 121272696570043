import { gql } from "@apollo/client";

export const GET_ERROR_MESSAGES = gql`
  query GetClientMessageText {
    getClientMessageText {
      textObject
    }
  }
`;

export const GET_SSO_MESSAGES = gql`
  query GetSSOText {
    getSSOText {
      textObject
    }
  }
`;

export const UPDATE_ERROR_MESSAGES = gql`
  mutation UpdateClientMessageText($input: UpdateClientMessageTextInput!) {
    updateClientMessageText(input: $input) {
      textObject
    }
  }
`;

export const UPDATE_SSO_MESSAGES = gql`
  mutation UpdateSSOText($input: UpdateSSOTextInput!) {
    updateSSOText(input: $input) {
      textObject
    }
  }
`;
