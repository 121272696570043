import React, { ReactNode } from "react";
import {
  ListItem,
  ListDescription,
  ListHeader,
  ListContent,
} from "./List.styles";

interface Props {
  children: React.ReactNode;
  className?: string;
}

interface ItemsProps {
  listText: string;
  children: ReactNode;
  inHouseStyled?: boolean;
  className?: string;
}

const Item = ({
  listText,
  children,
  inHouseStyled = true,
  className,
}: ItemsProps) => (
  <ListItem role="listitem" inHouseStyled={inHouseStyled} className={className}>
    <div>{listText}</div>
    <div>{children}</div>
  </ListItem>
);

export const List = ({ children, className }: Props) => (
  <div role="list" className={className}>
    {children}
  </div>
);

List.Header = ListHeader;
List.Description = ListDescription;
List.Item = Item;
List.Content = ListContent;
