import React from "react";
import { AttributeInstallationBox, CodeBlock } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { html } from "@codemirror/lang-html";

const WebflowCode = () => {
  const { appData } = useAppDataContext();

  const webflowHeaderCode = `<!-- Memberstack webflow package -->
<script data-memberstack-app="${appData?.id}" src="https://static.memberstack.com/scripts/v1/memberstack.js" type="text/javascript"></script>`;

  return (
    <>
      <div className="flex flex-col">
        <h3 className="text-h3 font-bold mb-1">Install Code</h3>
        <div className="mb-4">
          <p className="text-base mb-1">
            Copy and Paste the code snippet below into your Webflow site header.
          </p>
          <p className="text-base text-app-gray500">
            Note: Memberstack only works with sites that have an SSL/HTTPS
            enabled.
          </p>
        </div>
      </div>
      <CodeBlock
        value={webflowHeaderCode}
        readOnly
        extensions={[html()]}
        copyMessage="Header Code copied."
      />
      <div className="mt-4">
        <AttributeInstallationBox
          isLinkOnly
          docsLink="https://docs.memberstack.com/hc/en-us/articles/7253090768539-Install-Code"
        />
      </div>
    </>
  );
};

export default WebflowCode;
