import React, { Fragment } from "react";
import { DeleteOutline } from "@mui/icons-material";
import Input from "components/Input";
import Select, { OptionType } from "components/Select";
import Text from "components/Text";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Label } from "../Label/Label";
import { Button } from "../Button";

interface InputT {
  id?: string | number;
  value?: React.ReactText;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectValue?: OptionType;
  onSelect?: (selected: OptionType) => void;
  onInputDelete: () => void;
  options?: OptionType[];
  disabled?: boolean;
}

interface Props {
  className?: string;
  placeholder?: string;
  button?: React.ReactNode;
  errorMessage?: string;
  inputs: InputT[];
  hint?: string;
  label?: string;
  trailingDeleteButton?: boolean;
  addButtonPosition?: "bottom" | "nextToLabel";
  disableRemoveInput?: boolean;
  description?: React.ReactNode;
}

export const InputList = ({
  inputs,
  button,
  errorMessage,
  placeholder,
  hint,
  label,
  trailingDeleteButton = true,
  addButtonPosition = "nextToLabel",
  className,
  disableRemoveInput,
  description,
}: Props) => (
  <div className={className}>
    <div tw="flex justify-between items-center mb-1">
      <Label text={label} hint={hint} />
      {addButtonPosition === "nextToLabel" && button}
    </div>
    {description && (
      <p className="text-app-gray600 text-sm mb-2">{description}</p>
    )}
    {inputs.map(
      (
        {
          value,
          onChange,
          selectValue,
          onSelect,
          options,
          onInputDelete,
          id,
          disabled,
        },
        idx
      ) => (
        <div
          className={mergeClassNames(
            "flex items-center mb-2",
            idx < inputs.length && "mb-2"
          )}
          key={id}
        >
          <Input
            id={`${id}`}
            className="w-full"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...(selectValue
              ? {
                  leading: (
                    <Select
                      label=""
                      placeholder="Select"
                      value={selectValue}
                      onChange={onSelect}
                      options={options}
                      inline
                    />
                  ),
                }
              : {})}
            {...(trailingDeleteButton && {
              trailing: (
                <>
                  <Button
                    onClick={onInputDelete}
                    leftIcon={<DeleteOutline />}
                    buttonStyle="icon"
                    hideFocusedOutline
                    isDisabled={disableRemoveInput}
                    tw="pr-3"
                  />
                </>
              ),
            })}
            trailingInline
            disableTrailingPadding
            required
          />
          {!trailingDeleteButton && (
            <Button
              onClick={onInputDelete}
              leftIcon={<DeleteOutline />}
              buttonStyle="skeleton"
              tw="ml-2 h-11 w-11 items-center"
              isDisabled={disableRemoveInput}
            />
          )}
        </div>
      )
    )}

    {errorMessage && (
      <Text className="mt-2" noMargin type="utilitySmall" color="red2">
        {errorMessage}
      </Text>
    )}
    {addButtonPosition === "bottom" && button}
  </div>
);
