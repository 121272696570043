import styled from "styled-components";

export const SupportButton = styled.button`
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #070e27;
   color: #fff;
   box-shadow: 0 20px 20px -6px rgba(45, 62, 80, 0.25);
   border-radius: 50%;
   cursor: pointer;

   & > svg {
    height: 24px;
    width: 24px;
   }
`

export const Menu = styled.div<{isOpen: boolean}>`
  color: #070e27;
  display: ${({ isOpen }) => isOpen ? "block" : "none"};
  position: absolute;
  bottom: 60px;
  right: 0;
  width: max-content;
  min-width: 220px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dbe2eb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;

  & > div:first-child {
    padding-bottom: 0;
  }
`;

export const MenuGroup = styled.div`
  margin: 0;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const FindAnythingButton = styled.button`
  color: #6b6b6b;
  cursor: pointer;
  background-color: #f7f7f7;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px !important;
  font-size: 13px;
  transition: background-color .2s, border-color .2s;
  display: flex;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & > .find-anything-text {
    flex: 1;
    padding: 8px;
    text-align: left;
  }
  & > .help-command-k-icon {
    color: #070e27;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin-left: auto;
    margin-right: 4px;
    padding-left: 4px;
    padding-right: 4px;
    display: inline-block;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
  }
`;

export const MenuLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 8px;
  color: #2a2a2a;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: color 200ms ease;
  border-radius: 4px;
  & > svg {
    width: 18px;
  }
  & > span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-indent: 2px;
    color: #fff;
    background-color: #2962ff;
    padding: 4px;
    text-transform: uppercase;
    margin-left: auto;
    border-radius: 4px;
    font-size: 9px;
  }
  &:hover {
    color: #2962ff;
    background-color: rgba(41, 98, 255, .06);
  }
  &.ms-widget__link-group--small {
    color: #646464;
    font-size: .9em;
  }

  &.ms-widget__link-group--help-text {
    color: #a5a5a5;
    font-size: .8em;
  }

  &.ms-widget__link-group--help-text a:hover {
    background-color: transparent;
    color: #a5a5a5;
  }
`;