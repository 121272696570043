import React, { useState } from "react";
import { Modal, ModalTypes } from "components/Modal";
import { Checkbox, BackupCodeBlock } from "components";
import { useToastAlertContext } from "components/toastAlert";
import { useEnableUser2FaMutation } from "features";

interface Props extends ModalTypes {
  responseData: string[];
  closeModal: () => void;
}

export const SaveBackupCodeModal = ({
  showModal,
  setShowModal,
  responseData,
  closeModal,
}: Props) => {
  const [acknowledge, setAcknowledge] = useState(false);
  const { createToastAlert } = useToastAlertContext();

  const reloadPage = () => {
    window.location.reload();
  };

  const [enableUser2FA, { loading }] = useEnableUser2FaMutation({
    onCompleted: () => {
      createToastAlert({
        alertType: "success",
        message: "2FA was successfully enabled.",
      });
      setShowModal(false);
      reloadPage();
    },
    onError: () => {
      createToastAlert({
        alertType: "error",
        message: "Unable to enable 2FA.",
      });
      closeModal();
    },
  });

  const handleAuthentication = async () => {
    createToastAlert({ processing: true });
    await enableUser2FA();
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Save your backup codes"
      description="If you lose access to your authenticator method, backup codes can be used to access your user account. Each code can only be used once."
      showDivider
      actionButtons={{
        confirm: {
          label: "Enable 2FA",
          onConfirm: () => handleAuthentication(),
          isDisabled: !acknowledge,
          isLoading: loading,
        },
        cancel: { label: "Cancel" },
      }}
    >
      <form onSubmit={() => handleAuthentication()}>
        {responseData && <BackupCodeBlock backupCodes={responseData} />}

        <Checkbox
          label="I saved my backup codes"
          id="saveBackupCode"
          checked={acknowledge}
          name="saveBackupCode"
          required
          onChange={() => setAcknowledge(!acknowledge)}
          description="I understand that Memberstack cannot give me access to my user account if I lose my backup codes and access to my authentication methods."
        />

        <button
          type="submit"
          className="sr-only"
          onSubmit={() => handleAuthentication()}
        >
          Submit
        </button>
      </form>
    </Modal>
  );
};
