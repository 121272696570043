import React, { useCallback, useState } from "react";
import { useToastAlertContext } from "components/toastAlert";
import Text from "components/Text";
import Input from "components/Input";
import { Modal, ModalTypes } from "components/Modal";
import { Link } from "components";
import { useRemoveAllMembersMutation } from "features/members";
import { MemberstackEnv } from "generatedTypes";

interface Props extends ModalTypes {
  modeType: MemberstackEnv;
}

const DeleteAllMembersModal = ({
  showModal,
  setShowModal,
  modeType,
}: Props) => {
  const [inputValue, setInputValue] = useState("");

  const { createToastAlert } = useToastAlertContext();

  const textMap = {
    [MemberstackEnv.Live]: "LIVE",
    [MemberstackEnv.Sandbox]: "TEST",
  };

  const confirmationText = `delete ${textMap[modeType]} members`;

  const [removeAllMembers, { loading: removingMembers }] =
    useRemoveAllMembersMutation();

  const handleRemoveMembers = useCallback(async () => {
    createToastAlert({ processing: true });

    try {
      await removeAllMembers({
        variables: {
          input: {
            mode: modeType,
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message: `Deleting ${textMap[modeType]} Members in progress - it may take up to 10minutes to complete.`,
      });
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: err.message,
      });
    } finally {
      setShowModal(false);
      setInputValue("");
    }
  }, [modeType]);

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title={`Delete ${textMap[modeType]} Mode Members`}
      description="This action cannot be undone. Stripe data will not be affected."
      descriptionLink={
        <Link
          to="https://docs.memberstack.com/hc/en-us/articles/10834319418907"
          target="_blank"
          underline
          isExternal
        >
          Learn more
        </Link>
      }
      showDivider
      actionButtons={{
        confirm: {
          label: "Proceed with deletion",
          onConfirm: () => handleRemoveMembers(),
          isLoading: removingMembers,
          isDisabled: confirmationText !== inputValue,
          buttonStyle: "danger",
        },
      }}
    >
      <Text className="mb-1">
        Type <strong> {confirmationText} </strong> to delete these members and
        all associated data from Memberstack.
      </Text>
      <Input
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        hideIndicators
        placeholder={confirmationText}
      />
    </Modal>
  );
};

export default DeleteAllMembersModal;
