import { styled } from "twin.macro";
import BackGradient from "assets/images/gradient.png";

export const Background = styled.div`
  background: url(${BackGradient});
  background-size: cover;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;
