import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { PlansPage, PlanDetails, EditPlan, CreatePlan } from "containers/Plans";
import { CreatePlanPrice } from "containers/Plans/Plan/plan-settings/Prices/CreatePlanPrice";
import { EditPlanPrice } from "containers/Plans/Plan/plan-settings/Prices/EditPlanPrice";

export const PlanRoutes = () => {
  const { path } = useRouteMatch();
  const pages = [
    {
      exact: true,
      path: `${path}`,
      Component: PlanDetails,
    },
    {
      exact: true,
      path: `${path}/edit-plan`,
      Component: EditPlan,
    },
  ];
  return (
    <Switch>
      {pages.map(({ exact, path: routePath, Component }) => (
        <Route key={routePath} exact={exact} path={routePath}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};

export const PlansRoutes = () => {
  const { path } = useRouteMatch();

  const pages = [
    {
      exact: true,
      path: `${path}/create`,
      Component: CreatePlan,
    },
    {
      exact: true,
      path: `${path}/price/:priceId`,
      Component: EditPlanPrice,
    },
    {
      exact: true,
      path: `${path}/create-price`,
      Component: CreatePlanPrice,
    },
    {
      exact: false,
      path: `${path}`,
      Component: PlansPage,
    },
  ];

  return (
    <Switch>
      {pages.map(({ exact, path: routePath, Component }) => (
        <Route key={routePath} exact={exact} path={routePath}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};
