import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GET_PLAN,
  GetPlanQuery,
  useGetPlanLazyQuery,
  useUpdatePlanMutation,
} from "features/plans";
import { useToastAlertContext } from "components/toastAlert";
import { TitleSection } from "components/SettingsBar";
import Spacer from "components/Spacer";
import Input from "components/Input";
import { Textarea, Button, EmojiPicker, Link } from "components";
import ReplayIcon from "@mui/icons-material/Replay";
import TextCombo from "components/TextCombo";
import { ExpandLess, ExpandMore, Image, Upload } from "@mui/icons-material";
import styled from "styled-components";
import { Plan } from "generatedTypes";
import { EditPlanLoading } from "../../PlansSkeleton";

const AdvancedContainer = styled.div<{ show: boolean }>`
  ${({ show }) => !show && "display: none;"}
  font-size: 14px;
  line-height: 24px;
`;

interface PlanProps {
  plan: Plan;
}

const EditPlan = ({ plan }: PlanProps) => {
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [setShowAdvancedOptions, showAdvancedOptions] = useState(true);

  const { createToastAlert } = useToastAlertContext();
  const [updatePlan] = useUpdatePlanMutation({
    update(cache, { data }) {
      const planQuery = cache.readQuery<GetPlanQuery>({
        query: GET_PLAN,
      });
      cache.writeQuery({
        query: GET_PLAN,
        data: {
          getPlan: {
            ...planQuery?.getPlan,
            ...data?.updatePlan,
          },
        },
      });
    },
  });

  useEffect(() => {
    setName(plan.name);
    setDescription(plan.description);
    setIcon(plan.icon);
  }, [plan]);

  const wasPlanChanged =
    !(name === plan.name) ||
    !(description === plan.description) ||
    !(icon === plan.icon);

  const handleUpdate = async () => {
    const { id, permissions, allowedDomains } = plan;

    setIsSubmitting(true);
    createToastAlert({ processing: true });

    try {
      await updatePlan({
        variables: {
          input: {
            planId: id,
            icon,
            name,
            description,
            permissionIds: permissions.map((p) => p.id),
            allowedDomains,
          },
        },
      });

      createToastAlert({
        alertType: "success",
        message: "Plan was successfully saved.",
      });
    } catch (err) {
      createToastAlert({
        alertType: "success",
        message: err.graphQLErrors[0].message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const undoChanges = () => {
    setName(plan.name);
    setDescription(plan.description);
    setIcon(plan.icon);
  };

  const handleEmojiSelect = (emoji) => setIcon(emoji);

  const ListLink = ({ text, to }) => (
    <li>
      <Link to={to} target="_blank" isExternal underline tw="text-app-gray600">
        {text}
      </Link>
    </li>
  );

  return (
    <div className="w-1/2 h-full flex flex-col">
      <TitleSection
        title="Plan Information"
        buttons={
          <>
            {wasPlanChanged ? (
              <>
                <Button
                  leftIcon={<ReplayIcon />}
                  buttonStyle="icon"
                  isDisabled={!wasPlanChanged}
                  onClick={() => undoChanges()}
                />
                <Spacer spacing="xxSmall" isVertical={false} />
              </>
            ) : null}
            <Button
              text="Save"
              isLoading={isSubmitting}
              isDisabled={!wasPlanChanged}
              onClick={handleUpdate}
            />
          </>
        }
        emphasize
      />
      <div className="flex flex-col gap-4 p-5 overflow-auto">
        <TextCombo
          text={plan?.id || "planid"}
          hint="The price identifier"
          label="Plan ID"
          withCopy
          spaceOut
        />
        <Input
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          leading={<EmojiPicker icon={icon} onSelect={handleEmojiSelect} />}
          required
        />
        <Textarea
          label="Description"
          placeholder="Enter a plan description here"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required={false}
        />
        {plan?.isPaid && (
          <>
            <Button
              buttonStyle="transparent"
              text="Advanced"
              tw="justify-start overflow-visible"
              rightIcon={
                setShowAdvancedOptions ? <ExpandLess /> : <ExpandMore />
              }
              onClick={() => showAdvancedOptions(!setShowAdvancedOptions)}
            />
            <AdvancedContainer show={setShowAdvancedOptions}>
              <div className="flex justify-between items-center">
                <p className="font-bold">Plan Image</p>
                <p className="text-app-gray600">Optional</p>
              </div>
              <p className="text-app-gray600">
                You can add an image in Stripe.{" "}
                <Link
                  to="https://docs.memberstack.com/hc/en-us/articles/17712014688923#product-images"
                  target="_blank"
                  isExternal
                  underline
                  tw="text-app-gray600"
                >
                  Learn More
                </Link>
              </p>
              <div className="mt-3 mb-4 flex items-center">
                <div className="flex items-center p-3 border rounded bg-gray-50 mr-3 text-app-gray400">
                  <Image tw="w-8! h-8!" />
                </div>
                <Button
                  buttonStyle="skeleton"
                  text="Add an Image in Stripe"
                  leftIcon={<Upload />}
                  onClick={() =>
                    window.open(
                      "https://docs.memberstack.com/hc/en-us/articles/17712014688923#product-images",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className="flex justify-between items-center">
                <p className="font-bold">Additional Stripe Settings</p>
                <p className="text-app-gray600">Optional</p>
              </div>
              <p className="text-app-gray600">
                Access advanced plan settings within Stripe.
              </p>
              <ul className="text-app-gray600 ml-5 underline mt-1">
                <ListLink
                  text="Product Tax Category"
                  to="https://docs.memberstack.com/hc/en-us/articles/17712014688923#product-tax-category"
                />
                <ListLink
                  text="Statement Descriptor"
                  to="https://docs.memberstack.com/hc/en-us/articles/17712014688923#statement-descriptor"
                />
                <ListLink
                  text="Unit Label"
                  to="https://docs.memberstack.com/hc/en-us/articles/17712014688923#unit-label"
                />
              </ul>
            </AdvancedContainer>
          </>
        )}
      </div>
    </div>
  );
};

const EditPlanWrapper = () => {
  const { planId } = useParams<{ appId: string; planId: string }>();

  const [getPlans, { loading, data, error }] = useGetPlanLazyQuery({
    variables: {
      id: planId,
    },
  });

  useEffect(() => {
    if (planId) getPlans();
  }, [getPlans, planId]);

  if (loading || (!data && !data?.getPlan)) {
    return <EditPlanLoading />;
  }

  if (error) {
    return <div>Could not load plan</div>;
  }

  return <EditPlan plan={data.getPlan} />;
};

export default EditPlanWrapper;
