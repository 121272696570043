import React from "react";
import {
  Group,
  OfflineBoltOutlined,
  MonetizationOn,
  UnarchiveOutlined,
} from "@mui/icons-material";
import { NavLink, useParams, useResolvedPath } from "react-router-dom";
import { RouteName } from "enums/routes";
import useAppNavigator from "hooks/useAppNavigator";
import { messages } from "constants/messages";
import SearchBar from "components/SearchBar";
import useSearch from "hooks/useSearch";
import NoSearchResult from "components/SearchBar/NoSearchResult";
import cn from "helpers/cn";
import PlansOnboarding from "./PlansOnboarding";
import { usePlansContext } from "./context/Plans.context";

interface PlanListProps {
  type: "active" | "archived";
}

const linkClases: string =
  "rounded-lg shadow-sm px-4 py-3 flex justify-between items-center bg-white border border-app-gray200 hover:bg-transparent";

export const PlanList = ({ type }: PlanListProps) => {
  const isActivePlanType = type === "active";
  const { activePlans, archivedPlans } = usePlansContext();

  const { onNavigate, component } = useAppNavigator(null, messages.unsavedPlan);

  const plans = (isActivePlanType ? activePlans : archivedPlans).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const { appId } = useParams<{ appId: string; planId: string }>();
  const url = useResolvedPath("").pathname;

  const currentPath = url.substring(url.lastIndexOf("/") + 1);

  const { apps } = RouteName;

  const {
    searchString,
    setSearchString,
    output: searchedPlans,
  } = useSearch({
    input: plans,
    map: (cg) => cg?.name,
  });

  const renderIcons = (plan) => {
    if (isActivePlanType) {
      return plan?.isPaid ? (
        <MonetizationOn tw="text-app-blue400" />
      ) : (
        <OfflineBoltOutlined />
      );
    }
    return <UnarchiveOutlined />;
  };

  return (
    <>
      <div className="p-5 pb-0 bg-app-gray50">
        {plans.length > 5 && (
          <SearchBar
            value={searchString}
            onChange={(value) => setSearchString(value)}
          />
        )}
      </div>
      <div className="flex flex-col gap-3 p-5 overflow-y-auto bg-app-gray50 h-full">
        {isActivePlanType && (
          <NavLink
            to="redirects"
            onClick={onNavigate(`/${apps}/${appId}/plans/redirects`)}
            data-cy="redirects-card"
            className={({ isActive }) =>
              cn(
                linkClases,
                `${isActive && "outline outline-app-blue500 outline-2 border-transparent"}`,
                {
                  "bg-white":
                    ["plans", "redirects"].includes(currentPath) ||
                    !isActivePlanType,
                  "text-app-gray500": !isActivePlanType,
                }
              )
            }
          >
            <div className="flex items-center text-base gap-2">
              Default Settings
            </div>
            <OfflineBoltOutlined />
          </NavLink>
        )}
        {!isActivePlanType && plans.length === 0 && (
          <div className="flex items-center flex-col m-auto mt-32">
            <p className="font-bold text-h3 mb-1">No Archived Plans</p>
            <p className="text-body-sm text-app-gray500 text-center">
              Your archived plans will appear here.
            </p>
          </div>
        )}
        {plans.length === 0 && isActivePlanType && (
          <PlansOnboarding
            title="Applies to members without a Plan"
            description="These settings apply to all members by default. Create plans to change these settings for specific types of members. "
          />
        )}
        {plans.length !== 0 && searchedPlans?.length === 0 ? (
          <NoSearchResult onClearSearch={() => setSearchString("")} />
        ) : (
          searchedPlans?.map((plan) => (
            <NavLink
              to={plan.id}
              key={plan.id}
              onClick={onNavigate(`/${apps}/${appId}/plans/${plan.id}`)}
              data-cy="plan-card"
              className={({ isActive }) =>
                cn(
                  linkClases,
                  `${isActive ? "outline outline-app-blue500 outline-2 border-transparent" : ""}`,
                  {
                    "bg-white": isActive || !isActivePlanType,
                    "text-app-gray500": !isActivePlanType,
                  }
                )
              }
            >
              <div className="text-base truncate block">
                {plan.icon ? (
                  <span className="mr-2 inline-block">{plan.icon}</span>
                ) : null}
                {plan?.name}
              </div>
              <div className="flex items-center gap-2">
                {plan.memberCount ? (
                  <>
                    <Group />
                    <p className="text-base">{plan.memberCount}</p>
                  </>
                ) : null}
                {renderIcons(plan)}
              </div>
            </NavLink>
          ))
        )}
        {component}
      </div>
    </>
  );
};
