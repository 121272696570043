import {
  PriceType,
  PriceCurrency,
  PriceIntervalType,
  PriceExpirationInterval,
  PriceStatus,
  Price,
} from "generatedTypes";
import { PriceInputType } from "./plans.types";

export const DEFAULT_GROUP_STATE = {
  allowAllMembers: false,
  id: undefined,
  key: "",
  name: "",
  plans: [],
  redirect: "",
  urls: [],
  customContent: [],
};

export const DEFAULT_PRICE_INPUT = <PriceInputType>{
  amount: 0.0,
  name: "",
  active: true,
  type: PriceType.Subscription,
  description: "",
  currency: PriceCurrency.Usd,
  intervalType: PriceIntervalType.Monthly,
  intervalCount: 1,
  setupFeeAmount: 0.0,
  setupFeeName: "",
  freeTrialDays: 0,
  expirationCount: 0,
  expirationInterval: PriceExpirationInterval.Months,
  taxType: null,
  maxTeamMembers: 0,
};

export const DEFAULT_PRICE_STATE = <Price>{
  id: "",
  status: PriceStatus.Inactive,
  active: false,
  amount: 0,
  interval: {
    type: PriceIntervalType.Monthly,
    count: 0,
  },
  name: "",
  type: PriceType.Subscription,
  freeTrial: {
    enabled: false,
    days: null,
  },
  setupFee: {
    enabled: false,
    name: "",
    amount: null,
  },
  expiration: {
    count: 0,
    interval: PriceExpirationInterval.Months,
  },
  currency: PriceCurrency.Usd,
  memberCount: 0,
  taxType: null,
  isPersisted: false,
};

export const generateTabLabel = (word, length) =>
  `${word} ${length > 0 ? `(${length})` : ""}`;

/** formatLogicValues checks if `all` is available
 * in `defaultState.addedLogic.remove` and `defaultState.removedLogic.remove` object
 * if true then return `removeAllFree: true` and `cancelAllRecurring: true` respectively
 * else return the array without the `all` value in the array
 * */

export const formatLogicValues = (logic) => {
  // Create a new object without the __typename property
  const { __typename, ...newLogic } = logic;
  const isRemoveAll = newLogic.removeAllFree;
  const isCancelAll = newLogic.cancelAllRecurring;

  const removeValue = isRemoveAll
    ? { remove: [] }
    : { remove: newLogic.remove };

  const cancelValue = isCancelAll
    ? { cancelRecurring: [] }
    : { cancelRecurring: newLogic.cancelRecurring };

  return { ...newLogic, ...removeValue, ...cancelValue };
};

// getPlanLogicValues filters freePlans based on the logic state and returns the filtered array
export const getPlanLogicValues = (logic, plans, prices, logicType) => {
  const add = plans.filter((plan) => logic[logicType].add.includes(plan.value));
  const remove = plans.filter((plan) =>
    logic[logicType].remove.includes(plan.value)
  );
  const cancelRecurring = prices.filter((plan) =>
    logic[logicType].cancelRecurring.includes(plan.value)
  );
  return { add, remove, cancelRecurring };
};

// getPlanLogicOptions returns only freeplans not in the addedLogic array
export const getPlanLogicOptions = (logic, plans, logicType) => {
  const add = plans.filter(
    (plan) => !logic[logicType].add.includes(plan.value)
  );
  const remove = plans.filter(
    (plan) => !logic[logicType].remove.includes(plan.value)
  );
  return { add, remove };
};
