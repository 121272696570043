/* eslint-disable dot-notation */
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  components,
  ControlProps,
  OptionProps,
  StylesConfig,
} from "react-select";
import { ArrowDropDown, Check, ContentCopy } from "@mui/icons-material";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { Label } from "../Label/Label";
import { StyledSelect, useCreateStyles, Description } from "./styles";

export const SelectOption = (props: OptionProps) => {
  const { label, isSelected, data } = props;

  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between">
        <div className="flex items-center overflow-hidden">
          {data && data["avatar"] && (
            <img
              src={data["avatar"]}
              alt={data["avatar"]}
              className="flex-shrink-0 h-6 w-6 rounded-full mr-2"
            />
          )}
          <div aria-label={label} className="truncate text-utility-md">
            {label}
          </div>
        </div>
        {isSelected && (
          <span className="text-white flex items-center">
            <Check className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </div>
    </components.Option>
  );
};

const IndicatorsContainer = ({ children, ...props }: ControlProps) => {
  // @ts-ignore
  const { copyIcon, onCopy, showCopy } = props.selectProps;
  return (
    <components.IndicatorsContainer {...props}>
      {showCopy && (
        <span onMouseDown={onCopy} role="button" tabIndex={0}>
          {copyIcon}
        </span>
      )}
      {children}
    </components.IndicatorsContainer>
  );
};

export interface OptionType {
  label: string | number;
  value: string | number;
  type?: string;
}

interface SelectProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options: OptionType[];
  isDisabled?: boolean;
  isSearchable?: boolean;
  menuIsOpen?: boolean;
  errorMessage?: string;
  inline?: boolean;
  alternateDropdown?: boolean;
  hint?: string;
  inverted?: boolean;
  showCopy?: boolean;
  copyMessage?: string;
  preselectValue?: boolean;
  className?: string;
  description?: React.ReactNode;
  alwaysShowDescription?: boolean;
}

interface SingleProps extends SelectProps {
  isMulti: false;
  value?: OptionType;
  onChange?: (selected: OptionType) => void;
}

interface MultiProps extends SelectProps {
  isMulti: true;
  value?: OptionType[];
  onChange?: (selected: OptionType[]) => void;
}

const Select = ({
  id,
  label,
  placeholder,
  options,
  isMulti,
  onChange,
  value,
  isDisabled,
  isSearchable,
  menuIsOpen,
  errorMessage,
  inline,
  alternateDropdown,
  hint,
  inverted,
  showCopy,
  copyMessage,
  preselectValue,
  className,
  description,
  alwaysShowDescription = false,
}: SingleProps | MultiProps) => {
  const customStyles: StylesConfig<OptionProps> = useCreateStyles(
    menuIsOpen,
    inline,
    isDisabled,
    inverted
  );

  const [selectedValue, setSelectedValue] = useState<OptionType | OptionType[]>(
    null
  );

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isDisabled) return;
    setSelectedValue(value || options[0]);
  }, [isDisabled, options, value]);

  const { createToastAlert } = useToastAlertContext();

  const onClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore
    navigator.clipboard.writeText(selectedValue.value);
    createToastAlert({
      alertType: "success",
      message: copyMessage,
    });
  };

  const handleOnChange = useCallback(
    (values) => {
      onChange(values);
      setSelectedValue(values);
    },
    [onChange]
  );

  return (
    <div data-toggle="popover" data-trigger="focus" className={className}>
      {inline || !label ? null : (
        <>
          <Label htmlFor={label} text={label} hint={hint} className="mb-1" />
          {description && (alwaysShowDescription || isFocused) && (
            <Description
              $show={alwaysShowDescription || isFocused}
              animate={!alwaysShowDescription}
              className={alwaysShowDescription ? "mb-1" : ""}
              size={alwaysShowDescription ? "md" : "sm"}
            >
              {description}
            </Description>
          )}
        </>
      )}
      <StyledSelect
        // @ts-ignore
        copyIcon={
          <ContentCopy
            style={{ fontSize: 18 }}
            className={inverted && "text-app-gray200 fill-current"}
          />
        }
        showCopy={showCopy}
        onCopy={onClick}
        $inverted={inverted}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        isSearchable={isSearchable}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        components={{
          IndicatorsContainer,
          Option: SelectOption,
          ...(alternateDropdown
            ? // eslint-disable-next-line react/display-name
              {
                DropdownIndicator: () => (
                  <ArrowDropDown tw="mr-1 text-app-gray700" />
                ),
              }
            : {}),
        }}
        onChange={handleOnChange}
        value={preselectValue ? selectedValue : value}
        isDisabled={isDisabled}
        menuIsOpen={menuIsOpen}
        styles={customStyles}
        $inline={inline}
        isClearable={false}
        menuPlacement="auto"
        id={id}
        // menuPortalTarget={menuIsOpen ? null : document.body}
      />
      {!inline && errorMessage && (
        <p className="mt-2 text-sm text-red-600 font-medium">{errorMessage}</p>
      )}
    </div>
  );
};

export default Select;

Select.defaultProps = {
  placeholder: "",
  isMulti: false,
  onChange: () => undefined,
  value: undefined,
  isDisabled: false,
  isSearchable: false,
  menuIsOpen: undefined,
  errorMessage: undefined,
  inline: false,
  alternateDropdown: false,
};
