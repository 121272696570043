/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { UnsavedChanges } from "components/Modal/UnsavedChanges";
import { useAppDataContext } from "routes/AppDataContextProvider";

const useAppNavigator = (closeCallback?: () => void, message?: string) => {
  const history = useHistory();
  const { hasUnsavedChanges, setHasUnsavedChanges } = useAppDataContext();
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [link, setLink] = useState("");

  const onNavigate = useCallback(
    (_link) => (e) => {
      setLink(_link);
      e.preventDefault();
      if (hasUnsavedChanges) {
        return setShowUnsavedModal(true);
      }
      setHasUnsavedChanges(false);
      setShowUnsavedModal(false);
      return history.push(_link);
    },
    [hasUnsavedChanges]
  );

  const onClose = useCallback(() => {
    if (hasUnsavedChanges) {
      return setShowUnsavedModal(true);
    }
    setShowUnsavedModal(false);
    setHasUnsavedChanges(false);
    return closeCallback();
  }, [hasUnsavedChanges]);

  const handleOnConfirm = useCallback(() => {
    setShowUnsavedModal(false);
    setHasUnsavedChanges(false);
    return closeCallback ? onClose() : history.push(link);
  }, [link]);

  const component = useMemo(
    () => (
      <UnsavedChanges
        setShowModal={setShowUnsavedModal}
        showModal={showUnsavedModal}
        onConfirm={handleOnConfirm}
        message={message}
      />
    ),
    [showUnsavedModal]
  );

  return {
    onNavigate,
    onClose,
    component,
    setHasUnsavedChanges,
    hasUnsavedChanges,
  };
};

export default useAppNavigator;
