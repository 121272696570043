import { Card } from "components";
import Input from "components/Input";
import React from "react";

const BusinessInformation = ({ formValues, handleOnChange }) => (
  <Card className="p-4 flex flex-col">
    <h3 className="text-base font-bold mb-4">Business Information</h3>
    <div className="lg:w-custom-560 w-full flex flex-col gap-7">
      <Input
        label="Business Entity"
        name="businessEntityName"
        onChange={handleOnChange("businessEntityName")}
        placeholder="Memberstack Inc."
        value={formValues?.businessEntityName}
        hideIndicators
        description="Maybe required for certain features in the future."
        maxLength={60}
        dataCy="business-entity-name"
      />
      <Input
        label="Terms of Service URL"
        name="termsOfServiceURL"
        value={formValues.termsOfServiceURL}
        placeholder="memberstack.com/legal/terms"
        onChange={handleOnChange("termsOfServiceURL")}
        leading="https://"
        leadingInline
        hideIndicators
        description="Maybe required for certain features in the future."
        dataCy="terms-of-service-url"
      />
      <Input
        label="Privacy Policy URL"
        name="privacyPolicyURL"
        value={formValues?.privacyPolicyURL}
        placeholder="memberstack.com/legal/privacy"
        onChange={handleOnChange("privacyPolicyURL")}
        leading="https://"
        leadingInline
        hideIndicators
        description="Maybe required for certain features in the future."
        dataCy="privacy-policy-url"
      />
    </div>
  </Card>
);

export default BusinessInformation;
