import React, { useState } from "react";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import {
  useDeleteMemberMutation,
  GetMembersQuery,
  GET_MEMBERS,
} from "features";
import { isError } from "helpers/typeguards";
import { Modal, ModalTypes } from "components/Modal";
import { RouteName } from "enums/routes";
import { useParams, useNavigate } from "react-router-dom";
import { Form } from "components";
import useTranslations from "hooks/useTranslations";
import { useMembersContext } from "containers/Members/context/MembersContextProvider";

interface DeleteMemberModalProps extends ModalTypes {
  memberEmail: string;
  memberId: string;
}

const DeleteMemberModal = ({
  showModal,
  setShowModal,
  memberEmail,
  memberId,
}: DeleteMemberModalProps) => {
  const { formatMessage } = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const { createToastAlert } = useToastAlertContext();
  const { refetch } = useMembersContext();
  const [modalError, setModalError] = useState(null);
  const { appId } = useParams<{ appId: string }>();
  const navigate = useNavigate();

  const [deleteMember] = useDeleteMemberMutation({
    variables: {
      input: {
        memberId,
      },
    },
    update(cache, { data }) {
      const deletedMemberId = data?.deleteMember;
      const members = cache.readQuery<GetMembersQuery>({
        query: GET_MEMBERS,
      });

      cache.writeQuery<GetMembersQuery>({
        query: GET_MEMBERS,
        data: {
          getMembers: {
            ...members?.getMembers,
            edges: members?.getMembers.edges.filter(
              (member) => member.node.id !== deletedMemberId
            ),
          },
        },
      });
      // update member count
      cache.modify({
        fields: {
          getMembersCount: (existingCount) => existingCount - 1,
        },
      });
    },
    async onCompleted() {
      createToastAlert({
        alertType: "success",
        message: "Member was successfully deleted.",
      });
      setIsLoading(false);
      setShowModal(false);
      await refetch();
      navigate(`/${RouteName.apps}/${appId}/${RouteName.members}`);
    },
    onError(e) {
      if (isError(e)) {
        setModalError(e.message);
      }
    },
  });

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    createToastAlert({ processing: true });
    await deleteMember();
  };

  return (
    <Modal
      name="delete-member"
      setShowModal={setShowModal}
      showModal={showModal}
      title={formatMessage({
        id: "deleteMember.title",
        defaultMessage: "Delete Member",
      })}
      showDivider
      actionButtons={{
        confirm: {
          label: formatMessage({
            id: "deleteMember.confirm",
            defaultMessage: "Confirm",
          }),
          onConfirm: handleConfirmDelete,
          dataCy: "delete-member-submit-button",
          isLoading,
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form onSubmit={handleConfirmDelete} className="text-body-sm">
        <p>
          You are deleting a member with the email address{" "}
          <strong>{memberEmail}</strong>.
        </p>
        <p>Once you delete a member, you cannot restore it.</p>
      </Form>
    </Modal>
  );
};

export default DeleteMemberModal;
