import React from "react";
import { Link, CodeBlock, Button } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import {
  MemberstackProviderCodeBlock,
  UseMemberstackCodeBlock,
} from "containers/Settings/install-code/ReactCode";
import Input from "components/Input";
import { javascript } from "@codemirror/lang-javascript";
import { html } from "@codemirror/lang-html";
import InstallWebflowVideo from "assets/videos/install_code.mp4";
import { PulsingDot } from "components/PulsingDot/PulsingDot";
import { useVerifyUrlLazyQuery } from "features";
import { GSAccordionItem, GSAccordion } from "./InstallMemberstack.styles";

interface ReactCodeProps {
  onlyShowCode?: boolean;
  onComplete?: () => void;
}

export const ReactCode = ({ onlyShowCode, onComplete }: ReactCodeProps) => {
  const npmCode = "npm install @memberstack/react";
  const yarnCode = "yarn add @memberstack/react";
  return (
    <GSAccordion
      defaultExpanded="step1"
      name="react-code-accordion"
      striped={false}
    >
      <GSAccordionItem
        label="Step 1 - Install the Memberstack React package"
        value="step1"
        removeSectionPadding
      >
        <p className="text-body-sm">
          Install the Memberstack React package from the{" "}
          <Link
            isExternal
            showAsLink
            to="https://www.npmjs.com/package/@memberstack/react"
          >
            npm public registry
          </Link>
          , or{" "}
          <Link
            isExternal
            showAsLink
            to="https://classic.yarnpkg.com/en/package/@memberstack/react"
          >
            yarn
          </Link>
        </p>
        <CodeBlock
          value={npmCode}
          readOnly
          copyMessage="NPM code snippet was copied."
          className="mb-2"
          editable={false}
          showLineNumbers={false}
          isPulsingCopy={!onlyShowCode}
        />
        <CodeBlock
          value={yarnCode}
          readOnly
          editable={false}
          copyMessage="Yarn code snippet was copied."
          showLineNumbers={false}
          isPulsingCopy={!onlyShowCode}
        />
      </GSAccordionItem>
      <GSAccordionItem
        label="Step 2 - Add the Memberstack Provider"
        value="step2"
        removeSectionPadding
      >
        <MemberstackProviderCodeBlock
          className="text-sm"
          onlyShowCode={!onlyShowCode}
          onCodeCopy={onComplete}
        />
      </GSAccordionItem>
      <GSAccordionItem
        label="Step 3 - Import useMemberstack"
        value="step3"
        removeSectionPadding
      >
        <UseMemberstackCodeBlock
          className="text-sm"
          onlyShowCode={!onlyShowCode}
          onCodeCopy={onComplete}
        />
      </GSAccordionItem>
    </GSAccordion>
  );
};

interface Props {
  domain?: string;
  onlyShowCode?: boolean;
  onComplete?: () => void;
}
export const WebflowCode = ({
  domain,
  onlyShowCode = false,
  onComplete,
}: Props) => {
  const [url, setUrl] = React.useState("");
  const [showPulsingDot, setShowPulsingDot] = React.useState(false);

  const [verifyUrl, { loading, error, data }] = useVerifyUrlLazyQuery();
  const { appData } = useAppDataContext();

  const webflowHeaderCode = `<!-- Memberstack webflow package -->
<script ${
    domain
      ? `data-memberstack-domain="https://memberstack-client.${domain}" `
      : ""
  }data-memberstack-app="${
    appData?.id
  }" src="https://static.memberstack.com/scripts/v1/memberstack.js" type="text/javascript"></script>`;

  return (
    <div
      className={
        onlyShowCode
          ? "flex flex-col mt-0 gap-2"
          : "flex flex-col gap-2 ml-7 relative"
      }
    >
      {!onlyShowCode && (
        <p className="text-sm text-app-gray600">
          Copy and paste the code snippet into your site header.{" "}
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/7253090768539-Installing-Memberstack-in-Webflow"
            target="_blank"
            isExternal
            underline
            tw="text-app-gray600"
          >
            Learn more.
          </Link>
        </p>
      )}
      <CodeBlock
        isPulsingCopy={!onlyShowCode && !showPulsingDot}
        value={webflowHeaderCode}
        readOnly
        extensions={[html()]}
        copyMessage="Header Code copied."
        hasBorder
        onCopyClick={() => setShowPulsingDot(true)}
      />
      {!onlyShowCode && (
        <video autoPlay loop muted playsInline className="border rounded">
          <source src={InstallWebflowVideo} type="video/mp4" />
        </video>
      )}
      {!onlyShowCode && showPulsingDot && <PulsingDot bottom={25} left={-36} />}
      {!onlyShowCode && (
        <div>
          <label htmlFor="checkInstallCode" className="text-sm font-bold">
            Check your install code
          </label>
          <Input
            placeholder="https://your-website.webflow.io"
            errorMessage={error && error.message}
            hideIndicators
            name="checkInstallCode"
            tw="mt-1"
            value={url}
            disabled={loading}
            onChange={(e) => {
              e.stopPropagation();
              setUrl(e.target.value);
            }}
            successMessage={
              data?.getWebflowURLVerification && "Install code is working."
            }
            trailing={
              <Button
                text="Test Install Code"
                onClick={(e) => {
                  e.stopPropagation();
                  verifyUrl({ variables: { url } }).then(({ data: _data }) => {
                    const { getWebflowURLVerification } = _data;
                    if (getWebflowURLVerification) {
                      onComplete?.();
                      setShowPulsingDot(false);
                      window.CommandBar?.trackEvent(
                        "installed-correctly",
                        true
                      );
                    }
                  });
                }}
                isLoading={loading}
                dataCy="check-install-code"
                tw="text-utility-tag-md"
              />
            }
          />
          <p className="text-sm text-app-gray500 mt-1">
            Note: Memberstack only works with sites that have an SSL/HTTPS
            enabled.
          </p>
        </div>
      )}
    </div>
  );
};

export const VanillaJSCode = ({
  domain,
  onlyShowCode = false,
  onComplete,
}: Props) => {
  const { appData } = useAppDataContext();
  const publicKey = appData?.apiKeys?.publicKey;

  const vanillaCode = `import memberstackDOM from '@memberstack/dom';
const memberstack = memberstackDOM.init({
  ${
    domain
      ? `domain: "https://memberstack-client.${domain}",
  publicKey: "${publicKey}",`
      : `publicKey: "${publicKey}",`
  }
});`;

  return (
    <div className={onlyShowCode ? "mt-0" : "mt-4"}>
      {!onlyShowCode && (
        <h3 tw="font-bold text-body-sm mb-1">
          Copy and paste this code snippet into your site header
        </h3>
      )}
      <CodeBlock
        onCopyClick={onComplete}
        isPulsingCopy={!onlyShowCode}
        value={vanillaCode}
        readOnly
        extensions={[javascript()]}
        copyMessage="Vanilla code snippet was copied."
        hasBorder
      />
    </div>
  );
};
