import React from "react";
import { Card } from "components";
import Skeleton from "react-loading-skeleton";
import * as S from "./billing.styles";

const BillingSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <Card className="flex flex-col p-4 gap-4">
        <div className="p-4 flex flex-col xl:flex-row xl:justify-center">
          <Skeleton width={240} height={160} className="rounded-2xl" />
          <div className="flex flex-col gap-4 mt-4 ml-0 xl:ml-8 xl:mt-0">
            <Skeleton width={430} height={36} />
            <Skeleton width={400} height={28} />
            <Skeleton width={262} height={60} />
          </div>
        </div>

        <S.BillingPlanContainer>
          {[1, 2, 3, 4].map((content) => (
            <div tw="bg-white px-7 py-[30px]" key={content}>
              <Skeleton width={100} height={32} className="mb-3" />
              <Skeleton width={110} height={73} className="mb-3" />
              <Skeleton width={110} height={28} className="mb-5" />
              <div className="flex flex-col gap-3">
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <Skeleton width={200} height={28} key={item} />
                ))}
              </div>
              <Skeleton width={200} height={36} className="mt-8" />
            </div>
          ))}
        </S.BillingPlanContainer>
      </Card>
      <div className="flex gap-4">
        <Card className="p-4">
          <Skeleton width={200} height={28} className="mb-1" />
          <Skeleton width={780} height={20} className="mb-4" />
          <Skeleton width={188} height={32} />
        </Card>
      </div>
    </div>
  );
};

export default BillingSkeleton;
