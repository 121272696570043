import { PriceIntervalType } from "generatedTypes";

const intervalSingular = (type) =>
  // eslint-disable-next-line no-nested-ternary
  type === PriceIntervalType.Monthly
    ? "month"
    : type === PriceIntervalType.Weekly
    ? "week"
    : "year";

const intervalPlural = (type) =>
  // eslint-disable-next-line no-nested-ternary
  type === PriceIntervalType.Monthly
    ? "months"
    : type === PriceIntervalType.Weekly
    ? "weeks"
    : "years";

const intervalFormatter = ({ type, count }) => {
  if (count === 1) {
    return `per ${intervalSingular(type)}`;
  }
  return `every ${count || "x"} ${intervalPlural(type)}`;
};

export default intervalFormatter;
