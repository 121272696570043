import { gql } from "@apollo/client";

export const GET_USER = gql`
  query CurrentUser {
    currentUser {
      id
      profile {
        firstName
        lastName
      }
      auth {
        commandBarHMAC
        email
        connectedToGoogle
        twoFactorSettings {
          enabled
          validated
          backupCodes
        }
      }
      session {
        acr
        connectedToWebflow
      }
      appConnections {
        role
        app {
          id
          name
          status
          stack
          stripeSubscriptionStatus
          stripePriceId
          stripeAppFeePercent
          branding {
            colors {
              light {
                primaryButton
              }
            }
          }
          image
          webflowSiteId
          webflowSiteName
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      auth {
        email
      }
      profile {
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_USER_AUTH = gql`
  mutation UpdateUserAuth($input: UpdateUserAuthInput!) {
    updateUserAuth(input: $input) {
      id
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($input: UserResetPasswordInput!) {
    resetUserPassword(input: $input) {
      id
    }
  }
`;

export const SEND_USER_RESET_PASSWORD_EMAIL = gql`
  mutation SendUserResetPasswordEmail($input: UserResetPasswordEmailInput!) {
    sendUserResetPasswordEmail(input: $input)
  }
`;

export const CONNECT_USER_TO_GOOGLE = gql`
  mutation ConnectUserToGoogle($input: LoginUserGoogleInput!) {
    connectUserToGoogle(input: $input) {
      id
    }
  }
`;

export const DISCONNECT_USER_FROM_GOOGLE = gql`
  mutation DisconnectUserFromGoogle {
    disconnectUserFromGoogle {
      id
    }
  }
`;

export const VERIFY_URL = gql`
  query verifyUrl($url: String!) {
    getWebflowURLVerification(url: $url)
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query GetUsers($after: String, $order: OrderByInput, $first: Int) {
    getUsers(after: $after, order: $order, first: $first) {
      edges {
        cursor
        node {
          user {
            id
            auth {
              email
            }
            profile {
              firstName
              lastName
            }
          }
          role
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
