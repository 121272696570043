import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CurrentAppQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentAppQuery = (
  { __typename?: 'Query' }
  & { currentApp?: Types.Maybe<(
    { __typename?: 'App' }
    & Pick<Types.App, 'id' | 'name' | 'slug' | 'status' | 'stack' | 'image' | 'createdAt' | 'memberCountLimit' | 'businessEntityName' | 'termsOfServiceURL' | 'privacyPolicyURL' | 'stripePriceId' | 'stripeProductId' | 'stripeSubscriptionId' | 'stripeSubscriptionStatus' | 'preventDisposableEmails' | 'captchaEnabled' | 'stripeIdealEnabled' | 'stripeTaxIdCollectionEnabled' | 'stripeBillingAddressCollectionEnabled' | 'stripeDisablePaymentModeInvoices' | 'stripePortalCancelImmediately' | 'stripePortalDisableCancel' | 'ssoSignupButtonURL' | 'apiVersion' | 'clientMessageTextEnabled' | 'disableConcurrentLogins' | 'memberSessionDurationDays' | 'requireUser2FA' | 'webflowSiteId' | 'webflowSiteName' | 'socketAuthToken' | 'passwordlessEnabled' | 'defaultToPasswordless' | 'lastStripeAppFeeChange' | 'stripeAppFeePercent' | 'testModeMemberLimitEnabled' | 'churnkeyAuthToken' | 'stripeCustomerId' | 'weeklyRoundupEmailEnabled' | 'dailyCommentingEmailEnabled' | 'sendWelcomeEmailAfterVerification'>
    & { stripeConnection?: Types.Maybe<(
      { __typename?: 'AppStripeConnection' }
      & Pick<Types.AppStripeConnection, 'accountId'>
    )>, domains?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Domain' }
      & Pick<Types.Domain, 'id' | 'url' | 'verified' | 'mode'>
    )>>>, apiKeys?: Types.Maybe<(
      { __typename?: 'APIKeys' }
      & Pick<Types.ApiKeys, 'publicKey' | 'secretKey'>
    )>, customDomain: (
      { __typename?: 'CustomDomain' }
      & Pick<Types.CustomDomain, 'rootDomain' | 'authSubdomain'>
    ) }
  )> }
);

export type CreateAppMutationVariables = Types.Exact<{
  input: Types.CreateAppInput;
}>;


export type CreateAppMutation = (
  { __typename?: 'Mutation' }
  & { createApp: (
    { __typename?: 'App' }
    & Pick<Types.App, 'id' | 'status' | 'stack'>
  ) }
);

export type DeleteAppMutationVariables = Types.Exact<{
  input: Types.DeleteAppInput;
}>;


export type DeleteAppMutation = (
  { __typename?: 'Mutation' }
  & { deleteApp: (
    { __typename?: 'App' }
    & Pick<Types.App, 'id' | 'status'>
  ) }
);

export type RestoreAppMutationVariables = Types.Exact<{
  input: Types.RestoreAppInput;
}>;


export type RestoreAppMutation = (
  { __typename?: 'Mutation' }
  & { restoreApp: (
    { __typename?: 'App' }
    & Pick<Types.App, 'id' | 'status'>
  ) }
);

export type AddUserToAppMutationVariables = Types.Exact<{
  input: Types.AddUserToAppInput;
}>;


export type AddUserToAppMutation = (
  { __typename?: 'Mutation' }
  & { addUserToApp: (
    { __typename?: 'UserAppConnection' }
    & Pick<Types.UserAppConnection, 'role'>
    & { app: (
      { __typename?: 'App' }
      & Pick<Types.App, 'id'>
    ) }
  ) }
);

export type RemoveUserFromAppMutationVariables = Types.Exact<{
  input: Types.RemoveUserFromAppInput;
}>;


export type RemoveUserFromAppMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromApp: (
    { __typename?: 'UserAppConnection' }
    & Pick<Types.UserAppConnection, 'role'>
    & { app: (
      { __typename?: 'App' }
      & Pick<Types.App, 'id'>
    ) }
  ) }
);

export type TransferAppOwnershipMutationVariables = Types.Exact<{
  input: Types.TransferAppOwnershipInput;
}>;


export type TransferAppOwnershipMutation = (
  { __typename?: 'Mutation' }
  & { transferAppOwnership: (
    { __typename?: 'UserAppConnection' }
    & Pick<Types.UserAppConnection, 'role'>
    & { app: (
      { __typename?: 'App' }
      & Pick<Types.App, 'id'>
    ) }
  ) }
);

export type UpdateAppMutationVariables = Types.Exact<{
  input: Types.UpdateAppInput;
}>;


export type UpdateAppMutation = (
  { __typename?: 'Mutation' }
  & { updateApp: (
    { __typename?: 'App' }
    & Pick<Types.App, 'id' | 'name' | 'stack' | 'stripeTaxIdCollectionEnabled' | 'stripeBillingAddressCollectionEnabled' | 'stripeIdealEnabled' | 'stripePortalCancelImmediately' | 'stripeDisablePaymentModeInvoices' | 'stripePortalDisableCancel'>
    & { domains?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Domain' }
      & Pick<Types.Domain, 'url' | 'verified'>
    )>>> }
  ) }
);

export type GenerateApiKeysMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateApiKeysMutation = (
  { __typename?: 'Mutation' }
  & { generateAPIKeys?: Types.Maybe<(
    { __typename?: 'APIKeys' }
    & Pick<Types.ApiKeys, 'publicKey' | 'secretKey'>
  )> }
);

export type AddEmailDomainMutationVariables = Types.Exact<{
  input: Types.AddEmailDomainInput;
}>;


export type AddEmailDomainMutation = (
  { __typename?: 'Mutation' }
  & { addEmailDomain: (
    { __typename?: 'Emails' }
    & Pick<Types.Emails, 'from' | 'verified'>
    & { emailDNSSettings?: Types.Maybe<(
      { __typename?: 'emailDNSSettings' }
      & Pick<Types.EmailDnsSettings, 'DKIMHost' | 'DKIMTextValue' | 'ReturnPathCNAMEValue'>
    )> }
  ) }
);

export type RemoveEmailDomainMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveEmailDomainMutation = (
  { __typename?: 'Mutation' }
  & { removeEmailDomain: (
    { __typename?: 'Emails' }
    & Pick<Types.Emails, 'verified' | 'from'>
  ) }
);

export type VerifyEmailDomainMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type VerifyEmailDomainMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmailDomain: (
    { __typename?: 'Emails' }
    & Pick<Types.Emails, 'verified' | 'from'>
  ) }
);

export type GetEmailSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmailSettingsQuery = (
  { __typename?: 'Query' }
  & { getEmails?: Types.Maybe<(
    { __typename?: 'Emails' }
    & Pick<Types.Emails, 'from' | 'verified'>
    & { emailDNSSettings?: Types.Maybe<(
      { __typename?: 'emailDNSSettings' }
      & Pick<Types.EmailDnsSettings, 'DKIMHost' | 'DKIMTextValue' | 'ReturnPathCNAMEValue'>
    )> }
  )> }
);


export const CurrentAppDocument = gql`
    query CurrentApp {
  currentApp {
    id
    name
    slug
    status
    stack
    image
    createdAt
    memberCountLimit
    stripeConnection {
      accountId
    }
    domains {
      id
      url
      verified
      mode
    }
    businessEntityName
    termsOfServiceURL
    privacyPolicyURL
    stripePriceId
    stripeProductId
    stripeSubscriptionId
    stripeSubscriptionStatus
    preventDisposableEmails
    captchaEnabled
    stripeIdealEnabled
    stripeTaxIdCollectionEnabled
    stripeBillingAddressCollectionEnabled
    stripeDisablePaymentModeInvoices
    stripePortalCancelImmediately
    stripePortalDisableCancel
    ssoSignupButtonURL
    apiVersion
    clientMessageTextEnabled
    disableConcurrentLogins
    memberSessionDurationDays
    apiKeys {
      publicKey
      secretKey
    }
    requireUser2FA
    webflowSiteId
    webflowSiteName
    socketAuthToken
    passwordlessEnabled
    defaultToPasswordless
    customDomain {
      rootDomain
      authSubdomain
    }
    lastStripeAppFeeChange
    stripeAppFeePercent
    testModeMemberLimitEnabled
    churnkeyAuthToken
    stripeCustomerId
    weeklyRoundupEmailEnabled
    dailyCommentingEmailEnabled
    sendWelcomeEmailAfterVerification
  }
}
    `;

/**
 * __useCurrentAppQuery__
 *
 * To run a query within a React component, call `useCurrentAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAppQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAppQuery, CurrentAppQueryVariables>) {
        return Apollo.useQuery<CurrentAppQuery, CurrentAppQueryVariables>(CurrentAppDocument, baseOptions);
      }
export function useCurrentAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAppQuery, CurrentAppQueryVariables>) {
          return Apollo.useLazyQuery<CurrentAppQuery, CurrentAppQueryVariables>(CurrentAppDocument, baseOptions);
        }
export type CurrentAppQueryHookResult = ReturnType<typeof useCurrentAppQuery>;
export type CurrentAppLazyQueryHookResult = ReturnType<typeof useCurrentAppLazyQuery>;
export type CurrentAppQueryResult = Apollo.QueryResult<CurrentAppQuery, CurrentAppQueryVariables>;
export const CreateAppDocument = gql`
    mutation CreateApp($input: CreateAppInput!) {
  createApp(input: $input) {
    id
    status
    stack
  }
}
    `;
export type CreateAppMutationFn = Apollo.MutationFunction<CreateAppMutation, CreateAppMutationVariables>;

/**
 * __useCreateAppMutation__
 *
 * To run a mutation, you first call `useCreateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppMutation, { data, loading, error }] = useCreateAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppMutation, CreateAppMutationVariables>) {
        return Apollo.useMutation<CreateAppMutation, CreateAppMutationVariables>(CreateAppDocument, baseOptions);
      }
export type CreateAppMutationHookResult = ReturnType<typeof useCreateAppMutation>;
export type CreateAppMutationResult = Apollo.MutationResult<CreateAppMutation>;
export type CreateAppMutationOptions = Apollo.BaseMutationOptions<CreateAppMutation, CreateAppMutationVariables>;
export const DeleteAppDocument = gql`
    mutation DeleteApp($input: DeleteAppInput!) {
  deleteApp(input: $input) {
    id
    status
  }
}
    `;
export type DeleteAppMutationFn = Apollo.MutationFunction<DeleteAppMutation, DeleteAppMutationVariables>;

/**
 * __useDeleteAppMutation__
 *
 * To run a mutation, you first call `useDeleteAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppMutation, { data, loading, error }] = useDeleteAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppMutation, DeleteAppMutationVariables>) {
        return Apollo.useMutation<DeleteAppMutation, DeleteAppMutationVariables>(DeleteAppDocument, baseOptions);
      }
export type DeleteAppMutationHookResult = ReturnType<typeof useDeleteAppMutation>;
export type DeleteAppMutationResult = Apollo.MutationResult<DeleteAppMutation>;
export type DeleteAppMutationOptions = Apollo.BaseMutationOptions<DeleteAppMutation, DeleteAppMutationVariables>;
export const RestoreAppDocument = gql`
    mutation RestoreApp($input: RestoreAppInput!) {
  restoreApp(input: $input) {
    id
    status
  }
}
    `;
export type RestoreAppMutationFn = Apollo.MutationFunction<RestoreAppMutation, RestoreAppMutationVariables>;

/**
 * __useRestoreAppMutation__
 *
 * To run a mutation, you first call `useRestoreAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreAppMutation, { data, loading, error }] = useRestoreAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreAppMutation(baseOptions?: Apollo.MutationHookOptions<RestoreAppMutation, RestoreAppMutationVariables>) {
        return Apollo.useMutation<RestoreAppMutation, RestoreAppMutationVariables>(RestoreAppDocument, baseOptions);
      }
export type RestoreAppMutationHookResult = ReturnType<typeof useRestoreAppMutation>;
export type RestoreAppMutationResult = Apollo.MutationResult<RestoreAppMutation>;
export type RestoreAppMutationOptions = Apollo.BaseMutationOptions<RestoreAppMutation, RestoreAppMutationVariables>;
export const AddUserToAppDocument = gql`
    mutation AddUserToApp($input: AddUserToAppInput!) {
  addUserToApp(input: $input) {
    app {
      id
    }
    role
  }
}
    `;
export type AddUserToAppMutationFn = Apollo.MutationFunction<AddUserToAppMutation, AddUserToAppMutationVariables>;

/**
 * __useAddUserToAppMutation__
 *
 * To run a mutation, you first call `useAddUserToAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToAppMutation, { data, loading, error }] = useAddUserToAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserToAppMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToAppMutation, AddUserToAppMutationVariables>) {
        return Apollo.useMutation<AddUserToAppMutation, AddUserToAppMutationVariables>(AddUserToAppDocument, baseOptions);
      }
export type AddUserToAppMutationHookResult = ReturnType<typeof useAddUserToAppMutation>;
export type AddUserToAppMutationResult = Apollo.MutationResult<AddUserToAppMutation>;
export type AddUserToAppMutationOptions = Apollo.BaseMutationOptions<AddUserToAppMutation, AddUserToAppMutationVariables>;
export const RemoveUserFromAppDocument = gql`
    mutation RemoveUserFromApp($input: RemoveUserFromAppInput!) {
  removeUserFromApp(input: $input) {
    app {
      id
    }
    role
  }
}
    `;
export type RemoveUserFromAppMutationFn = Apollo.MutationFunction<RemoveUserFromAppMutation, RemoveUserFromAppMutationVariables>;

/**
 * __useRemoveUserFromAppMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromAppMutation, { data, loading, error }] = useRemoveUserFromAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserFromAppMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromAppMutation, RemoveUserFromAppMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromAppMutation, RemoveUserFromAppMutationVariables>(RemoveUserFromAppDocument, baseOptions);
      }
export type RemoveUserFromAppMutationHookResult = ReturnType<typeof useRemoveUserFromAppMutation>;
export type RemoveUserFromAppMutationResult = Apollo.MutationResult<RemoveUserFromAppMutation>;
export type RemoveUserFromAppMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromAppMutation, RemoveUserFromAppMutationVariables>;
export const TransferAppOwnershipDocument = gql`
    mutation TransferAppOwnership($input: TransferAppOwnershipInput!) {
  transferAppOwnership(input: $input) {
    app {
      id
    }
    role
  }
}
    `;
export type TransferAppOwnershipMutationFn = Apollo.MutationFunction<TransferAppOwnershipMutation, TransferAppOwnershipMutationVariables>;

/**
 * __useTransferAppOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferAppOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferAppOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferAppOwnershipMutation, { data, loading, error }] = useTransferAppOwnershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferAppOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<TransferAppOwnershipMutation, TransferAppOwnershipMutationVariables>) {
        return Apollo.useMutation<TransferAppOwnershipMutation, TransferAppOwnershipMutationVariables>(TransferAppOwnershipDocument, baseOptions);
      }
export type TransferAppOwnershipMutationHookResult = ReturnType<typeof useTransferAppOwnershipMutation>;
export type TransferAppOwnershipMutationResult = Apollo.MutationResult<TransferAppOwnershipMutation>;
export type TransferAppOwnershipMutationOptions = Apollo.BaseMutationOptions<TransferAppOwnershipMutation, TransferAppOwnershipMutationVariables>;
export const UpdateAppDocument = gql`
    mutation UpdateApp($input: UpdateAppInput!) {
  updateApp(input: $input) {
    id
    name
    stack
    domains {
      url
      verified
    }
    stripeTaxIdCollectionEnabled
    stripeBillingAddressCollectionEnabled
    stripeIdealEnabled
    stripePortalCancelImmediately
    stripeDisablePaymentModeInvoices
    stripePortalDisableCancel
  }
}
    `;
export type UpdateAppMutationFn = Apollo.MutationFunction<UpdateAppMutation, UpdateAppMutationVariables>;

/**
 * __useUpdateAppMutation__
 *
 * To run a mutation, you first call `useUpdateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMutation, { data, loading, error }] = useUpdateAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppMutation, UpdateAppMutationVariables>) {
        return Apollo.useMutation<UpdateAppMutation, UpdateAppMutationVariables>(UpdateAppDocument, baseOptions);
      }
export type UpdateAppMutationHookResult = ReturnType<typeof useUpdateAppMutation>;
export type UpdateAppMutationResult = Apollo.MutationResult<UpdateAppMutation>;
export type UpdateAppMutationOptions = Apollo.BaseMutationOptions<UpdateAppMutation, UpdateAppMutationVariables>;
export const GenerateApiKeysDocument = gql`
    mutation GenerateApiKeys {
  generateAPIKeys {
    publicKey
    secretKey
  }
}
    `;
export type GenerateApiKeysMutationFn = Apollo.MutationFunction<GenerateApiKeysMutation, GenerateApiKeysMutationVariables>;

/**
 * __useGenerateApiKeysMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeysMutation, { data, loading, error }] = useGenerateApiKeysMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateApiKeysMutation(baseOptions?: Apollo.MutationHookOptions<GenerateApiKeysMutation, GenerateApiKeysMutationVariables>) {
        return Apollo.useMutation<GenerateApiKeysMutation, GenerateApiKeysMutationVariables>(GenerateApiKeysDocument, baseOptions);
      }
export type GenerateApiKeysMutationHookResult = ReturnType<typeof useGenerateApiKeysMutation>;
export type GenerateApiKeysMutationResult = Apollo.MutationResult<GenerateApiKeysMutation>;
export type GenerateApiKeysMutationOptions = Apollo.BaseMutationOptions<GenerateApiKeysMutation, GenerateApiKeysMutationVariables>;
export const AddEmailDomainDocument = gql`
    mutation AddEmailDomain($input: AddEmailDomainInput!) {
  addEmailDomain(input: $input) {
    from
    verified
    emailDNSSettings {
      DKIMHost
      DKIMTextValue
      ReturnPathCNAMEValue
    }
  }
}
    `;
export type AddEmailDomainMutationFn = Apollo.MutationFunction<AddEmailDomainMutation, AddEmailDomainMutationVariables>;

/**
 * __useAddEmailDomainMutation__
 *
 * To run a mutation, you first call `useAddEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailDomainMutation, { data, loading, error }] = useAddEmailDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<AddEmailDomainMutation, AddEmailDomainMutationVariables>) {
        return Apollo.useMutation<AddEmailDomainMutation, AddEmailDomainMutationVariables>(AddEmailDomainDocument, baseOptions);
      }
export type AddEmailDomainMutationHookResult = ReturnType<typeof useAddEmailDomainMutation>;
export type AddEmailDomainMutationResult = Apollo.MutationResult<AddEmailDomainMutation>;
export type AddEmailDomainMutationOptions = Apollo.BaseMutationOptions<AddEmailDomainMutation, AddEmailDomainMutationVariables>;
export const RemoveEmailDomainDocument = gql`
    mutation RemoveEmailDomain {
  removeEmailDomain {
    verified
    from
  }
}
    `;
export type RemoveEmailDomainMutationFn = Apollo.MutationFunction<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>;

/**
 * __useRemoveEmailDomainMutation__
 *
 * To run a mutation, you first call `useRemoveEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmailDomainMutation, { data, loading, error }] = useRemoveEmailDomainMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>) {
        return Apollo.useMutation<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>(RemoveEmailDomainDocument, baseOptions);
      }
export type RemoveEmailDomainMutationHookResult = ReturnType<typeof useRemoveEmailDomainMutation>;
export type RemoveEmailDomainMutationResult = Apollo.MutationResult<RemoveEmailDomainMutation>;
export type RemoveEmailDomainMutationOptions = Apollo.BaseMutationOptions<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>;
export const VerifyEmailDomainDocument = gql`
    mutation VerifyEmailDomain {
  verifyEmailDomain {
    verified
    from
  }
}
    `;
export type VerifyEmailDomainMutationFn = Apollo.MutationFunction<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>;

/**
 * __useVerifyEmailDomainMutation__
 *
 * To run a mutation, you first call `useVerifyEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailDomainMutation, { data, loading, error }] = useVerifyEmailDomainMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>) {
        return Apollo.useMutation<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>(VerifyEmailDomainDocument, baseOptions);
      }
export type VerifyEmailDomainMutationHookResult = ReturnType<typeof useVerifyEmailDomainMutation>;
export type VerifyEmailDomainMutationResult = Apollo.MutationResult<VerifyEmailDomainMutation>;
export type VerifyEmailDomainMutationOptions = Apollo.BaseMutationOptions<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>;
export const GetEmailSettingsDocument = gql`
    query GetEmailSettings {
  getEmails {
    from
    verified
    emailDNSSettings {
      DKIMHost
      DKIMTextValue
      ReturnPathCNAMEValue
    }
  }
}
    `;

/**
 * __useGetEmailSettingsQuery__
 *
 * To run a query within a React component, call `useGetEmailSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailSettingsQuery, GetEmailSettingsQueryVariables>) {
        return Apollo.useQuery<GetEmailSettingsQuery, GetEmailSettingsQueryVariables>(GetEmailSettingsDocument, baseOptions);
      }
export function useGetEmailSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailSettingsQuery, GetEmailSettingsQueryVariables>) {
          return Apollo.useLazyQuery<GetEmailSettingsQuery, GetEmailSettingsQueryVariables>(GetEmailSettingsDocument, baseOptions);
        }
export type GetEmailSettingsQueryHookResult = ReturnType<typeof useGetEmailSettingsQuery>;
export type GetEmailSettingsLazyQueryHookResult = ReturnType<typeof useGetEmailSettingsLazyQuery>;
export type GetEmailSettingsQueryResult = Apollo.QueryResult<GetEmailSettingsQuery, GetEmailSettingsQueryVariables>;