import tw, { css, styled } from "twin.macro";
import { Link } from "components";

export const PlanStatusCard = styled(Link)<{
  $isActive: boolean;
  $isArchived: boolean;
}>(() => [
  tw`rounded-lg shadow-sm p-3 flex justify-between items-center`,
  ({
    theme: {
      colors: { grey100, blue400, white, grey050 },
    },
    $isActive,
    $isArchived,
  }) => css`
    background-color: ${$isArchived ? grey100 : white};
    border-width: ${$isActive ? "2px" : "1px"};
    border-style: solid;
    border-color: ${$isActive ? blue400 : grey100};
    &:hover {
      background-color: ${$isArchived ? grey100 : grey050};
    }
  `,
]);
