import React, { Suspense, useEffect } from "react";
import { useLocation, useSearchParams, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "styles";
import { IntlProvider } from "react-intl";
import Loader from "components/Loader";
import { PostHogProvider } from "posthog-js/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGenerateZendeskTokenMutation } from "features";
import {
  ToastAlertContextProvider,
  useToastAlertContext,
} from "./contexts/ToastAlert";
import AppRoutes from "./routes";
import { EnvContextProvider } from "./contexts/Environment";
import { ApolloContextProvider } from "./contexts/Apollo";
import { translations } from "../../translations";

const Wrapper = () => {
  const location = useLocation();
  const [generateZendeskToken] = useGenerateZendeskTokenMutation();
  const { createToastAlert } = useToastAlertContext();
  const [searchParams] = useSearchParams();

  const message = searchParams.get("message");

  useEffect(() => {
    if (message) {
      createToastAlert({ message, alertType: "error" });
    }
  }, [message, createToastAlert]);

  useEffect(() => {
    const hadBrandId = Boolean(searchParams.get("brand_id"));
    const isLogInPath = location.pathname === "/login";
    if (hadBrandId && isLogInPath) {
      const generateToken = async () => {
        const {
          data: { generateZendeskToken: token },
        } = await generateZendeskToken();
        let zendeskUrl = `https://memberstack-v2.zendesk.com/access/jwt?jwt=${token}`;
        const params = new URLSearchParams(window.location.search);
        const returnTo = params.get("return_to");
        if (returnTo) zendeskUrl += `&return_to=${returnTo}`;
        window.location.replace(zendeskUrl);
      };
      // eslint-disable-next-line no-console
      generateToken().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, searchParams]);

  useEffect(() => {
    // changes URL from /apps/app_123/members to members, etc.
    let page = location.pathname.split("/").pop() as string;
    if (!page || page === "apps") return;

    if (page.includes("pln_")) page = "plans";
    if (page.includes("prc_")) page = "prices";

    if (location.pathname.includes("community")) page = "community";

    try {
      window.revgems("trackPageView", {
        occurredAt: new Date(),
        title: document.title,
        referrer: document.referrer,
        url: `${window.location.origin}/${page}`,
      });
    } catch (err) {
      console.log(err);
    }
  }, [location]);

  return <AppRoutes />;
};

export const App = () => {
  const locale = navigator.language.split(/[-_]/)[0];
  const messages = translations[locale];
  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        }}
      >
        <BrowserRouter>
          <ApolloContextProvider>
            <EnvContextProvider>
              <ThemeProvider theme={theme}>
                <IntlProvider
                  locale={locale}
                  messages={messages}
                  defaultLocale="en"
                >
                  <ToastAlertContextProvider>
                    <Suspense fallback={<Loader isFullScreen />}>
                      <Wrapper />
                    </Suspense>
                  </ToastAlertContextProvider>
                </IntlProvider>
              </ThemeProvider>
            </EnvContextProvider>
          </ApolloContextProvider>
        </BrowserRouter>
      </PostHogProvider>
    </GoogleOAuthProvider>
  );
};
