import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SignupMemberEmailPasswordMutationVariables = Types.Exact<{
  input: Types.SignupMemberEmailPasswordInput;
}>;


export type SignupMemberEmailPasswordMutation = (
  { __typename?: 'Mutation' }
  & { signupMemberEmailPassword: (
    { __typename?: 'MemberAuthPayload' }
    & { member: (
      { __typename?: 'Member' }
      & Pick<Types.Member, 'id' | 'profileImage' | 'customFields' | 'createdAt' | 'lastLogin'>
      & { auth: (
        { __typename?: 'MemberAuth' }
        & Pick<Types.MemberAuth, 'email'>
      ), planConnections?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'PlanConnection' }
        & Pick<Types.PlanConnection, 'id' | 'status'>
        & { plan: (
          { __typename?: 'PlanConnectionPlan' }
          & Pick<Types.PlanConnectionPlan, 'id' | 'name'>
        ) }
      )>>> }
    ) }
  ) }
);

export type CreateStripeCustomerMutationVariables = Types.Exact<{
  input: Types.CreateStripeCustomerInput;
}>;


export type CreateStripeCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createStripeCustomer: (
    { __typename?: 'Member' }
    & Pick<Types.Member, 'id'>
  ) }
);

export type UpdateMemberMutationVariables = Types.Exact<{
  input: Types.UpdateMemberInput;
}>;


export type UpdateMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateMember: (
    { __typename?: 'Member' }
    & Pick<Types.Member, 'id' | 'customFields'>
  ) }
);

export type UpdateMemberAuthMutationVariables = Types.Exact<{
  input: Types.UpdateMemberAuthInput;
}>;


export type UpdateMemberAuthMutation = (
  { __typename?: 'Mutation' }
  & { updateMemberAuth: (
    { __typename?: 'Member' }
    & Pick<Types.Member, 'id'>
    & { auth: (
      { __typename?: 'MemberAuth' }
      & Pick<Types.MemberAuth, 'email'>
    ) }
  ) }
);

export type DeleteMemberMutationVariables = Types.Exact<{
  input: Types.DeleteMemberInput;
}>;


export type DeleteMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteMember'>
);

export type GenerateMemberPasswordMutationVariables = Types.Exact<{
  input: Types.GenerateMemberPasswordInput;
}>;


export type GenerateMemberPasswordMutation = (
  { __typename?: 'Mutation' }
  & { generateMemberPassword: (
    { __typename?: 'MemberGeneratedPasswordPayload' }
    & Pick<Types.MemberGeneratedPasswordPayload, 'generatedPassword'>
  ) }
);

export type ImportMembersMutationVariables = Types.Exact<{
  input: Types.ImportMembersInput;
}>;


export type ImportMembersMutation = (
  { __typename?: 'Mutation' }
  & { importMembers: (
    { __typename?: 'ImportMembersPayload' }
    & Pick<Types.ImportMembersPayload, 'jobId' | 'totalPendingJobs'>
  ) }
);

export type RemoveAllMembersMutationVariables = Types.Exact<{
  input: Types.RemoveAllMembersInput;
}>;


export type RemoveAllMembersMutation = (
  { __typename?: 'Mutation' }
  & { removeAllMembers: (
    { __typename?: 'RemoveAllMembersPayload' }
    & Pick<Types.RemoveAllMembersPayload, 'jobId'>
  ) }
);

export type ResyncStripeMemberMutationVariables = Types.Exact<{
  input: Types.ResyncStripeMemberInput;
}>;


export type ResyncStripeMemberMutation = (
  { __typename?: 'Mutation' }
  & { resyncStripeMember: (
    { __typename?: 'ResyncStripeMemberPayload' }
    & Pick<Types.ResyncStripeMemberPayload, 'jobId'>
  ) }
);

export type RemoveSelectMembersMutationVariables = Types.Exact<{
  input: Types.RemoveSelectMembersInput;
}>;


export type RemoveSelectMembersMutation = (
  { __typename?: 'Mutation' }
  & { removeSelectMembers: (
    { __typename?: 'RemoveSelectMembersPayload' }
    & Pick<Types.RemoveSelectMembersPayload, 'count'>
  ) }
);

export type UpdateMemberNoteMutationVariables = Types.Exact<{
  input: Types.UpdateMemberNoteInput;
}>;


export type UpdateMemberNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateMemberNote: (
    { __typename?: 'Member' }
    & Pick<Types.Member, 'id'>
    & { memberNotes?: Types.Maybe<(
      { __typename?: 'MemberNote' }
      & Pick<Types.MemberNote, 'note' | 'updatedAt'>
    )> }
  ) }
);

export type ExportMembersMutationVariables = Types.Exact<{
  input: Types.ExportMembersInput;
}>;


export type ExportMembersMutation = (
  { __typename?: 'Mutation' }
  & { exportMembers: (
    { __typename?: 'ExportMembersPayload' }
    & Pick<Types.ExportMembersPayload, 'jobId'>
  ) }
);

export type GetMembersCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMembersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getMembersCount'>
);

export type GetMembersQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>;
  order?: Types.Maybe<Types.OrderByInput>;
  first?: Types.Maybe<Types.Scalars['Int']>;
  search?: Types.Maybe<Types.Scalars['String']>;
  filters?: Types.Maybe<Types.MemberFilter>;
}>;


export type GetMembersQuery = (
  { __typename?: 'Query' }
  & { getMembers?: Types.Maybe<(
    { __typename?: 'MemberConnection' }
    & Pick<Types.MemberConnection, 'mode'>
    & { edges: Array<(
      { __typename?: 'MemberEdge' }
      & Pick<Types.MemberEdge, 'cursor'>
      & { node: (
        { __typename?: 'Member' }
        & Pick<Types.Member, 'id' | 'trustStatus' | 'profileImage' | 'customFields' | 'createdAt' | 'lastLogin' | 'isNew'>
        & { auth: (
          { __typename?: 'MemberAuth' }
          & Pick<Types.MemberAuth, 'email'>
        ), planConnections?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'PlanConnection' }
          & Pick<Types.PlanConnection, 'id' | 'status'>
          & { plan: (
            { __typename?: 'PlanConnectionPlan' }
            & Pick<Types.PlanConnectionPlan, 'id' | 'name'>
          ), team?: Types.Maybe<(
            { __typename?: 'PlanConnectionTeam' }
            & Pick<Types.PlanConnectionTeam, 'isTeamPlan' | 'isTeamOwner' | 'teamId' | 'teamOwnerId'>
          )> }
        )>>> }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<Types.PageInfo, 'endCursor'>
    ) }
  )> }
);

export type GetMemberQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetMemberQuery = (
  { __typename?: 'Query' }
  & { currentMember?: Types.Maybe<(
    { __typename?: 'Member' }
    & Pick<Types.Member, 'id' | 'trustStatus' | 'profileImage' | 'createdAt' | 'lastLogin' | 'customFields' | 'stripeCustomerId' | 'metaData' | 'json' | 'loginRedirect' | 'verified' | 'isModerator'>
    & { auth: (
      { __typename?: 'MemberAuth' }
      & Pick<Types.MemberAuth, 'email'>
      & { connectedProviders?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'MemberAuthProvider' }
        & Pick<Types.MemberAuthProvider, 'provider'>
      )>>> }
    ), memberNotes?: Types.Maybe<(
      { __typename?: 'MemberNote' }
      & Pick<Types.MemberNote, 'note' | 'updatedAt'>
    )>, planConnections?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'PlanConnection' }
      & Pick<Types.PlanConnection, 'id' | 'active' | 'status' | 'type'>
      & { plan: (
        { __typename?: 'PlanConnectionPlan' }
        & Pick<Types.PlanConnectionPlan, 'id' | 'name'>
      ), payment?: Types.Maybe<(
        { __typename?: 'PlanConnectionPayment' }
        & Pick<Types.PlanConnectionPayment, 'amount' | 'currency' | 'status' | 'priceId' | 'cancelAtDate' | 'pauseCollection' | 'pauseResumeAt'>
        & { interval?: Types.Maybe<(
          { __typename?: 'PriceInterval' }
          & Pick<Types.PriceInterval, 'type' | 'count'>
        )> }
      )>, team?: Types.Maybe<(
        { __typename?: 'PlanConnectionTeam' }
        & Pick<Types.PlanConnectionTeam, 'isTeamPlan' | 'isTeamOwner' | 'teamId' | 'teamOwnerId'>
      )> }
    )>>>, permissions?: Types.Maybe<(
      { __typename?: 'Permissions' }
      & Pick<Types.Permissions, 'all' | 'manuallyGranted'>
      & { planInherited?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'PlanInheritedPermission' }
        & Pick<Types.PlanInheritedPermission, 'permission'>
        & { plans?: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'Plan' }
          & Pick<Types.Plan, 'id' | 'name'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetLoginWithMemberTokenQueryVariables = Types.Exact<{
  input: Types.GetLoginWithMemberTokenInput;
}>;


export type GetLoginWithMemberTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getLoginWithMemberToken'>
);

export type GetMemberMetricsByReportQueryVariables = Types.Exact<{
  report: Types.MemberMetricsReport;
}>;


export type GetMemberMetricsByReportQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getMemberMetricsByReport'>
);

export type MemberMetricsQueryVariables = Types.Exact<{
  input: Types.GetMemberMetricsInput;
}>;


export type MemberMetricsQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getMemberMetrics'>
);

export type GetAccessLogsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAccessLogsQuery = (
  { __typename?: 'Query' }
  & { getAccessLogs?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'AccessLog' }
    & Pick<Types.AccessLog, 'timestamp' | 'ip'>
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id'>
      & { auth: (
        { __typename?: 'UserAuth' }
        & Pick<Types.UserAuth, 'email'>
      ), profile: (
        { __typename?: 'UserProfile' }
        & Pick<Types.UserProfile, 'firstName' | 'lastName'>
      ) }
    )> }
  )>>> }
);

export type GetAppEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAppEventsQuery = (
  { __typename?: 'Query' }
  & { getAppEvents?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'AppEvent' }
    & Pick<Types.AppEvent, 'timestamp' | 'ip' | 'type'>
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id'>
      & { auth: (
        { __typename?: 'UserAuth' }
        & Pick<Types.UserAuth, 'email'>
      ), profile: (
        { __typename?: 'UserProfile' }
        & Pick<Types.UserProfile, 'firstName' | 'lastName'>
      ) }
    )> }
  )>>> }
);

export type GetTeamMembersQueryVariables = Types.Exact<{
  input: Types.GetTeamMembersInput;
}>;


export type GetTeamMembersQuery = (
  { __typename?: 'Query' }
  & { getTeamMembers?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'MemberTeamConnection' }
    & Pick<Types.MemberTeamConnection, 'role'>
    & { member: (
      { __typename?: 'Member' }
      & Pick<Types.Member, 'id'>
      & { auth: (
        { __typename?: 'MemberAuth' }
        & Pick<Types.MemberAuth, 'email'>
      ) }
    ) }
  )>>> }
);

export type RemoveTeamMemberMutationVariables = Types.Exact<{
  input: Types.RemoveTeamMemberInput;
}>;


export type RemoveTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'removeTeamMember'>
);

export type GetTeamQueryVariables = Types.Exact<{
  input: Types.GetTeamInput;
}>;


export type GetTeamQuery = (
  { __typename?: 'Query' }
  & { getTeam?: Types.Maybe<(
    { __typename?: 'Team' }
    & Pick<Types.Team, 'id' | 'inviteToken' | 'maxTeamMembers' | 'currentTeamMemberCount' | 'inviteLink'>
  )> }
);


export const SignupMemberEmailPasswordDocument = gql`
    mutation SignupMemberEmailPassword($input: SignupMemberEmailPasswordInput!) {
  signupMemberEmailPassword(input: $input) {
    member {
      id
      auth {
        email
      }
      profileImage
      customFields
      createdAt
      lastLogin
      planConnections {
        id
        status
        plan {
          id
          name
        }
      }
    }
  }
}
    `;
export type SignupMemberEmailPasswordMutationFn = Apollo.MutationFunction<SignupMemberEmailPasswordMutation, SignupMemberEmailPasswordMutationVariables>;

/**
 * __useSignupMemberEmailPasswordMutation__
 *
 * To run a mutation, you first call `useSignupMemberEmailPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMemberEmailPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMemberEmailPasswordMutation, { data, loading, error }] = useSignupMemberEmailPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMemberEmailPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SignupMemberEmailPasswordMutation, SignupMemberEmailPasswordMutationVariables>) {
        return Apollo.useMutation<SignupMemberEmailPasswordMutation, SignupMemberEmailPasswordMutationVariables>(SignupMemberEmailPasswordDocument, baseOptions);
      }
export type SignupMemberEmailPasswordMutationHookResult = ReturnType<typeof useSignupMemberEmailPasswordMutation>;
export type SignupMemberEmailPasswordMutationResult = Apollo.MutationResult<SignupMemberEmailPasswordMutation>;
export type SignupMemberEmailPasswordMutationOptions = Apollo.BaseMutationOptions<SignupMemberEmailPasswordMutation, SignupMemberEmailPasswordMutationVariables>;
export const CreateStripeCustomerDocument = gql`
    mutation CreateStripeCustomer($input: CreateStripeCustomerInput!) {
  createStripeCustomer(input: $input) {
    id
  }
}
    `;
export type CreateStripeCustomerMutationFn = Apollo.MutationFunction<CreateStripeCustomerMutation, CreateStripeCustomerMutationVariables>;

/**
 * __useCreateStripeCustomerMutation__
 *
 * To run a mutation, you first call `useCreateStripeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCustomerMutation, { data, loading, error }] = useCreateStripeCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCustomerMutation, CreateStripeCustomerMutationVariables>) {
        return Apollo.useMutation<CreateStripeCustomerMutation, CreateStripeCustomerMutationVariables>(CreateStripeCustomerDocument, baseOptions);
      }
export type CreateStripeCustomerMutationHookResult = ReturnType<typeof useCreateStripeCustomerMutation>;
export type CreateStripeCustomerMutationResult = Apollo.MutationResult<CreateStripeCustomerMutation>;
export type CreateStripeCustomerMutationOptions = Apollo.BaseMutationOptions<CreateStripeCustomerMutation, CreateStripeCustomerMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($input: UpdateMemberInput!) {
  updateMember(input: $input) {
    id
    customFields
  }
}
    `;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, baseOptions);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const UpdateMemberAuthDocument = gql`
    mutation UpdateMemberAuth($input: UpdateMemberAuthInput!) {
  updateMemberAuth(input: $input) {
    id
    auth {
      email
    }
  }
}
    `;
export type UpdateMemberAuthMutationFn = Apollo.MutationFunction<UpdateMemberAuthMutation, UpdateMemberAuthMutationVariables>;

/**
 * __useUpdateMemberAuthMutation__
 *
 * To run a mutation, you first call `useUpdateMemberAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberAuthMutation, { data, loading, error }] = useUpdateMemberAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberAuthMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberAuthMutation, UpdateMemberAuthMutationVariables>) {
        return Apollo.useMutation<UpdateMemberAuthMutation, UpdateMemberAuthMutationVariables>(UpdateMemberAuthDocument, baseOptions);
      }
export type UpdateMemberAuthMutationHookResult = ReturnType<typeof useUpdateMemberAuthMutation>;
export type UpdateMemberAuthMutationResult = Apollo.MutationResult<UpdateMemberAuthMutation>;
export type UpdateMemberAuthMutationOptions = Apollo.BaseMutationOptions<UpdateMemberAuthMutation, UpdateMemberAuthMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation deleteMember($input: DeleteMemberInput!) {
  deleteMember(input: $input)
}
    `;
export type DeleteMemberMutationFn = Apollo.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        return Apollo.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, baseOptions);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const GenerateMemberPasswordDocument = gql`
    mutation generateMemberPassword($input: generateMemberPasswordInput!) {
  generateMemberPassword(input: $input) {
    generatedPassword
  }
}
    `;
export type GenerateMemberPasswordMutationFn = Apollo.MutationFunction<GenerateMemberPasswordMutation, GenerateMemberPasswordMutationVariables>;

/**
 * __useGenerateMemberPasswordMutation__
 *
 * To run a mutation, you first call `useGenerateMemberPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMemberPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMemberPasswordMutation, { data, loading, error }] = useGenerateMemberPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMemberPasswordMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMemberPasswordMutation, GenerateMemberPasswordMutationVariables>) {
        return Apollo.useMutation<GenerateMemberPasswordMutation, GenerateMemberPasswordMutationVariables>(GenerateMemberPasswordDocument, baseOptions);
      }
export type GenerateMemberPasswordMutationHookResult = ReturnType<typeof useGenerateMemberPasswordMutation>;
export type GenerateMemberPasswordMutationResult = Apollo.MutationResult<GenerateMemberPasswordMutation>;
export type GenerateMemberPasswordMutationOptions = Apollo.BaseMutationOptions<GenerateMemberPasswordMutation, GenerateMemberPasswordMutationVariables>;
export const ImportMembersDocument = gql`
    mutation importMembers($input: ImportMembersInput!) {
  importMembers(input: $input) {
    jobId
    totalPendingJobs
  }
}
    `;
export type ImportMembersMutationFn = Apollo.MutationFunction<ImportMembersMutation, ImportMembersMutationVariables>;

/**
 * __useImportMembersMutation__
 *
 * To run a mutation, you first call `useImportMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importMembersMutation, { data, loading, error }] = useImportMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportMembersMutation(baseOptions?: Apollo.MutationHookOptions<ImportMembersMutation, ImportMembersMutationVariables>) {
        return Apollo.useMutation<ImportMembersMutation, ImportMembersMutationVariables>(ImportMembersDocument, baseOptions);
      }
export type ImportMembersMutationHookResult = ReturnType<typeof useImportMembersMutation>;
export type ImportMembersMutationResult = Apollo.MutationResult<ImportMembersMutation>;
export type ImportMembersMutationOptions = Apollo.BaseMutationOptions<ImportMembersMutation, ImportMembersMutationVariables>;
export const RemoveAllMembersDocument = gql`
    mutation removeAllMembers($input: RemoveAllMembersInput!) {
  removeAllMembers(input: $input) {
    jobId
  }
}
    `;
export type RemoveAllMembersMutationFn = Apollo.MutationFunction<RemoveAllMembersMutation, RemoveAllMembersMutationVariables>;

/**
 * __useRemoveAllMembersMutation__
 *
 * To run a mutation, you first call `useRemoveAllMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllMembersMutation, { data, loading, error }] = useRemoveAllMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAllMembersMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAllMembersMutation, RemoveAllMembersMutationVariables>) {
        return Apollo.useMutation<RemoveAllMembersMutation, RemoveAllMembersMutationVariables>(RemoveAllMembersDocument, baseOptions);
      }
export type RemoveAllMembersMutationHookResult = ReturnType<typeof useRemoveAllMembersMutation>;
export type RemoveAllMembersMutationResult = Apollo.MutationResult<RemoveAllMembersMutation>;
export type RemoveAllMembersMutationOptions = Apollo.BaseMutationOptions<RemoveAllMembersMutation, RemoveAllMembersMutationVariables>;
export const ResyncStripeMemberDocument = gql`
    mutation resyncStripeMember($input: ResyncStripeMemberInput!) {
  resyncStripeMember(input: $input) {
    jobId
  }
}
    `;
export type ResyncStripeMemberMutationFn = Apollo.MutationFunction<ResyncStripeMemberMutation, ResyncStripeMemberMutationVariables>;

/**
 * __useResyncStripeMemberMutation__
 *
 * To run a mutation, you first call `useResyncStripeMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncStripeMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncStripeMemberMutation, { data, loading, error }] = useResyncStripeMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResyncStripeMemberMutation(baseOptions?: Apollo.MutationHookOptions<ResyncStripeMemberMutation, ResyncStripeMemberMutationVariables>) {
        return Apollo.useMutation<ResyncStripeMemberMutation, ResyncStripeMemberMutationVariables>(ResyncStripeMemberDocument, baseOptions);
      }
export type ResyncStripeMemberMutationHookResult = ReturnType<typeof useResyncStripeMemberMutation>;
export type ResyncStripeMemberMutationResult = Apollo.MutationResult<ResyncStripeMemberMutation>;
export type ResyncStripeMemberMutationOptions = Apollo.BaseMutationOptions<ResyncStripeMemberMutation, ResyncStripeMemberMutationVariables>;
export const RemoveSelectMembersDocument = gql`
    mutation removeSelectMembers($input: RemoveSelectMembersInput!) {
  removeSelectMembers(input: $input) {
    count
  }
}
    `;
export type RemoveSelectMembersMutationFn = Apollo.MutationFunction<RemoveSelectMembersMutation, RemoveSelectMembersMutationVariables>;

/**
 * __useRemoveSelectMembersMutation__
 *
 * To run a mutation, you first call `useRemoveSelectMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSelectMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSelectMembersMutation, { data, loading, error }] = useRemoveSelectMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSelectMembersMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSelectMembersMutation, RemoveSelectMembersMutationVariables>) {
        return Apollo.useMutation<RemoveSelectMembersMutation, RemoveSelectMembersMutationVariables>(RemoveSelectMembersDocument, baseOptions);
      }
export type RemoveSelectMembersMutationHookResult = ReturnType<typeof useRemoveSelectMembersMutation>;
export type RemoveSelectMembersMutationResult = Apollo.MutationResult<RemoveSelectMembersMutation>;
export type RemoveSelectMembersMutationOptions = Apollo.BaseMutationOptions<RemoveSelectMembersMutation, RemoveSelectMembersMutationVariables>;
export const UpdateMemberNoteDocument = gql`
    mutation updateMemberNote($input: UpdateMemberNoteInput!) {
  updateMemberNote(input: $input) {
    id
    memberNotes {
      note
      updatedAt
    }
  }
}
    `;
export type UpdateMemberNoteMutationFn = Apollo.MutationFunction<UpdateMemberNoteMutation, UpdateMemberNoteMutationVariables>;

/**
 * __useUpdateMemberNoteMutation__
 *
 * To run a mutation, you first call `useUpdateMemberNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberNoteMutation, { data, loading, error }] = useUpdateMemberNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberNoteMutation, UpdateMemberNoteMutationVariables>) {
        return Apollo.useMutation<UpdateMemberNoteMutation, UpdateMemberNoteMutationVariables>(UpdateMemberNoteDocument, baseOptions);
      }
export type UpdateMemberNoteMutationHookResult = ReturnType<typeof useUpdateMemberNoteMutation>;
export type UpdateMemberNoteMutationResult = Apollo.MutationResult<UpdateMemberNoteMutation>;
export type UpdateMemberNoteMutationOptions = Apollo.BaseMutationOptions<UpdateMemberNoteMutation, UpdateMemberNoteMutationVariables>;
export const ExportMembersDocument = gql`
    mutation ExportMembers($input: ExportMembersInput!) {
  exportMembers(input: $input) {
    jobId
  }
}
    `;
export type ExportMembersMutationFn = Apollo.MutationFunction<ExportMembersMutation, ExportMembersMutationVariables>;

/**
 * __useExportMembersMutation__
 *
 * To run a mutation, you first call `useExportMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportMembersMutation, { data, loading, error }] = useExportMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportMembersMutation(baseOptions?: Apollo.MutationHookOptions<ExportMembersMutation, ExportMembersMutationVariables>) {
        return Apollo.useMutation<ExportMembersMutation, ExportMembersMutationVariables>(ExportMembersDocument, baseOptions);
      }
export type ExportMembersMutationHookResult = ReturnType<typeof useExportMembersMutation>;
export type ExportMembersMutationResult = Apollo.MutationResult<ExportMembersMutation>;
export type ExportMembersMutationOptions = Apollo.BaseMutationOptions<ExportMembersMutation, ExportMembersMutationVariables>;
export const GetMembersCountDocument = gql`
    query GetMembersCount {
  getMembersCount
}
    `;

/**
 * __useGetMembersCountQuery__
 *
 * To run a query within a React component, call `useGetMembersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembersCountQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersCountQuery, GetMembersCountQueryVariables>) {
        return Apollo.useQuery<GetMembersCountQuery, GetMembersCountQueryVariables>(GetMembersCountDocument, baseOptions);
      }
export function useGetMembersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersCountQuery, GetMembersCountQueryVariables>) {
          return Apollo.useLazyQuery<GetMembersCountQuery, GetMembersCountQueryVariables>(GetMembersCountDocument, baseOptions);
        }
export type GetMembersCountQueryHookResult = ReturnType<typeof useGetMembersCountQuery>;
export type GetMembersCountLazyQueryHookResult = ReturnType<typeof useGetMembersCountLazyQuery>;
export type GetMembersCountQueryResult = Apollo.QueryResult<GetMembersCountQuery, GetMembersCountQueryVariables>;
export const GetMembersDocument = gql`
    query GetMembers($after: String, $order: OrderByInput, $first: Int, $search: String, $filters: MemberFilter) {
  getMembers(
    after: $after
    order: $order
    first: $first
    search: $search
    filters: $filters
  ) {
    mode
    edges {
      cursor
      node {
        id
        auth {
          email
        }
        trustStatus
        profileImage
        customFields
        createdAt
        lastLogin
        isNew
        planConnections {
          id
          status
          plan {
            id
            name
          }
          team {
            isTeamPlan
            isTeamOwner
            teamId
            teamOwnerId
          }
        }
      }
    }
    pageInfo {
      endCursor
    }
  }
}
    `;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, baseOptions);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, baseOptions);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const GetMemberDocument = gql`
    query GetMember($id: ID!) {
  currentMember(id: $id) {
    id
    auth {
      email
      connectedProviders {
        provider
      }
    }
    trustStatus
    profileImage
    createdAt
    lastLogin
    customFields
    stripeCustomerId
    metaData
    json
    loginRedirect
    verified
    isModerator
    memberNotes {
      note
      updatedAt
    }
    planConnections {
      id
      plan {
        id
        name
      }
      active
      status
      type
      payment {
        amount
        currency
        status
        priceId
        interval {
          type
          count
        }
        cancelAtDate
        pauseCollection
        pauseResumeAt
      }
      team {
        isTeamPlan
        isTeamOwner
        teamId
        teamOwnerId
      }
    }
    permissions {
      all
      manuallyGranted
      planInherited {
        permission
        plans {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetMemberQuery__
 *
 * To run a query within a React component, call `useGetMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberQuery(baseOptions: Apollo.QueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
        return Apollo.useQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, baseOptions);
      }
export function useGetMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
          return Apollo.useLazyQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, baseOptions);
        }
export type GetMemberQueryHookResult = ReturnType<typeof useGetMemberQuery>;
export type GetMemberLazyQueryHookResult = ReturnType<typeof useGetMemberLazyQuery>;
export type GetMemberQueryResult = Apollo.QueryResult<GetMemberQuery, GetMemberQueryVariables>;
export const GetLoginWithMemberTokenDocument = gql`
    query GetLoginWithMemberToken($input: GetLoginWithMemberTokenInput!) {
  getLoginWithMemberToken(input: $input)
}
    `;

/**
 * __useGetLoginWithMemberTokenQuery__
 *
 * To run a query within a React component, call `useGetLoginWithMemberTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginWithMemberTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginWithMemberTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLoginWithMemberTokenQuery(baseOptions: Apollo.QueryHookOptions<GetLoginWithMemberTokenQuery, GetLoginWithMemberTokenQueryVariables>) {
        return Apollo.useQuery<GetLoginWithMemberTokenQuery, GetLoginWithMemberTokenQueryVariables>(GetLoginWithMemberTokenDocument, baseOptions);
      }
export function useGetLoginWithMemberTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoginWithMemberTokenQuery, GetLoginWithMemberTokenQueryVariables>) {
          return Apollo.useLazyQuery<GetLoginWithMemberTokenQuery, GetLoginWithMemberTokenQueryVariables>(GetLoginWithMemberTokenDocument, baseOptions);
        }
export type GetLoginWithMemberTokenQueryHookResult = ReturnType<typeof useGetLoginWithMemberTokenQuery>;
export type GetLoginWithMemberTokenLazyQueryHookResult = ReturnType<typeof useGetLoginWithMemberTokenLazyQuery>;
export type GetLoginWithMemberTokenQueryResult = Apollo.QueryResult<GetLoginWithMemberTokenQuery, GetLoginWithMemberTokenQueryVariables>;
export const GetMemberMetricsByReportDocument = gql`
    query getMemberMetricsByReport($report: MemberMetricsReport!) {
  getMemberMetricsByReport(report: $report)
}
    `;

/**
 * __useGetMemberMetricsByReportQuery__
 *
 * To run a query within a React component, call `useGetMemberMetricsByReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberMetricsByReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberMetricsByReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function useGetMemberMetricsByReportQuery(baseOptions: Apollo.QueryHookOptions<GetMemberMetricsByReportQuery, GetMemberMetricsByReportQueryVariables>) {
        return Apollo.useQuery<GetMemberMetricsByReportQuery, GetMemberMetricsByReportQueryVariables>(GetMemberMetricsByReportDocument, baseOptions);
      }
export function useGetMemberMetricsByReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberMetricsByReportQuery, GetMemberMetricsByReportQueryVariables>) {
          return Apollo.useLazyQuery<GetMemberMetricsByReportQuery, GetMemberMetricsByReportQueryVariables>(GetMemberMetricsByReportDocument, baseOptions);
        }
export type GetMemberMetricsByReportQueryHookResult = ReturnType<typeof useGetMemberMetricsByReportQuery>;
export type GetMemberMetricsByReportLazyQueryHookResult = ReturnType<typeof useGetMemberMetricsByReportLazyQuery>;
export type GetMemberMetricsByReportQueryResult = Apollo.QueryResult<GetMemberMetricsByReportQuery, GetMemberMetricsByReportQueryVariables>;
export const MemberMetricsDocument = gql`
    query memberMetrics($input: GetMemberMetricsInput!) {
  getMemberMetrics(input: $input)
}
    `;

/**
 * __useMemberMetricsQuery__
 *
 * To run a query within a React component, call `useMemberMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberMetricsQuery(baseOptions: Apollo.QueryHookOptions<MemberMetricsQuery, MemberMetricsQueryVariables>) {
        return Apollo.useQuery<MemberMetricsQuery, MemberMetricsQueryVariables>(MemberMetricsDocument, baseOptions);
      }
export function useMemberMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberMetricsQuery, MemberMetricsQueryVariables>) {
          return Apollo.useLazyQuery<MemberMetricsQuery, MemberMetricsQueryVariables>(MemberMetricsDocument, baseOptions);
        }
export type MemberMetricsQueryHookResult = ReturnType<typeof useMemberMetricsQuery>;
export type MemberMetricsLazyQueryHookResult = ReturnType<typeof useMemberMetricsLazyQuery>;
export type MemberMetricsQueryResult = Apollo.QueryResult<MemberMetricsQuery, MemberMetricsQueryVariables>;
export const GetAccessLogsDocument = gql`
    query getAccessLogs {
  getAccessLogs {
    timestamp
    ip
    user {
      id
      auth {
        email
      }
      profile {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetAccessLogsQuery__
 *
 * To run a query within a React component, call `useGetAccessLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessLogsQuery, GetAccessLogsQueryVariables>) {
        return Apollo.useQuery<GetAccessLogsQuery, GetAccessLogsQueryVariables>(GetAccessLogsDocument, baseOptions);
      }
export function useGetAccessLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessLogsQuery, GetAccessLogsQueryVariables>) {
          return Apollo.useLazyQuery<GetAccessLogsQuery, GetAccessLogsQueryVariables>(GetAccessLogsDocument, baseOptions);
        }
export type GetAccessLogsQueryHookResult = ReturnType<typeof useGetAccessLogsQuery>;
export type GetAccessLogsLazyQueryHookResult = ReturnType<typeof useGetAccessLogsLazyQuery>;
export type GetAccessLogsQueryResult = Apollo.QueryResult<GetAccessLogsQuery, GetAccessLogsQueryVariables>;
export const GetAppEventsDocument = gql`
    query getAppEvents {
  getAppEvents {
    timestamp
    ip
    type
    user {
      id
      auth {
        email
      }
      profile {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetAppEventsQuery__
 *
 * To run a query within a React component, call `useGetAppEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppEventsQuery, GetAppEventsQueryVariables>) {
        return Apollo.useQuery<GetAppEventsQuery, GetAppEventsQueryVariables>(GetAppEventsDocument, baseOptions);
      }
export function useGetAppEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppEventsQuery, GetAppEventsQueryVariables>) {
          return Apollo.useLazyQuery<GetAppEventsQuery, GetAppEventsQueryVariables>(GetAppEventsDocument, baseOptions);
        }
export type GetAppEventsQueryHookResult = ReturnType<typeof useGetAppEventsQuery>;
export type GetAppEventsLazyQueryHookResult = ReturnType<typeof useGetAppEventsLazyQuery>;
export type GetAppEventsQueryResult = Apollo.QueryResult<GetAppEventsQuery, GetAppEventsQueryVariables>;
export const GetTeamMembersDocument = gql`
    query GetTeamMembers($input: GetTeamMembersInput!) {
  getTeamMembers(input: $input) {
    role
    member {
      id
      auth {
        email
      }
    }
  }
}
    `;

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
        return Apollo.useQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, baseOptions);
      }
export function useGetTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, baseOptions);
        }
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>;
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>;
export type GetTeamMembersQueryResult = Apollo.QueryResult<GetTeamMembersQuery, GetTeamMembersQueryVariables>;
export const RemoveTeamMemberDocument = gql`
    mutation removeTeamMember($input: RemoveTeamMemberInput!) {
  removeTeamMember(input: $input)
}
    `;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>) {
        return Apollo.useMutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, baseOptions);
      }
export type RemoveTeamMemberMutationHookResult = ReturnType<typeof useRemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationResult = Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;
export const GetTeamDocument = gql`
    query GetTeam($input: GetTeamInput!) {
  getTeam(input: $input) {
    id
    inviteToken
    maxTeamMembers
    currentTeamMemberCount
    inviteLink
  }
}
    `;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, baseOptions);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, baseOptions);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;