import React from "react";
import { TitleSection } from "components/SettingsBar";
import { Button } from "components";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { useParams } from "react-router-dom";
import InputLoading from "components/Input/Input.loading";

export const ErrorMessagesLoader = () => {
  const { appId } = useParams<{ appId: string }>();

  return (
    <div>
      <TitleSection
        title="Edit Error Messages"
        backLink={`/${appId}/settings/translations`}
        backLinkTitle="Translations"
        emphasize
        gutter="medium"
        buttons={<Button text="Save" onClick={undefined} />}
      />
      <div className="w-1/2 py-7 px-5">
        <ProgressBar progress={0} tw="mb-5" />
        <form onSubmit={undefined}>
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
          <InputLoading tw="mb-5" />
        </form>
      </div>
    </div>
  );
};
