import React, { useState } from "react";
import { Modal, ModalTypes } from "components/Modal";
import { Checkbox } from "components";
import { useUpdateAppMutation } from "features";
import { useAppDataContext } from "routes/AppDataContextProvider";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";

export const ConcurrentLoginModal = ({
  showModal,
  setShowModal,
}: ModalTypes) => {
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const { refetch } = useAppDataContext();

  const [updateAppInfo, { loading }] = useUpdateAppMutation();

  const { submit } = useOnSubmit({
    action: updateAppInfo,
    fields: { disableConcurrentLogins: true },
    cleanup: () => setShowModal(false),
    errorMsg: "Unable to disable concurrent logins.",
    refetch,
  });

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Disable Concurrent Logins"
      showDivider
      actionButtons={{
        confirm: {
          label: "Confirm",
          isLoading: loading,
          onConfirm: () => submit(),
          isDisabled: !isAcknowledged,
        },
      }}
    >
      <p tw="text-base mb-6">
        <span tw="font-bold">
          By disabling concurrent logins, all members will be logged out and
          must login again.
        </span>
      </p>
      <Checkbox
        tw="mt-6"
        label="I understand that all members will be logged out and must login again after this change."
        checked={isAcknowledged}
        id="concurrent-login-acknowledgement"
        onChange={() => setIsAcknowledged(!isAcknowledged)}
      />
    </Modal>
  );
};
