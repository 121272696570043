import { ApolloClient, ApolloLink, from, gql } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { MemberstackEnv } from "generatedTypes";
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";
import { useUserContext } from "containers/Layouts/PrivateLayout/UserContextProvider";
import { cache } from "./cache";

// This is for local-only fields
const typeDefs = gql`
  extend type Price {
    isPersisted: Boolean!
  }

  extend type Plan {
    isPersisted: Boolean!
  }
`;

const getEnv = (env) => (env === MemberstackEnv.Sandbox ? "sandbox" : "live");

const getAppId = () => {
  const pathParts = window.location.pathname.split("/");
  const appIndex = pathParts.indexOf("apps");

  if (!pathParts.includes("apps")) {
    // This is used for the Stripe connection page.
    // The app id is available on the redirect URI state (?state=app_....)
    return new URLSearchParams(window.location.search).get("state");
  }

  return pathParts[appIndex + 1];
};

const httpLink = (env: MemberstackEnv) =>
  createUploadLink({
    uri: `${process.env.API_ENDPOINT}?mode=${getEnv(env)}`,
    credentials: "include",
    // "Apollo-Require-Preflight": "true",
  });

const logoutLink = onError(({ networkError }) => {
  const { logout } = useUserContext();

  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401
  ) {
    logout();
  }
});

const clientProps = {
  connectToDevTools: true,
  typeDefs,
  resolvers: {
    Price: {
      // Set defaults for local-only fields
      isPersisted: () => true,
    },
    Plan: {
      isPersisted: () => true,
    },
  },
};

const createHttpLink = (env: MemberstackEnv) =>
  ApolloLink.from([
    new ApolloLink((operation, forward) => {
      const appId = getAppId();

      operation.setContext({
        headers: {
          ...(appId && { "ms-app-id": getAppId() }),
          "ms-mode": getEnv(env),
        },
      });

      return forward(operation);
    }),
    // @ts-ignore
    httpLink(env),
    logoutLink,
  ]);

export const getClient = (env: MemberstackEnv) => {
  return new ApolloClient({
    cache,
    link: from([createHttpLink(env)]),
    ...clientProps,
  });
};
