import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 16px auto;
  grid-template-rows: 16px auto;
  grid-column-gap: 8px;
`;

export const DescriptionContainer = styled.div`
  margin-top: 4px;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
`;

export const GroupContainer = styled.div<{ gap: number }>`
  & > :not(:last-child) {
    margin-bottom: ${(props) => props.gap || "24"}px;
  }
`;

export const RadioButtonImageContainer = styled.div`
  width: 100%;
  .input-hidden {
    position: absolute;
    left: -9999px;
  }

  input[type="radio"]:checked + label > * {
    border: 1px solid #2962ff;
    background: #fff;
  }

  input[type="radio"]:checked + label .selected {
    color: #0001c0;
    fill: #0001c0 !important;
  }

  input[type="radio"]:checked + svg {
    fill: #0001c0 !important;
  }

  /* Stuff after this is only to make things more pretty */
  input[type="radio"] + label > * {
    border: 1px solid #c9c9c9;
    /* width: 150px;
    height: 150px; */
    transition: 500ms all;
    border-radius: 8px;
    background: #fff;
  }

  input[type="radio"]:hover + label > * {
    transform: rotateZ(-1deg) rotateX(1deg) scale(1.02);
  }
`;
