import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import useGenerateComponentID from "hooks/useComponentId";
import { SketchPicker, ColorChangeHandler } from "react-color";

interface ColorPickerProps {
  id?: string;
  value: string;
  label?: string;
  onChange: ColorChangeHandler;
}

export const ColorPicker = ({
  id,
  value,
  label,
  onChange,
}: ColorPickerProps) => {
  const generatedId = useGenerateComponentID();
  const [inputId] = useState(generatedId);

  return (
    <Tippy
      placement="bottom-end"
      content={
        <SketchPicker color={value} onChange={({ hex }) => onChange(hex)} />
      }
      interactive
      trigger="click"
      role="button"
    >
      <div tw="flex cursor-pointer">
        {label && (
          <label tw="mr-auto text-base" htmlFor={id || inputId}>
            {label}
          </label>
        )}
        <button
          style={{ backgroundColor: value }}
          className="cursor-pointer rounded-full h-6 w-6 border border-app-gray300"
          type="button"
        >
          {" "}
        </button>
      </div>
    </Tippy>
  );
};
