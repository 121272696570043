import React, { useMemo } from "react";
import { useTable } from "react-table";
import { useEnvContext } from "containers/App/contexts/Environment";
import Skeleton from "react-loading-skeleton";
import Spacer from "components/Spacer";
import { Banner, Link, Table } from "components";
import { DEFAULT_COLUMNS } from "../../Members.utils";

const MembersTableLoading = () => {
  const { isSandboxEnv } = useEnvContext();

  const columns = useMemo(
    () => [
      ...DEFAULT_COLUMNS.map(({ label, key, icon }) => ({
        accessor: key,
        key,
        label,
        isPlan: key === "plans",
        icon,
      })),
    ],
    []
  );

  const data = [
    {
      createdAt: "24 Jun, 2022",
      email: "loader1@memberstack.com",
      id: "loader1",
      plans: [],
    },
    {
      createdAt: "24 Jun, 2022",
      email: "loader2@memberstack.com",
      id: "loader2",
      plans: [],
    },
    {
      createdAt: "24 Jun, 2022",
      email: "loader3@memberstack.com",
      id: "loader3",
      plans: [],
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: [data, ...data, ...data],
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any;

  return (
    <>
      <div className="flex flex-col m-5">
        <div className="flex items-center flex-row justify-between">
          <Skeleton width={120} height={28} />
          <div className="flex">
            <Skeleton width={138} height={32} />
            <Spacer spacing="xSmall" isVertical={false} />
            <Skeleton width={147} height={32} />
          </div>
        </div>
        <Skeleton height={42} className="mt-4" />
        {isSandboxEnv && (
          <Banner
            className="mt-4"
            text="You’re viewing and editing Test Mode members"
            description="Test Mode members cannot be converted to Live Mode members."
            rightPlugin={
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/7595262385819-Managing-Test-Mode-and-Live-Mode"
                target="_blank"
                isBold
                isExternal
              >
                Learn more
              </Link>
            }
          />
        )}
      </div>
      <Table
        numberOfData={rows.length}
        hasMoreData={undefined}
        fetchMoreData={undefined}
        tableProps={getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, id) => (
            <tr key="header" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps({
                    className: column.isPlan ? "plan" : "",
                  })}
                >
                  <div className="flex p-4 items-center w-full justify-between rounded-lg transition-all">
                    <div className="items-center flex">
                      {column.icon}
                      {column.label}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const key = `${cell.column.id}-${row.id}`;
                  return (
                    <td
                      key={key}
                      {...cell.getCellProps({
                        className: cell.column.collapse ? "plan" : "",
                      })}
                    >
                      <Skeleton width={380} height={25} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default MembersTableLoading;
