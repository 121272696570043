export const REDIRECT_MASK = ["afterSignup", "afterLogin", "afterLogout"];
export const REDIRECT_MASK_MESSAGE = "Homepage";

// TODO: uncomment afterPurchase, afterCancel, afterReplace when back-end supports them
export const REDIRECT_MAP = {
  afterSignup: "On Signup / On Purchase",
  afterLogin: "On Login",
  afterLogout: "On Logout",
  verificationRequired: "On Verification Required",
  // afterPurchase: "On Purchase",
  // afterCancel: "On Cancel",
  // afterReplace: "On Replace",
};

export const FREE_PLANS_REDIRECT_MAP = {
  afterSignup: "On Signup",
  afterLogin: "On Login",
  afterLogout: "On Logout",
  verificationRequired: "On Verification Required",
};
