import React from "react";
import Skeleton from "react-loading-skeleton";
import { Item, StyledAccordion, StyledAccordionItem } from "./Accordion.styles";

const AccordionLoader = ({ hasBorder = false, noOfItems = 3 }) => {
  const items = new Array(noOfItems).fill("");

  const accordionItems = items.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Item hasBorder={hasBorder} key={index} hasOuterBorder={false}>
      <StyledAccordionItem
        aria-controls="panel"
        aria-disabled="false"
        id="header"
        selected={false}
        disabled
      >
        <div className="flex items-center">
          <Skeleton width={229} height={28} />
        </div>
      </StyledAccordionItem>
    </Item>
  ));

  return (
    <StyledAccordion striped={hasBorder} disabled>
      {accordionItems}
    </StyledAccordion>
  );
};

export default AccordionLoader;
