import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ImportStripeProductMutationVariables = Types.Exact<{
  input: Types.ImportStripeProductInput;
}>;


export type ImportStripeProductMutation = (
  { __typename?: 'Mutation' }
  & { importStripeProduct: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
  ) }
);


export const ImportStripeProductDocument = gql`
    mutation ImportStripeProduct($input: ImportStripeProductInput!) {
  importStripeProduct(input: $input) {
    id
  }
}
    `;
export type ImportStripeProductMutationFn = Apollo.MutationFunction<ImportStripeProductMutation, ImportStripeProductMutationVariables>;

/**
 * __useImportStripeProductMutation__
 *
 * To run a mutation, you first call `useImportStripeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportStripeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importStripeProductMutation, { data, loading, error }] = useImportStripeProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportStripeProductMutation(baseOptions?: Apollo.MutationHookOptions<ImportStripeProductMutation, ImportStripeProductMutationVariables>) {
        return Apollo.useMutation<ImportStripeProductMutation, ImportStripeProductMutationVariables>(ImportStripeProductDocument, baseOptions);
      }
export type ImportStripeProductMutationHookResult = ReturnType<typeof useImportStripeProductMutation>;
export type ImportStripeProductMutationResult = Apollo.MutationResult<ImportStripeProductMutation>;
export type ImportStripeProductMutationOptions = Apollo.BaseMutationOptions<ImportStripeProductMutation, ImportStripeProductMutationVariables>;