import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ApolloQueryResult } from "@apollo/client";
import useSubmit from "hooks/useSubmit";
import { Modal, ModalTypes } from "components/Modal";
import Text from "components/Text";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { useTransferAppOwnershipMutation } from "features";

type TransferMembershipModalProps = ModalTypes & {
  refetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  user: {
    name: string;
    id: string;
  };
};

const TransferMembershipModal = ({
  showModal,
  setShowModal,
  refetch,
  user,
}: TransferMembershipModalProps) => {
  const { currentUser } = useUsersContext();
  const { appId } = useParams<{ appId: string }>();
  const [transfer] = useTransferAppOwnershipMutation();

  const appName = useMemo(
    () =>
      currentUser?.appConnections.find(
        (appConnection) => appConnection.app.id === appId
      )?.app.name,
    [appId, currentUser?.appConnections]
  );

  const { submit, isSubmitting } = useSubmit({
    action: transfer,
    refetch,
    fields: {
      userId: user?.id,
    },
    cleanup: () => setShowModal(false),
    success: "Ownership transferred successfully",
    error: "Ownership could not be transferred",
  });

  const handleTransfer = () => {
    submit();
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Transfer Ownership"
      showDivider
      width="430px"
      actionButtons={{
        confirm: {
          label: "Confirm and Transfer",
          onConfirm: handleTransfer,
          isLoading: isSubmitting,
        },
      }}
    >
      <Text>
        This step is not reversible. This will transfer the project ownership
        and all rights for <b>{appName}</b> to {user?.name} permanently.
      </Text>
    </Modal>
  );
};

export default TransferMembershipModal;
