import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetPlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPlansQuery = (
  { __typename?: 'Query' }
  & { getPlans?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id' | 'name' | 'icon' | 'description' | 'status' | 'isPaid' | 'isPersisted' | 'memberCount' | 'priority' | 'allowedDomains'>
    & { permissions?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Permission' }
      & Pick<Types.Permission, 'id' | 'name' | 'description'>
    )>>>, prices?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Price' }
      & Pick<Types.Price, 'id' | 'name' | 'amount' | 'isPersisted' | 'active' | 'status' | 'type'>
    )>>> }
  )>>> }
);

export type GetPlanQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPlanQuery = (
  { __typename?: 'Query' }
  & { getPlan?: Types.Maybe<(
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id' | 'name' | 'icon' | 'description' | 'status' | 'isPaid' | 'isPersisted' | 'copiedToLive' | 'memberCount' | 'priority' | 'allowedDomains' | 'limitMembers' | 'memberLimit' | 'teamAccountsEnabled' | 'teamAccountUpgradeLink' | 'teamAccountInviteSignupLink'>
    & { permissions?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Permission' }
      & Pick<Types.Permission, 'id' | 'name' | 'description'>
    )>>>, permissionGroups?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'PermissionGroup' }
      & Pick<Types.PermissionGroup, 'id' | 'name'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Types.Permission, 'id' | 'name' | 'description'>
      )> }
    )>>>, prices?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Price' }
      & Pick<Types.Price, 'id' | 'amount' | 'name' | 'isPersisted' | 'type' | 'description' | 'active' | 'status' | 'currency' | 'memberCount' | 'taxType' | 'maxTeamMembers' | 'teamAccountsEnabled'>
      & { interval?: Types.Maybe<(
        { __typename?: 'PriceInterval' }
        & Pick<Types.PriceInterval, 'type' | 'count'>
      )>, setupFee?: Types.Maybe<(
        { __typename?: 'PriceSetupFee' }
        & Pick<Types.PriceSetupFee, 'enabled' | 'name' | 'amount'>
      )>, freeTrial?: Types.Maybe<(
        { __typename?: 'PriceFreeTrial' }
        & Pick<Types.PriceFreeTrial, 'enabled' | 'days'>
      )>, expiration?: Types.Maybe<(
        { __typename?: 'PriceExpiration' }
        & Pick<Types.PriceExpiration, 'count' | 'interval'>
      )> }
    )>>>, redirects?: Types.Maybe<(
      { __typename?: 'Redirects' }
      & Pick<Types.Redirects, 'afterSignup' | 'afterLogin' | 'afterLogout' | 'afterPurchase' | 'afterCancel' | 'afterReplace' | 'verificationRequired'>
    )>, logic?: Types.Maybe<(
      { __typename?: 'PlanLogic' }
      & { addedLogic?: Types.Maybe<(
        { __typename?: 'PlanLogicSingle' }
        & Pick<Types.PlanLogicSingle, 'add' | 'remove' | 'cancelRecurring' | 'removeAllFree' | 'cancelAllRecurring'>
      )>, removedLogic?: Types.Maybe<(
        { __typename?: 'PlanLogicSingle' }
        & Pick<Types.PlanLogicSingle, 'add' | 'remove' | 'cancelRecurring' | 'removeAllFree' | 'cancelAllRecurring'>
      )> }
    )> }
  )> }
);

export type GetHasPaidPlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetHasPaidPlansQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getHasPaidPlans'>
);


export const GetPlansDocument = gql`
    query GetPlans {
  getPlans {
    id
    name
    icon
    description
    status
    permissions {
      id
      name
      description
    }
    prices {
      id
      name
      amount
      isPersisted @client
      active
      status
      type
    }
    isPaid
    isPersisted @client
    memberCount
    priority
    allowedDomains
  }
}
    `;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
        return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, baseOptions);
      }
export function useGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
          return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, baseOptions);
        }
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<typeof useGetPlansLazyQuery>;
export type GetPlansQueryResult = Apollo.QueryResult<GetPlansQuery, GetPlansQueryVariables>;
export const GetPlanDocument = gql`
    query GetPlan($id: ID!) {
  getPlan(id: $id) {
    id
    name
    icon
    description
    status
    permissions {
      id
      name
      description
    }
    permissionGroups {
      id
      name
      permissions {
        id
        name
        description
      }
    }
    prices {
      id
      amount
      name
      isPersisted @client
      type
      description
      active
      status
      currency
      interval {
        type
        count
      }
      setupFee {
        enabled
        name
        amount
      }
      freeTrial {
        enabled
        days
      }
      expiration {
        count
        interval
      }
      memberCount
      taxType
      maxTeamMembers
      teamAccountsEnabled
    }
    isPaid
    isPersisted @client
    copiedToLive
    redirects {
      afterSignup
      afterLogin
      afterLogout
      afterPurchase
      afterCancel
      afterReplace
      verificationRequired
    }
    memberCount
    priority
    allowedDomains
    limitMembers
    memberLimit
    logic {
      addedLogic {
        add
        remove
        cancelRecurring
        removeAllFree
        cancelAllRecurring
      }
      removedLogic {
        add
        remove
        cancelRecurring
        removeAllFree
        cancelAllRecurring
      }
    }
    teamAccountsEnabled
    teamAccountUpgradeLink
    teamAccountInviteSignupLink
  }
}
    `;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, baseOptions);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, baseOptions);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const GetHasPaidPlansDocument = gql`
    query getHasPaidPlans {
  getHasPaidPlans
}
    `;

/**
 * __useGetHasPaidPlansQuery__
 *
 * To run a query within a React component, call `useGetHasPaidPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHasPaidPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHasPaidPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHasPaidPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetHasPaidPlansQuery, GetHasPaidPlansQueryVariables>) {
        return Apollo.useQuery<GetHasPaidPlansQuery, GetHasPaidPlansQueryVariables>(GetHasPaidPlansDocument, baseOptions);
      }
export function useGetHasPaidPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHasPaidPlansQuery, GetHasPaidPlansQueryVariables>) {
          return Apollo.useLazyQuery<GetHasPaidPlansQuery, GetHasPaidPlansQueryVariables>(GetHasPaidPlansDocument, baseOptions);
        }
export type GetHasPaidPlansQueryHookResult = ReturnType<typeof useGetHasPaidPlansQuery>;
export type GetHasPaidPlansLazyQueryHookResult = ReturnType<typeof useGetHasPaidPlansLazyQuery>;
export type GetHasPaidPlansQueryResult = Apollo.QueryResult<GetHasPaidPlansQuery, GetHasPaidPlansQueryVariables>;