import React from "react";
import * as S from "./Button.styles";

export type ButtonStyleTypes =
  | "default"
  | "transparent"
  | "skeleton"
  | "danger"
  | "inverted"
  | "icon";

export interface ButtonProps {
  id?: string;
  text?: string;
  name?: string;
  type?: "button" | "submit" | "reset";
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonStyle?: ButtonStyleTypes;
  isDisabled?: boolean;
  isLoading?: boolean;
  noOutline?: boolean;
  hideFocusedOutline?: boolean;
  className?: string;
  noPadding?: boolean;
  dataCy?: string;
  onDrag?: React.DragEventHandler<HTMLButtonElement>;
  onDragEnter?: React.DragEventHandler<HTMLButtonElement>;
  onDragLeave?: React.DragEventHandler<HTMLButtonElement>;
  onDragOver?: React.DragEventHandler<HTMLButtonElement>;
  isPulsing?: boolean;
  dataTestId?: string;
  style?: React.CSSProperties;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      text,
      type,
      leftIcon,
      rightIcon,
      onClick,
      buttonStyle,
      isDisabled,
      isLoading,
      noOutline,
      className,
      noPadding,
      dataCy,
      onDrag,
      onDragEnter,
      onDragLeave,
      onDragOver,
      isPulsing = false,
      name,
      hideFocusedOutline,
      dataTestId,
      style,
    }: ButtonProps,
    ref
  ) => {
    return (
      <S.Button
        id={id}
        ref={ref}
        type={type}
        onClick={onClick}
        variant={buttonStyle}
        disabled={isLoading || isDisabled}
        noOutline={noOutline}
        hideFocusedOutline={hideFocusedOutline}
        className={className}
        noPadding={noPadding}
        data-cy={dataCy}
        onDrag={onDrag}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        isPulsing={isPulsing}
        isLoading={isLoading}
        name={name}
        data-testid={dataTestId}
        style={style}
      >
        {leftIcon}
        {text && (
          <span className="text-utility-md font-bold whitespace-nowrap">
            {text}
          </span>
        )}
        {rightIcon}
      </S.Button>
    );
  }
);

Button.defaultProps = {
  text: "",
  leftIcon: undefined,
  rightIcon: undefined,
  type: "button",
  onClick: null,
  noOutline: false,
  isDisabled: false,
  hideFocusedOutline: false,
  isLoading: false,
  className: undefined,
  buttonStyle: "default",
  noPadding: false,
  style: undefined,
};

Button.displayName = "Button";
