import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Modal, ModalTypes } from "components/Modal";
import Input from "components/Input";
import { InputList, Button, Link } from "components";
import { Add } from "@mui/icons-material";
import { useDeleteSsoAppMutation } from "features";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { getResponseError } from "helpers/getResponseError";
import TextComboInline from "components/TextCombo/TextComboInline";
import SensitiveText from "components/SensitiveText";
import { SsoApp } from "generatedTypes";
import { useDevtoolsContext } from "../context";

interface Props extends ModalTypes {
  ssoApp: SsoApp;
}

const EditSSOAppModal = ({ showModal, setShowModal, ssoApp }: Props) => {
  const [redirectUris, setRedirectUris] = useState([""]);
  const [name, setName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { createToastAlert } = useToastAlertContext();
  const { refetchSSOApps } = useDevtoolsContext();
  const [modalError, setModalError] = useState(null);

  const {
    id,
    name: appName,
    clientId,
    clientSecret,
    redirectUris: appRedirectUris,
  } = ssoApp;

  useEffect(() => {
    setRedirectUris(appRedirectUris);
    setName(appName);
  }, [appName, appRedirectUris]);

  const [deleteSSOApp] = useDeleteSsoAppMutation();

  const handleAddField = useCallback(() => {
    setRedirectUris([...redirectUris, ""]);
  }, [redirectUris]);

  const handleRemove = useCallback(
    (index) => () => {
      const uris = [...redirectUris];
      uris.splice(index, 1);
      setRedirectUris(uris);
    },
    [redirectUris]
  );

  const handleOnChange = useCallback(
    (index) => (event) => {
      const uris = [...redirectUris];
      uris[index] = event.target.value;
      setRedirectUris(uris);
    },
    [redirectUris]
  );

  const inputs = useMemo(
    () =>
      redirectUris.map((uri, idx) => ({
        id: idx,
        disablePadding: true,
        value: uri,
        onChange: handleOnChange(idx),
        onInputDelete: handleRemove(idx),
        disabled: true,
      })),
    [handleOnChange, handleRemove, redirectUris]
  );

  const handleSave = async () => {
    setIsSaving(true);
    createToastAlert({ processing: true });
    try {
      // await createSSOApp({
      //   variables: {
      //     input: {
      //       name,
      //       redirectUris,
      //     },
      //   },
      // });
      await refetchSSOApps();
      createToastAlert({
        alertType: "success",
        message: "SSO Application was successfully updated.",
      });
      setShowModal(false);
    } catch (err) {
      setModalError(getResponseError(err));
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    createToastAlert({ processing: true });
    try {
      await deleteSSOApp({
        variables: {
          input: {
            id,
          },
        },
      });
      await refetchSSOApps();
      setShowModal(false);
      createToastAlert({
        alertType: "success",
        message: "SSO Application was successfully deleted.",
      });
    } catch (err) {
      setModalError(getResponseError(err));
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Your Custom SSO Integration"
      showDivider
      width="560px"
      bottomSectionComponent={
        <Button
          text="Delete Integration"
          buttonStyle="danger"
          onClick={handleDelete}
          isLoading={isDeleting}
        />
      }
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleSave,
          isLoading: isSaving,
          isDisabled: true,
        },
        cancel: { label: "Cancel" },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Input
        label="Integration Name"
        placeholder="Circle Community"
        hideIndicators
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled
        description={`Visible to members when they login. They will see “Login to continue to ${name}."`}
        className="mb-6"
      />
      <InputList
        className="mb-6"
        trailingDeleteButton={false}
        addButtonPosition="bottom"
        inputs={inputs}
        label="Redirect URIs"
        description={
          <>
            Used after authenticating through an external provider. Learn more.{" "}
            <Link
              to="https://docs.memberstack.com/hc/en-us/articles/8161104982043-Custom-SSO-Integrations"
              underline
              isExternal
            >
              Help Docs →
            </Link>
          </>
        }
        placeholder="https://myapp/oauth2/callback"
        disableRemoveInput
        button={
          <Button
            leftIcon={<Add />}
            text="Add Redirect URI"
            onClick={handleAddField}
            buttonStyle="skeleton"
            isDisabled
          />
        }
      />
      <TextComboInline text={clientId} label="Client ID" className="mb-6" />
      <SensitiveText
        secretText={clientSecret}
        label="Secret Key"
        spaceOutContent
      />
    </Modal>
  );
};

export default EditSSOAppModal;
