import React, { lazy, useEffect } from "react";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import * as S from "./styles";
import { testimonials } from "./testimonials.mock";

const Logo = lazy(() => import("assets/images/logo-full.svg"));

interface PublicLayoutProps {
  children: React.ReactNode;
}

export const PublicLayout = ({ children }: PublicLayoutProps) => {
  const firstSetTestimonial = testimonials.slice(0, testimonials.length / 2);
  const otherSetTestimonial = testimonials.slice(testimonials.length / 2);

  useEffect(() => {
    // @ts-ignore
    if (window.google) window.google?.accounts?.id?.prompt();
  }, []);

  return (
    <div className="overflow-hidden p-3 flex min-h-screen max-h-screen bg-gray-50">
      <S.ComponentContainer tw="overflow-x-auto">
        <div className="p-10 pb-8 flex">
          <a href="https://www.memberstack.com/">
            <SuspenseWrapper>
              <Logo />
            </SuspenseWrapper>
          </a>
        </div>
        <div className="px-10 pb-32 flex-1 flex flex-col justify-center">
          {children}
        </div>
      </S.ComponentContainer>
      <S.TestimonialContainer>
        <S.TestimonialList>
          <div>
            {firstSetTestimonial.map((testimony) => (
              <S.TestimonyCard role="listitem" key={testimony.id}>
                <img src={testimony.image} alt={testimony.author} />
                <p className="mb-2">{testimony.testimony}</p>
                <p>- {testimony.author}</p>
              </S.TestimonyCard>
            ))}
          </div>
          <div>
            {otherSetTestimonial.map((testimony) => (
              <S.TestimonyCard role="listitem" key={testimony.id}>
                <img src={testimony.image} alt={testimony.author} />
                <p className="mb-2">{testimony.testimony}</p>
                <p>— {testimony.author}</p>
              </S.TestimonyCard>
            ))}
          </div>
        </S.TestimonialList>
      </S.TestimonialContainer>
    </div>
  );
};
