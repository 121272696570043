import React, { useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { List, Add } from "@mui/icons-material";
import { Button, Dropdown } from "components";
import { TitleSection } from "components/SettingsBar";
import PlanPriorityModal from "./modals/PlanPriorityModal";
import ImportPlanViaStripe from "./modals/ImportPlanViaStripe";

const PlansHeader = () => {
  const { appId } = useParams<{ appId: string; planId: string }>();
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const history = useHistory();

  const QuickActions = useMemo(() => {
    const options = [
      {
        text: "Import via Stripe",
        onClick: () => setShowImportModal(true),
      },
      {
        text: "Edit Plan Priority",
        onClick: () => setShowPlanModal(true),
      },
    ];
    return (
      <div className="flex items-center gap-2">
        <Button
          dataCy="plan-priority"
          text="Priority"
          leftIcon={<List />}
          buttonStyle="skeleton"
          onClick={() => setShowPlanModal(true)}
          tw="hidden xl:flex"
        />
        <Button
          dataCy="add-plan"
          leftIcon={<Add />}
          text="Add plan"
          onClick={() => history.push(`/apps/${appId}/plans/create`)}
        />
        <Dropdown
          tw="hidden xl:block"
          options={[
            {
              text: "Import via Stripe",
              onClick: () => setShowImportModal(true),
            },
          ]}
        />
        <Dropdown tw="xl:hidden" options={options} />
      </div>
    );
  }, [appId, history]);

  return (
    <>
      <TitleSection
        title="Plans"
        buttons={QuickActions}
        emphasize
        gutter="medium"
        divider={false}
      />
      <PlanPriorityModal
        setShowModal={setShowPlanModal}
        showModal={showPlanModal}
      />
      <ImportPlanViaStripe
        setShowModal={setShowImportModal}
        showModal={showImportModal}
      />
    </>
  );
};

export default PlansHeader;
