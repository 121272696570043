import React from "react";
import ImageUploading, {
  ImageListType,
  ImageType,
} from "react-images-uploading";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { AddAPhotoOutlined } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";
import { Label } from "../Label/Label";
import { Avatar } from "../Avatar";
import { Button } from "../Button";

interface Props {
  id?: string;
  text: string;
  onChange: (imageList: ImageListType) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: any;
  label: string;
  description?: string;
}

export const ImageUpload = ({
  id,
  text,
  onChange,
  image,
  label,
  description,
}: Props) => {
  const inputId = id || label.trim();
  return (
    <div className="flex flex-col">
      <Label text={label} htmlFor="applicationImage" />
      {description && (
        <p className="text-body-sm text-app-gray600">{description}</p>
      )}
      <ImageUploading
        multiple={false}
        value={image}
        onChange={onChange}
        inputProps={{ id: inputId }}
      >
        {({ imageList, onImageUpload, isDragging, dragProps }) => (
          <div className="flex items-center mt-3">
            {image ? (
              <Avatar
                src={(imageList as ImageType).dataURL || image}
                alt="logo"
                className="mr-4"
              />
            ) : (
              <AddAPhotoOutlined tw="mr-4 !w-8 !h-8" className="add-photo" />
            )}

            <Button
              onClick={onImageUpload}
              text={text}
              leftIcon={<FileUploadIcon />}
              buttonStyle="skeleton"
              tw="relative z-10"
              {...dragProps}
            />
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export const ImageUploadSkeleton = ({
  description,
}: {
  description?: boolean;
}) => {
  return (
    <div className="flex flex-col">
      <Skeleton width={180} height={24} />
      {description && <Skeleton height={24} width={120} />}
      <div className="flex items-center mt-3">
        <Skeleton className="mr-4 rounded-full" width={28} height={28} />
        <Skeleton width={140} height={32} />
      </div>
    </div>
  );
};
