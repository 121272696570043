import React, { useMemo } from "react";
import {
  ArrowDownward,
  ArrowUpward,
  HorizontalRule,
} from "@mui/icons-material";
import { PercentageChangeText } from "./MetricsCard.styles";

interface Props {
  percentChange?: number;
  compared?: string;
  className?: string;
}

export const StatChange = ({ percentChange, compared, className }: Props) => {
  const renderIcon = useMemo(() => {
    if (percentChange === 0)
      return (
        <>
          <HorizontalRule />
          <span>{Math.ceil(percentChange)}% </span>
        </>
      );
    if (percentChange > 0)
      return (
        <>
          <ArrowUpward className="text-app-green400" />
          <span className="text-app-green700">
            {Math.abs(percentChange).toFixed(2)}%
          </span>
        </>
      );
    return (
      <>
        <ArrowDownward className="text-red-400" />
        <span className="text-red-500">
          {Math.abs(percentChange).toFixed(2)}%
        </span>
      </>
    );
  }, [percentChange]);

  return (
    <PercentageChangeText className={className}>
      <div tw="mb-1 flex items-center">{renderIcon}</div>
      {compared && <span>&nbsp; vs {compared}</span>}
    </PercentageChangeText>
  );
};
