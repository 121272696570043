import React, { useContext } from "react";
import useAppNavigator from "hooks/useAppNavigator";

import { useRouteMatch, NavLink } from "react-router-dom";
import { SidebarContext } from "../context/sidebar.context";
import { SidebarItemsType } from "../Sidebar.types";
import { NavContainer } from "../styles";

interface Props {
  items: SidebarItemsType[];
  className?: string;
}

const NavItems = ({ items, className }: Props) => {
  const { onNavigate, component } = useAppNavigator();
  const { isExpanded } = useContext(SidebarContext);
  const { url } = useRouteMatch();

  // a variable that reads a string and returns true if it contains the word "plan/" or "plans/"
  const isPlanRoute = url.includes("plan/") || url.includes("plans/");
  //   export const ItemsContainer = styled.div`

  //   /* Hide scrollbar for Chrome, Safari and Opera */
  //   ::-webkit-scrollbar {
  //     display: none;
  //   }
  // `;

  return (
    <div
      className="flex flex-col gap-1 overflow-x-hidden mt-4"
      style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      aria-label="sidebar"
      data-cy="sidebar-nav"
    >
      {items.map((item) => {
        return (
          <NavContainer
            key={item.label}
            isExpanded={isExpanded}
            label={item.label}
          >
            <NavLink
              data-cy={`nav-${item.label.replace(/\s+/g, "-").toLowerCase()}`}
              key={item.label}
              to={item.link}
              className={`flex items-center p-2 text-body-sm rounded-lg whitespace-nowrap group hover:text-black hover:bg-app-gray50 focus:outline-none focus:text-black  group-[.active]:font-bold font-normal  ${isExpanded ? "justify-start" : "justify-center"}`}
              activeClassName="active bg-app-blue50 focus:bg-app-blue50 hover:bg-app-blue50"
              activeStyle={{
                fontWeight: "bold",
                color: "black",
              }}
              // onClick={onNavigate(item.link)}
            >
              <div className="flex justify-center p-1 rounded-sm group-[.active]:bg-white group-[.active]:shadow-sm group-[.justify-start]:mr-2">
                <item.icon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 text-app-gray600 group-hover:text-black group-focus:text-black group-[.active]:text-app-blue400"
                />
              </div>
              {isExpanded && item.label}
            </NavLink>
          </NavContainer>
        );
      })}
      {component}
    </div>
  );
};

export default NavItems;
