/* eslint-disable no-prototype-builtins */
import { isEqual } from "lodash";

const arraysAreEqual = (array1, array2, key: string): boolean => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }

  if (
    array1.some(
      (item) => typeof item !== "object" || !item.hasOwnProperty(key)
    ) ||
    array2.some((item) => typeof item !== "object" || !item.hasOwnProperty(key))
  ) {
    return false;
  }

  return isEqual(
    array1.map((a) => a[key]),
    array2.map((a) => a[key])
  );
};

export default arraysAreEqual;
