import React, { useCallback, useState } from "react";
import {
  BillingCard,
  Button,
  ButtonGroup,
  Card,
  PricingPlans,
} from "components";
import {
  useCreateBillingPortalSessionMutation,
  useCreateCheckoutSessionMutation,
} from "features/billing";
import { useToastAlertContext } from "components/toastAlert";
import { useAppDataContext } from "routes/AppDataContextProvider";
import Tag from "components/Tag";
import Video from "components/Video";
import { format } from "date-fns";
import { OpenInNew } from "@mui/icons-material";
import { useRouteMatch } from "react-router-dom";
import { getBillingPriceIds } from "./utils";
import * as S from "./billing.styles";
import CommentingBilling from "./CommentingBilling";
import BillingSkeleton from "./BillingSkeleton";

type IntervalTypes = "monthly" | "yearly";

const Billing = () => {
  const { url } = useRouteMatch();

  const [showModal, setShowModal] = useState<boolean>(
    url.includes("commenting-pricing")
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [subInterval, setSubInterval] = useState<IntervalTypes>("yearly");

  const [createCheckoutSession] = useCreateCheckoutSessionMutation();
  const [createBillingPortalSession] = useCreateBillingPortalSessionMutation();

  const { createToastAlert } = useToastAlertContext();

  const { appData, refetch, isSubscribed, isLoading } = useAppDataContext();

  const handleOnManageSubscription = useCallback(async () => {
    setSubmitting(true);
    try {
      await createBillingPortalSession({
        variables: {
          input: {},
        },
      }).then(({ data }) =>
        window.open(data.createBillingPortalSession.url, "_self")
      );
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message:
          e.graphQLErrors?.[0]?.message ||
          "An unexpected error occurred. Please try again.",
      });
      setSubmitting(false);
    }
  }, [createBillingPortalSession, createToastAlert]);

  const handleOnSubscribe = useCallback(
    (priceId) => async () => {
      setSubmitting(true);
      try {
        await createCheckoutSession({
          variables: {
            input: {
              priceId: priceId[subInterval],
            },
          },
        }).then(({ data }) => {
          window.open(data.createCheckoutSession.url, "_self");
        });
      } catch (e) {
        createToastAlert({
          alertType: "error",
          message:
            e.graphQLErrors?.[0]?.message ||
            "An unexpected error occurred. Please try again.",
        });
        setSubmitting(false);
      } finally {
        refetch();
      }
    },
    [createCheckoutSession, createToastAlert, refetch, subInterval]
  );

  const buttonData = [
    {
      text: "Monthly",
      id: "monthly",
    },
    {
      text: "Yearly",
      rightIcon: <Tag text="20% OFF" className="font-bold" variant="primary" />,
      id: "yearly",
    },
    // hidden for now until we have discounts
    // {
    //   text: "Discounts",
    //   rightIcon: <OpenInNewIcon />,
    //   id: "discounts",
    // },
  ];

  if (isLoading || !appData) {
    return <BillingSkeleton />;
  }

  const {
    stripePriceId,
    stripeCustomerId,
    churnkeyAuthToken,
    stripeAppFeePercent,
    lastStripeAppFeeChange,
  } = appData;

  const { prices } = getBillingPriceIds(stripePriceId);

  const { basic, professional, business } = prices;

  const onCancelSubscription = () => {
    // @ts-ignore
    if (window.churnkey) {
      // @ts-ignore
      window.churnkey.init("show", {
        customerId: stripeCustomerId,
        authHash: churnkeyAuthToken,
        appId: "zizprvgt8",
        mode: process.env.ENVIRONMENT === "development" ? "test" : "live",
        provider: "stripe",
        record: true,
      });
    }
  };

  const content = [
    {
      plan: PricingPlans.basic,
      onSubscribe: handleOnSubscribe(basic),
      transactionFee: "4%",
      price: subInterval === "yearly" ? "25" : "29",
      buttonText: "Basic",
      isLoading: submitting,
    },
    {
      plan: PricingPlans.professional,
      onSubscribe: handleOnSubscribe(professional),
      transactionFee: "2%",
      price: subInterval === "yearly" ? "39" : "49",
      buttonText: "Professional",
      isLoading: submitting,
    },
    {
      plan: PricingPlans.business,
      onSubscribe: handleOnSubscribe(business),
      transactionFee: "0.9%",
      price: subInterval === "yearly" ? "79" : "99",
      buttonText: "Business",
      isLoading: submitting,
    },
    {
      plan: PricingPlans.established,
      onSubscribe: () =>
        window.open(
          "https://www.memberstack.com/established-pricing",
          "_blank"
        ),
      transactionFee: "ZERO",
      price: subInterval === "yearly" ? "Calculate" : "Calculate",
      buttonText: "Contact Us",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      {isSubscribed && (
        <Card
          className="flex justify-between items-center p-4"
          hideShadow={false}
        >
          <div>
            <h2 className="text-base font-semibold mb-1">
              Manage Subscription and Billing
            </h2>
            <p className="text-body-sm">
              Update card, address and tax information via Stripe.
            </p>
          </div>
          <Button
            text="Manage Billing"
            rightIcon={<OpenInNew />}
            onClick={handleOnManageSubscription}
          />
        </Card>
      )}
      <div className="flex gap-4">
        <Card className="p-4">
          <h2 className="text-base font-semibold mb-1">Discount Programs</h2>
          <p className="text-body-sm leading-5 mb-4">
            We are excited to support students, startups, non-profits, and
            agencies with special plans and discounts.
          </p>
          <Button
            text="View Discount Programs"
            buttonStyle="skeleton"
            onClick={() =>
              window.open(
                "https://docs.memberstack.com/hc/en-us/articles/13707369368987-Discount-Programs",
                "_blank"
              )
            }
          />
        </Card>
        {isSubscribed && (
          <Card className="flex justify-between flex-col items-start p-4">
            <div>
              <h2 className="text-base font-semibold mb-1">
                Cancel Subscription
              </h2>
              <p className="text-body-sm leading-5">
                The cancellation process takes 15-120 seconds to complete.
              </p>
            </div>
            <Button
              text="Cancel Subscription"
              buttonStyle="danger"
              onClick={onCancelSubscription}
            />
          </Card>
        )}
      </div>
      {Boolean(appData?.stripeAppFeePercent) && (
        <Card
          className="flex justify-between items-center p-4"
          hideShadow={false}
        >
          <p className="text-base">
            Application Transaction Fee:&nbsp;
            <span className="font-semibold">{`${stripeAppFeePercent}%`}</span>
          </p>
          <p className="text-base">
            Last updated: &nbsp;
            {lastStripeAppFeeChange
              ? format(new Date(appData?.lastStripeAppFeeChange), "MMM d, yyyy")
              : "n/a"}
          </p>
        </Card>
      )}
      <Card className="flex flex-col p-4 gap-4">
        <div className="p-4 flex flex-col xl:flex-row xl:justify-center">
          <Video
            src="https://www.loom.com/embed/2e3027019ded486ca2fa2e7050cbd3c7?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
            title="pricing"
            allowFullScreen
            className="w-60 h-40"
          />
          <div className="flex flex-col gap-4 mt-4 ml-0 xl:ml-8 xl:mt-0">
            <h1 className="text-h1 font-bold">
              Launch fast, Scale to millions.
            </h1>
            <p className="text-base">
              100% free to test — only pay when you go live.
            </p>
            <ButtonGroup
              selected={subInterval}
              onChange={() =>
                subInterval === "yearly"
                  ? setSubInterval("monthly")
                  : setSubInterval("yearly")
              }
              buttonData={buttonData}
            />
          </div>
        </div>

        <S.BillingPlanContainer>
          {content.map(
            ({ plan, onSubscribe, transactionFee, price, buttonText }) => (
              <BillingCard
                interval={subInterval}
                key={plan}
                plan={plan}
                onSubscribe={onSubscribe}
                isLoading={submitting}
                transactionFee={transactionFee}
                buttonText={buttonText}
                price={price}
                isSubscribed={isSubscribed}
              />
            )
          )}
        </S.BillingPlanContainer>
      </Card>
      <CommentingBilling setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default Billing;
