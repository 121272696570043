import React from "react";
import { TagContainer, TagVariants, SizeVariants } from "./styles";

interface TagProps {
  className?: string;
  icon?: React.ReactNode;
  /**
   * Text to be rendered in tag
   */
  text: string | number;
  /**
   * variant of tag. The tag determines the color and background color of the tag <br>
   * primary = blue <br>
   * secondary = grey <br>
   * success = green <br>
   * danger = red <br>
   * warning = orange
   */
  variant?: TagVariants;
  size?: SizeVariants;
  isClear?: boolean;
  isSolid?: boolean;
  isBold?: boolean;
}

const Tag = ({
  icon,
  text,
  variant = "default",
  size = "small",
  className,
  isClear = false,
  isSolid = false,
  isBold,
}: TagProps) => (
  <TagContainer
    $variant={variant}
    className={className}
    $size={size}
    $isClear={isClear}
    $isSolid={isSolid}
    $isBold={isBold}
  >
    {icon ? <>{isClear ? <div>{icon}</div> : icon}</> : null}
    {text}
  </TagContainer>
);

export default Tag;
