/* eslint-disable dot-notation */
import React, { useRef } from "react";
import {
  components,
  ControlProps,
  OptionProps,
  StylesConfig,
} from "react-select";
import { OptionType, SelectOption } from "components/Select";
import { Switch } from "components/Switch";
import {
  StyledSelect,
  useCreateStyles,
  Container,
  SelectLabel,
} from "./LogicSelector.styles";

const actionTypeMap = {
  add: { label: "Add", value: "add" },
  remove: { label: "Remove", value: "remove" },
  cancelRecurring: { label: "Cancel", value: "cancelRecurring" },
};

const Control = ({ children, ...props }: ControlProps) => {
  return <components.Control {...props}>{children}</components.Control>;
};

interface Props {
  id?: string;
  className?: string;
  planPlaceholder?: string;
  planOptions: OptionType[];
  isDisabled?: boolean;
  type: "add" | "remove" | "cancelRecurring";
  value?: OptionType[];
  isChecked?: boolean;
  switchLabel?: string;
  onToggleSwitch?: (isChecked: boolean) => void;
  onChange?: (selected: OptionType) => void;
}

export const LogicSelector = ({
  id,
  className = "",
  type = "add",
  planPlaceholder = "",
  value = [],
  planOptions = [],
  isChecked = false,
  isDisabled = false,
  onChange = () => undefined,
  onToggleSwitch = () => undefined,
  switchLabel = "",
}: Props) => {
  const hasSwitch = type !== "add";
  const customStyles: StylesConfig<OptionProps> = useCreateStyles(
    isDisabled,
    hasSwitch
  );

  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(value);
  valueRef.current = value;

  const handlePlansChange = (values: OptionType[]) => {
    // @ts-ignore
    onChange({ [type]: values });
  };

  return (
    <div className="flex flex-col">
      <Container
        data-toggle="popover"
        data-trigger="focus"
        className={className}
        $hasSwitch={hasSwitch}
        isDisabled={isDisabled}
      >
        <SelectLabel htmlFor={id} $variant={type}>
          {actionTypeMap[type].label}
        </SelectLabel>
        <StyledSelect
          closeMenuOnSelect={false}
          placeholder={planPlaceholder}
          options={planOptions}
          isMulti
          isSearchable
          hideSelectedOptions={false}
          components={{
            Control,
            Option: SelectOption,
          }}
          onChange={handlePlansChange}
          value={valueRef.current}
          isDisabled={isDisabled}
          styles={customStyles}
          isClearable
          aria-labelledby={id}
        />
      </Container>
      {hasSwitch && (
        <div tw="p-2 rounded-b border-[0 1px 1px 1px] flex items-center">
          <Switch
            id={`${id}-switch`}
            isChecked={isChecked}
            onChange={onToggleSwitch}
          />
          <span className="text-sm text-app-gray600 ml-2">{switchLabel}</span>
        </div>
      )}
    </div>
  );
};
