/* eslint-disable no-nested-ternary */
import React from "react";
import styled, { css } from "styled-components";

const Progress = styled.div<{ progress: number; changeOnProgress: boolean }>(
  ({ theme: { colors }, progress, changeOnProgress }) => css`
    width: ${progress}%;
    background-color: ${progress < 50
      ? "#FA9496"
      : progress < 90
        ? "#E7D21C"
        : "#2EC861"};
    border-radius: 8px;
    height: inherit;
    ${!changeOnProgress &&
    css`
      background-color: ${colors?.blue400};
    `}
  `
);

export const ProgressBar = ({
  progress,
  className,
  text = "changed",
  showProgress = true,
  changeOnProgress = true,
}: {
  progress: number;
  className?: string;
  text?: string;
  showProgress?: boolean;
  changeOnProgress?: boolean;
}) => {
  return (
    <div tw="flex items-center" className={className}>
      <div tw="rounded-lg h-2 w-full bg-app-gray50">
        <Progress
          progress={progress}
          changeOnProgress={changeOnProgress}
          data-testid="progressContainer"
        />
      </div>
      {showProgress && (
        <span tw="ml-3 text-utility-md whitespace-nowrap text-app-gray700">
          {progress}% {text}
        </span>
      )}
    </div>
  );
};
