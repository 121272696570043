import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  useUpdatePriceMutation,
  useDeactivatePriceMutation,
  useReactivatePriceMutation,
} from "features/prices";
import { useAppDataContext } from "routes/AppDataContextProvider";
import PlanTitle from "components/PlanTitle";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import { Button, Form, Dropdown } from "components";
import { Background } from "components/BackgroundWithGradient/BackgroundWithGradient";
import StripeUI from "components/Stripe-UI";
import { useGetPlanQuery } from "features/plans";
import { useToastAlertContext } from "components/toastAlert";
import {
  DEFAULT_PRICE_INPUT,
  DEFAULT_PRICE_STATE,
} from "containers/Plans/utils";
import { Price, PriceType } from "generatedTypes";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { ContentCopy } from "@mui/icons-material";
import { NoPayment } from "./PlanPrice/NoPayment";
import { PriceInformation } from "./PlanPrice/PriceInformation";
import { SetupFee } from "./PlanPrice/SetupFee";
import { Trials } from "./PlanPrice/Trials";
import { Expiration } from "./PlanPrice/Expiration";
import { CreatePriceLoading } from "./loading";
import { AddTaxModal } from "./AddTaxModal";
import { DeletePriceModal } from "./DeletePriceModal";
import PlanInstallModal from "../../PlanInstallModal";

export const EditPlanPrice = () => {
  const [showInstallModal, setShowInstallModal] = useState<boolean>(false);
  const [priceInputs, setPriceInputs] = useState<Price>(DEFAULT_PRICE_STATE);

  const [isFormValid, setIsFormValid] = useState(false);
  const [showAddTaxModal, setShowAddTaxModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { appId, planId, priceId } = useParams<{
    appId: string;
    planId: string;
    priceId: string;
  }>();

  const history = useHistory();
  const { createToastAlert } = useToastAlertContext();
  const { appData, isLoading } = useAppDataContext();

  const [deactivate] = useDeactivatePriceMutation();
  const [reactivate] = useReactivatePriceMutation();
  const [updatePrice, { loading: updating }] = useUpdatePriceMutation();
  const { loading, data, error, refetch } = useGetPlanQuery({
    variables: { id: planId },
  });

  useEffect(() => {
    if (data) {
      const price = data.getPlan.prices.find((_price) => _price.id === priceId);
      setPriceInputs(price as Price);
    }
  }, [data, priceId]);

  const handleOnUpdate = async () => {
    const priceInputData = getObjectWithKeys(
      priceInputs || {},
      Object.keys(DEFAULT_PRICE_INPUT)
    );

    createToastAlert({ processing: true });
    try {
      await updatePrice({
        variables: {
          input: { priceId, ...priceInputData },
        },
      });
      await refetch();
      createToastAlert({
        alertType: "success",
        message: "Price successfully updated.",
      });
      history.push(`/apps/${appId}/plan/${planId}`);
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: `${e}`,
      });
    }
  };

  const handleReactivation = async () => {
    createToastAlert({ processing: true });
    try {
      await reactivate({
        variables: {
          input: { priceId },
        },
      });
      await refetch();
      createToastAlert({
        alertType: "success",
        message: "Price successfully reactivated.",
      });
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: `${e}`,
      });
    }
  };

  const handleDeactivation = async () => {
    createToastAlert({ processing: true });
    try {
      await deactivate({
        variables: {
          input: { priceId },
        },
      });
      await refetch();
      createToastAlert({
        alertType: "success",
        message: "Price successfully deactivated.",
      });
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: `${e}`,
      });
    }
  };

  if (isLoading || loading) return <CreatePriceLoading />;
  if (error) return <div>Unable to load plan price</div>;

  const isStripConnected = appData?.stripeConnection?.accountId;

  const plan = data.getPlan;
  const priceFromDb = plan.prices.find((_price) => _price.id === priceId);

  const handleSave = () => {
    if (priceInputs.taxType !== null) {
      return setShowAddTaxModal(true);
    }
    return handleOnUpdate();
  };

  const hasTaxValue = Boolean(priceFromDb.taxType);

  return (
    <div tw="flex h-full">
      <SettingsBar>
        <TitleSection
          title={priceInputs?.name}
          backLink={`/apps/${appId}/plan/${planId}`}
          backLinkTitle={
            <div tw="flex">
              <PlanTitle plan={plan} suffix="Prices" />
            </div>
          }
          buttons={
            <div tw="flex items-center">
              <Button
                text="Save"
                onClick={() => handleSave()}
                isLoading={updating}
                isDisabled={!isFormValid}
              />
              <Dropdown
                options={[
                  {
                    text: priceInputs?.active
                      ? "Archive Price"
                      : "Activate Price",
                    onClick: priceInputs?.active
                      ? () => handleDeactivation()
                      : () => handleReactivation(),
                  },
                  {
                    text: "Delete Price",
                    onClick: () => setShowDeleteModal(true),
                    isDangerText: true,
                  },
                ]}
              />
            </div>
          }
          emphasize
        />
        {isStripConnected ? (
          <Form onValidityCheck={setIsFormValid} className="overflow-auto">
            <div className="px-5 pt-5">
              <Button
                text="Install & Publish"
                buttonStyle="inverted"
                leftIcon={<ContentCopy />}
                className="w-full"
                onClick={() => setShowInstallModal(true)}
              />
              {/* <AttributeInstallationBox
                description="Add these attributes to your signup flow, pricing tables, etc so members can join."
                docsLink="https://docs.memberstack.com/hc/en-us/articles/7384849448091-Getting-Started-with-Plans-Prices"
                nameLabel="Name"
                idLabel="Value (Price ID)"
                nameSelectInfo={{
                  options: paidNameOptions,
                }}
                idValue={priceId}
                nameValue="data-ms-name"
                showMoreInfo={false}
              /> */}
            </div>
            <PriceInformation
              priceInputs={priceInputs}
              setPriceInputs={setPriceInputs}
              hasTaxValue={hasTaxValue}
              disabled
              name={priceInputs.name}
              amount={priceInputs.amount}
              type={priceInputs.type}
              currency={priceInputs.currency}
              intervalCount={priceInputs.interval.count}
              intervalType={priceInputs.interval.type}
              taxType={priceInputs.taxType}
              maxTeamMembers={priceInputs.maxTeamMembers}
              teamAccountsEnabled={priceInputs.teamAccountsEnabled}
            />
            <hr />
            <SetupFee
              priceInputs={priceInputs}
              setPriceInputs={setPriceInputs}
              amount={priceInputs.setupFee.amount}
              name={priceInputs.setupFee.name}
              disabled
            />
            <Trials
              days={priceInputs.freeTrial.days}
              priceInputs={priceInputs}
              setPriceInputs={setPriceInputs}
              disabled
            />
            {priceInputs.type === PriceType.Onetime && (
              <Expiration
                count={priceInputs.expiration.count}
                interval={priceInputs.expiration.interval}
                setPriceInputs={setPriceInputs}
                priceInputs={priceInputs}
                disabled
              />
            )}
          </Form>
        ) : (
          <NoPayment />
        )}
      </SettingsBar>
      <Background>
        <StripeUI
          priceInputs={priceInputs}
          setupFee={priceInputs.setupFee.amount}
          freeTrialDays={priceInputs.freeTrial.days}
          intervalType={priceInputs.interval.type}
          intervalCount={priceInputs.interval.count}
          planName={plan?.name}
        />
      </Background>
      <AddTaxModal
        showModal={showAddTaxModal}
        setShowModal={setShowAddTaxModal}
        onConfirm={handleOnUpdate}
        isLoading={updating}
      />
      <DeletePriceModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        price={priceFromDb}
      />
      <PlanInstallModal
        setShowModal={setShowInstallModal}
        showModal={showInstallModal}
        plan={plan}
        activePriceId={priceId}
      />
    </div>
  );
};
