import React, { useState } from "react";
import {
  useLinkPlansToRestrictedUrlGroupMutation,
  useDetachPlansFromRestrictedUrlGroupMutation,
  useGetContentGroupsQuery,
} from "features/content-groups";
import CreateContentGroupModal from "features/content-groups/components/CreateContentGroupModal";
import useSearch from "hooks/useSearch";
import EditContentGroupModal from "features/content-groups/components/EditContentGroupModal";
import { EmptyState, GatedContentCard, Link } from "components";
import SearchBar from "components/SearchBar";
import { Plan } from "generatedTypes";
import useSubmit from "hooks/useSubmit";
import Skeleton from "react-loading-skeleton";
import { DEFAULT_GROUP_STATE } from "../../utils";
import { PlanAccordionItem } from "./PlanAccordionItem";

interface Props {
  plan: Plan;
}

export const PlanContentGroups = ({ plan }: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_GROUP_STATE);

  const [link] = useLinkPlansToRestrictedUrlGroupMutation();
  const [detach] = useDetachPlansFromRestrictedUrlGroupMutation();

  const { loading, refetch, error, data } = useGetContentGroupsQuery();

  const contentGroups = data?.getContentGroups;

  const {
    searchString,
    setSearchString,
    output: filteredRestrictedUrlGroups,
  } = useSearch({
    input: contentGroups,
    map: (cg) => cg?.name,
  });

  const handleShowModal = (group) => () => {
    setSelectedGroup(group);
    return setShowEditModal(true);
  };

  const { submit: submitLink, isSubmitting: linking } = useSubmit({
    action: link,
    refetch,
    error: "Unable to link permission to plan",
    success: "Successfully linked permission to plan",
  });

  const { submit: submitDetach, isSubmitting: detaching } = useSubmit({
    action: detach,
    refetch,
    error: "Unable to detach permission from plan",
    success: "Successfully detached permission to plan",
  });

  if (loading && !contentGroups)
    return (
      <section data-testid="plansContentGroup-skeleton">
        <Skeleton width={494} height={24} className="mb-4" />
        <div className="flex flex-col gap-2">
          <Skeleton height={98} />
          <Skeleton height={98} />
          <Skeleton height={98} />
        </div>
      </section>
    );
  const handleCheck = async (id, checked) => {
    if (checked) {
      submitLink({
        restrictedUrlGroupId: id,
        planIds: [plan.id],
      });
    } else {
      submitDetach({
        restrictedUrlGroupId: id,
        planIds: [plan.id],
      });
    }
  };

  const planActiveContentGroup = contentGroups?.filter(
    (g) => g.plans.find((pp) => pp.id === plan.id) !== undefined
  );

  return (
    <PlanAccordionItem
      label="Gated Content"
      value="gatedContent"
      total={planActiveContentGroup?.length}
      onAdd={() => setShowCreateModal(true)}
    >
      <section>
        <p className="text-body-sm text-app-gray600 mb-4">
          Use this feature to block access to pages, folders, and page elements.
          &nbsp;
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
            target="_blank"
            isExternal
            underline
          >
            Help Docs →
          </Link>
        </p>
        {contentGroups.length > 5 && (
          <SearchBar
            onChange={(value) => setSearchString(value)}
            className="mb-2 p-1"
            value={searchString}
          />
        )}
        {filteredRestrictedUrlGroups?.length ? (
          <div className="flex flex-col gap-2">
            {filteredRestrictedUrlGroups.map((restrictedUrlGroup) => {
              const {
                id,
                key,
                name,
                urls,
                plans: restrictedUrlGroupPlans,
              } = restrictedUrlGroup;
              return (
                <GatedContentCard
                  key={id}
                  switchProps={{
                    isChecked:
                      restrictedUrlGroupPlans.find((p) => p.id === plan.id) !==
                      undefined,
                    disabled: linking || detaching,
                    id,
                    onChange: (checked) => handleCheck(id, checked),
                  }}
                  contentId={key}
                  name={name}
                  plans={restrictedUrlGroupPlans}
                  urls={urls}
                  onClick={handleShowModal(restrictedUrlGroup)}
                />
              );
            })}
          </div>
        ) : (
          <p className="text-base">No gated contents found</p>
        )}
        {contentGroups.length === 0 && (
          <EmptyState
            text="Gate Access to Content"
            description="Use this feature to block access to pages, folders, and page elements. Just tell Memberstack what you want to hide, who should have access, and where to send folks who don't."
            buttonText="Gate Content"
            onCreateClick={() => setShowCreateModal(true)}
            docsLink="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
          />
        )}
        <EditContentGroupModal
          setShowModal={setShowEditModal}
          showModal={showEditModal}
          selectedGroup={selectedGroup}
        />
        <CreateContentGroupModal
          setShowModal={setShowCreateModal}
          showModal={showCreateModal}
          refetchContentGroups={refetch}
        />
      </section>
    </PlanAccordionItem>
  );
};
