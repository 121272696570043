import React, { lazy, useEffect, useMemo, useState } from "react";
import { EmptyState } from "components";
import { useEnvContext } from "containers/App/contexts/Environment";
import { useSearchWithFilter } from "hooks/useSearchWithFilter/useSearchWithFilter";
import { useCopy } from "hooks/useCopy/useCopy";
import useAppNavigator from "hooks/useAppNavigator";
import { EditCustomFieldModal } from "features/custom-fields";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";
import useMembersImport from "hooks/useMembersImport";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import WarningIcon from "@mui/icons-material/Warning";
import useDashboardEvent from "hooks/useWebsockets/dashboard.event";
import { RouteName } from "enums/routes";
import { useNavigate } from "react-router-dom";
import OnboardingModal from "components/Modal/OnboardingModal";
import { CustomFieldVisibility } from "generatedTypes";
import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import { useFiltering } from "../Members.utils";
import Header from "./Header";
import MembersTable from "./MembersTable/MembersTable";
import { useMembersContext } from "../context/MembersContextProvider";

const EmptyMemberTableIcon = lazy(
  () => import("assets/images/empty_members_table.svg")
);

// const containerClasses =
//   "flex flex-col flex-1 rounded-xl bg-white border border-app-gray100 min-w-[640px] mr-3";

const DEFAULT_STATE = {
  id: "",
  label: "",
  key: "",
  hidden: false,
  visibility: CustomFieldVisibility.Private,
};

const MembersView = ({ activeMemberId, totalMembers }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState(DEFAULT_STATE);

  const [copy] = useCopy();
  const { plans } = usePlansContext();
  const dashboardEvents = useDashboardEvent();
  const { isSubscribed } = useAppDataContext();
  const { isSandboxEnv, env } = useEnvContext();
  const { refetch, members, setShowCreateMemberModal } = useMembersContext();

  const newMembers = useMemo(
    () => members.filter((m) => m.isNew).length,
    [members]
  );

  const { onNavigate, component } = useAppNavigator();

  const { importInProgress, importJobData } = useMembersImport();

  const handleDashboardEvent = (_data) => {
    const memberCreated = _data.event === "member.created" && env === _data.env;

    const memberId = _data.member?.id;
    const memberInList = memberId
      ? members.findIndex((m) => m.id === memberId)
      : -1;

    if (memberCreated || (memberInList !== -1 && memberInList < 20)) {
      logInDevelopmentEnvironment(
        "🚨 members refetch: A new member was created"
      );
      refetch();
    }
  };

  useEffect(() => {
    dashboardEvents.subscribe("dashboard-page", handleDashboardEvent);
    return () => {
      dashboardEvents.unsubscribe("dashboard-page");
    };
  });

  const { filterOptions, planIds, status, comments } = useFiltering({
    plans,
    refetch,
    searchValue,
  });

  const [changeHandler] = useSearchWithFilter({
    planIds,
    refetch,
    setSearchValue,
    status,
    comments,
  });

  const handleShowEditModal = (selected) => {
    setSelectedCustomField(selected);
    setShowEditModal(true);
  };

  const options = ({ id, label, key, visibility }) => [
    {
      text: "Copy ID",
      onClick: copy({ text: key, feedback: `${label} ID copied.` }),
    },
    {
      text: "Edit",
      onClick: () => handleShowEditModal({ id, label, key, visibility }),
    },
  ];

  const renderImportUpdates = useMemo(() => {
    if (importInProgress) {
      if (importJobData.statusPercentage === 100) {
        return (
          <div className="w-full justify-center flex mb-5 text-base">
            Your import is finished 🎉
          </div>
        );
      }
      return (
        <div tw="flex items-center mx-5 mb-5">
          <span className="mr-5 whitespace-nowrap flex items-center">
            <WarningIcon className="text-yellow-400 mr-2" />
            Import in progress
          </span>
          <ProgressBar
            progress={importJobData.statusPercentage}
            text="imported"
            className="w-full"
          />
        </div>
      );
    }
    return null;
  }, [importInProgress, importJobData.statusPercentage]);

  if (!isSandboxEnv && !isSubscribed) {
    return (
      <>
        <Header
          totalMembers={totalMembers}
          planIds={planIds.current}
          status={status.current}
          noOfNewMembers={newMembers}
        />
        <EmptyState
          isFullScreen
          text="Upgrade Required to Add Real Members"
          description="To add live members and fully launch your site, you’ll need to upgrade your Memberstack plan."
          buttonText="Upgrade Now"
          onCreateClick={() =>
            navigate(`../${RouteName.settings}/${RouteName.billing}`)
          }
          showEnvSwitcher
          svgImage={<EmptyMemberTableIcon />}
        />
      </>
    );
  }

  if (totalMembers === 0) {
    return (
      <>
        <Header
          totalMembers={totalMembers}
          planIds={planIds.current}
          status={status.current}
          noOfNewMembers={newMembers}
        />
        <EmptyState
          isFullScreen
          text={isSandboxEnv ? "Create a Test Member" : "Create a Member"}
          description="Create you first member to change their plans, edit custom fields, login as that person, etc."
          buttonText="Create Member"
          onCreateClick={() => setShowCreateMemberModal(true)}
          onDemoClick={() => setShowOnboardingModal(true)}
          svgImage={<EmptyMemberTableIcon />}
        />
        <OnboardingModal
          setShowModal={setShowOnboardingModal}
          showModal={showOnboardingModal}
          title="Welcome! Get started by adding a member 🎉"
          docsLink="https://docs.memberstack.com/hc/en-us/search?utf8=%E2%9C%93&query=members+page"
          videoSrc="https://www.loom.com/embed/4a60c22c191a4d0f8a66750de429047a"
        />
      </>
    );
  }

  return (
    <>
      <div className="w-full h-full overflow-hidden flex flex-col">
        <Header
          totalMembers={totalMembers}
          planIds={planIds.current}
          status={status.current}
          noOfNewMembers={newMembers}
        />
        {renderImportUpdates}
        <MembersTable
          activeMemberId={activeMemberId}
          onNavigate={onNavigate}
          options={options}
          filterOptions={filterOptions}
          changeHandler={changeHandler}
          searchValue={searchValue}
        />

        {component}
      </div>
      <EditCustomFieldModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        id={selectedCustomField.id}
        label={selectedCustomField.label}
        uniqueKey={selectedCustomField.key}
        hidden={selectedCustomField.hidden}
        visibility={selectedCustomField.visibility}
      />
    </>
  );
};

export default MembersView;
