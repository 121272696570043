import React, { useEffect, useMemo, useRef, useState } from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import { Background, Button, Card, MarkdownEditor } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useUpdateEmailsMutation } from "features";
import { useToastAlertContext } from "components/toastAlert";
import { isError } from "helpers/typeguards";
import { useGetEmailsQuery } from "features/emails";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import {
  DEFAULT_PASSWORDLESS_DESCRIPTION,
  DEFAULT_PASSWORDLESS_FOOTER,
  INITIAL_PASSWORDLESS_EMAIL_STATE,
  DEFAULT_PASSWORDLESS_HEADER,
  useHandleOnChange,
} from "../emails.utils";
import * as S from "../emails.styles";
import { EmailTemplateLoading } from "../emails.loading";

export const PasswordlessEmail = () => {
  const { appData, isLoading } = useAppDataContext();
  const [formValues, setFormValues] = useState<{ [key: string]: string }>(
    INITIAL_PASSWORDLESS_EMAIL_STATE
  );
  const valRef = useRef(null);

  const { createToastAlert } = useToastAlertContext();

  const [updateEmails, { loading }] = useUpdateEmailsMutation();

  const { data: emails, loading: isFetching, refetch } = useGetEmailsQuery();

  const passwordlessLogin = useMemo(
    () =>
      getObjectWithKeys(
        emails?.getEmails?.passwordlessLogin || {},
        Object.keys(INITIAL_PASSWORDLESS_EMAIL_STATE)
      ),
    [emails?.getEmails?.passwordlessLogin]
  );

  useEffect(() => {
    valRef.current = passwordlessLogin;

    setFormValues({
      replyTo: valRef.current?.replyTo || "",
      subject: valRef.current?.subject || "",
      paragraph1: valRef.current?.paragraph1 || "",
      paragraph2: valRef.current?.paragraph2 || "",
      paragraph3: valRef.current?.paragraph3 || "",
    });
  }, [passwordlessLogin]);

  const { handleOnChange } = useHandleOnChange({ setFormValues, formValues });

  const onSubmit = async (e) => {
    createToastAlert({ processing: true });
    e.preventDefault();
    try {
      await updateEmails({
        variables: {
          input: {
            passwordlessLogin: { ...formValues },
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message:
          "Successfully updated content of the email password reset email",
      });
    } catch (error) {
      if (isError(error)) {
        createToastAlert({
          alertType: "error",
          message: error.message,
        });
      }
    } finally {
      await refetch();
    }
  };

  if (isFetching || isLoading) {
    return <EmailTemplateLoading title="Edit Passwordless Email" />;
  }

  const didFormChange = !isObjectEqual(
    removeEmpty(valRef.current || {}),
    removeEmpty(trimObjectStringValues(formValues))
  );

  return (
    <Card className="flex flex-row" hideBorder>
      <SettingsBar>
        <TitleSection
          title="Edit Passwordless Email"
          backLink="/settings/emails"
          backLinkTitle="Emails"
          emphasize
          gutter="medium"
          buttons={
            <Button
              text="Save"
              onClick={(e) => onSubmit(e)}
              isLoading={loading}
              isDisabled={!didFormChange}
            />
          }
        />
        <form className="p-5 overflow-auto" onSubmit={(e) => onSubmit(e)}>
          <Input
            name="replyTo"
            placeholder="email@somemail.com"
            value={formValues.replyTo}
            label="Reply-To-Email"
            infoHintText="Email address"
            tw="pb-5"
            description="Enter your customer support address."
            onChange={({ target: { value } }) =>
              handleOnChange({ type: "replyTo", value })
            }
          />
          <Input
            name="subject"
            placeholder="Your verification code is"
            value={formValues.subject}
            label="Subject"
            infoHintText="Subject of the email"
            tw="pb-5"
            hideIndicators
            description="Customize the email subject."
            onChange={({ target: { value } }) =>
              handleOnChange({ type: "subject", value })
            }
          />
          <Input
            name="header"
            placeholder="Verify your email address"
            value={formValues.paragraph1}
            label="Header"
            infoHintText="Header of the email"
            tw="pb-5"
            hideIndicators
            description="Customize the email header content."
            onChange={({ target: { value } }) =>
              handleOnChange({ type: "paragraph1", value })
            }
          />
          <MarkdownEditor
            label="Content"
            name="paragraph2"
            height={150}
            value={formValues.paragraph2}
            placeholder={DEFAULT_PASSWORDLESS_DESCRIPTION}
            onChange={(value) => handleOnChange({ type: "paragraph2", value })}
            description="Customize the body of your email. Markdown supported."
          />
          <MarkdownEditor
            className="mt-5"
            label="Footer"
            name="paragraph3"
            height={150}
            value={formValues.paragraph3}
            placeholder={DEFAULT_PASSWORDLESS_FOOTER}
            onChange={(value) => handleOnChange({ type: "paragraph3", value })}
            description="Customize the footer of your email. Markdown supported."
          />
        </form>
      </SettingsBar>
      <Background>
        <div className="p-5 max-h-full min-h-0 overflow-auto">
          <S.UploadLogoText />
          <S.ShowcaseCard>
            {appData?.image && (
              <div tw="pb-[30px]">
                <img
                  src={appData?.image}
                  height={43}
                  tw="h-[43px]"
                  alt={appData?.name}
                />
              </div>
            )}
            <h1 tw="text-h1 font-bold mb-8">
              {formValues.paragraph1 || DEFAULT_PASSWORDLESS_HEADER}
            </h1>
            <MarkdownEditor
              readonly
              value={formValues.paragraph2 || DEFAULT_PASSWORDLESS_DESCRIPTION}
            />
            <div className="bg-app-gray50 p-6 flex justify-center text-h1 my-8">
              ICJ-NKR
            </div>
            <MarkdownEditor
              readonly
              value={formValues.paragraph3 || DEFAULT_PASSWORDLESS_FOOTER}
            />
          </S.ShowcaseCard>
        </div>
      </Background>
    </Card>
  );
};
