import { styled } from "twin.macro";

export const Container = styled.div`
  background-color: rgba(246, 246, 246, 1);
  border-radius: 8px;
  border-color: rgba(221, 221, 221, 1);
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 32px;
  position: relative;
  border-width: 1px;

  & > div:first-of-type {
    display: flex;
    justify-content: center;
    gap: 48px;
    font-size: 16px;
    line-height: 28px;
    color: rgba(73, 73, 73, 1);
  }
`;
