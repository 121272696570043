import tw, { css, styled } from "twin.macro";
import { animated } from "react-spring";

export const OptionsContainer = styled(animated.div)(
  ({ theme: { colors } }) => css`
    margin-top: 8px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    border-width: 1px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08)
    border-color: ${colors.grey100};
    z-index: 10;
    width: auto;
    background-color: ${colors.white};
    max-height: 240px;
    overflow: auto;
  `
);

export const Option = styled.div<{
  $isSelected: boolean;
  $isDisabled?: boolean;
}>(({ $isSelected, $isDisabled, theme: { colors } }) => [
  tw`cursor-default select-none relative m-1 py-2 px-3 rounded text-black`,
  $isSelected
    ? css`
        background-color: ${colors.primary};
        color: ${colors.white};
      `
    : tw`hover:bg-app-gray50`,
  $isDisabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `,
]);

export const SelectContainer = styled.div(() => [
  tw`pr-5 text-utility-md leading-5`,
  ({ theme: { colors } }) => css`
    position: relative;
    width: 100%;
    text-align: right;
    cursor: default;
    color: ${colors.black};
  `,
]);
