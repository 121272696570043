import tw from "twin.macro";

export const fontStyleMap = {
  regular: tw`text-base`,
  small: tw`text-sm`,
  bodySmall: tw`text-body-sm`,
  utilitySmall: tw`text-utility-sm`,
  utilityMedium: tw`text-utility-md`,
  utilityTagMedium: tw`text-utility-tag-md`,
  headingH1: tw`text-h1`,
  headingH2: tw`text-h2`,
  headingH3: tw`text-h3`,
  headingH4: tw`text-h4`,
};
