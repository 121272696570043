import React, { useState } from "react";
import styled, { css } from "styled-components";
import useClickout from "hooks/useClickout";
import Input from "../Input";

const SuggestionsContainer = styled.ul(
  ({ theme: { colors } }) => css`
    margin-top: 4px;
    padding: 4px;
    background: #ffffff;
    border: 1px solid ${colors?.grey200};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    width: 100%;
    z-index: 10;
    max-height: 260px;
    overflow: auto;
    & > li {
      padding: 8px 12px;
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background: ${colors?.grey100};
      }
    }
  `
);

interface Props {
  items: string[];
  label: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export const TypeAheadDropdown = ({
  items,
  label,
  onChange,
  placeholder,
}: Props) => {
  const [text, setText] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string[]>(items);

  const {
    ref,
    isVisible: showSuggestion,
    setIsVisible: setShowSuggestion,
  } = useClickout(false);

  const onTextChange = (e) => {
    const { value } = e.target;
    let filteredSuggestions = items;
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      filteredSuggestions = items.sort().filter((v) => regex.test(v));
    }
    setSuggestions(filteredSuggestions);
    setText(value);
    onChange(value);
  };

  const suggestionSelected = (value) => {
    onChange(value);
    setText(value);
    setShowSuggestion(false);
  };

  return (
    <div className="relative" ref={ref}>
      <Input
        name={label}
        value={text}
        onChange={onTextChange}
        onClick={() => setShowSuggestion(true)}
        type="text"
        label={label}
        hideIndicators
        placeholder={placeholder}
      />
      {showSuggestion && suggestions.length > 0 && (
        <SuggestionsContainer>
          {suggestions.map((city) => (
            <li key={city} onClick={() => suggestionSelected(city)} aria-hidden>
              {city}
            </li>
          ))}
        </SuggestionsContainer>
      )}
    </div>
  );
};
