import { styled } from "twin.macro";
import ReactSelect, { OptionProps, StylesConfig } from "react-select";
import { useMemo } from "react";
import { css } from "styled-components";
import { colors } from "styles";

const variants = {
  add: {
    background: "green050",
    color: "green900",
  },
  remove: {
    background: "red050",
    color: "red900",
  },
  cancelRecurring: {
    background: "greyBlue050",
    color: "greyBlue700",
  },
};

export const StyledSelect = styled(ReactSelect)`
  width: 100%;
  & > div > div > div > input:focus {
    box-shadow: none;
  }
`;

export const Container = styled.div<{
  $hasSwitch: boolean;
  isDisabled: boolean;
}>(
  ({ $hasSwitch, isDisabled, theme: { colors: _colors } }) => css`
    display: flex;
    align-items: center;
    border: 1px solid ${_colors.grey100};
    border-radius: 4px;
    :hover {
      border-color: ${_colors.blue400};
    }
    ${$hasSwitch &&
    css`
      border-width: 1px;
      border-radius: 4px 4px 0 0;
    `}
    ${isDisabled &&
    css`
      background: ${_colors.grey050};
    `}
  `
);

export const SelectLabel = styled.label<{
  $variant: string;
}>(
  ({ $variant, theme: { colors: _colors } }) => css`
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    min-width: 68px;
    align-self: stretch;
    border-radius: 2px;
    background: ${_colors[variants[$variant]?.background]};
    color: ${_colors[variants[$variant]?.color]};
    border-right-width: 0px;
    padding: 0px 8px;
    margin: 6px 0px 6px 6px;
  `
);

export const useCreateStyles = (isDisabled, hasSwitch) => {
  const customStyles: StylesConfig<OptionProps> = useMemo(() => {
    return {
      menu: (provided) => ({
        ...provided,
        zIndex: 1000,
        background: "white",
        display: "table",
      }),
      menuList: (provided) => ({
        ...provided,
        width: "100%",
        padding: "0px",
        borderRadius: "6px",
        borderColor: colors.grey200,
        background: "white",
      }),
      control: (provided, state) => ({
        ...provided,
        // "&:hover": { borderColor: colors.blue400 },
        background: isDisabled ? colors.grey050 : "white",
        borderColor: colors.grey100,
        // borderWidth: "0 0 0 1px",
        borderRadius: "0 3px 3px 0",
        borderWidth: 0,
        minHeight: "40px",
        ...(hasSwitch && {
          border: `0 solid ${colors.grey100}`,
          borderRadius: "0 3px 0 0",
        }),
        ...(hasSwitch &&
          state.isFocused && {
            boxShadow: "none",
          }),
      }),
      input: (provided) => ({
        ...provided,
        fontSize: 14,
        lineHeight: "16px",
        margin: 0,
        padding: 0,
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: 13,
        lineHeight: "16px",
        color: colors.grey500,
        opacity: isDisabled ? 0.5 : 1,
      }),
      option: (provided, { isFocused, isSelected }) => ({
        padding: "8px 12px",
        borderRadius: "4px",
        margin: "4px",
        width: "auto",
        boxSizing: "border-box",
        display: "block",
        userSelect: "none",
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isSelected
          ? colors.blue400
          : isFocused
            ? colors.grey050
            : undefined,
        color: isSelected ? colors.white : undefined,
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: "6px 8px",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        // maxHeight: "38px",
        overflow: "auto",
        maxHeight: "unset",
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: colors.grey700,
        fontSize: 12,
        lineHeight: "12px",
        padding: 0,
        paddingLeft: 0,
      }),
      multiValueRemove: () => ({
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: 2,
        boxSizing: "border-box",
        display: "flex",
        paddingLeft: "4px",
        color: colors.grey500,
      }),
      multiValue: (provided) => ({
        ...provided,
        alignItems: "center",
        margin: 0,
        marginRight: "4px",
        padding: "5px 6px 5px 8px",
        backgroundColor: colors.grey050,
        borderRadius: "200px",
        border: `1px solid ${colors.grey100}`,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    };
  }, [hasSwitch, isDisabled]);
  return customStyles;
};
