import React, { useState } from "react";
import { ApolloQueryResult } from "@apollo/client";
import { FilterType } from "generatedTypes";
import { GetContentGroupsQuery } from "features/content-groups";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { Modal, ModalTypes } from "components/Modal";
import { generateID } from "helpers/generateID";
import { useCreateRestrictedUrlGroupMutation } from "../mutations.generated";
import GatedContentFields from "./GatedContentFields";
import { accessOptions } from "./content-group.utils";

interface Props extends ModalTypes {
  refetchContentGroups: () => Promise<ApolloQueryResult<GetContentGroupsQuery>>;
}

const CreateContentGroupModal = ({
  showModal,
  setShowModal,
  refetchContentGroups,
}: Props) => {
  const [key, setKey] = useState("");
  const [name, setName] = useState("");
  const [redirect, setRedirect] = useState("");
  const [addedPlans, setAddedPlans] = useState([]);
  const [customContents, setCustomContents] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [accessType, setAccessType] = useState(accessOptions[1]);
  const [modalError, setModalError] = useState(null);

  const [createRestrictedUrlGroup, { loading: creatingUrlGroup }] =
    useCreateRestrictedUrlGroupMutation();
  const { createToastAlert } = useToastAlertContext();

  const onReset = () => {
    setAccessType(accessOptions[0]);
    setKey("");
    setName("");
    setFormFields([{ url: "", filter: FilterType.Starts, id: "" }]);
    setRedirect("");
    setAddedPlans([]);
    setCustomContents([]);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    // Filter customContents to include only entries with both name and content
    const filteredCustomContents = customContents.filter(
      (_entry) => _entry.name && _entry.content
    );

    createToastAlert({ processing: true });
    try {
      await createRestrictedUrlGroup({
        variables: {
          input: {
            name,
            key,
            redirect,
            allowAllMembers: accessType.value === "allMembers",
            urls: formFields.map(({ filter, url }) => ({ filter, url })),
            planIds: addedPlans.map((p) => p.value),
            customContent: filteredCustomContents,
          },
        },
      });

      await refetchContentGroups();
      setShowModal(false);
      createToastAlert({
        alertType: "success",
        message: "Gated Content successfully created.",
      });
      window.CommandBar?.trackEvent("new-gated-content-added", {});
    } catch (err) {
      setModalError(err);
    } finally {
      onReset();
    }
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Add Gated Content"
      showDivider
      width="560px"
      removePadding
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: (e) => handleOnSubmit(e),
          isDisabled: !name,
          isLoading: creatingUrlGroup,
          dataCy: "content-group-save-button",
        },
        cancel: { label: "Cancel" },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <GatedContentFields
        handleUpdate={handleOnSubmit}
        name={name}
        onNameChange={(e) => {
          setName(e.target.value);
          setKey(generateID(e.target.value));
        }}
        contentKey={key}
        onContentKeyChange={(e) => setKey(generateID(e.target.value))}
        accessType={accessType}
        onAccessTypeChange={(o) => setAccessType(o)}
        plans={addedPlans}
        onPlansChange={(o) => setAddedPlans(o)}
        urlInputs={formFields}
        setUrlInputs={setFormFields}
        redirect={redirect}
        setRedirect={setRedirect}
        customContentsInt={customContents}
        setCustomContents={setCustomContents}
        className="flex flex-col"
      />
    </Modal>
  );
};

export default CreateContentGroupModal;
