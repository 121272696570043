import React from "react";

import UpArrow from "assets/images/up-arrow.svg";
import Video from "components/Video";

const PlansOnboarding = ({ title, description }) => {
  return (
    <div className="flex items-center flex-col justify-center">
      <div className="flex justify-center flex-col items-center w-[416px]">
        <UpArrow className="mb-1" />
        <p className="font-bold text-h3 mb-1">{title}</p>
        <p className="text-body-sm text-app-gray500 text-center">
          {description}
        </p>
      </div>
      <Video
        src="https://www.youtube.com/embed/9Psf4HPCMac?si=PYKFHHh07a5zyu6m"
        title="Plan Onboarding Video"
        className="my-5 w-full"
      />
    </div>
  );
};

export default PlansOnboarding;
