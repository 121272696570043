import { gql } from "@apollo/client";

export const UPDATE_BRANDING = gql`
  mutation UpdateBranding($input: UpdateBrandingInput!) {
    updateBranding(input: $input) {
      id
    }
  }
`;

export const GET_BRANDING = gql`
  query GetBranding {
    getBranding {
      id
      appId
      appearance
      logo
      colors {
        light {
          primaryButton
        }
      }
    }
  }
`;
