/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { LinkProps as RRLinkProps, NavLink } from "react-router-dom";
import cn from "helpers/cn";
import { fontStyleMap } from "../../constants/fontStyleMap";
import mergeClassNames from "../../helpers/mergeClassNames/mergeClassNames";

interface LinkProps extends RRLinkProps {
  isExternal?: boolean;
  exact?: boolean;
  showAsLink?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  underline?: boolean;
  isBold?: boolean;
  fullWidth?: boolean;
  font?: keyof typeof fontStyleMap;
  dataCy?: string;
}

export const Link = ({
  to,
  tabIndex,
  children,
  className,
  isExternal,
  exact,
  showAsLink,
  underline,
  onClick,
  isBold,
  fullWidth,
  font = "utilityMedium",
  dataCy,
}: LinkProps) => {
  const classNameFactory = useMemo(
    () => mergeClassNames(!fullWidth && "w-min", fontStyleMap[font], className),
    [className, font, fullWidth]
  );

  if (isExternal) {
    return (
      <a
        href={to as string}
        className={cn(
          classNameFactory,
          "cursor-pointer",
          "hover:brightness-75",
          {
            "text-app-blue400": showAsLink,
            "font-bold": isBold,
            "underline": underline,
          }
        )}
        rel="noreferrer"
        target="_blank"
        onClick={onClick}
        data-cy={dataCy}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      to={to}
      tabIndex={tabIndex}
      exact={exact}
      className={cn(classNameFactory, {
        "text-app-blue500": showAsLink,
        "font-bold": isBold,
        "underline": underline,
        "hover:text-app-blue500": showAsLink,
      })}
      onClick={onClick}
      data-cy={dataCy}
    >
      {children}
    </NavLink>
  );
};

Link.defaultProps = {
  isExternal: false,
  exact: false,
  showAsLink: false,
  onClick: undefined,
  underline: false,
  isBold: false,
  fullWidth: true,
};
