import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useUpdatePlanMutation, useGetPlanLazyQuery } from "features/plans";
import useSubmit from "hooks/useSubmit";
import PlanTitle from "components/PlanTitle";
import { PlanStatus } from "generatedTypes";
import { Button, Dropdown } from "components";
import { isPlanActive } from "helpers/isPlanActive";
import { Accordion } from "components/Accordion";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { sessionStorageKeys } from "helpers/cacheIds";
import { ContentCopy } from "@mui/icons-material";
import { Tooltip } from "components/Tooltip";
import { useCopy } from "hooks/useCopy/useCopy";
import { PlanContentGroups } from "./plan-settings/ContentGroups";
import { PlanPermissions } from "./plan-settings/Permissions";
import PlanRedirects from "./plan-settings/Redirects";
import { PlanSignupRules } from "./plan-settings/SignupRules";
import { PlanDetailLoading } from "../PlansSkeleton";
import { PlanPrices } from "./plan-settings/Prices/PlanPrices";
import { PlanLogic } from "./plan-settings/PlanLogic";
import DefaultSettings from "./DefaultSettings/DefaultSettings";
import PlanInstallModal from "./PlanInstallModal";
import { usePlansContext } from "../context/Plans.context";
import { DeletePlanModal } from "../modals/DeletePlanModal";
import TeamAccounts from "./plan-settings/TeamAccounts";

const Plan = () => {
  const [hideAdvanced, setHideAdvanced] = useState<boolean>(true);
  const [showInstallModal, setShowInstallModal] = useState<boolean>(false);
  const { appId, planId } = useParams<{ appId: string; planId: string }>();
  const history = useHistory();

  const [copy] = useCopy();

  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);
  const [update] = useUpdatePlanMutation();

  const { refetchPlans } = usePlansContext();

  const [getPlan, { data, loading, refetch, error }] = useGetPlanLazyQuery({
    variables: { id: planId },
  });

  useEffect(() => {
    if (planId) getPlan();
  }, [planId]);

  const plan = data?.getPlan;

  const enableInstallButton = useMemo(() => {
    // if free plan return true
    if (!plan?.isPaid) return true;
    // if plan is paid and has prices return true
    return plan?.prices.length > 0;
  }, [plan?.isPaid, plan?.prices]);

  const { submit: handleArchive } = useSubmit({
    action: update,
    fields: {
      planId: plan?.id,
      status: isPlanActive(plan?.status)
        ? PlanStatus.Inactive
        : PlanStatus.Active,
    },
    refetch: refetchPlans,
    success: `Plan was successfully ${
      isPlanActive(plan?.status) ? "archived" : "activated"
    }.`,
  });

  const options = [
    {
      ...(!plan?.isPaid && {
        id: "copy-plan-id",
        Icon: <ContentCopy />,
        text: "Copy Plan ID",
        dataCy: "copy-plan-id",
        onClick: copy({
          text: plan?.id,
          feedback: "Plan ID copied to clipboard",
        }),
      }),
    },
    {
      id: "edit-plan",
      text: "Edit details",
      dataCy: "edit-plan-details",
      onClick: () => history.push(`/apps/${appId}/plan/${planId}/edit-plan`),
    },
    {
      id: "archive-plan",
      text: isPlanActive(plan?.status) ? "Archive plan" : "Activate plan",
      dataCy: "archive-plan",
      onClick: () => handleArchive(),
    },
    {
      id: "delete-plan",
      text: "Delete plan",
      dataCy: "delete-plan",
      onClick: () => setShowDeletePlanModal(true),
    },
  ].filter(Boolean);

  if (loading || !data) return <PlanDetailLoading />;

  if (error)
    return (
      <div tw="w-1/2 flex flex-col items-center justify-center p-10 text-h1 h-full">
        <WarningAmberIcon tw="text-orange-fill! w-6! h-6!" />
        <h3 tw="text-h3 text-app-gray500">Plan Not Found</h3>
        <p tw="text-body-sm text-app-gray400 text-center w-[260px]">
          The link you followed may be broken, or the plan has been removed
        </p>
      </div>
    );

  return (
    <div tw="w-1/2 flex flex-col overflow-y-auto">
      <div tw="p-5 flex flex-col">
        <div tw="flex items-center justify-between">
          <PlanTitle plan={plan} />
          <div className="flex items-center gap-1">
            <Tooltip
              content={
                !enableInstallButton && "Add a price to install this plan"
              }
              placement="bottom"
            >
              <Button
                text="Install & Publish"
                buttonStyle="inverted"
                leftIcon={<ContentCopy />}
                onClick={() => setShowInstallModal(true)}
                isDisabled={!enableInstallButton}
              />
            </Tooltip>
            <Dropdown options={options} dataCy="plan-options-dropdown" />
          </div>
        </div>
      </div>
      <div tw="flex-1 overflow-y-auto overflow-x-hidden h-screen">
        <Accordion name={sessionStorageKeys.plansAccordion}>
          {plan?.isPaid && (
            <PlanPrices appId={appId} plan={plan} refetch={refetch} />
          )}
          <PlanContentGroups plan={plan} />
          <PlanRedirects plan={plan} refetch={refetch} />
          {plan.teamAccountsEnabled && (
            <TeamAccounts plan={plan} refetch={refetch} />
          )}
          {!hideAdvanced && (
            <>
              <PlanSignupRules plan={plan} refetch={refetch} />
              <PlanLogic plan={plan} refetch={refetch} />
              <PlanPermissions plan={plan} />
            </>
          )}
        </Accordion>
        <div className="py-4 pl-6 pr-2">
          <Button
            buttonStyle="transparent"
            text={hideAdvanced ? "Show Advanced" : "Hide Advanced"}
            onClick={() => setHideAdvanced(!hideAdvanced)}
          />
        </div>
      </div>
      <DeletePlanModal
        showModal={showDeletePlanModal}
        setShowModal={setShowDeletePlanModal}
        plan={plan}
        refetch={refetch}
      />
      {!loading && plan && (
        <PlanInstallModal
          setShowModal={setShowInstallModal}
          showModal={showInstallModal}
          plan={plan}
        />
      )}
    </div>
  );
};

export const PlanDetails = () => {
  const { planId } = useParams<{ planId: string }>();

  // Check if planId is "redirects"
  if (planId === "redirects") {
    // Redirect to DefaultSettings component
    return <DefaultSettings />;
  }

  return <Plan />;
};
