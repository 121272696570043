import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";

interface Props {
  message: string;
  className?: string;
  showIcon?: boolean;
}
export const ErrorMessage = ({
  message,
  className,
  showIcon = true,
}: Props) => (
  <div className={mergeClassNames("flex gap-2 items-center", className)}>
    {showIcon && (
      <ErrorOutlineIcon
        className="w-4 h-4 text-red-600"
        data-testid="error-icon"
      />
    )}
    <p className="text-sm text-red-600 font-medium">{message}</p>
  </div>
);
