import React from "react";

export const CONTENT_PLACEHOLDER = `This reset code is valid for 1 hour.
  
You are receiving this because you (or someone else) have requested the reset of the password for your account.
  
If you did not request this, please ignore this email and your password will remain unchanged.`;

export const DEFAULT_PASSWORDLESS_HEADER = "Verify your email address";

export const DEFAULT_PASSWORDLESS_DESCRIPTION =
  "Your verification is below -- enter it in your open browser window and we'll get you signed in!";

export const DEFAULT_PASSWORDLESS_FOOTER = `If you didn’t request this email, there’s nothing to
worry about — you can safely ignore it.`;

export const DEFAULT_INTRO = `Thank you for creating an account! To finish signing up, please verify your email.
  
To confirm your email, please click this link:`;

export const DEFAULT_OUTRO = "Welcome and thanks!";

export const DEFAULT_BUTTON_TEXT = "Verify Email";

export const INITIAL_VERIFICATION_EMAIL_STATE = {
  replyTo: "",
  subject: "",
  paragraph1: "",
  paragraph2: "",
  paragraph3: "",
  primaryButtonText: "",
};

export const INITIAL_PASSWORD_RESET_EMAIL_STATE = {
  replyTo: "",
  subject: "",
  paragraph1: "",
  paragraph2: "",
};

export const INITIAL_PASSWORDLESS_EMAIL_STATE = {
  replyTo: "",
  subject: "",
  paragraph1: "",
  paragraph2: "",
  paragraph3: "",
};

export const INITIAL_WELCOME_EMAIL_STATE = {
  replyTo: "email@somemail.com",
  subject: "Thanks for creating a new account!",
  paragraph1: "Welcome!",
  paragraph2: `Thank you for creating a new account.

If you need assistance, our support team is here to help. Please do not hesitate to contact us if you have any questions or concerns.

Thanks again!`,
};

export const INITIAL_ABANDONED_CART_STATE = {
  replyTo: "email@somemail.com",
  subject: "Finish Signing Up",
  paragraph1:
    "Hey there! It looks like you didn't finish signing up for your subscription.",
  primaryButtonText: "Continue to Checkout",
};

export const INITIAL_COMMENTING_NOTIFICATION_STATE = {
  replyTo: "email@somemail.com",
  subject: "New Comment",
  paragraph1: "New Comment",
  paragraph2:
    "You will receive a notification email containing new comments and replies no more than once every 30 minutes.",
  primaryButtonText: "Go to Comment",
  unsubscribeLinkText: "Turn off comment notifications for this site",
};

interface HandleChangeArg {
  setFormValues: (
    value: React.SetStateAction<{
      [key: string]: string;
    }>
  ) => void;
  formValues: {
    [key: string]: string;
  };
}

type InputType = { type: string; value: string };

export const useHandleOnChange = ({
  setFormValues,
  formValues,
}: HandleChangeArg) => {
  const handleOnChange = ({ type, value }: InputType) =>
    setFormValues({
      ...formValues,
      ...{
        [type]: value,
      },
    });

  return { handleOnChange };
};
