import React from "react";
import { Modal, ModalTypes } from "components/Modal";
import Video from "components/Video";
import { Link } from "components";
import { OpenInNewOutlined } from "@mui/icons-material";

interface Props extends ModalTypes {
  title: string;
  videoSrc: string;
  docsLink: string;
}

const OnboardingModal = ({
  showModal,
  setShowModal,
  title,
  videoSrc,
  docsLink,
}: Props) => {
  return (
    <>
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title={title}
        showDivider
        width="800px"
        showBottomSection={false}
      >
        <div className="m-auto flex items-center flex-col">
          <Video
            src={videoSrc}
            allowFullScreen
            title={title}
            className="m-auto mb-5"
            width={760}
            height={400}
          />
          <Link
            className="flex items-center"
            isExternal
            to={docsLink}
            target="_blank"
            font="regular"
          >
            <span className="mr-1 text-base text-app-gray600">
              View Help Center article
            </span>
            <OpenInNewOutlined className="text-app-gray600" />
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default OnboardingModal;
