import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ApolloQueryResult } from "@apollo/client";
import useSubmit from "hooks/useSubmit";
import { Modal, ModalTypes } from "components/Modal";
import Text from "components/Text";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { useRemoveUserFromAppMutation } from "features";

type RemoveAdminModalProps = ModalTypes & {
  refetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  user: {
    name: string;
    id: string;
  };
};

const RemoveAdminModal = ({
  showModal,
  setShowModal,
  refetch,
  user,
}: RemoveAdminModalProps) => {
  const { currentUser } = useUsersContext();
  const { appId } = useParams<{ appId: string }>();
  const [remove] = useRemoveUserFromAppMutation();

  const appName = useMemo(
    () =>
      currentUser?.appConnections.find(
        (appConnection) => appConnection.app.id === appId
      )?.app.name,
    [appId, currentUser?.appConnections]
  );

  const { submit, isSubmitting } = useSubmit({
    action: remove,
    refetch,
    fields: {
      userId: user?.id,
    },
    cleanup: () => setShowModal(false),
    success: "Admin removed successfully",
    error: "Admin could not be removed",
  });

  const handleTransfer = () => {
    submit();
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Delete Admin"
      name="remove-admin"
      showDivider
      width="430px"
      actionButtons={{
        confirm: {
          label: "Confirm and Delete",
          onConfirm: handleTransfer,
          isLoading: isSubmitting,
          buttonStyle: "danger",
          dataCy: "confirm-remove-admin",
        },
      }}
    >
      <Text>
        This will remove <b>{user?.name}</b> from <b>{appName}</b> immediately.
      </Text>
    </Modal>
  );
};

export default RemoveAdminModal;
