import React, { Fragment } from "react";
import { Popover as UIPopover, Transition } from "@headlessui/react";
import { Button, ButtonStyleTypes } from "../Button/Button";

interface Props {
  target?: React.ReactNode;
  children: React.ReactNode;
  onClickOutside?: () => void;
  buttonText?: string;
  buttonStyle?: ButtonStyleTypes;
  buttonLeftIcon?: React.ReactNode;
  style?: { [key: string]: string };
  panelSize?: number;
  dataCy?: string;
  buttonNoBorder?: boolean;
  className?: string;
}

const Popover = ({
  target,
  children,
  onClickOutside,
  buttonText,
  buttonStyle,
  buttonLeftIcon,
  style,
  panelSize,
  buttonNoBorder,
  dataCy,
  className,
}: Props) => {
  return (
    <UIPopover className="relative">
      {({ open }) => (
        <>
          {target ? (
            <UIPopover.Button as="div">{target}</UIPopover.Button>
          ) : (
            <UIPopover.Button
              as={Button}
              leftIcon={buttonLeftIcon}
              buttonStyle={buttonStyle}
              hideFocusedOutline={buttonNoBorder}
              text={buttonText}
              dataCy={dataCy}
              className={className}
            />
          )}
          <UIPopover.Overlay
            onClick={onClickOutside}
            className={`${
              open ? "opacity-30 fixed inset-0 z-10" : "opacity-0"
            } `}
          />
          {open && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <UIPopover.Panel
                className="absolute z-10 mt-2 flex flex-col bg-white shadow-lg rounded-lg border border-solid border-app-gray200"
                style={{ zIndex: 1000, width: `${panelSize}px`, ...style }}
              >
                {children}
              </UIPopover.Panel>
            </Transition>
          )}
        </>
      )}
    </UIPopover>
  );
};

Popover.defaultProps = {
  target: undefined,
  onClickOutside: undefined,
  buttonStyle: "skeleton",
  buttonLeftIcon: undefined,
  panelSize: 250,
};

export default Popover;
