import React, { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "components/Loader";
import { localStorageKeys } from "helpers/cacheIds";
import { RouteName } from "enums/routes";
import { UserSessionAcr } from "generatedTypes";
import ErrorPage from "containers/ErrorPage";
import { CurrentUserQuery, useCurrentUserQuery } from "features";

interface DefaultContext {
  currentUser: CurrentUserQuery["currentUser"];
  error: ApolloError;
  isLoading: boolean;
  refetch: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<unknown>>;
}

const defaultContext: DefaultContext = {
  isLoading: false,
  error: undefined,
  currentUser: undefined,
  refetch: undefined,
};

const UsersContext = React.createContext(defaultContext);

const useUsersContext = () => {
  return React.useContext(UsersContext);
};

const UsersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, loading, error, refetch } = useCurrentUserQuery();

  const location = useLocation();
  const history = useHistory();
  const posthog = usePostHog();
  const { search } = window.location;

  useEffect(() => {
    if (error?.graphQLErrors[0]?.extensions?.code === "auth/user-not-found") {
      localStorage.setItem("redirectTo", `${location.pathname}${search}`);
      history.push(`/${RouteName.login}`);
    }
  }, [error?.graphQLErrors, history, location.pathname, search]);

  const page = location.pathname.split("/")[3];

  useEffect(() => {
    if (data?.currentUser) {
      posthog?.capture("pageview", { page });
      const { currentUser } = data;
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(currentUser.id, {
        email: currentUser.auth.email,
        name: `${currentUser.profile.firstName} ${currentUser.profile.lastName}`,
        connectedApps: currentUser.appConnections.length,
        appsOwned: currentUser.appConnections.filter(
          (app) => app.role === "OWNER"
        ).length,
        hasWebflowApp: currentUser.appConnections.some(
          (app) => app.app.stack === "WEBFLOW"
        ),
        hasReactApp: currentUser.appConnections.some(
          (app) => app.app.stack === "REACT"
        ),
        hasVanillaApp: currentUser.appConnections.some(
          (app) => app.app.stack === "VANILLA"
        ),
        paidApps: currentUser.appConnections.filter(
          (app) => app.app.stripePriceId
        ).length,
        basicApps: currentUser.appConnections.filter(
          (app) => app.app.stripeAppFeePercent === 4
        ).length,
        professionalApps: currentUser.appConnections.filter(
          (app) => app.app.stripeAppFeePercent === 2
        ).length,
        enterpriseApps: currentUser.appConnections.filter(
          (app) =>
            app.app.stripeAppFeePercent && app.app.stripeAppFeePercent < 2
        ).length,
      });

      try {
        window.revgems("user", currentUser.id, {
          name: `${currentUser.profile.firstName} ${currentUser.profile.lastName}`,
          email: currentUser.auth.email
        })
      } catch(err) {
        console.log(err)
      }

    }
  }, [data?.currentUser, posthog, page]);

  if (loading) return <Loader isFullScreen />;
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return <ErrorPage isFullScreen />;
  }

  const { currentUser } = data;
  const is2FaEnabled = currentUser?.auth?.twoFactorSettings?.enabled;
  const isSessionPassword =
    currentUser?.session?.acr === UserSessionAcr.Password;
  const isTwoFactorAuthPage = location.pathname.includes(
    RouteName.twoFactorAuthenticator
  );

  if (is2FaEnabled && isSessionPassword && !isTwoFactorAuthPage) {
    history.push(`/${RouteName.twoFactorAuthenticator}`);
  }

  const isUserLoggedAvailable = currentUser ? "true" : "";

  if (!isUserLoggedAvailable) {
    window.localStorage.removeItem(localStorageKeys.appId);
    window.localStorage.removeItem(localStorageKeys.planId);
    window.localStorage.removeItem(localStorageKeys.memberId);
  }

  return (
    <UsersContext.Provider
      value={{
        error,
        isLoading: loading,
        currentUser,
        refetch,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersContextProvider };
