import React from "react";
import MDEditor, { commands } from "@uiw/react-md-editor";
import styled from "styled-components";
import rehypeSanitize from "rehype-sanitize";
import { Description } from "components/Textarea/Textarea.styles";

const StyledMDEditor = styled(MDEditor)`
  --md-editor-font-family: "Plus Jakarta Text";
  border-radius: 6px;
  & > .w-md-editor-toolbar {
    background-color: rgba(246, 246, 246, 0.5);
    height: 40px;
    padding: 6px;
    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      width: 28px;
      & svg {
        width: 16px;
        height: 16px;
        color: rgba(164, 164, 164, 1);
        :hover {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
  .w-md-editor-content {
    font-family: "Plus Jakarta Text";
  }
  & .w-md-editor-text,
  .w-md-editor-text-input {
    padding: 8px 12px;
    font-size: 14px !important;
    line-height: 24px !important;
  }
`;

const StyledMDPreview = styled(MDEditor.Markdown)`
  font-size: 16px;
  line-height: 28px;
  & > h1 {
    border-bottom: none;
  }
  /* & > p {
    margin-bottom: 0px;
  } */
`;

interface Props {
  value: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
  placeholder?: string;
  label?: string;
  name?: string;
  className?: string;
  height?: number;
  description?: string;
}

const MarkdownEditor = ({
  value,
  onChange,
  readonly,
  placeholder,
  label,
  name,
  className,
  height = 200,
  description,
}: Props) => {
  const [isFocused, setIsFocused] = React.useState(false);
  if (readonly) {
    return (
      <div className="container" data-color-mode="light">
        <StyledMDPreview source={value} rehypePlugins={[rehypeSanitize]} />
      </div>
    );
  }
  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          tw="flex items-center text-body-sm font-bold mb-1"
        >
          {label}
        </label>
      )}
      <div className="container">
        <div data-color-mode="light">
          <StyledMDEditor
            id={name}
            textareaProps={{
              placeholder,
            }}
            height={height}
            value={value}
            onChange={onChange}
            preview="edit"
            extraCommands={[]}
            onFocus={() => setIsFocused(true)}
            commands={[
              commands.bold,
              commands.italic,
              commands.group(
                [
                  commands.title3,
                  commands.title4,
                  commands.title5,
                  commands.title6,
                ],
                {
                  name: "title",
                  groupName: "title",
                  buttonProps: { "aria-label": "Insert title" },
                }
              ),
              commands.link,
              commands.divider,
              commands.unorderedListCommand,
              commands.orderedListCommand,
            ]}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />
        </div>
      </div>
      {description && (
        <Description $show={isFocused}>{description}</Description>
      )}
    </div>
  );
};

export default MarkdownEditor;
