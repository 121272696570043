import styled, { css } from "styled-components";

export const AvatarContainer = styled.div<{
  $size: number;
  $textColor: string;
  $bgColor: string;
  $hasImage: boolean;
}>`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${(props) => `${props.$size}px`};
  height: ${(props) => `${props.$size}px`};
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: ${(props) => props.theme.colors?.white};
  background-color: ${(props) => props.theme.colors?.grey500};
  & > img {
    width: 100%;
    height: 100%;
    text-align: cover;
    color: transparent;
    text-indent: 10000px;
    object-fit: cover;
    background: white;
  }
  ${(props) =>
    props.$textColor &&
    css`
      color: ${props.$textColor};
      border: 1px solid ${props.$textColor};
    `}
  ${(props) => props.$bgColor && `background-color: ${props.$bgColor};`}
  ${(props) => props.$hasImage && "border: none"}
`;

export const AvatarSpan = styled.span<{ $fontSize: number }>`
  font-size: ${(props) => `${props.$fontSize}px`};
  text-transform: capitalize;
`;
