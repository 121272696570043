/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from "react";
import { AttributeInstallationBox, Accordion, Link } from "components";
import Input from "components/Input";
import Select from "components/Select";
import TextCombo from "components/TextCombo";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import { RestrictedURLs } from "./RestrictedURLs";
import HostedContent from "./HostedContents/HostedContent";
import { accessOptions } from "./content-group.utils";

const GatedContentFields = ({
  className,
  handleUpdate,
  name,
  onNameChange,
  contentKey,
  onContentKeyChange,
  accessType,
  onAccessTypeChange,
  plans,
  onPlansChange,
  urlInputs,
  setUrlInputs,
  redirect,
  setRedirect,
  customContentsInt,
  setCustomContents,
  isEditMode = true,
  onAddContent = (content) => undefined,
  onDeleteContent = (id: string) => undefined,
  onUpdateContent = (content) => undefined,
}) => {
  const { plans: persistedPlans } = usePlansContext();

  const planOptions = useMemo(
    () =>
      persistedPlans?.map((plan) => ({ label: plan?.name, value: plan?.id })),
    [persistedPlans]
  );

  const hostedCallbackProps = isEditMode
    ? { onAddContent, onDeleteContent, onUpdateContent, isEditMode }
    : { isEditMode };

  return (
    <form onSubmit={(e) => handleUpdate(e)} className={className}>
      <div className="flex flex-col p-5 gap-5">
        <Input
          label="Content Name"
          id="content-name"
          name="name"
          placeholder="e.g. Premium Newsletter"
          value={name}
          hideIndicators
          required
          onChange={onNameChange}
          description={
            <>
              For internal use. E.g. "Premium," "Admin," "Course 2," etc.{" "}
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
                target="_blank"
                isExternal
                underline
              >
                Help Docs →
              </Link>
            </>
          }
        />
        <TextCombo
          text={contentKey}
          id="content-key"
          isEditable
          name="key"
          label="Content ID"
          placeholder="premium-newsletter"
          onChange={onContentKeyChange}
          inputDescription={
            <>
              This ID is used to tag content on your site. Update your site when
              updating this ID.{" "}
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/7403036765339"
                target="_blank"
                isExternal
                underline
              >
                Help Docs →
              </Link>
            </>
          }
        />
        <div tw="rounded-lg bg-app-gray50 p-2">
          <Select
            id="select-access-type"
            label="Grant access to"
            description="Select which members should get access to this plan."
            options={accessOptions}
            value={accessType}
            onChange={onAccessTypeChange}
          />
          {accessType.value === "memberSpecific" && (
            <div tw="mt-2">
              <Select
                id="select-plans"
                label="Select specific plans"
                description="Select plans to apply these rules."
                placeholder="Select a plan to add"
                options={planOptions}
                value={plans}
                onChange={onPlansChange}
                isMulti
              />
            </div>
          )}
        </div>
      </div>
      <Accordion name="edit-gated-content">
        <RestrictedURLs
          formFields={urlInputs}
          setFormFields={setUrlInputs}
          redirect={redirect}
          setRedirect={setRedirect}
        />
        <HostedContent
          customContents={customContentsInt}
          onChange={(content) => setCustomContents(content)}
          setCustomContents={setCustomContents}
          {...hostedCallbackProps}
        />
      </Accordion>

      <div className="p-5">
        <AttributeInstallationBox
          description="Use these data attributes to tag gated content on your site."
          docsLink="https://docs.memberstack.com/hc/en-us/articles/7403036765339-Showing-and-Hiding-Elements-on-a-Page-in-Webflow"
          idValue={contentKey}
          nameValue="data-ms-content"
        />
      </div>
      <button className="sr-only" type="submit">
        Submit
      </button>
    </form>
  );
};

export default GatedContentFields;
