import styled, { css } from "styled-components";

const tagStyleMap = {
  primary: {
    background: "blue050",
    color: "blue900",
  },
  secondary: {
    background: "grey100",
    color: "grey900",
  },
  success: {
    background: "green050",
    color: "green700",
  },
  danger: {
    color: "red700",
    background: "red050",
  },
  warning: {
    color: "warningTagText",
    background: "warningTagBg",
  },
  default: {
    color: "blueTagText",
    background: "blueTagBg",
  },
  tan: {
    color: "black",
    background: "tanTagBg",
  },
  beta: {
    color: "purple500",
    background: "purple050",
  },
  fadedBlue: {
    color: "blue300",
    background: "blue050",
  },
};

const whiteTextVariants = [
  "primary",
  "danger",
  "warning",
  "default",
  "success",
  "tan",
];

export type TagVariants =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "default"
  | "tan"
  | "beta"
  | "fadedBlue";

export type SizeVariants = "small" | "medium";

export const TagContainer = styled.span<{
  $variant: TagVariants;
  $size: SizeVariants;
  $isClear: boolean;
  $isSolid: boolean;
  $isBold?: boolean;
}>(
  ({ $variant, $size, theme: { colors }, $isClear, $isSolid, $isBold }) => css`
    font-size: ${$size === "small" ? "12px" : "14px"};
    line-height: 16px;
    font-style: normal;
    font-weight: ${$isBold ? "bold" : "normal"};
    text-align: left;
    color: inherit;
    white-space: pre-line;
    text-decoration: none;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px 8px;
    /* text-transform: capitalize; */
    color: ${colors?.[tagStyleMap?.[$variant]?.color]};
    background: ${colors?.[tagStyleMap?.[$variant]?.background]};
    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    ${$isSolid &&
    css`
      color: ${whiteTextVariants.includes($variant)
        ? colors?.white
        : colors?.black};
      background: ${$variant === "secondary"
        ? colors?.[tagStyleMap?.[$variant]?.background]
        : colors?.[tagStyleMap?.[$variant]?.color]};
      background: ${$variant === "success" && colors?.green400};
    `}
    ${$isClear &&
    css`
      border: 1px solid #ebebeb;
      background: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
        inset 0px -1px 0px rgba(0, 0, 0, 0.1);
      & > div {
        background: ${colors?.[tagStyleMap?.[$variant]?.background]};
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 9999px;
        margin-right: 4px;
        padding: 2px;
      }
      & svg {
        fill: ${colors?.[tagStyleMap?.[$variant]?.color]};
        width: unset;
        height: unset;
      }
    `}
  `
);
