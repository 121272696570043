import React, { useCallback, useState } from "react";
import Input from "components/Input";
import Select from "components/Select";
import { useCurrencies } from "hooks/useCurrencies";
import { PriceCurrency } from "generatedTypes";

interface PriceInputEvent {
  value: number;
  currency: string;
}

interface Props {
  disabled?: boolean;
  label: string;
  placeholder?: string;
  onChange?: (values: PriceInputEvent) => void;
  onCurrencyChange?: (currency: PriceCurrency) => void;
  inputValue?: number;
  initialCurrency?: string;
  step?: string;
  name?: string;
}
const PriceInput = ({
  disabled,
  label,
  placeholder,
  inputValue,
  onChange,
  onCurrencyChange,
  initialCurrency,
  step,
  name,
}: Props) => {
  const { currencies } = useCurrencies();

  const currencyToDisplay = currencies.find(
    (currency) => currency.value === initialCurrency
  );

  const [value, setValue] = useState(inputValue);
  const [selectedCurrency, setSelectedCurrency] = useState(currencyToDisplay);

  const handlePriceChange = useCallback(
    (e) => {
      setValue(e.target.value);
      onChange({
        currency: selectedCurrency.value,
        value: parseFloat(e.target.value),
      });
    },
    [onChange, selectedCurrency.value]
  );

  const handleOnCurrencyChange = useCallback(
    (selected) => {
      onChange({ currency: selected.value, value });
      onCurrencyChange(selected.value);
      setSelectedCurrency(selected);
    },
    [onChange, onCurrencyChange, value]
  );

  return (
    <Input
      type="number"
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={handlePriceChange}
      leading={selectedCurrency.symbol}
      leadingInline
      required
      trailing={
        <div tw="flex pr-2">
          <Select
            isSearchable
            label=""
            value={selectedCurrency}
            onChange={handleOnCurrencyChange}
            options={currencies}
            inline
            isDisabled={disabled}
          />
        </div>
      }
      trailingInline
      disableTrailingPadding
      hideIndicators
      disabled={disabled}
      step={step}
    />
  );
};

export default PriceInput;

PriceInput.defaultProps = {
  placeholder: "",
  onChange: () => undefined,
  onCurrencyChange: () => undefined,
  inputValue: "",
  initialCurrency: "usd",
  disabled: false,
  step: "0.01",
};
