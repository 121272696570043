import React from "react";
import Text from "components/Text";
import Spacer from "components/Spacer";
import PlanTitle from "components/PlanTitle";
import { Background } from "../BackgroundWithGradient/BackgroundWithGradient";
import { Plan, PlanBlurb, DummyInput, DummyButton } from "./style";

interface PlaceholderPlanProps {
  type: "FREE" | "PAID";
  name: string;
  icon: string;
  selectTitle: boolean;
  buttonText: string;
}

const PlaceholderPlan = ({
  type,
  name,
  icon,
  selectTitle,
  buttonText,
}: PlaceholderPlanProps) => (
  <Background tw="items-center">
    <Plan>
      <PlanTitle
        plan={{
          name,
          icon,
        }}
      />
      <Spacer spacing="medium" />
      <Spacer spacing="xxSmall" />
      <div tw="flex flex-col">
        <PlanBlurb $variant="first" />
        <Spacer spacing="small" />
        <PlanBlurb $variant="second" />
      </div>
      <Spacer spacing="medium" />
      <Spacer spacing="xxSmall" />
      <div tw="flex flex-col">
        <Text noMargin tw="text-app-gray300">
          Email
        </Text>
        <Spacer spacing="xxSmall" />
        <DummyInput />
        <Spacer spacing="medium" />
        <Spacer spacing="xxSmall" />
        <Text noMargin tw="text-app-gray300">
          Password
        </Text>
        <Spacer spacing="xxSmall" />
        <DummyInput />
        <Spacer spacing="medium" />
        <Spacer spacing="xxSmall" />
        <DummyButton $selected={selectTitle}>
          <Text noMargin type="headingH3">
            {buttonText}
          </Text>
        </DummyButton>
      </div>
    </Plan>
  </Background>
);

export default PlaceholderPlan;
