import { gql } from "@apollo/client";

export const GET_SVIX_APP_PORTAL = gql`
  query GetSvixAppPortal {
    getSvixAppPortal
  }
`;

export const GET_WEBHOOKS = gql`
  query GetWebhooks {
    getWebhooks {
      id
      name
      enabled
      apiVersion
    }
  }
`;

export const GET_WEBHOOK = gql`
  query GetWebhook($id: ID!) {
    getWebhook(id: $id) {
      id
      name
      enabled
      events
      apiVersion
      endpoint
      signingSecret
    }
  }
`;

export const CREATE_WEBHOOK = gql`
  mutation CreateWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      id
      endpoint
      apiVersion
      events
    }
  }
`;

export const UPDATE_WEBHOOK = gql`
  mutation UpdateWebhook($input: UpdateWebhookInput!) {
    updateWebhook(input: $input) {
      id
      endpoint
      apiVersion
      events
    }
  }
`;

export const DELETE_WEBHOOK = gql`
  mutation DeleteWebhook($input: DeleteWebhookInput!) {
    deleteWebhook(input: $input)
  }
`;
