import React from "react";
import { Link } from "../Link/Link";

interface Props {
  children: React.ReactNode;
  showBottomInfo?: boolean;
  className?: string;
  bannerInfo?: {
    label?: string;
    linkText?: string;
    link?: string;
    children?: React.ReactNode;
  };
}

const SettingsBar = ({
  showBottomInfo,
  children,
  className,
  bannerInfo,
}: Props) => {
  return (
    <div
      tw="flex flex-col h-full min-w-[405px] max-w-[405px] rounded-none relative rounded-l-2xl border-r border-app-gray100"
      className={className}
    >
      {children}
      {showBottomInfo && (
        <>
          <div tw="flex-1" />
          <div className="bottom-0 rounded p-3 m-5 bg-app-gray50 border border-app-gray100 text-base">
            <p>{bannerInfo.label}</p>
            <Link
              to={bannerInfo.link}
              target="_blank"
              isExternal
              showAsLink
              isBold
            >
              {bannerInfo.linkText}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SettingsBar;
