import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Section } from "components/SettingsBar";
import Text from "components/Text";
import { Button } from "components";
import { Stripe } from "components/Icons";
import Spacer from "components/Spacer";

export const NoPayment = () => {
  const { appId } = useParams<{ appId: string }>();
  const navigate = useNavigate();

  return (
    <Section gutter="medium">
      <div tw="flex flex-col items-start justify-center bg-app-gray50 rounded-lg p-8">
        <Stripe tw="w-20 h-10" />
        <Spacer spacing="small" />
        <Text className="mb-5">
          Please connect your Stripe account to add a price to your plan.
        </Text>
        <Button
          text="Connect"
          onClick={() => navigate(`/apps/${appId}/settings/stripe-settings`)}
        />
      </div>
    </Section>
  );
};
