import { useConnectStripeMutation } from "features";

const useStripeConnect = ({ appId }: { appId?: string } = {}) => {
  const [
    connectStripe,
    { data: connectStripeData, error: connectStripeError },
  ] = useConnectStripeMutation();

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const state = params.get("state");

  const BASE_URL = "https://connect.stripe.com/oauth/authorize";
  const PARAMS = {
    response_type: "code",
    client_id: process.env.STRIPE_CLIENT_ID,
    scope: "read_write",
    redirect_uri: `${process.env.STRIPE_REDIRECT_URI}&state=${appId}`,
  };

  const url = `${BASE_URL}?${Object.entries(PARAMS)
    .map((x) => x.join("="))
    .join("&")}`;

  return {
    url,
    code,
    appId: state,

    connectStripeData,
    connectStripeError,

    connectStripe: () =>
      connectStripe({
        variables: { input: { code, appId: state } },
      }),
  };
};

export default useStripeConnect;
