import {
  endOfDay,
  lastDayOfMonth,
  startOfMonth,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
  isBefore,
} from "date-fns";
import { MemberMetricsInterval } from "generatedTypes";
import { DateRange } from "react-day-picker";

export enum PeriodTypes {
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_4_WEEKS = "LAST_4_WEEKS",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  LAST_12_MONTHS = "LAST_12_MONTHS",
  ALL_TIME = "ALL_TIME",
  CUSTOM = "CUSTOM",
}

export const periodTypes = {
  LAST_7_DAYS: { id: "LAST_7_DAYS", label: "Last 7 days" },
  LAST_4_WEEKS: { id: "LAST_4_WEEKS", label: "Last 4 weeks" },
  LAST_3_MONTHS: { id: "LAST_3_MONTHS", label: "Last 3 months" },
  LAST_12_MONTHS: { id: "LAST_12_MONTHS", label: "Last 12 months" },
  ALL_TIME: { id: "ALL_TIME", label: "All time" },
  CUSTOM: { id: "CUSTOM", label: "Custom" },
};

export const periodOptions = [
  {
    label: "Last 7 days",
    value: PeriodTypes.LAST_7_DAYS,
  },
  {
    label: "Last 4 weeks",
    value: PeriodTypes.LAST_4_WEEKS,
  },
  {
    label: "Last 3 months",
    value: PeriodTypes.LAST_3_MONTHS,
  },
  {
    label: "Last 12 months",
    value: PeriodTypes.LAST_12_MONTHS,
  },
  {
    label: "All time",
    value: PeriodTypes.ALL_TIME,
  },
  {
    label: "Custom",
    value: PeriodTypes.CUSTOM,
  },
];

export const periodIntervalOptions = {
  LAST_7_DAYS: [
    { label: "Daily", value: MemberMetricsInterval.Day },
    { label: "Hourly", value: MemberMetricsInterval.Hour },
  ],
  LAST_4_WEEKS: [{ label: "Daily", value: MemberMetricsInterval.Day }],
  LAST_3_MONTHS: [
    { label: "Daily", value: MemberMetricsInterval.Day },
    { label: "Monthly", value: MemberMetricsInterval.Month },
  ],
  LAST_12_MONTHS: [{ label: "Monthly", value: MemberMetricsInterval.Month }],
  ALL_TIME: [{ label: "Monthly", value: MemberMetricsInterval.Month }],
  CUSTOM: [
    { label: "Daily", value: MemberMetricsInterval.Day },
    { label: "Monthly", value: MemberMetricsInterval.Month },
  ],
};

export const defaultDateRange: DateRange = {
  from: startOfDay(subDays(new Date(), 6)),
  to: endOfDay(new Date()),
};

interface GetDateRangeT {
  period: PeriodTypes;
  dates: DateRange;
  interval: MemberMetricsInterval;
  allTimeFromDate?: Date;
}

export const getDateRange = ({
  period,
  dates,
  interval,
  allTimeFromDate = null,
}: GetDateRangeT) => {
  if (period !== PeriodTypes.CUSTOM) {
    // eslint-disable-next-line no-param-reassign
    dates.to = new Date();
  }
  switch (period) {
    case PeriodTypes.LAST_7_DAYS:
      return {
        from: startOfDay(subDays(dates.to, 6)),
        to: endOfDay(dates.to),
      };
    case PeriodTypes.LAST_4_WEEKS:
      return {
        from: startOfDay(subWeeks(dates.to, 4)),
        to: endOfDay(dates.to),
      };
    case PeriodTypes.LAST_3_MONTHS: {
      if (interval === MemberMetricsInterval.Month) {
        return {
          from: startOfDay(startOfMonth(subMonths(dates.to, 2))),
          to: endOfDay(lastDayOfMonth(dates.to)),
        };
      }

      return {
        from: startOfDay(subMonths(dates.to, 2)),
        to: endOfDay(dates.to),
      };
    }
    case PeriodTypes.LAST_12_MONTHS:
      return {
        from: startOfDay(startOfMonth(subMonths(dates.to, 12))),
        to: endOfDay(lastDayOfMonth(dates.to)),
      };
    case PeriodTypes.ALL_TIME: {
      const backDate = startOfMonth(subMonths(dates.to, 3));
      const select = isBefore(subMonths(allTimeFromDate, 1), backDate)
        ? subMonths(allTimeFromDate, 1)
        : backDate;
      return {
        from: startOfDay(select),
        to: endOfDay(lastDayOfMonth(dates.to)),
      };
    }
    default:
      return dates;
  }
};
