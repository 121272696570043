import React from "react";
import { CodeBlock } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { javascript } from "@codemirror/lang-javascript";

const VanillaCode = () => {
  const { appData } = useAppDataContext();
  const publicKey = appData?.apiKeys?.publicKey;

  const vanillaCode = `import memberstackDOM from '@memberstack/dom';
const memberstack = memberstackDOM.init({
  publicKey: "${publicKey}",
});`;
  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <h3 className="text-h3 font-bold">Install Code</h3>
        </div>
        <div className="mb-4">
          <p className="text-base">
            Copy and Paste the code snippet below into project.
          </p>
        </div>
      </div>

      <CodeBlock
        value={vanillaCode}
        readOnly
        extensions={[javascript()]}
        copyMessage="Vanilla code snippet was copied."
        editable={false}
      />
    </>
  );
};

export default VanillaCode;
