import React, { useEffect, useState } from "react";
import { Link, AttributeInstallationBox, Switch } from "components";
import { ModalTypes, Modal } from "components/Modal";
import { useUpdatePasswordlessSettingsMutation } from "features/sso";
import { useAppDataContext } from "routes/AppDataContextProvider";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { useParams } from "react-router-dom";

const PasswordlessModal = ({ setShowModal, showModal }: ModalTypes) => {
  const { appId } = useParams<{ appId: string }>();
  const { appData, refetch } = useAppDataContext();

  const [defaultToPasswordless, setDefaultToPasswordless] = useState(false);
  const [passwordlessEnabled, setPasswordlessEnabled] = useState(false);

  const [updatePasswordlessSettings, { loading }] =
    useUpdatePasswordlessSettingsMutation();

  const { submit: handleSubmit } = useOnSubmit({
    action: updatePasswordlessSettings,
    refetch,
    successMsg: "Passwordless settings were successfully updated",
    fields: { defaultToPasswordless, enabled: passwordlessEnabled },
  });

  const passwordEnablesApi = appData?.passwordlessEnabled;
  const defaultToPasswordlessApi = appData?.defaultToPasswordless;

  useEffect(
    () => setDefaultToPasswordless(defaultToPasswordlessApi),
    [defaultToPasswordlessApi]
  );
  useEffect(
    () => setPasswordlessEnabled(passwordEnablesApi),
    [passwordEnablesApi]
  );

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Passwordless"
      width="500px"
      description="Let members login with only their email address."
      descriptionLink={
        <span>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://docs.memberstack.com/hc/en-us/articles/14052704355483"
            isExternal
            underline
          >
            Learn more
          </Link>{" "}
          or{" "}
          <Link
            to={`/apps/${appId}/settings/emails/password-reset-email`}
            isExternal
            underline
          >
            Preview Passwordless Email.
          </Link>
        </span>
      }
      // bottomSectionComponent={
      //   // <Switch
      //   //   label={
      //   //     passwordlessEnabled
      //   //       ? "Enable Passwordless Login"
      //   //       : "Disabled Passwordless Login"
      //   //   }
      //   //   id="passwordlessEnabled"
      //   //   name="passwordlessEnabled"
      //   //   isChecked={passwordlessEnabled}
      //   //   onChange={() => setPasswordlessEnabled(!passwordlessEnabled)}
      //   //   description="Enabled in the Pre-built UI, Zapier, Make, & Admin API."
      //   // />
      // }
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleSubmit,
          isLoading: loading,
        },
        cancel: { label: "Cancel" },
      }}
    >
      <>
        <Switch
          label={
            passwordlessEnabled
              ? "Enabled Passwordless Login"
              : "Disabled Passwordless Login"
          }
          id="passwordlessEnabled"
          name="passwordlessEnabled"
          isChecked={passwordlessEnabled}
          onChange={() => setPasswordlessEnabled(!passwordlessEnabled)}
          description="Enabled in the Pre-built UI, Zapier, Make, & Admin API."
        />
        <div tw="mt-6" />
        <Switch
          label="Set as default signup and login method in the pre-built UI."
          id="defaultToPasswordless"
          name="defaultToPasswordless"
          isChecked={defaultToPasswordless}
          onChange={() => setDefaultToPasswordless(!defaultToPasswordless)}
        />
        <div tw="mt-6" />
        <AttributeInstallationBox
          isLinkOnly
          docsLink="https://docs.memberstack.com/hc/en-us/articles/14052704355483"
        />
      </>
    </Modal>
  );
};

export default PasswordlessModal;
