import { useState, useMemo } from "react";
import { MutationFunction, FetchResult, ApolloError } from "@apollo/client";
import { useToastAlertContext } from "components/toastAlert";

export interface useSubmitProps {
  /**
   * The request to be submitted.
   */
  action: MutationFunction;
  /**
   * Refetch to be performed after success.
   */
  refetch?: () => void;
  /**
   * Clean-up function to be run after any submit attempt.
   */
  cleanup?: () => void;
  /**
   * Fields to be submitted in the request.
   */
  fields?: { [key: string]: string | number | Object };
  /**
   * Optional success message (toast alert).
   */
  success?: string;
  /**
   * Optional error message (toast alert). If not provided, the actual error message will be displayed.
   */
  error?: string;
  /**
   * Perform after successful request, and receives the result as a parameter.
   */
  onSuccess?: (result: FetchResult) => void;
  /**
   * Perform after failed request, and receives the error as a parameter.
   */
  onError?: (error: ApolloError) => void;
}
const useSubmit = ({
  action,
  refetch,
  fields,
  cleanup,
  success,
  error,
  onSuccess,
  onError,
}: useSubmitProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createToastAlert } = useToastAlertContext();

  return useMemo(
    () => ({
      isSubmitting,
      submit: async (paramFields?) => {
        setIsSubmitting(true);
        createToastAlert({
          processing: true,
        });
        try {
          const result = await action({
            variables: {
              input: paramFields || fields,
            },
          });

          if (refetch) {
            await refetch();
          }

          if (success) {
            createToastAlert({
              alertType: "success",
              message: success,
            });
          }
          if (onSuccess) {
            onSuccess(result);
          }
        } catch (err) {
          createToastAlert({
            alertType: "error",
            message: error || `${err}`,
          });

          if (onError) {
            onError(err);
          }
        } finally {
          setIsSubmitting(false);
          if (cleanup) {
            cleanup();
          }
        }
      },
    }),
    [
      isSubmitting,
      action,
      fields,
      refetch,
      success,
      onSuccess,
      createToastAlert,
      error,
      onError,
      cleanup,
    ]
  );
};

export default useSubmit;
