import { useToastAlertContext } from "components/toastAlert";
import {
  useConnectUserToGoogleMutation,
  useLoginUserGoogleMutation,
  useSignupUserGoogleMutation,
} from "features";
import { RouteName } from "enums/routes";
import { getResponseError } from "helpers/getResponseError";
import { useHistory } from "react-router-dom";

const useGoogleSSO = () => {
  const history = useHistory();
  const { createToastAlert } = useToastAlertContext();

  const [signupUserGoogle] = useSignupUserGoogleMutation();
  const [loginUserGoogle] = useLoginUserGoogleMutation();
  const [connectUserToGoogle] = useConnectUserToGoogleMutation();

  const handleUserLogin = async (response) => {
    try {
      await loginUserGoogle({
        variables: {
          input: {
            id_token: response.credential,
          },
        },
      });
      const redirectTo = localStorage.getItem("redirectTo");
      localStorage.removeItem("redirectTo");
      history.push(redirectTo || `/${RouteName.apps}`);
    } catch (e) {
      const errorMessage = getResponseError(e);
      createToastAlert({
        alertType: "error",
        message: errorMessage,
      });
    }
  };

  const handleUserSignup = async (response) => {
    try {
      await signupUserGoogle({
        variables: {
          input: {
            id_token: response.credential,
          },
        },
      });
      const redirectTo = localStorage.getItem("redirectTo");
      localStorage.removeItem("redirectTo");
      history.push(redirectTo || `/${RouteName.apps}`);
    } catch (e) {
      const errorMessage = getResponseError(e);
      createToastAlert({
        alertType: "error",
        message: errorMessage,
      });
    }
  };

  const handleUserConnect = async (response) => {
    try {
      await connectUserToGoogle({
        variables: {
          input: {
            id_token: response.credential,
          },
        },
      });

      createToastAlert({
        alertType: "success",
        message: "Google successfully connected to your account.",
      });
    } catch (e) {
      const errorMessage = getResponseError(e);
      createToastAlert({
        alertType: "error",
        message: errorMessage,
      });
    }
  };
  return {
    login: handleUserLogin,
    signup: handleUserSignup,
    connect: handleUserConnect,
  };
};

export default useGoogleSSO;
