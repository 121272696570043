import React from "react";
import { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import Text from "components/Text";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";

export const Trials = ({ days, setPriceInputs, priceInputs, disabled }) => {
  const handleOnChange = ({ target: { name, value } }) => {
    setPriceInputs((prev) => ({ ...prev, [name]: parseInt(value, 10) }));
  };

  return (
    <div className={mergeClassNames(disabled && "opacity-50")}>
      <TitleSection
        title="Trials"
        titleHint="Add temporary free-trials to your price"
        tw="p-5"
        gutter="medium"
      >
        <div tw="flex justify-between items-center">
          <Text noMargin tw="text-utility-md">
            Duration
          </Text>
          <div tw="w-28">
            <Input
              value={days}
              name="freeTrialDays"
              onChange={(e) => handleOnChange(e)}
              trailing="days"
              hideIndicators
              disabled={disabled}
              type="number"
            />
          </div>
        </div>
      </TitleSection>
    </div>
  );
};
