import React, { useState, useMemo } from "react";
import { UserAppRole } from "generatedTypes";
import { useGetUsersQuery } from "features";
import AddTeamMemberModal from "containers/Settings/team-settings/AddTeamMemberModal";
import cursorPaginationHelper from "helpers/cursorPaginationHelper";
import { Button, Card, Dropdown } from "components";
import OnboardingModal from "components/Modal/OnboardingModal";
import { useUsersContext } from "containers/App/UsersContextProvider";
import Background from "assets/images/empty_state_bg.png";
import RemoveAdminModal from "./RemoveAdminModal";
import TransferMembershipModal from "./TransferOwnershipModal";
import { TeamMemberTableSkeleton } from "./TeamSettingsLoader";

const TeamMemberTable = () => {
  const [removeTarget, setRemoveTarget] = useState(null);
  const [transferTarget, setTransferTarget] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const { currentUser } = useUsersContext();
  const { loading, data, refetch } = useGetUsersQuery({});
  const { nodes: users } = cursorPaginationHelper(data?.getUsers);

  const { id: currentUserId } = currentUser;

  const isOwner = useMemo(
    () =>
      users?.some(
        (user) =>
          user?.user?.id === currentUserId && user?.role === UserAppRole.Owner
      ),
    [currentUserId, users]
  );

  const teamMembers = useMemo(
    () =>
      users &&
      [
        users.find((user) => user?.role === UserAppRole.Owner),
        ...users.filter((user) => user?.role !== UserAppRole.Owner),
      ].filter((i) => i),
    [users]
  );

  if (loading) return <TeamMemberTableSkeleton />;

  const handleTransferOwnership = (name, id) => {
    setTransferTarget({ name, id });
    setShowTransferModal(true);
  };

  const handleRemoveAdmin = (name, id) => {
    setRemoveTarget({ name, id });
    setShowRemoveModal(true);
  };

  return (
    <>
      <Card>
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-base font-bold">
            Team Members &nbsp;
            <span className="text-utility-sm text-app-gray500 font-normal">
              (Manage who has access to this Memberstack project)
            </span>
          </h3>
          <Button
            type="button"
            id="add-admin"
            text="Add Admin"
            onClick={() => setShowInviteModal(true)}
            isLoading={loading}
          />
        </div>
        <div className="max-h-52 h-52 flex flex-col overflow-auto">
          <table className="w-full" id="team-members-table">
            <thead className="sr-only">
              <tr className="text-utility-md">
                <th className="p-4">Name</th>
                <th className="p-4">Email</th>
                <th className="p-4">Role</th>
                <th className="p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {teamMembers?.map(({ role, user }, index) => {
                const options = [
                  ...(isOwner
                    ? [
                        {
                          dataCy: "transfer-ownership",
                          text: `Transfer ownership to ${user?.profile?.firstName}`,
                          onClick: () =>
                            handleTransferOwnership(
                              user?.profile?.firstName,
                              user?.id
                            ),
                        },
                      ]
                    : []),
                  {
                    dataCy: "remove-admin",
                    text: "Remove Admin",
                    onClick: () =>
                      handleRemoveAdmin(
                        `${user?.profile?.firstName} ${user?.profile?.lastName}`,
                        user?.id
                      ),
                  },
                ];

                const name = `${user?.profile?.firstName} ${user?.profile?.lastName}`;
                return (
                  <tr
                    key={user?.id}
                    className="text-utility-md border-solid border-b last:border-b-0"
                  >
                    <td className="p-4">{name}</td>
                    <td className="p-4">{user?.auth?.email}</td>
                    <td className="p-4 capitalize">{role.toLowerCase()}</td>
                    <td className="p-4 flex justify-end">
                      {role !== UserAppRole.Owner && (
                        <Dropdown
                          options={options}
                          dataCy={`team-action-${index}`}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {teamMembers?.length <= 2 && (
            <div
              className="h-full flex items-center justify-center border-t"
              style={{ background: `url(${Background})` }}
            >
              <p className="text-body-sm text-app-gray500">
                Invite admins to help manage your project.
              </p>
            </div>
          )}
        </div>
      </Card>

      <AddTeamMemberModal
        showModal={showInviteModal}
        setShowModal={setShowInviteModal}
        refetch={refetch}
      />
      {isOwner && (
        <TransferMembershipModal
          showModal={showTransferModal}
          setShowModal={setShowTransferModal}
          refetch={refetch}
          user={transferTarget}
        />
      )}
      <RemoveAdminModal
        showModal={showRemoveModal}
        setShowModal={setShowRemoveModal}
        refetch={refetch}
        user={removeTarget}
      />

      <OnboardingModal
        docsLink="https://docs.memberstack.com/hc/en-us/articles/9549865106715-Team-Members-Admin-and-Owners"
        setShowModal={setShowOnboardingModal}
        showModal={showOnboardingModal}
        title="Learn more about adding a team member below"
        videoSrc="https://www.loom.com/embed/886291ffd7ba4640af2615d9fae37a08"
      />
    </>
  );
};

export default TeamMemberTable;
