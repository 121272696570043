import React from "react";
import { Modal, ModalTypes } from "components/Modal";
import { AttributeInstallationBox, Link } from "components";

interface Props extends ModalTypes {
  rootDomain: string;
  authSubdomain: string;
}

export const DnsRecordsModal = ({
  showModal,
  setShowModal,
  rootDomain,
  authSubdomain,
}: Props) => {
  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Copy DNS Records"
      description="Add these CNAME records to your DNS provider."
      descriptionLink={
        <Link
          target="_blank"
          rel="noreferrer"
          to="https://docs.memberstack.com/hc/en-us/articles/15793943107995"
          isExternal
          underline
        >
          Learn more
        </Link>
      }
      showDivider
      showBottomSection={false}
    >
      <AttributeInstallationBox
        header={`memberstack-client.${rootDomain}`}
        nameLabel="Add a CNAME record for"
        nameValue="memberstack-client"
        idLabel="Pointing to"
        idValue="service-client.memberstack.com"
        showMoreInfo={false}
        showBottomSection={false}
        className="mb-3"
      />
      <AttributeInstallationBox
        header={`${authSubdomain}.${rootDomain}`}
        nameLabel="Add a CNAME record for"
        nameValue={authSubdomain}
        idLabel="Pointing to"
        idValue="service-auth.memberstack.com"
        showMoreInfo={false}
        showBottomSection={false}
        className="mb-3"
      />
      {/* <AttributeInstallationBox
        header={`memberstack-static.${rootDomain}`}
        nameLabel="Add a CNAME record for"
        nameValue="memberstack-static"
        idLabel="Pointing to"
        idValue="service-static.memberstack.com"
        showMoreInfo={false}
        showBottomSection={false}
      /> */}
    </Modal>
  );
};
