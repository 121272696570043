import React from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

interface Props {
  formInputs: { [key: string]: string };
  brandColor: string;
  appImage: string;
}

export const AuthUI = ({ formInputs, brandColor, appImage }: Props) => (
  <div tw="w-[360px] rounded-[20px] rounded-3xl shadow-lg bg-white">
    <div tw="p-7 pb-0">
      <div tw="pb-[30px] flex justify-center">
        <img src={appImage} height={43} tw="h-[43px]" alt="app-logo" />
      </div>

      <h2 tw="text-h2 text-center font-bold mb-5">
        {formInputs.auth || "Authorize"}
      </h2>

      <p tw="text-base text-center">
        <b>Integration Name </b>
        {formInputs.auth_info || "will share the following info with "}
        <b> Your App Name</b>
      </p>
      <ul tw="my-5 text-base list-disc pl-[40%]">
        <li>Email</li>
        <li>Profile</li>
      </ul>

      <button
        tw="mb-5 w-full font-bold text-white p-3 rounded-lg text-base"
        type="button"
        style={{ backgroundColor: brandColor || "#2962FF" }}
      >
        {formInputs.continue || "Continue"}
      </button>
    </div>
    <div tw="bg-app-gray50 flex items-center justify-center text-body-sm font-bold p-3 rounded-b-[20px]">
      <VerifiedUserIcon tw="mr-1 w-3.5! h-3.5!" /> Secured by Memberstack
    </div>
  </div>
);
