import React from "react";
import { Banner, ContentWrapper, Link, SectionCard } from "components";
import { useEnvContext } from "containers/App/contexts/Environment";
import Skeleton from "react-loading-skeleton";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import Spacer from "components/Spacer";

export const WebhooksSkeleton = () => (
  <SectionCard loading>
    <ul className="list-none p-0 m-0">
      {["loader1", "loader2"].map((loader) => (
        <div
          className={mergeClassNames(
            "flex justify-between items-center",
            loader && "mb-4"
          )}
          key={loader}
        >
          <Skeleton width={285} height={28} />
          <div className="flex items-center min-w-0">
            <Skeleton width={65} height={24} />
            <Spacer isVertical={false} spacing="large" />
            <Skeleton width={99} height={28} className="mr-4" />
            <Skeleton width={64} height={32} />
          </div>
        </div>
      ))}
    </ul>
  </SectionCard>
);

export const SSOApplicationsSkeleton = () => (
  <SectionCard loading>
    <ul tw="list-none p-0 m-0">
      {["loader1", "loader2", "loader3"].map((loader) => (
        <div
          className={mergeClassNames(
            "flex justify-between items-center",
            loader && "mb-4"
          )}
          key={loader}
        >
          <Skeleton width={285} height={28} />
          <div className="flex items-center min-w-0">
            <Skeleton width={64} height={32} />
          </div>
        </div>
      ))}
    </ul>
  </SectionCard>
);

export const ApiManagementSkeleton = () => (
  <div className="flex flex-row gap-6">
    <SectionCard loading footerSection={<Skeleton width={462} height={16} />}>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <Skeleton width={157} height={21.69} />
          <Skeleton width={157} height={32} />
        </div>
        <div className="flex justify-between items-center">
          <Skeleton width={157} height={21.69} />
          <Skeleton width={157} height={32} />
        </div>
      </div>
    </SectionCard>
    <SectionCard loading>
      <div className="flex justify-between items-center">
        <Skeleton width={47} height={21.69} />
        <Skeleton width={272} height={32} />
      </div>
    </SectionCard>
  </div>
);

export const DevtoolsLoader = () => {
  const { isSandboxEnv } = useEnvContext();

  return (
    <ContentWrapper>
      <div className="flex flex-col m-5">
        <div className="flex items-center flex-row justify-between">
          <Skeleton width={116} height={32} />
          <div className="mt-4 flex gap-2 md:mt-0 md:ml-4">
            <Skeleton width={113} height={32} />
            <Skeleton width={120} height={32} />
            <Skeleton width={182} height={32} />
          </div>
        </div>
        {isSandboxEnv && (
          <Banner
            className="mt-4"
            text="You’re viewing and editing Test Mode Data"
            description="You will need to update API Keys and SSO Integrations when you switch to Live Mode."
            rightPlugin={
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/7595262385819-Managing-Test-Mode-and-Live-Mode"
                target="_blank"
                isBold
                isExternal
              >
                Learn more
              </Link>
            }
          />
        )}
      </div>
      <div
        className="px-5 pb-5 flex flex-col gap-4"
        tw="h-[calc(100vh - 76px)]"
      >
        <ApiManagementSkeleton />
        <SSOApplicationsSkeleton />
        <WebhooksSkeleton />
        <SSOApplicationsSkeleton />
      </div>
    </ContentWrapper>
  );
};
