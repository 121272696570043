import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type LoginUserEmailPasswordMutationVariables = Types.Exact<{
  input: Types.LoginUserEmailPasswordInput;
}>;


export type LoginUserEmailPasswordMutation = (
  { __typename?: 'Mutation' }
  & { loginUserEmailPassword: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { profile: (
      { __typename?: 'UserProfile' }
      & Pick<Types.UserProfile, 'firstName'>
    ), auth: (
      { __typename?: 'UserAuth' }
      & Pick<Types.UserAuth, 'email'>
      & { twoFactorSettings?: Types.Maybe<(
        { __typename?: 'UserTwoFactorSettings' }
        & Pick<Types.UserTwoFactorSettings, 'enabled'>
      )> }
    ) }
  ) }
);

export type LoginUserGoogleMutationVariables = Types.Exact<{
  input: Types.LoginUserGoogleInput;
}>;


export type LoginUserGoogleMutation = (
  { __typename?: 'Mutation' }
  & { loginUserGoogle: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { profile: (
      { __typename?: 'UserProfile' }
      & Pick<Types.UserProfile, 'firstName'>
    ), auth: (
      { __typename?: 'UserAuth' }
      & Pick<Types.UserAuth, 'email'>
      & { twoFactorSettings?: Types.Maybe<(
        { __typename?: 'UserTwoFactorSettings' }
        & Pick<Types.UserTwoFactorSettings, 'enabled'>
      )> }
    ) }
  ) }
);

export type GenerateZendeskTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateZendeskTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'generateZendeskToken'>
);

export type LogoutUserMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = (
  { __typename?: 'Mutation' }
  & { logoutUser: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type SetupUser2FaMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SetupUser2FaMutation = (
  { __typename?: 'Mutation' }
  & { setupUser2FA: (
    { __typename?: 'UserTwoFactorAuthPayload' }
    & Pick<Types.UserTwoFactorAuthPayload, 'secret' | 'url'>
  ) }
);

export type AuthenticateUserWith2FaMutationVariables = Types.Exact<{
  input: Types.AuthenticateUserWith2FaInput;
}>;


export type AuthenticateUserWith2FaMutation = (
  { __typename?: 'Mutation' }
  & { authenticateUserWith2FA: (
    { __typename?: 'User' }
    & { auth: (
      { __typename?: 'UserAuth' }
      & { twoFactorSettings?: Types.Maybe<(
        { __typename?: 'UserTwoFactorSettings' }
        & Pick<Types.UserTwoFactorSettings, 'backupCodes'>
      )> }
    ) }
  ) }
);

export type EnableUser2FaMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type EnableUser2FaMutation = (
  { __typename?: 'Mutation' }
  & { enableUser2FA: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type DisableUser2FaMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DisableUser2FaMutation = (
  { __typename?: 'Mutation' }
  & { disableUser2FA: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type SignupUserEmailPasswordMutationVariables = Types.Exact<{
  input: Types.SignupUserEmailPasswordInput;
}>;


export type SignupUserEmailPasswordMutation = (
  { __typename?: 'Mutation' }
  & { signupUserEmailPassword: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { auth: (
      { __typename?: 'UserAuth' }
      & Pick<Types.UserAuth, 'email'>
    ) }
  ) }
);

export type SignupUserGoogleMutationVariables = Types.Exact<{
  input: Types.LoginUserGoogleInput;
}>;


export type SignupUserGoogleMutation = (
  { __typename?: 'Mutation' }
  & { signupUserGoogle: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { auth: (
      { __typename?: 'UserAuth' }
      & Pick<Types.UserAuth, 'email'>
    ) }
  ) }
);


export const LoginUserEmailPasswordDocument = gql`
    mutation LoginUserEmailPassword($input: LoginUserEmailPasswordInput!) {
  loginUserEmailPassword(input: $input) {
    id
    profile {
      firstName
    }
    auth {
      email
      twoFactorSettings {
        enabled
      }
    }
  }
}
    `;
export type LoginUserEmailPasswordMutationFn = Apollo.MutationFunction<LoginUserEmailPasswordMutation, LoginUserEmailPasswordMutationVariables>;

/**
 * __useLoginUserEmailPasswordMutation__
 *
 * To run a mutation, you first call `useLoginUserEmailPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserEmailPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserEmailPasswordMutation, { data, loading, error }] = useLoginUserEmailPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserEmailPasswordMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserEmailPasswordMutation, LoginUserEmailPasswordMutationVariables>) {
        return Apollo.useMutation<LoginUserEmailPasswordMutation, LoginUserEmailPasswordMutationVariables>(LoginUserEmailPasswordDocument, baseOptions);
      }
export type LoginUserEmailPasswordMutationHookResult = ReturnType<typeof useLoginUserEmailPasswordMutation>;
export type LoginUserEmailPasswordMutationResult = Apollo.MutationResult<LoginUserEmailPasswordMutation>;
export type LoginUserEmailPasswordMutationOptions = Apollo.BaseMutationOptions<LoginUserEmailPasswordMutation, LoginUserEmailPasswordMutationVariables>;
export const LoginUserGoogleDocument = gql`
    mutation LoginUserGoogle($input: LoginUserGoogleInput!) {
  loginUserGoogle(input: $input) {
    id
    profile {
      firstName
    }
    auth {
      email
      twoFactorSettings {
        enabled
      }
    }
  }
}
    `;
export type LoginUserGoogleMutationFn = Apollo.MutationFunction<LoginUserGoogleMutation, LoginUserGoogleMutationVariables>;

/**
 * __useLoginUserGoogleMutation__
 *
 * To run a mutation, you first call `useLoginUserGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserGoogleMutation, { data, loading, error }] = useLoginUserGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserGoogleMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserGoogleMutation, LoginUserGoogleMutationVariables>) {
        return Apollo.useMutation<LoginUserGoogleMutation, LoginUserGoogleMutationVariables>(LoginUserGoogleDocument, baseOptions);
      }
export type LoginUserGoogleMutationHookResult = ReturnType<typeof useLoginUserGoogleMutation>;
export type LoginUserGoogleMutationResult = Apollo.MutationResult<LoginUserGoogleMutation>;
export type LoginUserGoogleMutationOptions = Apollo.BaseMutationOptions<LoginUserGoogleMutation, LoginUserGoogleMutationVariables>;
export const GenerateZendeskTokenDocument = gql`
    mutation GenerateZendeskToken {
  generateZendeskToken
}
    `;
export type GenerateZendeskTokenMutationFn = Apollo.MutationFunction<GenerateZendeskTokenMutation, GenerateZendeskTokenMutationVariables>;

/**
 * __useGenerateZendeskTokenMutation__
 *
 * To run a mutation, you first call `useGenerateZendeskTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateZendeskTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateZendeskTokenMutation, { data, loading, error }] = useGenerateZendeskTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateZendeskTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateZendeskTokenMutation, GenerateZendeskTokenMutationVariables>) {
        return Apollo.useMutation<GenerateZendeskTokenMutation, GenerateZendeskTokenMutationVariables>(GenerateZendeskTokenDocument, baseOptions);
      }
export type GenerateZendeskTokenMutationHookResult = ReturnType<typeof useGenerateZendeskTokenMutation>;
export type GenerateZendeskTokenMutationResult = Apollo.MutationResult<GenerateZendeskTokenMutation>;
export type GenerateZendeskTokenMutationOptions = Apollo.BaseMutationOptions<GenerateZendeskTokenMutation, GenerateZendeskTokenMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser {
  logoutUser {
    id
  }
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, baseOptions);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const SetupUser2FaDocument = gql`
    mutation SetupUser2FA {
  setupUser2FA {
    secret
    url
  }
}
    `;
export type SetupUser2FaMutationFn = Apollo.MutationFunction<SetupUser2FaMutation, SetupUser2FaMutationVariables>;

/**
 * __useSetupUser2FaMutation__
 *
 * To run a mutation, you first call `useSetupUser2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupUser2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupUser2FaMutation, { data, loading, error }] = useSetupUser2FaMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetupUser2FaMutation(baseOptions?: Apollo.MutationHookOptions<SetupUser2FaMutation, SetupUser2FaMutationVariables>) {
        return Apollo.useMutation<SetupUser2FaMutation, SetupUser2FaMutationVariables>(SetupUser2FaDocument, baseOptions);
      }
export type SetupUser2FaMutationHookResult = ReturnType<typeof useSetupUser2FaMutation>;
export type SetupUser2FaMutationResult = Apollo.MutationResult<SetupUser2FaMutation>;
export type SetupUser2FaMutationOptions = Apollo.BaseMutationOptions<SetupUser2FaMutation, SetupUser2FaMutationVariables>;
export const AuthenticateUserWith2FaDocument = gql`
    mutation AuthenticateUserWith2FA($input: AuthenticateUserWith2FAInput!) {
  authenticateUserWith2FA(input: $input) {
    auth {
      twoFactorSettings {
        backupCodes
      }
    }
  }
}
    `;
export type AuthenticateUserWith2FaMutationFn = Apollo.MutationFunction<AuthenticateUserWith2FaMutation, AuthenticateUserWith2FaMutationVariables>;

/**
 * __useAuthenticateUserWith2FaMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserWith2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserWith2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserWith2FaMutation, { data, loading, error }] = useAuthenticateUserWith2FaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateUserWith2FaMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserWith2FaMutation, AuthenticateUserWith2FaMutationVariables>) {
        return Apollo.useMutation<AuthenticateUserWith2FaMutation, AuthenticateUserWith2FaMutationVariables>(AuthenticateUserWith2FaDocument, baseOptions);
      }
export type AuthenticateUserWith2FaMutationHookResult = ReturnType<typeof useAuthenticateUserWith2FaMutation>;
export type AuthenticateUserWith2FaMutationResult = Apollo.MutationResult<AuthenticateUserWith2FaMutation>;
export type AuthenticateUserWith2FaMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserWith2FaMutation, AuthenticateUserWith2FaMutationVariables>;
export const EnableUser2FaDocument = gql`
    mutation EnableUser2FA {
  enableUser2FA {
    id
  }
}
    `;
export type EnableUser2FaMutationFn = Apollo.MutationFunction<EnableUser2FaMutation, EnableUser2FaMutationVariables>;

/**
 * __useEnableUser2FaMutation__
 *
 * To run a mutation, you first call `useEnableUser2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUser2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUser2FaMutation, { data, loading, error }] = useEnableUser2FaMutation({
 *   variables: {
 *   },
 * });
 */
export function useEnableUser2FaMutation(baseOptions?: Apollo.MutationHookOptions<EnableUser2FaMutation, EnableUser2FaMutationVariables>) {
        return Apollo.useMutation<EnableUser2FaMutation, EnableUser2FaMutationVariables>(EnableUser2FaDocument, baseOptions);
      }
export type EnableUser2FaMutationHookResult = ReturnType<typeof useEnableUser2FaMutation>;
export type EnableUser2FaMutationResult = Apollo.MutationResult<EnableUser2FaMutation>;
export type EnableUser2FaMutationOptions = Apollo.BaseMutationOptions<EnableUser2FaMutation, EnableUser2FaMutationVariables>;
export const DisableUser2FaDocument = gql`
    mutation DisableUser2FA {
  disableUser2FA {
    id
  }
}
    `;
export type DisableUser2FaMutationFn = Apollo.MutationFunction<DisableUser2FaMutation, DisableUser2FaMutationVariables>;

/**
 * __useDisableUser2FaMutation__
 *
 * To run a mutation, you first call `useDisableUser2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUser2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUser2FaMutation, { data, loading, error }] = useDisableUser2FaMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableUser2FaMutation(baseOptions?: Apollo.MutationHookOptions<DisableUser2FaMutation, DisableUser2FaMutationVariables>) {
        return Apollo.useMutation<DisableUser2FaMutation, DisableUser2FaMutationVariables>(DisableUser2FaDocument, baseOptions);
      }
export type DisableUser2FaMutationHookResult = ReturnType<typeof useDisableUser2FaMutation>;
export type DisableUser2FaMutationResult = Apollo.MutationResult<DisableUser2FaMutation>;
export type DisableUser2FaMutationOptions = Apollo.BaseMutationOptions<DisableUser2FaMutation, DisableUser2FaMutationVariables>;
export const SignupUserEmailPasswordDocument = gql`
    mutation SignupUserEmailPassword($input: SignupUserEmailPasswordInput!) {
  signupUserEmailPassword(input: $input) {
    id
    auth {
      email
    }
  }
}
    `;
export type SignupUserEmailPasswordMutationFn = Apollo.MutationFunction<SignupUserEmailPasswordMutation, SignupUserEmailPasswordMutationVariables>;

/**
 * __useSignupUserEmailPasswordMutation__
 *
 * To run a mutation, you first call `useSignupUserEmailPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserEmailPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserEmailPasswordMutation, { data, loading, error }] = useSignupUserEmailPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupUserEmailPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SignupUserEmailPasswordMutation, SignupUserEmailPasswordMutationVariables>) {
        return Apollo.useMutation<SignupUserEmailPasswordMutation, SignupUserEmailPasswordMutationVariables>(SignupUserEmailPasswordDocument, baseOptions);
      }
export type SignupUserEmailPasswordMutationHookResult = ReturnType<typeof useSignupUserEmailPasswordMutation>;
export type SignupUserEmailPasswordMutationResult = Apollo.MutationResult<SignupUserEmailPasswordMutation>;
export type SignupUserEmailPasswordMutationOptions = Apollo.BaseMutationOptions<SignupUserEmailPasswordMutation, SignupUserEmailPasswordMutationVariables>;
export const SignupUserGoogleDocument = gql`
    mutation SignupUserGoogle($input: LoginUserGoogleInput!) {
  signupUserGoogle(input: $input) {
    id
    auth {
      email
    }
  }
}
    `;
export type SignupUserGoogleMutationFn = Apollo.MutationFunction<SignupUserGoogleMutation, SignupUserGoogleMutationVariables>;

/**
 * __useSignupUserGoogleMutation__
 *
 * To run a mutation, you first call `useSignupUserGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserGoogleMutation, { data, loading, error }] = useSignupUserGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupUserGoogleMutation(baseOptions?: Apollo.MutationHookOptions<SignupUserGoogleMutation, SignupUserGoogleMutationVariables>) {
        return Apollo.useMutation<SignupUserGoogleMutation, SignupUserGoogleMutationVariables>(SignupUserGoogleDocument, baseOptions);
      }
export type SignupUserGoogleMutationHookResult = ReturnType<typeof useSignupUserGoogleMutation>;
export type SignupUserGoogleMutationResult = Apollo.MutationResult<SignupUserGoogleMutation>;
export type SignupUserGoogleMutationOptions = Apollo.BaseMutationOptions<SignupUserGoogleMutation, SignupUserGoogleMutationVariables>;