import React, { useCallback, useState } from "react";
import { Form, Link } from "components";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { Modal, ModalTypes } from "components/Modal";
import Input from "components/Input";
import { useImportStripeProductMutation } from "features";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import { getResponseError } from "helpers/getResponseError";

interface Props extends ModalTypes {}

const ImportPlanViaStripe = ({ setShowModal, showModal }: Props) => {
  const { refetchPlans } = usePlansContext();
  const [productId, setProductId] = useState<string>();
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalError, setModalError] = useState(null);

  const { createToastAlert } = useToastAlertContext();
  const [importStripeProduct] = useImportStripeProductMutation();

  const handleImport = useCallback(async () => {
    setIsSubmitting(true);

    try {
      await importStripeProduct({
        variables: {
          input: { productId },
        },
      });
      setShowModal(false);
      createToastAlert({
        alertType: "success",
        message: "Stripe product was successfully imported",
      });
      refetchPlans();
    } catch (e) {
      setModalError(getResponseError(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [productId]);

  const handleSubmit = useCallback(() => {
    if (productId.startsWith("price_")) {
      return setErrorMsg(
        "Price IDs are not accepted. Please re-paste the Product ID."
      );
    }
    return handleImport();
  }, [handleImport, productId]);

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Import Plan via Stripe"
      description="Sync Memberstack with an already existing Stripe product."
      descriptionLink={
        <Link
          to="https://docs.memberstack.com/hc/en-us/search?utf8=%E2%9C%93&query=import+plan"
          target="_blank"
          underline
          isExternal
        >
          Learn more
        </Link>
      }
      showDivider
      width="460px"
      hideCancel
      actionButtons={{
        confirm: {
          label: "Confirm & Import",
          onConfirm: handleSubmit,
          isLoading: isSubmitting,
          isDisabled: !isFormValid,
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form onSubmit={handleSubmit} onValidityCheck={setIsFormValid}>
        <Input
          label="Stripe Product ID"
          infoHintText="The unique Product ID in your Stripe dashboard."
          hideIndicators
          placeholder="Eg: prod_aisjfb78as3jsa"
          value={productId}
          onChange={(e) => setProductId(e.target.value)}
          required
          errorMessage={errorMsg}
        />
      </Form>
    </Modal>
  );
};

export default ImportPlanViaStripe;
