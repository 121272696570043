import React, { useState, useCallback } from "react";
import { Button, Dropdown, Link } from "components";
import { AddOutlined, ExpandMore, LockOutlined } from "@mui/icons-material";
import useTranslations from "hooks/useTranslations";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";
import { useEnvContext } from "containers/App/contexts/Environment";
import { Tooltip } from "components/Tooltip";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import ImportMembersModal from "./ImportMembersModal";
import { useMembersContext } from "../context/MembersContextProvider";
import CreateMemberModal from "../modals/CreateMemberModal";

const AddMember = ({ isMemberLimitReached }) => {
  const { activeFreePlans } = usePlansContext();
  const { setShowCreateMemberModal, showCreateMemberModal } =
    useMembersContext();
  const { isSubscribed } = useAppDataContext();
  const { isSandboxEnv } = useEnvContext();
  const { translate } = useTranslations();

  const [showImportMembersModal, setShowImportMembersModal] = useState(false);

  const DropdownTrigger = useCallback(() => {
    let tooltipContent = null;
    if (isSandboxEnv && isMemberLimitReached) {
      tooltipContent = (
        <p className="text-center text-utility-md leading-5 w-48">
          Test mode Member Limit Reached
        </p>
      );
    } else if (!isSandboxEnv && !isSubscribed) {
      tooltipContent = (
        <p className="text-center text-utility-md leading-5 w-48">
          <Link underline to="./settings/billing">
            Upgrade Plan
          </Link>
          &nbsp; to add Live Mode Members
        </p>
      );
    }
    return (
      <Tooltip content={tooltipContent} placement="bottom">
        <Button
          className="items-center"
          text="Add Member"
          leftIcon={<AddOutlined />}
          rightIcon={<ExpandMore />}
          dataCy="add-member-button"
          isDisabled={
            (!isSandboxEnv && !isSubscribed) ||
            (isSandboxEnv && isMemberLimitReached)
          }
        />
      </Tooltip>
    );
  }, [isMemberLimitReached, isSandboxEnv, isSubscribed]);

  const addMemberOptions = [
    {
      text: translate("addMember.addMember", "Add a Member"),
      onClick: () => setShowCreateMemberModal(true),
      dataCy: "add-member-option",
    },
    {
      text: translate("addMember.importFreeMembers", "Import members"),
      onClick: () => setShowImportMembersModal(true),
      dataCy: "import-free-members-option",
      Icon: isSandboxEnv ? <LockOutlined /> : null,
      disabled: isSandboxEnv,
      tooltipText: isSandboxEnv ? (
        <div className="whitespace-nowrap">
          Import only works for live-mode members
        </div>
      ) : null,
    },
  ];

  return (
    <>
      <Dropdown options={addMemberOptions} targetElement={DropdownTrigger} />
      <CreateMemberModal
        showModal={showCreateMemberModal}
        setShowModal={setShowCreateMemberModal}
        onImportClick={() => setShowImportMembersModal(true)}
        activeFreePlans={activeFreePlans}
      />
      <ImportMembersModal
        showModal={showImportMembersModal}
        setShowModal={setShowImportMembersModal}
      />
    </>
  );
};

export default AddMember;
