import React, { useMemo } from "react";
import { CopyButton } from "../Button/CopyButton";
import * as S from "./BackupCodeBlock.styles";

interface Props {
  backupCodes: string[];
}

const BackupCodeBlock = ({ backupCodes }: Props) => {
  const clipboardValue = useMemo(
    () => backupCodes?.join(" ").toString(),
    [backupCodes]
  );

  const splitBackupCodes = useMemo(() => {
    const backupCodeClone = [...backupCodes];
    const firstHalf = backupCodeClone.splice(0, 5);
    const secondHalf = backupCodeClone.splice(-5);
    return [firstHalf, secondHalf];
  }, [backupCodes]);

  const [firstHalf, secondHalf] = splitBackupCodes;

  return (
    <S.Container>
      <div className="flex justify-center gap-12 text-base text-app-gray700">
        <div>
          {firstHalf?.map((code) => (
            <p key={code}>{code}</p>
          ))}
        </div>
        <div>
          {secondHalf?.map((code) => (
            <p key={code}>{code}</p>
          ))}
        </div>
      </div>
      <CopyButton
        value={clipboardValue}
        text="&nbsp;Copy"
        message="Backup codes copied to clipboard"
        tw="absolute top-1 right-1"
      />
    </S.Container>
  );
};

export default BackupCodeBlock;
