import React from "react";
import styled, { css } from "styled-components";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";

export const StyledSpan = styled.span<{
  $isActive?: boolean;
  $isDisabled?: boolean;
}>`
  ${({ theme: { colors }, $isActive, $isDisabled }) => css`
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    color: ${colors.grey900};
    border: 1px solid transparent;
    ${$isActive &&
    css`
      background-color: ${colors.white};
      border-radius: 8px;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      border-color: ${colors.grey100};
    `}
    ${$isDisabled &&
    css`
      cursor: pointer;
    `}
  `}
`;

interface Tab {
  label: string;
  value: string;
  Icon?: React.ReactNode;
}

interface PilledTabsProps {
  tabs: Tab[];
  active?: string;
  onChange?: (string) => void;
  disabled?: boolean;
  label?: string;
}

const PilledTabs = ({
  tabs,
  active,
  onChange,
  disabled,
  label,
}: PilledTabsProps) => {
  const memoizedTabs = React.useMemo(() => tabs, [tabs]);
  return (
    <div>
      {label && (
        <p
          className={mergeClassNames(
            disabled && "opacity-50",
            "text-utility-md font-bold mb-1"
          )}
        >
          {label}
        </p>
      )}
      <div
        className={mergeClassNames(
          disabled && "opacity-50",
          "flex flex-1 p-0.5 bg-app-gray50 rounded-lg select-none overflow-hidden"
        )}
        aria-label="Tabs"
      >
        {memoizedTabs.map((tab) => (
          <StyledSpan
            key={tab?.value}
            onClick={() => !disabled && onChange(tab?.value)}
            aria-hidden
            $isActive={active === tab?.value}
            $isDisabled={disabled}
          >
            {tab.Icon ? (
              <div tw="flex all-child:(h-4 w-4)! mr-1.5">{tab.Icon}</div>
            ) : null}
            <span
              className={
                active === tab?.value ? "font-semibold" : "font-medium"
              }
            >
              {tab?.label}
            </span>
          </StyledSpan>
        ))}
      </div>
    </div>
  );
};

PilledTabs.defaultProps = {
  active: "",
  onChange: () => {},
  disabled: false,
};

export default PilledTabs;
