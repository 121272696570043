import React from "react";
import SettingsBar from "components/SettingsBar";
import { TabItem, TabNav, Tabs, TabContent } from "components/Tabs";
import { useLocation, useParams } from "react-router-dom";
import { PlanRoutes } from "routes/PlansRoutes";
import { RouteName } from "enums/routes";
import { PlansPageLoading } from "./PlansSkeleton";
import PlansHeader from "./PlansHeader";
import { PlanList } from "./PlanList";
import DefaultSettings from "./Plan/DefaultSettings/DefaultSettings";
import { generateTabLabel } from "./utils";
import { usePlansContext } from "./context/Plans.context";

export const PlansPage = () => {
  const { pathname } = useLocation();

  const { appId, planId } = useParams<{ appId: string; planId: string }>();
  const { apps, plan } = RouteName;

  const { loadingPlans, activePlans, archivedPlans } = usePlansContext();

  const isFullPageRoutes = [`/${apps}/${appId}/${plan}/${planId}/create-price`];

  const isFullPage = isFullPageRoutes.includes(pathname);

  if (loadingPlans) return <PlansPageLoading />;

  return (
    !isFullPage && (
      <div className="flex flex-col flex-wrap h-full overflow-hidden">
        <SettingsBar tw="w-1/2 min-w-[350px] max-w-[50%] overflow-hidden">
          <PlansHeader />
          <Tabs isExpanded showBottomBorder>
            <TabNav>
              <TabItem
                id="active"
                label={generateTabLabel("Active", activePlans.length)}
              />
              <TabItem
                id="archived"
                label={generateTabLabel("Archived", archivedPlans?.length)}
              />
            </TabNav>
            <TabContent id="active" tw="mt-0">
              <PlanList type="active" />
            </TabContent>
            <TabContent id="archived">
              <PlanList type="archived" />
            </TabContent>
          </Tabs>
        </SettingsBar>
        {!planId ? <DefaultSettings /> : <PlanRoutes />}
      </div>
    )
  );
};
