import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StripeCVCCard from "assets/icons/strip-cvc-card.svg";
import StripeCard from "assets/icons/stripe-card.svg";
import VisaCard from "assets/icons/visa.svg";
import MasterCard from "assets/icons/mastercard.svg";
import AmericanExpress from "assets/icons/american-express.svg";
import Discover from "assets/icons/discover.svg";
import PoweredByStripe from "assets/icons/powered_by_stripe.svg";
import "react-loading-skeleton/dist/skeleton.css";
import { ExpandMore } from "@mui/icons-material";
import Text from "components/Text";
import { PriceType } from "generatedTypes";
import intervalFormatter from "helpers/intervalFormatter";
import { currencies } from "constants/currencies";
import * as S from "./styles";

const StripeUI = ({
  priceInputs,
  setupFee,
  freeTrialDays,
  intervalType,
  intervalCount,
  planName,
}) => {
  const currency = currencies.find(
    (curr) => curr.label === priceInputs?.currency
  );
  const symbol = currency?.symbol;

  const pricingInterval = useMemo(
    () => intervalFormatter({ count: intervalCount, type: intervalType }),
    [intervalCount, intervalType]
  );

  const renderPricing = useMemo(() => {
    const price = `${symbol}${priceInputs?.amount || ""}`;

    if (setupFee) {
      const fee = `${symbol}${
        parseInt(priceInputs?.amount, 10) + parseInt(setupFee, 10)
      }`;
      return (
        <div className="flex flex-col items-center">
          <span
            className="font-semibold"
            tw="text-[34.6329px] leading-[46px] tracking-tight"
          >
            {fee}
          </span>
          {priceInputs?.type === PriceType.Subscription && (
            <span tw="text-gray-400 text-[13.859px] leading-[30px]">
              Then <span className="capitalize">{price}</span> {pricingInterval}
            </span>
          )}
        </div>
      );
    }
    if (freeTrialDays) {
      return (
        <div className="flex flex-col items-end">
          <span
            className="font-semibold"
            tw="text-[34.6329px] leading-[46px] tracking-tight"
          >
            {freeTrialDays} days free
          </span>
          {priceInputs?.type === PriceType.Subscription && (
            <span tw="text-gray-400 text-[13.859px] leading-[30px]">
              Then <span className="capitalize">{price}</span> {pricingInterval}
            </span>
          )}
        </div>
      );
    }
    return (
      <div className="flex items-end">
        <span
          className="font-semibold"
          tw="text-[34.6329px] leading-[46px] tracking-tight"
        >
          {price}
        </span>
        {priceInputs?.type === PriceType.Subscription && (
          <span tw="text-gray-400 text-[13.859px] leading-[30px] ml-1.5">
            {pricingInterval}
          </span>
        )}
      </div>
    );
  }, [
    freeTrialDays,
    priceInputs?.amount,
    pricingInterval,
    priceInputs?.type,
    setupFee,
    symbol,
  ]);

  return (
    <div tw="bg-white flex-grow-0 order-2 rounded-[20px] p-[60px] border w-[420px] items-center">
      <div className="flex flex-col items-center justify-center w-full pb-5">
        <Text color="grey400" className="pb-1">
          {planName}
        </Text>
        {renderPricing}
      </div>
      <div className="pb-6">
        <S.StripeText>Payment method</S.StripeText>
        <div className="flex gap-1.5">
          <div className="w-full border-black border rounded-md flex justify-center items-start flex-col shadow-sm gap-1.5 p-2">
            <StripeCard />
            <span tw="text-[12.24px] leading-[17px] tracking-[0.005rem]">
              Card
            </span>
          </div>
          <div className="w-full border rounded-md flex justify-center items-start flex-col shadow-sm gap-1.5 p-2">
            <AccountBalanceIcon tw="text-gray-400" />
            <span tw="text-gray-400 text-[12.24px] leading-[17px] tracking-[0.005rem]">
              US bank account
            </span>
          </div>
        </div>
      </div>
      <div className="pb-6">
        <S.StripeInputPlaceholder label="Email" />
      </div>
      <div className="pb-6">
        <S.StripeText>Card information</S.StripeText>
        <S.MultiInputPlaceholder>
          <S.Container>
            <Skeleton width={119} height={12} />
            <div className="flex justify-between items-center gap-1.5">
              <VisaCard />
              <MasterCard />
              <AmericanExpress />
              <Discover />
            </div>
          </S.Container>
          <hr className="w-full" />
          <div tw="flex justify-between w-full">
            <div tw="flex gap-1 items-center px-[6.89px] py-[9.18px] w-full">
              <Skeleton width={28} height={12} />
              <Skeleton width={28} height={12} />
            </div>
            <div className="border-l" />
            <S.Container>
              <Skeleton width={28} height={12} />
              <StripeCVCCard />
            </S.Container>
          </div>
        </S.MultiInputPlaceholder>
      </div>
      <div className="pb-6">
        <S.StripeInputPlaceholder label="Name on card" />
      </div>
      <div className="pb-6">
        <S.StripeText>Country or region</S.StripeText>
        <S.MultiInputPlaceholder>
          <S.Container>
            <Skeleton width={74} height={12} />
            <div className="flex justify-between items-center gap-1.5">
              <ExpandMore />
            </div>
          </S.Container>
          <hr className="w-full" />
          <S.Container>
            <Skeleton width={41} height={12} />
          </S.Container>
        </S.MultiInputPlaceholder>
      </div>
      <div className="flex justify-center">
        <PoweredByStripe />
      </div>
    </div>
  );
};

export default StripeUI;
