import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Add, DeleteOutline } from "@mui/icons-material";
import {
  useGetEmailSettingsQuery,
  useRemoveEmailDomainMutation,
} from "features";

import { Button, Card, Dropdown, Link } from "components";
import Tag from "components/Tag";
import { useToastAlertContext } from "components/toastAlert";
import ErrorPage from "containers/ErrorPage";
import ConfigureEmailSenderAddressModal from "./ConfigureEmailSenderAddressModal";

const EmailSenderAddress = () => {
  const [showSetUpModal, setShowSetUpModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);

  const { createToastAlert } = useToastAlertContext();
  const { data, loading, error, refetch } = useGetEmailSettingsQuery();

  const [removeEmailDomain, { loading: removingEmail }] =
    useRemoveEmailDomainMutation();

  const emailSettings = data?.getEmails;

  const handleRemoveEmailDomain = async () => {
    try {
      await removeEmailDomain();
      refetch();
      createToastAlert({
        alertType: "success",
        message: "Email domain removed successfully",
      });
    } catch (er) {
      createToastAlert({
        message: "Error removing email domain",
        alertType: "error",
      });
    }
  };

  if (error) {
    return <ErrorPage isFullScreen />;
  }

  const isVerified = emailSettings?.verified;
  const emailDomain = emailSettings?.from;
  const emailSenderAddress = emailSettings?.emailDNSSettings;

  return (
    <>
      <Card className="p-4">
        <h3 className="text-base font-bold mb-4">Email Sender Address</h3>
        <div className="lg:w-custom-560 w-full">
          <p className="text-body-sm font-bold mb-2">Email Sender Address</p>
          <p className="text-body-sm text-app-gray600 mb-2">
            Use your own email address for sending emails to members. &nbsp;
            <Link
              to="https://docs.memberstack.com/hc/en-us/articles/11193994555547-Custom-Email-Sender-Address-Feature"
              target="_blank"
              rel="noreferrer"
              className="text-app-primary"
              isExternal
              underline
            >
              Learn more
            </Link>
          </p>
          {loading && <Skeleton height={64} width={560} />}
          {!loading && emailSenderAddress ? (
            <Card className="p-4 flex items-center justify-between">
              <p className="text-base" data-cy="email-sender-address">
                {emailDomain}
              </p>
              <div className="flex items-center gap-4">
                <Tag
                  text={isVerified ? "Verified" : "Pending"}
                  variant={isVerified ? "primary" : "secondary"}
                  size="medium"
                />
                {!isVerified && (
                  <Button
                    text="Verify"
                    buttonStyle="skeleton"
                    onClick={() => setShowConfigModal(true)}
                  />
                )}
                <Dropdown
                  options={[
                    {
                      text: "Delete",
                      onClick: handleRemoveEmailDomain,
                      isDangerText: true,
                      Icon: <DeleteOutline />,
                      disabled: removingEmail,
                    },
                  ]}
                />
              </div>
            </Card>
          ) : (
            <Button
              text="Add email sender address"
              buttonStyle="skeleton"
              leftIcon={<Add />}
              onClick={() => setShowSetUpModal(true)}
              dataCy="add-email-sender-address-button"
            />
          )}
        </div>
      </Card>
      <ConfigureEmailSenderAddressModal
        showModal={showSetUpModal}
        setShowModal={setShowSetUpModal}
        refetch={refetch}
      />
      {!loading && emailSettings && (
        <ConfigureEmailSenderAddressModal
          showModal={showConfigModal}
          setShowModal={setShowConfigModal}
          step={2}
          emailDnsSettings={emailSettings?.emailDNSSettings}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default EmailSenderAddress;
