import React, { useMemo } from "react";
import { DEFAULT_AVATAR_SIZE, getInitials } from "./avatar.utils";
import * as S from "./Avatar.styles";

export type AvatarSizeTypes =
  | "xSmall"
  | "small"
  | "medium"
  | "large"
  | "xLarge";

interface AvatarProp {
  /**
   * To provide custom styles.
   */
  className?: string;
  /**
   * First name which would be used to generate initials.
   */
  firstName?: string;
  /**
   * Last name which would be used to generate initials.
   */
  lastName?: string;
  /**
   * Specifies the size of the avatar.
   * It can be `small`, `medium`, `large`, `xLarge` <br>
   * Default is `small`
   */
  size?: AvatarSizeTypes;
  /**
   * The src attribute for the img element.
   */
  src?: string;
  /**
   * Used in combination with src or srcSet to provide an alt attribute for the rendered img element.
   */
  alt?: string;
  textColor?: string;
  bgColor?: string;
}

const Avatar = ({
  alt = "avatar",
  className,
  firstName,
  lastName,
  size = "small",
  src,
  textColor,
  bgColor,
}: AvatarProp) => {
  const initials = getInitials(firstName, lastName);

  /** Returns font size for the text */
  const fontSize = useMemo(() => DEFAULT_AVATAR_SIZE[size] / 2, [size]);

  const content = React.useMemo(
    () =>
      src ? (
        <img src={src} alt={alt} loading="lazy" />
      ) : (
        <S.AvatarSpan $fontSize={fontSize}>{initials}</S.AvatarSpan>
      ),
    [src, alt, fontSize, initials]
  );

  return (
    <S.AvatarContainer
      className={className}
      $size={DEFAULT_AVATAR_SIZE[size]}
      data-testid="avatar"
      $textColor={textColor}
      $bgColor={bgColor}
      $hasImage={Boolean(src)}
    >
      {content}
    </S.AvatarContainer>
  );
};

export default Avatar;
