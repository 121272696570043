import React, { useEffect, useState, useRef, useReducer, useMemo } from "react";
import useGenerateComponentID from "hooks/useComponentId";
import {
  ErrorOutline,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Label } from "../Label/Label";
import { CopyButton } from "../Button/CopyButton";
import * as S from "./styles";

export interface InputProps {
  id?: string;
  /**
   * Minimum for range of numeric input
   */
  min?: string;
  /**
   * Maximum for range of numeric input
   */
  max?: string;
  /**
   * The type of input (this component implements textual types)
   */
  type?: string;
  /**
   * The step of numeric increment/decrement permitted
   */
  step?: string;
  /**
   * DOM name for the element
   */
  name?: string;
  /**
   * Value
   */
  value?: string | number | readonly string[];
  /**
   * Label to appear above the input
   */
  label?: string;
  /**
   *
   */
  title?: string;
  /**
   * Regex pattern for input validation
   */
  pattern?: string;
  /**
   * Prevent modification and grey the input
   */
  disabled?: boolean;
  /**
   * Require the component in validation
   */
  required?: boolean;
  /**
   * Similar to disabled but without the styling change
   */
  readOnly?: boolean;
  /**
   * Ghost text to appear before any value is added
   */
  placeholder?: string;
  /**
   * onChange callback
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * onClick callback, useful for controlling click events in higher components
   */
  onClick?: (event: React.MouseEventHandler<HTMLInputElement>) => void;
  /**
   * Renders a copy button at the end of the input. This is overridden by leading elements.
   */
  allowCopy?: boolean;
  /**
   * Minimum length for text inputs
   */
  minLength?: number;
  /**
   * Maximum length for text inputs
   */
  maxLength?: number;
  /**
   * Hide stylized indicators like the 'Optional' tag and error symbol regardless of state
   */
  hideIndicators?: boolean;
  /**
   * Hide label
   */
  hideLabel?: boolean;
  /**
   * Hint text to appear in the top-right corner, above the input. Replaces "Optional".
   */
  hintText?: string | React.ReactNode;
  /**
   * Text to appear as a hover info hint.
   */
  infoHintText?: string;
  /**
   * Text or element to appear in front of the input
   */
  leading?: React.ReactNode | string;
  /**
   * Toggle the visual demarcation for the leading element
   */
  leadingInline?: boolean;
  /**
   * Text or element to appear at the end of the input. Superseded in password mode.
   */
  trailing?: React.ReactNode | string;
  /**
   * Toggle the visual demarcation for the trailing element
   */
  trailingInline?: boolean;
  /**
   * Toggle preset padding on leading & trailing elements
   */
  disableLeadingPadding?: boolean;
  disableTrailingPadding?: boolean;

  /**
   * Override padding on actual input element
   */
  inputPadding?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  /*
   * Error message to be displayed
   */
  errorMessage?: string;
  /**
   * Success message to be displayed
   */
  successMessage?: string;
  /**
   * specifies whether or not an input field should have autocomplete enabled.
   */
  autocomplete?: string;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  inverted?: boolean;
  dataCy?: string;
  subLabel?: string;
  description?: React.ReactNode;
  inputWidth?: number;
  hintLabel?: React.ReactNode;
}

const Input = (props: InputProps) => {
  const {
    id,
    min,
    max,
    type,
    step,
    name,
    label,
    hideLabel,
    value,
    title,
    pattern,
    disabled,
    readOnly,
    required,
    allowCopy,
    minLength,
    maxLength,
    placeholder,
    onChange,
    onClick,
    onBlur,
    leading: leadingElement,
    leadingInline,
    trailing,
    trailingInline,
    hideIndicators,
    hintText = undefined,
    hintLabel = undefined,
    infoHintText,
    inputPadding,
    disableLeadingPadding,
    errorMessage,
    successMessage,
    autocomplete = "off",
    className,
    inverted,
    dataCy,
    subLabel,
    description,
    inputWidth,
    disableTrailingPadding = false,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [inputState, dispatchInputState] = useReducer(
    (state, action) => {
      const newState = { ...state, ...action };
      if (newState.firstInput && newState.firstBlur) {
        newState.message = newState.text;
      }
      return newState;
    },
    {
      message: "",
      text: "",
      firstInput: false,
      firstBlur: false,
      isFocused: false,
    }
  );
  const inputId = useGenerateComponentID();
  const inputEl = useRef<HTMLInputElement>();

  const handleOnClick = (e) => onClick && onClick(e);

  const typeOverride = useMemo(() => {
    if (type === "password" && showPassword) {
      return "text";
    }

    return type;
  }, [type, showPassword]);

  const renderLabelText = useMemo(
    () =>
      label && (
        <Label
          htmlFor={inputId}
          text={label}
          hint={infoHintText}
          className={hideLabel && "sr-only"}
        />
      ),
    [hideLabel, infoHintText, inputId, label]
  );

  const renderCornerHint = useMemo(() => {
    const optional =
      !hideIndicators && !required && !readOnly && !disabled && "Optional";

    if (hintLabel) {
      return <div className="text-app-gray600 text-body-sm">{hintLabel}</div>;
    }

    if (hintText)
      return (
        <S.CornerHintText $show={inputState.isFocused}>
          {hintText}
        </S.CornerHintText>
      );

    if (optional) {
      return <S.CornerHintText $show>{optional}</S.CornerHintText>;
    }
    return null;
  }, [
    hideIndicators,
    required,
    readOnly,
    disabled,
    hintLabel,
    hintText,
    inputState.isFocused,
  ]);

  const renderText = useMemo(() => {
    if (inputState.message || errorMessage) {
      return (
        <div className="flex gap-2 items-center mt-2 text-body-sm text-primary-red font-medium">
          <ErrorOutline tw="w-4! h-4!" />
          <p>{inputState.message || errorMessage}</p>
        </div>
      );
    }
    if (successMessage) {
      return (
        <div className="flex gap-2 items-center mt-2 text-body-sm text-app-green700 font-medium">
          <CheckCircleOutline tw="w-4! h-4!" />
          <p>{successMessage}</p>
        </div>
      );
    }
    return null;
  }, [inputState.message, errorMessage, successMessage]);

  const trailingElement = useMemo(() => {
    const renderPasswordToggle = () => {
      const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
      };
      const ButtonIcon = showPassword ? (
        <VisibilityOff style={{ fontSize: "16px", color: "black" }} />
      ) : (
        <Visibility style={{ fontSize: "16px", color: "black" }} />
      );

      return (
        <S.TrailingIconContainer onClick={handlePasswordToggle}>
          <S.TrailingIcon>{ButtonIcon}</S.TrailingIcon>
        </S.TrailingIconContainer>
      );
    };

    const renderErrorIcon = () =>
      !hideIndicators && inputState.message ? (
        <S.ErrorIcon aria-hidden="true" />
      ) : null;

    const renderCopy = () => {
      return (
        <S.TrailingIconContainer>
          <S.TrailingIcon>
            <CopyButton
              value={value?.toString()}
              noPadding
              inverted={inverted}
            />
          </S.TrailingIcon>
        </S.TrailingIconContainer>
      );
    };

    if (type === "password") {
      return renderPasswordToggle();
    }

    if (inputState.message) {
      return renderErrorIcon();
    }

    if (!trailing && allowCopy) {
      return renderCopy();
    }
    return trailing;
  }, [
    allowCopy,
    inputState.message,
    hideIndicators,
    showPassword,
    trailing,
    type,
    value,
    inverted,
  ]);

  const renderLeading = useMemo(() => {
    const renderLeadingOutline = (el) => (
      <S.LeadingOutlineContainer $disablePadding={!disableLeadingPadding}>
        {el}
      </S.LeadingOutlineContainer>
    );
    const renderLeadingInline = (el) => (
      <S.LeadingInlineContainer
        className="leadingInline"
        error={!!inputState.message}
        $disablePadding={disableLeadingPadding}
      >
        <S.LeadingInline>{el}</S.LeadingInline>
      </S.LeadingInlineContainer>
    );
    if (leadingElement) {
      return leadingInline
        ? renderLeadingInline(leadingElement)
        : renderLeadingOutline(leadingElement);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadingElement, inputState.message, leadingInline]);

  const renderTrailing = useMemo(() => {
    const renderTrailingOutline = (el) => (
      <S.TrailingOutlineContainer $disablePadding={disableTrailingPadding}>
        {el}
      </S.TrailingOutlineContainer>
    );
    const renderTrailingInline = (el) => (
      <S.TrailingInlineContainer
        error={!!inputState.message}
        $disablePadding={disableTrailingPadding}
      >
        <S.TrailingInline>{el}</S.TrailingInline>
      </S.TrailingInlineContainer>
    );

    if (trailingElement) {
      return trailingInline
        ? renderTrailingInline(trailingElement)
        : renderTrailingOutline(trailingElement);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailingElement, trailingInline, inputState.message]);

  // set focused state on input
  useEffect(() => {
    const handleFocus = () => {
      dispatchInputState({ isFocused: true, firstBlur: false });
    };
    const handleBlur = () => {
      dispatchInputState({ firstBlur: true, isFocused: false });
    };

    if (inputEl?.current) {
      inputEl.current.addEventListener("focus", handleFocus);
      inputEl.current.addEventListener("blur", handleBlur);
    }

    return () => {
      if (inputEl?.current) {
        inputEl.current.removeEventListener("focus", handleFocus);
        inputEl.current.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  return (
    <div className={className}>
      {renderLabelText && (
        <S.LabelContainer>
          {renderLabelText}
          {renderCornerHint}
        </S.LabelContainer>
      )}
      {subLabel && (
        <p tw="text-utility-md text-app-gray600 mb-2 cursor-default">
          {subLabel}
        </p>
      )}
      <S.InputContainer
        $error={!!inputState.message || Boolean(errorMessage)}
        $inverted={inverted}
        $success={Boolean(successMessage)}
        className="inputContainer"
        $width={inputWidth}
      >
        {renderLeading}
        <S.InputElement
          type={typeOverride}
          id={id || inputId}
          aria-label={label}
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          title={title}
          ref={inputEl}
          pattern={pattern}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          placeholder={placeholder}
          onChange={onChange}
          onClick={handleOnClick}
          minLength={minLength}
          maxLength={maxLength}
          onBlur={onBlur}
          onInput={() => dispatchInputState({ firstInput: true })}
          $padding={inputPadding}
          $isLeadingElement={!!leadingElement}
          $isTrailingElement={!!trailingElement}
          $leadingInline={leadingInline}
          $trailingInline={trailingInline}
          $error={inputState.message || errorMessage}
          $inverted={inverted}
          autoComplete={autocomplete}
          onWheel={(e) => {
            if (type === "number") {
              e.currentTarget.blur();
            }
          }}
          data-cy={dataCy}
        />
        {renderTrailing}
      </S.InputContainer>
      {description && (
        <S.Description $show={inputState.isFocused}>
          {description}
        </S.Description>
      )}
      {renderText}
    </div>
  );
};

Input.defaultProps = {
  min: undefined,
  max: undefined,
  step: undefined,
  name: undefined,
  label: "",
  type: "text",
  pattern: null,
  required: false,
  disabled: false,
  hideIndicators: false,
  hintText: "",
  infoHintText: "",
  title: undefined,
  onChange: undefined,
  onClick: undefined,
  placeholder: undefined,
  readOnly: false,
  allowCopy: false,
  minLength: undefined,
  maxLength: undefined,
  leading: null,
  leadingInline: false,
  trailing: null,
  trailingInline: false,
  inputPadding: {},
  autocomplete: "off",
};

export default Input;
