/* eslint-disable no-console */
import CSVBoxImporter from "@csvbox/csvboxjs";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { useCustomFieldContext } from "features/custom-fields";
import { useImportMembersMutation } from "features/members";
import { useEffect, useMemo, useState } from "react";
import useWebSockets from "hooks/useWebsockets";
import { useUserContext } from "containers/Layouts/PrivateLayout/UserContextProvider";
import { cleanAndNormalizeText } from "helpers/cleanAndNormalizeText";
import { usePlansContext } from "containers/Plans/context/Plans.context";

let importer;

const useMembersImport = () => {
  const [importMembers] = useImportMembersMutation();
  const { createToastAlert } = useToastAlertContext();
  const { customFields } = useCustomFieldContext();
  const { activeFreePlans } = usePlansContext();
  const { currentUser } = useUserContext();
  const [importSubmitted, setImportSubmitted] = useState(false);
  const [totalPendingJobs, setTotalPendingJobs] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const appWebSockets = useWebSockets();

  const [importJobData, setImportJobData] = useState({
    statusPercentage: 0,
    totalMemberCount: 0,
    totalProcessed: 0,
  });

  const user = currentUser;

  const currentAppId = useMemo(() => {
    return window.location.pathname
      .split("/")
      .find((val) => val.includes("app_"));
  }, [window.location.pathname]);

  const additionalFields = customFields.map(({ key, label }) => ({
    key,
    label,
  }));

  function calculateEstimatedTime(memberCount) {
    const processingTimePerMember = 0.15; // 15 seconds per 100 members -> 15/100 = .15
    const minimumTime = 1 * 60; // 1 minute in seconds

    const processingTime = memberCount * processingTimePerMember;
    const estimatedProcessingTime = Math.max(processingTime, minimumTime);

    const minutes = Math.ceil(estimatedProcessingTime / 60);

    return minutes;
  }

  useEffect(() => {
    if (appWebSockets) {
      // reset import job data
      setImportJobData({
        statusPercentage: 0,
        totalMemberCount: 0,
        totalProcessed: 0,
      });
      setImportSubmitted(false);
      setTotalPendingJobs(0);

      try {
        appWebSockets.then((channel) => {
          channel.listen("status_update", (data) => {
            const { statusPercentage, totalMemberCount, totalProcessed } = data;
            setImportJobData({
              statusPercentage,
              totalMemberCount,
              totalProcessed,
            });
          });
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [appWebSockets]);

  useEffect(() => {
    setEstimatedTime(
      calculateEstimatedTime(
        importJobData.totalMemberCount - importJobData.totalProcessed
      )
    );
  }, [importJobData.totalMemberCount, importJobData.totalProcessed]);

  async function callback(result, data) {
    if (importSubmitted) {
      console.warn("Import already submitted - please wait one minute.");
      return;
    }

    if (result) {
      const { rows } = data;

      const members = rows?.map(
        ({
          email,
          password,
          hashedPassword,
          stripeCustomerId,
          stripeSubscriptionId,
          id,
          loginRedirect,
          metaData,
          json,
          // eslint-disable-next-line camelcase
          _dynamic_data,
        }) => {
          const cleanedJson = cleanAndNormalizeText(json);
          const cleanedMetaData = cleanAndNormalizeText(metaData);

          const plans = _dynamic_data?.plans;

          // eslint-disable-next-line no-param-reassign
          delete _dynamic_data?.plans;

          return {
            email,
            password,
            hashedPassword,
            stripeCustomerId,
            stripeSubscriptionId,
            id,
            loginRedirect,
            ...(metaData && { metaData: JSON.parse(cleanedMetaData) }),
            ...(json && { json: JSON.parse(cleanedJson) }),
            ...(plans && {
              plans: plans.split(",").map((plan) => {
                return {
                  planId: plan,
                };
              }),
            }),
            customFields: _dynamic_data,
          };
        }
      );

      try {
        const {
          data: {
            importMembers: { totalPendingJobs: _totalPendingJobs },
          },
        } = await importMembers({
          variables: {
            input: {
              members,
            },
          },
        });

        setTotalPendingJobs(_totalPendingJobs);
        setImportSubmitted(true);

        setTimeout(() => {
          setImportSubmitted(false);
        }, 60000);

        createToastAlert({
          alertType: "success",
          message: `Importing ${rows.length} members.`,
        });
      } catch (e) {
        createToastAlert({
          alertType: "error",
          message: e.graphQLErrors[0].message,
        });
      }
    }
  }

  useEffect(() => {
    // if importer already exists, don't create a new one
    // this is needed because CSVBox is not a react component
    if (importer) return;
    importer = new CSVBoxImporter(
      process.env.CSVBOX_LICENSE_KEY,
      {},
      callback,
      { lazy: true }
    );
  }, []);

  const openImportTool = () => {
    if (importSubmitted) {
      console.warn("Import already submitted - please wait one minute.");
      createToastAlert({
        alertType: "error",
        message: "Only 1 import per minute - please wait one minute.",
      });
      return;
    }

    importer.setUser({
      user_id: user.id,
      app_id: currentAppId,
    });

    const additionalFieldsMap = additionalFields.map((field) => ({
      column_name: field.key,
      display_label: field.label,
      info_hint: "",
      matching_keywords: "",
      type: "text",
    }));

    importer.setDynamicColumns([
      // if activeFreePlans is empty, don't show the free plans field
      ...(activeFreePlans.length > 0
        ? [
            {
              column_name: "plans",
              display_label: "Free Plans",
              info_hint: "Free plans you'd like to assign to members",
              matching_keywords:
                "free plan, plan, plans, member plan, member plans",
              type: "multiselect_list",
              validators: {
                values: activeFreePlans.map((plan) => plan.value),
              },
              required: false,
            },
          ]
        : []),
      ...additionalFieldsMap,
    ]);

    importer.openModal();
  };

  const closeModal = () => {
    if (importJobData.statusPercentage === 100) {
      setImportSubmitted(false);
      setImportJobData({
        statusPercentage: 0,
        totalMemberCount: 0,
        totalProcessed: 0,
      });
    }
  };

  return {
    openImportTool,
    importInProgress: importSubmitted || importJobData.statusPercentage > 0,
    totalPendingJobs,
    estimatedTime,
    importJobData,
    closeModal,
  };
};

export default useMembersImport;
