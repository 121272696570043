import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useCreatePriceMutation } from "features";
import { useAppDataContext } from "routes/AppDataContextProvider";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import { Button, Form, Accordion, AccordionItem } from "components";
import { Background } from "components/BackgroundWithGradient/BackgroundWithGradient";
import StripeUI from "components/Stripe-UI";
import { useToastAlertContext } from "components/toastAlert";
import { DEFAULT_PRICE_INPUT } from "containers/Plans/utils";
import { omit } from "lodash";
import { PriceType } from "generatedTypes";
import { sessionStorageKeys } from "helpers/cacheIds";
import { useGetPlanQuery } from "features/plans";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import { NoPayment } from "./PlanPrice/NoPayment";
import { PriceInputType } from "../../../plans.types";
import { PriceInformation } from "./PlanPrice/PriceInformation";
import { SetupFee } from "./PlanPrice/SetupFee";
import { Trials } from "./PlanPrice/Trials";
import { Expiration } from "./PlanPrice/Expiration";
import { CreatePriceLoading } from "./loading";
import { CreatePriceModal } from "./CreatePriceModal";

export const CreatePlanPrice = () => {
  const history = useHistory();
  const { createToastAlert } = useToastAlertContext();

  const { appId, planId } = useParams<{ appId: string; planId: string }>();

  const [priceInputs, setPriceInputs] =
    useState<PriceInputType>(DEFAULT_PRICE_INPUT);
  const [showCreatePriceModal, setShowCreatePriceModal] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const { refetchPlans } = usePlansContext();

  const { appData, isLoading } = useAppDataContext();

  const isStripConnected = appData?.stripeConnection?.accountId;

  const [createPrice, { loading }] = useCreatePriceMutation();

  const { loading: loadingPlan, data } = useGetPlanQuery({
    variables: { id: planId },
  });

  const handleOnCreate = async () => {
    const modifiedInput = omit(priceInputs, "taxType");
    const input = { planId, ...modifiedInput };
    createToastAlert({ processing: true });
    try {
      await createPrice({
        variables: { input },
      });
      createToastAlert({
        alertType: "success",
        message: "Price created successfully",
      });
      refetchPlans();
      history.push(`/apps/${appId}/plan/${planId}`);
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: `${e}`,
      });
    }
  };

  if (isLoading || loadingPlan) return <CreatePriceLoading />;

  const plan = data.getPlan;

  return (
    <div tw="flex h-full overflow-hidden">
      <SettingsBar>
        <TitleSection
          title="New Price"
          backLink={`/apps/${appId}/plan/${planId}`}
          backLinkTitle="Plans"
          buttons={
            <Button
              text="Create Price"
              onClick={() => setShowCreatePriceModal(true)}
              isLoading={loading}
              isDisabled={!isFormValid}
            />
          }
          emphasize
        />
        {isStripConnected ? (
          <Form
            onValidityCheck={setIsFormValid}
            className="overflow-auto h-full"
          >
            <PriceInformation
              priceInputs={priceInputs}
              setPriceInputs={setPriceInputs}
              hasTaxValue
              disabled={false}
              name={priceInputs.name}
              amount={priceInputs.amount}
              type={priceInputs.type}
              taxType={priceInputs.taxType} // make optional
              currency={priceInputs.currency}
              intervalCount={priceInputs.intervalCount}
              intervalType={priceInputs.intervalType}
              maxTeamMembers={priceInputs.maxTeamMembers}
              teamAccountsEnabled={plan.teamAccountsEnabled}
            />
            <Accordion
              name={sessionStorageKeys.createPlanAccordion}
              persistent={false}
            >
              <AccordionItem
                label="Additional options"
                value="additionalOptions"
                hasOuterBorder={false}
                removeSectionPadding
              >
                <SetupFee
                  priceInputs={priceInputs}
                  setPriceInputs={setPriceInputs}
                  amount={priceInputs.setupFeeAmount}
                  name={priceInputs.setupFeeName}
                  disabled={false}
                />
                {priceInputs.type === PriceType.Subscription && (
                  <Trials
                    priceInputs={priceInputs}
                    setPriceInputs={setPriceInputs}
                    days={priceInputs.freeTrialDays}
                    disabled={false}
                  />
                )}
                {priceInputs.type === PriceType.Onetime && (
                  <Expiration
                    count={priceInputs.expirationCount}
                    interval={priceInputs.expirationInterval}
                    setPriceInputs={setPriceInputs}
                    priceInputs={priceInputs}
                    disabled={false}
                  />
                )}
              </AccordionItem>
            </Accordion>
          </Form>
        ) : (
          <NoPayment />
        )}
        <CreatePriceModal
          showModal={showCreatePriceModal}
          setShowModal={setShowCreatePriceModal}
          onConfirm={handleOnCreate}
          isLoading={loading}
        />
      </SettingsBar>
      <Background>
        <StripeUI
          priceInputs={priceInputs}
          setupFee={priceInputs.setupFeeAmount}
          freeTrialDays={priceInputs.freeTrialDays}
          intervalType={priceInputs.intervalType}
          intervalCount={priceInputs.intervalCount}
          planName={plan?.name}
        />
      </Background>
    </div>
  );
};
