import React, { useCallback, useMemo, useState } from "react";
import Input from "components/Input";
import { Button } from "components";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { useUpdateMemberAuthMutation } from "features";
import useTranslations from "hooks/useTranslations";

interface CreatePasswordProps {
  memberId: string;
  closeModal: () => void;
}

const CreatePassword = ({ memberId, closeModal }: CreatePasswordProps) => {
  const { translate } = useTranslations();
  const defaultValues = { newPassword: "", confirmPassword: "" };
  const [passwords, setPasswords] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createToastAlert } = useToastAlertContext();
  const [updateMemberAuthMutation] = useUpdateMemberAuthMutation();
  const handleOnChange = useCallback(
    (key: string) => (event) =>
      setPasswords({ ...passwords, ...{ [key]: event.target.value } }),
    [passwords]
  );

  const validate = useMemo(() => {
    let isValid = true;
    const validationErrors = {
      newPassword: "",
      confirmPassword: "",
    };

    if (!passwords.newPassword && !passwords.confirmPassword) {
      isValid = false;
      return setErrors(defaultValues);
    }

    if (!passwords.newPassword) {
      isValid = false;
      validationErrors.newPassword = "Please enter your password.";
    }

    if (!passwords.confirmPassword) {
      isValid = false;
      validationErrors.confirmPassword = "Please enter your confirm password.";
    }

    if (passwords.newPassword && passwords.confirmPassword) {
      if (passwords.newPassword !== passwords.confirmPassword) {
        isValid = false;
        validationErrors.newPassword = "Passwords don't match.";
      }
    }
    setErrors(validationErrors);
    return isValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwords.confirmPassword, passwords.newPassword]);

  const handleReset = async () => {
    setIsSubmitting(true);

    createToastAlert({ processing: true });

    try {
      await updateMemberAuthMutation({
        variables: {
          input: {
            memberId,
            newPassword: passwords.newPassword,
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message: translate(
          "createMemberPassword.successMessage",
          "Password was successfully changed."
        ),
      });
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: e.graphQLErrors[0].message,
      });
    } finally {
      setIsSubmitting(false);
      closeModal();
    }
  };

  return (
    <>
      <div className="p-5">
        {errors.newPassword && (
          <span className="text-sm my-3 text-red-500">
            {errors.newPassword}
          </span>
        )}
        <Input
          label={translate("createMemberPassword.newPassword", "New Password")}
          name="newPassword"
          onChange={handleOnChange("newPassword")}
          required
          type="password"
          hideIndicators
          value={passwords.newPassword}
        />
        <div className="mt-6">
          <Input
            label={translate(
              "createMemberPassword.confirmNewPassword",
              "Confirm new Password"
            )}
            name="confirmPassword"
            type="password"
            value={passwords.confirmPassword}
            required
            hideIndicators
            onChange={handleOnChange("confirmPassword")}
          />
        </div>
        {errors.confirmPassword && (
          <span className="text-sm my-3 text-red-500">
            {errors.confirmPassword}
          </span>
        )}
      </div>
      <hr />
      <div className="flex justify-end p-5">
        <Button
          text={translate("createMemberPassword.cancel", "Cancel")}
          onClick={closeModal}
          buttonStyle="skeleton"
          tw="mr-2"
        />
        <Button
          text={translate("createMemberPassword.reset", "Reset")}
          isDisabled={!validate}
          isLoading={isSubmitting}
          type="submit"
          onClick={handleReset}
        />
      </div>
    </>
  );
};

export default CreatePassword;
