import { format, isToday } from "date-fns";
import { MemberMetricsInterval } from "generatedTypes";

export const currentData = {
  "2022-01": 202,
  "2022-02": 8,
  "2022-03": 25,
  "2022-04": 45,
  "2022-05": 200,
  "2022-06": 460,
  "2022-07": 215,
  "2022-08": 217,
  "2022-09": 324,
  "2022-10": 259,
  "2022-11": 148,
  "2022-12": 80,
  "2023-01": 13,
};

export const previousData = {
  "2022-01": 90,
  "2022-02": 42,
  "2022-03": 16,
  "2022-04": 45,
  "2022-05": 300,
  "2022-06": 512,
  "2022-07": 215,
  "2022-08": 12,
  "2022-09": 84,
  "2022-10": 623,
  "2022-11": 794,
  "2022-12": 891,
  "2023-01": 909,
};

export const convertDate = (dateString, interval) => {
  const date = new Date(dateString * 1000);

  const dateFormat = interval === MemberMetricsInterval.Month ? "MMM yyyy" : "dd MMM yyyy";

  return isToday(date) ? "Today" : format(date, dateFormat);
};

export const percentage = (a, b) => ((a - b) / b) * 100;
