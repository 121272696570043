import React, { ReactNode, useMemo, useState } from "react";
import styled from "styled-components";
import { ExpandMore, ExpandLess, ChevronRight } from "@mui/icons-material";
import { Card } from "components/Card";

const Collapsible = styled.div<{ $isOpen: boolean }>`
  height: ${(props) => (props.$isOpen ? "auto" : "0")};
  overflow: hidden;
`;

interface NodeProps {
  title: string;
  description?: string;
  onClick?: () => void;
}

interface BranchProps extends NodeProps {
  children?: ReactNode;
}

const Node = ({ title, description, onClick }: NodeProps) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <div
      className="flex flex-col p-3 pl-10 cursor-pointer hover:bg-app-gray50"
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e)}
    >
      <span className="text-base">{title}</span>
      {description && (
        <span className="text-app-gray500 mt-1">{description}</span>
      )}
    </div>
  );
};

const Branch = ({ title, children, description, onClick }: BranchProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      return children ? handleToggle() : onClick();
    }
    return null;
  };

  const icon = useMemo(() => {
    if (children) {
      if (isOpen) {
        return <ExpandLess />;
      }
      return <ExpandMore />;
    }
    return <ChevronRight />;
  }, [children, isOpen]);

  return (
    <Card className="cursor-pointer">
      <div
        className="flex items-center justify-between py-5 px-5"
        onClick={children ? () => handleToggle() : onClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => handleKeyDown(e)}
      >
        <div className="flex flex-col">
          <span className="text-base">{title}</span>
          <span className="text-app-gray500 mt-1">{description}</span>
        </div>
        {icon}
      </div>
      {children
        ? isOpen && <Collapsible $isOpen={isOpen}>{children}</Collapsible>
        : null}
    </Card>
  );
};

export const Tree = ({ children }) => {
  return <div className="flex flex-col gap-4">{children}</div>;
};

Tree.Node = Node;
Tree.Branch = Branch;
