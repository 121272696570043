import { isEqual } from "lodash";

/**
 * Compares two objects to know if they are equal
 * @param obj1 object whose key/value pairs are to be extracted
 * @param obj2 reference keys to be used for extraction
 * @returns object with keys specified
 */
export const isObjectEqual = (obj1, obj2) => {
  // check if both object are valid
  if (!obj1 || !obj2) {
    return false;
  }
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length === 0 && obj2Keys.length === 0) {
    return true;
  }
  return isEqual(obj1, obj2);
};
