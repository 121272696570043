import { Card } from "components";
import { ImageUploadSkeleton } from "components/ImageUpload/ImageUpload";
import InputLoading from "components/Input/Input.loading";
import TextComboLoading from "components/TextCombo/TextCombo.loading";
import React from "react";
import Skeleton from "react-loading-skeleton";

const AppInformationSkeleton = () => (
  <Card className="p-4 flex flex-col">
    <Skeleton width={198} height={28} className="mb-4" />
    <div className="flex flex-col gap-7">
      <InputLoading className="lg:w-custom-560 w-full" />
      <TextComboLoading hasLabel />
      <ImageUploadSkeleton description />
    </div>
  </Card>
);

const BusinessInformationSkeleton = () => (
  <Card className="p-4 flex flex-col">
    <Skeleton width={198} height={28} className="mb-4" />
    <div className="lg:w-custom-560 w-full flex flex-col gap-7">
      <InputLoading />
      <InputLoading />
      <InputLoading />
    </div>
  </Card>
);

const AppDomainsSkeleton = () => (
  <Card className="p-4 flex flex-col">
    <Skeleton width={198} height={28} className="mb-4" />
    <Skeleton width={119} height={24.6} />
    <div className="w-3/5">
      <Skeleton height={48} className="my-2" />
      <Skeleton height={32} width={115} />
    </div>
    <div className="flex flex-col justify-center pt-4">
      <Skeleton height={32} width={120} className="mb-3" />
      <Skeleton height={172} width={319} className="mb-3" />
      <Skeleton height={28} width={200} />
    </div>
  </Card>
);

const DNSSettingsSkeleton = () => (
  <Card className="p-4 flex flex-col">
    <Skeleton width={198} height={28} className="mb-4" />
    <Skeleton width={119} height={24.6} />
    <div className="w-3/5">
      <Skeleton height={72} className="my-2" />
      <Skeleton height={32} width={115} />
    </div>
    <div className="flex flex-col gap-1">
      <Skeleton height={60} />
      <Skeleton height={60} />
      <Skeleton height={60} />
      <Skeleton height={60} />
    </div>
  </Card>
);

const AppSettingsSkeleton = () => (
  <div className="flex flex-col gap-4">
    <AppInformationSkeleton />
    <AppDomainsSkeleton />
    <DNSSettingsSkeleton />
    <BusinessInformationSkeleton />
  </div>
);

export default AppSettingsSkeleton;
