import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { App, Exact } from "generatedTypes";
import React, { useMemo, useState } from "react";
import { CurrentAppQuery, useCurrentAppQuery } from "features";

interface DefaultContext {
  isSubscribed: boolean;
  refetch: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<CurrentAppQuery>>;
  appData: App;
  isLoading: boolean;
  canAccessLiveFeatures: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
  error: ApolloError;
}

const defaultContext: DefaultContext = {
  isSubscribed: false,
  refetch: undefined,
  appData: undefined,
  isLoading: undefined,
  canAccessLiveFeatures: undefined,
  setHasUnsavedChanges: undefined,
  hasUnsavedChanges: undefined,
  error: undefined,
};

const AppDataContext = React.createContext(defaultContext);

const useAppDataContext = () => {
  return React.useContext(AppDataContext);
};

const AppDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { data, loading, refetch, error } = useCurrentAppQuery();

  if (data?.currentApp) {
    try {
      window.revgems("account", data.currentApp.id, {
        name: data.currentApp.name,
        stripe_customer_id: data.currentApp.stripeCustomerId
      })
    } catch (err) {
      console.log(err)
    }
  }

  const isSubscribed = Boolean(data?.currentApp?.stripeSubscriptionId);

  const canAccessLiveFeatures = useMemo(
    () => Boolean(isSubscribed),
    [isSubscribed]
  );

  return (
    <AppDataContext.Provider
      value={{
        appData: data?.currentApp as App,
        isLoading: loading,
        refetch,
        isSubscribed,
        canAccessLiveFeatures,
        setHasUnsavedChanges,
        hasUnsavedChanges,
        error,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export { useAppDataContext, AppDataContextProvider };
