import React from "react";
import Skeleton from "react-loading-skeleton";
import { AttributeInstallationBox, Link } from "components";

const AuthIntegrationLoader = () => (
  <>
    <h3 tw="text-h3 font-bold mb-7">Connect Auth Providers</h3>
    <p className="text-body-sm text-app-gray600 whitespace-pre-line mb-4">
      Configure auth providers to add them to your sign up and login forms.
      <br />
      You can{" "}
      <Link to="application/#dns-settings" underline>
        add a custom domain
      </Link>{" "}
      to use your own your domain for social auth (e.g. continue to
      mydomain.com).
    </p>
    <div className="mt-7 flex flex-col gap-2">
      <Skeleton height={68} />
      <Skeleton height={68} />
      <Skeleton height={68} />
      <Skeleton height={68} />
      <Skeleton height={68} />
      <Skeleton height={68} />
      <AttributeInstallationBox
        isLinkOnly
        docsLink="https://docs.memberstack.com/hc/en-us/sections/9188390078491-Social-Authentication-SSO"
      />
    </div>
  </>
);

export default AuthIntegrationLoader;
