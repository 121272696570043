/* eslint-disable no-console */
import { useEffect } from "react";
import PieSocket from "piesocket-js";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";
import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";

// using singleton pattern to avoid multiple instances of pieSocket
let pieSocketInstance = null;
let pieSocketSubscribe = null;

const useWebSockets = () => {
  const { appData } = useAppDataContext();

  const pieSocketAppId = () => {
    const currentAppId = window.location.pathname
      .split("/")
      .find((val) => val.includes("app_"));
    return `private-${currentAppId}`;
  };

  useEffect(() => {
    const socketAuthToken = appData?.socketAuthToken;
    if (socketAuthToken) {
      if (!pieSocketInstance) {
        logInDevelopmentEnvironment(
          `✅ Initializing PieSocket With App ID: ${pieSocketAppId()}`
        );
        pieSocketInstance = new PieSocket({
          clusterId: process.env.PIE_SOCKET_CLUSTER_ID,
          apiKey: process.env.PIE_SOCKET_API_KEY,
          notifySelf: false,
          jwt: socketAuthToken,
        });
      }

      if (!pieSocketSubscribe)
        pieSocketSubscribe = pieSocketInstance.subscribe(pieSocketAppId());
    }
    return () => {
      if (pieSocketInstance) {
        const connections = pieSocketInstance.getConnections();

        // loop through connections keys and unsubscribe from each
        Object.keys(connections).forEach((key) => {
          logInDevelopmentEnvironment(
            `🚫 Unsubscribing from PieSocket With Key: ${key}`
          );

          pieSocketInstance.unsubscribe(key);
        });

        pieSocketSubscribe = null;
        pieSocketInstance = null;
      }
    };
  }, [appData?.socketAuthToken]);

  return pieSocketSubscribe;
};

export default useWebSockets;
