import { useToastAlertContext } from "components/toastAlert";
import {
  useCreateRestrictedUrlMutation,
  useDeleteRestrictedUrlMutation,
  useUpdateRestrictedUrlMutation,
  useUpdateRestrictedUrlGroupMutation,
  useDetachPlansFromRestrictedUrlGroupMutation,
  useLinkPlansToRestrictedUrlGroupMutation,
  useCreateCustomContentMutation,
  useDeleteCustomContentMutation,
  useUpdateCustomContentMutation,
  useCreateRestrictedUrlGroupMutation,
} from "features/content-groups";

export const accessOptions = [
  {
    value: "memberSpecific",
    label: "Members on specific plans",
  },
  {
    value: "allMembers",
    label: "All Members",
  },
];

// check for deleted customContent from query and state
const getDeletedIds = (queryData, stateData) =>
  queryData
    ?.filter(
      (dbData) =>
        !stateData?.find((contents) => contents.value === dbData.value)
    )
    ?.map((dbData) => dbData.value);

// check for new customContent that was just added
const getAddedData = (queryData, stateData) =>
  stateData
    ?.filter(
      (feData) =>
        queryData?.find((dbData) => dbData.value === feData.value) === undefined
    )
    .map((data) => data.value);

export const planChecker = (initialPlans, statePlans) => {
  const deletedPlans = getDeletedIds(initialPlans, statePlans);
  const addedPlans = getAddedData(initialPlans, statePlans);
  return { deletedPlans, addedPlans };
};

// Check for modified plans
export const getModifiedUrls = (restrictedUrls, urlInputs) =>
  restrictedUrls
    ?.map((p) => {
      const { id: restrictedUrlId, url, filter } = p;
      const working = urlInputs.find((_url) => _url.id === restrictedUrlId);
      if (working && (url !== working.url || filter !== working.filter)) {
        return working;
      }
      return null;
    })
    .filter((i) => i !== null);

// compare customContentsInt with customContentsExt, check for deleted custom content
export const getDeletedCustomContentsIds = (
  customContentsInt,
  customContentsExt
) =>
  customContentsInt
    ?.filter(
      (content) =>
        !customContentsExt.some((c) => c.id === content.id) &&
        content.id !== undefined
    )
    .map((content) => content.id);

// compare customContentsInt with customContentsExt, check for updated custom content based on name, content and type
export const getUpdatedCustomContents = (
  customContentsInt,
  customContentsExt
) =>
  customContentsInt
    .filter(
      (content) =>
        customContentsExt.some(
          (c) =>
            c.id === content.id &&
            (c.name !== content.name ||
              c.content !== content.content ||
              c.type !== content.type)
        ) && content.id !== undefined
    )
    .map((content) => ({
      customContentId: content.id,
      name: content.name,
      content: content.content,
      type: content.type,
    }));

// compare customContentsInt with customContentsExt, check for created custom content
export const getCreatedCustomContent = (customContentsInt, customContentsExt) =>
  customContentsInt
    .filter(
      (content) =>
        (!customContentsExt.some((c) => c.id === content.id) &&
          content.id === undefined) ||
        typeof content.id !== "string"
    )
    .map((content) => ({
      name: content.name,
      content: content.content,
      type: content.type,
    }));

export const useContentGroupCalls = () => {
  const [createRestrictedUrl, { loading: creatingUrl }] =
    useCreateRestrictedUrlMutation();
  const [deleteRestrictedUrl, { loading: deletingUrl }] =
    useDeleteRestrictedUrlMutation();
  const [updateRestrictedUrl, { loading: updatingUrl }] =
    useUpdateRestrictedUrlMutation();
  const [updateRestrictedUrlGroup, { loading: updatingUrlGroups }] =
    useUpdateRestrictedUrlGroupMutation();

  const [detachPlansFromRestrictedUrlGroup, { loading: detachingPlans }] =
    useDetachPlansFromRestrictedUrlGroupMutation();
  const [linkPlansToRestrictedUrlGroup, { loading: linkingPlans }] =
    useLinkPlansToRestrictedUrlGroupMutation();

  const [createCustomContent, { loading: creatingCustomContent }] =
    useCreateCustomContentMutation();
  const [deleteCustomContent, { loading: deletingCustomContent }] =
    useDeleteCustomContentMutation();
  const [updateCustomContent, { loading: updatingCustomContent }] =
    useUpdateCustomContentMutation();

  const [createRestrictedUrlGroup, { loading: creatingUrlGroup }] =
    useCreateRestrictedUrlGroupMutation();

  const loadingMutations =
    creatingUrl ||
    deletingUrl ||
    updatingUrl ||
    updatingUrlGroups ||
    linkingPlans ||
    detachingPlans ||
    creatingCustomContent ||
    deletingCustomContent ||
    updatingCustomContent ||
    creatingUrlGroup;

  return {
    loadingMutations,
    createRestrictedUrl,
    deleteRestrictedUrl,
    updateRestrictedUrl,
    updateRestrictedUrlGroup,
    linkPlansToRestrictedUrlGroup,
    detachPlansFromRestrictedUrlGroup,
    createCustomContent,
    deleteCustomContent,
    updateCustomContent,
    createRestrictedUrlGroup,
  };
};

export const useHandleSubmit = () => {
  const { createToastAlert } = useToastAlertContext();

  const handleSubmit = async ({ action, input, successMessage, refetch }) => {
    try {
      await action({
        variables: {
          input,
        },
      });
      createToastAlert({
        alertType: "success",
        message: successMessage,
      });
      refetch();
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: `${err}`,
      });
    }
  };

  return handleSubmit;
};
