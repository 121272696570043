import React from "react";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import { Formik } from "formik";
import { domainSchema } from "helpers/validationSchemas";
import useSubmit from "hooks/useSubmit";
import { Modal } from "components/Modal";
import Text from "components/Text";
import Input from "components/Input";
import { useUpdatePlanMutation } from "features/plans";
import { usePlansContext } from "containers/Plans/context/Plans.context";

const EditSignupRule = ({ showModal, setShowModal, domains, targetDomain }) => {
  const { planId } = useParams<{ planId: string }>();
  const { refetchPlans } = usePlansContext();
  const [updatePlan] = useUpdatePlanMutation();
  const { submit, isSubmitting } = useSubmit({
    action: updatePlan,
    refetch: refetchPlans,
    success: "Domain created successfully.",
    onSuccess: () => setShowModal(false),
  });

  const handleEdit = async (values) => {
    await submit({
      planId,
      allowedDomains: update(domains, {
        [domains.indexOf(targetDomain)]: { $set: values?.domain },
      }),
    });
  };

  if (!targetDomain) {
    return null;
  }

  return (
    <Formik
      initialValues={{ domain: targetDomain }}
      validationSchema={domainSchema}
      onSubmit={async (values, { resetForm }) => {
        await handleEdit(values);
        resetForm();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
      }) => (
        <Modal
          setShowModal={setShowModal}
          showModal={showModal}
          title="Edit Signup rule"
          description="You can choose if signup rules should be used to only allow domains."
          showDivider
          actionButtons={{
            cancel: {
              label: "Cancel",
            },
            confirm: {
              label: "Save",
              onConfirm: handleSubmit,
              isLoading: isSubmitting,
              isDisabled: !isValid,
            },
          }}
        >
          <form onSubmit={handleSubmit}>
            <Input
              label="Domain"
              name="domain"
              onChange={handleChange}
              type="text"
              value={values.domain}
              className="mb-4"
              onBlur={handleBlur}
              required
              errorMessage={
                errors.domain && touched.domain && (errors.domain as string)
              }
              leading={<Text color="black">username@</Text>}
            />
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default EditSignupRule;
