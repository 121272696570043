import { gql } from "@apollo/client";

export const CREATE_PLAN = gql`
  mutation CreatePlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      id
      name
      icon
      description
      status
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation UpdatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      id
      name
      icon
      description
      status
      allowedDomains
    }
  }
`;

export const DELETE_PLAN = gql`
  mutation DeletePlan($input: DeletePlanInput!) {
    deletePlan(input: $input) {
      id
    }
  }
`;

export const ORDER_PLANS = gql`
  mutation OrderPlans($input: OrderPlansInput!) {
    orderPlans(input: $input) {
      id
    }
  }
`;

export const ADD_FREE_PLAN = gql`
  mutation addFreePlan($input: AddFreePlanInput!) {
    addFreePlan(input: $input) {
      id
    }
  }
`;

export const REMOVE_FREE_PLAN = gql`
  mutation removeFreePlan($input: RemoveFreePlanInput!) {
    removeFreePlan(input: $input) {
      id
    }
  }
`;

export const REMOVE_ONE_TIME_PLAN = gql`
  mutation removeOneTimePlan($input: RemoveOneTimePlanInput!) {
    removeOneTimePlan(input: $input) {
      id
    }
  }
`;

export const UPDATE_PLAN_LOGIC = gql`
  mutation updatePlanLogic($input: UpdatePlanLogicInput!) {
    updatePlanLogic(input: $input) {
      addedLogic {
        add
      }
      removedLogic {
        add
      }
    }
  }
`;
