// create a function that formats the entries from the backend into the format that the frontend expects
export const formatEntriesFromBackend = (entries) => {
  return entries.map(({ name, content, type, id, key }) => ({
    id,
    key,
    name,
    content,
    type: { label: type, value: type },
  }));
};

// create a function that formats the entries into the format that the backend expects
export const formatEntries = (entries, isEditMode) => {
  return entries.map(({ name, content, type, id }) => ({
    name,
    content,
    type: type.value,
    // if we are in edit mode, we need to pass the id of the entry
    ...(isEditMode && { id }),
  }));
};

// format entry object to match the format of the backend
export const formatEntry = (entry, actionType) => {
  return {
    name: entry.name,
    content: entry.content,
    type: entry.type.value,
    ...(actionType === "update" && { customContentId: entry.id }),
  };
};

export const generateCustomContentId = (name) => {
  // Replace non-alphanumeric characters with a dash
  const updatedStr = name.replace(/[^a-zA-Z0-9]+/g, "-");
  // Remove any leading or trailing dashes
  return updatedStr.replace(/^-+|-+$/g, "").toLowerCase();
};
