import React from "react";
import Input from "components/Input";
import { Modal, ModalTypes } from "components/Modal";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { useGenerateApiKeysMutation } from "features";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";

const ResetSecretKeyModal = ({ showModal, setShowModal }: ModalTypes) => {
  const { refetch } = useAppDataContext();

  const [confirmationInput, setConfirmationInput] = React.useState("");

  const [generateApiKeys, { loading }] = useGenerateApiKeysMutation();

  const { submit: resetSecretKey } = useOnSubmit({
    action: generateApiKeys,
    fields: { disableConcurrentLogins: true },
    cleanup: () => {
      setConfirmationInput("");
      setShowModal(false);
    },
    errorMsg: "Unable to reset secret key.",
    successMsg: "Secret key reset successfully.",
    refetch,
  });

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Reset Secret Key"
      description="This action cannot be undone. You are resetting your Memberstack API Key."
      showDivider
      width="441px"
      actionButtons={{
        confirm: {
          label: "Confirm & Reset",
          isDisabled: confirmationInput !== "RESET",
          onConfirm: resetSecretKey,
          isLoading: loading,
        },
        cancel: { label: "Cancel" },
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          resetSecretKey();
        }}
      >
        <Input
          hideIndicators
          label="To confirm please type RESET below:"
          placeholder="RESET"
          onChange={(e) => setConfirmationInput(e.target.value)}
          value={confirmationInput}
        />
        <button type="button" className="sr-only">
          Submit
        </button>
      </form>
    </Modal>
  );
};

export default ResetSecretKeyModal;
