import { gql } from "@apollo/client";

export const CREATE_SSO_CLIENT = gql`
  mutation CreateSSOClient($input: CreateSSOClientInput!) {
    createSSOClient(input: $input) {
      id
      providerType
      provider
    }
  }
`;

export const UPDATE_SSO_CLIENT = gql`
  mutation UpdateSSOClient($input: UpdateSSOClientInput!) {
    updateSSOClient(input: $input) {
      id
      providerType
      provider
    }
  }
`;

export const UPDATE_PASSWORDLESS_SETTINGS = gql`
  mutation UpdatePasswordlessSettings(
    $input: UpdatePasswordlessSettingsInput!
  ) {
    updatePasswordlessSettings(input: $input) {
      passwordlessEnabled
      defaultToPasswordless
    }
  }
`;

export const GET_SSO_CLIENTS = gql`
  query GetSSOClients {
    getSSOClients {
      id
      providerType
      icon
      name
      discoveryUrl
      provider
      enabled
      clientId
      clientSecret
      redirectUri
      scopes
      issuer
      authorizationUrl
      tokenUrl
      userInfoUrl
      order
    }
  }
`;

export const GET_DEFAULT_AUTH_PROVIDERS = gql`
  query GetDefaultAuthProviders {
    defaultAuthProviders {
      id
      icon
      name
      discoveryUrl
      provider
      enabled
      clientId
      clientSecret
      redirectUri
      providerType
    }
  }
`;

export const GET_SSO_APPS = gql`
  query GetSSOApps {
    getSSOApps {
      id
      name
      clientId
      clientSecret
      redirectUris
    }
  }
`;

export const CREATE_SSO_APP = gql`
  mutation CreateSSOApp($input: CreateSSOAppInput!) {
    createSSOApp(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_SSO_APP = gql`
  mutation DeleteSSOApp($input: DeleteSSOAppInput!) {
    deleteSSOApp(input: $input)
  }
`;
