import { gql } from "@apollo/client";

export const CREATE_PLAN_PAYMENT_OPTION = gql`
  mutation createPrice($input: CreatePriceInput!) {
    createPrice(input: $input) {
      id
      amount
      interval {
        type
        count
      }
      name
      type
      description
      active
      currency
    }
  }
`;

export const UPDATE_PLAN_PAYMENT_OPTION = gql`
  mutation updatePrice($input: UpdatePriceInput!) {
    updatePrice(input: $input) {
      id
      name
      description
    }
  }
`;

export const DELETE_PLAN_PAYMENT_OPTION = gql`
  mutation deletePrice($input: DeletePriceInput!) {
    deletePrice(input: $input) {
      id
      active
    }
  }
`;

export const REACTIVATE_PRICE = gql`
  mutation reactivatePrice($input: ReactivatePriceInput!) {
    reactivatePrice(input: $input) {
      id
    }
  }
`;

export const DEACTIVATE_PRICE = gql`
  mutation deactivatePrice($input: DeactivatePriceInput!) {
    deactivatePrice(input: $input) {
      id
    }
  }
`;
