import React from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { FilterType } from "generatedTypes";
import {
  useGetContentGroupsQuery,
  GetContentGroupsQuery,
} from "../queries.generated";

const filterTypes = {
  [FilterType.Starts]: "Starting with",
  [FilterType.Equals]: "Equal to",
};

interface DefaultContext {
  error: ApolloError;
  loadingContentGroups: boolean;
  contentGroups: GetContentGroupsQuery["getContentGroups"];
  refetchContentGroups: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  filterTypes: typeof filterTypes;
}

const defaultContext: DefaultContext = {
  error: undefined,
  loadingContentGroups: false,
  refetchContentGroups: undefined,
  contentGroups: undefined,
  filterTypes,
};

const ContentGroups = React.createContext(defaultContext);

const useContentGroupsContext = () => {
  return React.useContext(ContentGroups);
};

const ContentGroupsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loading, refetch, error, data } = useGetContentGroupsQuery();

  return (
    <ContentGroups.Provider
      value={{
        error,
        refetchContentGroups: refetch,
        loadingContentGroups: loading,
        contentGroups: data?.getContentGroups,
        filterTypes,
      }}
    >
      {children}
    </ContentGroups.Provider>
  );
};

export { useContentGroupsContext, ContentGroupsContextProvider };
