import React, { useState, useMemo, useCallback } from "react";
import Input from "components/Input";
import { Form } from "components";
import Select from "components/Select";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { Modal, ModalTypes } from "components/Modal";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import { useGetPermissionsQuery } from "features/permissions/queries.generated";
import {
  useCreatePermissionMutation,
  useLinkPermissionsToPlanMutation,
} from "../../mutations.generated";

interface Props extends ModalTypes {}

const CreatePermissionModal = ({ showModal, setShowModal }: Props) => {
  const [name, setName] = useState("");
  const [addedPlans, setAddedPlans] = useState([]);
  const [description, setDescription] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { plans } = usePlansContext();
  const { createToastAlert } = useToastAlertContext();
  const { refetch: refetchPermissions } = useGetPermissionsQuery();

  const [createPermission] = useCreatePermissionMutation();
  const [linkPlan] = useLinkPermissionsToPlanMutation();

  const [modalError, setModalError] = useState(null);

  const planOptions = useMemo(
    () =>
      plans?.map((plan) => ({
        label: plan?.name,
        value: plan?.id,
      })),
    [plans]
  );

  const handleOnSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsSubmitting(true);

      try {
        const response = await createPermission({
          variables: {
            input: {
              name,
              description,
            },
          },
        });
        const newPermission = response?.data?.createPermission?.id;

        await Promise.all(
          addedPlans.map((plan) =>
            linkPlan({
              variables: {
                input: {
                  planId: plan?.value,
                  permissionIds: [newPermission],
                },
              },
            })
          )
        );

        await refetchPermissions();
        setShowModal(false);
        setIsSubmitting(false);
        setAddedPlans([]);
        setName("");
        setDescription("");
        createToastAlert({
          alertType: "success",
          message: "Permission was successfully created.",
        });
      } catch (e) {
        setModalError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addedPlans, description, name]
  );

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Add a permission"
      showDivider
      width="441px"
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleOnSubmit,
          isDisabled: !isFormValid,
          isLoading: isSubmitting,
          dataCy: "add-permission-modal-save-button",
        },
        cancel: { label: "Cancel" },
      }}
      nonScrollable
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form
        onSubmit={handleOnSubmit}
        onValidityCheck={setIsFormValid}
        className="gap-6"
      >
        <Input
          label="Name"
          placeholder="e.g. can:edit"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          dataCy="add-permission-modal-name-input"
        />
        <Input
          label="Description"
          placeholder="Enter a description"
          infoHintText="Not shown to users"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Select
          label="Assign to existing plans"
          placeholder="Select a plan to add"
          options={planOptions}
          value={addedPlans}
          onChange={(o) => setAddedPlans(o)}
          isMulti
        />

        <button className="sr-only" type="submit">
          Submit
        </button>
      </Form>
    </Modal>
  );
};

export default CreatePermissionModal;
