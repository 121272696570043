import React from "react";
import { Modal } from "components/Modal";
import Text from "components/Text";
import { Link } from "components";

export const AddTaxModal = ({
  showModal,
  setShowModal,
  onConfirm,
  isLoading,
}) => (
  <Modal
    setShowModal={setShowModal}
    showModal={showModal}
    width="441px"
    title="Add taxes"
    showDivider
    actionButtons={{
      cancel: {
        label: "Cancel",
      },
      confirm: {
        label: "Add taxes",
        onConfirm: () => onConfirm(),
        isLoading,
      },
    }}
  >
    <Text noMargin>
      Once you add taxes, you cannot remove or edit the amount.
    </Text>
    <ol className="text-base my-4 list-decimal ml-5">
      <li>
        In order to enable taxes fully, you need to set up{" "}
        <Link
          to="https://stripe.com/docs/invoicing/taxes#enable"
          isExternal
          underline
          target="_blank"
          tw="!text-base"
        >
          Stripe Tax settings
        </Link>{" "}
        on your dashboard.
      </li>
      <li>
        Due to Stripe restrictions, Apple Pay and Google Pay aren’t available to
        customers when you enable Stripe Tax in Checkout.{" "}
        <Link
          to="https://stripe.com/docs/payments/checkout/taxes?tax-calculation=stripe-tax"
          isExternal
          underline
          target="_blank"
          tw="!text-base"
        >
          Learn more
        </Link>
      </li>
    </ol>
    <Text className="mb-2" noMargin>
      Are you sure you’d like to proceed?{" "}
    </Text>
  </Modal>
);
