import React from "react";
import { Card, Link, Switch } from "components";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import styled from "styled-components";

const CheckoutCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PreviewContainer = styled.div`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: rgba(235, 235, 235, 1);

  & > svg {
    width: 100%;
    height: 100%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

interface Props {
  id: string;
  docs?: string;
  isChecked: boolean;
  label: string;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
  onChange: (isChecked: boolean) => void;
  description: string;
  PreviewComponent?: React.ReactNode;
  className?: string;
}

export const StripeSwitch = ({
  id,
  docs,
  label,
  isChecked,
  onClick,
  onChange,
  description,
  PreviewComponent,
  className,
}: Props) => {
  return (
    <CheckoutCard className={className} hideShadow={false}>
      <div className="px-6 py-4">
        <Switch
          isChecked={isChecked}
          onChange={onChange}
          id={id}
          label={label}
          className="mb-2"
        />
        <p tw="text-app-gray500 text-body-sm w-[610px]">
          {description}{" "}
          {docs && (
            <Link to={docs} target="_blank" isExternal showAsLink>
              Learn more
            </Link>
          )}
        </p>
      </div>
      {PreviewComponent && (
        <PreviewContainer>
          <SuspenseWrapper>{PreviewComponent}</SuspenseWrapper>
        </PreviewContainer>
      )}
    </CheckoutCard>
  );
};
