import styled, { css } from "styled-components";

export const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #f5f8fa;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
`;

export const StyledButton = styled.button<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 15px;
    border-radius: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    background-color: ${isActive ? "#070E27" : theme.colors.white};
    color: ${isActive ? theme.colors.white : "#070E27"};
    height: 44px;

    &:last-child {
      margin-right: 0px;
    }
  `}
`;
