import React, { useMemo } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { Link, Button } from "components";
import { SectionCard } from "components/Card/Card";
import Spacer from "components/Spacer";
import Tag from "components/Tag";
import Text from "components/Text";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { useEnvContext } from "containers/App/contexts/Environment";
import Onboarding from "components/Onboarding";
import { Add, Edit } from "@mui/icons-material";
import { formatDate } from "helpers/formatDate";
import { useDevtoolsContext } from "../context";

const WebhooksList = () => {
  const { isSandboxEnv } = useEnvContext();
  const url = useResolvedPath("").pathname;
  const navigate = useNavigate();
  const { webhooks } = useDevtoolsContext();

  const renderWebhooks = useMemo(() => {
    if (!webhooks?.length) {
      return (
        <div className="py-4">
          <Onboarding
            headerText="Add a Webhook to get started here"
            videoSrc="https://www.loom.com/embed/df73b8f9cf1b444cb23c6e6fa87ff928"
            videoTitle="Adding a webhook"
            videoWidth={319}
            videoHeight={172}
            docsLink="https://help.memberstack.com/hc/en-us/articles/7253256843803-Dev-Tools-Page-Overview"
            docsTitle="View Help Center article"
          />
        </div>
      );
    }

    return (
      <ul tw="list-none p-0 m-0">
        {webhooks?.map((webhook, idx) => {
          const { enabled, id, apiVersion, name } = webhook;
          return (
            <div
              className={mergeClassNames(
                "flex justify-between items-center",
                idx < webhooks?.length - 1 && "mb-4"
              )}
              key={id}
            >
              <Text>{name || id}</Text>
              <div className="flex items-center min-w-0">
                <Tag
                  variant={enabled ? "primary" : "secondary"}
                  text={enabled ? "Enabled" : "Disabled"}
                />
                <Spacer isVertical={false} spacing="large" />
                <Text className="mr-4">
                  {formatDate(apiVersion, "LLL dd, yyyy")}
                </Text>
                <Link to={`webhooks/${id}`} type="button">
                  <Button
                    text="Edit"
                    buttonStyle="skeleton"
                    rightIcon={<Edit />}
                  />
                </Link>
              </div>
            </div>
          );
        })}
      </ul>
    );
  }, [webhooks, url]);

  if (!webhooks || webhooks?.length === 0) return null;

  return (
    <SectionCard
      title={
        isSandboxEnv
          ? "Test Mode Webhooks (Deprecated)"
          : "Webhooks (Deprecated)"
      }
      buttonValues={{
        leftIcon: <Add />,
        text: "Add Endpoint",
        onClick: () => navigate(`${url}/webhooks/create`),
      }}
      maxHeight="38.4%"
    >
      {renderWebhooks}
    </SectionCard>
  );
};

export default WebhooksList;
