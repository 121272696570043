import React from "react";
import { Search, Close } from "@mui/icons-material";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { CheckboxProps } from "../Checkbox/Checkbox.types";
import Input from "../Input";
import { InputContainer } from "./styles";
import Filter from "./Filter";
import { SearchBy } from "./SearchBy/SearchBy";

interface FilterOption {
  label: string;
  value: string;
  items?: CheckboxProps[];
  onFilter: (items: string[]) => void;
}

interface SearchByOption {
  text: string;
  onClick: (event?: React.MouseEventHandler<HTMLDivElement>) => void;
}

interface Props {
  placeholder?: string;
  value?: string;
  className?: string;
  onChange: (event: string) => void;
  hasExternalFilter?: boolean;
  filterOptions?: FilterOption[];
  searchBy?: SearchByOption[];
}

const SearchBar = ({
  value,
  placeholder,
  onChange,
  className,
  filterOptions = [],
  searchBy = [],
}: Props) => {
  const hasFilter = filterOptions.length > 0;
  const hasSearchBy = searchBy.length > 0;

  return (
    <div
      tw="shadow-sm rounded-lg"
      className={mergeClassNames(hasFilter && "flex", className)}
    >
      {hasSearchBy && <SearchBy options={searchBy} />}
      <InputContainer $hasFilter={hasFilter} $hasSearchBy={hasSearchBy}>
        <Input
          label="Search"
          hideLabel
          placeholder={placeholder || "Search"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          hideIndicators
          leading={!hasSearchBy && <Search tw="!h-5 !w-5 !text-black" />}
          leadingInline
          disabled={false}
          {...(value
            ? {
                trailing: (
                  <Close
                    tw="!h-4 !w-4 cursor-pointer"
                    onClick={() => onChange("")}
                  />
                ),
                trailingInline: true,
              }
            : {})}
        />
      </InputContainer>
      {hasFilter && <Filter filterOptions={filterOptions} />}
    </div>
  );
};

export default SearchBar;
