import React, { useState, useCallback, useMemo, useEffect } from "react";
import { App, Stack, WebflowDomain } from "generatedTypes";
import Text from "components/Text";
import { Button, Banner, DomainInput, Label, Link } from "components";
import { Add, ArrowUpward } from "@mui/icons-material";
import Onboarding from "components/Onboarding";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useGetWebflowDomainsLazyQuery } from "features/webflow";
import WarningIcon from "@mui/icons-material/Warning";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { AppDomainsLoader } from "./AppDomain.loader";

interface DomainAdderProps {
  label?: string;
  domains: App["domains"];
  isLoading?: boolean;
  onAddDomain: (url: string, mode: string) => void;
  onRemoveDomain: (url: string) => void;
  onUpdateDomain: (id: string, url: string, mode: string) => void;
}

export const AppDomains = ({
  label,
  domains,
  isLoading,
  onAddDomain,
  onRemoveDomain,
  onUpdateDomain,
}: DomainAdderProps) => {
  const [showUpdateBanner, setShowUpdateBanner] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [domainsDeleting, setDomainsDeleting] = useState([]);
  const [suggestions, setSuggestions] = useState<WebflowDomain[]>(null);
  const { currentUser } = useUsersContext();
  const { isSubscribed, canAccessLiveFeatures, appData } = useAppDataContext();

  const [getWebflowDomains, { data, loading }] =
    useGetWebflowDomainsLazyQuery();

  const hasWebflowSiteId = useMemo(
    () => Boolean(appData?.webflowSiteId),
    [appData?.webflowSiteId]
  );
  const isWebflowStack = useMemo(
    () => appData?.stack === Stack.Webflow,
    [appData?.stack]
  );

  const isConnectedToWebflow = currentUser?.session?.connectedToWebflow;

  useEffect(() => {
    if (hasWebflowSiteId && isConnectedToWebflow && isWebflowStack) {
      getWebflowDomains();
    }
  }, [
    getWebflowDomains,
    hasWebflowSiteId,
    isConnectedToWebflow,
    isWebflowStack,
  ]);

  useEffect(() => {
    if (data?.getWebflowDomains) {
      setSuggestions(data?.getWebflowDomains);
    }
  }, [data?.getWebflowDomains]);

  const handleAddDomain = ({ url, mode }) => {
    onAddDomain(url, mode);
  };

  const handleUpdateDomain = useCallback(
    ({ id, url, mode }) => {
      onUpdateDomain(id, url, mode);
    },
    [onUpdateDomain]
  );

  const handleDeleteDomain = useCallback(
    (url) => () => {
      setDomainsDeleting([domainsDeleting, url]);
      onRemoveDomain(url);
    },
    [domainsDeleting, onRemoveDomain]
  );

  const handleAddDomainInput = useCallback(
    () => setFormValues([...formValues, { placeHolder: "my-website.com" }]),
    [formValues]
  );

  const renderState = useMemo(() => {
    if (!isSubscribed) {
      return (
        <Banner
          tw="mt-7 w-max"
          text={
            <>
              To add a Live Mode domain{" "}
              <Link to="/settings/billing" isBold>
                upgrade your plan
              </Link>
            </>
          }
          leftPlugin={<ArrowUpward />}
        />
      );
    }
    if (!domains.length) {
      return (
        <Onboarding
          className="pt-4"
          headerText="Add an App domain to get started here."
          videoSrc="https://www.loom.com/embed/413c2846b78440cca8e597a1cb2d5028"
          videoTitle="Adding add domain"
          videoWidth={319}
          videoHeight={172}
          docsLink="https://help.memberstack.com/hc/en-us/articles/7253288937371-Settings-Page-Overview"
          docsTitle="View Help Center article"
          centerItems={false}
        />
      );
    }
    return null;
  }, [isSubscribed, domains.length]);

  if (loading) {
    return <AppDomainsLoader />;
  }

  const orderedDomains = domains
    ? [...domains]?.sort((a, b) => {
        if (a?.mode === b?.mode) {
          return a?.url?.localeCompare(b?.url);
        }
        return a?.mode === "LIVE" ? -1 : 1;
      })
    : [];

  const domainsList = orderedDomains?.map(
    ({ url: domainUrl, mode: domainMode, id }) => (
      <DomainInput
        key={id}
        className="mb-2"
        domainUrl={domainUrl}
        domainMode={domainMode}
        domainId={id}
        isUpdating={isLoading}
        onDelete={handleDeleteDomain(domainUrl)}
        onUpdate={({ id: _id, url, mode }) => {
          if (domainMode === "SANDBOX" && mode === "LIVE") {
            handleUpdateDomain({ id: _id, url, mode });
            return setShowUpdateBanner(true);
          }
          handleUpdateDomain({ id: _id, url, mode });
          return setShowUpdateBanner(false);
        }}
        isLiveOptionDisabled={!canAccessLiveFeatures}
        suggestions={suggestions}
      />
    )
  );

  const buttonText =
    domainsList && domainsList.length > 0
      ? "Add another domain"
      : "Add app domain";

  return (
    <>
      <div tw="max-w-[546px]">
        <span className="flex items-center text-sm mb-2">
          <Label
            text={label}
            htmlFor={label}
            hint="The main URLs on which you want Memberstack to work"
          />
        </span>
        <Text type="bodySmall" tw="my-2" noMargin color="grey600">
          When you’re ready to launch, enter your live domains below.
          Memberstack will assume you’re in test mode until you do.{" "}
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/7595262385819-Managing-Test-Mode-and-Live-Mode"
            target="_blank"
            underline
            isExternal
          >
            Learn more
          </Link>
        </Text>
      </div>
      <div tw="w-[488px]">
        {domainsList}
        {formValues.map((element, idx) => (
          <DomainInput
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className="mb-2"
            placeholder={element.placeHolder}
            isUpdating={isLoading}
            onAdd={handleAddDomain}
            suggestions={suggestions}
            isLiveOptionDisabled={!canAccessLiveFeatures}
          />
        ))}
        <Button
          text={buttonText}
          leftIcon={<Add />}
          buttonStyle="skeleton"
          onClick={handleAddDomainInput}
          dataCy="add-domain-button"
        />
      </div>
      {showUpdateBanner && (
        <Banner
          leftPlugin={<WarningIcon />}
          className="mt-4"
          type="warning"
          text="Update to Live Mode API Keys"
          description="API keys used in Zapier, Make etc. should be updated to work with your Live Mode domain."
        />
      )}
      {renderState}
    </>
  );
};

AppDomains.defaultProps = {
  isLoading: false,
  label: undefined,
};
