import React, { useMemo, useState, useEffect } from "react";
import { GripVertical } from "lucide-react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { Checkbox, Link, EmptyState } from "components";
import { isError } from "helpers/typeguards";
import { sortBy } from "lodash";
import {
  useOrderCustomFieldsMutation,
  useUpdateCustomFieldMutation,
} from "features/custom-fields";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { Modal, ModalTypes } from "components/Modal";
import { ApolloQueryResult } from "@apollo/client/core";
import { CustomField } from "generatedTypes";
import { dndReorder } from "helpers/dndReorder";

interface Props extends ModalTypes {
  refetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  customFields: Omit<CustomField, "plans">[];
  showCreateModalFn: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrebuiltUIModal = ({
  showModal,
  setShowModal,
  showCreateModalFn,
  refetch,
  customFields,
}: Props) => {
  const [updateCustomField] = useUpdateCustomFieldMutation();
  const [orderCustomFields] = useOrderCustomFieldsMutation();
  const { createToastAlert } = useToastAlertContext();
  const [modalError, setModalError] = useState(null);

  const [items, setItems] = useState(customFields);

  const sortedCustomFields = useMemo(
    () => sortBy(customFields, "order"),
    [customFields]
  );

  useEffect(() => setItems(sortedCustomFields), [sortedCustomFields]);

  const handleOnOrder = async (fieldOrder) => {
    const orders = fieldOrder.map(({ id }, idx) => ({
      customFieldId: id,
      order: idx,
    }));
    createToastAlert({ processing: true });
    try {
      await orderCustomFields({
        variables: {
          input: {
            orders,
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message: "Order of the custom fields have been successfully updated.",
      });
    } catch (e) {
      if (isError(e)) {
        createToastAlert({
          alertType: "error",
          message: e.message,
        });
      }
    } finally {
      await refetch();
    }
  };

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedItems = dndReorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);

    handleOnOrder(reorderedItems);
  };

  const handleOnHide = async ({ hidden, label, id }) => {
    createToastAlert({ processing: true });
    try {
      await updateCustomField({
        variables: {
          input: {
            customFieldId: id,
            hidden: !hidden,
            label,
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message: `${label} field is now ${!hidden ? "hidden" : "unhidden"}.`,
      });
    } catch (e) {
      if (isError(e)) {
        setModalError(e.message);
      }
    } finally {
      await refetch();
    }
  };

  const showCreateModal = () => {
    setShowModal(false);
    showCreateModalFn(true);
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Pre-built UI Settings"
      showDivider
      showBottomSection={false}
      description="Change the order and visibility of custom fields when using the optional"
      descriptionLink={
        <>
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/12971481752091"
            isExternal
            underline
          >
            pre-built modals.
          </Link>{" "}
          See the docs to{" "}
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/25170954863643-Pre-built-UI-Settings"
            isExternal
            underline
          >
            learn more.
          </Link>
        </>
      }
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      {items.length > 0 ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <div className="p-5 bg-app-gray50">
                <ul
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  className="flex flex-col gap-3"
                >
                  {items.map(({ id, hidden, label, key }, index: number) => (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(
                        draggableProvided: DraggableProvided,
                        snapshot: DraggableStateSnapshot
                      ) => (
                        <li
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className="drag-indicator-container flex justify-between items-center p-3 bg-white border border-app-gray200 rounded-md hover:bg-app-gray100"
                          style={{
                            top: "auto",
                            left: "auto",
                            ...draggableProvided.draggableProps.style,
                            boxShadow: snapshot.isDragging
                              ? "0px 4px 24px rgba(0, 0, 0, 0.04)"
                              : "none",
                          }}
                        >
                          <Checkbox
                            type="visibility"
                            label={label}
                            checked={hidden}
                            onChange={() => handleOnHide({ hidden, label, id })}
                          />
                          <GripVertical className="w-4 h-4" />
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </ul>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="p-5">
          <EmptyState
            text="Reorder Custom Fields"
            description="You don’t have any custom fields. Click below to add your first Custom Field."
            buttonText="Add Custom Field"
            onCreateClick={showCreateModal}
            docsLink="https://docs.memberstack.com/hc/en-us/articles/25170954863643-Pre-built-UI-Settings"
          />
        </div>
      )}
    </Modal>
  );
};

export default PrebuiltUIModal;
