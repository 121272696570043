import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetContentGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetContentGroupsQuery = (
  { __typename?: 'Query' }
  & { getContentGroups?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id' | 'key' | 'name' | 'redirect' | 'allowAllMembers'>
    & { urls: Array<Types.Maybe<(
      { __typename?: 'RestrictedUrl' }
      & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
    )>>, plans: Array<Types.Maybe<(
      { __typename?: 'Plan' }
      & Pick<Types.Plan, 'id' | 'name'>
    )>>, customContent: Array<Types.Maybe<(
      { __typename?: 'CustomContent' }
      & Pick<Types.CustomContent, 'key' | 'id' | 'name' | 'content' | 'type' | 'restrictedUrlGroupId'>
    )>> }
  )>>> }
);

export type GetContentGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetContentGroupQuery = (
  { __typename?: 'Query' }
  & { getContentGroup?: Types.Maybe<(
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id' | 'key' | 'name' | 'redirect' | 'allowAllMembers'>
    & { urls: Array<Types.Maybe<(
      { __typename?: 'RestrictedUrl' }
      & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
    )>>, plans: Array<Types.Maybe<(
      { __typename?: 'Plan' }
      & Pick<Types.Plan, 'id' | 'name'>
    )>>, customContent: Array<Types.Maybe<(
      { __typename?: 'CustomContent' }
      & Pick<Types.CustomContent, 'key' | 'id' | 'name' | 'content' | 'type' | 'restrictedUrlGroupId'>
    )>> }
  )> }
);


export const GetContentGroupsDocument = gql`
    query GetContentGroups {
  getContentGroups {
    id
    key
    name
    redirect
    allowAllMembers
    urls {
      id
      url
      filter
    }
    plans {
      id
      name
    }
    customContent {
      key
      id
      name
      content
      type
      restrictedUrlGroupId
    }
  }
}
    `;

/**
 * __useGetContentGroupsQuery__
 *
 * To run a query within a React component, call `useGetContentGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetContentGroupsQuery, GetContentGroupsQueryVariables>) {
        return Apollo.useQuery<GetContentGroupsQuery, GetContentGroupsQueryVariables>(GetContentGroupsDocument, baseOptions);
      }
export function useGetContentGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentGroupsQuery, GetContentGroupsQueryVariables>) {
          return Apollo.useLazyQuery<GetContentGroupsQuery, GetContentGroupsQueryVariables>(GetContentGroupsDocument, baseOptions);
        }
export type GetContentGroupsQueryHookResult = ReturnType<typeof useGetContentGroupsQuery>;
export type GetContentGroupsLazyQueryHookResult = ReturnType<typeof useGetContentGroupsLazyQuery>;
export type GetContentGroupsQueryResult = Apollo.QueryResult<GetContentGroupsQuery, GetContentGroupsQueryVariables>;
export const GetContentGroupDocument = gql`
    query GetContentGroup($id: ID!) {
  getContentGroup(id: $id) {
    id
    key
    name
    redirect
    allowAllMembers
    urls {
      id
      url
      filter
    }
    plans {
      id
      name
    }
    customContent {
      key
      id
      name
      content
      type
      restrictedUrlGroupId
    }
  }
}
    `;

/**
 * __useGetContentGroupQuery__
 *
 * To run a query within a React component, call `useGetContentGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentGroupQuery(baseOptions: Apollo.QueryHookOptions<GetContentGroupQuery, GetContentGroupQueryVariables>) {
        return Apollo.useQuery<GetContentGroupQuery, GetContentGroupQueryVariables>(GetContentGroupDocument, baseOptions);
      }
export function useGetContentGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentGroupQuery, GetContentGroupQueryVariables>) {
          return Apollo.useLazyQuery<GetContentGroupQuery, GetContentGroupQueryVariables>(GetContentGroupDocument, baseOptions);
        }
export type GetContentGroupQueryHookResult = ReturnType<typeof useGetContentGroupQuery>;
export type GetContentGroupLazyQueryHookResult = ReturnType<typeof useGetContentGroupLazyQuery>;
export type GetContentGroupQueryResult = Apollo.QueryResult<GetContentGroupQuery, GetContentGroupQueryVariables>;