import React, { useCallback, useState } from "react";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import Text from "components/Text";
import Input from "components/Input";
import Spacer from "components/Spacer";
import { Modal, ModalTypes } from "components/Modal";
import { useDeleteCustomFieldMutation } from "features/custom-fields/mutations.generated";
import { Form } from "components";
import { useCustomFieldContext } from "../context";

interface Props extends ModalTypes {
  label: string;
  id: string;
  closeEditModal: () => void;
}

export const DeleteCustomFieldModal = ({
  showModal,
  setShowModal,
  label,
  id,
  closeEditModal,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const confirmationText = label;

  const { createToastAlert } = useToastAlertContext();
  const { refetchCustomFields } = useCustomFieldContext();
  const [modalError, setModalError] = useState(null);
  const [deleteCustomField, { loading }] = useDeleteCustomFieldMutation({
    variables: {
      input: {
        customFieldId: id,
      },
    },
    onCompleted: () => {
      refetchCustomFields();
      createToastAlert({
        alertType: "success",
        message: "Column was successfully deleted.",
      });
      setShowModal(false);
      setInputValue("");
      closeEditModal();
    },
    onError: () => {
      setModalError("Could not delete column.");
    },
  });

  const handleOnChange = useCallback(
    (event) => setInputValue(event.target.value),
    []
  );

  const handleDeleteCustomField = async () => {
    createToastAlert({ processing: true });
    await deleteCustomField();
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Confirm Deletion"
      showDivider
      actionButtons={{
        confirm: {
          label: "Proceed with deletion",
          onConfirm: handleDeleteCustomField,
          isLoading: loading,
          isDisabled: confirmationText !== inputValue,
          buttonStyle: "danger",
          dataCy: "confirm-delete-custom-field-button",
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <div data-testid="delete-field">
        <Form onSubmit={handleDeleteCustomField}>
          <Text className="mb-2">
            Once deleted, this process cannot be undone. All existing member
            data in ‘<strong>{label}</strong>’ will be deleted immediately.
          </Text>
          <Spacer spacing="medium" />
          <Text className="mb-1">
            To confirm, please type <strong> {confirmationText} </strong> below:
          </Text>
          <Input
            value={inputValue}
            onChange={handleOnChange}
            hideIndicators
            dataCy="confirm-delete-custom-field-input"
          />
        </Form>
      </div>
    </Modal>
  );
};
