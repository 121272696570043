import React from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import Skeleton from "react-loading-skeleton";
import { AccordionLoader } from "components";
import TextComboLoading from "components/TextCombo/TextCombo.loading";
import InputLoading from "components/Input/Input.loading";
import Spacer from "components/Spacer";
import { TabItem, TabNav, Tabs } from "components/Tabs";

export const PlansPageLoading = () => (
  <div className="flex flex-col flex-wrap h-full overflow-hidden">
    <SettingsBar tw="w-1/2 min-w-[350px] max-w-[50%]">
      <TitleSection
        title="Plans"
        buttons={
          <div className="flex items-center gap-2">
            <Skeleton width={88} height={32} />
            <Skeleton width={98} height={32} />
            <Skeleton width={24} height={32} />
          </div>
        }
        emphasize
        divider={false}
      />
      <Tabs isExpanded showBottomBorder>
        <TabNav>
          <TabItem id="active" label="Active" />
          <TabItem id="archived" label="Archived" />
        </TabNav>
      </Tabs>
      <div className="flex flex-col gap-3 p-5 bg-app-gray50 h-full">
        {["item1", "item2", "item3"].map((item) => (
          <Skeleton height={54} key={item} />
        ))}
      </div>
    </SettingsBar>
    <div className="w-1/2">
      <div className="p-5 flex flex-col">
        <div className="flex items-center justify-between">
          <Skeleton width={150} height={28} />
          <div className="flex items-center gap-2">
            <Skeleton width={147} height={32} />
            <Skeleton width={24} height={32} />
          </div>
        </div>
      </div>
      <AccordionLoader noOfItems={4} hasBorder />
    </div>
  </div>
);

export const PlanDetailLoading = () => {
  return (
    <div className="w-1/2">
      <div className="p-5 flex flex-col">
        <div className="flex items-center justify-between">
          <Skeleton width={150} height={28} />
          <div className="flex items-center gap-2">
            <Skeleton width={147} height={32} />
            <Skeleton width={24} height={32} />
          </div>
        </div>
      </div>
      <AccordionLoader noOfItems={4} hasBorder />
    </div>
  );
};

export const EditPlanLoading = () => {
  return (
    <div className="w-1/2 h-full flex flex-col">
      <TitleSection
        title="Plan Information"
        backLink={undefined}
        backLinkTitle={<Skeleton height={20.15} width={120} />}
        buttons={<Skeleton width={49} height={32} />}
        emphasize
      />
      <div tw="p-5">
        <TextComboLoading className="mb-4" spaceOut hasLabel />
        <InputLoading />
        <Spacer spacing="medium" />
        <InputLoading row={3} />
      </div>
    </div>
  );
};
