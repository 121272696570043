export const testimonials = [
  {
    id: "1",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61e6c57965f51008e5efb9f2_Tim%20daff%201%20(1).jpg",
    author: "Tim Daff",
    testimony:
      "Awesome service by the Memberstack team. I recently implemented my first site with paid memberships and gated content - Super easy.",
  },
  {
    id: "2",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f94481641bbe0b69eed327_Sean%20Tubridy.jpg",
    author: "Sean Tubridy",
    testimony:
      "We built our event site in Webflow and Memberstack for our 1000 attendee event and it went perfectly. Not a single issue with Memberstack logins.",
  },
  {
    id: "3",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f92eec5ebdd64e9523d9cb_Mark%20Yaasi.jpg",
    author: "Mark Yaasi",
    testimony:
      "I actually showed memberstack to a client wanting a webflow site as an example of what can be done. You guys did an amazing job!",
  },
  {
    id: "4",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f944162f9ca605be006aaa_Martin%20Adams.jpg",
    author: "Martin Adams",
    testimony:
      "Setting up was a breeze. Go ahead and take the step — you won’t regret it.",
  },
  {
    id: "5",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f92f0c2f9ca64b28ecc382_Youssef%20Sarhan.jpg",
    author: "Youssef Sarhan",
    testimony:
      "Damn, Memberstack is changing the game. This is big. If you're building websites, you need to start playing with Memberstack. It allows you to create logged–in users. That's a game changer.",
  },
  {
    id: "6",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f944307170da5b374164e7_Matthew%20James%20Lawler.jpg",
    author: "Matthew James Lawler",
    testimony:
      "Have you seen MemberStack? It’s a great solution for creating members only websites. Gives you similar functionality to Patreon, but on your own site.",
  },
  {
    id: "7",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f9443e1b83ef49eebe77c2_Mike%20Devore.jpg",
    author: "Mike DeVore",
    testimony: "Figured out Memberstack today. Such a cool platform.",
  },
  {
    id: "8",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f942c1641bbe2ce7ed94b9_Aaron%20Romero.jpg",
    author: "Aaron Grieve",
    testimony:
      "I've been integrating MemberStack into a new project. I'm so impressed by the well designed UI/UX and great customer service. Check it out and show them some love!",
  },
  {
    id: "9",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f94306af61bd3c4aa7bdf4_Brett%20Williams.jpg",
    author: "Brett Williams",
    testimony:
      "Memberstack has been a lifesaver for my design subscription company, Designjoy. The system was extremely easy to implement, and Tyler and Duncan have provided phenomenal support along the way. Our favorite part.",
  },
  {
    id: "10",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61b2856746f5244e91510ddf_Jvw_5gG6_400x400%201%20(1).jpg",
    author: "Nicolas Kneler",
    testimony:
      "Memberstack helps us sign up over 10K new applicants every month, with zero problems and a stellar support crew. Couldn't be happier with our choice 🙂",
  },
  {
    id: "11",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/5c9ec553b9de9c5eb9af8bd2_5c86880a5d3e5309f7d6c38f_jonah.jpg",
    author: "Jonah Cockshaw",
    testimony:
      "Guys, you just blew my mind. So many other Membership Platforms didn’t offer what our clients need. They need a fully integrated and easy-to-set-up solution that our team, and our clients, can enjoy working with, whilst also delivering on the crucial components of a successful Membership solution. For us, Memberstack has truly gone above and beyond. We simply couldn’t wait to introduce Memberstack to our clients!",
  },

  {
    id: "12",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f94449e30bad0bf76e05ad_Nigel%20Shaw.jpg",
    author: "Nigel Shaw",
    testimony:
      "As a software developer of 25 years, I've seen a lot of apps and SAAS products and Memberstack is one of the best I've ever seen. The ease of use of the site is unreal. Amazing job putting it all together and providing an indispensable",
  },
  {
    id: "13",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61b28d7537a7f2d32f5478f0_image%209%20(1).jpg",
    author: "Alastair Budge",
    testimony:
      "I'm not really sure how I would have built my site without Memberstack. It's done everything that I wanted. So I'm very happy to be a Memberstack customer.",
  },
  {
    id: "14",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f92e9be8da18f034b31b00_Gabriel.jpg",
    author: "Gabriel Amzallag",
    testimony:
      "Thanks for your help and your amazing product! You have a good impact on the no-code industry :)",
  },
  {
    id: "15",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f942fe5233c4897cd23aeb_Brendan%20Weinstein.jpg",
    author: "Brendan Weinstein",
    testimony:
      "Amazing! Quite possibly my favorite no-code tools as it makes creating groups, memberships, communities and sub-user provisioning SOO easy! Will be making countless products using @MemberStackApp.",
  },
  {
    id: "16",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/619d3a9ac1c71a4934853d6d_618d560c28d1a0bc953e1ea8_1539232956898.jpg",
    author: "Joe Krug",
    testimony:
      "The Memberstack team and product is A+++. It's what the web development community needs!",
  },
  {
    id: "17",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f92ea8e3af825dab9e927c_Hunter.jpg",
    author: "Hunter McKinley",
    testimony:
      "Loving Memberstack so far! I got my site up and running with profiles in no time.",
  },
  {
    id: "18",
    image:
      "https://assets-global.website-files.com/5c2a9a234fdbba7439c48fa4/61f9456cd5966a6f24b2220f_Raymahl%20Sutton.jpg",
    author: "Raymahl Sutton",
    testimony:
      "I've really gotten dialed in with @webflow @MemberstackApp @zapier @twilio @stripe. They've helped me understand how to move quickly once I find an angle to service.",
  },
];
