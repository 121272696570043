import styled, { css } from "styled-components";

export const Benefit = styled.div<{ $isActive?: boolean }>`
  ${({ theme: { colors }, $isActive }) => css`
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 12px;
    overflow: hidden;
    white-space: nowrap;
    color: ${$isActive ? colors.grey700 : colors.grey400};
    & > svg {
      margin-right: 6px;
      color: ${$isActive ? colors.blue400 : colors.grey400};
    }
  `}
`;
