import React, { useState } from "react";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import useSubmit from "hooks/useSubmit";
import { Modal } from "components/Modal";
import Text from "components/Text";
import Input from "components/Input";
import { useUpdatePlanMutation } from "features/plans";
import { Link } from "components";
import { usePlansContext } from "../context/Plans.context";

const CreateSignupRule = ({ showModal, setShowModal, domains }) => {
  const [domain, setDomain] = useState("");
  const { refetchPlans } = usePlansContext();
  const { planId } = useParams<{ planId: string }>();
  const [updatePlan, { loading }] = useUpdatePlanMutation();
  const { submit } = useSubmit({
    action: updatePlan,
    refetch: refetchPlans,
    success: "Domain created successfully.",
    onSuccess: () => setShowModal(false),
    fields: {
      planId,
      allowedDomains: update(domains, {
        $push: [domain],
      }),
    },
  });

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Add Signup Rule"
      description="Require members to use a specific email domain. Ex: @yourcompany.com or @clientdomain.com"
      showDivider
      actionButtons={{
        cancel: {
          label: "Cancel",
        },
        confirm: {
          label: "Save",
          onConfirm: () => submit(),
          isLoading: loading,
          isDisabled: !domain,
        },
      }}
    >
      <form onSubmit={() => submit()}>
        <Input
          label="Domain"
          name="domain"
          onChange={(e) => setDomain(e.target.value)}
          type="text"
          value={domain}
          className="mb-4"
          required
          leading={<Text color="black">username@</Text>}
          description={
            <>
              Enter the required email domain.{" "}
              <Link
                isExternal
                to="https://docs.memberstack.com/hc/en-us/articles/12125675234075"
                target="_blank"
                underline
              >
                Help Docs →
              </Link>
            </>
          }
        />
        <button type="submit" className="sr-only">
          Submit
        </button>
      </form>
    </Modal>
  );
};

export default CreateSignupRule;
