import tw, { styled, css } from "twin.macro";
import { colors as c } from "styles";

import { animated } from "react-spring";

type AlertType = "success" | "error";

const alertTypeColors = {
  success: c.green300,
  error: c.red300,
};

export const ToastAlertContainer = styled.div(() => [
  tw`p-3 bg-app-gray900 text-white flex justify-between items-center rounded-lg box-border`,
  css`
    transform: translateY(calc(-100% - 30px));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: all;
  `,
]);

export const ToastInfo = styled.div<{ alertType: AlertType }>(
  ({ alertType }) => css`
    display: flex;
    align-items: center;
    & > svg {
      height: 20px;
      width: 20px;
      background: ${alertTypeColors[alertType]};
      border-radius: 50%;
      margin-right: 8px;
      padding: 3px;
      color: black;
    }
  `
);

const WIDTH = 529;

export const ToastContainer = styled(animated.div)`
  min-width: ${WIDTH}px;
  position: fixed;
  left: calc(50% - ${WIDTH / 2}px);
  bottom: 0;
  z-index: 101;
  pointer-events: none;
`;
