export const PRICE_IDS = {
  app: {
    basic: {
      monthly: "price_1LNeGeKQYPov2Q6DMoicDgRX", // live mode basic monthly
      yearly: "price_1LNeGeKQYPov2Q6Dqthvj5nH", // live mode basic yearly
    },
    professional: {
      monthly: "price_1LNeHoKQYPov2Q6DZtGl9cpy", // live mode professional monthly
      yearly: "price_1MjPTjKQYPov2Q6DpCs6DtKP", // live mode professional yearly
    },
    business: {
      monthly: "price_1MhGP4KQYPov2Q6DEkn1PETS", // live mode business monthly
      yearly: "price_1MhGP4KQYPov2Q6DFAbbRPiF", // live mode business yearly
    },
  },
  development: {
    basic: {
      monthly: "price_1Jro9EKQYPov2Q6DOYTMGxZu", // devlopment mode basic monthly
      yearly: "price_1Jro9EKQYPov2Q6DPCiaGGpq", // devlopment mode basic yearly
    },
    professional: {
      monthly: "price_1JroAMKQYPov2Q6DAqT19JGo", // devlopment mode professional monthly
      yearly: "price_1MjPUUKQYPov2Q6DUgeuLKff", // devlopment mode professional yearly
    },
    business: {
      monthly: "price_1MhGOUKQYPov2Q6D5CjmoWyw", // devlopment mode business monthly
      yearly: "price_1MhGOUKQYPov2Q6DpXbf8pFw", // devlopment mode business yearly
    },
  },
};
