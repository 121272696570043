import React, { useState } from "react";
import { Card, Dropdown, EmptyState } from "components";
import { Plan } from "generatedTypes";
import DeleteSignupRule from "./DeleteSignupRule";
import EditSignupRule from "./EditSignupRule";
import CreateSignupRule from "../../modals/CreateSignupRule";
import { PlanSignupMemberLimit } from "./SignupMemberLimit";
import { PlanAccordionItem } from "./PlanAccordionItem";

interface Props {
  plan: Plan;
  refetch: () => void;
}

export const PlanSignupRules = ({ plan, refetch }: Props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editTargetDomain, setEditTargetDomain] = useState(null);
  const [deleteTargetDomain, setDeleteTargetDomain] = useState(null);
  const [showCreateSignupRules, setShowCreateSignupRules] = useState(false);

  const handleEditDomain = (domain) => {
    setEditTargetDomain(domain);
    setShowEdit(true);
  };

  const handleRemoveDomain = (domain) => {
    setDeleteTargetDomain(domain);
    setShowDelete(true);
  };

  return (
    <PlanAccordionItem
      label="Signup Rules"
      value="signupRules"
      total={plan?.allowedDomains?.length}
      onAdd={() => setShowCreateSignupRules(true)}
    >
      <section>
        <div className="flex flex-col gap-2">
          {plan.allowedDomains?.map((rule) => (
            <Card tw="p-3" key={rule}>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <p className="text-base whitespace-pre-line">Allow {rule}</p>
                </div>
                <div className="flex items-center">
                  <Dropdown
                    options={[
                      {
                        text: "Edit",
                        onClick: () => handleEditDomain(rule),
                      },
                      {
                        text: "Remove",
                        onClick: () => handleRemoveDomain(rule),
                        isDangerText: true,
                      },
                    ]}
                  />
                </div>
              </div>
            </Card>
          ))}
          {plan.allowedDomains.length === 0 && (
            <EmptyState
              text="Create Signup Rules"
              description="Allow all members with particular domains to sign up."
              buttonText="Create Signup Rule"
              onCreateClick={() => setShowCreateSignupRules(true)}
              docsLink="https://docs.memberstack.com/hc/en-us/articles/12125675234075-Plan-Signup-Rules-a-k-a-restrict-signups-by-email-domain-"
            />
          )}
        </div>
        <PlanSignupMemberLimit plan={plan} refetch={refetch} />
      </section>
      <EditSignupRule
        showModal={showEdit}
        setShowModal={(value) => {
          if (!value) {
            setEditTargetDomain("");
          }
          setShowEdit(value);
        }}
        domains={plan.allowedDomains}
        targetDomain={editTargetDomain}
      />
      <DeleteSignupRule
        showModal={showDelete}
        setShowModal={(value) => {
          if (!value) {
            setDeleteTargetDomain("");
          }
          setShowDelete(value);
        }}
        domains={plan.allowedDomains}
        targetDomain={deleteTargetDomain}
      />
      <CreateSignupRule
        showModal={showCreateSignupRules}
        setShowModal={setShowCreateSignupRules}
        domains={plan?.allowedDomains}
      />
    </PlanAccordionItem>
  );
};
