export const currencies = [
  {
    label: "usd",
    code: "USD",
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "cad",
    code: "CAD",
    symbol: "$",
    name: "Canadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "eur",
    code: "EUR",
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "aed",
    code: "AED",
    symbol: "AED",
    name: "United Arab Emirates Dirham",
    symbol_native: "د.إ",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "ang",
    code: "ANG",
    symbol: "Af",
    name: "Netherlands Antillean guilder",
    symbol_native: "NAƒ",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "amd",
    code: "AMD",
    symbol: "AMD",
    name: "Armenian Dram",
    symbol_native: "֏",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "aud",
    code: "AUD",
    symbol: "$",
    name: "Australian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "azn",
    code: "AZN",
    symbol: "man.",
    name: "Azerbaijani Manat",
    symbol_native: "ман",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "awg",
    code: "AWG",
    symbol: "ƒ",
    name: "Aruban florin",
    symbol_native: "ƒ",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bam",
    code: "BAM",
    symbol: "KM",
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol_native: "KM",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bbd",
    code: "BBD",
    symbol: "$",
    name: "Barbados Dollar",
    symbol_native: "Bds$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bdt",
    code: "BDT",
    symbol: "Tk",
    name: "Bangladeshi Taka",
    symbol_native: "৳",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bgn",
    code: "BGN",
    symbol: "BGN",
    name: "Bulgarian Lev",
    symbol_native: "лв.",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bif",
    code: "BIF",
    symbol: "FBu",
    name: "Burundian Franc",
    symbol_native: "FBu",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "bmd",
    code: "BMD",
    symbol: "$",
    name: "Bermuda Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bnd",
    code: "BND",
    symbol: "B$",
    name: "Brunei Dollar",
    symbol_native: "B$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bsd",
    code: "BSD",
    symbol: "B$",
    name: "Bahamian Dollar",
    symbol_native: "B$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bwp",
    code: "BWP",
    symbol: "P",
    name: "Botswanan Pula",
    symbol_native: "P",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "bzd",
    code: "BZD",
    symbol: "$",
    name: "Belize Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "cdf",
    code: "CDF",
    symbol: "FC",
    name: "Congolese Franc",
    symbol_native: "FC",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "chf",
    code: "CHF",
    symbol: "CHF",
    name: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
  },
  {
    label: "clp",
    code: "CLP",
    symbol: "$",
    name: "Chilean Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "cny",
    code: "CNY",
    symbol: "¥",
    name: "Chinese Yuan",
    symbol_native: "¥",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "djf",
    code: "DJF",
    symbol: "Fdj",
    name: "Djiboutian Franc",
    symbol_native: "Fdj",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "dkk",
    code: "DKK",
    symbol: "kr",
    name: "Danish Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "dop",
    code: "DOP",
    symbol: "RD$",
    name: "Dominican Peso",
    symbol_native: "RD$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "dzd",
    code: "DZD",
    symbol: "DA",
    name: "Algerian Dinar",
    symbol_native: "د.ج.",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "egp",
    code: "EGP",
    symbol: "EGP",
    name: "Egyptian Pound",
    symbol_native: "ج.م.",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "etb",
    code: "ETB",
    symbol: "Br",
    name: "Ethiopian Birr",
    symbol_native: "Br",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "etb",
    code: "ETB",
    symbol: "$",
    name: "Fijian dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "gbp",
    code: "GBP",
    symbol: "£",
    name: "British Pound Sterling",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "gel",
    code: "GEL",
    symbol: "GEL",
    name: "Georgian Lari",
    symbol_native: "GEL",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "gip",
    code: "GIP",
    symbol: "£",
    name: "Gibraltar Pound",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "gmd",
    code: "GMD",
    symbol: "D",
    name: "Gambian Dalasi",
    symbol_native: "D",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "gyd",
    code: "GYD",
    symbol: "GY$",
    name: "Guyanese dollar",
    symbol_native: "GY$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "gnf",
    code: "GNF",
    symbol: "FG",
    name: "Guinean Franc",
    symbol_native: "FG",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "hkd",
    code: "HKD",
    symbol: "$",
    name: "Hong Kong Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "hrk",
    code: "HRK",
    symbol: "kn",
    name: "Croatian Kuna",
    symbol_native: "kn",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "idr",
    code: "IDR",
    symbol: "Rp",
    name: "Indonesian Rupiah",
    symbol_native: "Rp",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "ils",
    code: "ILS",
    symbol: "₪",
    name: "Israeli New Sheqel",
    symbol_native: "₪",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "isk",
    code: "ISK",
    symbol: "Ikr",
    name: "Icelandic Króna",
    symbol_native: "kr",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "jmd",
    code: "JMD",
    symbol: "J$",
    name: "Jamaican Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "jpy",
    code: "JPY",
    symbol: "¥",
    name: "Japanese Yen",
    symbol_native: "￥",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "kes",
    code: "KES",
    symbol: "Ksh",
    name: "Kenyan Shilling",
    symbol_native: "Ksh",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "khr",
    code: "KHR",
    symbol: "KHR",
    name: "Cambodian Riel",
    symbol_native: "៛",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "kmf",
    code: "KMF",
    symbol: "CF",
    name: "Comorian Franc",
    symbol_native: "FC",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "krw",
    code: "KRW",
    symbol: "₩",
    name: "South Korean Won",
    symbol_native: "₩",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "kzt",
    code: "KZT",
    symbol: "₸",
    name: "Kazakhstani Tenge",
    symbol_native: "₸",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "lbp",
    code: "LBP",
    symbol: ".ل.ل",
    name: "Lebanese Pound",
    symbol_native: ".ل.ل",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "lkr",
    code: "LKR",
    symbol: "Rs",
    name: "Sri Lankan Rupee",
    symbol_native: "Rs",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mad",
    code: "MAD",
    symbol: "MAD",
    name: "Moroccan Dirham",
    symbol_native: "د.م.",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mdl",
    code: "MDL",
    symbol: "MDL",
    name: "Moldovan Leu",
    symbol_native: "MDL",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mga",
    code: "MGA",
    symbol: "MGA",
    name: "Malagasy Ariary",
    symbol_native: "MGA",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "mkd",
    code: "MKD",
    symbol: "MKD",
    name: "Macedonian Denar",
    symbol_native: "MKD",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mmk",
    code: "MMK",
    symbol: "MMK",
    name: "Myanma Kyat",
    symbol_native: "K",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "mop",
    code: "MOP",
    symbol: "MOP$",
    name: "Macanese Pataca",
    symbol_native: "MOP$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mxn",
    code: "MXN",
    symbol: "MX$",
    name: "Mexican Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "myr",
    code: "MYR",
    symbol: "RM",
    name: "Malaysian Ringgit",
    symbol_native: "RM",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mzn",
    code: "MZN",
    symbol: "MTn",
    name: "Mozambican Metical",
    symbol_native: "MTn",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "nad",
    code: "NAD",
    symbol: "N$",
    name: "Namibian Dollar",
    symbol_native: "N$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "ngn",
    code: "NGN",
    symbol: "₦",
    name: "Nigerian Naira",
    symbol_native: "₦",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "nok",
    code: "NOK",
    symbol: "Nkr",
    name: "Norwegian Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "npr",
    code: "NPR",
    symbol: "NPRs",
    name: "Nepalese Rupee",
    symbol_native: "नेरू",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "nzd",
    code: "NZD",
    symbol: "NZ$",
    name: "New Zealand Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "php",
    code: "PHP",
    symbol: "₱",
    name: "Philippine Peso",
    symbol_native: "₱",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "pkr",
    code: "PKR",
    symbol: "PKRs",
    name: "Pakistani Rupee",
    symbol_native: "₨",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "pln",
    code: "PLN",
    symbol: "zł",
    name: "Polish Zloty",
    symbol_native: "zł",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "pyg",
    code: "PYG",
    symbol: "₲",
    name: "Paraguayan Guarani",
    symbol_native: "₲",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "qar",
    code: "QAR",
    symbol: "QR",
    name: "Qatari Rial",
    symbol_native: "ر.ق.",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "ron",
    code: "RON",
    symbol: "RON",
    name: "Romanian Leu",
    symbol_native: "RON",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "rsd",
    code: "RSD",
    symbol: "din.",
    name: "Serbian Dinar",
    symbol_native: "дин.",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "rub",
    code: "RUB",
    symbol: "RUB",
    name: "Russian Ruble",
    symbol_native: "₽.",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "rwf",
    code: "RWF",
    symbol: "RWF",
    name: "Rwandan Franc",
    symbol_native: "FR",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "sar",
    code: "SAR",
    symbol: "SR",
    name: "Saudi Riyal",
    symbol_native: "ر.س.‏",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "sek",
    code: "SEK",
    symbol: "Skr",
    name: "Swedish Krona",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "sgd",
    code: "SGD",
    symbol: "S$",
    name: "Singapore Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "sos",
    code: "SOS",
    symbol: "Ssh",
    name: "Somali Shilling",
    symbol_native: "Ssh",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "thb",
    code: "THB",
    symbol: "฿",
    name: "Thai Baht",
    symbol_native: "฿",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "top",
    code: "TOP",
    symbol: "T$",
    name: "Tongan Paʻanga",
    symbol_native: "T$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "try",
    code: "TRY",
    symbol: "TL",
    name: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "ttd",
    code: "TTD",
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "twd",
    code: "TWD",
    symbol: "NT$",
    name: "New Taiwan Dollar",
    symbol_native: "NT$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "tzs",
    code: "TZS",
    symbol: "TSh",
    name: "Tanzanian Shilling",
    symbol_native: "TSh",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "uah",
    code: "UAH",
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    symbol_native: "₴",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "ugx",
    code: "UGX",
    symbol: "USh",
    name: "Ugandan Shilling",
    symbol_native: "USh",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "uzs",
    code: "UZS",
    symbol: "UZS",
    name: "Uzbekistan Som",
    symbol_native: "UZS",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "vuv",
    code: "VUV",
    symbol: "VT",
    name: "Vanuatu Vatu",
    symbol_native: "VT.",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "vnd",
    code: "VND",
    symbol: "₫",
    name: "Vietnamese Dong",
    symbol_native: "₫",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "xaf",
    code: "XAF",
    symbol: "FCFA",
    name: "CFA Franc BEAC",
    symbol_native: "FCFA",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "xof",
    code: "XOF",
    symbol: "CFA",
    name: "CFA Franc BCEAO",
    symbol_native: "CFA",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "xpf",
    code: "XPF",
    symbol: "₣",
    name: "CFP Franc",
    symbol_native: "CFP",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "yer",
    code: "YER",
    symbol: "YR",
    name: "Yemeni Rial",
    symbol_native: "ر.ي.‏",
    decimal_digits: 0,
    rounding: 0,
  },
  {
    label: "zar",
    code: "ZAR",
    symbol: "R",
    name: "South African Rand",
    symbol_native: "R",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "htg",
    code: "HTG",
    symbol: "G",
    name: "Haitian gourde",
    symbol_native: "G",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "kgs",
    code: "KGS",
    symbol: "Лв",
    name: "Kyrgyzstani som",
    symbol_native: "Лв",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "kyd",
    code: "KYD",
    symbol: "$",
    name: "Cayman Islands dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "lrd",
    code: "LRD",
    symbol: "L$",
    name: "Liberian dollar",
    symbol_native: "L$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "lsl",
    code: "LSL",
    symbol: "L",
    name: "Lesotho loti",
    symbol_native: "L",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mnt",
    code: "MNT",
    symbol: "₮",
    name: "Mongolian tögrög",
    symbol_native: "₮",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mro",
    code: "MRO",
    symbol: "UM",
    name: "Mauritanian ouguiya",
    symbol_native: "UM",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "zmw",
    code: "ZMW",
    symbol: "ZK",
    name: "Zambian kwacha",
    symbol_native: "ZK",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "wst",
    code: "WST",
    symbol: "WS$",
    name: "Samoan tālā",
    symbol_native: "WS$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "pgk",
    code: "PGK",
    symbol: "K",
    name: "Papua New Guinean kina",
    symbol_native: "K",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "sbd",
    code: "SBD",
    symbol: "Si$",
    name: "Solomon Islands dollar",
    symbol_native: "Si$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "scr",
    code: "SCR",
    symbol: "SR",
    name: "Seychellois rupee",
    symbol_native: "SR",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "sll",
    code: "SLL",
    symbol: "Le",
    name: "Sierra Leonean leone",
    symbol_native: "Le",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mwk",
    code: "MWK",
    symbol: "MK",
    name: "Malawian kwacha",
    symbol_native: "MK",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "xcd",
    code: "XCD",
    symbol: "$",
    name: "stern Caribbean dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "szl",
    code: "SZL",
    symbol: "E",
    name: "Swazi lilangeni",
    symbol_native: "E",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "mvr",
    code: "MVR",
    symbol: "Rf",
    name: "Maldivian rufiyaa",
    symbol_native: "Rf",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "czk",
    code: "CZK",
    symbol: "Kč",
    name: "Czech koruna",
    symbol_native: "Kč",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "inr",
    code: "INR",
    symbol: "₹",
    name: "Indian rupee",
    symbol_native: "₹",
    decimal_digits: 2,
    rounding: 0,
  },
  {
    label: "brl",
    code: "BRL",
    symbol: "R$",
    name: "Brazilian real",
    symbol_native: "R$",
    decimal_digits: 2,
    rounding: 0,
  },
];
