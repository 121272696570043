import React from "react";

const PlanTitle = ({ plan, suffix = "" }) => (
  <>
    {plan?.icon ? (
      <div className="flex items-center text-base gap-2">
        {plan.icon}
        <span>{`${plan?.name} ${suffix}`}</span>
      </div>
    ) : (
      <span tw="text-base w-[280px] truncate">{`${plan?.name} ${suffix}`}</span>
    )}
  </>
);

export default PlanTitle;
