import React from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import { Button, Background, Switch } from "components";
import InputLoading from "components/Input/Input.loading";
import Skeleton from "react-loading-skeleton";
import * as S from "./emails.styles";

export const EmailTemplateLoading = ({ title }) => (
  <>
    <SettingsBar>
      <TitleSection
        title={title}
        backLink="/settings/emails"
        backLinkTitle="Emails"
        emphasize
        gutter="medium"
        buttons={<Button text="Save" isDisabled={false} />}
      />
      <div className="p-5" onSubmit={() => undefined}>
        <Switch
          tw="pb-5"
          label="Enable "
          isChecked={undefined}
          name="welcomeEmail"
          id="welcomeEmail"
          onChange={undefined}
        />
        <InputLoading tw="pb-5" />
        <InputLoading tw="pb-5" />
        <InputLoading tw="pb-5" />
        <InputLoading tw="pb-5" row={7} />
      </div>
    </SettingsBar>
    <Background>
      <div tw="p-5">
        <S.UploadLogoText />
        <S.ShowcaseCard>
          <div tw="pb-[30px]">
            <Skeleton height={43} width={102} />
          </div>
          <Skeleton height={100} />
        </S.ShowcaseCard>
      </div>
    </Background>
  </>
);
