import React, { lazy, useCallback } from "react";
import { MenuOpen, Search } from "@mui/icons-material";
import { useSidebarItems } from "hooks/useSidebarItems";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { Button } from "../../../../components/Button";
import AppSelector from "./AppSelector";
import * as S from "./styles";
import { useSidebarContext } from "./context/sidebar.context";
import NavItems from "./NavItems";
import ProfileMenu from "./Profile/ProfileMenu";
import GetStartedLink from "./GetStartedLink";

const Logo = lazy(() => import("assets/images/logo-full.svg"));
const MenuClosed = lazy(() => import("assets/images/menu_closed.svg"));

const Sidebar = () => {
  const sidebarItems = useSidebarItems();
  const { isExpanded, toggleIsExpanded } = useSidebarContext();
  const { isSubscribed } = useAppDataContext();

  const openCommandBar = useCallback(() => window.CommandBar?.open(), []);

  return (
    <S.Container $isExpanded={isExpanded}>
      <div tw="flex flex-col overflow-hidden px-3 py-5">
        {isExpanded ? (
          <div tw="flex justify-between items-center p-2 pl-0 mb-3 cursor-pointer h-10">
            <SuspenseWrapper>
              <Logo />
            </SuspenseWrapper>
            <div
              onClick={toggleIsExpanded}
              role="button"
              tabIndex={0}
              aria-hidden="true"
            >
              <S.SidebarHint hintText="Collapse">
                <MenuOpen tw="w-6! h-6!" />
              </S.SidebarHint>
            </div>
          </div>
        ) : (
          <S.SidebarHint hintText="Expand">
            <Button
              leftIcon={
                <SuspenseWrapper>
                  <MenuClosed tw="w-6! h-6!" />
                </SuspenseWrapper>
              }
              onClick={toggleIsExpanded}
              buttonStyle="transparent"
              tw="mb-3 p-2 w-full"
            />
          </S.SidebarHint>
        )}
        <AppSelector />
        {!isSubscribed && <GetStartedLink />}
        <NavItems items={sidebarItems} />
      </div>
      <div className="flex-1" />
      <S.SubContainer $isExpanded={isExpanded}>
        {isExpanded ? (
          <button
            type="button"
            onClick={openCommandBar}
            className="flex items-center justify-between rounded-lg py-2 px-3 bg-white border border-app-gray100 text-body-sm h-10"
          >
            <div className="flex items-center">
              <Search tw="mr-2 w-5! h-5!" />
              Find anything
            </div>
            ⌘ + K
          </button>
        ) : (
          <S.SidebarHint hintText="Find anything">
            <button
              type="button"
              onClick={openCommandBar}
              className="flex items-center rounded-lg p-2 bg-white border border-app-gray100 h-10"
            >
              <Search tw="w-5! h-5!" />
            </button>
          </S.SidebarHint>
        )}
      </S.SubContainer>

      <ProfileMenu />
    </S.Container>
  );
};

export default Sidebar;
