import React, { useState } from "react";
import Input from "components/Input";
import Select from "components/Select";
import { Textarea, Button, CodeBlock, ErrorMessage, Link } from "components";
import { CustomContent, CustomContentType } from "generatedTypes";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { html } from "@codemirror/lang-html";

function validateHTML(_html) {
  const doc = new DOMParser().parseFromString(_html, "text/html");
  return doc.body.innerHTML === _html;
}

const contentTypeOptions = [
  { label: "HTML", value: CustomContentType.Html },
  { label: "LINK", value: CustomContentType.Link },
];

const defaultEntry = {
  type: { label: "HTML", value: CustomContentType.Html },
  name: "",
  content: "",
};

type EntryType = Omit<CustomContent, "restrictedUrlGroupId">;

type OptionType = {
  label: string;
  value: CustomContentType;
};

type UpdateEntryType = Omit<
  CustomContent,
  "id" | "restrictedUrlGroupId" | "type"
> & {
  type: OptionType;
};

interface EntryInputProps {
  updateEntry?: (entry: UpdateEntryType) => void;
  removeEntry?: () => void;
  editMode?: boolean;
  reset?: () => void;
  addEntry?: (entry: EntryType) => void;
  currentEntry: EntryType;
  isEntriesAvailable?: boolean;
}

export const EntryInput = ({
  updateEntry,
  removeEntry,
  editMode = false,
  reset,
  addEntry,
  currentEntry,
  isEntriesAvailable,
}: EntryInputProps) => {
  const [entry, setEntry] = useState(currentEntry);

  // check if entry is valid before enabling the add button
  const isEntryValid = !entry.name || !entry.content;

  const contentTypeValue: OptionType =
    contentTypeOptions.find((option) => option.value === entry.type) ||
    (entry.type as unknown as OptionType);

  // check if any entry value has changed from currentEntry to determine if the save button should be enabled
  const hasEntryChanged =
    entry.name !== currentEntry.name ||
    entry.content !== currentEntry.content ||
    contentTypeValue.value !== currentEntry.type;

  const isHtmlValid =
    contentTypeValue.value === CustomContentType.Html &&
    validateHTML(entry.content);

  return (
    <div
      className={mergeClassNames(
        "border-b pb-3",
        isEntriesAvailable ? "mt-4" : "mt-2"
      )}
    >
      <div className="flex items-start w-full mb-2">
        <Select
          options={contentTypeOptions}
          preselectValue
          label="Content Type"
          className="w-36"
          // @ts-ignore
          value={contentTypeValue}
          onChange={(value) => {
            setEntry({ ...entry, type: value });
          }}
        />
        <Input
          label="Name"
          placeholder="content-name"
          hintText="For internal use only"
          className="ml-2 w-3/4"
          required
          value={entry.name}
          onChange={(e) => {
            setEntry({ ...entry, name: e.target.value });
          }}
        />
      </div>
      {contentTypeValue.value === CustomContentType.Html ? (
        <>
          <CodeBlock
            extensions={[html()]}
            placeholder="<div> HTML code goes here </div>"
            value={entry.content}
            enableCopy={false}
            minHeight="100px"
            editable
            onChange={(value) => {
              setEntry({ ...entry, content: value });
            }}
            description={
              <>
                Valid HTML code.{" "}
                <Link
                  to="https://docs.memberstack.com/hc/en-us/articles/15074205669403"
                  target="_blank"
                  isExternal
                  underline
                  tw="text-app-gray600"
                >
                  Tutorial video
                </Link>
              </>
            }
          />
          {!isHtmlValid && (
            <ErrorMessage
              message="Not valid HTML. Please check before going live"
              showIcon
            />
          )}
        </>
      ) : (
        <Textarea
          rows={1}
          placeholder="Add link here"
          required
          value={entry.content}
          onChange={(e) => {
            setEntry({ ...entry, content: e.target.value });
          }}
        />
      )}
      {editMode ? (
        <div className="flex items-center w-full justify-between mt-2">
          <Button
            text="Delete"
            buttonStyle="danger"
            onClick={() => removeEntry()}
          />
          <div className="flex items-center">
            <Button
              text="Cancel"
              buttonStyle="skeleton"
              onClick={reset}
              className="mr-2"
            />
            <Button
              text="Save"
              buttonStyle="default"
              onClick={() => {
                const formattedEntry = {
                  ...entry,
                  type: contentTypeValue,
                };
                updateEntry(formattedEntry);
              }}
              isDisabled={!hasEntryChanged}
            />
          </div>
        </div>
      ) : (
        <div className="flex w-full justify-end mt-2">
          <Button
            text="Add Content"
            buttonStyle="default"
            isDisabled={isEntryValid}
            onClick={() => {
              // const formattedEntry = {
              //   ...entry,
              //   type: contentTypeValue,
              // };
              // updateEntry(formattedEntry);
              addEntry(entry);
              // @ts-ignore
              setEntry(defaultEntry);
            }}
          />
        </div>
      )}
    </div>
  );
};
