import { PRICE_IDS } from "constants/billingPriceIds";

const getCurrentDomain = () => window.location.href.split("/apps")[0];

export const getBillingPriceIds = (priceId?: string) => {
  const { app, development } = PRICE_IDS;

  const monthlyPlanIds = [
    app.basic.monthly,
    app.professional.monthly,
    development.basic.monthly,
    development.professional.monthly,
  ];

  // eslint-disable-next-line no-nested-ternary
  const interval = priceId ? monthlyPlanIds.includes(priceId) ? "monthly" : "yearly" : "yearly";

  const domain = getCurrentDomain();

  const prices = domain.includes("app.memberstack.com") ? app : development;
  return { interval, prices };
};
