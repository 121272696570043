import React from "react";
import tw, { styled, css } from "twin.macro";
import CheckIcon from "@mui/icons-material/Check";
import Text from "components/Text";

export const StackItemContainer = styled.div<{ $active: boolean }>(() => [
  tw`flex flex-1 justify-center items-center p-3 rounded cursor-pointer`,
  css`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
      inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #ebebeb;
  `,
  ({ $active }) => $active && tw`text-white bg-app-blue400`,
]);

export const StackItem = ({ label, selected, onClick }) => (
  <StackItemContainer $active={selected} onClick={onClick}>
    {selected ? <CheckIcon tw="!h-4 !w-4 mr-1" /> : null}
    <Text noMargin type="utilityMedium">
      {label}
    </Text>
  </StackItemContainer>
);

export const Background = styled.div(() => [
  css`
    display: flex;
    justify-content: center;
    border-left: 1px solid #ebebeb;
    background-image: #ffffff;
    background-image: radial-gradient(
        at 0% 19%,
        hsla(22, 100%, 92%, 0.4) 0,
        transparent 58%
      ),
      radial-gradient(at 0% 82%, hsla(183, 90%, 82%, 0.4) 0, transparent 57%),
      radial-gradient(at 88% 0%, hsla(212, 100%, 85%, 0.4) 0, transparent 81%),
      radial-gradient(at 90% 95%, hsla(183, 89%, 72%, 0.4) 0, transparent 46%);
  `,
  tw`flex flex-1`,
]);
