/* eslint-disable react/display-name */
import React, { lazy, useEffect, useMemo, Suspense } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useParams,
} from "react-router-dom";
import { getId, setIds } from "helpers/cacheIds";
import { RouteName } from "enums/routes";
import { Plans } from "containers/Plans";
import { PrivateLayout } from "containers/Layout/PrivateLayout";
import { useToastAlertContext } from "components/toastAlert";

import { AppDataContextProvider } from "./AppDataContextProvider";

// Lazy-loaded components
// const Plans = lazy(() => import("containers/Plans"));
const Members = lazy(() => import("containers/Members"));
const Settings = lazy(() => import("containers/Settings"));
const Devtools = lazy(() => import("containers/Devtools"));
const Components = lazy(() => import("containers/Components"));
const GettingStarted = lazy(() => import("containers/GettingStarted"));
const WebhookSettings = lazy(
  () => import("containers/Devtools/Webhooks/WebhookSettings")
);
const Dashboard = lazy(() => import("containers/Dashboard/Dashboard"));
const Apps = lazy(() => import("./CreateNewApp"));
const Permissions = lazy(() => import("containers/Permissions"));
const GatedContent = lazy(() => import("containers/GatedContent"));
const PageNotFound = lazy(() => import("containers/PageNotFound"));
const EventLog = lazy(() => import("containers/EventLog/EventLog"));
const Community = lazy(() => import("containers/Community/Community"));

const IdHelper = ({ children }) => {
  const { appId } = useParams<{ appId: string }>();

  useEffect(() => {
    setIds({ appId });
  }, [appId]);

  return children;
};

const AppRoutes = () => {
  const { path } = useRouteMatch();
  const lastAppId = getId("appId");
  const { createToastAlert } = useToastAlertContext();
  const fromWebflow = new URLSearchParams(window.location.search).get(
    "fromWebflow"
  );

  const webflowSuccess = new URLSearchParams(window.location.search).get(
    "webflowSuccess"
  );

  const message = new URLSearchParams(window.location.search).get("message");

  useEffect(() => {
    if (message) {
      createToastAlert({ message, alertType: "error" });
    }
  }, [message]);

  const renderComponent = useMemo(() => {
    if (fromWebflow && webflowSuccess && lastAppId) {
      return () => (
        <Redirect
          to={`/apps/${lastAppId}/${
            RouteName.members
          }?fromWebflow=${fromWebflow}&webflowSuccess=${webflowSuccess}${
            message ? `&message=${message}` : ""
          }`}
        />
      );
    }
    if (lastAppId) {
      return () => <Redirect to={`/apps/${lastAppId}/${RouteName.members}`} />;
    }
    return Apps;
  }, [fromWebflow, lastAppId, message, webflowSuccess]);

  const pages = [
    {
      exact: true,
      path,
      Component: renderComponent,
    },
    {
      exact: true,
      path: `${path}/:appId`,
      AuthComponent: () => <Redirect to={`/${RouteName.members}`} />,
    },
    {
      exact: true,
      path: `${path}/:appId/${RouteName.gettingStarted}`,
      AuthComponent: GettingStarted,
      options: {
        blank: true,
      },
    },
    {
      exact: true,
      path: `${path}/:appId/${RouteName.dashboard}`,
      AuthComponent: Dashboard,
      options: {
        blank: true,
        overflowMain: true,
      },
    },
    {
      exact: false,
      path: `${path}/:appId/${RouteName.members}/:memberId`,
      AuthComponent: Members,
      options: {
        blank: true,
      },
    },
    {
      exact: true,
      path: `${path}/:appId/${RouteName.members}`,
      AuthComponent: Members,
      options: {
        blank: true,
      },
    },
    {
      exact: false,
      path: `${path}/:appId/plan/:planId`,
      AuthComponent: Plans,
    },
    {
      exact: false,
      path: `${path}/:appId/plans`,
      AuthComponent: Plans,
    },
    {
      exact: false,
      path: `${path}/:appId/${RouteName.community}`,
      AuthComponent: Community,
    },
    {
      exact: false,
      path: `${path}/:appId/${RouteName.settings}`,
      AuthComponent: Settings,
      options: {
        overflowMain: true,
      },
    },
    {
      exact: false,
      path: `${path}/:appId/${RouteName.editor}`,
      AuthComponent: Components,
    },
    {
      exact: true,
      path: `${path}/:appId/${RouteName.webhooks}/:webhookId`,
      AuthComponent: WebhookSettings,
    },
    {
      exact: false,
      path: `${path}/:appId/${RouteName.eventLog}`,
      AuthComponent: EventLog,
      options: {
        blank: true,
      },
    },
    {
      exact: true,
      path: `${path}/:appId/${RouteName.devtools}`,
      AuthComponent: Devtools,
      options: {
        overflowMain: true,
      },
    },
    {
      exact: false,
      path: `${path}/:appId/${RouteName.devtools}/${RouteName.permissions}`,
      AuthComponent: Permissions,
    },
    {
      exact: false,
      path: `${path}/:appId/${RouteName.gatedContent}`,
      AuthComponent: GatedContent,
      options: {
        overflowMain: false,
      },
    },
    {
      path: "*",
      AuthComponent: PageNotFound,
    },
  ];

  return (
    <Suspense fallback={null}>
      <Switch>
        {pages.map(
          ({ exact, path: routePath, AuthComponent, Component, options }) => {
            return (
              <Route key={path} exact={exact} path={routePath}>
                {Component ? (
                  <Component />
                ) : (
                  <AppDataContextProvider>
                    <PrivateLayout {...options}>
                      <IdHelper>
                        <AuthComponent />
                      </IdHelper>
                    </PrivateLayout>
                  </AppDataContextProvider>
                )}
              </Route>
            );
          }
        )}
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
