import React from "react";
import Text from "components/Text";
import { Button, Label, Link } from "components";
import { Add, Check } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";

export const AppDomainsLoader = () => (
  <>
    <span tw="flex items-center text-sm mb-2">
      <Label
        text="App Domains"
        htmlFor="App Domains"
        hint="The main URLs on which you want Memberstack to work"
      />
    </span>
    <Text type="bodySmall" tw="my-2" noMargin color="grey600">
      When you’re ready to launch, enter your live domains below. Memberstack
      will assume you’re in test mode until you do.{" "}
      <Link
        to="https://docs.memberstack.com/hc/en-us/articles/7595262385819-Managing-Test-Mode-and-Live-Mode"
        target="_blank"
        underline
        isExternal
      >
        Learn more
      </Link>
    </Text>
    <div tw="flex items-center mb-2">
      <div className="w-full">
        <Skeleton height={43} />
      </div>
      <Button
        tw="h-10 ml-2 items-center"
        leftIcon={<Check />}
        buttonStyle="skeleton"
        onClick={undefined}
      />
    </div>

    <div tw="flex items-center  mb-2">
      <div className="w-full">
        <Skeleton height={43} />
      </div>
      <Button
        tw="h-10 ml-2 items-center"
        leftIcon={<Check />}
        buttonStyle="skeleton"
        onClick={undefined}
      />
    </div>

    <div tw="flex items-center mb-2">
      <div className="w-full">
        <Skeleton height={43} />
      </div>
      <Button
        tw="h-10 ml-2 items-center"
        leftIcon={<Check />}
        buttonStyle="skeleton"
        onClick={undefined}
      />
    </div>

    <Button
      text="Add app domain"
      leftIcon={<Add />}
      buttonStyle="skeleton"
      onClick={undefined}
    />
  </>
);
