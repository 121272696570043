import { gql } from "@apollo/client";

export const CONNECT_WEBFLOW = gql`
  query GetWebflowAuthorizationUrl {
    getWebflowAuthorizationUrl
  }
`;

export const GET_WEBFLOW_SITES = gql`
  query GetWebflowSites {
    getWebflowSites {
      id
      name
      shortName
      previewUrl
    }
  }
`;

export const GET_WEBFLOW_DOMAINS = gql`
  query getWebflowDomains {
    getWebflowDomains {
      domain
    }
  }
`;

export const DEAUTHORIZE_WEBFLOW = gql`
  mutation DeauthorizeWebflow {
    deauthorizeWebflow {
      id
      session {
        acr
        connectedToWebflow
      }
    }
  }
`;

export const SET_WEBFLOW_APP = gql`
  mutation setWebflowSite($input: SetWebflowSiteInput!) {
    setWebflowSite(input: $input) {
      id
      webflowSiteId
      name
      slug
    }
  }
`;
