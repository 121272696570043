import { NavLink } from "react-router-dom";
import PilledTabs from "components/PilledTabs";
import styled, { css } from "styled-components";

export const TabLink = styled(NavLink)<{
  $isExpanded: boolean;
}>(
  ({ theme: { colors = {} }, $isExpanded }) => css`
    user-select: none;
    display: inline-flex;
    align-items: center;
    flex: ${$isExpanded ? "1 1 0%" : "none"};
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    border-bottom-width: 2px;
    padding: ${$isExpanded ? "0 0 8px 0" : "0 12px 8px 8px"};
    width: ${$isExpanded ? "25%" : "auto"};
    border-color: transparent;
    :hover {
      color: ${colors.grey700};
      border-color: ${colors.grey300};
    }
    & > svg {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
    &.active {
      border-color: ${colors.blue400};
      color: ${colors.blue400};
      > svg {
        fill: ${colors.blue400};
      }
    }
  `
);

export const TabAnchor = styled.div<{
  $active: boolean;
  $isExpanded: boolean;
}>(
  ({ theme: { colors = {} }, $active, $isExpanded }) => css`
    user-select: none;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    flex: ${$isExpanded ? "1 1 0%" : "none"};
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    border-bottom-width: 2px;
    padding: ${$isExpanded ? "0 0 8px 0" : "0 12px 8px 8px"};
    width: ${$isExpanded ? "25%" : "auto"};
    border-color: transparent;
    :hover {
      color: ${colors.grey700};
      border-color: ${colors.grey300};
    }
    & > svg {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
    ${$active &&
    css`
      border-color: ${colors.blue400};
      color: ${colors.blue400};
      :hover {
        color: ${colors.blue400};
        border-color: ${colors.blue400};
      }
      > svg {
        fill: ${colors.blue400};
      }
    `}
  `
);

export const StyledPilledTabs = styled(PilledTabs)`
  display: none;
  @media (min-width: 640px) {
    display: flex;
  }
`;
