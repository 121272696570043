import styled, { css } from "styled-components";

export const FilterMenuItem = styled.div<{ selected: boolean }>`
  align-items: center;
  background: none;
  border-radius: 6px;
  display: flex;
  font-weight: normal;
  font-size: 1em;
  padding: 8px 12px;
  text-align: left;
  width: 100%;
  font-style: normal;
  justify-content: flex-end;
  user-select: none;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: justify-content;
  transition-delay: 0;
  ${(props) =>
    props.selected &&
    css`
      justify-content: flex-start;
      transition-duration: 200ms;
      transition-property: justify-content;
      transition-delay: 200ms;
      & > div > .notification {
        display: none;
      }
    `}
  & > .chevron-left {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
  & .arrow-right {
    width: 24px;
    height: 24px;
    opacity: 1;
    transition-duration: 200ms;
    transition-property: opacity;
    transition-delay: 0;
    ${(props) =>
      props.selected &&
      css`
        opacity: 0;
        width: 0px;
        height: 0px;
        transition-duration: 200ms;
        transition-property: opacity;
        transition-delay: 200ms;
      `}
  }
`;

export const Item = styled.div<{ selected: boolean }>`
  ${({ theme: { colors }, selected }) => css`
    padding: 4px;
    background-color: white;
    display: ${!selected && "none"};
    border-radius: 6px;

    &:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  `}
`;

export const ExpandableSection = styled.section<{ expanded: boolean }>`
  ${({ theme: { colors }, expanded }) => css`
    border-radius: 6px;
    background-color: white;
    height: 0px;
    opacity: 0;
    visibility: hidden;
    top: -10px;
    transition-duration: 200ms, 200ms, 200ms, 200ms, 100ms;
    transition-property: height, padding, opacity, top, visibility;
    transition-delay: 0, 0, 200ms;
    ${expanded &&
    css`
      overflow-y: scroll;
      height: auto;
      max-height: 308px;
      opacity: 1;
      visibility: visible;
      top: 0px;
      transition-duration: 200ms, 200ms, 250ms, 200ms, 100ms;
      transition-property: height, padding, opacity, top, visibility;
      transition-delay: 200ms, 200ms, 0;
    `}
  `}
`;

export const FilterButton = styled.button(
  () =>
    ({ theme: { colors = {} } }) =>
      css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        gap: 2px;
        background: ${colors.white};
        border: 1px solid ${colors.grey200};
        border-radius: 0px 8px 8px 0px;
        height: 100%;
      `
);
