import React, { useCallback, useState, useMemo } from "react";
import useAuth from "hooks/useAuth";
import { useToastAlertContext } from "components/toastAlert";
import Text from "components/Text";
import Input from "components/Input";
import Spacer from "components/Spacer";
import { Modal, ModalTypes } from "components/Modal";
import { useDeleteAppMutation } from "features/apps";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { localStorageKeys } from "helpers/cacheIds";

const DeleteAppModal = ({ showModal, setShowModal }: ModalTypes) => {
  const [inputValue, setInputValue] = useState("");

  const { createToastAlert } = useToastAlertContext();
  const { appData } = useAppDataContext();

  const confirmationText = appData ? appData.name.trim() : "";

  const { logout } = useAuth();

  const [deleteApp, { loading }] = useDeleteAppMutation({
    variables: {
      input: {
        appId: appData?.id,
      },
    },
  });

  const appId = window.location.pathname
    .split("/")
    .find((val) => val.includes("app_"));
  const { currentUser } = useUsersContext();

  const appConnections = useMemo(
    () => currentUser?.appConnections || [],
    [currentUser?.appConnections]
  );
  const appConnectionsFiltered = appConnections.filter(
    (appConnection) =>
      appConnection.app.id !== appId && appConnection.app.status !== "DELETED"
  );

  const handleOnChange = useCallback(
    (event) => setInputValue(event.target.value),
    []
  );

  const handleDeleteApp = useCallback(async () => {
    createToastAlert({ processing: true });

    try {
      await deleteApp();
      createToastAlert({
        alertType: "success",
        message: "App was successfully deleted.",
      });
      if (appConnectionsFiltered.length > 1) {
        window.location.href = `/apps/${appConnections[0].app.id}/getting-started`;
      } else {
        localStorage.removeItem(localStorageKeys.appId);
        window.location.href = "/apps";
      }
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: err.message || "App could not be deleted.",
      });
    } finally {
      setShowModal(false);
    }
  }, [
    createToastAlert,
    deleteApp,
    setShowModal,
    appConnectionsFiltered,
    appConnections,
  ]);

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Delete App"
      showDivider
      actionButtons={{
        confirm: {
          label: "Proceed with deletion",
          onConfirm: handleDeleteApp,
          isLoading: loading,
          isDisabled: confirmationText !== inputValue,
          buttonStyle: "danger",
        },
      }}
    >
      <Text className="mb-2">
        You are deleting your application named
        <strong> {appData?.name} </strong>. Once you delete the application, it
        cannot be restored.
      </Text>
      <Spacer spacing="medium" />
      <Text className="mb-1">
        To confirm, please type <strong> {confirmationText} </strong> below:
      </Text>
      <Input
        value={inputValue}
        onChange={handleOnChange}
        hideIndicators
        placeholder={confirmationText}
      />
    </Modal>
  );
};

export default DeleteAppModal;
