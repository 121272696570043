import React, { useMemo } from "react";
import { PlayCircleOutlined } from "@mui/icons-material";

import styled from "styled-components";
import EmptyStateBg from "assets/images/empty_state_bg.png";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import { EnvSwitcher } from "../Switch";
import { Button } from "../Button";
import { Link } from "../Link";

export const EmptyContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 120px auto;
  & > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 56px;
    & > p {
      width: 378px;
    }
  }
`;

export const EmptyStateContainer = styled.div<{ showSVG: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 36px 40px;
  background-color: rgba(246, 246, 246, 1);
  & > .label {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 4px;
  }
  & > .description {
    font-size: 14px;
    line-height: 24px;
    color: rgba(130, 130, 130, 1);
    text-align: center;
    max-width: 440px;
  }
  & > .buttons {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
  }
  // conditional add background image based on showSVG prop
  ${(props) =>
    props.showSVG
      ? `
    background-image: url(${EmptyStateBg});
  `
      : `
    background-color: #FBFBFB;
  `}
`;

interface Props {
  text: string;
  description?: string;
  buttonText?: string;
  onCreateClick?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onDemoClick?: () => void;
  isFullScreen?: boolean;
  svgImage?: React.ReactNode;
  showEnvSwitcher?: boolean;
  docsLink?: string;
  className?: string;
  id?: string;
  showSVGOnCompact?: boolean;
}

export const EmptyState = ({
  text,
  description,
  buttonText,
  onCreateClick = undefined,
  onDemoClick = undefined,
  isFullScreen = false,
  svgImage,
  showEnvSwitcher = false,
  docsLink,
  className,
  id,
  showSVGOnCompact = true,
}: Props) => {
  const rightPlugin = useMemo(() => {
    if (showEnvSwitcher) return <EnvSwitcher />;
    if (onDemoClick)
      return (
        <Button
          text="Watch Tutorial"
          onClick={onDemoClick}
          buttonStyle="transparent"
          leftIcon={<PlayCircleOutlined />}
        />
      );
    if (docsLink)
      return (
        <Link
          className="flex items-center"
          isExternal
          to={docsLink}
          target="_blank"
          font="regular"
        >
          <Button
            text="View Help Center"
            onClick={onDemoClick}
            buttonStyle="transparent"
          />
        </Link>
      );
    return null;
  }, [docsLink, onDemoClick, showEnvSwitcher]);

  if (isFullScreen) {
    return (
      <EmptyContentContainer className={className} id={id}>
        <div>
          <p className="font-bold text-base mb-1">{text}</p>
          <p className="text-body-sm text-app-gray500">{description}</p>
          {buttonText && (
            <div className="flex items-center gap-3 mt-4">
              <Button
                text={buttonText}
                onClick={onCreateClick}
                className="w-min"
              />
              {rightPlugin}
            </div>
          )}
        </div>
        {svgImage && <SuspenseWrapper>{svgImage}</SuspenseWrapper>}
      </EmptyContentContainer>
    );
  }
  return (
    <EmptyStateContainer
      className={className}
      id={id}
      showSVG={showSVGOnCompact}
    >
      <p className="label">{text}</p>
      <p className="description">{description}</p>
      {buttonText && (
        <div className="buttons">
          <Button text={buttonText} onClick={onCreateClick} className="w-min" />
          {rightPlugin}
        </div>
      )}
    </EmptyStateContainer>
  );
};
