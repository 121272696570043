import React from "react";
import { PlansRoutes } from "routes/PlansRoutes";
import EditPlan from "./Plan/edit-plan/EditPlan";
import { PlansContextProvider } from "./context/Plans.context";

export const Plans = () => {
  return (
    <PlansContextProvider>
      <PlansRoutes />
    </PlansContextProvider>
  );
};

export { PlansPage } from "./Plans";
export { PlanDetails } from "./Plan/Plan";
export { EditPlan };
export { CreatePlan } from "./Plan/create-plan/CreatePlan";
