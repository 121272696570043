import { styled } from "twin.macro";
import { InsertEmoticonOutlined } from "@mui/icons-material";
import { colors } from "styles";

export const Icon = styled(InsertEmoticonOutlined)`
  fill: ${colors.black} !important;
`;

export const EmojiStyleWrapper = styled.div`
  & .emoji-group::before {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 20px;
    font-size: 10px !important;
  }
  & .content-wrapper::before {
    content: "" !important;
  }

  & .emoji-img {
    height: 16px !important;
    width: 16px !important;
  }
`;

export const PickerContainer = styled.div`
  & .emoji-mart-scroll {
  }
  & .emoji-mart > .emoji-mart-bar:last-child {
    display: none !important;
  }
`;
