import React, { useMemo } from "react";
import { PlanConnectionStatus, PlanConnection } from "generatedTypes";
import { Tooltip } from "components/Tooltip";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Group } from "@mui/icons-material";
import { PLAN_CONNECTION_STATUS_MAPPING } from "../../constants/plans";
import { fontStyleMap } from "../../constants/fontStyleMap";
import * as S from "./styles";

interface Props {
  status?: PlanConnectionStatus;
  className?: string;
  planName?: string;
  textSize?: keyof typeof fontStyleMap;
  overflow?: PlanConnection[];
  showTooltip?: boolean;
  showMemberIcon?: boolean;
}

const PlanStatusTag = ({
  className,
  planName,
  status,
  textSize,
  overflow,
  showTooltip = true,
  showMemberIcon = false,
}: Props) => {
  const transformStatus = useMemo(
    () => <span className="capitalize">{status?.toLocaleLowerCase()}</span>,
    [status]
  );

  const isOverflow = overflow?.length > 0;

  if (isOverflow) {
    const tooltipContent = overflow.map(
      ({ status: planStatus, plan, team }) => (
        <div
          key={plan.id}
          className="capitalize flex flex-row items-center gap-1"
        >
          <S.Orb
            statusColor={PLAN_CONNECTION_STATUS_MAPPING[planStatus].color}
            className="orb"
          />
          <p>{plan.name}</p>
          {team?.isTeamPlan && <Group />}
        </div>
      )
    );
    return (
      <Tooltip
        content={showTooltip ? tooltipContent : null}
        showArrow
        placement="top"
      >
        <S.StatusTag
          $fontSize={textSize}
          className={mergeClassNames("plans-status", className)}
          $isOverflow={isOverflow}
        >
          +{overflow.length} more
        </S.StatusTag>
      </Tooltip>
    );
  }

  const { name, color } = PLAN_CONNECTION_STATUS_MAPPING[status];

  return (
    <Tooltip
      content={showTooltip ? transformStatus : null}
      showArrow
      placement="top"
    >
      <S.StatusTagContainer>
        <S.StatusTagText
          $fontSize={textSize}
          className={mergeClassNames("plans-status", className)}
          $isOverflow={isOverflow}
          $showMemberIcon={showMemberIcon}
        >
          <S.Orb statusColor={color} className="orb" />
          <span className="truncate max-w-[160px]">{planName || name}</span>
        </S.StatusTagText>
        {showMemberIcon && (
          <div className="p-1.5 flex">
            <Group
              className="text-app-gray400"
              style={{ fontSize: 16 }}
              data-testid="group-icon"
            />
          </div>
        )}
      </S.StatusTagContainer>
    </Tooltip>
  );
};

PlanStatusTag.defaultProps = {
  className: "",

  planName: undefined,
  textSize: "utilitySmall",
};

export default PlanStatusTag;
