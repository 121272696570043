import { Button, Card, Checkbox, Link, Switch, Textarea } from "components";
import Select from "components/Select";
import { useUpdateGlobalCommentSettingsMutation } from "features/community/mutations.generated";
import { useGetCommentSettingsQuery } from "features/community/queries.generated";
import { GlobalCommentSettings } from "generatedTypes";
import useAppNavigator from "hooks/useAppNavigator";
import React, { useEffect, useMemo, useState } from "react";
import { isDeepObjectEqual } from "helpers/isDeepObjectEqual";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { Description } from "components/Select/styles";
import { RadioButtonGroup } from "components/RadioButton";
import cn from "helpers/cn";
import SettingsLoader from "./SettingsLoader";

const aiThresholdOptions = [
  {
    label: "Most strict - A Spam free community.",
    value: 4,
  },
  { label: "Default - Reduce moderation time.", value: 6 },
  {
    label: "Least strict - Give commenters the benefit of the doubt.",
    value: 9,
  },
];

interface SettingsCardProps {
  title?: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}
const SettingsCard = ({
  title,
  description,
  children,
  className,
}: SettingsCardProps) => (
  <Card className={cn("p-5", className)} hideShadow={false} hideBorder={false}>
    {title && <p className="text-body-sm font-bold">{title}</p>}
    {description && (
      <p className="text-body-sm text-app-gray600">{description}</p>
    )}
    <div className="flex flex-col gap-1.5 mt-4">{children}</div>
  </Card>
);

type Props = {
  settings?: Omit<GlobalCommentSettings, "__typename">;
};

const General = ({ settings }: Props) => {
  const [formValues, setFormValues] = useState({ ...settings });

  const { createToastAlert } = useToastAlertContext();

  const { setHasUnsavedChanges } = useAppNavigator();

  const isFormChanged = useMemo(
    () => isDeepObjectEqual(settings, formValues),
    [formValues, settings]
  );

  useEffect(() => {
    setHasUnsavedChanges(isFormChanged);
  }, [isFormChanged, setHasUnsavedChanges]);

  const [updateSettings, { loading }] = useUpdateGlobalCommentSettingsMutation({
    variables: {
      input: {
        ...formValues,
      },
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          getGlobalCommentSettings() {
            return data?.updateGlobalCommentSettings;
          },
        },
      });
    },
    onCompleted() {
      createToastAlert({
        alertType: "success",
        message: "Settings updated successfully.",
      });
    },
    onError(error) {
      createToastAlert({
        alertType: "error",
        message: "Unable to update settings. Please try again.",
      });
    },
  });

  const handleUpdate = async () => {
    createToastAlert({ processing: true });
    await updateSettings();
  };

  /** variable that store aiThreshold value by checking a range of values,
   * if range is 1-5 then it is most strict,
   * if range is 6-8 then it is default,
   * if range is 9-10 then it is least strict
   * */
  const aiThresholdValue = useMemo(() => {
    if (formValues.aiSpamThreshold >= 1 && formValues.aiSpamThreshold <= 5) {
      return aiThresholdOptions[0];
    }
    if (formValues.aiSpamThreshold >= 6 && formValues.aiSpamThreshold <= 8) {
      return aiThresholdOptions[1];
    }
    if (formValues.aiSpamThreshold >= 9 && formValues.aiSpamThreshold <= 10) {
      return aiThresholdOptions[2];
    }
    return aiThresholdOptions[1];
  }, [formValues.aiSpamThreshold]);

  return (
    <form className="w-full flex flex-col overflow-hidden">
      <div className="flex justify-between items-center p-5 border-b">
        <h4 className="text-base">General Settings</h4>
        <Button
          text="Save"
          isLoading={loading}
          onClick={handleUpdate}
          isDisabled={!isFormChanged}
        />
      </div>
      <div className="flex-1 overflow-auto p-7 bg-gray-50 border-b">
        <SettingsCard
          title="Content Approval"
          description="Choose how and when you want to publish end-user content."
        >
          <Checkbox
            id="moderateAllComments"
            label="Hold all comments for review"
            checked={formValues.moderateAllComments}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                moderateAllComments: e.target.checked,
              });
            }}
          />
          {/* <Checkbox
              id="moderateNewMembers"
              label="Hold all new-users posts for review (must have atleast 1 approved post)"
              checked={formValues.moderateNewMembers}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  moderateNewMembers: e.target.checked,
                });
              }}
            /> */}
        </SettingsCard>
        <SettingsCard
          className="mt-4"
          title="Restricted Words & Phrases"
          description={
            <>
              Comments with restricted words will be flagged for moderation.{" "}
              <Link
                isExternal
                rel="noreferrer"
                underline
                to="https://docs.memberstack.com/hc/en-us/articles/20269774291227-Restricted-Words-and-Phrases-Commenting"
              >
                Learn more.
              </Link>
            </>
          }
        >
          <RadioButtonGroup
            name="words"
            label=""
            onChange={(value) => {
              setFormValues({
                ...formValues,
                useDefaultRestrictedWords: value === "on",
              });
            }}
            selectedValue={formValues.useDefaultRestrictedWords ? "on" : "off"}
            gap={16}
            labelClassName="font-bold"
            options={[
              {
                label: "Use default restricted words",
                value: "on",
                description: (
                  <>
                    Use default restricted words.&nbsp;
                    <Link
                      showAsLink
                      isExternal
                      rel="noreferrer"
                      to="https://docs.memberstack.com/hc/en-us/articles/20269774291227"
                    >
                      View words
                    </Link>
                  </>
                ),
              },
              {
                label: "Use your own restricted words",
                value: "off",
                description: "Enter a comma separated list of your own words.",
              },
            ]}
          />

          {!formValues.useDefaultRestrictedWords && (
            <div className="mt-4">
              <Textarea
                placeholder="Enter words or phrases"
                rows={4}
                value={formValues.restrictedWords}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    restrictedWords: e.target.value,
                  });
                }}
              />
              <p className="text-body-sm text-app-gray600">
                Enter as a comma separated list. Does not include{" "}
                <Link
                  showAsLink
                  isExternal
                  rel="noreferrer"
                  to="https://docs.memberstack.com/hc/en-us/articles/20269774291227"
                >
                  default words
                </Link>
                .
              </p>
            </div>
          )}
        </SettingsCard>
        <SettingsCard className="mt-4" title="Filter Spam">
          <Switch
            className="mb-2.5"
            label="Enable Al Moderation"
            id="aiModerationEnabled"
            isChecked={formValues.aiModerationEnabled}
            onChange={(checked) => {
              setFormValues({
                ...formValues,
                aiModerationEnabled: checked,
              });
            }}
            description={
              <Description $show animate={false} size="md">
                By default, we run all new comments through an Al spam filter.
              </Description>
            }
          />
          {/* <Textarea
            label="Describe Your Community to the Al"
            description="Who's in your community and what are their goals? 250 words or less"
            placeholder="Enter description"
            rows={4}
            hideIndicator
            value={formValues.communityDescription}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                communityDescription: e.target.value,
              });
            }}
          /> */}
          <Select
            id="aiSpamThreshold"
            description="What is your top priority when it comes to spam filtering?"
            label="Sensitivity"
            alwaysShowDescription
            options={aiThresholdOptions}
            value={aiThresholdValue}
            onChange={(selectedOptions) => {
              setFormValues({
                ...formValues,
                aiSpamThreshold: selectedOptions.value,
              });
            }}
          />
        </SettingsCard>
      </div>
    </form>
  );
};

const Wrapper = () => {
  const { loading, data, error } = useGetCommentSettingsQuery();

  if (loading) return <SettingsLoader />;
  if (error) return <div>{error.message}</div>;

  const { getGlobalCommentSettings } = data;

  // remove __typename from getGlobalCommentSettings and set it to settings

  if (!getGlobalCommentSettings) return null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...rest } = getGlobalCommentSettings;

  return <General settings={rest} />;
};

export default Wrapper;
