import React from "react";
import { ChevronLeft } from "@mui/icons-material";
import { paddingSizes } from "styles/theme/variables";
import SearchBar from "components/SearchBar";
import { Link } from "../Link/Link";
import Section from "./Section";

export interface TitleSectionProps {
  title?: React.ReactNode;
  titleHint?: React.ReactNode;
  backLink?: string;
  backLinkTitle?: React.ReactNode;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
  gutter?: keyof typeof paddingSizes | "none";
  divider?: boolean;
  emphasize?: boolean;
  truncateChar?: number;
  searchString?: string;
  setSearchString?: (value: string) => void;
}

const TitleSection = ({
  title,
  titleHint,
  backLink = undefined,
  backLinkTitle,
  buttons,
  children,
  gutter = "medium",
  divider,
  emphasize,
  truncateChar = 28,
  searchString = undefined,
  setSearchString = undefined,
}: TitleSectionProps) => {
  const hasSearch = searchString !== undefined && setSearchString;
  return (
    <Section gutter={gutter} divider={divider}>
      {backLink && (
        <Link
          to={`${backLink ? `${backLink}` : ""}`}
          type="button"
          className="flex self-start items-center mb-1"
        >
          <ChevronLeft style={{ fontSize: "16px" }} />
          <span tw="truncate max-w-[28ch]">{backLinkTitle}</span>
        </Link>
      )}
      <div className="flex flex-row justify-between">
        <h3
          className={
            emphasize
              ? "text-h3 font-bold flex items-center"
              : "text-h4 font-normal flex items-center"
          }
        >
          <span tw="truncate max-w-[20ch]">{title}</span>
        </h3>
        <div className="flex justify-between items-center">{buttons}</div>
      </div>
      {titleHint && (
        <p className="text-app-gray600 text-body-sm">{titleHint}</p>
      )}
      {hasSearch && (
        <SearchBar
          value={searchString}
          onChange={(value) => setSearchString(value)}
          className="mt-4"
        />
      )}
      {children && <div className="mb-4">{children}</div>}
    </Section>
  );
};

TitleSection.defaultProps = {
  title: "",
  titleHint: "",
  backLink: "",
  backLinkTitle: "",
  buttons: [],
  children: null,
  gutter: "medium",
  divider: true,
  emphasize: false,
};

export default TitleSection;
