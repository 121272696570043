import React from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Input from "components/Input";

interface Props {
  color: string;
  imageSrc: string;
}

const CreateAccount = ({ color, imageSrc }: Props) => (
  <div className="shadow-lg rounded-2xl bg-white w-96 border">
    <div className="p-7 pb-0">
      <div className="pb-2 flex justify-center">
        <img src={imageSrc} height={43} tw="h-[43px]" alt="app-logo" />
      </div>
      <h2 tw="text-h2 font-bold mb-10 text-center">Create an Account</h2>
      <Input
        label="Email Address"
        placeholder="Enter your email address"
        className="mb-5"
        hideIndicators
        disabled
      />
      <Input
        label="Password"
        placeholder="Enter a strong password"
        className="mb-5"
        hideIndicators
        disabled
      />
      <button
        id="sign-up-button"
        className="mb-2 w-full font-bold text-white p-3 rounded-lg text-base"
        type="button"
        style={{ backgroundColor: color }}
      >
        Sign up
      </button>
      <p className="text-body-sm font-bold text-center pb-5">
        Already have an account?{" "}
      </p>
    </div>
    <div className="bg-app-gray50 flex items-center justify-center text-body-sm font-bold p-3 rounded-b-2xl">
      <VerifiedUserIcon tw="mr-1 w-3.5 h-3.5" /> Secured by Memberstack
    </div>
  </div>
);

export default CreateAccount;
