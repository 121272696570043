import React from "react";
import { ChevronRight, HandymanOutlined } from "@mui/icons-material";
import { OptionType } from "components/Select";
import styled from "styled-components";
import { Link } from "../Link/Link";
import { InstallationInput, InstallationSelect } from "./InstallationInput";

const Label = styled.label`
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #d9d9d9;
  align-self: center;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 8px;
`;

interface Props {
  description?: string;
  docsLink?: string;
  nameValue?: string;
  nameLabel?: string;
  idValue?: string;
  idLabel?: string;
  showMoreInfo?: boolean;
  className?: string;
  nameSelectInfo?: {
    options: OptionType[];
  };
  idSelectInfo?: {
    options: OptionType[];
  };
  isLinkOnly?: boolean;
  showBottomSection?: boolean;
  header?: string;
}

export const AttributeInstallationBox = ({
  description,
  docsLink,
  nameValue = "",
  nameLabel = "Name",
  idValue = "",
  idLabel = "ID",
  showMoreInfo = true,
  className,
  idSelectInfo,
  nameSelectInfo,
  isLinkOnly = false,
  showBottomSection = true,
  header,
}: Props) => {
  if (isLinkOnly) {
    return (
      <Link
        to={docsLink}
        target="_blank"
        isExternal
        tw="bg-[#4D4D4D] text-[#D9D9D9] flex items-center w-full p-4 hover:text-app-gray50 justify-between rounded"
      >
        <div className="flex items-center">
          <HandymanOutlined tw="w-6! h-6! mr-3" />
          <div className="flex flex-col">
            <p tw="font-bold text-body-sm">Installation Required</p>
          </div>
        </div>
        <ChevronRight />
      </Link>
    );
  }

  return (
    <div tw="rounded-lg flex-1 bg-[#404040]" className={className}>
      <div tw="px-4 py-3 flex-1 text-utility-tag-md text-[#D9D9D9]">
        {header && <p className="text-body-sm font-bold mb-3">{header}</p>}
        {description && (
          <p tw=" mb-4">
            {description}{" "}
            <Link
              to={docsLink}
              target="_blank"
              isExternal
              underline
              tw="text-app-gray50"
            >
              Learn more
            </Link>
          </p>
        )}
        <InputContainer>
          <Label htmlFor="installation-name">{nameLabel}</Label>
          {nameSelectInfo ? (
            <InstallationSelect
              options={nameSelectInfo.options}
              toastFeedback="Installation name was copied"
            />
          ) : (
            <InstallationInput
              name="installation-name"
              value={nameValue}
              toastFeedback="Installation name was copied"
            />
          )}
          <Label htmlFor="installation-id">{idLabel}</Label>
          {idSelectInfo ? (
            <InstallationSelect
              options={idSelectInfo.options}
              toastFeedback="Installation ID was copied"
            />
          ) : (
            <InstallationInput
              name="installation-id"
              value={idValue}
              toastFeedback="Installation ID was copied"
            />
          )}
        </InputContainer>
      </div>
      {showBottomSection && (
        <>
          <hr tw="border-[ #363636]" />
          <div className="p-3">
            <Link
              to={docsLink}
              target="_blank"
              isExternal
              tw="bg-[#4D4D4D] text-[#D9D9D9] flex items-center w-full p-3 hover:text-app-gray50 justify-between rounded"
            >
              <div className="flex items-center">
                <HandymanOutlined tw="w-7! h-7! mr-3" />
                <div className="flex flex-col">
                  <p className="font-bold text-sm">Installation Required</p>
                  {showMoreInfo && (
                    <p tw="text-sm">
                      Visit the Install Guide for more information
                    </p>
                  )}
                </div>
              </div>
              <ChevronRight />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
