import React from "react";
import Skeleton from "react-loading-skeleton";
import tw, { styled } from "twin.macro";

export const StripeText = styled.p`
  font-weight: 600;
  font-size: 10.7114px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #697386;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: 6px;
`;

export const InputPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 9px 12px;
  height: 30.6px;
  background: #ffffff;
  border: 1px solid rgba(60, 66, 87, 0.12);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
`;

export const MultiInputPlaceholder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid rgba(60, 66, 87, 0.12);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  & > hr {
    color: rgba(60, 66, 87, 0.12);
  }
`;

export const Container = styled.div(() => [
  tw`flex justify-between items-center px-[6.89px] py-[9.18px] w-full`,
]);

export const StripeInputPlaceholder = ({ label }) => (
  <>
    <StripeText>{label}</StripeText>
    <InputPlaceholder className="flex gap-1.5">
      <Skeleton width={97} height={12} />
    </InputPlaceholder>
  </>
);
