import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetClientMessageTextQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientMessageTextQuery = (
  { __typename?: 'Query' }
  & { getClientMessageText?: Types.Maybe<(
    { __typename?: 'ClientMessageText' }
    & Pick<Types.ClientMessageText, 'textObject'>
  )> }
);

export type GetSsoTextQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSsoTextQuery = (
  { __typename?: 'Query' }
  & { getSSOText?: Types.Maybe<(
    { __typename?: 'SSOText' }
    & Pick<Types.SsoText, 'textObject'>
  )> }
);

export type UpdateClientMessageTextMutationVariables = Types.Exact<{
  input: Types.UpdateClientMessageTextInput;
}>;


export type UpdateClientMessageTextMutation = (
  { __typename?: 'Mutation' }
  & { updateClientMessageText: (
    { __typename?: 'ClientMessageText' }
    & Pick<Types.ClientMessageText, 'textObject'>
  ) }
);

export type UpdateSsoTextMutationVariables = Types.Exact<{
  input: Types.UpdateSsoTextInput;
}>;


export type UpdateSsoTextMutation = (
  { __typename?: 'Mutation' }
  & { updateSSOText: (
    { __typename?: 'SSOText' }
    & Pick<Types.SsoText, 'textObject'>
  ) }
);


export const GetClientMessageTextDocument = gql`
    query GetClientMessageText {
  getClientMessageText {
    textObject
  }
}
    `;

/**
 * __useGetClientMessageTextQuery__
 *
 * To run a query within a React component, call `useGetClientMessageTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientMessageTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientMessageTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientMessageTextQuery(baseOptions?: Apollo.QueryHookOptions<GetClientMessageTextQuery, GetClientMessageTextQueryVariables>) {
        return Apollo.useQuery<GetClientMessageTextQuery, GetClientMessageTextQueryVariables>(GetClientMessageTextDocument, baseOptions);
      }
export function useGetClientMessageTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientMessageTextQuery, GetClientMessageTextQueryVariables>) {
          return Apollo.useLazyQuery<GetClientMessageTextQuery, GetClientMessageTextQueryVariables>(GetClientMessageTextDocument, baseOptions);
        }
export type GetClientMessageTextQueryHookResult = ReturnType<typeof useGetClientMessageTextQuery>;
export type GetClientMessageTextLazyQueryHookResult = ReturnType<typeof useGetClientMessageTextLazyQuery>;
export type GetClientMessageTextQueryResult = Apollo.QueryResult<GetClientMessageTextQuery, GetClientMessageTextQueryVariables>;
export const GetSsoTextDocument = gql`
    query GetSSOText {
  getSSOText {
    textObject
  }
}
    `;

/**
 * __useGetSsoTextQuery__
 *
 * To run a query within a React component, call `useGetSsoTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSsoTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSsoTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSsoTextQuery(baseOptions?: Apollo.QueryHookOptions<GetSsoTextQuery, GetSsoTextQueryVariables>) {
        return Apollo.useQuery<GetSsoTextQuery, GetSsoTextQueryVariables>(GetSsoTextDocument, baseOptions);
      }
export function useGetSsoTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSsoTextQuery, GetSsoTextQueryVariables>) {
          return Apollo.useLazyQuery<GetSsoTextQuery, GetSsoTextQueryVariables>(GetSsoTextDocument, baseOptions);
        }
export type GetSsoTextQueryHookResult = ReturnType<typeof useGetSsoTextQuery>;
export type GetSsoTextLazyQueryHookResult = ReturnType<typeof useGetSsoTextLazyQuery>;
export type GetSsoTextQueryResult = Apollo.QueryResult<GetSsoTextQuery, GetSsoTextQueryVariables>;
export const UpdateClientMessageTextDocument = gql`
    mutation UpdateClientMessageText($input: UpdateClientMessageTextInput!) {
  updateClientMessageText(input: $input) {
    textObject
  }
}
    `;
export type UpdateClientMessageTextMutationFn = Apollo.MutationFunction<UpdateClientMessageTextMutation, UpdateClientMessageTextMutationVariables>;

/**
 * __useUpdateClientMessageTextMutation__
 *
 * To run a mutation, you first call `useUpdateClientMessageTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMessageTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMessageTextMutation, { data, loading, error }] = useUpdateClientMessageTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientMessageTextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMessageTextMutation, UpdateClientMessageTextMutationVariables>) {
        return Apollo.useMutation<UpdateClientMessageTextMutation, UpdateClientMessageTextMutationVariables>(UpdateClientMessageTextDocument, baseOptions);
      }
export type UpdateClientMessageTextMutationHookResult = ReturnType<typeof useUpdateClientMessageTextMutation>;
export type UpdateClientMessageTextMutationResult = Apollo.MutationResult<UpdateClientMessageTextMutation>;
export type UpdateClientMessageTextMutationOptions = Apollo.BaseMutationOptions<UpdateClientMessageTextMutation, UpdateClientMessageTextMutationVariables>;
export const UpdateSsoTextDocument = gql`
    mutation UpdateSSOText($input: UpdateSSOTextInput!) {
  updateSSOText(input: $input) {
    textObject
  }
}
    `;
export type UpdateSsoTextMutationFn = Apollo.MutationFunction<UpdateSsoTextMutation, UpdateSsoTextMutationVariables>;

/**
 * __useUpdateSsoTextMutation__
 *
 * To run a mutation, you first call `useUpdateSsoTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSsoTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSsoTextMutation, { data, loading, error }] = useUpdateSsoTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSsoTextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSsoTextMutation, UpdateSsoTextMutationVariables>) {
        return Apollo.useMutation<UpdateSsoTextMutation, UpdateSsoTextMutationVariables>(UpdateSsoTextDocument, baseOptions);
      }
export type UpdateSsoTextMutationHookResult = ReturnType<typeof useUpdateSsoTextMutation>;
export type UpdateSsoTextMutationResult = Apollo.MutationResult<UpdateSsoTextMutation>;
export type UpdateSsoTextMutationOptions = Apollo.BaseMutationOptions<UpdateSsoTextMutation, UpdateSsoTextMutationVariables>;