import { createContext, useContext } from "react";

interface TabsContextProps {
  activeTab: string;
  setActiveTab: (value: string) => void;
  withRouter: boolean;
  showBottomBorder?: boolean;
  isExpanded?: boolean;
  onTabChange?: (tabId: string) => void;
}

const defaultValues: TabsContextProps = {
  activeTab: "",
  setActiveTab: () => {},
  withRouter: false,
  showBottomBorder: true,
  isExpanded: true,
  onTabChange: () => {},
};

export const TabsContext = createContext(defaultValues);

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error(
      "useTabsContext must be used within a TabsContext.Provider"
    );
  }
  return context;
};
