import { Accordion, AccordionItem } from "components/Accordion";
import styled, { css } from "styled-components";

export const GSAccordion = styled(Accordion)`
  border: none;
`;

export const GSAccordionItem = styled(AccordionItem)(
  ({ theme: { colors } }) => css`
    border: none;
    background-color: ${colors?.grey050};
    & > div {
      padding: 12px 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      background: ${colors?.grey050};
    }
    & > section {
      padding: none;
      background-color: ${colors?.grey050};
    }
  `
);
