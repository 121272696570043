import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "components/Modal";
import Text from "components/Text";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { useDeletePlanMutation } from "../../../features/plans/mutations.generated";
import { usePlansContext } from "../context/Plans.context";

export const DeletePlanModal = ({ showModal, setShowModal, plan, refetch }) => {
  const { appId } = useParams<{ appId: string }>();
  const navigate = useNavigate();
  const { createToastAlert } = useToastAlertContext();
  const [deletePlan, { loading }] = useDeletePlanMutation();
  const { refetchPlans } = usePlansContext();
  const [modalError, setModalError] = useState(null);

  const handleDelete = async () => {
    createToastAlert({ processing: true });
    try {
      await deletePlan({
        variables: {
          input: {
            planId: plan?.id,
          },
        },
      });

      await refetchPlans();
      setShowModal(false);
      createToastAlert({
        alertType: "success",
        message: "Plan deleted successfully.",
      });
    } catch (e) {
      setModalError(e.message);
    } finally {
      if (!loading) {
        navigate(`/apps/${appId}/plans`);
      }
    }
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Confirm deletion"
      showDivider
      actionButtons={{
        confirm: {
          label: "Delete",
          onConfirm: () => handleDelete(),
          isLoading: loading,
          buttonStyle: "danger",
          dataCy: "delete-plan-modal-confirm",
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Text>
        Are you sure you want to delete the <b>{plan?.name}</b> plan?
      </Text>
    </Modal>
  );
};
