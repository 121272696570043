import React from "react";
import { Avatar } from "components";
import { ExpandMore } from "@mui/icons-material";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import Skeleton from "react-loading-skeleton";
import { AvatarButton, SidebarHint } from "../styles";

interface Props {
  isExpanded: boolean;
  appImageSrc?: string;
  appImageAlt?: string;
  appName: string;
  showBorder?: boolean;
  showExpandIcon?: boolean;
  className?: string;
  isSubmenu?: boolean;
}

export const Trigger = ({
  isExpanded,
  appImageSrc,
  appImageAlt,
  appName,
  showBorder = false,
  showExpandIcon = true,
  className,
  isSubmenu = false,
}: Props) => {
  return (
    <AvatarButton $isExpanded={isExpanded} className={className}>
      {isExpanded ? (
        <>
          <div className="flex items-center">
            {appImageSrc && (
              <Avatar src={appImageSrc} alt={appImageAlt} className="mr-2" />
            )}
            <p
              className={mergeClassNames(
                isSubmenu ? "w-40" : "w-32",
                "text-body-sm overflow-ellipsis whitespace-nowrap overflow-hidden truncate text-left text-black font-bold"
              )}
            >
              {appName}
            </p>
          </div>
          {showExpandIcon && <ExpandMore className="text-black" />}
        </>
      ) : (
        <SidebarHint hintText={!isExpanded && "Profile"}>
          <Avatar src={appImageSrc} alt={appImageAlt} />
        </SidebarHint>
      )}
    </AvatarButton>
  );
};

export const TriggerLoading = ({ isExpanded }) => (
  <AvatarButton $isExpanded={isExpanded}>
    {isExpanded && (
      <div className="flex items-center">
        <Avatar
          src="https://ui-avatars.com/api/?background=828282&color=fff"
          alt="loading"
          className="mr-2"
        />
        <Skeleton width={128} height={24} />
      </div>
    )}
  </AvatarButton>
);
