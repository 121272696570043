import { Card } from "components/Card";
import React from "react";
import styled, { css } from "styled-components";

type StatCardType = "success" | "warning" | "danger" | "info";

const colorType = {
  primary: {
    background: "blue050",
    color: "blue900",
  },
  secondary: {
    background: "grey100",
    color: "grey900",
  },
  success: {
    background: "green050",
    color: "green700",
  },
  danger: {
    color: "red300",
    background: "red050",
  },
  warning: {
    color: "warningTagText",
    background: "tan",
  },
  info: {
    color: "blueTagText",
    background: "blueTagBg",
  },
};

const IconContainer = styled.div<{ $type: StatCardType }>(
  ({ $type, theme: { colors } }) => css`
    border-radius: 9999px;
    padding: 10px;
    background: ${colors?.[colorType?.[$type]?.background]};
    & > svg {
      color: ${colors?.[colorType?.[$type]?.color]};
    }
  `
);

interface Props {
  type: StatCardType;
  title: string;
  value: number;
  icon?: React.ReactNode;
}

export const StatCard = ({ type, title, value, icon }: Props) => (
  <Card className="gap-3 flex items-center p-3">
    <IconContainer $type={type}>{icon}</IconContainer>
    <div>
      <p className="text-utility-tag-md text-app-gray500">{title}</p>
      <h1 className="text-h4 font-bold mt-1">{value}</h1>
    </div>
  </Card>
);
