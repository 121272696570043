import { styled } from "twin.macro";
import { css } from "styled-components";

export const InputContainer = styled.div<{
  $hasFilter: boolean;
  $hasSearchBy: boolean;
}>(
  () =>
    ({ theme: { colors = {} }, $hasFilter, $hasSearchBy }) =>
      css`
        width: 100%;
        div > div {
          margin: 0;
          ${$hasSearchBy &&
          css`
            border-left-width: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            box-shadow: none;
          `}
          ${$hasFilter &&
          css`
            border-right-width: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            box-shadow: none;
          `}
        }
        .leadingInline {
          padding: 6px 0 6px 12px;
        }
        input {
          padding: 6px 8px;
        }
        input::placeholder {
          color: ${colors.grey600};
        }
      `
);
