import React from "react";
import styled, { css } from "styled-components";

type SizeT = "regular" | "large";

const Container = styled.div<{ isFullScreen: boolean; fillSpace: boolean }>`
  ${({ isFullScreen, fillSpace }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    ${isFullScreen &&
    css`
      width: 100%;
      height: 100vh;
    `}
    ${fillSpace &&
    css`
      width: 100%;
      height: 100%;
    `}
  `}
`;

const Spinner = styled.div`
  ${({ theme: { colors } }) => css`
    border-width: 0.5rem;
    border-style: solid;
    border-color: ${colors?.primary400};
    width: 3.625rem;
    height: 3.625rem;
    border-radius: 50%;
    position: relative;
    -webkit-animation: spin 1s infinite;
    animation: spin 1s infinite;

    &:before,
    &:after {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: ${colors?.primary400};
      position: absolute;
      left: 0.125rem;
    }

    &:before {
      top: 0.063rem;
    }

    &:after {
      bottom: 0.063rem;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  `}
`;

interface Props {
  size?: SizeT;
  isFullScreen?: boolean;
  fillSpace?: boolean;
}

const Loader = ({ size, isFullScreen, fillSpace }: Props) => (
  <Container fillSpace={fillSpace} isFullScreen={isFullScreen}>
    <Spinner data-testid="spinner" />
  </Container>
);

export default Loader;
