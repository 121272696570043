import { useIntl } from "react-intl";

const useTranslations = () => {
  const { formatMessage } = useIntl();
  const translate = (id, defaultMessage) =>
    formatMessage({ id, defaultMessage });
  return { translate, formatMessage };
};

export default useTranslations;
