import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import tw, { styled, css, theme } from "twin.macro";

export const InputContainer = styled.div<{
  $error: boolean;
  $inverted: boolean;
  $success: boolean;
  $width?: number;
}>(
  ({ $error, $inverted, $success, $width, theme: { colors } }) => css`
    display: flex;
    position: relative;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.06);
    border: 1px solid ${colors?.grey200};
    background-color: #fff;
    border-radius: 6px;
    &:focus-within {
      border: 1px solid ${theme`colors.app-blue500`};
      outline: 1px solid ${theme`colors.app-blue500`};
    }
    &:focus-within input {
      outline: none;
      box-shadow: none;
      border-color: ${theme`colors[app-gray300]`};
      -webkit-box-shadow: none;
    }
    ${$inverted &&
    css`
      background: #2b2b2b;
      border: 1px solid #212121;
      border-radius: 2px;
    `}
    ${$error &&
    css`
      border: 2px solid ${colors?.errorFill};
      color: ${theme`colors[error-fill]`};
      &:focus-within {
        border: 1px solid ${theme`colors[error-fill]`} !important;
        outline: 1px solid ${theme`colors[error-fill]`} !important;
      }
    `}
      ${$success &&
    css`
      border: 2px solid ${colors?.green700};
    `}
      ${$width &&
    css`
      width: ${$width}px;
    `}
  `
);

export const LabelContainer = tw.div`flex justify-between items-center mb-1`;

interface InputElementProps {
  $error: string;
  $isLeadingElement: boolean;
  $isTrailingElement: boolean;
  $leadingInline: boolean;
  $trailingInline: boolean;
  $padding: { top?: string; right?: string; bottom?: string; left?: string };
  $inverted: boolean;
}

export const variantStyles = {
  leadingOutline: tw`rounded-l-none border-l`,
  leadingInline: tw`rounded-l-none`,
  trailingOutline: tw`rounded-r-none`,
  trailingInline: tw`rounded-r-none`,
};
export const InputElement = styled.input<InputElementProps>(() => [
  ({
    $padding: { top = "6px", right = "12px", bottom = "6px", left = "12px" },
  }) => css`
    /* z-index: 1; */
    font-size: 14px;
    line-height: 24px;
    padding: ${top} ${right} ${bottom} ${left};
    border-width: 0;
    border-radius: 6px;
    background-color: white;
    width: 100%;
    display: block;
    border-color: ${(props) => props.theme.colors?.grey200};
    flex: 1 1 0%;
    min-width: 0px;
  `,
  ({ $isLeadingElement, $leadingInline }) =>
    $isLeadingElement && !$leadingInline && variantStyles.leadingOutline,
  ({ $isLeadingElement, $leadingInline }) =>
    $isLeadingElement && $leadingInline && variantStyles.leadingInline,
  ({ $isTrailingElement, $trailingInline }) =>
    $isTrailingElement && !$trailingInline && variantStyles.trailingOutline,
  ({ $isTrailingElement, $trailingInline }) =>
    $isTrailingElement && $trailingInline && variantStyles.trailingInline,
  ({ disabled }) =>
    disabled && tw`bg-app-gray50 text-app-gray300 cursor-not-allowed`,
  ({ $inverted }) =>
    $inverted &&
    css`
      background: #2b2b2b;
      color: #d9d9d9;
      padding: 8px;
      font-size: 12px;
      line-height: 20px;
    `,
]);

export const SubText = tw.p`mt-1 text-body-sm text-app-gray600`;

export const Description = styled.div<{ $show: boolean }>`
  font-size: 12px;
  line-height: 20px;
  cursor: default;
  color: ${(props) => props.theme.colors?.grey600};
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  margin-top: ${(props) => (props.$show ? "4px" : "0px")};
  transform: ${(props) =>
    props.$show ? "translateY(0px)" : "translateY(-5px)"};
  height: ${(props) => (props.$show ? "100%" : "0px")};
`;

// Error
export const ErrorIcon = tw(ErrorOutlineIcon)`h-5 w-5 text-red-500`;
export const ErrorText = tw.p`mt-2 text-sm text-red-600 font-medium`;

export const CornerHintText = styled.div<{ $show?: boolean }>`
  color: ${(props) => props.theme.colors?.grey600};
  font-size: 14px;
  line-height: 24px;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transform: ${(props) =>
    props.$show ? "translateY(0px)" : "translateY(-5px)"};
  height: ${(props) => (props.$show ? "100%" : "0px")};
`;

// Leading
export const LeadingOutlineContainer = styled.div<{
  $disablePadding?: boolean;
}>(() => [
  tw`inline-flex items-center rounded-l-md rounded-r-none! border border-r-0 border-app-gray200 bg-app-gray50 border-0 text-body-sm`,
  ({ $disablePadding }) => $disablePadding && tw`px-3 py-2`,
]);

export const LeadingInlineContainer = styled.div<{
  error: boolean;
  $disablePadding: boolean;
}>(() => [
  css`
    display: inline-flex;
    align-items: center;
    background-color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-width: 0px;
    padding: 8px 0px 8px 12px;
    & > svg {
      font-size: 16px;
    }
  `,
  ({ error }) => error && tw`border-red-300`,
  ({ $disablePadding }) =>
    $disablePadding &&
    css`
      padding: 0;
    `,
]);
export const LeadingInline = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    font-size: 14px;
    line-height: 24px;
    color: ${colors?.grey500};
    & > svg {
      font-size: 16px;
    }
  `
);

// Trailing
export const TrailingOutlineContainer = styled.div<{
  $disablePadding: boolean;
}>(
  ({ $disablePadding, theme: { colors } }) => css`
    display: flex;
    align-items: center;
    padding: ${$disablePadding ? "0px" : "4px 4px 4px 0px"};
    & > svg {
      font-size: 16px;
    }
  `
);

export const TrailingInlineContainer = styled.div<{
  error: boolean;
  $disablePadding: boolean;
}>(() => [
  css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-width: 0px;
    padding: 0px 12px 0px 0px;
    & > svg {
      font-size: 16px;
    }
  `,
  ({ error }) => error && tw`border-red-300`,
  ({ $disablePadding }) =>
    $disablePadding &&
    css`
      padding: 0;
    `,
]);
export const TrailingInline = tw.span`flex text-app-gray500 text-body-sm`;

export const TrailingIconContainer = styled.div`
  background: transparent;
  border: none;
  color: ${({ theme: themeProp }) => themeProp.colors?.grey3};
  pointer-events: auto;
  cursor: pointer;
`;

export const TrailingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
