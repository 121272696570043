import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetWebflowAuthorizationUrlQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWebflowAuthorizationUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getWebflowAuthorizationUrl'>
);

export type GetWebflowSitesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWebflowSitesQuery = (
  { __typename?: 'Query' }
  & { getWebflowSites?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'WebflowSite' }
    & Pick<Types.WebflowSite, 'id' | 'name' | 'shortName' | 'previewUrl'>
  )>>> }
);

export type GetWebflowDomainsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWebflowDomainsQuery = (
  { __typename?: 'Query' }
  & { getWebflowDomains?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'WebflowDomain' }
    & Pick<Types.WebflowDomain, 'domain'>
  )>>> }
);

export type DeauthorizeWebflowMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeauthorizeWebflowMutation = (
  { __typename?: 'Mutation' }
  & { deauthorizeWebflow: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { session?: Types.Maybe<(
      { __typename?: 'UserSession' }
      & Pick<Types.UserSession, 'acr' | 'connectedToWebflow'>
    )> }
  ) }
);

export type SetWebflowSiteMutationVariables = Types.Exact<{
  input: Types.SetWebflowSiteInput;
}>;


export type SetWebflowSiteMutation = (
  { __typename?: 'Mutation' }
  & { setWebflowSite: (
    { __typename?: 'App' }
    & Pick<Types.App, 'id' | 'webflowSiteId' | 'name' | 'slug'>
  ) }
);


export const GetWebflowAuthorizationUrlDocument = gql`
    query GetWebflowAuthorizationUrl {
  getWebflowAuthorizationUrl
}
    `;

/**
 * __useGetWebflowAuthorizationUrlQuery__
 *
 * To run a query within a React component, call `useGetWebflowAuthorizationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebflowAuthorizationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebflowAuthorizationUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebflowAuthorizationUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetWebflowAuthorizationUrlQuery, GetWebflowAuthorizationUrlQueryVariables>) {
        return Apollo.useQuery<GetWebflowAuthorizationUrlQuery, GetWebflowAuthorizationUrlQueryVariables>(GetWebflowAuthorizationUrlDocument, baseOptions);
      }
export function useGetWebflowAuthorizationUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebflowAuthorizationUrlQuery, GetWebflowAuthorizationUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetWebflowAuthorizationUrlQuery, GetWebflowAuthorizationUrlQueryVariables>(GetWebflowAuthorizationUrlDocument, baseOptions);
        }
export type GetWebflowAuthorizationUrlQueryHookResult = ReturnType<typeof useGetWebflowAuthorizationUrlQuery>;
export type GetWebflowAuthorizationUrlLazyQueryHookResult = ReturnType<typeof useGetWebflowAuthorizationUrlLazyQuery>;
export type GetWebflowAuthorizationUrlQueryResult = Apollo.QueryResult<GetWebflowAuthorizationUrlQuery, GetWebflowAuthorizationUrlQueryVariables>;
export const GetWebflowSitesDocument = gql`
    query GetWebflowSites {
  getWebflowSites {
    id
    name
    shortName
    previewUrl
  }
}
    `;

/**
 * __useGetWebflowSitesQuery__
 *
 * To run a query within a React component, call `useGetWebflowSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebflowSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebflowSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebflowSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetWebflowSitesQuery, GetWebflowSitesQueryVariables>) {
        return Apollo.useQuery<GetWebflowSitesQuery, GetWebflowSitesQueryVariables>(GetWebflowSitesDocument, baseOptions);
      }
export function useGetWebflowSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebflowSitesQuery, GetWebflowSitesQueryVariables>) {
          return Apollo.useLazyQuery<GetWebflowSitesQuery, GetWebflowSitesQueryVariables>(GetWebflowSitesDocument, baseOptions);
        }
export type GetWebflowSitesQueryHookResult = ReturnType<typeof useGetWebflowSitesQuery>;
export type GetWebflowSitesLazyQueryHookResult = ReturnType<typeof useGetWebflowSitesLazyQuery>;
export type GetWebflowSitesQueryResult = Apollo.QueryResult<GetWebflowSitesQuery, GetWebflowSitesQueryVariables>;
export const GetWebflowDomainsDocument = gql`
    query getWebflowDomains {
  getWebflowDomains {
    domain
  }
}
    `;

/**
 * __useGetWebflowDomainsQuery__
 *
 * To run a query within a React component, call `useGetWebflowDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebflowDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebflowDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebflowDomainsQuery(baseOptions?: Apollo.QueryHookOptions<GetWebflowDomainsQuery, GetWebflowDomainsQueryVariables>) {
        return Apollo.useQuery<GetWebflowDomainsQuery, GetWebflowDomainsQueryVariables>(GetWebflowDomainsDocument, baseOptions);
      }
export function useGetWebflowDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebflowDomainsQuery, GetWebflowDomainsQueryVariables>) {
          return Apollo.useLazyQuery<GetWebflowDomainsQuery, GetWebflowDomainsQueryVariables>(GetWebflowDomainsDocument, baseOptions);
        }
export type GetWebflowDomainsQueryHookResult = ReturnType<typeof useGetWebflowDomainsQuery>;
export type GetWebflowDomainsLazyQueryHookResult = ReturnType<typeof useGetWebflowDomainsLazyQuery>;
export type GetWebflowDomainsQueryResult = Apollo.QueryResult<GetWebflowDomainsQuery, GetWebflowDomainsQueryVariables>;
export const DeauthorizeWebflowDocument = gql`
    mutation DeauthorizeWebflow {
  deauthorizeWebflow {
    id
    session {
      acr
      connectedToWebflow
    }
  }
}
    `;
export type DeauthorizeWebflowMutationFn = Apollo.MutationFunction<DeauthorizeWebflowMutation, DeauthorizeWebflowMutationVariables>;

/**
 * __useDeauthorizeWebflowMutation__
 *
 * To run a mutation, you first call `useDeauthorizeWebflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeauthorizeWebflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deauthorizeWebflowMutation, { data, loading, error }] = useDeauthorizeWebflowMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeauthorizeWebflowMutation(baseOptions?: Apollo.MutationHookOptions<DeauthorizeWebflowMutation, DeauthorizeWebflowMutationVariables>) {
        return Apollo.useMutation<DeauthorizeWebflowMutation, DeauthorizeWebflowMutationVariables>(DeauthorizeWebflowDocument, baseOptions);
      }
export type DeauthorizeWebflowMutationHookResult = ReturnType<typeof useDeauthorizeWebflowMutation>;
export type DeauthorizeWebflowMutationResult = Apollo.MutationResult<DeauthorizeWebflowMutation>;
export type DeauthorizeWebflowMutationOptions = Apollo.BaseMutationOptions<DeauthorizeWebflowMutation, DeauthorizeWebflowMutationVariables>;
export const SetWebflowSiteDocument = gql`
    mutation setWebflowSite($input: SetWebflowSiteInput!) {
  setWebflowSite(input: $input) {
    id
    webflowSiteId
    name
    slug
  }
}
    `;
export type SetWebflowSiteMutationFn = Apollo.MutationFunction<SetWebflowSiteMutation, SetWebflowSiteMutationVariables>;

/**
 * __useSetWebflowSiteMutation__
 *
 * To run a mutation, you first call `useSetWebflowSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWebflowSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWebflowSiteMutation, { data, loading, error }] = useSetWebflowSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetWebflowSiteMutation(baseOptions?: Apollo.MutationHookOptions<SetWebflowSiteMutation, SetWebflowSiteMutationVariables>) {
        return Apollo.useMutation<SetWebflowSiteMutation, SetWebflowSiteMutationVariables>(SetWebflowSiteDocument, baseOptions);
      }
export type SetWebflowSiteMutationHookResult = ReturnType<typeof useSetWebflowSiteMutation>;
export type SetWebflowSiteMutationResult = Apollo.MutationResult<SetWebflowSiteMutation>;
export type SetWebflowSiteMutationOptions = Apollo.BaseMutationOptions<SetWebflowSiteMutation, SetWebflowSiteMutationVariables>;