import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreatePlanMutationVariables = Types.Exact<{
  input: Types.CreatePlanInput;
}>;


export type CreatePlanMutation = (
  { __typename?: 'Mutation' }
  & { createPlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id' | 'name' | 'icon' | 'description' | 'status'>
  ) }
);

export type UpdatePlanMutationVariables = Types.Exact<{
  input: Types.UpdatePlanInput;
}>;


export type UpdatePlanMutation = (
  { __typename?: 'Mutation' }
  & { updatePlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id' | 'name' | 'icon' | 'description' | 'status' | 'allowedDomains'>
  ) }
);

export type DeletePlanMutationVariables = Types.Exact<{
  input: Types.DeletePlanInput;
}>;


export type DeletePlanMutation = (
  { __typename?: 'Mutation' }
  & { deletePlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
  ) }
);

export type OrderPlansMutationVariables = Types.Exact<{
  input: Types.OrderPlansInput;
}>;


export type OrderPlansMutation = (
  { __typename?: 'Mutation' }
  & { orderPlans: Array<(
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
  )> }
);

export type AddFreePlanMutationVariables = Types.Exact<{
  input: Types.AddFreePlanInput;
}>;


export type AddFreePlanMutation = (
  { __typename?: 'Mutation' }
  & { addFreePlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
  ) }
);

export type RemoveFreePlanMutationVariables = Types.Exact<{
  input: Types.RemoveFreePlanInput;
}>;


export type RemoveFreePlanMutation = (
  { __typename?: 'Mutation' }
  & { removeFreePlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
  ) }
);

export type RemoveOneTimePlanMutationVariables = Types.Exact<{
  input: Types.RemoveOneTimePlanInput;
}>;


export type RemoveOneTimePlanMutation = (
  { __typename?: 'Mutation' }
  & { removeOneTimePlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
  ) }
);

export type UpdatePlanLogicMutationVariables = Types.Exact<{
  input: Types.UpdatePlanLogicInput;
}>;


export type UpdatePlanLogicMutation = (
  { __typename?: 'Mutation' }
  & { updatePlanLogic: (
    { __typename?: 'PlanLogic' }
    & { addedLogic?: Types.Maybe<(
      { __typename?: 'PlanLogicSingle' }
      & Pick<Types.PlanLogicSingle, 'add'>
    )>, removedLogic?: Types.Maybe<(
      { __typename?: 'PlanLogicSingle' }
      & Pick<Types.PlanLogicSingle, 'add'>
    )> }
  ) }
);


export const CreatePlanDocument = gql`
    mutation CreatePlan($input: CreatePlanInput!) {
  createPlan(input: $input) {
    id
    name
    icon
    description
    status
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, baseOptions);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    id
    name
    icon
    description
    status
    allowedDomains
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, baseOptions);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const DeletePlanDocument = gql`
    mutation DeletePlan($input: DeletePlanInput!) {
  deletePlan(input: $input) {
    id
  }
}
    `;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>) {
        return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, baseOptions);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const OrderPlansDocument = gql`
    mutation OrderPlans($input: OrderPlansInput!) {
  orderPlans(input: $input) {
    id
  }
}
    `;
export type OrderPlansMutationFn = Apollo.MutationFunction<OrderPlansMutation, OrderPlansMutationVariables>;

/**
 * __useOrderPlansMutation__
 *
 * To run a mutation, you first call `useOrderPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderPlansMutation, { data, loading, error }] = useOrderPlansMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderPlansMutation(baseOptions?: Apollo.MutationHookOptions<OrderPlansMutation, OrderPlansMutationVariables>) {
        return Apollo.useMutation<OrderPlansMutation, OrderPlansMutationVariables>(OrderPlansDocument, baseOptions);
      }
export type OrderPlansMutationHookResult = ReturnType<typeof useOrderPlansMutation>;
export type OrderPlansMutationResult = Apollo.MutationResult<OrderPlansMutation>;
export type OrderPlansMutationOptions = Apollo.BaseMutationOptions<OrderPlansMutation, OrderPlansMutationVariables>;
export const AddFreePlanDocument = gql`
    mutation addFreePlan($input: AddFreePlanInput!) {
  addFreePlan(input: $input) {
    id
  }
}
    `;
export type AddFreePlanMutationFn = Apollo.MutationFunction<AddFreePlanMutation, AddFreePlanMutationVariables>;

/**
 * __useAddFreePlanMutation__
 *
 * To run a mutation, you first call `useAddFreePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFreePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFreePlanMutation, { data, loading, error }] = useAddFreePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFreePlanMutation(baseOptions?: Apollo.MutationHookOptions<AddFreePlanMutation, AddFreePlanMutationVariables>) {
        return Apollo.useMutation<AddFreePlanMutation, AddFreePlanMutationVariables>(AddFreePlanDocument, baseOptions);
      }
export type AddFreePlanMutationHookResult = ReturnType<typeof useAddFreePlanMutation>;
export type AddFreePlanMutationResult = Apollo.MutationResult<AddFreePlanMutation>;
export type AddFreePlanMutationOptions = Apollo.BaseMutationOptions<AddFreePlanMutation, AddFreePlanMutationVariables>;
export const RemoveFreePlanDocument = gql`
    mutation removeFreePlan($input: RemoveFreePlanInput!) {
  removeFreePlan(input: $input) {
    id
  }
}
    `;
export type RemoveFreePlanMutationFn = Apollo.MutationFunction<RemoveFreePlanMutation, RemoveFreePlanMutationVariables>;

/**
 * __useRemoveFreePlanMutation__
 *
 * To run a mutation, you first call `useRemoveFreePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFreePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFreePlanMutation, { data, loading, error }] = useRemoveFreePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFreePlanMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFreePlanMutation, RemoveFreePlanMutationVariables>) {
        return Apollo.useMutation<RemoveFreePlanMutation, RemoveFreePlanMutationVariables>(RemoveFreePlanDocument, baseOptions);
      }
export type RemoveFreePlanMutationHookResult = ReturnType<typeof useRemoveFreePlanMutation>;
export type RemoveFreePlanMutationResult = Apollo.MutationResult<RemoveFreePlanMutation>;
export type RemoveFreePlanMutationOptions = Apollo.BaseMutationOptions<RemoveFreePlanMutation, RemoveFreePlanMutationVariables>;
export const RemoveOneTimePlanDocument = gql`
    mutation removeOneTimePlan($input: RemoveOneTimePlanInput!) {
  removeOneTimePlan(input: $input) {
    id
  }
}
    `;
export type RemoveOneTimePlanMutationFn = Apollo.MutationFunction<RemoveOneTimePlanMutation, RemoveOneTimePlanMutationVariables>;

/**
 * __useRemoveOneTimePlanMutation__
 *
 * To run a mutation, you first call `useRemoveOneTimePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTimePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOneTimePlanMutation, { data, loading, error }] = useRemoveOneTimePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneTimePlanMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOneTimePlanMutation, RemoveOneTimePlanMutationVariables>) {
        return Apollo.useMutation<RemoveOneTimePlanMutation, RemoveOneTimePlanMutationVariables>(RemoveOneTimePlanDocument, baseOptions);
      }
export type RemoveOneTimePlanMutationHookResult = ReturnType<typeof useRemoveOneTimePlanMutation>;
export type RemoveOneTimePlanMutationResult = Apollo.MutationResult<RemoveOneTimePlanMutation>;
export type RemoveOneTimePlanMutationOptions = Apollo.BaseMutationOptions<RemoveOneTimePlanMutation, RemoveOneTimePlanMutationVariables>;
export const UpdatePlanLogicDocument = gql`
    mutation updatePlanLogic($input: UpdatePlanLogicInput!) {
  updatePlanLogic(input: $input) {
    addedLogic {
      add
    }
    removedLogic {
      add
    }
  }
}
    `;
export type UpdatePlanLogicMutationFn = Apollo.MutationFunction<UpdatePlanLogicMutation, UpdatePlanLogicMutationVariables>;

/**
 * __useUpdatePlanLogicMutation__
 *
 * To run a mutation, you first call `useUpdatePlanLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanLogicMutation, { data, loading, error }] = useUpdatePlanLogicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanLogicMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanLogicMutation, UpdatePlanLogicMutationVariables>) {
        return Apollo.useMutation<UpdatePlanLogicMutation, UpdatePlanLogicMutationVariables>(UpdatePlanLogicDocument, baseOptions);
      }
export type UpdatePlanLogicMutationHookResult = ReturnType<typeof useUpdatePlanLogicMutation>;
export type UpdatePlanLogicMutationResult = Apollo.MutationResult<UpdatePlanLogicMutation>;
export type UpdatePlanLogicMutationOptions = Apollo.BaseMutationOptions<UpdatePlanLogicMutation, UpdatePlanLogicMutationVariables>;