import React from "react";
import { Button, Card } from "components";
import Skeleton from "react-loading-skeleton";

export const StripeSettingsLoading = () => {
  return (
    <form>
      <Button text="Save" tw="absolute right-5 top-5" isDisabled />
      <Card className="p-4 flex flex-col">
        <h3 tw="text-h3 font-bold mb-4">Connect Stripe</h3>
        <p className="mb-4 text-body-sm lg:w-3/4 w-full">
          Stripe is the easiest way to accept credit cards, and process major
          international debit or credit cards, including Visa, MasterCard and
          American Express. You don’t need a merchant account, so you can start
          accepting payments today.
        </p>
        <Button
          text="Save"
          onClick={undefined}
          isLoading
          tw="w-[fit-content]"
        />
      </Card>
      <>
        <h3 tw="text-h3 font-bold my-4">Checkout Settings</h3>
        <Skeleton height={130} />
        <Skeleton height={130} />
        <Skeleton height={130} />
        <Skeleton height={130} />
        <Skeleton height={130} />
        <Skeleton height={130} />
      </>
    </form>
  );
};
