import React, { useState, useContext, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { act } from "@testing-library/react";
import { Link } from "components/Link/Link";
import ToastAlert from "../../../components/ToastAlert/index";

interface Toast {
  message?: React.ReactNode | string;
  alertType?: "success" | "error";
  processing?: boolean;
}

interface DefaultContext {
  createToastAlert: (toast: Toast) => void;
}

const defaultContext: DefaultContext = {
  createToastAlert: () => {},
};

const ToastAlertContext = React.createContext(defaultContext);

const useToastAlertContext = () => {
  return useContext(ToastAlertContext);
};

const ToastAlertContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [toast, setToast] = useState<Toast>(null);
  const popover = useRef<HTMLDivElement>();
  let timeoutId: null | ReturnType<typeof setTimeout>;

  const handleClose = () => {
    // @ts-ignore
    popover.current?.hidePopover();
    setToast(null);
    clearTimeout(timeoutId);
  };

  const createToastAlert = (t: Toast) => {
    handleClose();
    act(() => {
      setToast(t);
    });
  };

  useEffect(() => {
    if (toast) {
      // @ts-ignore
      if (popover.current) popover.current.showPopover();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutId = setTimeout(
        () => {
          handleClose();
        },
        toast.alertType === "error" ? 10000 : 5000
      );
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [toast]);

  const getToastMessage = (alertType, message) => {
    if (alertType === "success") return message;
    return (
      <>
        {message}&nbsp;
        <Link
          isExternal
          target="_blank"
          underline
          className="text-current"
          to="https://docs.memberstack.com/hc/en-us"
        >
          Contact support
        </Link>
      </>
    );
  };

  return (
    <ToastAlertContext.Provider value={{ createToastAlert }}>
      {children}
      {createPortal(
        <div
          className="drop-shadow-md p-3 bg-app-gray900 text-white rounded-lg"
          style={{
            inset: "unset",
            left: "calc(50% - 264px)",
            bottom: "50px",
            width: "528px",
          }}
          // @ts-ignore
          popover="manual"
          ref={popover}
        >
          {toast && (
            <ToastAlert
              message={getToastMessage(toast.alertType, toast.message)}
              alertType={toast.alertType}
              onClose={handleClose}
              processing={toast.processing}
            />
          )}
        </div>,
        document.body
      )}
    </ToastAlertContext.Provider>
  );
};

export { useToastAlertContext, ToastAlertContextProvider };
