import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import { Background, Button, Switch, MarkdownEditor, Card } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useUpdateEmailsMutation } from "features";
import { useToastAlertContext } from "components/toastAlert";
import { isError } from "helpers/typeguards";
import { useGetEmailsQuery } from "features/emails";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import {
  INITIAL_WELCOME_EMAIL_STATE,
  useHandleOnChange,
} from "../emails.utils";
import { EmailTemplateLoading } from "../emails.loading";
import * as S from "../emails.styles";

export const HelloAndWelcome = () => {
  const { appData, isLoading } = useAppDataContext();
  const [isWelcomeEmailEnabled, setIsWelcomeEmailEnabled] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>(
    INITIAL_WELCOME_EMAIL_STATE
  );

  const { replyTo, subject, paragraph1, paragraph2 } =
    INITIAL_WELCOME_EMAIL_STATE;
  const valRef = useRef(null);

  const { createToastAlert } = useToastAlertContext();

  const [updateEmails, { loading }] = useUpdateEmailsMutation();

  const {
    data: emails,
    loading: loadingEmails,
    refetch: refetchEmails,
  } = useGetEmailsQuery();

  const emailVerification = useMemo(
    () =>
      getObjectWithKeys(
        emails?.getEmails?.welcome || {},
        Object.keys(INITIAL_WELCOME_EMAIL_STATE)
      ),
    [emails?.getEmails?.welcome]
  );

  useEffect(() => {
    valRef.current = emailVerification;
    setFormValues({
      replyTo: valRef.current?.replyTo || "",
      subject: valRef.current?.subject || "",
      paragraph1: valRef.current?.paragraph1 || "",
      paragraph2: valRef.current?.paragraph2 || "",
    });
    setIsWelcomeEmailEnabled(emails?.getEmails?.welcomeEmailsEnabled);
  }, [emails?.getEmails?.welcomeEmailsEnabled, emailVerification]);

  const { handleOnChange } = useHandleOnChange({ setFormValues, formValues });

  // check if isWelcomeEmailEnabled changed, then run mutation
  const didWelcomeEmailEnabledChange =
    emails?.getEmails?.welcomeEmailsEnabled !== isWelcomeEmailEnabled;

  // check if email form changed then run mutation
  const didEmailFormChange = !isObjectEqual(
    removeEmpty(valRef.current || {}),
    removeEmpty(trimObjectStringValues(formValues))
  );

  const didFormChange = didEmailFormChange || didWelcomeEmailEnabledChange;

  const onSubmit = useCallback(
    async (e) => {
      createToastAlert({ processing: true });
      e.preventDefault();

      try {
        await updateEmails({
          variables: {
            input: {
              welcome: { ...formValues },
              welcomeEmailsEnabled: didWelcomeEmailEnabledChange
                ? isWelcomeEmailEnabled
                : emails?.getEmails?.welcomeEmailsEnabled,
            },
          },
        });

        createToastAlert({
          alertType: "success",
          message: "Hello and Welcome email saved!",
        });
      } catch (_error) {
        if (isError(_error)) {
          createToastAlert({
            alertType: "error",
            message: _error.message,
          });
        }
      } finally {
        if (didEmailFormChange || didWelcomeEmailEnabledChange) {
          await refetchEmails();
        }
      }
    },
    [
      createToastAlert,
      didEmailFormChange,
      didWelcomeEmailEnabledChange,
      formValues,
      isWelcomeEmailEnabled,
      refetchEmails,
    ]
  );

  if (loadingEmails || isLoading)
    return <EmailTemplateLoading title="Hello & Welcome" />;

  return (
    <Card className="flex flex-row" hideBorder>
      <SettingsBar>
        <TitleSection
          title="Hello & Welcome"
          titleHint="Enable & personalize welcome emails."
          backLink="/settings/emails"
          backLinkTitle="Emails"
          emphasize
          gutter="medium"
          buttons={
            <Button
              text="Save"
              onClick={onSubmit}
              isLoading={loading}
              isDisabled={!didFormChange}
            />
          }
        />
        <form
          className="p-5 overflow-y-auto flex flex-col h-full"
          onSubmit={onSubmit}
        >
          <Switch
            tw="pb-5"
            label="Enable Welcome Email"
            isChecked={isWelcomeEmailEnabled}
            name="isWelcomeEmailEnabled"
            id="isWelcomeEmailEnabled"
            onChange={(value) =>
              setIsWelcomeEmailEnabled(!isWelcomeEmailEnabled)
            }
          />
          {isWelcomeEmailEnabled && (
            <>
              <Input
                name="replyTo"
                placeholder={replyTo}
                value={formValues.replyTo}
                label="Reply-To-Email"
                infoHintText="Email address"
                tw="pb-5"
                hideIndicators
                description="Enter your customer support address."
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "replyTo", value })
                }
              />
              <Input
                name="subject"
                placeholder={subject}
                value={formValues.subject}
                label="Subject"
                infoHintText="Subject of the email"
                tw="pb-5"
                hideIndicators
                description="Customize the email subject."
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "subject", value })
                }
              />
              <Input
                name="header"
                placeholder={paragraph1}
                value={formValues.paragraph1}
                label="Header"
                infoHintText="Header of the email"
                tw="pb-5"
                hideIndicators
                description="Customize the email header content."
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "paragraph1", value })
                }
              />
              <MarkdownEditor
                label="Content"
                name="paragraph2"
                height={260}
                className="mb-5"
                value={formValues.paragraph2}
                placeholder={paragraph2}
                description="Customize the body of your email. Markdown supported."
                onChange={(value) =>
                  handleOnChange({ type: "paragraph2", value })
                }
              />
            </>
          )}
        </form>
      </SettingsBar>
      <Background>
        <div className="p-5 max-h-full min-h-0 overflow-auto">
          <S.UploadLogoText />
          <S.ShowcaseCard>
            {appData?.image && (
              <div tw="pb-[30px]">
                <img
                  src={appData?.image}
                  height={43}
                  tw="h-[43px]"
                  alt={appData?.name}
                />
              </div>
            )}
            <h1 tw="text-h1 font-bold mb-[30px]">
              {formValues.paragraph1 || paragraph1}
            </h1>
            <MarkdownEditor
              readonly
              value={formValues.paragraph2 || paragraph2}
            />
          </S.ShowcaseCard>
        </div>
      </Background>
    </Card>
  );
};
