import React from "react";
import { Button, Link } from "components";
import { Warning } from "@mui/icons-material";
import { useRouteMatch } from "react-router-dom";

const PageNotFound = ({ page = "members" }: { page?: string }) => {
  const { url } = useRouteMatch();
  const cleansedPath = url.split("/").slice(0, -1).join("/");
  const routePath = page === "members" ? `${cleansedPath}/${page}` : `/${page}`;
  return (
    <div className="flex flex-col justify-center items-center mx-auto w-full h-full text-gray-500">
      <Warning tw="w-6! h-6! text-orange-fill" />
      <h3 tw="text-h3 text-app-gray400 mb-1">Page Not Found</h3>
      <p className="text-body-sm mb-6 w-64 text-center">
        The link you followed may be broken, or the page has been removed
      </p>

      <Link to={routePath}>
        <Button text={`Go to ${page}`} />
      </Link>
    </div>
  );
};

export default PageNotFound;
