import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import AuthProviders from "containers/Settings/auth-integration";
import SettingsDangerZone from "containers/Settings/danger-zone";
import AppSettings from "containers/Settings/application-settings";
import InstallCode from "containers/Settings/install-code";
import TeamSettings from "containers/Settings/team-settings";
import Design from "containers/Settings/design";
import ReactCode from "containers/Settings/install-code/ReactCode";
import WebflowCode from "containers/Settings/install-code/WebflowCode";
import VanillaCode from "containers/Settings/install-code/Vanilla";
import PageNotFound from "containers/PageNotFound";
import Billing from "containers/Settings/billing";
import {
  VerificationEmail,
  PasswordResetEmail,
  PasswordlessEmail,
  Emails,
  HelloAndWelcome,
  AbandonedCart,
} from "containers/Settings/emails";
import {
  ErrorMessages,
  ResetPasswordRequest,
  TranslateLogin,
  Translation,
  ChangePassword,
  TranslateAuth,
} from "containers/Settings/translation";
import { RouteName } from "enums/routes";
import StripeSettingsPage from "containers/Settings/stripe-settings/StripeSettingsPage";
import ConnectWebflowPage from "containers/Settings/connect-webflow/ConnectWebflow";
import Notifications from "containers/Settings/notifications/Notifications";
import { CommentingNotification } from "containers/Settings/emails/Templates/CommentingNotification";

export const InstallCodeRoutes = ({ selectedStack }) => {
  const { path, url } = useRouteMatch();

  const pages = [
    {
      exact: true,
      path: `${path}/`,
      // this is the fix for the redirect going to /install-code/undefined.
      Component: () =>
        selectedStack ? <Redirect to={`${url}/${selectedStack}`} /> : <></>,
    },
    {
      exact: true,
      path: `${path}/webflow`,
      Component: WebflowCode,
    },
    {
      exact: true,
      path: `${path}/react`,
      Component: ReactCode,
    },
    {
      exact: true,
      path: `${path}/vanilla`,
      Component: VanillaCode,
    },
  ];

  return (
    <Switch>
      {pages.map(({ exact, path: routePath, Component }) => (
        <Route key={path} exact={exact} path={routePath}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};

export const SettingsRoutes = () => {
  const { path, url } = useRouteMatch();
  const pages = [
    {
      exact: true,
      path: `${path}/`,
      // eslint-disable-next-line react/display-name
      Component: () => <Redirect to={`${url}/application`} />,
    },
    {
      exact: true,
      path: `${path}/application`,
      Component: AppSettings,
    },
    {
      exact: false,
      path: `${path}/${RouteName.installCode}`,
      Component: InstallCode,
    },
    {
      exact: false,
      path: `${path}/${RouteName.notifications}`,
      Component: Notifications,
    },
    {
      exact: true,
      path: `${path}/${RouteName.team}`,
      Component: TeamSettings,
    },
    {
      exact: true,
      path: `${path}/${RouteName.authProviders}`,
      Component: AuthProviders,
    },
    {
      exact: true,
      path: `${path}/${RouteName.stripeSettings}`,
      Component: StripeSettingsPage,
    },
    {
      exact: true,
      path: `${path}/${RouteName.dangerZone}`,
      Component: SettingsDangerZone,
    },
    {
      exact: true,
      path: `${path}/${RouteName.connectWebflow}`,
      Component: ConnectWebflowPage,
    },
    {
      exact: true,
      path: `${path}/design`,
      Component: Design,
    },
    {
      exact: true,
      path: `${path}/emails`,
      Component: Emails,
    },
    {
      exact: true,
      path: `${path}/translations`,
      Component: Translation,
    },
    {
      exact: true,
      path: `${path}/translations/error-messages`,
      Component: ErrorMessages,
    },
    {
      exact: true,
      path: `${path}/translations/reset-password-request`,
      Component: ResetPasswordRequest,
    },
    {
      exact: true,
      path: `${path}/translations/translate-login`,
      Component: TranslateLogin,
    },
    {
      exact: true,
      path: `${path}/translations/change-password`,
      Component: ChangePassword,
    },
    {
      exact: true,
      path: `${path}/translations/translate-auth`,
      Component: TranslateAuth,
    },
    {
      exact: true,
      path: `${path}/emails/password-reset-email`,
      Component: PasswordResetEmail,
    },
    {
      exact: true,
      path: `${path}/emails/passwordless-email`,
      Component: PasswordlessEmail,
    },
    {
      exact: true,
      path: `${path}/emails/email-verification`,
      Component: VerificationEmail,
    },
    {
      exact: true,
      path: `${path}/emails/hello-and-welcome`,
      Component: HelloAndWelcome,
    },
    {
      exact: true,
      path: `${path}/emails/abandoned-cart`,
      Component: AbandonedCart,
    },
    {
      exact: true,
      path: `${path}/emails/commenting-notification`,
      Component: CommentingNotification,
    },
    {
      exact: true,
      path: `${path}/billing`,
      Component: Billing,
    },
    {
      exact: false,
      path: `${path}/billing/commenting-pricing`,
      Component: Billing,
    },
    {
      path: "*",
      Component: () => <PageNotFound page="settings" />,
    },
  ];

  return (
    <Switch>
      {pages.map(({ exact, path: routePath, Component }) => (
        <Route key={path} exact={exact} path={routePath}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};
