import React, { useMemo } from "react";
import Tippy, { useSingleton } from "@tippyjs/react/headless";
import { useSpring } from "react-spring";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Placement } from "tippy.js";
import * as S from "./styles";

interface TooltipProps {
  /**
   * Tooltip reference element.
   */
  children: React.ReactElement;
  /**
   * Tooltip placement
   */
  placement?: Placement;
  /**
   * Content to be shown
   */
  content: React.ReactChild | React.ReactChild[];
  /**
   * Determines if an arrow is shown <br>
   * If true, adds an arrow to the tooltip.
   */
  showArrow?: boolean;
  /**
   * Duration in ms of the transition animation.
   */
  duration?: number | [number, number];
  /**
   * Delay in ms once a trigger event is fired before a tippy shows or hides.
   */
  delay?: number | [number, number];
  onCreate?: (i) => void;
  className?: string;
  flip?: boolean;
  offset?: [number, number];
  interactive?: boolean;
}

const Tooltip = ({
  children,
  placement,
  content,
  showArrow,
  duration,
  delay,
  onCreate,
  className,
  flip = true,
  offset = [0, 10],
  interactive = true,
}: TooltipProps) => {
  const marginStyle = useMemo(
    () => ({
      from: {
        [placement === "top" ? "marginBottom" : "marginTop"]:
          placement === "top" ? -4 : 4,
      },
      to: { [placement === "top" ? "marginBottom" : "marginTop"]: 0 },
    }),
    [placement]
  );
  const [source, target] = useSingleton();
  const config = { tension: 200, friction: 20 };
  const initialStyles = {
    opacity: 0,
    ...marginStyle.from,
  };
  const [props, setSpringStyle] = useSpring(() => initialStyles);

  const onMount = () => {
    setSpringStyle({
      opacity: 1,
      ...marginStyle.to,
      onRest: () => {},
      config,
    });
  };

  const onHide = ({ unmount }) => {
    setSpringStyle({
      ...initialStyles,
      onRest: unmount,
      config: { ...config, clamp: true },
    });
  };

  return (
    <>
      <Tippy
        singleton={source}
        placement={placement}
        interactive={interactive}
        delay={delay}
        onCreate={onCreate}
        hideOnClick={false}
        render={(attrs, _content) => {
          if (!_content) return null;
          return (
            <S.ContentContainer placement={placement} style={props} {...attrs}>
              {showArrow && <span id="arrow" data-testid="arrow" />}
              {_content}
            </S.ContentContainer>
          );
        }}
        animation
        onMount={onMount}
        onHide={onHide}
        popperOptions={{ modifiers: [{ name: "flip", enabled: flip }] }}
        offset={offset}
      />
      <Tippy content={content} duration={duration} singleton={target}>
        <S.Container className={className}>{children}</S.Container>
      </Tippy>
    </>
  );
};

Tooltip.defaultProps = {
  placement: "right",
  showArrow: false,
  duration: [100, 100],
  delay: 0,
};

export default Tooltip;
