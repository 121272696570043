import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import { Background, Button, Switch, MarkdownEditor, Card } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useUpdateEmailsMutation } from "features";
import { useToastAlertContext } from "components/toastAlert";
import { isError } from "helpers/typeguards";
import { useGetEmailsQuery } from "features/emails";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import Select from "components/Select";
import {
  INITIAL_ABANDONED_CART_STATE,
  useHandleOnChange,
} from "../emails.utils";
import { EmailTemplateLoading } from "../emails.loading";
import * as S from "../emails.styles";

const sendAfterOptions = [
  {
    label: "30 minutes",
    value: 30,
  },
  {
    label: "1 Hour",
    value: 60,
  },
  {
    label: "3 Hours",
    value: 180,
  },
  {
    label: "6 Hours",
    value: 360,
  },
  {
    label: "12 Hours",
    value: 720,
  },
  {
    label: "24 Hours",
    value: 1439,
  },
];

export const AbandonedCart = () => {
  const { appData, isLoading } = useAppDataContext();
  const [isEmailEnabled, setIsEmailEnabled] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>(
    INITIAL_ABANDONED_CART_STATE
  );

  const [sendAfter, setSendAfter] = useState({ label: "1 Hour", value: 60 });

  const { replyTo, subject, paragraph1, primaryButtonText } =
    INITIAL_ABANDONED_CART_STATE;

  const valRef = useRef(null);

  const { createToastAlert } = useToastAlertContext();

  const [updateEmails, { loading }] = useUpdateEmailsMutation();

  const {
    data: emails,
    loading: loadingEmails,
    refetch: refetchEmails,
  } = useGetEmailsQuery();

  const emailVerification = useMemo(
    () =>
      getObjectWithKeys(
        emails?.getEmails?.abandonedCart || {},
        Object.keys(INITIAL_ABANDONED_CART_STATE)
      ),
    [emails?.getEmails?.abandonedCart]
  );

  useEffect(() => {
    valRef.current = emailVerification;
    setFormValues({
      replyTo: valRef.current?.replyTo || "",
      subject: valRef.current?.subject || "",
      paragraph1: valRef.current?.paragraph1 || "",
      primaryButtonText: valRef.current?.primaryButtonText || "",
    });
    setIsEmailEnabled(emails?.getEmails?.abandonedCartEmailsEnabled);
    const cartExpireMinutes = sendAfterOptions.find(
      (option) => option.value === emails?.getEmails?.abandonedCartExpireMinutes
    );
    setSendAfter(cartExpireMinutes);
  }, [
    emails?.getEmails?.abandonedCartEmailsEnabled,
    emailVerification,
    emails?.getEmails?.abandonedCartExpireMinutes,
  ]);

  const { handleOnChange } = useHandleOnChange({ setFormValues, formValues });

  // check if isWelcomeEmailEnabled changed, then run mutation
  const didAbandonedCartEnabledChange =
    emails?.getEmails?.abandonedCartEmailsEnabled !== isEmailEnabled;

  const didSendAfterChange =
    emails?.getEmails?.abandonedCartExpireMinutes !== sendAfter?.value;

  // check if email form changed then run mutation
  const didEmailFormChange = !isObjectEqual(
    removeEmpty(valRef.current || {}),
    removeEmpty(trimObjectStringValues(formValues))
  );

  const didFormChange =
    didEmailFormChange || didAbandonedCartEnabledChange || didSendAfterChange;

  const onSubmit = useCallback(
    async (e) => {
      createToastAlert({ processing: true });
      e.preventDefault();

      try {
        await updateEmails({
          variables: {
            input: {
              abandonedCart: { ...formValues },
              abandonedCartExpireMinutes: sendAfter?.value,
              abandonedCartEmailsEnabled: didAbandonedCartEnabledChange
                ? isEmailEnabled
                : emails?.getEmails?.abandonedCartEmailsEnabled,
            },
          },
        });

        createToastAlert({
          alertType: "success",
          message: "Abandoned Cart email was successfully updated.",
        });
      } catch (_error) {
        if (isError(_error)) {
          createToastAlert({
            alertType: "error",
            message: _error.message,
          });
        }
      } finally {
        if (didEmailFormChange || didAbandonedCartEnabledChange) {
          await refetchEmails();
        }
      }
    },
    [
      createToastAlert,
      didEmailFormChange,
      didAbandonedCartEnabledChange,
      formValues,
      isEmailEnabled,
      refetchEmails,
      sendAfter?.value,
    ]
  );

  const sendAfterValue = useMemo(() => {
    if (!sendAfter) return { label: "1 Hour", value: 60 };
    return sendAfter;
  }, [sendAfter]);

  const handleSendAfterChange = (option) => setSendAfter(option);

  if (loadingEmails || isLoading)
    return <EmailTemplateLoading title="Abandoned Cart" />;

  return (
    <Card className="flex flex-row" hideBorder>
      <SettingsBar>
        <TitleSection
          title="Abandoned Cart"
          titleHint="Configure the Abandoned Cart email."
          backLink="/settings/emails"
          backLinkTitle="Emails"
          emphasize
          gutter="medium"
          buttons={
            <Button
              text="Save"
              onClick={onSubmit}
              isLoading={loading}
              isDisabled={!didFormChange}
            />
          }
        />
        <form
          className="p-5 overflow-y-auto flex flex-col h-full"
          onSubmit={onSubmit}
        >
          <Switch
            tw="pb-5"
            label="Enable Abandoned Cart Email"
            isChecked={isEmailEnabled}
            name="emailVerificationEnabled"
            id="emailVerificationEnabled"
            onChange={(value) => setIsEmailEnabled(!isEmailEnabled)}
          />
          {isEmailEnabled && (
            <>
              <Input
                name="replyTo"
                placeholder={replyTo}
                value={formValues.replyTo}
                label="Reply-To-Email"
                infoHintText="Email address"
                tw="pb-5"
                description="Enter your customer support address."
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "replyTo", value })
                }
              />
              <Input
                name="subject"
                placeholder={subject}
                value={formValues.subject}
                label="Subject"
                infoHintText="Subject of the email"
                tw="pb-5"
                hideIndicators
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "subject", value })
                }
                description="Customize the email subject."
              />
              <MarkdownEditor
                label="Content"
                name="paragraph1"
                height={150}
                className="mb-5"
                value={formValues.paragraph1}
                placeholder={paragraph1}
                description="Customize the body of your email. Markdown supported."
                onChange={(value) =>
                  handleOnChange({ type: "paragraph1", value })
                }
              />
              <Input
                name="primaryButtonText"
                placeholder={primaryButtonText}
                value={formValues.primaryButtonText}
                label="Button Text"
                tw="mb-5"
                hideIndicators
                description="Customize the button text."
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "primaryButtonText", value })
                }
              />
              <Select
                options={sendAfterOptions}
                label="Send After"
                value={sendAfterValue}
                onChange={handleSendAfterChange}
              />
            </>
          )}
        </form>
      </SettingsBar>
      <Background>
        <div className="p-5 max-h-full min-h-0 overflow-auto">
          <S.UploadLogoText />
          <S.ShowcaseCard>
            {appData?.image && (
              <div tw="pb-[30px]">
                <img
                  src={appData?.image}
                  height={43}
                  tw="h-[43px]"
                  alt={appData?.name}
                />
              </div>
            )}
            <MarkdownEditor
              readonly
              value={formValues.paragraph1 || paragraph1}
            />
            <Button
              text={formValues.primaryButtonText || primaryButtonText}
              tw="my-[30px]"
            />
          </S.ShowcaseCard>
        </div>
      </Background>
    </Card>
  );
};
