export const paddingSizes = {
  xxSmall: "4px",
  xSmall: "8px",
  small: "12px",
  regular: "16px",
  medium: "20px",
  large: "32px",
  xLarge: "64px",
  auto: "auto",
};

const fontSizes = {
  fontSize1: "32px",
  fontSize2: "24px",
  fontSize3: "18px",
  fontSize4: "14px",
  fontSize5: "13px",
  fontSize6: "10px",
};

const fontWeights = {
  fontWeightBold: "700",
  fontWeightMedium: "500",
  fontWeightNormal: "400",
  fontWeightLight: "300",
};

const boxShadows = {
  mainBoxShadow:
    "0px -1px 0px 0px rgba(0, 0, 0, 0.10) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.04);",
  controlShadow: "0 12px 20px -6px rgba(83, 106, 129, 0.15)",
  heavyBoxShadow: "0px 4px 50px rgba(0, 0, 0, 0.35)",
};

const borderRadius = {
  smallBorderRadius: "4px",
  mainBorderRadius: "10px",
};

const transitions = {
  mainTransition: "150ms",
};

const zIndex = {
  topZ: 100,
  bumpUpZ: 1,
  bottomZ: -100,
};

const widths = {
  formWidth: "416px",
};

export const variables = {
  ...widths,
  ...fontSizes,
  ...fontWeights,
  ...boxShadows,
  ...borderRadius,
  ...transitions,
  ...zIndex,
  ...paddingSizes,
};
