import { styled } from "twin.macro";

export const BillingPlanContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  overflow-y: hidden;

  @media (max-width: 1400px) {
    flex-wrap: wrap;
    justify-content: left;
  }
  & > div {
    border-right-width: 1px;
    :last-child {
      border-right-width: 0;
    }
    @media (max-width: 960px) {
      width: 100%;
    }
  }
`;
