import React from "react";
import { render } from "react-dom";
import { App } from "containers/App";

import * as Sentry from "@sentry/react";
import { init } from "commandbar";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import "./styles.css";

// commandbar init
init("06b079c5");

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://3c74adeedba147bb8148ba13021a7267@o220641.ingest.sentry.io/1427842",
  });
}

Chart.register(CategoryScale);

render(<App />, document.getElementById("container"));
