import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreatePriceMutationVariables = Types.Exact<{
  input: Types.CreatePriceInput;
}>;


export type CreatePriceMutation = (
  { __typename?: 'Mutation' }
  & { createPrice: (
    { __typename?: 'Price' }
    & Pick<Types.Price, 'id' | 'amount' | 'name' | 'type' | 'description' | 'active' | 'currency'>
    & { interval?: Types.Maybe<(
      { __typename?: 'PriceInterval' }
      & Pick<Types.PriceInterval, 'type' | 'count'>
    )> }
  ) }
);

export type UpdatePriceMutationVariables = Types.Exact<{
  input: Types.UpdatePriceInput;
}>;


export type UpdatePriceMutation = (
  { __typename?: 'Mutation' }
  & { updatePrice: (
    { __typename?: 'Price' }
    & Pick<Types.Price, 'id' | 'name' | 'description'>
  ) }
);

export type DeletePriceMutationVariables = Types.Exact<{
  input: Types.DeletePriceInput;
}>;


export type DeletePriceMutation = (
  { __typename?: 'Mutation' }
  & { deletePrice: (
    { __typename?: 'Price' }
    & Pick<Types.Price, 'id' | 'active'>
  ) }
);

export type ReactivatePriceMutationVariables = Types.Exact<{
  input: Types.ReactivatePriceInput;
}>;


export type ReactivatePriceMutation = (
  { __typename?: 'Mutation' }
  & { reactivatePrice: (
    { __typename?: 'Price' }
    & Pick<Types.Price, 'id'>
  ) }
);

export type DeactivatePriceMutationVariables = Types.Exact<{
  input: Types.DeactivatePriceInput;
}>;


export type DeactivatePriceMutation = (
  { __typename?: 'Mutation' }
  & { deactivatePrice: (
    { __typename?: 'Price' }
    & Pick<Types.Price, 'id'>
  ) }
);


export const CreatePriceDocument = gql`
    mutation createPrice($input: CreatePriceInput!) {
  createPrice(input: $input) {
    id
    amount
    interval {
      type
      count
    }
    name
    type
    description
    active
    currency
  }
}
    `;
export type CreatePriceMutationFn = Apollo.MutationFunction<CreatePriceMutation, CreatePriceMutationVariables>;

/**
 * __useCreatePriceMutation__
 *
 * To run a mutation, you first call `useCreatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPriceMutation, { data, loading, error }] = useCreatePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePriceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePriceMutation, CreatePriceMutationVariables>) {
        return Apollo.useMutation<CreatePriceMutation, CreatePriceMutationVariables>(CreatePriceDocument, baseOptions);
      }
export type CreatePriceMutationHookResult = ReturnType<typeof useCreatePriceMutation>;
export type CreatePriceMutationResult = Apollo.MutationResult<CreatePriceMutation>;
export type CreatePriceMutationOptions = Apollo.BaseMutationOptions<CreatePriceMutation, CreatePriceMutationVariables>;
export const UpdatePriceDocument = gql`
    mutation updatePrice($input: UpdatePriceInput!) {
  updatePrice(input: $input) {
    id
    name
    description
  }
}
    `;
export type UpdatePriceMutationFn = Apollo.MutationFunction<UpdatePriceMutation, UpdatePriceMutationVariables>;

/**
 * __useUpdatePriceMutation__
 *
 * To run a mutation, you first call `useUpdatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceMutation, { data, loading, error }] = useUpdatePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePriceMutation, UpdatePriceMutationVariables>) {
        return Apollo.useMutation<UpdatePriceMutation, UpdatePriceMutationVariables>(UpdatePriceDocument, baseOptions);
      }
export type UpdatePriceMutationHookResult = ReturnType<typeof useUpdatePriceMutation>;
export type UpdatePriceMutationResult = Apollo.MutationResult<UpdatePriceMutation>;
export type UpdatePriceMutationOptions = Apollo.BaseMutationOptions<UpdatePriceMutation, UpdatePriceMutationVariables>;
export const DeletePriceDocument = gql`
    mutation deletePrice($input: DeletePriceInput!) {
  deletePrice(input: $input) {
    id
    active
  }
}
    `;
export type DeletePriceMutationFn = Apollo.MutationFunction<DeletePriceMutation, DeletePriceMutationVariables>;

/**
 * __useDeletePriceMutation__
 *
 * To run a mutation, you first call `useDeletePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePriceMutation, { data, loading, error }] = useDeletePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePriceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePriceMutation, DeletePriceMutationVariables>) {
        return Apollo.useMutation<DeletePriceMutation, DeletePriceMutationVariables>(DeletePriceDocument, baseOptions);
      }
export type DeletePriceMutationHookResult = ReturnType<typeof useDeletePriceMutation>;
export type DeletePriceMutationResult = Apollo.MutationResult<DeletePriceMutation>;
export type DeletePriceMutationOptions = Apollo.BaseMutationOptions<DeletePriceMutation, DeletePriceMutationVariables>;
export const ReactivatePriceDocument = gql`
    mutation reactivatePrice($input: ReactivatePriceInput!) {
  reactivatePrice(input: $input) {
    id
  }
}
    `;
export type ReactivatePriceMutationFn = Apollo.MutationFunction<ReactivatePriceMutation, ReactivatePriceMutationVariables>;

/**
 * __useReactivatePriceMutation__
 *
 * To run a mutation, you first call `useReactivatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivatePriceMutation, { data, loading, error }] = useReactivatePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactivatePriceMutation(baseOptions?: Apollo.MutationHookOptions<ReactivatePriceMutation, ReactivatePriceMutationVariables>) {
        return Apollo.useMutation<ReactivatePriceMutation, ReactivatePriceMutationVariables>(ReactivatePriceDocument, baseOptions);
      }
export type ReactivatePriceMutationHookResult = ReturnType<typeof useReactivatePriceMutation>;
export type ReactivatePriceMutationResult = Apollo.MutationResult<ReactivatePriceMutation>;
export type ReactivatePriceMutationOptions = Apollo.BaseMutationOptions<ReactivatePriceMutation, ReactivatePriceMutationVariables>;
export const DeactivatePriceDocument = gql`
    mutation deactivatePrice($input: DeactivatePriceInput!) {
  deactivatePrice(input: $input) {
    id
  }
}
    `;
export type DeactivatePriceMutationFn = Apollo.MutationFunction<DeactivatePriceMutation, DeactivatePriceMutationVariables>;

/**
 * __useDeactivatePriceMutation__
 *
 * To run a mutation, you first call `useDeactivatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePriceMutation, { data, loading, error }] = useDeactivatePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivatePriceMutation(baseOptions?: Apollo.MutationHookOptions<DeactivatePriceMutation, DeactivatePriceMutationVariables>) {
        return Apollo.useMutation<DeactivatePriceMutation, DeactivatePriceMutationVariables>(DeactivatePriceDocument, baseOptions);
      }
export type DeactivatePriceMutationHookResult = ReturnType<typeof useDeactivatePriceMutation>;
export type DeactivatePriceMutationResult = Apollo.MutationResult<DeactivatePriceMutation>;
export type DeactivatePriceMutationOptions = Apollo.BaseMutationOptions<DeactivatePriceMutation, DeactivatePriceMutationVariables>;