import React from "react";
import { Modal, ModalTypes } from "components/Modal";
import { BackupCodeBlock } from "components";

interface Props extends ModalTypes {
  backupCodes: string[];
}

export const BackupCodeModal = ({
  showModal,
  setShowModal,
  backupCodes,
}: Props) => (
  <Modal
    setShowModal={setShowModal}
    showModal={showModal}
    title="View your backup codes"
    showDivider
    showBottomSection={false}
  >
    <BackupCodeBlock backupCodes={backupCodes} />
  </Modal>
);
