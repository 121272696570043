import { useToastAlertContext } from "components/toastAlert";

export const useCopy = () => {
  const { createToastAlert } = useToastAlertContext();

  const copy =
    ({ text, feedback }) =>
    async (event) => {
      event.stopPropagation();
      event.preventDefault();
      try {
        await navigator.clipboard.writeText(text.toString());
        createToastAlert({
          alertType: "success",
          message: feedback,
        });
      } catch (err) {
        createToastAlert({
          alertType: "error",
          message: "Failed to copy text",
        });
      }
    };

  return [copy];
};
