import React, { useMemo, useState } from "react";
import { AccordionLoader, Link } from "components";
import { useGetAppRedirectsQuery } from "features";
import {
  REDIRECT_MAP,
  REDIRECT_MASK,
  REDIRECT_MASK_MESSAGE,
} from "constants/redirects";
import { InfoTooltip } from "components/Tooltip";
import { Create, LinkOutlined } from "@mui/icons-material";
import { PlanAccordionItem } from "../plan-settings/PlanAccordionItem";
import EditRedirectModal from "../../modals/EditRedirectModal";

const DefaultRedirects = () => {
  const [workingRedirect, setWorkingRedirect] = useState(null);
  const [showEditRedirectModal, setShowEditRedirectModal] = useState(false);

  const {
    loading: gettingRedirects,
    data: redirectsData,
    refetch: refetchRedirects,
    error: redirectError,
  } = useGetAppRedirectsQuery();

  const defaultRedirects = useMemo(() => {
    if (!redirectsData) return [];
    const { __typename, ...newObj } = redirectsData?.getRedirects;
    return Object.keys(newObj || {}).map((key) => ({
      key,
      value: newObj[key],
    }));
  }, [redirectsData]);

  const handleRedirectClick = (redirect) => {
    setWorkingRedirect(redirect);
    setShowEditRedirectModal(true);
  };

  const handleKeyDown = (event) => (redirect) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      event.stopPropagation();
      handleRedirectClick(redirect);
    }
  };

  const filteredRedirects = defaultRedirects.filter(
    (r) => REDIRECT_MASK.includes(r.key) || r.value !== "/"
  );

  return (
    <>
      {gettingRedirects ? (
        <AccordionLoader noOfItems={1} />
      ) : (
        <PlanAccordionItem label="Redirects" value="redirects">
          <div>
            <p className="text-body-sm text-app-gray600 mb-4">
              These redirects apply to all members and can be overridden by
              plan-level redirects. &nbsp;
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/7384608370715"
                target="_blank"
                isExternal
                underline
              >
                Help Docs →
              </Link>
            </p>
            <div className="flex flex-col gap-2">
              {filteredRedirects.map(({ key, value }) => (
                <div
                  key={key}
                  onKeyDown={(e) => handleKeyDown(e)({ key, value })}
                  role="button"
                  tabIndex={0}
                  className="cursor-pointer p-3 hover:bg-app-gray50 shadow-sm rounded-lg w-full border border-app-gray300"
                  onClick={() => handleRedirectClick({ key, value })}
                >
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <p>{REDIRECT_MAP[key]}</p>
                      <InfoTooltip
                        content="Redirect upon event"
                        size="md"
                        tw="ml-1"
                      />
                    </div>
                    <Create
                      fill="black"
                      className="hoverState"
                      tw="!h-4 !w-4"
                    />
                  </div>
                  {value ? (
                    <div className="flex gap-2 items-center text-app-gray500">
                      <LinkOutlined tw="w-4! h-4!" />
                      <p>{value === "/" ? REDIRECT_MASK_MESSAGE : value}</p>
                    </div>
                  ) : (
                    <div tw="flex items-center text-app-gray400">
                      <p>No URLs</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </PlanAccordionItem>
      )}
      <EditRedirectModal
        setShowModal={setShowEditRedirectModal}
        showModal={showEditRedirectModal}
        refetch={refetchRedirects}
        redirect={workingRedirect}
      />
    </>
  );
};

export default DefaultRedirects;
