import React, {
  useState,
  useCallback,
  useMemo,
  ReactNode,
  useEffect,
} from "react";
import { AccordionContext } from "./Accordion.context";
import { StyledAccordion } from "./Accordion.styles";

interface Props {
  children: ReactNode;
  defaultExpanded?: string;
  name: string;
  className?: string;
  striped?: boolean;
  disabled?: boolean;
  isGSType?: boolean;
  persistent?: boolean;
  persistIn?: "session" | "local";
}

function Accordion({
  children,
  className,
  defaultExpanded,
  striped = true,
  name,
  disabled,
  isGSType = false,
  persistent = true,
  persistIn = "session",
}: Props) {
  const storage =
    persistIn === "session" ? window.sessionStorage : window.localStorage;

  const defaultActiveItem = persistent
    ? storage.getItem(name)
    : defaultExpanded;

  const [activeItem, setActiveItem] = useState(defaultActiveItem);

  useEffect(() => {
    if (!persistent) return;
    storage.setItem(name, activeItem);
  }, [activeItem, name, persistent, storage]);

  const setToggle = useCallback(
    (value) => {
      setActiveItem(() => {
        if (activeItem !== value) return value;
        return "";
      });
    },
    [activeItem]
  );

  const value = useMemo(
    () => ({
      activeItem,
      setToggle,
      defaultExpanded,
      striped,
      disabled,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeItem, striped, defaultExpanded]
  );

  return (
    <AccordionContext.Provider value={value}>
      <StyledAccordion
        striped={striped}
        className={className}
        disabled={disabled}
        isGSType={isGSType}
      >
        {children}
      </StyledAccordion>
    </AccordionContext.Provider>
  );
}

export default Accordion;
