import tw, { styled, css } from "twin.macro";

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

/**
 * All of this is necessary to:
 *  1. Properly wrap the input with the correct border-radius settings
 *  2. Select the outline containers and configure them as well. These must be targeted directly because there
 *  is no other way to use their background color and prevent overlap without breaking inline selects.
 */
export const top = {
  left: styled.div(() => [
    tw`flex-1 all-child:(rounded-none rounded-tl-md) focus-within:z-10`,
    css`
      & .inputContainer {
        border-radius: 0;
        border-top-left-radius: 6px;
      }
    `,
  ]),
  both: styled.div(() => [
    tw`flex-1 all-child:(rounded-none rounded-tl-md rounded-tr-md) focus-within:z-10`,
    css`
      & .inputContainer {
        border-radius: 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    `,
  ]),
  right: styled.div(() => [
    tw`flex-1 all-child:(rounded-none rounded-tr-md) focus-within:z-10`,
    css`
      & .inputContainer {
        border-radius: 0;
        border-top-right-radius: 6px;
      }
    `,
  ]),
};
export const bottom = {
  left: styled.div(() => [
    tw`flex-1 all-child:(rounded-none rounded-bl-md) focus-within:z-10`,
    css`
      & .inputContainer {
        border-radius: 0;
        border-bottom-left-radius: 6px;
      }
    `,
  ]),
  both: styled.div(() => [
    tw`flex-1 all-child:(rounded-none rounded-bl-md rounded-br-md) focus-within:z-10`,
    css`
      & .inputContainer {
        border-radius: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    `,
  ]),
  right: styled.div(() => [
    tw`flex-1 all-child:(rounded-none rounded-br-md) focus-within:z-10`,
    css`
      & .inputContainer {
        border-radius: 0;
        border-bottom-right-radius: 6px;
      }
    `,
  ]),
};

export const base = styled.div(() => [
  tw`flex-1 all-child:(rounded-none) focus-within:z-10`,
  css`
    & .inputContainer {
      border-radius: 0;
      border-bottom-right-radius: 6px;
    }
  `,
]);
