import React from "react";
import { Modal } from "components/Modal";
import Text from "components/Text";
import { Link } from "components";

export const CreatePriceModal = ({
  showModal,
  setShowModal,
  onConfirm,
  isLoading,
}) => (
  <Modal
    setShowModal={setShowModal}
    showModal={showModal}
    title="Create a new price"
    showDivider
    actionButtons={{
      cancel: {
        label: "Cancel",
      },
      confirm: {
        label: "Create new price",
        onConfirm: () => onConfirm(),
        isLoading,
      },
    }}
  >
    <Text className="mb-2">
      Once you create a new price, it cannot be edited. You will still be able
      to delete or archive it after it’s created.{" "}
      <Link
        to="https://docs.memberstack.com/hc/en-us/articles/7403200877211-Guide-to-Understanding-Paid-Plans-Prices-in-2-0"
        isExternal
        underline
        target="_blank"
        tw="!text-base"
      >
        Learn more
      </Link>
    </Text>
    <Text className="mb-2">Would you like to proceed? </Text>
  </Modal>
);
