import React from "react";
import { Card } from "components";
import Skeleton from "react-loading-skeleton";

export const TeamMemberTableSkeleton = () => (
  <Card>
    <div className="flex justify-between items-center p-4 border-b">
      <h3 className="text-base font-bold">
        <Skeleton height={28} width={125}/>
      </h3>
    </div>
    <div className="max-h-52 h-52 flex flex-col">
      <table className="w-full max-h-52 overflow-auto">
        <thead className="sr-only">
          <tr className="text-utility-md">
            <th className="p-4">Name</th>
            <th className="p-4">Email</th>
            <th className="p-4">Role</th>
            <th className="p-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4].map((row) => (
            <tr
              key={row}
              className="text-utility-md border-solid border-b last:border-b-0"
            >
              <td className="p-4">
                <Skeleton height={14} />
              </td>
              <td className="p-4">
                <Skeleton height={14} />
              </td>
              <td className="p-4">
                <Skeleton height={14} />
              </td>
              <td className="p-4">
                <Skeleton height={14} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Card>
);
