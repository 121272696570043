import React from "react";
import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import styled, { css } from "styled-components";
import { CopyButton } from "../Button";

interface Props extends ReactCodeMirrorProps {
  className?: string;
  copyMessage?: string;
  enableCopy?: boolean;
  hasBorder?: boolean;
  showLineNumbers?: boolean;
  enableFoldGutter?: boolean;
  isPulsingCopy?: boolean;
  onCopyClick?: () => void;
  description?: React.ReactNode;
}

const StyledCodeBlock = styled(CodeMirror)<{
  isBold?: boolean;
  $hasBorder?: boolean;
  $isWhiteBackground?: boolean;
}>`
  ${({ theme: { colors }, isBold, $hasBorder, $isWhiteBackground }) => css`
    position: relative;
    border: ${$hasBorder ? `1px solid ${colors.grey200}` : "none"};
    border-radius: ${$hasBorder ? "8px" : "none"};
    & > .cm-editor,
    .cm-scroller {
      font-size: 13px;
      line-height: 16px;
      border-radius: ${$hasBorder ? "8px" : "none"};
      & > .cm-content {
        padding: 12px 0;
      }
    }
    /* & > div {
      border-radius: 8px;
      background-color: ${$isWhiteBackground ? "white" : colors.grey100};
      font-size: 14px;
      line-height: 24px;
      font-weight: ${isBold ? 700 : 500};
      border: 1px solid ${colors.grey200};
    } */
  `}
`;

const Description = styled.div<{ $show: boolean }>`
  font-size: 12px;
  line-height: 20px;
  cursor: default;
  color: ${(props) => props.theme.colors?.grey600};
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  margin-top: ${(props) => (props.$show ? "4px" : "0px")};
  transform: ${(props) =>
    props.$show ? "translateY(0px)" : "translateY(-5px)"};
  height: ${(props) => (props.$show ? "100%" : "0px")};
`;

export const CodeBlock = ({
  value,
  height,
  minHeight,
  maxHeight,
  autoFocus,
  placeholder,
  extensions,
  editable,
  readOnly,
  className,
  onChange,
  onCopyClick,
  copyMessage,
  enableCopy = true,
  isPulsingCopy = false,
  hasBorder = true,
  showLineNumbers = true,
  enableFoldGutter = false,
  description,
}: Props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <div className="relative">
      <StyledCodeBlock
        className={className}
        $hasBorder={hasBorder}
        extensions={extensions}
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
        height={height}
        minHeight="34px"
        maxHeight={maxHeight}
        autoFocus={autoFocus}
        editable={editable}
        onChange={onChange}
        basicSetup={{
          lineNumbers: showLineNumbers,
          foldGutter: enableFoldGutter,
          searchKeymap: false,
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {enableCopy && (
        <CopyButton
          value={value}
          message={copyMessage}
          text="Copy"
          tw="absolute top-2 right-2"
          inverted
          isPulsing={isPulsingCopy}
          onClick={onCopyClick}
        />
      )}
      {description && (
        <Description $show={isFocused}>{description}</Description>
      )}
    </div>
  );
};
