import React, { useState } from "react";
import { usePostHog } from "posthog-js/react";
import { useNavigate, useParams } from "react-router-dom";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import { TitleSection } from "components/SettingsBar";
import { Button, Card, EmojiPicker, Link, Switch, Textarea } from "components";
import Input from "components/Input";
import { useCreatePlanMutation, useGetPlansQuery } from "features/plans";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";

interface Props {
  onBack: () => void;
  type: string;
  onChanges: (changes: { name?: string; icon?: string }) => void;
}

const NewPlanInfo = ({ onBack, type, onChanges }: Props) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { appId } = useParams<{ appId: string }>();

  const [icon, setIcon] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [signupPageURL, setSignupPageURL] = useState<string>("");
  const [upgradePageURL, setUpgradePageURL] = useState<string>("");
  const [enableTeamAccount, setEnableTeamAccount] = useState<boolean>(false);
  const { refetch } = useGetPlansQuery();
  const [createPlan] = useCreatePlanMutation();
  const { createToastAlert } = useToastAlertContext();

  const onCreatePlan = async () => {
    try {
      const result = await createPlan({
        variables: {
          input: {
            icon,
            name,
            description,
            isPaid: type === "PAID",
            teamAccountsEnabled: enableTeamAccount,
            teamAccountUpgradeLink: upgradePageURL,
            teamAccountInviteSignupLink: signupPageURL,
          },
        },
      });
      posthog?.capture("Plan Created", {
        "Plan Name": name,
        "Plan Type": type,
      });
      window.CommandBar?.trackEvent("new-plan-added", {});
      await refetch();
      navigate(`/apps/${appId}/plans/${result?.data?.createPlan?.id}`);
      createToastAlert({
        message: "Plan was successfully created.",
        alertType: "success",
      });
    } catch (error) {
      console.log(error);
      createToastAlert({
        message: "Error creating plan",
        alertType: "error",
      });
    }
  };

  return (
    <>
      <TitleSection
        title="Add a New Plan"
        backLink={`/apps/${appId}/plans`}
        backLinkTitle="All Plans"
        emphasize
      />
      <div className="flex flex-1 flex-col p-5 overflow-auto min-w-[415px]">
        <Input
          label="Name"
          value={name}
          onChange={(e) => {
            onChanges({ name: e.target.value, icon });
            setName(e.target.value);
          }}
          placeholder="Enter a plan name"
          leading={
            <EmojiPicker
              icon={icon}
              onSelect={(emoji) => {
                onChanges({ name, icon: emoji });
                setIcon(emoji);
              }}
            />
          }
          required
          dataCy="plan-name-input"
          className="mb-6"
          description="Used in the profile, checkout, invoices, etc. "
        />
        <Textarea
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter a plan description here"
          required={false}
          description="Optional. May appear in checkout & billing portal."
          className="mb-4"
        />
        {type === "PAID" && (
          <div className="p-3 flex flex-col gap-4 bg-app-gray50 rounded-lg mb-4">
            <Switch
              id="enableTeamAccount"
              label="Enable Team Accounts"
              description={
                <p className="text-body-sm text-app-gray600">
                  Allow this plan to have team account prices.{" "}
                  <Link
                    to="https://docs.memberstack.com/hc/en-us/articles/27489493916315-Team-Accounts-Start-to-Finish"
                    target="_blank"
                    isBold
                    isExternal
                  >
                    Learn more
                  </Link>
                </p>
              }
              onChange={() => {
                setEnableTeamAccount(!enableTeamAccount);
              }}
              isChecked={enableTeamAccount}
            />
            {enableTeamAccount && (
              <>
                <Input
                  id="signupPageURL"
                  label="Signup Page URL Path"
                  value={signupPageURL}
                  onChange={(e) => setSignupPageURL(e.target.value)}
                  placeholder="e.g. signup"
                  hideIndicators
                  leading={<>yourdomain.com/</>}
                />
                <Input
                  id="upgradePageURL"
                  label="Upgrade Page URL Path"
                  value={upgradePageURL}
                  onChange={(e) => setUpgradePageURL(e.target.value)}
                  placeholder="e.g. upgrade"
                  hideIndicators
                  leading={<>yourdomain.com/</>}
                />
              </>
            )}
          </div>
        )}
        <div className="flex-1" />
        {type === "PAID" && (
          <a
            href="https://docs.memberstack.com/hc/en-us/articles/17712014688923"
            target="_blank"
            className="cursor-pointer"
            rel="noreferrer"
          >
            <Card
              className="p-3 text-base cursor-pointer"
              hideShadow
              type="primary"
            >
              <div className="flex items-center mb-1.5">
                <PhotoOutlinedIcon
                  className="text-app-blue400"
                  style={{ fontSize: 24 }}
                />
                <p className="font-bold ml-1.5 text-base">
                  Want to Add an Image?
                </p>
              </div>
              <p className="text-app-gray600 text-utility-md leading-6 mb-2">
                You can add an image in Stripe once your plan has been created.
              </p>
            </Card>
          </a>
        )}
      </div>
      <div className="flex justify-between items-center p-5 bg-white">
        <Button text="Back" buttonStyle="skeleton" onClick={onBack} />
        <Button
          text="Create Plan"
          isDisabled={!name}
          onClick={onCreatePlan}
          // isLoading={isSubmitting}
          dataCy="create-plan-button"
        />
      </div>
    </>
  );
};

export default NewPlanInfo;
