import React, { lazy, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import Skeleton from "react-loading-skeleton";
import cn from "helpers/cn";
import { getUIAvatarLink } from "helpers/getUIAvatarLink";
import { useGetPostsQuery } from "features/comments/queries.generated";

import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import { Button, Card, Link, Table } from "components";
import Tag from "components/Tag";

const EmptyMemberTableIcon = lazy(
  () => import("assets/images/empty_members_table.svg")
);

const TableSkeleton = () => (
  <Table
    isHeadersInteractive={false}
    isRowsInteractive={false}
    numberOfData={5}
  >
    <tbody>
      {[1, 2, 3, 4, 5]?.map((row) => {
        return (
          <tr key={row}>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

export const NewCommentsTable = () => {
  const navigate = useNavigate();
  const { data, loading } = useGetPostsQuery({
    variables: {
      first: 4,
    },
  });

  const rowData = useMemo(() => {
    if (data?.getPosts?.edges) {
      return sortBy(data.getPosts.edges, "node.createdAt")
        .reverse()
        .map((edge) => {
          return {
            id: edge.node.id,
            email: edge.node.member?.auth?.email,
            profileImage: edge.node.member?.profileImage,
            content: edge.node.content,
            isNew: edge.node.isNew,
          };
        });
    }
    return [];
  }, [data]);

  const table = useMemo(() => {
    if (!rowData?.length)
      return (
        <div className="relative flex items-center content-center">
          <div className="flex content-center flex-col ml-[56px]">
            <p className="font-bold text-base mb-1 w-[378px]">
              Get Started with Commenting
            </p>
            <p className="text-body-sm text-app-gray500 w-[378px]">
              There are no comments yet. Start engaging with your community by
              adding Commenting to your site.
            </p>
            <Button
              text="Install Commenting"
              tw="mt-4"
              onClick={() => navigate("../community/ui-components")}
              className="w-min"
            />
          </div>
          <SuspenseWrapper>
            <EmptyMemberTableIcon />
          </SuspenseWrapper>
        </div>
      );

    return (
      <Table
        isHeadersInteractive={false}
        isRowsInteractive={false}
        numberOfData={rowData?.length}
      >
        <tbody>
          {rowData?.map((row) => {
            const userImgLink =
              row.profileImage || getUIAvatarLink({ name: row.email });
            return (
              <tr
                key={row.id}
                data-cy="member-row"
                className={cn({ "active": row.isNew }, "cursor-pointer")}
                onClick={() => navigate("../community")}
              >
                <td className="flex items-center gap-3 justify-between comment-table">
                  <div className="flex items-center gap-3">
                    <img
                      alt={`${row.email}'s avatar`}
                      src={userImgLink}
                      className="rounded-full p-px w-6 h-6 object-cover"
                    />
                    <p className="text-utility-tag-md truncate w-80">
                      {row.content}
                    </p>
                  </div>
                  {row.isNew && (
                    <Tag text="NEW" variant="fadedBlue" size="small" isBold />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }, [navigate, rowData]);

  return (
    <Card>
      <div className="flex items-center justify-between p-4 border-b">
        <h2 className="font-bold text-base">New Comments</h2>
        {loading ? (
          <Skeleton width={60} height={28} />
        ) : (
          rowData.length > 0 && (
            <Link
              to="../community"
              className="text-base"
              showAsLink
              dataCy="comments"
            >
              View all
            </Link>
          )
        )}
      </div>
      {loading || !data ? <TableSkeleton /> : table}
    </Card>
  );
};
