import React, { useCallback, useState } from "react";
import { Modal } from "components/Modal";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { useContentGroupsContext } from "../context";
import {
  useDeleteRestrictedUrlMutation,
  useDeleteRestrictedUrlGroupMutation,
} from "../mutations.generated";

const DeleteContentGroup = ({
  showModal,
  setShowModal,
  group,
  closeModal = () => {},
}) => {
  const [deleteRestrictedUrl, { loading: deletingRestrictedUrl }] =
    useDeleteRestrictedUrlMutation();
  const [deleteRestrictedUrlGroup, { loading: deletingRestrictedUrlGroup }] =
    useDeleteRestrictedUrlGroupMutation();
  const { createToastAlert } = useToastAlertContext();
  const { refetchContentGroups } = useContentGroupsContext();

  const [modalError, setModalError] = useState(null);

  const handleDelete = useCallback(async () => {
    createToastAlert({ processing: true });

    try {
      await Promise.all(
        group?.urls.map((url) =>
          deleteRestrictedUrl({
            variables: {
              input: {
                urlId: url.id,
              },
            },
          })
        )
      );
      await deleteRestrictedUrlGroup({
        variables: {
          input: {
            restrictedUrlGroupId: group?.id,
          },
        },
      });
      setShowModal(false);
      createToastAlert({
        alertType: "success",
        message: "Gated Content successfully deleted.",
      });

      refetchContentGroups();
    } catch (err) {
      setModalError(err);
    } finally {
      closeModal();
    }
  }, [group?.id, group?.urls, setShowModal, closeModal]);

  return (
    <Modal
      name="delete-content-group"
      setShowModal={setShowModal}
      showModal={showModal}
      title="Confirm deletion"
      description="This action cannot be undone"
      showDivider
      actionButtons={{
        confirm: {
          label: "Delete",
          onConfirm: handleDelete,
          isLoading: deletingRestrictedUrl || deletingRestrictedUrlGroup,
          buttonStyle: "danger",
          dataCy: "content-group-delete-confirm",
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <p>
        Are you sure? <strong>{group?.name}</strong> content will no longer be
        gated on your site.
      </p>
    </Modal>
  );
};

export default DeleteContentGroup;
