import React, { lazy, useEffect, useState } from "react";
import { useDisconnectStripeMutation, useUpdateAppMutation } from "features";
import { Button, Card, Link } from "components";
import useSubmit from "hooks/useSubmit";
import useStripeConnect from "hooks/useStripeConnect";
import { InfoTooltip } from "components/Tooltip";
import useAppNavigator from "hooks/useAppNavigator";
import { isObjectEqual } from "helpers/isObjectEqual";
import { Modal } from "components/Modal";
import { StripeSwitch } from "./StripeSwitch";

const StripeBilling = lazy(() => import("./assets/billing_address.svg"));
const StripeIDEAL = lazy(() => import("./assets/stripe_IDEAL.svg"));
const StripeTax = lazy(() => import("./assets/stripe_tax.svg"));
const RemoveCancel = lazy(() => import("./assets/remove_cancel.svg"));

const DEFAULT_PREVIEWS = {
  stripeTaxPreview: false,
  stripeBillingPreview: false,
  stripeId: false,
};

interface Props {
  stripeSettings: {
    stripeTaxIdCollectionEnabled: boolean;
    stripeBillingAddressCollectionEnabled: boolean;
    stripeIdealEnabled: boolean;
    stripePortalCancelImmediately: boolean;
    stripeDisablePaymentModeInvoices: boolean;
    stripePortalDisableCancel: boolean;
  };
  appId: string;
  hasPaidPlans: boolean;
  isStripeConnected: boolean;
  stripeAccountId: string;
}

const StripeSettings = ({
  stripeSettings,
  appId,
  hasPaidPlans,
  isStripeConnected,
  stripeAccountId
}: Props) => {
  const { setHasUnsavedChanges } = useAppNavigator();
  const [inputs, setInputs] = useState({ ...stripeSettings });
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showPreview, setShowPreview] = useState(DEFAULT_PREVIEWS);

  const { url } = useStripeConnect({ appId });
  const handleConnectToStripe = () => window.open(url, "_blank");

  const [disconnectStripe, { loading: disconnecting }] =
    useDisconnectStripeMutation({
      update: (cache) => {
        cache.modify({
          id: cache.identify({ id: appId }),
          fields: {
            stripeConnection() {
              return null;
            },
          },
        });
      },
    });

  const handlePreviewClick = (event) =>
    setShowPreview({
      ...showPreview,
      [event.target.id]: !showPreview[event.target.id],
    });

  const handleInputsToggle = (id) =>
    setInputs({
      ...inputs,
      [id]: !inputs[id],
    });

  const [updateApp, { loading: updating }] = useUpdateAppMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify({ id: appId }),
        fields: {
          stripeTaxIdCollectionEnabled() {
            return data?.updateApp?.stripeTaxIdCollectionEnabled;
          },
          stripeBillingAddressCollectionEnabled() {
            return data?.updateApp?.stripeBillingAddressCollectionEnabled;
          },
          stripeIdealEnabled() {
            return data?.updateApp?.stripeIdealEnabled;
          },
          stripePortalCancelImmediately() {
            return data?.updateApp?.stripePortalCancelImmediately;
          },
          stripeDisablePaymentModeInvoices() {
            return data?.updateApp?.stripeDisablePaymentModeInvoices;
          },
          stripePortalDisableCancel() {
            return data?.updateApp?.stripePortalDisableCancel;
          },
        },
      });
    },
  });

  const { submit: handleUpdate } = useSubmit({
    action: updateApp,
    fields: {
      ...inputs,
    },
    success: "Your settings were successfully updated",
  });

  const { submit: handleDisconnect } = useSubmit({
    action: disconnectStripe,
    success: "Your have been successfully disconnected from Stripe",
  });

  const {
    stripeBillingAddressCollectionEnabled,
    stripeIdealEnabled,
    stripeTaxIdCollectionEnabled,
    stripePortalCancelImmediately,
    stripeDisablePaymentModeInvoices,
    stripePortalDisableCancel,
  } = inputs;

  const didFormChange = !isObjectEqual(inputs, stripeSettings);

  useEffect(() => {
    if (didFormChange) {
      return setHasUnsavedChanges(true);
    }
    return setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didFormChange]);

  const buttonText = () => {
    if (isStripeConnected) {
      if (hasPaidPlans) {
        return "Connected";
      }
      return "Disconnect";
    }
    return "Connect";
  };

  return (
    <form>
      <Button
        type="submit"
        text="Save"
        tw="absolute right-5 top-5"
        onClick={() => handleUpdate()}
        isLoading={updating}
        isDisabled={!didFormChange}
      />
      <Card className="p-4 flex flex-col">
        <h3 className="text-h3 font-bold mb-4">Connect Stripe</h3>
        <p className="mb-4 text-body-sm lg:w-3/4 w-full">
          Stripe is the easiest way to accept credit cards, and process major
          international debit or credit cards, including Visa, MasterCard and
          American Express. You don’t need a merchant account, so you can start
          accepting payments today.
        </p>
        <div className="flex items-center gap-4">
          <Button
            text={buttonText()}
            isDisabled={isStripeConnected && hasPaidPlans}
            onClick={() =>
              isStripeConnected ? handleDisconnect() : setShowConnectModal(true)
            }
            tw="w-[fit-content]"
            buttonStyle={
              isStripeConnected && !hasPaidPlans ? "danger" : "default"
            }
            isLoading={disconnecting}
          />
          {isStripeConnected && hasPaidPlans && (
            <div className="flex items-center gap-1">
              <p className="text-utility-md text-app-gray600">
                Want to disconnect?
              </p>
              <InfoTooltip
                placement="right-start"
                content={
                  <>
                    To disconnect, you will need to create a new App in
                    Memberstack and connect your new Stripe account. If this is
                    a major inconvenience, please contact the support team for
                    more information to make the transition easier.{" "}
                    <Link
                      to="https://docs.memberstack.com/hc/en-us/articles/7921241180699"
                      target="_blank"
                      isExternal
                      underline
                      tw="text-white"
                    >
                      Learn more.
                    </Link>
                  </>
                }
              />
            </div>
          )}
        </div>
        {isStripeConnected && stripeAccountId && (
          <p className="text-body-sm font-bold mt-4">
            Connected Account:  <span className="text-app-blue400">{stripeAccountId}</span>
          </p>
        )}
        <Modal 
          title="Connect Stripe"
          showModal={showConnectModal}
          setShowModal={setShowConnectModal}
          actionButtons={{
            confirm: {
              label: "Continue to Stripe",
              onConfirm: handleConnectToStripe,
              isDisabled: false,
              isLoading: disconnecting,
              dataCy: "stripe-connect-button",
            },
            cancel: { label: "Back" },
          }}
        >
          <p className="text-body-sm">
            <span className="font-bold">An app can only link to one Stripe account.</span> &nbsp;
            Ensure you connect the right account as removing it requires deleting all plans and members.
            <br/>
          </p>
          <p className="text-body-sm mt-2">Verify your Stripe details carefully before linking.&nbsp;
            <Link 
              to="https://docs.memberstack.com/hc/en-us/articles/7403068886171-Connect-to-Stripe" 
              target="_blank" 
              isExternal 
              underline
            >
              Learn more.
            </Link> 
          </p>
        </Modal>
      </Card>
      <>
        <h3 tw="text-h3 font-bold my-4">Checkout Settings</h3>
        <StripeSwitch
          className="mb-4"
          PreviewComponent={<StripeTax />}
          docs="https://docs.memberstack.com/hc/en-us/articles/11086083578907"
          isChecked={stripeTaxIdCollectionEnabled}
          id="stripeTaxIdCollectionEnabled"
          label="Enable Stripe Tax ID collection"
          onChange={() => handleInputsToggle("stripeTaxIdCollectionEnabled")}
          onClick={(event) => handlePreviewClick(event)}
          description="With tax ID collection enabled, Checkout shows and hides the tax ID collection form depending on your customer’s location."
        />
        <StripeSwitch
          className="mb-4"
          PreviewComponent={<StripeBilling />}
          docs="https://docs.memberstack.com/hc/en-us/articles/11086044293147"
          isChecked={stripeBillingAddressCollectionEnabled}
          id="stripeBillingAddressCollectionEnabled"
          label="Enable Billing Address collection"
          onChange={() =>
            handleInputsToggle("stripeBillingAddressCollectionEnabled")
          }
          onClick={(event) => handlePreviewClick(event)}
          description="The Address Element is an embeddable UI component that autofill your customers’ billing and shipping addresses quickly and accurately."
        />
        <StripeSwitch
          className="mb-4"
          PreviewComponent={<StripeIDEAL />}
          docs="https://docs.memberstack.com/hc/en-us/articles/11086110811547"
          isChecked={stripeIdealEnabled}
          id="stripeIdealEnabled"
          label="Enable iDEAL & Apple Pay for one-time payments."
          onChange={() => handleInputsToggle("stripeIdealEnabled")}
          onClick={(event) => handlePreviewClick(event)}
          description="iDEAL is a Netherlands-based payment method that allows customers to complete transactions online using their bank credentials."
        />
        <StripeSwitch
          className="mb-4"
          PreviewComponent={<RemoveCancel />}
          isChecked={stripePortalDisableCancel}
          id="stripePortalDisableCancel"
          label="Remove the “Cancel” Button"
          onChange={() => handleInputsToggle("stripePortalDisableCancel")}
          onClick={(event) => handlePreviewClick(event)}
          description="Prevent member’s from cancelling their own accounts in the Stripe Portal. You will need to create your own cancellation process."
        />
        <StripeSwitch
          className="mb-4"
          isChecked={stripeDisablePaymentModeInvoices}
          id="stripeDisablePaymentModeInvoices"
          label="Disable Invoice Creation For One Time Payments"
          onChange={() =>
            handleInputsToggle("stripeDisablePaymentModeInvoices")
          }
          onClick={(event) => handlePreviewClick(event)}
          description="When enabled, invoices will not be generated for one-time payments, and customers won't see them in the Stripe portal. Only useful if you want to avoid Stripe's additional fee on one-time payments."
        />
        <StripeSwitch
          docs="https://docs.memberstack.com/hc/en-us/articles/12973251224347"
          isChecked={stripePortalCancelImmediately}
          id="stripePortalCancelImmediately"
          label="Cancel subscriptions immediately"
          onChange={() => handleInputsToggle("stripePortalCancelImmediately")}
          onClick={(event) => handlePreviewClick(event)}
          description='Changes the default cancellation behavior to "immediately" instead of "at_period_end".'
        />
      </>
    </form>
  );
};

export default StripeSettings;
