import React, { useMemo } from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import { ChevronRight, PlaylistAddCheck } from "@mui/icons-material";

import { Link } from "components";
import { RouteName } from "enums/routes";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { Stack } from "generatedTypes";
import { useSidebarContext } from "./context/sidebar.context";
import { NavContainer } from "./styles";

const StyledCard = styled(Link)`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  gap: 8px;
  & svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  .gs-icon {
    color: ${({ theme }) => theme.colors.grey600};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.grey050};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.blue050};
    & > div > div {
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
      > .gs-icon {
        color: ${({ theme }) => theme.colors?.blue400};
      }
    }
  }
`;

const StyledProgress = styled.progress`
  border-radius: 200px;
  border: 1px solid #ebebeb;
  background-color: white;
  height: 8px;
  ::-webkit-progress-bar {
    border-radius: 200px;
    background-color: white;
  }
  ::-webkit-progress-value {
    border-radius: 200px;
    background-color: ${({ theme }) => theme.colors.blue400};
  }
  ::-moz-progress-bar {
    border-radius: 200px;
    background-color: white;
  }
`;

const GetStartedLink = () => {
  const { appData, isLoading } = useAppDataContext();
  const { isExpanded, checkedItems } = useSidebarContext();

  const match = useRouteMatch(`/${RouteName.apps}/:appId`);
  const url = match?.url || "";

  const numberOfItems = useMemo(
    () => (appData?.stack === Stack.Webflow ? 7 : 5),
    [appData?.stack]
  );

  const progress = useMemo(
    () => (checkedItems.length / numberOfItems) * 100,
    [checkedItems.length, numberOfItems]
  );

  return (
    <NavContainer label="Get Started" isExpanded={isExpanded}>
      <StyledCard to={`${url}/${RouteName.gettingStarted}`}>
        <div className="flex items-center gap-2">
          <div className="p-1 rounded bg-white">
            <PlaylistAddCheck className="gs-icon" />
          </div>
          {isExpanded && (
            <div className="flex items-start flex-col">
              <p className="font-bold text-body-sm mb-1">
                Get Started &nbsp;
                <span className="font-normal">
                  {checkedItems.length} of {numberOfItems}
                </span>
              </p>
              <StyledProgress value={progress} max="100" />
            </div>
          )}
        </div>
        {isExpanded && <ChevronRight />}
      </StyledCard>
    </NavContainer>
  );
};

export default GetStartedLink;
