import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useContentGroupsContext } from "features/content-groups";
import { Button } from "components";
import { useToastAlertContext } from "components/toastAlert";
import { OptionType } from "components/Select";
import { RestrictedUrlGroup } from "generatedTypes";
import { Modal, ModalTypes } from "components/Modal";
import useDuplicateError from "hooks/useDuplicateError";
import { isObjectEqual } from "helpers/isObjectEqual";
import { generateID } from "helpers/generateID";
import DeleteContentGroup from "./DeleteContentGroupModal";
import {
  accessOptions,
  planChecker,
  getModifiedUrls,
  useContentGroupCalls,
  useHandleSubmit,
} from "./content-group.utils";
import GatedContentFields from "./GatedContentFields";

interface Props extends ModalTypes {
  selectedGroup?: RestrictedUrlGroup;
}

const EditContentGroupModal = ({
  showModal,
  setShowModal,
  selectedGroup,
}: Props) => {
  const [key, setKey] = useState("");
  const [name, setName] = useState("");
  const [redirect, setRedirect] = useState("");
  const [urlInputs, setUrlInputs] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [plans, setPlans] = useState<OptionType[]>([]);
  const [accessType, setAccessType] = useState(accessOptions[1]);
  const [customContentsInt, setCustomContents] = useState([]);

  const { errorMessage } = useDuplicateError(urlInputs);
  const { createToastAlert } = useToastAlertContext();
  const { refetchContentGroups } = useContentGroupsContext();
  const handleSubmit = useHandleSubmit();

  const {
    loadingMutations,
    createRestrictedUrl,
    deleteRestrictedUrl,
    updateRestrictedUrl,
    updateRestrictedUrlGroup,
    linkPlansToRestrictedUrlGroup,
    detachPlansFromRestrictedUrlGroup,
    createCustomContent,
    deleteCustomContent,
    updateCustomContent,
  } = useContentGroupCalls();

  const handleDelete = useCallback(() => setShowDeleteModal(true), []);

  const {
    allowAllMembers,
    id: restrictedUrlGroupId,
    key: contentKey,
    name: contentName,
    plans: contentPlans,
    redirect: groupRedirect,
    urls: restrictedUrls,
    customContent: customContentsExt,
  } = selectedGroup;

  const initialPlans = useMemo(
    () =>
      contentPlans?.map((plan) => ({
        label: plan?.name,
        value: plan?.id,
      })),
    [contentPlans]
  );

  useEffect(() => {
    setUrlInputs(restrictedUrls);
    setPlans(initialPlans);
    setName(contentName);
    setKey(contentKey);
    setRedirect(groupRedirect);
    setCustomContents(customContentsExt);
    setAccessType(
      allowAllMembers || restrictedUrls.length < 0
        ? accessOptions[1]
        : accessOptions[0]
    );
  }, [
    allowAllMembers,
    contentKey,
    contentName,
    customContentsExt,
    groupRedirect,
    restrictedUrls,
    initialPlans,
  ]);

  const plansCheck = planChecker(initialPlans, plans);

  // urls from query
  const restrictedUrlsId = restrictedUrls.map((url) => url.id);
  // current urls in state
  const workingUrlsId = urlInputs.map((url) => url.id);

  const createdUrls = urlInputs.filter((url) => !url?.id.startsWith("url"));
  const deletedUrlsIds = restrictedUrlsId.filter(
    (urlId) => !workingUrlsId.includes(urlId)
  );
  // Check for modified plans
  const modifiedUrls = getModifiedUrls(restrictedUrls, urlInputs);

  const initialAccessType = allowAllMembers
    ? accessOptions[1]
    : accessOptions[0];

  // checks if changes were made to name, key, redirect and if a different access type was granted
  const wasInputsChanged = useMemo(
    () =>
      contentName !== name ||
      contentKey !== key ||
      redirect !== groupRedirect ||
      !isObjectEqual(accessType, initialAccessType),
    [
      accessType,
      contentKey,
      contentName,
      groupRedirect,
      initialAccessType,
      key,
      name,
      redirect,
    ]
  );

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      // Some urls may have been modified to empty... push them to be deleted
      modifiedUrls.forEach(({ id, url }) => {
        if (!url) {
          deletedUrlsIds.push(id);
        }
      });

      // update restricted URL group if any of the inputs (name, key, redirect and access type) was changed
      if (wasInputsChanged) {
        await updateRestrictedUrlGroup({
          variables: {
            input: {
              restrictedUrlGroupId,
              name,
              key,
              redirect,
              allowAllMembers: accessType.value === "allMembers",
            },
          },
        });
      }

      // create and link newly added urls to restricted groups
      if (createdUrls.length) {
        Promise.all(
          createdUrls
            .filter(({ url }) => url?.length)
            .map(({ url, filter }) =>
              createRestrictedUrl({
                variables: {
                  input: {
                    url,
                    filter,
                    restrictedUrlGroupId,
                  },
                },
              })
            )
        );
      }

      // detach urls from restricted Url group if urls was deleted
      if (deletedUrlsIds.length) {
        await Promise.all(
          deletedUrlsIds.map((deletedUrlId) =>
            deleteRestrictedUrl({
              variables: {
                input: {
                  urlId: deletedUrlId,
                },
              },
            })
          )
        );
      }

      // run updateRestrictedUrl mutation if any urls were modified
      if (modifiedUrls.length) {
        await Promise.all(
          modifiedUrls
            .filter(({ url }) => url?.length)
            .map(({ url, filter, id: restrictedUrlId }) =>
              updateRestrictedUrl({
                variables: {
                  input: {
                    restrictedUrlId,
                    url,
                    filter,
                  },
                },
              })
            )
        );
      }

      // detach all plans that were removed from the list of connected plans
      if (plansCheck.deletedPlans.length) {
        await detachPlansFromRestrictedUrlGroup({
          variables: {
            input: {
              restrictedUrlGroupId,
              planIds: plansCheck.deletedPlans,
            },
          },
        });
      }

      // link all plans that is being recently added to the connected plans list
      if (plansCheck.addedPlans.length) {
        await linkPlansToRestrictedUrlGroup({
          variables: {
            input: {
              restrictedUrlGroupId,
              planIds: plansCheck.addedPlans,
            },
          },
        });
      }

      await refetchContentGroups();

      createToastAlert({
        alertType: "success",
        message: "Your changes were successfully updated.",
      });
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: `${err}`,
      });
    } finally {
      setShowModal(false);
    }
  };

  // check createdUrls array, filter out any empty url fields
  const filteredCreatedUrls = useMemo(
    () => createdUrls.filter((url) => url.url !== ""),
    [createdUrls]
  );

  const wasFormChanged =
    wasInputsChanged ||
    modifiedUrls.length > 0 ||
    plansCheck.deletedPlans.length > 0 ||
    plansCheck.addedPlans.length > 0 ||
    filteredCreatedUrls.length > 0 ||
    deletedUrlsIds.length > 0;

  return (
    <>
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title="Edit Gated Content"
        showDivider
        width="560px"
        showOverlay
        removePadding
        actionButtons={{
          confirm: {
            label: "Confirm",
            onConfirm: (e) => handleUpdate(e),
            isLoading: loadingMutations,
            isDisabled: !wasFormChanged || errorMessage === "DUPLICATE",
          },
        }}
        bottomSectionComponent={
          <Button
            text="Delete"
            dataCy="content-group-delete-button"
            buttonStyle="danger"
            onClick={handleDelete}
          />
        }
      >
        <GatedContentFields
          handleUpdate={handleUpdate}
          name={name}
          onNameChange={(e) => setName(e.target.value)}
          contentKey={key}
          onContentKeyChange={(e) => setKey(generateID(e.target.value))}
          accessType={accessType}
          onAccessTypeChange={(o) => setAccessType(o)}
          plans={plans}
          onPlansChange={(o) => setPlans(o)}
          urlInputs={urlInputs}
          setUrlInputs={setUrlInputs}
          redirect={redirect}
          setRedirect={setRedirect}
          customContentsInt={customContentsInt}
          setCustomContents={setCustomContents}
          className="flex flex-col"
          isEditMode
          onAddContent={(_content) =>
            handleSubmit({
              action: createCustomContent,
              successMessage: "Hosted content successfully created",
              input: { restrictedUrlGroupId, ..._content },
              refetch: refetchContentGroups,
            })
          }
          onDeleteContent={(id) =>
            handleSubmit({
              action: deleteCustomContent,
              successMessage: "Hosted content successfully deleted",
              input: { customContentId: id },
              refetch: refetchContentGroups,
            })
          }
          onUpdateContent={(content) =>
            handleSubmit({
              action: updateCustomContent,
              successMessage: "Hosted content successfully updated.",
              input: content,
              refetch: refetchContentGroups,
            })
          }
        />
      </Modal>
      <DeleteContentGroup
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        group={selectedGroup}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
};

export default EditContentGroupModal;
