import React, { useState, useMemo } from "react";
import CreateIcon from "@mui/icons-material/Create";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Text from "components/Text";
import { AuthProviderType } from "generatedTypes";
import Tag from "components/Tag";
import { Avatar, Button, Card } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import AuthConfigFormModal from "./AuthConfigFormModal";
import { useAuthPageContext } from "../context";
import PasswordlessModal from "./PasswordlessModal";

const mergeOn = (source, target, prop) =>
  source
    .filter((aObj) => !target.find((bObj) => aObj[prop] === bObj[prop]))
    .concat(target);

export const ConnectAuthProviders = () => {
  const { defaultAuthProviders, ssoClients } = useAuthPageContext();
  const { appData } = useAppDataContext();

  const rootDomain = appData?.customDomain?.rootDomain;

  // const [showFormModal, setShowFormModal] = useState(false);
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [showPasswordlessModal, setShowPasswordlessModal] = useState(false);
  const [provider, setProvider] = useState({
    name: "",
    providerType: AuthProviderType.Google,
    enabled: false,
    clientId: "",
    clientSecret: "",
  });

  const providers = mergeOn(defaultAuthProviders, ssoClients, "provider");

  // order providers by enabled true first and then by order value
  providers.sort((a, b) => {
    if (a.enabled === b.enabled) {
      return a.order - b.order;
    }
    return a.enabled ? -1 : 1;
  });

  const renderApplication = useMemo(
    () =>
      providers.map((ssoApp, idx) => {
        const { provider: pr, name, id } = ssoApp;
        return (
          <li key={id} data-testid={pr}>
            <Card
              hideShadow={false}
              className="p-4 flex justify-between items-center"
            >
              <div tw="flex items-center">
                <Avatar
                  alt={ssoApp.provider}
                  src={ssoApp.icon}
                  tw="mr-4"
                  size="small"
                />
                <p className="text-base select-none">{name}</p>
              </div>
              <div className="flex items-center">
                <Tag
                  text={ssoApp.enabled ? "Enabled" : "Disabled"}
                  variant={ssoApp.enabled ? "primary" : "secondary"}
                  className="mr-4"
                />
                <Button
                  text={ssoApp.enabled ? "Edit" : "Configure"}
                  buttonStyle="skeleton"
                  rightIcon={<CreateIcon />}
                  onClick={() => {
                    setShowConfigureModal(true);
                    setProvider(ssoApp);
                  }}
                />
              </div>
            </Card>
          </li>
        );
      }),
    [providers]
  );

  return (
    <>
      <ul
        className="list-none p-0 m-0 flex flex-col gap-2"
        data-testid="auth-providers-list"
      >
        <li data-testid="passwordless">
          <Card
            hideShadow={false}
            className="p-4 flex justify-between items-center"
          >
            <div tw="flex items-center">
              <MailOutlineIcon tw="mr-4" />
              <Text noMargin tw="select-none">
                Passwordless
              </Text>
            </div>
            <div tw="flex items-center">
              <Tag
                text={appData?.passwordlessEnabled ? "Enabled" : "Disabled"}
                variant={appData?.passwordlessEnabled ? "primary" : "secondary"}
                className="mr-4"
              />
              <Button
                text={appData?.passwordlessEnabled ? "Edit" : "Configure"}
                buttonStyle="skeleton"
                rightIcon={<CreateIcon />}
                onClick={() => setShowPasswordlessModal(true)}
              />
            </div>
          </Card>
        </li>
        {renderApplication}
      </ul>

      {/* <AddCustomAuthProvider
        setShowModal={setShowFormModal}
        showModal={showFormModal}
      /> */}
      <AuthConfigFormModal
        setShowModal={setShowConfigureModal}
        showModal={showConfigureModal}
        authProvider={provider}
        rootDomain={rootDomain}
      />
      <PasswordlessModal
        setShowModal={setShowPasswordlessModal}
        showModal={showPasswordlessModal}
      />
    </>
  );
};

export default ConnectAuthProviders;
