import React from "react";
import { ButtonProps } from "../Button/Button";
import * as S from "./ButtonGroup.styles";

type ButtonTypes = ButtonProps & { id: string };

interface Props {
  selected: string;
  buttonData: ButtonTypes[];
  onChange: (value: string | number) => void;
}

export const ButtonGroup = ({ selected, buttonData, onChange }: Props) => {
  return (
    <S.ButtonGroupContainer>
      {buttonData.map(({ text, id, leftIcon, rightIcon }, i) => (
        <S.StyledButton
          isActive={selected === id}
          key={id}
          onClick={() => onChange(id)}
        >
          {leftIcon}
          {text}
          {rightIcon && (
            <>
              <div className="ml-2" />
              {rightIcon}
            </>
          )}
        </S.StyledButton>
      ))}
    </S.ButtonGroupContainer>
  );
};
