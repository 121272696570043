import React, { useRef, useEffect } from "react";
import mergeClassNames from "../../helpers/mergeClassNames/mergeClassNames";

interface FormProps {
  children: React.ReactNode;
  className?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onValidityCheck?: (isValid: boolean) => void;
}

export const Form = ({
  children,
  className,
  onSubmit,
  onValidityCheck: checkValidity,
}: FormProps) => {
  const formEl = useRef(null);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    /**
     * Always preventDefault so that submit doesn't trigger a page refresh
     */
    event.preventDefault();

    onSubmit(event);
  };

  const handleValidityCheck = () => {
    if (!checkValidity) {
      return;
    }

    checkValidity(formEl?.current?.checkValidity());
  };

  useEffect(() => {
    handleValidityCheck();
  });

  return (
    <form
      onSubmit={handleSubmit}
      className={mergeClassNames("w-full flex flex-col", className)}
      ref={formEl}
      onChange={handleValidityCheck}
    >
      {children}
    </form>
  );
};

Form.defaultProps = {
  className: undefined,
  onValidityCheck: undefined,
  onSubmit: () => {},
};
