import { gql } from "@apollo/client";

export const ADD_WEBSITE_DOMAIN = gql`
  mutation AddAppDomain($input: AddDomainToAppInput!) {
    addDomain(input: $input) {
      url
    }
  }
`;

export const UPDATE_WEBSITE_DOMAIN = gql`
  mutation updateDomain($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      id
      url
      mode
    }
  }
`;

export const CONNECT_STRIPE = gql`
  mutation ConnectStripe($input: ConnectStripeAppInput!) {
    connectStripe(input: $input) {
      id
    }
  }
`;

export const DISCONNECT_STRIPE = gql`
  mutation DisconnectStripe {
    disconnectStripe {
      id
      stripeConnection {
        accountId
      }
    }
  }
`;

export const REMOVE_DOMAIN = gql`
  mutation RemoveDomain($input: RemoveDomainInput!) {
    removeDomain(input: $input)
  }
`;

export const UPDATE_CUSTOM_DOMAIN = gql`
  mutation UpdateCustomDomain($input: UpdateCustomDomainInput!) {
    updateCustomDomain(input: $input) {
      rootDomain
      authSubdomain
    }
  }
`;
