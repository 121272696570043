/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useCallback } from "react";
import { FilterType } from "generatedTypes";
import {
  AccordionItem,
  Button,
  Card,
  EmptyState,
  ErrorMessage,
  InputList,
  Link,
} from "components";
import useDuplicateError, { ErrorTypes } from "hooks/useDuplicateError";
import { removeLeadingSlash } from "helpers/removeLeadingSlash";
import TipBox from "components/TipBox";
import { cloneDeep } from "lodash";
import { Add } from "@mui/icons-material";
import Input from "components/Input";
import checkUrl from "helpers/checkUrl";
import { useContentGroupsContext } from "features/content-groups/context";

export const RestrictedURLs = ({
  formFields,
  setFormFields,
  redirect,
  setRedirect,
}) => {
  const { errorMessage } = useDuplicateError(formFields);
  const { filterTypes } = useContentGroupsContext();

  const handleOnChange = useCallback(
    (index) => (event) => {
      const data = cloneDeep(formFields);
      data[index].url = event.target.value;
      setFormFields(data);
    },
    [formFields]
  );

  const handleOnSelect = useCallback(
    (value, index) => {
      const data = cloneDeep(formFields);
      data[index].filter = value;
      setFormFields(data);
    },
    [formFields]
  );

  const handleAddField = (e) => {
    e.stopPropagation();
    const object = {
      url: "",
      filter: FilterType.Starts,
      id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    };
    const sanitizedFields = removeLeadingSlash(formFields);
    setFormFields([...sanitizedFields, object]);
  };

  const handleRemove = useCallback(
    (index) => () => {
      const data = [...formFields];
      data.splice(index, 1);
      setFormFields(data);
    },
    [formFields]
  );

  const filters = Object.keys(filterTypes).map((filterKey) => ({
    value: filterKey,
    label: filterTypes[filterKey],
  }));

  const inputs = useMemo(
    () =>
      formFields.map(({ url, filter }, idx) => ({
        id: `restricted-input-${idx}`,
        disablePadding: true,
        value: url,
        onChange: handleOnChange(idx),
        selectValue: filters.find((f) => f.value === filter),
        onSelect: (o) => handleOnSelect(o.value, idx),
        options: filters,
        onInputDelete: handleRemove(idx),
      })),
    [filters, formFields]
  );

  const renderError = useMemo(() => {
    if (errorMessage === ErrorTypes.Multiple) {
      return (
        <TipBox title="Time Saving Tip">
          You can use “Starts with” to gate multiple pages at once.{" "}
          <Link
            to="https://www.loom.com/share/71e03b118acb46fa80784b8267a53477"
            target="_blank"
            isExternal
            underline
            tw="text-app-gray600"
          >
            This 60-second video
          </Link>{" "}
          will show you a few ways this can be done.{" "}
        </TipBox>
      );
    }
    if (errorMessage === ErrorTypes.Duplicate) {
      return <ErrorMessage message="You cannot add the same URL twice." />;
    }
    return null;
  }, [errorMessage]);

  return (
    <AccordionItem
      label="Restricted URLs"
      value="restricted-urls"
      rightComponent={
        <Button
          leftIcon={<Add />}
          text="Add"
          buttonStyle="skeleton"
          onClick={handleAddField}
          className="py-1.5"
        />
      }
    >
      <div>
        <p className="text-body-sm text-app-gray600 mb-4">
          What folders and pages would you like to gate access to?
        </p>
        {formFields.length === 0 ? (
          <EmptyState
            id="empty-state-restricted-urls"
            text="Add a restricted URL"
            description="What folders and pages would you like to gate access to?"
            buttonText="Add URL"
            docsLink="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
            onCreateClick={handleAddField}
          />
        ) : (
          <>
            <InputList
              inputs={inputs}
              label="Restrict specific URLs"
              description={
                <>
                  Use "Starts with" to hide all pages within a specific folder
                  or "Equals" to hide specific pages. Learn more.{" "}
                  <Link
                    to="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
                    isExternal
                    underline
                  >
                    Help Docs →
                  </Link>
                </>
              }
              placeholder="e.g. members-only/"
              button={
                <Button
                  text="+ Add URL"
                  buttonStyle="transparent"
                  onClick={handleAddField}
                  tw="w-20"
                />
              }
            />
            {renderError}
          </>
        )}
        {formFields.length > 0 && <Card hideShadow tw="bg-app-gray50 p-2 mt-3">
          <Input
            id="access-denied-url"
            hideIndicators
            label="Access Denied URL"
            description="Unauthorized users will be redirected to this URL."
            placeholder="e.g. upgrade"
            value={redirect}
            onChange={(e) => setRedirect(e.target.value)}
            leading={checkUrl(redirect) ? null : "yourdomain.com/"}
          />
        </Card>}
      </div>
    </AccordionItem>
  );
};
