type TAvatarLink = {
  name: string;
  color?: string;
};

export const getUIAvatarLink = ({ name, color }: TAvatarLink) => {
  const bgColor = color ? `${color?.split("#")[1]}` : "828282";
  return `https://ui-avatars.com/api/?name=${name?.replace(
    / /g,
    "-"
  )}&background=${bgColor}&color=fff`;
};
