import { FilterType } from "generatedTypes";

export const removeLeadingSlash = (
  formField: { url: string; filter: FilterType; id: string }[]
) => {
  const fields = formField.map(({ url, filter, id }) => ({
    url: url.replace(/^\/+/, ""),
    filter,
    id,
  }));
  return fields;
};
