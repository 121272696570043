import React, { useCallback, useMemo, useState } from "react";
import Input from "components/Input";
import { Check, DeleteOutline } from "@mui/icons-material";
import { DomainMode, WebflowDomain } from "generatedTypes";
import { modes } from "constants/domainModes";
import { useSpring } from "react-spring";
import useClickout from "hooks/useClickout";
import { Button } from "../Button";
import { OptionsContainer, Option } from "./DomainInput.styles";
import { ModeSelector } from "./ModeSelector";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface DomainInputType {
  id?: string;
  url: string;
  mode?: string;
}
interface Props {
  className?: string;
  domainMode?: DomainMode;
  domainUrl?: string;
  domainId?: string;
  placeholder?: string;
  onDelete?: () => void;
  onUpdate?: (domainInput: DomainInputType) => void;
  onAdd?: (domainInput: DomainInputType) => void;
  isUpdating?: boolean;
  isLiveOptionDisabled?: boolean;
  suggestions?: WebflowDomain[];
}

export const DomainInput = ({
  domainMode,
  domainUrl,
  domainId,
  className,
  onDelete,
  onUpdate,
  onAdd,
  isUpdating,
  placeholder,
  isLiveOptionDisabled,
  suggestions,
}: Props) => {
  const [url, setUrl] = useState<string>(domainUrl || "");
  const [mode, setMode] = useState<string>(domainMode || modes[1]);
  const { ref, isVisible, setIsVisible } = useClickout(false);

  const suggestionAnimation = useSpring({
    width: isVisible ? "100%" : "0%",
    transform: isVisible ? "translateY(0%)" : "translateY(-10%)",
    opacity: isVisible ? 1 : 0,
    transitionDuration: "0s",
  });

  const isUpdated = useMemo(() => {
    return mode !== domainMode || url !== domainUrl;
  }, [domainMode, domainUrl, mode, url]);

  const handleOnClick = useCallback(() => {
    if (domainUrl === undefined) {
      onAdd({ url, mode });
      return setUrl("");
    }
    if (domainUrl !== undefined && isUpdated) {
      return onUpdate({ id: domainId, url, mode });
    }
    return onDelete();
  }, [domainId, domainUrl, isUpdated, mode, onAdd, onDelete, onUpdate, url]);

  const buttonIcon = useMemo(
    () => (isUpdated || !domainUrl ? <Check /> : <DeleteOutline />),
    [domainUrl, isUpdated]
  );

  const suggestionsList = useMemo(() => {
    if (suggestions?.length > 0 && isVisible) {
      return suggestions?.map((suggestion) => (
        <Option
          aria-hidden="true"
          key={suggestion?.domain}
          role="button"
          $isSelected={suggestion?.domain === url}
        >
          <span
            className="block truncate text-body-sm"
            aria-hidden="true"
            role="button"
            onClick={() => {
              setUrl(suggestion?.domain);
              setIsVisible(false);
            }}
          >
            {suggestion?.domain}
          </span>
        </Option>
      ));
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, suggestions, url]);

  return (
    <div className={classNames("flex items-center", className)} ref={ref}>
      <div className="relative w-full">
        <Input
          tw="w-full"
          leading="https://"
          leadingInline
          hideIndicators
          trailingInline
          trailing={
            <ModeSelector
              initialMode={mode}
              isLiveOptionDisabled={isLiveOptionDisabled}
              onChange={(_mode) => {
                setMode(_mode);
              }}
            />
          }
          placeholder={placeholder}
          onChange={(e) => setUrl(e.target.value)}
          onClick={() => setIsVisible(!isVisible)}
          value={url}
          dataCy="domain-input"
        />
        {suggestions && (
          <OptionsContainer
            tw="absolute top-[42px]"
            style={suggestionAnimation}
          >
            {suggestionsList}
          </OptionsContainer>
        )}
      </div>
      {!domainUrl ? (
        <Button
          className="h-10 ml-2 items-center"
          leftIcon={buttonIcon}
          buttonStyle="skeleton"
          onClick={handleOnClick}
          isDisabled={!url}
          isLoading={isUpdating}
        />
      ) : (
        <Button
          className="h-10 ml-2 items-center"
          leftIcon={buttonIcon}
          buttonStyle="skeleton"
          onClick={handleOnClick}
          isLoading={isUpdating}
        />
      )}
    </div>
  );
};
