import React from "react";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { RowWrapper, top, bottom, base } from "./styles";

interface InputGroupProps {
  items: React.ReactNode[][];
  disabled?: boolean;
}

export const InputGroup = ({ items, disabled }: InputGroupProps) => {
  return (
    <div className={mergeClassNames(disabled && "opacity-50", "-space-y-px")}>
      {items.map((row, rIdx, rArr) => {
        const phase = (rIdx === 0 && top) ||
          (rIdx === rArr.length - 1 && bottom) || {
            left: undefined,
            right: undefined,
            both: undefined,
          };
        return (
          // eslint-disable-next-line react/no-array-index-key
          <RowWrapper tw="-space-x-px" key={rIdx}>
            {row.map((item, cIdx, cArr) => {
              const wrap = [
                (cIdx === 0 && cArr.length === 1 && phase?.both) ||
                  (cIdx === 0 && phase?.left) ||
                  (cIdx === cArr.length - 1 && phase?.right) ||
                  base,
              ];
              return wrap.reduce(
                (accu, Layer) =>
                  Layer ? <Layer key={`${rIdx + cIdx}`}>{accu}</Layer> : accu,
                item
              );
            })}
          </RowWrapper>
        );
      })}
    </div>
  );
};
