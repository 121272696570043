import React from "react";
import { useTabsContext } from "./Tabs.context";
import * as S from "./styles";

interface TabItemProps {
  id: string;
  label: string;
  Icon?: React.ReactNode;
  url?: string;
}

const TabItem = ({ id, label, Icon, url = null }: TabItemProps) => {
  const { activeTab, setActiveTab, isExpanded, onTabChange } = useTabsContext();

  const handleClick = () => {
    setActiveTab(id);
    if (onTabChange) {
      onTabChange(id);
    }
  };

  return (
    <>
      {React.createElement(
        url ? S.TabLink : S.TabAnchor,
        {
          id,
          $isExpanded: isExpanded,
          to: url || undefined,
          onClick: handleClick,
          $active: activeTab === id,
          className: "whitespace-nowrap",
          "data-testid": `tab-${id}`,
        },
        <>
          {Icon && <>{Icon}</>}
          {label}
        </>
      )}
    </>
  );
};

export default TabItem;
