import { useMemo, useCallback } from "react";
import { currencies as options } from "../../constants/currencies";

export const useCurrencies = () => {
  const currencies = useMemo(
    () =>
      options.map(({ code, label, symbol }) => ({
        label: code,
        value: label,
        symbol,
      })),
    []
  );

  const getCurrencySymbol = useCallback(
    (currency) => currencies.find(({ value }) => value === currency).symbol,
    [currencies]
  );

  return { currencies, getCurrencySymbol };
};
