import React from "react";
import { Modal, ModalTypes } from "components/Modal";
import { messages } from "constants/messages";

interface Props extends ModalTypes {
  onConfirm: () => void;
  message?: string;
}

export const UnsavedChanges = ({
  showModal,
  setShowModal,
  onConfirm,
  message,
}: Props) => {
  const alertMessage = message
    ? `${message}</br> If you leave this page, your changes will be lost.`
    : messages.defaultUnsavedMessage;

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Discard changes?"
      description={alertMessage}
      showDivider
      actionButtons={{
        confirm: {
          label: "Discard",
          onConfirm,
          buttonStyle: "danger",
        },
        cancel: {
          label: "Go Back",
        },
      }}
    />
  );
};
