import styled, { css } from "styled-components";

interface ListItemsProps {
  inHouseStyled?: boolean;
}

export const ListItem = styled.div<ListItemsProps>(
  ({ inHouseStyled }) => css`
    display: inline-flex;
    align-items: baseline;
    width: 100%;
    & > div:first-of-type {
      ${inHouseStyled &&
      css`
        background-color: rgba(231, 241, 255, 1);
        color: rgba(98, 161, 255, 1);
        border-radius: 9999px;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    }
    & > div:last-of-type {
      width: 100%;
      margin-left: 8px;
    }
  `
);

export const ListDescription = styled.div`
  font-size: 14px;
  line-height: 24px;
`;

export const ListHeader = styled.h4`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16.8px;
  line-height: 28px;
`;

export const ListContent = styled.div`
  width: 100%;
`;
