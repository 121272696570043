import React from "react";
import Input from "components/Input";
import { ProgressBar } from "components/ProgressBar/ProgressBar";

export const ChangePasswordForm = ({
  handleSubmit,
  disableInputs,
  formProgress,
  formInputs,
  handleInputChange,
}) => (
  <form onSubmit={(e) => handleSubmit(e)} className="py-7 px-5">
    <ProgressBar progress={formProgress} tw="mb-5" />
    <Input
      disabled={disableInputs}
      name="reset_enter_code"
      label="Enter your 6-digit code"
      placeholder="Enter your 6-digit code"
      tw="mb-5"
      hideIndicators
      value={formInputs.reset_enter_code}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="choose_new_password"
      label="Choose a new password"
      placeholder="Choose a new password"
      tw="mb-5"
      hideIndicators
      value={formInputs.choose_new_password}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="reset_password_placeholder"
      label="Enter password"
      placeholder="Enter password"
      tw="mb-5"
      hideIndicators
      value={formInputs.reset_password_placeholder}
      onChange={handleInputChange}
    />
    <Input
      disabled={disableInputs}
      name="reset_confirm_reset"
      label="Confirm & Reset"
      placeholder="Confirm & Reset"
      tw="mb-5"
      hideIndicators
      value={formInputs.reset_confirm_reset}
      onChange={handleInputChange}
    />
  </form>
);
