import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateEmailsMutationVariables = Types.Exact<{
  input: Types.UpdateEmailsInput;
}>;


export type UpdateEmailsMutation = (
  { __typename?: 'Mutation' }
  & { updateEmails: (
    { __typename?: 'Emails' }
    & Pick<Types.Emails, 'from'>
  ) }
);

export type GetEmailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmailsQuery = (
  { __typename?: 'Query' }
  & { getEmails?: Types.Maybe<(
    { __typename?: 'Emails' }
    & Pick<Types.Emails, 'from' | 'verified' | 'emailVerificationEnabled' | 'requireEmailVerification' | 'welcomeEmailsEnabled' | 'abandonedCartEmailsEnabled' | 'abandonedCartExpireMinutes'>
    & { welcome?: Types.Maybe<(
      { __typename?: 'welcomeTemplate' }
      & Pick<Types.WelcomeTemplate, 'replyTo' | 'subject' | 'paragraph1' | 'paragraph2'>
    )>, passwordReset?: Types.Maybe<(
      { __typename?: 'passwordResetTemplate' }
      & Pick<Types.PasswordResetTemplate, 'replyTo' | 'subject' | 'paragraph1' | 'paragraph2'>
    )>, emailVerification?: Types.Maybe<(
      { __typename?: 'emailVerificationTemplate' }
      & Pick<Types.EmailVerificationTemplate, 'replyTo' | 'subject' | 'paragraph1' | 'paragraph2' | 'paragraph3' | 'primaryButtonText'>
    )>, passwordlessLogin?: Types.Maybe<(
      { __typename?: 'passwordlessLoginTemplate' }
      & Pick<Types.PasswordlessLoginTemplate, 'replyTo' | 'subject' | 'paragraph1' | 'paragraph2' | 'paragraph3' | 'primaryButtonText'>
    )>, abandonedCart?: Types.Maybe<(
      { __typename?: 'abandonedCartTemplate' }
      & Pick<Types.AbandonedCartTemplate, 'replyTo' | 'subject' | 'paragraph1' | 'paragraph2' | 'paragraph3' | 'primaryButtonText'>
    )>, emailDNSSettings?: Types.Maybe<(
      { __typename?: 'emailDNSSettings' }
      & Pick<Types.EmailDnsSettings, 'DKIMHost' | 'DKIMTextValue' | 'ReturnPathCNAMEValue'>
    )>, commentNotification?: Types.Maybe<(
      { __typename?: 'commentNotificationTemplate' }
      & Pick<Types.CommentNotificationTemplate, 'replyTo' | 'subject' | 'paragraph1' | 'paragraph2' | 'primaryButtonText' | 'unsubscribeLinkText'>
    )> }
  )> }
);


export const UpdateEmailsDocument = gql`
    mutation UpdateEmails($input: UpdateEmailsInput!) {
  updateEmails(input: $input) {
    from
  }
}
    `;
export type UpdateEmailsMutationFn = Apollo.MutationFunction<UpdateEmailsMutation, UpdateEmailsMutationVariables>;

/**
 * __useUpdateEmailsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailsMutation, { data, loading, error }] = useUpdateEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailsMutation, UpdateEmailsMutationVariables>) {
        return Apollo.useMutation<UpdateEmailsMutation, UpdateEmailsMutationVariables>(UpdateEmailsDocument, baseOptions);
      }
export type UpdateEmailsMutationHookResult = ReturnType<typeof useUpdateEmailsMutation>;
export type UpdateEmailsMutationResult = Apollo.MutationResult<UpdateEmailsMutation>;
export type UpdateEmailsMutationOptions = Apollo.BaseMutationOptions<UpdateEmailsMutation, UpdateEmailsMutationVariables>;
export const GetEmailsDocument = gql`
    query GetEmails {
  getEmails {
    from
    verified
    welcome {
      replyTo
      subject
      paragraph1
      paragraph2
    }
    passwordReset {
      replyTo
      subject
      paragraph1
      paragraph2
    }
    emailVerification {
      replyTo
      subject
      paragraph1
      paragraph2
      paragraph3
      primaryButtonText
    }
    passwordlessLogin {
      replyTo
      subject
      paragraph1
      paragraph2
      paragraph3
      primaryButtonText
    }
    abandonedCart {
      replyTo
      subject
      paragraph1
      paragraph2
      paragraph3
      primaryButtonText
    }
    emailDNSSettings {
      DKIMHost
      DKIMTextValue
      ReturnPathCNAMEValue
    }
    emailVerificationEnabled
    requireEmailVerification
    welcomeEmailsEnabled
    abandonedCartEmailsEnabled
    abandonedCartExpireMinutes
    commentNotification {
      replyTo
      subject
      paragraph1
      paragraph2
      primaryButtonText
      unsubscribeLinkText
    }
  }
}
    `;

/**
 * __useGetEmailsQuery__
 *
 * To run a query within a React component, call `useGetEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>) {
        return Apollo.useQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, baseOptions);
      }
export function useGetEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>) {
          return Apollo.useLazyQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, baseOptions);
        }
export type GetEmailsQueryHookResult = ReturnType<typeof useGetEmailsQuery>;
export type GetEmailsLazyQueryHookResult = ReturnType<typeof useGetEmailsLazyQuery>;
export type GetEmailsQueryResult = Apollo.QueryResult<GetEmailsQuery, GetEmailsQueryVariables>;