import React from "react";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import useSubmit from "hooks/useSubmit";
import { Modal } from "components/Modal";
import { useUpdatePlanMutation } from "features/plans";
import { usePlansContext } from "containers/Plans/context/Plans.context";

const DeleteSignupRule = ({
  showModal,
  setShowModal,
  domains,
  targetDomain,
}) => {
  const { planId } = useParams<{ planId: string }>();
  const { refetchPlans } = usePlansContext();
  const [updatePlan] = useUpdatePlanMutation();
  const { submit, isSubmitting } = useSubmit({
    action: updatePlan,
    refetch: refetchPlans,
    success: "Domain deleted successfully.",
    onSuccess: () => setShowModal(false),
  });

  const handleSubmit = () => {
    submit({
      planId,
      allowedDomains: update(domains, {
        $splice: [[domains.indexOf(targetDomain), 1]],
      }),
    });
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Delete Signup Rule"
      description="This action cannot be undone"
      showDivider
      actionButtons={{
        cancel: {
          label: "Cancel",
        },
        confirm: {
          label: "Confirm & Delete",
          onConfirm: () => handleSubmit(),
          isLoading: isSubmitting,
          buttonStyle: "danger",
        },
      }}
    >
      <p>
        Are you sure? The domain <strong>{targetDomain}</strong> will no longer
        be required to create an account.
      </p>
    </Modal>
  );
};

export default DeleteSignupRule;
