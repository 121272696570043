import React, { useState, useCallback } from "react";
import { Button, Card, Link } from "components";
import { MemberstackEnv } from "generatedTypes";
import DeleteAllMembersModal from "./DeleteAllMembersModal";
import DeleteAppModal from "./DeleteAppModal";

const DangerZone = () => {
  const [mode, setMode] = useState<MemberstackEnv>();
  const [showAppMemberModal, setShowAppMemberModal] = useState(false);
  const [showRemoveTestMember, setShowRemoveTestMember] = useState(false);

  const docs = (
    <Link
      to="https://docs.memberstack.com/hc/en-us/articles/10834319418907"
      target="_blank"
      underline
      isExternal
    >
      Learn more
    </Link>
  );

  const handleShowRemoveMembersModal = useCallback(
    (_mode) => () => {
      setMode(_mode);
      setShowRemoveTestMember(true);
    },
    []
  );

  return (
    <div className="flex flex-col gap-4">
      <Card className="flex flex-col p-4">
        <h3 className="text-base font-bold mb-4">Danger App</h3>
        <p className="mb-4 text-app-gray600 text-base">
          Deleting an app will also delete your members and cancel your
          subscription.
        </p>
        <Button
          text="Delete App"
          onClick={() => setShowAppMemberModal(true)}
          buttonStyle="danger"
          className="w-max"
        />
      </Card>
      <Card className="flex flex-col p-4">
        <h3 className="text-base font-bold mb-4">Delete Live Mode Members</h3>
        <p className="mb-4 text-app-gray600 text-base">
          This action cannot be undone. Stripe data will not be affected. {docs}
          .
        </p>
        <Button
          text="Delete all Live Mode Members"
          onClick={handleShowRemoveMembersModal(MemberstackEnv.Live)}
          buttonStyle="danger"
          className="w-max truncate"
        />
      </Card>
      <Card className="flex flex-col p-4">
        <h3 className="text-base font-bold mb-4">Delete Test Mode Members</h3>
        <p className="mb-4` text-app-gray600 text-base">
          This action cannot be undone. Stripe data will not be affected. {docs}
          .
        </p>
        <Button
          text="Delete all Test Mode Members"
          onClick={handleShowRemoveMembersModal(MemberstackEnv.Sandbox)}
          buttonStyle="danger"
          className="w-max truncate"
        />
      </Card>
      <DeleteAppModal
        showModal={showAppMemberModal}
        setShowModal={setShowAppMemberModal}
      />
      <DeleteAllMembersModal
        showModal={showRemoveTestMember}
        setShowModal={setShowRemoveTestMember}
        modeType={mode}
      />
    </div>
  );
};

export default DangerZone;
