import React from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker as PickerComponent } from "emoji-mart";
import useClickout from "hooks/useClickout";
import { Button } from "../Button";
import * as S from "./EmojiPicker.styles";

export const EmojiPicker = ({
  onSelect,
  icon,
}: {
  onSelect: (emoji: string) => void;
  icon?: string;
}) => {
  const { ref, isVisible, setIsVisible } = useClickout(false);

  return (
    <div ref={ref}>
      <Button
        leftIcon={icon || <S.Icon />}
        buttonStyle="transparent"
        onClick={() => setIsVisible(!isVisible)}
        tw="mx-2"
      />
      {isVisible ? (
        <S.PickerContainer>
          <PickerComponent
            perLine={9}
            emojiSize={16}
            onSelect={(e) => {
              onSelect(e.native);
              setIsVisible(false);
            }}
            title=""
            showPreview={false}
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              height: "330px",
              width: "300px",
              zIndex: "99",
            }}
          />
        </S.PickerContainer>
      ) : null}
    </div>
  );
};
