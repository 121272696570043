import React, { useEffect, useState } from "react";
import Input from "components/Input";
import Spacer from "components/Spacer";
import {
  useUpdateUserProfileMutation,
  useDisconnectUserFromGoogleMutation,
  useUpdateUserAuthMutation,
  useDisableUser2FaMutation,
  useSetupUser2FaMutation,
} from "features";
import { Modal, ModalTypes } from "components/Modal";
import { Button } from "components";
import { UserTwoFactorAuthPayload } from "generatedTypes";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { isObjectEqual } from "helpers/isObjectEqual";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { SetUp2FAModal } from "containers/2FactorAuth/SetUp2FAModal";
import { GoogleLogin } from "@react-oauth/google";
import useGoogleSSO from "hooks/useGoogleSSO";
import { BackupCodeModal } from "./BackupCodeModal";

const EditProfileModal = ({ showModal, setShowModal }: ModalTypes) => {
  const [show2FAModal, setShow2FAModal] = useState(false);
  const [showBackupCode, setShowBackupCode] = useState(false);

  const { connect } = useGoogleSSO();

  const [
    disconnectUserFromGoogle,
    { error: disconnectGoogleError, loading: disconnecting },
  ] = useDisconnectUserFromGoogleMutation();

  const [
    updateUserAuthMutation,
    { loading: userAuthLoading, error: updateUserAuthError },
  ] = useUpdateUserAuthMutation();

  const [profileData, setProfileData] = useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
  });

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [responseData, setResponseData] = useState<UserTwoFactorAuthPayload>({
    secret: "",
    url: "",
  });

  const [
    updateUserProfileMutation,
    { loading: updatingProfile, error: profileUpdateError },
  ] = useUpdateUserProfileMutation();
  const [setUpUser2FA, { loading: settingUp2FA }] = useSetupUser2FaMutation();
  const [disableUser2FA, { loading: disabling2FA, error }] =
    useDisableUser2FaMutation();

  const { currentUser, refetch } = useUsersContext();

  const {
    profile: { firstName, lastName },
    auth: {
      email,
      connectedToGoogle,
      twoFactorSettings: { enabled, backupCodes },
    },
  } = currentUser;

  useEffect(
    () => setProfileData({ firstName, lastName, email }),
    [firstName, lastName, email]
  );

  const userPasswordUpdated = !isObjectEqual(passwordData, {
    oldPassword: "",
    newPassword: "",
  });

  const userProfiledUpdated = !isObjectEqual(profileData, {
    firstName,
    lastName,
    email,
  });

  const { submit: handleUpdatePassword } = useOnSubmit({
    action: updateUserAuthMutation,
    fields: passwordData,
    cleanup: () => setShowModal(true),
    onSuccess: () => {
      setPasswordData({ oldPassword: "", newPassword: "" });
    },
    errorMsg: updateUserAuthError?.message,
    successMsg: "Your password was successfully updated.",
  });

  const { submit: handleUpdateProfile } = useOnSubmit({
    action: updateUserProfileMutation,
    fields: profileData,
    cleanup: () => setShowModal(true),
    errorMsg: profileUpdateError?.message,
    successMsg: "Your profile was successfully updated.",
  });

  const handleProfileAndAuthUpdate = () => {
    if (userPasswordUpdated) {
      handleUpdatePassword();
    } else if (userProfiledUpdated) {
      handleUpdateProfile();
    }
  };

  const { submit: handleSetup2FA } = useOnSubmit({
    action: setUpUser2FA,
    onSuccess: ({ data: { setupUser2FA } }) => setResponseData(setupUser2FA),
    cleanup: () => setShow2FAModal(true),
    errorMsg: "Unable to set up 2FA.",
    showToastProcessing: false,
  });

  const { submit: handleDisableUser2FA } = useOnSubmit({
    action: disableUser2FA,
    cleanup: () => setShowModal(false),
    errorMsg: error?.message,
    successMsg: "2FA was successfully disabled.",
  });

  const handle2FA = () => {
    if (enabled) {
      handleDisableUser2FA();
    } else {
      handleSetup2FA();
    }
  };

  const { submit: handleGoogleDisconnect } = useOnSubmit({
    action: disconnectUserFromGoogle,
    cleanup: () => setShowModal(true),
    errorMsg: disconnectGoogleError?.message,
    successMsg: "Google was successfully disconnected.",
    onSuccess: refetch,
  });

  const isUserUpdated = userProfiledUpdated || userPasswordUpdated;

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Edit Profile"
      showDivider
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: () => handleProfileAndAuthUpdate(),
          isDisabled: !isUserUpdated,
          isLoading:
            updatingProfile || settingUp2FA || disabling2FA || userAuthLoading,
        },
        cancel: { label: "Cancel" },
      }}
    >
      <form onSubmit={() => handleProfileAndAuthUpdate()}>
        <Input
          required
          value={profileData.email}
          type="email"
          label="Email"
          placeholder="Enter the member's email"
          disabled={connectedToGoogle}
          onChange={(e) =>
            setProfileData({ ...profileData, email: e.target.value })
          }
        />
        {connectedToGoogle && (
          <p className="text-body-sm text-gray-500">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            You are connected to Google, you can't change your email.
          </p>
        )}
        <Spacer />
        <Input
          required
          value={profileData.firstName}
          label="First name"
          placeholder="e.g. Roman"
          onChange={(e) =>
            setProfileData({ ...profileData, firstName: e.target.value })
          }
        />
        <Spacer />
        <Input
          required
          value={profileData.lastName}
          label="Last name"
          placeholder="e.g. Mars"
          onChange={(e) =>
            setProfileData({ ...profileData, lastName: e.target.value })
          }
        />
        <h4 className="text-h4 mb-1 mt-6 font-bold">Change Password</h4>
        <Input
          required
          type="password"
          value={passwordData.oldPassword}
          label="Current Password"
          placeholder=""
          onChange={(e) =>
            setPasswordData({ ...passwordData, oldPassword: e.target.value })
          }
        />
        <Spacer />
        <Input
          required
          type="password"
          value={passwordData.newPassword}
          label="New Password"
          placeholder=""
          onChange={(e) =>
            setPasswordData({ ...passwordData, newPassword: e.target.value })
          }
        />
        <button
          type="submit"
          className="sr-only"
          onSubmit={() => handleProfileAndAuthUpdate()}
        >
          Submit
        </button>
      </form>
      <div className="flex items-center justify-between w-full mt-6">
        <div className="text-body-sm">
          <h4 className="text-h4 mb-1 font-bold">Two Factor Authentication</h4>
          <p>
            Security codes will be generated by <br />
            your preferred authenticator app.
          </p>
          {enabled && (
            <span
              className="text-blue-600 hover:text-blue-700"
              onClick={() => setShowBackupCode(true)}
              tabIndex={0}
              role="button"
              aria-hidden="true"
            >
              View backup codes
            </span>
          )}
        </div>
        <Button
          text={enabled ? "Turn off" : "Set up"}
          buttonStyle={enabled ? "danger" : "default"}
          onClick={() => handle2FA()}
          isLoading={settingUp2FA}
        />
      </div>

      <div className="flex items-center justify-between w-full mt-6">
        <div className="text-body-sm">
          <h4 className="text-h4 mb-1 font-bold">Connect to Google</h4>
          <p>Use Google to login.</p>
        </div>

        {connectedToGoogle ? (
          <Button
            text="Disconnect Google"
            buttonStyle="danger"
            onClick={handleGoogleDisconnect}
            isLoading={disconnecting}
          />
        ) : (
          <GoogleLogin
            onSuccess={(res) => connect(res).then(() => refetch())}
            text="signup_with"
          />
        )}
      </div>
      <SetUp2FAModal
        setShowModal={setShow2FAModal}
        showModal={show2FAModal}
        responseData={responseData}
        closeModal={() => setShowModal(false)}
      />
      {backupCodes && (
        <BackupCodeModal
          setShowModal={setShowBackupCode}
          showModal={showBackupCode}
          backupCodes={backupCodes}
        />
      )}
    </Modal>
  );
};

export default EditProfileModal;
