import React, { useState, useMemo, useCallback } from "react";
import { Modal, ModalTypes } from "components/Modal";
import Input from "components/Input";
import { InputList, Button, Link } from "components";
import { Add } from "@mui/icons-material";
import { useCreateSsoAppMutation } from "features";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { getResponseError } from "helpers/getResponseError";
import { useDevtoolsContext } from "../context";

interface Props extends ModalTypes {}

const RegisterNewAppModal = ({ showModal, setShowModal }: Props) => {
  const [redirectUris, setRedirectUris] = useState([""]);
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createToastAlert } = useToastAlertContext();
  const { refetchSSOApps } = useDevtoolsContext();
  const [modalError, setModalError] = useState(null);

  const [createSSOApp] = useCreateSsoAppMutation();

  const handleAddField = useCallback(() => {
    setRedirectUris([...redirectUris, ""]);
  }, [redirectUris]);

  const handleRemove = useCallback(
    (index) => () => {
      const data = [...redirectUris];
      data.splice(index, 1);
      setRedirectUris(data);
    },
    [redirectUris]
  );

  const handleOnChange = useCallback(
    (index) => (event) => {
      const data = [...redirectUris];
      data[index] = event.target.value;
      setRedirectUris(data);
    },
    [redirectUris]
  );

  const inputs = useMemo(
    () =>
      redirectUris.map((uri, idx) => ({
        id: idx,
        disablePadding: true,
        value: uri,
        onChange: handleOnChange(idx),
        onInputDelete: handleRemove(idx),
      })),
    [handleOnChange, handleRemove, redirectUris]
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    createToastAlert({ processing: true });
    try {
      await createSSOApp({
        variables: {
          input: {
            name,
            redirectUris,
          },
        },
      });
      await refetchSSOApps();
      setShowModal(false);
      createToastAlert({
        alertType: "success",
        message: "SSO Application was successfully created.",
      });
    } catch (err) {
      setModalError(getResponseError(err));
    } finally {
      setIsSubmitting(false);
      setRedirectUris([""]);
      setName("");
    }
  };

  const isFormValid =
    name !== "" && redirectUris.length > 0 && redirectUris.indexOf("") === -1;

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Add a New Custom SSO Integration"
      showDivider
      width="570px"
      actionButtons={{
        confirm: {
          label: "Add Integration",
          onConfirm: handleSubmit,
          isLoading: isSubmitting,
          isDisabled: !isFormValid,
        },
        cancel: { label: "Cancel" },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Input
        label="Integration Name"
        placeholder="Circle Community"
        hideIndicators
        value={name}
        onChange={(e) => setName(e.target.value)}
        description="This is for your reference only. Cannot be changed later."
        className="mb-6"
      />
      <InputList
        trailingDeleteButton={false}
        addButtonPosition="bottom"
        inputs={inputs}
        label="Redirect URIs"
        placeholder="https://myapp/oauth2/callback"
        button={
          <Button
            leftIcon={<Add />}
            text="Add Redirect URI"
            onClick={handleAddField}
            buttonStyle="skeleton"
          />
        }
        description={
          <>
            Used after authenticating through an external provider. Learn more.{" "}
            <Link
              to="https://docs.memberstack.com/hc/en-us/articles/8161104982043-Custom-SSO-Integrations"
              underline
              isExternal
            >
              Help Docs →
            </Link>
          </>
        }
      />
      <h4 className="text-body-sm flex items-center justify-between mt-6 font-bold">
        Set a Signup Link{" "}
        <span className="text-body-sm text-app-gray600 font-normal">
          Optional
        </span>
      </h4>
      <p className="text-body-sm text-app-gray600">
        By default, there is no signup link in the hosted login UI.
        <br />
        Enter a signup URL to allow members to signup on your site.
        <br />
        <Link to="/settings" underline>
          Open settings in a new tab →
        </Link>
      </p>
    </Modal>
  );
};

export default RegisterNewAppModal;
