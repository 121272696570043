import { gql } from "@apollo/client";

export const UPDATE_EMAILS = gql`
  mutation UpdateEmails($input: UpdateEmailsInput!) {
    updateEmails(input: $input) {
      from
    }
  }
`;

export const GET_EMAILS = gql`
  query GetEmails {
    getEmails {
      from
      verified
      welcome {
        replyTo
        subject
        paragraph1
        paragraph2
      }
      passwordReset {
        replyTo
        subject
        paragraph1
        paragraph2
      }
      emailVerification {
        replyTo
        subject
        paragraph1
        paragraph2
        paragraph3
        primaryButtonText
      }
      passwordlessLogin {
        replyTo
        subject
        paragraph1
        paragraph2
        paragraph3
        primaryButtonText
      }
      abandonedCart {
        replyTo
        subject
        paragraph1
        paragraph2
        paragraph3
        primaryButtonText
      }
      emailDNSSettings {
        DKIMHost
        DKIMTextValue
        ReturnPathCNAMEValue
      }

      emailVerificationEnabled
      requireEmailVerification
      welcomeEmailsEnabled
      abandonedCartEmailsEnabled
      abandonedCartExpireMinutes
      commentNotification {
        replyTo
        subject
        paragraph1
        paragraph2
        primaryButtonText
        unsubscribeLinkText
      }
    }
  }
`;
