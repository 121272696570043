import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RouteName } from "enums/routes";
import { Tree } from "components/Tree/Tree";

export const Translation = () => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const {
    resetPasswordRequest,
    translateLogin,
    changePassword,
    translateAuth,
    errorMessages,
  } = RouteName;

  return (
    <>
      <h3 className="text-h3 font-bold">Translation</h3>
      <p className="text-body-sm text-app-gray600 mt-2 mb-5">
        Translate member-facing UI.
      </p>
      <Tree>
        <Tree.Branch
          title="Translate Error & Success Messages"
          description="Personalize and Translate member-facing error messages."
          onClick={() => history.push(`${url}/${errorMessages}`)}
        />
        <Tree.Branch
          title="SSO Hosted Login"
          description="Translate the SSO hosted login, authorization, and password reset UI."
        >
          <Tree.Node
            title="Login"
            onClick={() => history.push(`${url}/${translateLogin}`)}
          />
          <Tree.Node
            title="Reset password request"
            onClick={() => history.push(`${url}/${resetPasswordRequest}`)}
          />
          <Tree.Node
            title="Change password"
            onClick={() => history.push(`${url}/${changePassword}`)}
          />
          <Tree.Node
            title="Authorization"
            onClick={() => history.push(`${url}/${translateAuth}`)}
          />
        </Tree.Branch>
        <Tree.Branch
          title="Modals & Other UI"
          description="Learn how to translate member facing UI into any language."
        >
          <Tree.Node
            title="Visit out help center to learn how to translate your site."
            onClick={() =>
              window.open(
                "https://docs.memberstack.com/hc/en-us/articles/7838832577563-Translate-the-Memberstack-UI-",
                "_blank"
              )
            }
          />
        </Tree.Branch>
      </Tree>
    </>
  );
};
