import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteName } from "enums/routes";

import AppRoutes from "routes/AppRoutes";
import { SidebarProvider } from "containers/Layout/PrivateLayout/Sidebar/context/sidebar.context";
import SidebarWeb from "containers/Layout/PrivateLayout/Sidebar";
import { useSharableRoutes } from "./SharableRoutes";
// import useAuth from "hooks/useAuth";

// const LazyApps = React.lazy(() => import("routes/AppRoutes"));
const Lazy2FA = React.lazy(
  () => import("containers/TwoFactorAuthenticator/TwoFactorAuthenticator")
);
const LazyStripeConnect = React.lazy(
  () => import("containers/Settings/stripe-connect")
);

// Authenticated pages
export const protectedPages = [
  {
    exact: true,
    path: `/${RouteName.home}`,
    // eslint-disable-next-line react/display-name
    Component: () => <Redirect to={`/${RouteName.apps}`} />,
  },
  {
    exact: false,
    path: `/${RouteName.twoFactorAuthenticator}`,
    Component: Lazy2FA,
  },
  {
    exact: false,
    path: `/${RouteName.apps}`,
    Component: AppRoutes,
  },
  {
    exact: true,
    path: `/${RouteName.stripeConnect}`,
    Component: LazyStripeConnect,
  },
];

export default function ProtectedPages() {
  // *** Logout all users for maintenance
  // const { logout } = useAuth();
  // useEffect(() => {
  //   localStorage.clear();
  //   logout();
  // }, [logout]);
  // *********************************

  useSharableRoutes();

  return (
    <SidebarProvider>
      <div className="h-screen flex overflow-hidden bg-gray-50">
        <SidebarWeb />
        <Switch>
          {protectedPages.map(({ exact, path, Component }) => (
            <Route key={path} exact={exact} path={path}>
              <Component />
            </Route>
          ))}
        </Switch>
      </div>
    </SidebarProvider>
  );
}
