import React, { useState } from "react";
import { ApolloQueryResult } from "@apollo/client";
import Input from "components/Input";
import { Modal, ModalTypes } from "components/Modal";
import { Link } from "components";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { useAddUserToAppMutation } from "features";

type AddTeamMemberModalProps = ModalTypes & {
  refetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
};

const AddTeamMemberModal = ({
  showModal,
  setShowModal,
  refetch,
}: AddTeamMemberModalProps) => {
  const [email, setEmail] = useState("");

  const [addUser, { loading }] = useAddUserToAppMutation();

  const { submit: addMember } = useOnSubmit({
    action: addUser,
    fields: { email },
    cleanup: () => setShowModal(false),
    onSuccess: () => {
      window.CommandBar?.trackEvent("new-team-member-added", {});
    },
    refetch,
    successMsg: "Admin added to app",
    errorMsg: "User doesn't exist. Please have them create an account first.",
  });

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Add Admin"
      name="add-admin"
      showDivider
      width="430px"
      actionButtons={{
        confirm: {
          label: "Add Admin",
          onConfirm: () => addMember(),
          isLoading: loading,
          isDisabled: email === "",
          dataCy: "add-admin",
        },
      }}
    >
      <Input
        label="Email Address"
        name="email"
        type="email"
        value={email}
        required
        onChange={(e) => setEmail(e.target.value)}
        description={
          <>
            Add/invite a new teammate to your account.{" "}
            <Link
              target="_blank"
              isExternal
              underline
              to="https://docs.memberstack.com/hc/en-us/articles/9549865106715-Team-Members-Admin-and-Owners"
            >
              Help Docs →
            </Link>
          </>
        }
      />
    </Modal>
  );
};

export default AddTeamMemberModal;
