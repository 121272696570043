import React from "react";
import Tag from "components/Tag";
import { InfoTooltip } from "components/Tooltip";
import styled, { css } from "styled-components";
import Skeleton from "react-loading-skeleton";
import { Button, ButtonProps } from "../Button/Button";

type CardType =
  | "default"
  | "warning"
  | "error"
  | "success"
  | "primary"
  | "info";

export const Card = styled.div<{
  isDisabled?: boolean;
  hideShadow?: boolean;
  hideBorder?: boolean;
  hasHoverState?: boolean;
  selected?: boolean;
  type?: CardType;
}>`
  ${({
    theme: { colors, variables },
    isDisabled,
    hideShadow,
    hasHoverState,
    selected,
    hideBorder,
    type = "default",
  }) => css`
    border: ${hideBorder ? "none" : "1px solid"};
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: ${hideShadow ? "none" : variables?.mainBoxShadow};
    width: 100%;
    cursor: default;
    ${type === "primary" &&
    css`
      background: ${colors?.blue050};
      border-color: ${colors?.blue100};
    `}
    ${type === "default" &&
    css`
      border-color: ${colors?.grey100};
      background-color: ${colors?.white};
    `}
    ${hasHoverState &&
    css`
      &:hover {
        background: ${colors?.grey050};
        cursor: pointer;
      }
    `}
    ${selected &&
    css`
      border: 1px solid ${colors?.primary};
    `}
    ${isDisabled &&
    css`
      background: ${colors?.grey050};
      border-color: ${colors?.grey050};
    `}
  `}
`;

type ButtonType = Pick<ButtonProps, "text" | "onClick" | "leftIcon">;

interface SectionCardProps {
  children: React.ReactNode;
  title?: string;
  buttonValues?: ButtonType;
  workInProgress?: boolean;
  tooltipText?: string;
  footerSection?: React.ReactNode;
  maxHeight?: string;
  loading?: boolean;
}

export const SectionCard = ({
  children,
  title,
  buttonValues = undefined,
  workInProgress = false,
  tooltipText,
  footerSection,
  maxHeight = "auto",
  loading,
}: SectionCardProps) => {
  return (
    <Card tw="flex flex-col" style={{ maxHeight }}>
      <div className="flex justify-between items-center border-b px-3 py-4">
        <div className="flex items-center">
          <h4 tw="text-h4 font-bold flex items-center m-0">
            {loading ? <Skeleton width={127} height={28} /> : title}
            {!!tooltipText && (
              <InfoTooltip content={tooltipText} tw="ml-1" size="md" />
            )}
          </h4>
          {workInProgress && (
            <Tag text="Work In Progress" variant="warning" tw="ml-4" />
          )}
        </div>
        {buttonValues && (
          <Button
            text={buttonValues.text}
            onClick={buttonValues.onClick}
            leftIcon={buttonValues.leftIcon}
          />
        )}
      </div>
      <div tw="px-4 py-4 overflow-y-auto">{children}</div>
      {!!footerSection && (
        <div className="border-t px-3 py-4">{footerSection}</div>
      )}
    </Card>
  );
};
