import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateBrandingMutationVariables = Types.Exact<{
  input: Types.UpdateBrandingInput;
}>;


export type UpdateBrandingMutation = (
  { __typename?: 'Mutation' }
  & { updateBranding: (
    { __typename?: 'Branding' }
    & Pick<Types.Branding, 'id'>
  ) }
);

export type GetBrandingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBrandingQuery = (
  { __typename?: 'Query' }
  & { getBranding: (
    { __typename?: 'Branding' }
    & Pick<Types.Branding, 'id' | 'appId' | 'appearance' | 'logo'>
    & { colors: (
      { __typename?: 'Colors' }
      & { light: (
        { __typename?: 'BrandingColors' }
        & Pick<Types.BrandingColors, 'primaryButton'>
      ) }
    ) }
  ) }
);


export const UpdateBrandingDocument = gql`
    mutation UpdateBranding($input: UpdateBrandingInput!) {
  updateBranding(input: $input) {
    id
  }
}
    `;
export type UpdateBrandingMutationFn = Apollo.MutationFunction<UpdateBrandingMutation, UpdateBrandingMutationVariables>;

/**
 * __useUpdateBrandingMutation__
 *
 * To run a mutation, you first call `useUpdateBrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandingMutation, { data, loading, error }] = useUpdateBrandingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandingMutation, UpdateBrandingMutationVariables>) {
        return Apollo.useMutation<UpdateBrandingMutation, UpdateBrandingMutationVariables>(UpdateBrandingDocument, baseOptions);
      }
export type UpdateBrandingMutationHookResult = ReturnType<typeof useUpdateBrandingMutation>;
export type UpdateBrandingMutationResult = Apollo.MutationResult<UpdateBrandingMutation>;
export type UpdateBrandingMutationOptions = Apollo.BaseMutationOptions<UpdateBrandingMutation, UpdateBrandingMutationVariables>;
export const GetBrandingDocument = gql`
    query GetBranding {
  getBranding {
    id
    appId
    appearance
    logo
    colors {
      light {
        primaryButton
      }
    }
  }
}
    `;

/**
 * __useGetBrandingQuery__
 *
 * To run a query within a React component, call `useGetBrandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandingQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandingQuery, GetBrandingQueryVariables>) {
        return Apollo.useQuery<GetBrandingQuery, GetBrandingQueryVariables>(GetBrandingDocument, baseOptions);
      }
export function useGetBrandingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandingQuery, GetBrandingQueryVariables>) {
          return Apollo.useLazyQuery<GetBrandingQuery, GetBrandingQueryVariables>(GetBrandingDocument, baseOptions);
        }
export type GetBrandingQueryHookResult = ReturnType<typeof useGetBrandingQuery>;
export type GetBrandingLazyQueryHookResult = ReturnType<typeof useGetBrandingLazyQuery>;
export type GetBrandingQueryResult = Apollo.QueryResult<GetBrandingQuery, GetBrandingQueryVariables>;