import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { RouteName } from "enums/routes";
import { Link } from "components";
import styled from "styled-components";
import AbandonedCart from "assets/images/AbandonedCart.png";
import HelloAndWelcome from "assets/images/HelloAndWelcome.png";
import EmailVerification from "assets/images/EmailVerification.png";
import ResetPasswordEmail from "assets/images/ResetPasswordEmail.png";
import CommentingNotification from "assets/images/CommentingNotification.png";
import PasswordlessAuth from "assets/images/PasswordlessAuth.png";

const EmailCard = styled.div`
  & img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const Emails = () => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const {
    emailVerification,
    passwordResetEmail,
    passwordlessEmail,
    helloAndWelcome,
    abandonedCart,
    commentingNotification,
  } = RouteName;

  const emailConfigurations = [
    {
      name: "Email Verification",
      description: "Enable & configure verification emails",
      url: emailVerification,
      onClick: () => history.push(`${url}/${emailVerification}`),
      image: EmailVerification,
    },
    {
      name: "Password Reset Email",
      description: "Configure customers password reset emails",
      onClick: () => history.push(`${url}/${passwordResetEmail}`),
      image: ResetPasswordEmail,
    },
    {
      name: "Passwordless Auth",
      description: "Configure the  passwordless emails.",
      onClick: () => history.push(`${url}/${passwordlessEmail}`),
      image: PasswordlessAuth,
    },
    {
      name: "Hello & Welcome",
      description: "Configure the  Welcome email.",
      onClick: () => history.push(`${url}/${helloAndWelcome}`),
      image: HelloAndWelcome,
    },
    {
      name: "Abandoned Cart",
      description: "Configure the Abandoned Cart email.",
      onClick: () => history.push(`${url}/${abandonedCart}`),
      image: AbandonedCart,
    },
    {
      name: "Commenting Notification",
      description: "Configure the Commenting Notification Email.",
      onClick: () => history.push(`${url}/${commentingNotification}`),
      image: CommentingNotification,
    },
  ];

  return (
    <>
      <h3 tw="text-h3 mb-2 font-bold flex items-center">Emails</h3>
      <p className="text-body-sm text-app-gray600 whitespace-pre-line mb-5">
        Enable & customize the emails that will be sent to members.{" "}
        <Link
          to="https://docs.memberstack.com/hc/en-us/articles/13254139687067-Emails"
          underline
          isExternal
          target="_blank"
        >
          Help Docs →
        </Link>
      </p>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 list-none">
        {emailConfigurations.map(({ name, description, onClick, image }) => (
          <li
            key={name}
            className="border border-app-gray100 col-span-1 rounded-lg bg-white hover:border-app-blue400 shadow-sm"
          >
            <EmailCard
              role="button"
              onClick={onClick}
              onKeyDown={
                onClick
                  ? (e) => {
                      if (e.key === "Enter") {
                        onClick();
                      }
                    }
                  : undefined
              }
              tabIndex={0}
            >
              <img src={image} alt={name} />
              <div className="flex items-center justify-between p-6">
                <div className="flex flex-col">
                  <h3 className="text-base font-bold">{name}</h3>
                  <p className="text-app-gray500 text-body-sm">{description}</p>
                </div>
                <ChevronRightOutlinedIcon />
              </div>
            </EmailCard>
          </li>
        ))}
      </ul>
    </>
  );
};
