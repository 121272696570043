import React from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Input from "components/Input";

interface Props {
  formInputs: { [key: string]: string };
  brandColor: string;
  appImage: string;
}

export const LoginUI = ({ formInputs, brandColor, appImage }: Props) => (
  <div className="rounded-3xl shadow-lg bg-white" tw="w-[400px] rounded-[20px]">
    <div className="p-7 pb-0">
      <div tw="pb-[30px] flex justify-center">
        <img src={appImage} height={43} tw="h-[43px]" alt="app-logo" />
      </div>

      <h2 tw="text-h2 text-center font-bold mb-10">
        {formInputs.login_to_your_account || "Login to your account"}
      </h2>
      <Input
        label={formInputs.email_address || "Email Address"}
        placeholder={
          formInputs.login_email_placeholder || "Enter your email address"
        }
        className="mb-5"
        hideIndicators
        disabled
      />
      <Input
        label={formInputs.password || "Password"}
        placeholder={formInputs.login_password_placeholder || "Enter password"}
        className="mb-5"
        hintLabel={formInputs.forgot_password || "Forgot password?"}
        disabled
      />
      <button
        className="mb-5 w-full font-bold text-white p-3 rounded-lg text-base"
        type="button"
        style={{ backgroundColor: brandColor || "#2962FF" }}
      >
        {formInputs.login || "Log in"}
      </button>
      <div className="flex items-center text-base mb-5">
        <hr className="w-full" />{" "}
        <span className="mx-1 whitespace-nowrap">{formInputs.or || "or"}</span>
        <hr className="w-full" />
      </div>
      <button
        className="mb-5 w-full font-bold text-black border p-3 rounded-lg text-base"
        type="button"
      >
        {`${formInputs.continue_with || "Continue with"} Google`}
      </button>
    </div>
    <div
      className="bg-app-gray50 flex items-center justify-center text-body-sm font-bold p-3"
      tw="rounded-b-[20px]"
    >
      <VerifiedUserIcon tw="mr-1 w-3.5! h-3.5!" /> Secured by Memberstack
    </div>
  </div>
);
