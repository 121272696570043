import { useMemo } from "react";
import { RouteName } from "enums/routes";
import { useRouteMatch } from "react-router-dom";
import {
  Home,
  Users,
  GalleryHorizontal,
  Folder,
  LayoutGrid,
  MessageSquare,
  List,
  CodeXml,
  Settings,
} from "lucide-react";
import useTranslations from "hooks/useTranslations";

export const useSidebarItems = () => {
  const { translate } = useTranslations();
  const match = useRouteMatch(`/${RouteName.apps}/:appId`);
  const url = match?.url || "";

  const items = useMemo(
    () =>
      [
        {
          label: "Dashboard",
          icon: Home,
          link: `${url}/${RouteName.dashboard}`,
        },
        {
          label: translate("sideBar.members", "Members"),
          icon: Users,
          link: `${url}/${RouteName.members}`,
        },
        {
          label: translate("sideBar.plans", "Plans"),
          icon: GalleryHorizontal,
          link: `${url}/plans`,
        },
        {
          label: "Gated Content",
          icon: Folder,
          link: `${url}/${RouteName.gatedContent}`,
        },
        {
          label: "Components",
          icon: LayoutGrid,
          link: `${url}/${RouteName.editor}`,
        },
        {
          label: "Community",
          icon: MessageSquare,
          link: `${url}/${RouteName.community}`,
        },
        {
          label: "Event Log",
          icon: List,
          link: `${url}/${RouteName.eventLog}`,
        },
        {
          label: translate("sideBar.devTools", "Dev Tools"),
          icon: CodeXml,
          link: `${url}/${RouteName.devtools}`,
        },
        {
          label: translate("sideBar.settings", "Settings"),
          icon: Settings,
          link: `${url}/${RouteName.settings}`,
        },
      ].filter((item) => item.label),
    [translate, url]
  );
  return items;
};
