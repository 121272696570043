import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const SIDEBAR_STATE = "sidebar_state";

interface SidebarContextInterface {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  toggleIsExpanded: () => void;
  checklistId: string;
  handleUpdateCheckedItems: (updatedItems: string[]) => void;
  checkedItems: string[];
}

const defaultValue = {
  isExpanded: true,
  setIsExpanded: undefined,
  toggleIsExpanded: undefined,
  checklistId: "",
  handleUpdateCheckedItems: undefined,
  checkedItems: [],
};

export const SidebarContext =
  createContext<SidebarContextInterface>(defaultValue);

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const { appId } = useParams<{ appId: string }>();
  const checklistId = useMemo(() => `checklist-${appId}`, [appId]);
  let storedCheckedItems;
  try {
    storedCheckedItems = JSON.parse(localStorage.getItem(checklistId) || "[]");
  } catch (error) {
    console.error("Failed to parse storedCheckedItems:", error);
    storedCheckedItems = [];
  }

  const appSidebarState = `${appId}-${SIDEBAR_STATE}`;
  const sessionStorageValue = window.sessionStorage.getItem(appSidebarState);
  const [isExpanded, setIsExpanded] = useState(
    sessionStorageValue ? sessionStorageValue === "open" : true
  );

  const [checkedItems, setCheckedItems] = useState<string[]>(storedCheckedItems || []);

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
    window.sessionStorage.setItem(
      appSidebarState,
      isExpanded ? "closed" : "open"
    );
  }, [appSidebarState, isExpanded]);

  const handleUpdateCheckedItems = (updatedItems: string[]) => {
    try {
      localStorage.setItem(checklistId, JSON.stringify(updatedItems));
    } catch (error) {
      console.error("Failed to save checkedItems to localStorage:", error);
    }
    return setCheckedItems(updatedItems);
  }

  return (
    <SidebarContext.Provider
      value={{
        isExpanded,
        setIsExpanded,
        toggleIsExpanded,
        checklistId,
        handleUpdateCheckedItems,
        checkedItems,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
