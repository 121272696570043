import styled, { keyframes } from "styled-components";

export const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.07);
  border-radius: 12px 0px 0px 12px;
  width: 400px;
`;

export const TestimonialContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex: 1 1 0%;
  border: 1px solid #ebebeb;
  border-radius: 0px 12px 12px 0px;
  background-image: radial-gradient(
      at 0% 19%,
      hsla(22, 78%, 92%, 0.4) 0,
      transparent 100%
    ),
    radial-gradient(at 31% 100%, hsla(224, 40%, 49%, 0.4) 0, transparent 57%),
    radial-gradient(at 80% 0%, hsla(256, 20%, 96%, 0.4) 0, transparent 89%),
    radial-gradient(at 90% 87%, hsla(224, 100%, 49%, 0.4) 0, transparent 68%);
`;

const breatheAnimation = keyframes`
  0%   {top: 0px;}
  100% {top: -260px;}
`;

export const TestimonialList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 588px;
  margin: 0;
  & > div:first-child {
    margin-right: 12px;
  }
  animation: ${breatheAnimation} 20s infinite;
`;

export const TestimonyCard = styled.div`
  display: inline-block;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  padding: 24px;
  background: #ffffff;
  width: 288px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  max-height: fit-content;
  margin-bottom: 12px;
  & > img {
    width: 48px;
    height: 48px;
    border-radius: 999px;
    margin-bottom: 8px;
  }
`;
