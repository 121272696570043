import React, { useState } from "react";
import { useGenerateApiKeysMutation } from "features";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import Text from "components/Text";
import { getResponseError } from "helpers/getResponseError";
import { Modal, ModalTypes } from "components/Modal";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";

const GenerateApiKeyModal = ({ showModal, setShowModal }: ModalTypes) => {
  const [generate] = useGenerateApiKeysMutation();
  const { refetch } = useAppDataContext();
  const { createToastAlert } = useToastAlertContext();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [modalError, setModalError] = useState(null);

  const handleGenerateKeys = async () => {
    setIsSubmitting(true);
    createToastAlert({ processing: true });
    try {
      const newKeys = await generate();
      const { publicKey, secretKey } = newKeys?.data?.generateAPIKeys;
      refetch();

      if (publicKey && secretKey) {
        setShowModal(false);
        createToastAlert({
          alertType: "success",
          message: "API keys generated.",
        });
      }
    } catch (err) {
      setModalError(getResponseError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Add a permission"
      showDivider
      width="441px"
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleGenerateKeys,
          isLoading: isSubmitting,
        },
        cancel: { label: "Cancel" },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <>
        <Text className="mb-2">
          This will generate a new Publishable Key to access the Memberstack
          API. This cannot be undone.
        </Text>
        <Text className="mb-2">
          By confirming, all Test Mode settings will override Live Mode
          settings. This cannot be undone.
        </Text>
      </>
    </Modal>
  );
};

export default GenerateApiKeyModal;
