import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateSsoClientMutationVariables = Types.Exact<{
  input: Types.CreateSsoClientInput;
}>;


export type CreateSsoClientMutation = (
  { __typename?: 'Mutation' }
  & { createSSOClient: (
    { __typename?: 'AuthProviderConfig' }
    & Pick<Types.AuthProviderConfig, 'id' | 'providerType' | 'provider'>
  ) }
);

export type UpdateSsoClientMutationVariables = Types.Exact<{
  input: Types.UpdateSsoClientInput;
}>;


export type UpdateSsoClientMutation = (
  { __typename?: 'Mutation' }
  & { updateSSOClient: (
    { __typename?: 'AuthProviderConfig' }
    & Pick<Types.AuthProviderConfig, 'id' | 'providerType' | 'provider'>
  ) }
);

export type UpdatePasswordlessSettingsMutationVariables = Types.Exact<{
  input: Types.UpdatePasswordlessSettingsInput;
}>;


export type UpdatePasswordlessSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updatePasswordlessSettings: (
    { __typename?: 'PasswordlessSettingsPayload' }
    & Pick<Types.PasswordlessSettingsPayload, 'passwordlessEnabled' | 'defaultToPasswordless'>
  ) }
);

export type GetSsoClientsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSsoClientsQuery = (
  { __typename?: 'Query' }
  & { getSSOClients?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'AuthProviderConfig' }
    & Pick<Types.AuthProviderConfig, 'id' | 'providerType' | 'icon' | 'name' | 'discoveryUrl' | 'provider' | 'enabled' | 'clientId' | 'clientSecret' | 'redirectUri' | 'scopes' | 'issuer' | 'authorizationUrl' | 'tokenUrl' | 'userInfoUrl' | 'order'>
  )>>> }
);

export type GetDefaultAuthProvidersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDefaultAuthProvidersQuery = (
  { __typename?: 'Query' }
  & { defaultAuthProviders?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'AuthProviderConfig' }
    & Pick<Types.AuthProviderConfig, 'id' | 'icon' | 'name' | 'discoveryUrl' | 'provider' | 'enabled' | 'clientId' | 'clientSecret' | 'redirectUri' | 'providerType'>
  )>>> }
);

export type GetSsoAppsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSsoAppsQuery = (
  { __typename?: 'Query' }
  & { getSSOApps?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'SSOApp' }
    & Pick<Types.SsoApp, 'id' | 'name' | 'clientId' | 'clientSecret' | 'redirectUris'>
  )>>> }
);

export type CreateSsoAppMutationVariables = Types.Exact<{
  input: Types.CreateSsoAppInput;
}>;


export type CreateSsoAppMutation = (
  { __typename?: 'Mutation' }
  & { createSSOApp: (
    { __typename?: 'SSOApp' }
    & Pick<Types.SsoApp, 'id' | 'name'>
  ) }
);

export type DeleteSsoAppMutationVariables = Types.Exact<{
  input: Types.DeleteSsoAppInput;
}>;


export type DeleteSsoAppMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteSSOApp'>
);


export const CreateSsoClientDocument = gql`
    mutation CreateSSOClient($input: CreateSSOClientInput!) {
  createSSOClient(input: $input) {
    id
    providerType
    provider
  }
}
    `;
export type CreateSsoClientMutationFn = Apollo.MutationFunction<CreateSsoClientMutation, CreateSsoClientMutationVariables>;

/**
 * __useCreateSsoClientMutation__
 *
 * To run a mutation, you first call `useCreateSsoClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSsoClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSsoClientMutation, { data, loading, error }] = useCreateSsoClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSsoClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateSsoClientMutation, CreateSsoClientMutationVariables>) {
        return Apollo.useMutation<CreateSsoClientMutation, CreateSsoClientMutationVariables>(CreateSsoClientDocument, baseOptions);
      }
export type CreateSsoClientMutationHookResult = ReturnType<typeof useCreateSsoClientMutation>;
export type CreateSsoClientMutationResult = Apollo.MutationResult<CreateSsoClientMutation>;
export type CreateSsoClientMutationOptions = Apollo.BaseMutationOptions<CreateSsoClientMutation, CreateSsoClientMutationVariables>;
export const UpdateSsoClientDocument = gql`
    mutation UpdateSSOClient($input: UpdateSSOClientInput!) {
  updateSSOClient(input: $input) {
    id
    providerType
    provider
  }
}
    `;
export type UpdateSsoClientMutationFn = Apollo.MutationFunction<UpdateSsoClientMutation, UpdateSsoClientMutationVariables>;

/**
 * __useUpdateSsoClientMutation__
 *
 * To run a mutation, you first call `useUpdateSsoClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSsoClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSsoClientMutation, { data, loading, error }] = useUpdateSsoClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSsoClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSsoClientMutation, UpdateSsoClientMutationVariables>) {
        return Apollo.useMutation<UpdateSsoClientMutation, UpdateSsoClientMutationVariables>(UpdateSsoClientDocument, baseOptions);
      }
export type UpdateSsoClientMutationHookResult = ReturnType<typeof useUpdateSsoClientMutation>;
export type UpdateSsoClientMutationResult = Apollo.MutationResult<UpdateSsoClientMutation>;
export type UpdateSsoClientMutationOptions = Apollo.BaseMutationOptions<UpdateSsoClientMutation, UpdateSsoClientMutationVariables>;
export const UpdatePasswordlessSettingsDocument = gql`
    mutation UpdatePasswordlessSettings($input: UpdatePasswordlessSettingsInput!) {
  updatePasswordlessSettings(input: $input) {
    passwordlessEnabled
    defaultToPasswordless
  }
}
    `;
export type UpdatePasswordlessSettingsMutationFn = Apollo.MutationFunction<UpdatePasswordlessSettingsMutation, UpdatePasswordlessSettingsMutationVariables>;

/**
 * __useUpdatePasswordlessSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordlessSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordlessSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordlessSettingsMutation, { data, loading, error }] = useUpdatePasswordlessSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordlessSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordlessSettingsMutation, UpdatePasswordlessSettingsMutationVariables>) {
        return Apollo.useMutation<UpdatePasswordlessSettingsMutation, UpdatePasswordlessSettingsMutationVariables>(UpdatePasswordlessSettingsDocument, baseOptions);
      }
export type UpdatePasswordlessSettingsMutationHookResult = ReturnType<typeof useUpdatePasswordlessSettingsMutation>;
export type UpdatePasswordlessSettingsMutationResult = Apollo.MutationResult<UpdatePasswordlessSettingsMutation>;
export type UpdatePasswordlessSettingsMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordlessSettingsMutation, UpdatePasswordlessSettingsMutationVariables>;
export const GetSsoClientsDocument = gql`
    query GetSSOClients {
  getSSOClients {
    id
    providerType
    icon
    name
    discoveryUrl
    provider
    enabled
    clientId
    clientSecret
    redirectUri
    scopes
    issuer
    authorizationUrl
    tokenUrl
    userInfoUrl
    order
  }
}
    `;

/**
 * __useGetSsoClientsQuery__
 *
 * To run a query within a React component, call `useGetSsoClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSsoClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSsoClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSsoClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetSsoClientsQuery, GetSsoClientsQueryVariables>) {
        return Apollo.useQuery<GetSsoClientsQuery, GetSsoClientsQueryVariables>(GetSsoClientsDocument, baseOptions);
      }
export function useGetSsoClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSsoClientsQuery, GetSsoClientsQueryVariables>) {
          return Apollo.useLazyQuery<GetSsoClientsQuery, GetSsoClientsQueryVariables>(GetSsoClientsDocument, baseOptions);
        }
export type GetSsoClientsQueryHookResult = ReturnType<typeof useGetSsoClientsQuery>;
export type GetSsoClientsLazyQueryHookResult = ReturnType<typeof useGetSsoClientsLazyQuery>;
export type GetSsoClientsQueryResult = Apollo.QueryResult<GetSsoClientsQuery, GetSsoClientsQueryVariables>;
export const GetDefaultAuthProvidersDocument = gql`
    query GetDefaultAuthProviders {
  defaultAuthProviders {
    id
    icon
    name
    discoveryUrl
    provider
    enabled
    clientId
    clientSecret
    redirectUri
    providerType
  }
}
    `;

/**
 * __useGetDefaultAuthProvidersQuery__
 *
 * To run a query within a React component, call `useGetDefaultAuthProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultAuthProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultAuthProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultAuthProvidersQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultAuthProvidersQuery, GetDefaultAuthProvidersQueryVariables>) {
        return Apollo.useQuery<GetDefaultAuthProvidersQuery, GetDefaultAuthProvidersQueryVariables>(GetDefaultAuthProvidersDocument, baseOptions);
      }
export function useGetDefaultAuthProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultAuthProvidersQuery, GetDefaultAuthProvidersQueryVariables>) {
          return Apollo.useLazyQuery<GetDefaultAuthProvidersQuery, GetDefaultAuthProvidersQueryVariables>(GetDefaultAuthProvidersDocument, baseOptions);
        }
export type GetDefaultAuthProvidersQueryHookResult = ReturnType<typeof useGetDefaultAuthProvidersQuery>;
export type GetDefaultAuthProvidersLazyQueryHookResult = ReturnType<typeof useGetDefaultAuthProvidersLazyQuery>;
export type GetDefaultAuthProvidersQueryResult = Apollo.QueryResult<GetDefaultAuthProvidersQuery, GetDefaultAuthProvidersQueryVariables>;
export const GetSsoAppsDocument = gql`
    query GetSSOApps {
  getSSOApps {
    id
    name
    clientId
    clientSecret
    redirectUris
  }
}
    `;

/**
 * __useGetSsoAppsQuery__
 *
 * To run a query within a React component, call `useGetSsoAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSsoAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSsoAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSsoAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetSsoAppsQuery, GetSsoAppsQueryVariables>) {
        return Apollo.useQuery<GetSsoAppsQuery, GetSsoAppsQueryVariables>(GetSsoAppsDocument, baseOptions);
      }
export function useGetSsoAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSsoAppsQuery, GetSsoAppsQueryVariables>) {
          return Apollo.useLazyQuery<GetSsoAppsQuery, GetSsoAppsQueryVariables>(GetSsoAppsDocument, baseOptions);
        }
export type GetSsoAppsQueryHookResult = ReturnType<typeof useGetSsoAppsQuery>;
export type GetSsoAppsLazyQueryHookResult = ReturnType<typeof useGetSsoAppsLazyQuery>;
export type GetSsoAppsQueryResult = Apollo.QueryResult<GetSsoAppsQuery, GetSsoAppsQueryVariables>;
export const CreateSsoAppDocument = gql`
    mutation CreateSSOApp($input: CreateSSOAppInput!) {
  createSSOApp(input: $input) {
    id
    name
  }
}
    `;
export type CreateSsoAppMutationFn = Apollo.MutationFunction<CreateSsoAppMutation, CreateSsoAppMutationVariables>;

/**
 * __useCreateSsoAppMutation__
 *
 * To run a mutation, you first call `useCreateSsoAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSsoAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSsoAppMutation, { data, loading, error }] = useCreateSsoAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSsoAppMutation(baseOptions?: Apollo.MutationHookOptions<CreateSsoAppMutation, CreateSsoAppMutationVariables>) {
        return Apollo.useMutation<CreateSsoAppMutation, CreateSsoAppMutationVariables>(CreateSsoAppDocument, baseOptions);
      }
export type CreateSsoAppMutationHookResult = ReturnType<typeof useCreateSsoAppMutation>;
export type CreateSsoAppMutationResult = Apollo.MutationResult<CreateSsoAppMutation>;
export type CreateSsoAppMutationOptions = Apollo.BaseMutationOptions<CreateSsoAppMutation, CreateSsoAppMutationVariables>;
export const DeleteSsoAppDocument = gql`
    mutation DeleteSSOApp($input: DeleteSSOAppInput!) {
  deleteSSOApp(input: $input)
}
    `;
export type DeleteSsoAppMutationFn = Apollo.MutationFunction<DeleteSsoAppMutation, DeleteSsoAppMutationVariables>;

/**
 * __useDeleteSsoAppMutation__
 *
 * To run a mutation, you first call `useDeleteSsoAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSsoAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSsoAppMutation, { data, loading, error }] = useDeleteSsoAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSsoAppMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSsoAppMutation, DeleteSsoAppMutationVariables>) {
        return Apollo.useMutation<DeleteSsoAppMutation, DeleteSsoAppMutationVariables>(DeleteSsoAppDocument, baseOptions);
      }
export type DeleteSsoAppMutationHookResult = ReturnType<typeof useDeleteSsoAppMutation>;
export type DeleteSsoAppMutationResult = Apollo.MutationResult<DeleteSsoAppMutation>;
export type DeleteSsoAppMutationOptions = Apollo.BaseMutationOptions<DeleteSsoAppMutation, DeleteSsoAppMutationVariables>;