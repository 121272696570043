import React, { useCallback, useMemo, useState } from "react";
import Text from "components/Text";
import { InfoTooltip } from "components/Tooltip";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Edit } from "@mui/icons-material";
import Input from "components/Input";
import { isStringValid } from "helpers/isStringValid";
import { Button, CopyButton } from "../Button";

interface TextComboProps {
  id?: string;
  label?: string;
  text: string;
  hint?: string;
  withCopy?: boolean;
  isEditable?: boolean;
  className?: string;
  spaceOut?: boolean;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  errorMessage?: string;
  inputDescription?: React.ReactNode;
}

const TextCombo = ({
  id,
  label,
  hint,
  text,
  withCopy = false,
  className,
  isEditable = false,
  spaceOut = false,
  placeholder,
  onChange,
  name,
  errorMessage,
  inputDescription,
}: TextComboProps) => {
  const [showInput, setShowInput] = useState(false);

  const handleShowInput = useCallback(() => setShowInput(true), []);
  const handleOnInputChange = useCallback(
    (e) => showInput && onChange(e),
    [onChange, showInput]
  );
  const renderAction = useMemo(() => {
    if (isStringValid(text) && isEditable && !showInput) {
      return (
        <Button
          buttonStyle="transparent"
          onClick={handleShowInput}
          leftIcon={<Edit tw="ml-1" />}
        />
      );
    }
    if (isStringValid(text) && withCopy) {
      return (
        <CopyButton
          className={mergeClassNames(!spaceOut && "ml-2")}
          value={text}
        />
      );
    }
    return null;
  }, [handleShowInput, isEditable, showInput, spaceOut, text, withCopy]);
  return (
    <div className={className} data-cy={`${label}-${text}`}>
      {label && (
        <span className="flex items-center text-body-sm mb-1">
          <Text type="bodySmall" bold noMargin>
            {label}
          </Text>
          {label && hint && (
            <InfoTooltip tw="ml-2 w-4 h-4" content={hint} variant="outline" />
          )}
        </span>
      )}
      <div
        className={mergeClassNames(
          spaceOut && "justify-between",
          showInput ? "block" : "flex items-center"
        )}
      >
        {showInput ? (
          <Input
            value={text}
            onChange={handleOnInputChange}
            name={name}
            errorMessage={errorMessage}
            description={inputDescription}
            id={id}
          />
        ) : (
          <span
            className="text-base font-normal truncate"
            id={id}
            data-cy="memberstack-app-id"
          >
            {isStringValid(text) ? text : placeholder}
          </span>
        )}
        {renderAction}
      </div>
    </div>
  );
};

export default TextCombo;
