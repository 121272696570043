import { MemberstackEnv } from "generatedTypes";
import { useState } from "react";

export const useLocalStorageEnv = (
  key: string,
  initialValue?: MemberstackEnv
) => {
  const [env, setEnv] = useState<MemberstackEnv>(() => {
    try {
      const item = window.localStorage.getItem(key) as MemberstackEnv;
      return item || initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: MemberstackEnv) => {
    setEnv(value);
    window.localStorage.setItem(key, value);
  };

  return { storedEnv: env, setStoredEnv: setValue };
};
