import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetSvixAppPortalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSvixAppPortalQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'getSvixAppPortal'>
);

export type GetWebhooksQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWebhooksQuery = (
  { __typename?: 'Query' }
  & { getWebhooks?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Webhook' }
    & Pick<Types.Webhook, 'id' | 'name' | 'enabled' | 'apiVersion'>
  )>>> }
);

export type GetWebhookQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetWebhookQuery = (
  { __typename?: 'Query' }
  & { getWebhook?: Types.Maybe<(
    { __typename?: 'Webhook' }
    & Pick<Types.Webhook, 'id' | 'name' | 'enabled' | 'events' | 'apiVersion' | 'endpoint' | 'signingSecret'>
  )> }
);

export type CreateWebhookMutationVariables = Types.Exact<{
  input: Types.CreateWebhookInput;
}>;


export type CreateWebhookMutation = (
  { __typename?: 'Mutation' }
  & { createWebhook: (
    { __typename?: 'Webhook' }
    & Pick<Types.Webhook, 'id' | 'endpoint' | 'apiVersion' | 'events'>
  ) }
);

export type UpdateWebhookMutationVariables = Types.Exact<{
  input: Types.UpdateWebhookInput;
}>;


export type UpdateWebhookMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhook: (
    { __typename?: 'Webhook' }
    & Pick<Types.Webhook, 'id' | 'endpoint' | 'apiVersion' | 'events'>
  ) }
);

export type DeleteWebhookMutationVariables = Types.Exact<{
  input: Types.DeleteWebhookInput;
}>;


export type DeleteWebhookMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteWebhook'>
);


export const GetSvixAppPortalDocument = gql`
    query GetSvixAppPortal {
  getSvixAppPortal
}
    `;

/**
 * __useGetSvixAppPortalQuery__
 *
 * To run a query within a React component, call `useGetSvixAppPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSvixAppPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSvixAppPortalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSvixAppPortalQuery(baseOptions?: Apollo.QueryHookOptions<GetSvixAppPortalQuery, GetSvixAppPortalQueryVariables>) {
        return Apollo.useQuery<GetSvixAppPortalQuery, GetSvixAppPortalQueryVariables>(GetSvixAppPortalDocument, baseOptions);
      }
export function useGetSvixAppPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSvixAppPortalQuery, GetSvixAppPortalQueryVariables>) {
          return Apollo.useLazyQuery<GetSvixAppPortalQuery, GetSvixAppPortalQueryVariables>(GetSvixAppPortalDocument, baseOptions);
        }
export type GetSvixAppPortalQueryHookResult = ReturnType<typeof useGetSvixAppPortalQuery>;
export type GetSvixAppPortalLazyQueryHookResult = ReturnType<typeof useGetSvixAppPortalLazyQuery>;
export type GetSvixAppPortalQueryResult = Apollo.QueryResult<GetSvixAppPortalQuery, GetSvixAppPortalQueryVariables>;
export const GetWebhooksDocument = gql`
    query GetWebhooks {
  getWebhooks {
    id
    name
    enabled
    apiVersion
  }
}
    `;

/**
 * __useGetWebhooksQuery__
 *
 * To run a query within a React component, call `useGetWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebhooksQuery(baseOptions?: Apollo.QueryHookOptions<GetWebhooksQuery, GetWebhooksQueryVariables>) {
        return Apollo.useQuery<GetWebhooksQuery, GetWebhooksQueryVariables>(GetWebhooksDocument, baseOptions);
      }
export function useGetWebhooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebhooksQuery, GetWebhooksQueryVariables>) {
          return Apollo.useLazyQuery<GetWebhooksQuery, GetWebhooksQueryVariables>(GetWebhooksDocument, baseOptions);
        }
export type GetWebhooksQueryHookResult = ReturnType<typeof useGetWebhooksQuery>;
export type GetWebhooksLazyQueryHookResult = ReturnType<typeof useGetWebhooksLazyQuery>;
export type GetWebhooksQueryResult = Apollo.QueryResult<GetWebhooksQuery, GetWebhooksQueryVariables>;
export const GetWebhookDocument = gql`
    query GetWebhook($id: ID!) {
  getWebhook(id: $id) {
    id
    name
    enabled
    events
    apiVersion
    endpoint
    signingSecret
  }
}
    `;

/**
 * __useGetWebhookQuery__
 *
 * To run a query within a React component, call `useGetWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWebhookQuery(baseOptions: Apollo.QueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
        return Apollo.useQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, baseOptions);
      }
export function useGetWebhookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
          return Apollo.useLazyQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, baseOptions);
        }
export type GetWebhookQueryHookResult = ReturnType<typeof useGetWebhookQuery>;
export type GetWebhookLazyQueryHookResult = ReturnType<typeof useGetWebhookLazyQuery>;
export type GetWebhookQueryResult = Apollo.QueryResult<GetWebhookQuery, GetWebhookQueryVariables>;
export const CreateWebhookDocument = gql`
    mutation CreateWebhook($input: CreateWebhookInput!) {
  createWebhook(input: $input) {
    id
    endpoint
    apiVersion
    events
  }
}
    `;
export type CreateWebhookMutationFn = Apollo.MutationFunction<CreateWebhookMutation, CreateWebhookMutationVariables>;

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebhookMutation, CreateWebhookMutationVariables>) {
        return Apollo.useMutation<CreateWebhookMutation, CreateWebhookMutationVariables>(CreateWebhookDocument, baseOptions);
      }
export type CreateWebhookMutationHookResult = ReturnType<typeof useCreateWebhookMutation>;
export type CreateWebhookMutationResult = Apollo.MutationResult<CreateWebhookMutation>;
export type CreateWebhookMutationOptions = Apollo.BaseMutationOptions<CreateWebhookMutation, CreateWebhookMutationVariables>;
export const UpdateWebhookDocument = gql`
    mutation UpdateWebhook($input: UpdateWebhookInput!) {
  updateWebhook(input: $input) {
    id
    endpoint
    apiVersion
    events
  }
}
    `;
export type UpdateWebhookMutationFn = Apollo.MutationFunction<UpdateWebhookMutation, UpdateWebhookMutationVariables>;

/**
 * __useUpdateWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookMutation, { data, loading, error }] = useUpdateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>) {
        return Apollo.useMutation<UpdateWebhookMutation, UpdateWebhookMutationVariables>(UpdateWebhookDocument, baseOptions);
      }
export type UpdateWebhookMutationHookResult = ReturnType<typeof useUpdateWebhookMutation>;
export type UpdateWebhookMutationResult = Apollo.MutationResult<UpdateWebhookMutation>;
export type UpdateWebhookMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>;
export const DeleteWebhookDocument = gql`
    mutation DeleteWebhook($input: DeleteWebhookInput!) {
  deleteWebhook(input: $input)
}
    `;
export type DeleteWebhookMutationFn = Apollo.MutationFunction<DeleteWebhookMutation, DeleteWebhookMutationVariables>;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWebhookMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>) {
        return Apollo.useMutation<DeleteWebhookMutation, DeleteWebhookMutationVariables>(DeleteWebhookDocument, baseOptions);
      }
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>;
export type DeleteWebhookMutationResult = Apollo.MutationResult<DeleteWebhookMutation>;
export type DeleteWebhookMutationOptions = Apollo.BaseMutationOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>;