import React, { useState, useEffect, useRef } from "react";
import checkUrl from "helpers/checkUrl";
import Input from "components/Input";
import { Modal, ModalTypes } from "components/Modal";
import { useToastAlertContext } from "components/toastAlert";
import { useUpdatePlanMutation } from "features/plans";
import { REDIRECT_MAP } from "constants/redirects";
import { useUpdateDefaultRedirectsMutation } from "features";

interface Props extends ModalTypes {
  refetch: () => void;
  planId?: string;
  redirect: { key: string; value: string };
}

const EditRedirectModal = ({
  refetch,
  planId,
  redirect,
  showModal,
  setShowModal,
}: Props) => {
  const redirectValueRef = useRef(null);
  const [redirectUrl, setRedirectUrl] = useState<string>("");

  useEffect(() => {
    setRedirectUrl(redirect?.value || "");
    redirectValueRef.current = redirect?.value || "";
  }, [redirect?.value]);

  const { createToastAlert } = useToastAlertContext();
  const [updatePlan, { loading: planLoading }] = useUpdatePlanMutation();
  const [updateDefaultRedirect, { loading }] =
    useUpdateDefaultRedirectsMutation();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    createToastAlert({
      processing: true,
    });

    try {
      if (planId) {
        await updatePlan({
          variables: {
            input: {
              planId,
              redirects: {
                [redirect.key]: redirectUrl,
              },
            },
          },
        });
      } else {
        await updateDefaultRedirect({
          variables: {
            input: {
              [redirect.key]: redirectUrl,
            },
          },
        });
      }

      await refetch();

      createToastAlert({
        alertType: "success",
        message: "Redirect was successfully updated.",
      });

      // duncan using this for commandbar questlist event
      if (redirect.key === "afterSignup") {
        window.CommandBar?.trackEvent("signup-redirect-edited", {});
      }
      setRedirectUrl(redirect?.value);
      setShowModal(false);
    } catch (error) {
      createToastAlert({
        alertType: "error",
        message: `${error}`,
      });
    }
  };

  return (
    <Modal
      setShowModal={setShowModal}
      description="Redirect members to a specific page. (e.g. dashboard)"
      showModal={showModal}
      title={REDIRECT_MAP[redirect?.key] || ""}
      showDivider
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleOnSubmit,
          isDisabled: redirectUrl === redirectValueRef.current,
          isLoading: loading || planLoading,
        },
        cancel: {
          label: "Cancel",
          onClick: () => setRedirectUrl(redirect?.value),
        },
      }}
    >
      <form onSubmit={handleOnSubmit}>
        <Input
          label=""
          required
          placeholder="Enter redirect URL"
          value={redirectUrl || ""}
          onChange={(e) => setRedirectUrl(e.target.value)}
          leading={checkUrl(redirectUrl) ? null : "www.yourdomain.com/"}
          hideIndicators
        />
        <button
          type="submit"
          onClick={(e) => handleOnSubmit(e)}
          className="sr-only"
        >
          Submit
        </button>
      </form>
    </Modal>
  );
};

EditRedirectModal.defaultProps = {
  planId: undefined,
};

export default EditRedirectModal;
