import styled, { css } from "styled-components";

export const StyledAccordion = styled.div<{
  striped: boolean;
  disabled: boolean;
  isGSType?: boolean;
}>`
  ${({ theme: { colors }, striped, disabled, isGSType }) => css`
    border-radius: ${!striped && "4px"};
    cursor: ${disabled && "not-allowed"};
    opacity: ${disabled ? 0.5 : 1};
    ${isGSType &&
    css`
      background: ${colors?.grey050};
      border: 1px solid ${colors?.grey200};
      border-radius: 4px;
    `}

    ${striped &&
    css`
      border-top: 1px solid ${colors?.grey200};
      border-bottom: 1px solid ${colors?.grey200};
    `}
  `}
`;

export const GSAccordionLabel = styled.span<{
  checked: boolean;
  isActive: boolean;
}>`
  ${({ theme: { colors }, checked, isActive }) => css`
    margin-left: 8px;
    display: block;
    color: ${colors?.grey800};
    font-size: 14px;
    font-weight: ${isActive ? 700 : "normal"};
    line-height: 24px;
    user-select: none;
    ${checked &&
    css`
      text-decoration: line-through;
      color: ${colors?.grey500};
    `}
  `}
`;

export const StyledAccordionItem = styled.div<{
  selected: boolean;
  disabled: boolean;
  isGSType?: boolean;
}>`
  position: relative;
  z-index: 1;
  align-items: center;
  background: ${(props) => (props.isGSType ? "#F6F6F6" : "none")};
  border: none;
  border-radius: 4px;
  display: flex;
  font-weight: normal;
  font-size: 1em;
  justify-content: space-between;
  padding: ${(props) => (props.isGSType ? "8px" : "16px 20px 16px 12px")};
  text-align: left;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  & div > .chevron {
    width: 24px;
    height: 24px;
  }
  & .chevron {
    fill: #a4a4a4;
    width: 16px;
    height: 16px;
  }
`;

export const Item = styled.div<{
  hasBorder: boolean;
  hasOuterBorder: boolean;
  isGSType?: boolean;
}>`
  ${({ theme: { colors }, hasBorder, hasOuterBorder, isGSType }) => css`
    border-top: 1px solid ${colors?.grey200};
    background-color: ${isGSType ? colors?.grey050 : "white"};

    ${!hasBorder && "border: none;"};

    &:first-of-type {
      border-top: 0;
      border-top-left-radius: ${hasOuterBorder ? "4px" : 0};
      border-top-right-radius: ${hasOuterBorder ? "4px" : 0};
    }

    &:last-child {
      border-bottom-left-radius: ${hasOuterBorder ? "4px" : 0};
      border-bottom-right-radius: ${hasOuterBorder ? "4px" : 0};
    }
  `}
`;

export const ExpandableSection = styled.section<{
  expanded: boolean;
  $removeSectionPadding?: boolean;
  isGSType?: boolean;
}>`
  ${({ theme: { colors }, expanded, $removeSectionPadding, isGSType }) => css`
    background-color: white;
    /* padding: 0 24px; */
    height: 0px;
    opacity: 0;
    visibility: hidden;
    top: -10px;
    transition-duration: 200ms, 200ms, 200ms, 200ms, 100ms;
    transition-property: height, padding, opacity, top, visibility;
    transition-delay: 0, 0, 200ms;
    & > :first-of-type {
      display: none;
    }
    ${expanded &&
    css`
      padding: ${$removeSectionPadding ? 0 : "0px 24px 18px"};
      height: auto;
      opacity: 1;
      visibility: visible;
      top: 0px;
      transition-duration: 200ms, 200ms, 250ms, 200ms, 100ms;
      transition-property: height, padding, opacity, top, visibility;
      transition-delay: 200ms, 200ms, 0;
      & > :first-of-type {
        display: block;
      }
    `}
    ${isGSType &&
    css`
      padding: ${expanded ? "0px 8px 8px" : "0px"};
      background: ${colors?.grey050};
    `}
  `}
`;
