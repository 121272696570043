import { useState, useEffect, useRef } from "react";

const useClickout = (initial) => {
  const [isVisible, setIsVisible] = useState(initial);
  const ref = useRef(null);

  const handleClickout = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickout, true);
    return () => {
      document.removeEventListener("click", handleClickout, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

export default useClickout;
