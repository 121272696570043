import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useToastAlertContext } from "components/toastAlert";
import { AuthProviderConfig } from "generatedTypes";
import Input from "components/Input";
import { Banner, Link, Switch } from "components";
import { ModalTypes, Modal } from "components/Modal";
import {
  useCreateSsoClientMutation,
  useUpdateSsoClientMutation,
} from "features/sso";
import { omit } from "lodash";
import TextCombo from "components/TextCombo";
import TipBox from "components/TipBox";
import { Warning } from "@mui/icons-material";
import { useAuthPageContext } from "../context";

interface AuthConfigFormModalProps extends ModalTypes {
  authProvider: AuthProviderConfig;
  rootDomain: string;
}

const AuthConfigFormModal = ({
  setShowModal,
  showModal,
  rootDomain,
  authProvider,
}: AuthConfigFormModalProps) => {
  const { refetchSSOClient } = useAuthPageContext();
  const { createToastAlert } = useToastAlertContext();
  const { clientId, clientSecret, name, enabled, providerType, provider } =
    authProvider;

  const [enableIntegration, setEnableIntegration] = useState(false);

  const reducer = (
    state: { clientId: string; clientSecret: string },
    payload: { [k: string]: string | boolean | string[] }
  ) => ({
    ...state,
    ...payload,
  });

  const initialState = useMemo(
    () => ({ clientId, clientSecret }),
    [clientId, clientSecret]
  );

  const [input, setInput] = useReducer(reducer, initialState);

  useEffect(
    () =>
      setInput({
        clientId,
        clientSecret,
      }),
    [authProvider]
  );

  useEffect(() => setEnableIntegration(enabled), [enabled]);

  // isCreateMode when `clientId` or `clientSecret` is `null`
  const isCreateMode = !(Boolean(clientId) || Boolean(clientSecret));

  const createInput = {
    ...omit(authProvider, "__typename", "enabled", "provider", "id"),
    ...input,
    providerType,
    name,
  };

  const [createAuthProvider, { loading: createLoading }] =
    useCreateSsoClientMutation({
      variables: {
        input: createInput,
      },
      onCompleted: () => {
        createToastAlert({
          alertType: "success",
          message: `Credentials for ${name} were successfully added.`,
        });
        refetchSSOClient();
      },
      onError: () => {
        createToastAlert({
          alertType: "error",
          message: `Failed to add credentials for ${name}.`,
        });
      },
    });

  const updateInput = {
    provider,
    enabled: enableIntegration,
    clientId: input.clientId,
    clientSecret: input.clientSecret,
  };

  const [updateAuthProvider, { loading: updateLoading }] =
    useUpdateSsoClientMutation({
      variables: {
        input: updateInput,
      },
      onCompleted: () => {
        createToastAlert({
          alertType: "success",
          message: `Credentials for ${name} were successfully updated.`,
        });
        refetchSSOClient();
      },
      onError: () => {
        createToastAlert({
          alertType: "error",
          message: `Failed to update credentials for ${name}.`,
        });
      },
    });

  const handleSubmit = async () => {
    createToastAlert({ processing: true });
    const apiCall = isCreateMode ? createAuthProvider() : updateAuthProvider();

    try {
      await apiCall;
    } catch (e) {
      createToastAlert({
        alertType: "error",
        message: e.graphQLErrors[0],
      });
    } finally {
      setShowModal(false);
    }
  };

  const authIntegrationArticles = {
    google:
      "https://docs.memberstack.com/hc/en-us/articles/8327236548379-Google-Authentication",
    facebook:
      "https://docs.memberstack.com/hc/en-us/articles/9116286679195-Facebook-Authentication",
    microsoft:
      "https://docs.memberstack.com/hc/en-us/articles/9151844094107-Microsoft-Authentication",
    github:
      "https://docs.memberstack.com/hc/en-us/articles/9151629563291-Github-Authentication",
    linkedin:
      "https://docs.memberstack.com/hc/en-us/articles/9128407335835-LinkedIn-Authentication",
    spotify:
      "https://docs.memberstack.com/hc/en-us/articles/9151679451803-Spotify-Authentication",
    dribbble:
      "https://docs.memberstack.com/hc/en-us/articles/9151726845339-Dribbble-Authentication",
    zapier:
      "https://docs.memberstack.com/hc/en-us/articles/7252886662299-Zapier-Integration",
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      width="494px"
      title={`${name} Integration`}
      description={`Let members login with ${name} Authentication.`}
      descriptionLink={
        <Link
          target="_blank"
          rel="noreferrer"
          to={authIntegrationArticles[provider]}
          isExternal
          underline
        >
          Learn more
        </Link>
      }
      bottomSectionComponent={
        <Switch
          label={enabled ? "Enabled" : "Disabled"}
          id="toggleIntegration"
          name="toggleIntegration"
          isChecked={enableIntegration}
          onChange={() => setEnableIntegration(!enableIntegration)}
        />
      }
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleSubmit,
          isLoading: createLoading || updateLoading,
        },
        cancel: { label: "Cancel" },
      }}
    >
      <div className="flex flex-col gap-6">
        {Boolean(rootDomain) && (
          <Banner
            text="Configure Custom Domain"
            type="error"
            leftPlugin={<Warning />}
          >
            <ol className="ml-3.5">
              <li>Add your Client ID and Client Secret</li>
              <li>Add the Redirect URI in {name}</li>
            </ol>
          </Banner>
        )}
        <Input
          onChange={(e) => setInput({ clientId: e.target.value })}
          value={input.clientId}
          label="Client ID"
          required
          placeholder="Enter Client ID here"
          hintText={`Get this from your ${name} developer account.`}
          // hintText={
          // <Link
          //   target="_blank"
          //   rel="noreferrer"
          //   to={provider.infoLink}
          //   isExternal
          //   underline
          // >
          //   Where can I find this?
          // </Link>
          // }
        />
        <Input
          onChange={(e) => setInput({ clientSecret: e.target.value })}
          value={input.clientSecret}
          label="Client Secret"
          required
          placeholder="Enter Client Secret here"
          hintText={`Get this from your ${name} developer account.`}
          // hintText={
          //   <Link
          //     target="_blank"
          //     rel="noreferrer"
          //     to={provider.infoLink}
          //     isExternal
          //     underline
          //   >
          //     Where can I find this?
          //   </Link>
          // }
        />
        <TextCombo
          label="Redirect URI"
          withCopy
          hint={`Paste this into your ${name} developer account.`}
          text={
            rootDomain
              ? `https://memberstack-client.${rootDomain}/auth/callback`
              : "https://client.memberstack.com/auth/callback"
          }
        />
        {!rootDomain && (
          <TipBox showWavingEmoji={false}>
            Want to use a custom domain for signup & login? (e.g. continue to
            mydomain.com).
            <div className="mt-2 flex gap-4">
              <Link to="application" showAsLink isBold>
                Configure Domain
              </Link>
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/15793943107995-DNS-Settings-Custom-Domains"
                isExternal
                target="_blank"
                isBold
              >
                View Docs
              </Link>
            </div>
          </TipBox>
        )}
      </div>
    </Modal>
  );
};

export default AuthConfigFormModal;
