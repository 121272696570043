import React from "react";
import styled, { css } from "styled-components";
import { paddingSizes } from "styles/theme/variables";

interface Props {
  children: React.ReactNode;
  bottom?: boolean;
  gutter?: keyof typeof paddingSizes | "none";
  divider?: boolean;
}

const CardSection = styled.div<Props>`
  flex: 1;
  border: none;
  padding: 16px;
  ${({ gutter = "regular", bottom, divider }) => css`
    padding: ${gutter === "none"
      ? "0px"
      : paddingSizes[gutter] || paddingSizes[gutter]};
    ${bottom && divider ? "padding-bottom: 0px;" : ""}
  `}

  ${({ divider }) => divider && "border-bottom: 1px solid #ebebeb"}
`;

const Section = ({
  children,
  gutter = "regular",
  divider = true,
  bottom,
}: Props) => (
  <CardSection
    divider={divider}
    bottom={bottom}
    gutter={gutter}
    tw="flex flex-col flex-none"
  >
    {children}
  </CardSection>
);

Section.defaultProps = {
  bottom: false,
};

export default Section;
