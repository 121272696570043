import { useMemo } from "react";
import { MutationFunction, FetchResult, ApolloError } from "@apollo/client";
import { useToastAlertContext } from "components/toastAlert";

interface useOnSubmitTypes {
  /**
   * The request to be submitted.
   */
  action: MutationFunction;
  /**
   * Refetch to be performed after success.
   */
  refetch?: () => void;
  /**
   * Clean-up function to be run after any submit attempt.
   */
  cleanup?: () => void;
  /**
   * Fields to be submitted in the request.
   */
  fields?: { [key: string]: string | number | Object };
  /**
   * Optional success message (toast alert).
   */
  successMsg?: string;
  /**
   * Optional error message (toast alert). If not provided, the actual error message will be displayed.
   */
  errorMsg?: string;
  /**
   * Perform after successful request, and receives the result as a parameter.
   */
  onSuccess?: (result: FetchResult) => void;
  /**
   * Perform after failed request, and receives the error as a parameter.
   */
  onError?: (error: ApolloError) => void;
  showToastProcessing?: boolean;
}

const useOnSubmit = ({
  action,
  refetch,
  fields,
  cleanup,
  successMsg,
  errorMsg,
  onSuccess,
  onError,
  showToastProcessing = true,
}: useOnSubmitTypes) => {
  const { createToastAlert } = useToastAlertContext();

  return useMemo(
    () => ({
      submit: async () => {
        if (showToastProcessing) {
          createToastAlert({
            processing: true,
          });
        }
        try {
          const result = await action({
            ...(fields && { variables: { input: fields } }),
          });

          if (refetch) {
            await refetch();
          }

          if (successMsg) {
            createToastAlert({
              alertType: "success",
              message: successMsg,
            });
          }
          if (onSuccess) {
            onSuccess(result);
          }
        } catch (err) {
          createToastAlert({
            alertType: "error",
            message: errorMsg || `${err}`,
          });

          if (onError) {
            onError(err);
          }
        } finally {
          if (cleanup) {
            cleanup();
          }
        }
      },
    }),
    [
      action,
      fields,
      refetch,
      successMsg,
      onSuccess,
      createToastAlert,
      errorMsg,
      onError,
      cleanup,
      showToastProcessing,
    ]
  );
};

export default useOnSubmit;
