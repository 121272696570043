import React from "react";
import { render } from "react-dom";
import App from "containers/App";
import { EnvContextProvider } from "containers/Environment";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "./styles.css";
import { init } from "commandbar";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { PostHogProvider } from "posthog-js/react";
import { GoogleOAuthProvider } from "@react-oauth/google";

// commandbar init
init("06b079c5");

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://3c74adeedba147bb8148ba13021a7267@o220641.ingest.sentry.io/1427842",
  });
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

Chart.register(CategoryScale);

render(
  <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <BrowserRouter>
        <EnvContextProvider>
          <App />
        </EnvContextProvider>
      </BrowserRouter>
    </PostHogProvider>
  </GoogleOAuthProvider>,
  document.getElementById("container")
);
