/**
 * This recursive function removes all key/value pair with `null` or empty string value
 * from an object
 * @param obj object to be checked
 * @returns object that is without a key of undefined, null or empty string
 */
export const removeEmpty = (obj) => {
  return Object.keys(obj)
    .filter((k) => {
      return obj[k] !== null && obj[k] !== "" && obj[k] !== undefined;
    })
    .reduce((acc, k) => {
      acc[k] = typeof obj[k] === "object" ? removeEmpty(obj[k]) : obj[k];
      return acc;
    }, {});
};
