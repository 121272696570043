import cn from "helpers/cn";
import React, { HTMLAttributeReferrerPolicy } from "react";
import Logo from "assets/images/video-placeholder.svg";

interface Props {
  src: string;
  allowFullScreen?: boolean;
  title: string;
  className?: string;
  width?: number | string;
  height?: number;
  referrerpolicy?: HTMLAttributeReferrerPolicy;
}

const Video = ({
  src,
  allowFullScreen,
  title,
  className,
  width,
  height,
  referrerpolicy,
}: Props) => {
  return (
    <iframe
      src={src || Logo}
      frameBorder="0"
      allowFullScreen={allowFullScreen}
      title={title}
      className={cn("rounded-2xl bg-app-gray50 aspect-video", className)}
      width={width}
      height={height}
      aria-label={title}
      referrerPolicy={referrerpolicy}
    />
  );
};

export default Video;
