import { CheckboxProps } from "components/Checkbox/Checkbox.types";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import React, { useState, useCallback, useMemo } from "react";
import { FilterList } from "@mui/icons-material";
import useClickout from "hooks/useClickout";
import { useSpring, animated } from "react-spring";
import { FilterContext } from "./Filter.context";
import FilterItem from "./FilterItem";
import { FilterButton } from "./Filter.styles";

interface Props {
  filterOptions: {
    label: string;
    value: string;
    items?: CheckboxProps[];
    onFilter: (items: string[]) => void;
  }[];
}

const Filter = ({ filterOptions }: Props) => {
  const { ref, isVisible, setIsVisible } = useClickout(false);
  const [activeItem, setActiveItem] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  const menuAppear = useSpring({
    transform: isVisible ? "translate3D(0,0,0)" : "translate3D(0,-40px,0)",
    opacity: isVisible ? 1 : 0,
  });

  const toggleShowFilter = useCallback(
    () => setIsVisible(!isVisible),
    [isVisible, setIsVisible]
  );

  const setToggle = useCallback(
    (value) => {
      setActiveItem(() => {
        if (activeItem !== value) return value;
        return "";
      });
    },
    [setActiveItem, activeItem]
  );

  const contextValue = useMemo(
    () => ({
      activeItem,
      setToggle,
      activeFilters,
      setActiveFilters,
    }),
    [activeItem, setToggle, activeFilters]
  );

  const filterItems = useMemo(
    () => (
      <div
        data-testid="filters"
        className={mergeClassNames(isVisible ? "block" : "hidden")}
        tw="absolute right-0 top-0 border-app-gray100 bg-white rounded-md border shadow-regular w-max  mt-1.5"
        aria-hidden
      >
        {filterOptions.map(({ label, value, items, onFilter }) => (
          <FilterItem
            key={value}
            label={label}
            value={value}
            items={items}
            onFilter={onFilter}
          />
        ))}
      </div>
    ),
    [filterOptions, isVisible]
  );

  return (
    <FilterContext.Provider value={contextValue}>
      <div ref={ref}>
        <FilterButton className="text-body-sm" onClick={toggleShowFilter}>
          <FilterList /> Filters
          {activeFilters.length > 0 && (
            <div tw="bg-app-blue100 text-black rounded-full w-4 h-4 ml-1.5 text-utility-tag-md flex justify-center font-bold">
              {activeFilters.length}
            </div>
          )}
        </FilterButton>
        <animated.div style={menuAppear} tw="relative z-50">
          {filterItems}
        </animated.div>
      </div>
    </FilterContext.Provider>
  );
};

export default Filter;
