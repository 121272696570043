import styled, { css } from "styled-components";

export const StyledTextarea = styled.textarea<{ $type: "regular" | "note" }>(
  ({ $type }) => css`
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    display: block;
    border-width: 1px;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 8px;
    background: ${$type === "note" ? "#FFF7D0" : "white"};
    padding: 12px;
  `
);

export const Description = styled.p<{ $show: boolean }>`
  font-size: 12px;
  line-height: 20px;
  cursor: default;
  color: ${props => props.theme.colors?.grey600};
  transition: all 0.1s ease-in-out;
  opacity: ${props => (props.$show ? 1 : 0)};
  margin-top: ${props => (props.$show ? "4px" : "0px")};
  transform: ${props => (props.$show ? "translateY(0px)" : "translateY(-5px)")};
  height: ${props => (props.$show ? "auto" : "0px")};
`;