import React, { ReactElement, ReactNode, useMemo, useState } from "react";
import { TabsContext } from "./Tabs.context";

const getFirstTabId = (children: ReactNode): string | undefined => {
  const firstChild = React.Children.toArray(children)[0] as ReactElement;

  if (!firstChild || !firstChild.props.children) {
    return undefined; // Return undefined if there are no children
  }

  const firstTab = React.Children.toArray(
    firstChild.props.children
  )[0] as ReactElement;

  if (!firstTab || !firstTab.props.id) {
    return undefined; // Return undefined if the first tab or its id is undefined
  }

  return firstTab.props.id;
};
interface TabsProps {
  withRouter?: boolean;
  children: ReactNode;
  showBottomBorder?: boolean;
  isExpanded?: boolean;
  onTabChange?: (tabId: string) => void;
}

const Tabs = ({
  withRouter,
  children,
  showBottomBorder,
  isExpanded,
  onTabChange,
}: TabsProps) => {
  const initialActiveTab = useMemo(
    () => getFirstTabId(children) || "",
    [children]
  );

  const [activeTab, setActiveTab] = useState<string>(initialActiveTab);

  return (
    <TabsContext.Provider
      value={{
        activeTab,
        setActiveTab,
        withRouter,
        showBottomBorder,
        isExpanded,
        onTabChange,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export default Tabs;
