import { styled, css } from "twin.macro";
import ReactSelect, { OptionProps, StylesConfig } from "react-select";
import { colors } from "styles";
import { useMemo } from "react";

export const StyledSelect = styled(ReactSelect)<{
  $inline: boolean;
  $inverted: boolean;
}>`
  & > div > div > div > input:focus {
    box-shadow: none;
  }
  ${({ $inline }) =>
    $inline &&
    css`
      & > div:first-of-type {
        background-color: transparent;
        min-height: 0;
      }
      & > div {
        border: none;
        box-shadow: none;
      }
      & > div:hover {
        border: none;
        box-shadow: none;
      }
    `}
`;

type DescriptionSizeType = "sm" | "md" | "lg";
export const Description = styled.div<{
  $show: boolean;
  animate?: boolean;
  size?: DescriptionSizeType;
}>(
  () =>
    ({ theme, $show = false, animate = false, size = "sm" }) => css`
      cursor: default;
      color: ${theme.colors?.grey600};
      ${animate &&
      css`
        transition: all 0.1s ease-in-out;
        opacity: ${$show ? 1 : 0};
        margin-bottom: ${$show ? "4px" : "0px"};
        transform: ${$show ? "translateY(1px)" : "translateY(0px)"};
        height: ${$show ? "100%" : "0px"};
      `}
      ${size === "sm" &&
      css`
        font-size: 12px;
        line-height: 16px;
      `}
        ${size === "md" &&
      css`
        font-size: 14px;
        line-height: 24px;
      `}
        ${size === "lg" &&
      css`
        font-size: 16px;
        line-height: 24px;
      `}
    `
);

const renderOptionBkgColor = ({ isFocused, isSelected, inverted }) => {
  if (inverted) {
    return isSelected ? "#404040" : "#2B2B2B";
  }
  if (isSelected) {
    return colors.blue400;
  }
  if (isFocused) {
    return colors.grey050;
  }
  return undefined;
};

export const useCreateStyles = (menuIsOpen, inline, isDisabled, inverted) => {
  const customStyles: StylesConfig<OptionProps> = useMemo(() => {
    return {
      menu: (provided) => ({
        ...provided,
        position: menuIsOpen ? "relative" : "absolute",
        zIndex: 1000,
        background: inverted ? "#2B2B2B" : "white",
        width: "auto",
      }),
      menuList: (provided) => ({
        ...provided,
        width: "100%",
        padding: "0px",
        borderRadius: "6px",
        borderColor: colors.grey200,
        color: inverted ? "white" : "black",
        background: inverted ? "#2B2B2B" : "white",
        ...(inline
          ? {
              boxShadow:
                "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
            }
          : {}),
      }),
      control: (provided, state) => ({
        ...provided,
        minHeight: inverted ? "unset" : "38px",
        "&:hover": { borderColor: colors.blue400 },
        // eslint-disable-next-line no-nested-ternary
        borderColor: state.isFocused
          ? colors.blue400
          : inverted
            ? "transparent"
            : colors.grey200,
        background: inverted ? "#2B2B2B" : "white",
      }),
      input: (provided) => ({
        ...provided,
        fontSize: "14px",
        lineHeight: "24px",
        color: inverted ? "white" : "black",
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: "14px",
        lineHeight: "24px",
        color: colors.grey800,
        opacity: isDisabled ? 0.5 : 1,
      }),
      option: (provided, { isFocused, isSelected }) => ({
        padding: "8px 12px",
        borderRadius: "4px",
        margin: "4px",
        width: "auto",
        boxSizing: "border-box",
        display: "block",
        userSelect: "none",
        // eslint-disable-next-line no-nested-ternary
        color: isSelected ? "white" : inverted ? "white" : "black",
        backgroundColor: renderOptionBkgColor({
          inverted,
          isSelected,
          isFocused,
        }),
        "&:hover": { background: colors.blue400, color: "white" },
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0px 0px 0px 4px !important",
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: "6px 8px",
        fontWeight: 500,
        fontSize: inverted ? "12px" : "14px",
        lineHeight: inverted ? "20px" : "24px",
        ...(inline ? { padding: "0" } : {}),
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: colors.black,
        fontSize: "14px",
        lineHeight: "24px",
        padding: "0px",
        paddingLeft: "8px",
      }),
      multiValueRemove: () => ({
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: 2,
        boxSizing: "border-box",
        display: "flex",
        paddingLeft: "4px",
        paddingRight: "8px",
        color: colors.black,
      }),
      multiValue: (provided) => ({
        ...provided,
        margin: "2px 4px 3px 0",
        backgroundColor: colors.grey100,
        borderRadius: "4px",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: inverted ? "#D9D9D9" : "black",
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    };
  }, [inline, inverted, isDisabled, menuIsOpen]);
  return customStyles;
};
