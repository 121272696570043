import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPermissionsQuery = (
  { __typename?: 'Query' }
  & { getPermissions?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Permission' }
    & Pick<Types.Permission, 'id' | 'name' | 'description'>
  )>>> }
);

export type GetPermissionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPermissionQuery = (
  { __typename?: 'Query' }
  & { getPermission: (
    { __typename?: 'Permission' }
    & Pick<Types.Permission, 'id' | 'name' | 'description'>
  ) }
);


export const GetPermissionsDocument = gql`
    query GetPermissions {
  getPermissions {
    id
    name
    description
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, baseOptions);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, baseOptions);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetPermissionDocument = gql`
    query GetPermission($id: ID!) {
  getPermission(id: $id) {
    id
    name
    description
  }
}
    `;

/**
 * __useGetPermissionQuery__
 *
 * To run a query within a React component, call `useGetPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPermissionQuery(baseOptions: Apollo.QueryHookOptions<GetPermissionQuery, GetPermissionQueryVariables>) {
        return Apollo.useQuery<GetPermissionQuery, GetPermissionQueryVariables>(GetPermissionDocument, baseOptions);
      }
export function useGetPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionQuery, GetPermissionQueryVariables>) {
          return Apollo.useLazyQuery<GetPermissionQuery, GetPermissionQueryVariables>(GetPermissionDocument, baseOptions);
        }
export type GetPermissionQueryHookResult = ReturnType<typeof useGetPermissionQuery>;
export type GetPermissionLazyQueryHookResult = ReturnType<typeof useGetPermissionLazyQuery>;
export type GetPermissionQueryResult = Apollo.QueryResult<GetPermissionQuery, GetPermissionQueryVariables>;