import React from "react";
import Text from "components/Text";
import { AttributeInstallationBox, CodeBlock } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { javascript } from "@codemirror/lang-javascript";

interface CodeProps {
  className?: string;
  domain?: string;
  onlyShowCode?: boolean;
  onCodeCopy?: () => void;
}

export const MemberstackProviderCodeBlock = ({
  className,
  domain,
  onlyShowCode = false,
  onCodeCopy,
}: CodeProps) => {
  const { appData } = useAppDataContext();
  const publicKey = appData?.apiKeys?.publicKey;

  const memberstackProviderCode = `const config = { ${
    domain
      ? `domain: "https://memberstack-client.${domain}"`
      : `publicKey: "${publicKey}"`
  } }
function Index() {
  return (
    <MemberstackProvider config={config}>
      <App />
    </MemberstackProvider>
  )
}`;

  return (
    <>
      <p tw="text-body-sm mb-1">
        Replace the Memberstack Provider code in your app with the code below.
      </p>
      <CodeBlock
        isPulsingCopy={onlyShowCode}
        value={memberstackProviderCode}
        readOnly
        extensions={[javascript({ jsx: true })]}
        copyMessage="Memberstack Provider code snippet was copied."
        className={className}
        onCopyClick={onCodeCopy}
        // showLineNumbers={false}
      />
    </>
  );
};

export const UseMemberstackCodeBlock = ({
  className,
  domain,
  onlyShowCode,
  onCodeCopy,
}: CodeProps) => {
  const useMemberstackCode = `import { useMemberstack } from "@memberstack/react";

function Dashboard() {
  const memberstack = useMemberstack();
  const [member, setMember] = React.useState(null);

  React.useEffect(() => {
    memberstack.getCurrentMember().then(({ data: member }) => setMember(member));
  }, []);
  
  if (!member) return null;
  return <div>Welcome, {member.auth.email}</div>;
}`;

  return (
    <CodeBlock
      isPulsingCopy={onlyShowCode}
      value={useMemberstackCode}
      readOnly
      extensions={[javascript({ jsx: true })]}
      copyMessage="useMemberstack code snippet was copied."
      className={className}
      editable={false}
      onCopyClick={onCodeCopy}
      // showLineNumbers={false}
    />
  );
};

const ReactCode = () => {
  const npmCode = "npm install @memberstack/react";
  const yarnCode = "yarn add @memberstack/react";

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <h3 className="text-h3 font-bold">Install Code</h3>
        </div>
        <div className="mb-4">
          <p className="text-body-sm">
            Install the Memberstack React package from the{" "}
            <a
              href="https://www.npmjs.com/package/@memberstack/react"
              target="_blank"
              rel="noreferrer"
            >
              npm public registry
            </a>
            , or{" "}
            <a
              href="https://classic.yarnpkg.com/en/package/@memberstack/react"
              target="_blank"
              rel="noreferrer"
            >
              yarn
            </a>
            .
          </p>
        </div>
      </div>

      <div tw="mb-7">
        <ul className="text-base">
          <li tw="flex items-center mb-2">
            <b>npm</b> -
            <CodeBlock
              className="ml-2 w-96"
              value={npmCode}
              readOnly
              editable={false}
              copyMessage="NPM code snippet was copied."
            />
          </li>
          <li tw="flex items-center">
            <b>yarn</b> -
            <CodeBlock
              className="ml-2 w-96"
              value={yarnCode}
              readOnly
              editable={false}
              copyMessage="Yarn code snippet was copied."
            />
          </li>
        </ul>
      </div>

      <div tw="mb-7">
        <Text type="regular" tw="font-bold mb-1">
          Memberstack Provider
        </Text>
        <MemberstackProviderCodeBlock />
      </div>

      <div tw="mb-7">
        <Text type="regular" tw="font-bold mb-1">
          useMemberstack
        </Text>
        <UseMemberstackCodeBlock />
      </div>

      <div tw="mt-7">
        <AttributeInstallationBox
          isLinkOnly
          docsLink="https://memberstack.notion.site/React-Package-API-79b726ab4daf4fcf9f098e2ed547f521"
        />
      </div>
    </>
  );
};

export default ReactCode;
