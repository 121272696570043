import React, { useEffect, useState } from "react";
import Input from "components/Input";
import { Plan } from "generatedTypes";
import { useUpdatePlanMutation } from "features/plans";
import useSubmit from "hooks/useSubmit";
import { PlanAccordionItem } from "./PlanAccordionItem";

interface Props {
  plan: Plan;
  refetch: () => void;
}

const TeamAccounts = ({ plan, refetch }: Props) => {
  const [signupPageURL, setSignupPageURL] = useState<string>("");
  const [upgradePageURL, setUpgradePageURL] = useState<string>("");

  const [updatePlan] = useUpdatePlanMutation();

  useEffect(() => {
    if (plan) {
      setSignupPageURL(plan.teamAccountInviteSignupLink || "");
      setUpgradePageURL(plan.teamAccountUpgradeLink || "");
    }
  }, [plan]);

  const { submit, isSubmitting } = useSubmit({
    action: updatePlan,
    fields: {
      planId: plan.id,
      teamAccountInviteSignupLink: signupPageURL,
      teamAccountUpgradeLink: upgradePageURL,
    },
    success: "Team Accounts settings updated successfully",
    onSuccess: refetch,
  });

  return (
    <PlanAccordionItem
      label="Team Accounts"
      value="teamAccounts"
      onSave={() => submit()}
      isSaveDisabled={
        signupPageURL === plan.teamAccountInviteSignupLink &&
        upgradePageURL === plan.teamAccountUpgradeLink
      }
      buttonLoading={isSubmitting}
    >
      <section>
        <div className="flex flex-col gap-4">
          <Input
            id="signupPageURL"
            label="Signup Page URL"
            value={signupPageURL}
            onChange={(e) => setSignupPageURL(e.target.value)}
            placeholder="https://www.yoursite.com/signup"
            hideIndicators
          />
          <Input
            id="upgradePageURL"
            label="Upgrade Page URL"
            value={upgradePageURL}
            onChange={(e) => setUpgradePageURL(e.target.value)}
            placeholder="https://www.yoursite.com/upgrade"
            hideIndicators
          />
        </div>
      </section>
    </PlanAccordionItem>
  );
};

export default TeamAccounts;
