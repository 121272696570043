import { PublicLayout } from "containers/Layout";
import { RouteName } from "enums/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";

const LazyLogin = React.lazy(() => import("containers/Login"));
const LazyReset = React.lazy(() => import("containers/Reset"));
const LazySignup = React.lazy(() => import("containers/Signup"));

// Public pages
export const publicPages = [
  {
    exact: true,
    path: `/${RouteName.login}`,
    Component: LazyLogin,
  },
  {
    exact: true,
    path: `/${RouteName.reset}`,
    Component: LazyReset,
  },
  {
    exact: true,
    path: `/${RouteName.signup}`,
    Component: LazySignup,
  },
];

export default function PublicPages() {
  return (
    <PublicLayout>
      <Switch>
        {publicPages.map(({ exact, path, Component }) => (
          <Route key={path} exact={exact} path={path}>
            <Component />
          </Route>
        ))}
      </Switch>
    </PublicLayout>
  )
}