export const formatPluralizedString = (totalCount, singularWord) => {
  if (totalCount === null || totalCount === undefined || totalCount < 1) {
    return null;
  }

  const pluralSuffix = totalCount === 1 ? "" : "s";
  const formattedCount = totalCount.toLocaleString();

  return `${formattedCount} ${singularWord}${pluralSuffix}`;
};
