import React from "react";
import { Close, Done, PriorityHigh } from "@mui/icons-material";
import Text from "components/Text";
import * as S from "./ToastAlert.styles";

interface ToastAlertProps {
  message?: React.ReactNode;
  alertType?: "success" | "error";
  onClose?: () => void;
  processing?: boolean;
}

const ToastAlert = ({
  message,
  alertType,
  onClose,
  processing,
}: ToastAlertProps) => {
  const Icon = {
    success: Done,
    error: PriorityHigh,
  }[alertType];

  return (
    <S.ToastAlertContainer id="toast-alert">
      {processing ? (
        <Text className="text-white" noMargin type="regular">
          Saving...
        </Text>
      ) : (
        <S.ToastInfo alertType={alertType}>
          <Icon />
          <Text className="text-white" noMargin type="regular">
            {message}
          </Text>
        </S.ToastInfo>
      )}
      <button
        type="button"
        id="toast-alert-close"
        className="p-1.5 bg-transparent hover:bg-gray-800 rounded"
        onClick={onClose}
      >
        <Close className="text-white" />
      </button>
    </S.ToastAlertContainer>
  );
};

ToastAlert.defaultProps = {
  alertType: "error",
  message: undefined,
  processing: false,
};

export default ToastAlert;
