import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useUpdateAppMutation, useUpdateCustomDomainMutation } from "features";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useToastAlertContext } from "components/toastAlert";
import { Button } from "components";
import { Stack } from "generatedTypes";
import { ImageListType, ImageType } from "react-images-uploading";
import useAppNavigator from "hooks/useAppNavigator";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import { useLocation } from "react-router-dom";
import AppSettingsSkeleton from "./loading";
import ApplicationInformation from "./ApplicationInformation";
import DnsSettings from "./DnsSettings/DnsSettings";
import BusinessInformation from "./BusinessInformation";
import Security from "./Security";
import ApplicationDomains from "./ApplicationDomains/ApplicationDomains";
import EmailSenderAddress from "./DnsSettings/EmailSenderAddress/EmailSenderAddress";

const initialInputState = {
  name: "",
  businessEntityName: "",
  termsOfServiceURL: "",
  privacyPolicyURL: "",
  ssoSignupButtonURL: "",
  memberSessionDurationDays: 14,
};

const initialImageState = {
  dataURL: "",
  file: new File([""], "logo"),
};

const ApplicationSettings = () => {
  const [rootDomain, setRootDomain] = useState<string>(null);
  const [authSubdomain, setAuthSubdomain] = useState<string>();
  const [image, setImage] = useState<ImageType>(initialImageState);
  const [preventDisposableEmails, setPreventDisposableEmails] =
    useState<boolean>(false);

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 1000);
    }
  }, [pathname, hash, key]); // do this on route change

  const [captchaEnabled, setCaptchaEnabled] = useState<boolean>(false);
  const [disableConcurrentLogins, setDisableConcurrentLogins] =
    useState<boolean>(false);

  const [wasChanged, setWasChanged] = useState<boolean>(false);

  const { setHasUnsavedChanges } = useAppNavigator();

  const {
    appData,
    isLoading,
    refetch: refetchAppSettings,
  } = useAppDataContext();

  const cleanedAppData = useMemo(
    () => getObjectWithKeys(appData || {}, Object.keys(initialInputState)),
    [appData]
  );

  const valRef = useRef(null);
  const domainRef = useRef(null);

  const [formValues, setFormValues] = useState<{
    [key: string]: string | number;
  }>(initialInputState);

  useEffect(() => {
    valRef.current = cleanedAppData;
    domainRef.current = appData?.customDomain;
    setFormValues({
      name: valRef.current?.name || "",
      businessEntityName: valRef.current?.businessEntityName || "",
      termsOfServiceURL: valRef.current?.termsOfServiceURL || "",
      privacyPolicyURL: valRef.current?.privacyPolicyURL || "",
      ssoSignupButtonURL: valRef.current?.ssoSignupButtonURL || "",
      memberSessionDurationDays:
        valRef.current?.memberSessionDurationDays || 14,
    });
    setPreventDisposableEmails(appData?.preventDisposableEmails);
    setCaptchaEnabled(appData?.captchaEnabled);
    setDisableConcurrentLogins(appData?.disableConcurrentLogins);
    setImage(appData?.image as unknown as ImageType);
    setRootDomain(appData?.customDomain?.rootDomain || "");
    setAuthSubdomain(appData?.customDomain?.authSubdomain || "");
  }, [
    appData?.captchaEnabled,
    appData?.customDomain,
    appData?.customDomain?.authSubdomain,
    appData?.customDomain?.rootDomain,
    appData?.disableConcurrentLogins,
    appData?.image,
    appData?.preventDisposableEmails,
    cleanedAppData,
  ]);

  const { createToastAlert } = useToastAlertContext();

  const [updateAppInfo, { loading }] = useUpdateAppMutation({
    variables: {
      input: {
        ...formValues,
        preventDisposableEmails,
        disableConcurrentLogins,
        captchaEnabled,
        ...(wasChanged && { image: image?.file }),
      },
    },
  });

  const [updateCustomDomain, { loading: customDomainLoading }] =
    useUpdateCustomDomainMutation({
      variables: {
        input: {
          rootDomain,
          authSubdomain,
        },
      },
    });

  const didCustomDomainChange = !isObjectEqual(
    removeEmpty(domainRef.current || {}),
    removeEmpty(
      trimObjectStringValues({
        rootDomain,
        authSubdomain,
        __typename: "CustomDomain",
      })
    )
  );

  const didAppDataChange =
    !isObjectEqual(
      removeEmpty(valRef.current || {}),
      removeEmpty(trimObjectStringValues(formValues))
    ) ||
    wasChanged ||
    appData?.preventDisposableEmails !== preventDisposableEmails ||
    appData?.captchaEnabled !== captchaEnabled ||
    appData?.disableConcurrentLogins !== disableConcurrentLogins;

  const didFormChange = didAppDataChange || didCustomDomainChange;

  useEffect(() => {
    if (didFormChange && !isLoading) {
      return setHasUnsavedChanges(true);
    }
    return setHasUnsavedChanges(false);
  }, [didFormChange, isLoading, setHasUnsavedChanges]);

  const handleOnChange = useCallback(
    (type: string) =>
      ({ target: { value } }) => {
        setFormValues({
          ...formValues,
          ...{
            [type]:
              type === "memberSessionDurationDays"
                ? parseInt(value, 10)
                : value,
          },
        });
      },
    [formValues]
  );

  const handleOnImageChange = useCallback((imageList: ImageListType) => {
    setImage(imageList[0]);
    setWasChanged(true);
  }, []);

  if (isLoading) {
    return <AppSettingsSkeleton />;
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (didAppDataChange) {
        await updateAppInfo();
      }
      if (didCustomDomainChange) {
        await updateCustomDomain();
      }
      createToastAlert({
        alertType: "success",
        message: "Changes has been successfully updated.",
      });
      setWasChanged(false);
      refetchAppSettings();
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: err.message,
      });
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Button
        type="submit"
        text="Save"
        tw="absolute right-5 top-5"
        onClick={handleOnSubmit}
        isLoading={loading || customDomainLoading}
        isDisabled={!didFormChange}
      />
      <div className="flex flex-col gap-4">
        <ApplicationInformation
          formValues={formValues}
          handleOnChange={() => handleOnChange("name")}
          handleOnImageChange={handleOnImageChange}
          image={image}
        />
        {appData?.stack === Stack.Webflow && <ApplicationDomains />}
        <DnsSettings
          domainRef={domainRef}
          rootDomain={rootDomain}
          authSubdomain={authSubdomain}
          setAuthSubdomain={setAuthSubdomain}
          setRootDomain={setRootDomain}
        />
        <EmailSenderAddress />
        <BusinessInformation
          formValues={formValues}
          handleOnChange={handleOnChange}
        />
        <Security
          formValues={formValues}
          captchaEnabled={captchaEnabled}
          disableConcurrentLogins={disableConcurrentLogins}
          preventDisposableEmails={preventDisposableEmails}
          setCaptchaEnabled={setCaptchaEnabled}
          setDisableConcurrentLogins={setDisableConcurrentLogins}
          setPreventDisposableEmails={setPreventDisposableEmails}
          handleOnChange={handleOnChange}
        />
      </div>
    </form>
  );
};

export default ApplicationSettings;
