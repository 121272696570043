import { createContext, useContext } from "react";

const defaultValues = {
  activeItem: undefined,
  setToggle: undefined,
  striped: undefined,
  disabled: false,
};

export const AccordionContext = createContext(defaultValues);

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    // Error message should be more descriptive
    throw new Error("No context found for Accordion");
  }
  return context;
};
