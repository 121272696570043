import React from "react";
import { Button } from "components";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 132px;
  & > svg:first-of-type {
    width: 24px;
    height: 24px;
  }
`;

const NoSearchResult = ({ onClearSearch }) => (
  <Container data-cy="no-search-result">
    <SearchOutlinedIcon className="mb-1" />
    <p className="font-bold text-h3 mb-1">No Search Results Found</p>
    <p className="text-body-sm text-app-gray500 text-center mb-5">
      Try again with a different query.
    </p>
    <Button
      text="Clear Search"
      onClick={onClearSearch}
      buttonStyle="skeleton"
    />
  </Container>
);

export default NoSearchResult;
