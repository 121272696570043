import {
  HelpOutlined,
  HelpOutline,
  InfoOutlined,
  Info,
} from "@mui/icons-material";

import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Placement } from "tippy.js";
import mergeClassNames from "../../helpers/mergeClassNames/mergeClassNames";
import Tooltip from "./Tooltip";

export const sizeMap = {
  sm: "w-3 h-3",
  md: "w-4 h-4",
  lg: "w-5 h-5",
  xl: "w-6 h-6",
};

type IconVariant = "outline" | "solid";
type IconType = "info" | "questionMark";
type SizeMapTypes = "sm" | "md" | "lg" | "xl";

interface InfoTooltipProps {
  className?: string;
  /**
   * Tooltip placement
   */
  placement?: Placement;
  /**
   * content to be shown
   */
  content: React.ReactChild | React.ReactChild[];
  /**
   * Determines if an arrow is shown <br>
   * If true, adds an arrow to the tooltip.
   */
  showArrow?: boolean;
  /**
   * variant can be `normal` or `outlined`
   */
  variant?: IconVariant;
  /**
   * size of the help icon <b>
   * Here are the available sizes @sizeMapTypes
   */
  size?: SizeMapTypes;
  /**
   * type of icon to be rendered `info` or `help` <br>
   * default is info
   */
  iconType?: IconType;
}

const InfoTooltip = (props: InfoTooltipProps) => {
  const { placement, content, showArrow, variant, className, size, iconType } =
    props;

  const renderIcon = React.useMemo(() => {
    if (iconType === "questionMark") {
      return variant === "solid" ? (
        <HelpOutline
          data-testid="question-mark-icon"
          className={mergeClassNames(sizeMap[size], "text-gray-500", className)}
        />
      ) : (
        <HelpOutlined
          data-testid="question-mark-icon"
          className={mergeClassNames(sizeMap[size], "text-gray-500", className)}
        />
      );
    }
    return variant === "solid" ? (
      <Info
        data-testid="info-icon"
        className={mergeClassNames(sizeMap[size], "text-gray-500", className)}
      />
    ) : (
      <InfoOutlined
        data-testid="info-icon"
        className={mergeClassNames(sizeMap[size], "text-gray-500", className)}
      />
    );
  }, [className, size, variant, iconType]);

  return (
    <Tooltip content={content} showArrow={showArrow} placement={placement}>
      {renderIcon}
    </Tooltip>
  );
};

InfoTooltip.defaultProps = {
  placement: "right",
  showArrow: false,
  variant: "outline",
  size: "sm",
  className: undefined,
  iconType: "info",
};

export default InfoTooltip;
