import tw, { css, styled } from "twin.macro";
import { fontStyleMap } from "../../constants/fontStyleMap";

export const StatusTagContainer = styled.div<{}>(
  ({ theme: { colors } }) => css`
    display: flex;
    background-color: ${colors.white};
    /* height: 28px; */
    /* align-items: center; */
    border-width: 1px;
    border-color: ${colors.grey100};
    border-radius: 4px;
    box-shadow:
      0px 2px 4px rgba(0, 0, 0, 0.04),
      inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  `
);

export const StatusTagText = styled.div<{
  $fontSize: keyof typeof fontStyleMap;
  $isOverflow: boolean;
  $showMemberIcon: boolean;
}>(() => [
  ({ $fontSize }) => fontStyleMap[$fontSize],
  ({ theme: { colors = {} }, $isOverflow, $showMemberIcon }) => css`
    display: flex;
    align-items: center;
    text-transform: ${$isOverflow ? "lowercase" : "capitalize"};
    background-color: ${colors.white};
    width: max-content;
    padding: 6px;
    background-color: transparent;
    ${$showMemberIcon &&
    css`
      border-right: 1px solid ${colors.grey100};
      /* border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; */
    `}
  `,
]);

export const StatusTag = styled.div<{
  $fontSize: keyof typeof fontStyleMap;
  $isOverflow: boolean;
}>(() => [
  ({ $fontSize }) => fontStyleMap[$fontSize],
  ({ theme: { colors = {} }, $isOverflow }) => css`
    display: flex;
    height: 28px;
    align-items: center;
    text-transform: ${$isOverflow ? "lowercase" : "capitalize"};
    background-color: ${colors.white};
    width: max-content;

    border-width: 1px;
    border-color: ${colors.grey100};
    border-radius: 4px;
    box-shadow:
      0px 2px 4px rgba(0, 0, 0, 0.04),
      inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    padding: 6px;
  `,
]);

export const Orb = styled.div<{ statusColor: string }>(() => [
  tw`rounded-full bg-red-800 font-normal mr-1`,
  ({ theme: { colors = {} }, statusColor }) => css`
    background: ${colors[statusColor]};
    width: 6px;
    height: 6px;
  `,
]);
