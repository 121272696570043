import React, { useState, useEffect } from "react";
import { Modal, ModalTypes } from "components/Modal";
import { useGetPermissionLazyQuery } from "features/permissions/queries.generated";
import DeletePermissionForm from "./DeletePermissionForm";
import EditPermissionForm from "./EditPermissionForms";

interface Props extends ModalTypes {
  permissionId: string;
}

const EditPermissionModal = ({
  showModal,
  setShowModal,
  permissionId,
}: Props) => {
  const [view, setView] = useState<React.ReactNode>(null);
  const [getPermission, { loading, data }] = useGetPermissionLazyQuery({
    variables: { id: permissionId },
  });

  useEffect(() => {
    if (permissionId) {
      getPermission();
    }
  }, [permissionId]);

  if (loading || !permissionId) return null;

  const permission = data?.getPermission;

  const closeModal = () => {
    setView(null);
    setShowModal(false);
  };

  const handleGoBack = () => setView(null);

  const handleDelete = () =>
    setView(
      <DeletePermissionForm
        closeModal={closeModal}
        permission={permission}
        onBack={handleGoBack}
      />
    );

  return (
    <Modal
      setShowModal={setShowModal}
      onModalClose={() => setView(null)}
      showModal={showModal}
      title={view ? "Confirm deleting permission" : "Edit permission"}
      description={view && "This action cannot be undone."}
      showDivider
      width="441px"
      showBottomSection={false}
      removePadding
    >
      {view || (
        <EditPermissionForm
          permission={permission}
          closeModal={closeModal}
          handleDelete={handleDelete}
        />
      )}
    </Modal>
  );
};

export default EditPermissionModal;
