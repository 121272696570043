import React, { useState, useCallback } from "react";
import Input from "components/Input";
import { isError } from "helpers/typeguards";
import { useToastAlertContext } from "containers/App/contexts/ToastAlert";
import { Modal, ModalTypes } from "components/Modal";
import useTranslations from "hooks/useTranslations";
import { generateID } from "helpers/generateID";
import TextCombo from "components/TextCombo";
import {
  Switch,
  Button,
  AttributeInstallationBox,
  Link,
  Accordion,
  AccordionItem,
} from "components";
import { RadioButtonGroup } from "components/RadioButton";
import { CustomFieldVisibility } from "generatedTypes";
import { useCreateCustomFieldMutation } from "../mutations.generated";
import { useCustomFieldContext } from "../context";

const visibilityOptions = [
  {
    label: "Private",
    value: CustomFieldVisibility.Private,
    description: "Not accessible when posting. Only visible to the member.",
  },
  {
    label: "Public",
    value: CustomFieldVisibility.Public,
    description: "Visible to anyone on the web IF the member makes a post.",
  },
];

export const AddCustomFieldsModal = ({
  showModal,
  setShowModal,
}: ModalTypes) => {
  const { translate } = useTranslations();
  const [key, setKey] = useState("");
  const [hidden, setHidden] = useState(false);
  const [label, setLabel] = useState("");
  const [visibility, setVisibility] = useState(visibilityOptions[0].value);

  const { refetchCustomFields } = useCustomFieldContext();

  const handleVisibilityChange = (value) => setVisibility(value);

  const [createCustomField, { loading }] = useCreateCustomFieldMutation();

  const { createToastAlert } = useToastAlertContext();
  const [modalError, setModalError] = useState(null);

  const handleLabelChange = useCallback(({ target }) => {
    setLabel(target.value);
    setKey(generateID(target.value));
  }, []);

  const handleSubmit = async (shouldAddAnother?: boolean) => {
    try {
      await createCustomField({
        variables: {
          input: {
            key,
            label,
            hidden,
            visibility,
          },
        },
      });
      if (!shouldAddAnother) {
        setShowModal(false);
        refetchCustomFields();
      }
      setKey("");
      setLabel("");
      setHidden(false);
      createToastAlert({
        alertType: "success",
        message: `${label} was successfully created.`,
      });
    } catch (e) {
      if (isError(e)) {
        setModalError(e.message);
      }
    }
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      removePadding
      width="560px"
      title={translate("addCustomField.title", "Add a custom field")}
      description="Use Custom Fields to store additional info for each member."
      showDivider
      descriptionLink={
        <Link
          target="_blank"
          rel="noreferrer"
          to="https://docs.memberstack.com/hc/en-us/articles/7389502579355-Custom-Fields-Inputs"
          isExternal
          underline
        >
          Learn more
        </Link>
      }
      bottomSectionComponent={
        <Button
          text="Save & Add Another"
          buttonStyle="transparent"
          onClick={() => handleSubmit(true)}
          isDisabled={!label || !key}
          isLoading={loading}
        />
      }
      actionButtons={{
        confirm: {
          label: "Save Custom Field",
          onConfirm: () => handleSubmit(),
          isDisabled: !label || !key,
          isLoading: loading,
          dataCy: "save-custom-field-button",
        },
        cancel: {
          label: translate("addCustomField.cancel", "Cancel"),
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <form onSubmit={() => handleSubmit()} className="p-5 pb-0">
        <Input
          name="label"
          type="text"
          value={label}
          placeholder="e.g. First name"
          onChange={handleLabelChange}
          label={translate("addCustomField.label", "Custom Field Name")}
          className="mb-6"
          description="Members will see this in their profiles, invoices, etc"
          hideIndicators
          dataCy="custom-field-name-input"
        />
        <TextCombo
          label="Custom Field ID"
          withCopy
          text={key}
          placeholder="first-name"
          inputDescription="A human-readable unique ID used in identifying the custom field"
          hint="A human-readable unique ID used in identifying the custom field"
        />
        <AttributeInstallationBox
          className="mt-6"
          description="Use to populate inputs and to display member data on your site."
          docsLink="https://docs.memberstack.com/hc/en-us/articles/7389502579355-Custom-Fields-Inputs"
          nameLabel="Name"
          nameValue="data-ms-member"
          idLabel="ID"
          idValue={key}
        />
        <div className="mt-6 w-full p-3 rounded-lg bg-app-gray50">
          <Switch
            id="hide-from-prebuilt-ui-2"
            name="hideFromPrebuiltUI"
            isChecked={hidden}
            onChange={() => setHidden(!hidden)}
            label="Hide from Pre-built UI"
            description="Hide the field from the prebuilt signup and profile UI"
          />
        </div>
        {/* <Input
          name="key"
          type="text"
          value={key}
          placeholder="e.g. first-name"
          onChange={(e) => setKey(e.target.value)}
          label={translate("addCustomField.key", "Custom Field ID")}
          infoHintText={translate(
            "addCustomField.keyHint",
            "A human-readable unique ID used in identifying the custom field"
          )}
          hideIndicators
        /> */}
      </form>
      <Accordion name="commenting" striped={false} persistent={false}>
        <AccordionItem
          label="Advanced/Commenting"
          value="advanced-commenting"
          isBeta
        >
          <div className="text-body-sm text-app-gray600 mb-2">
            Enable discussion threads and commenting.{" "}
            <Link
              to="https://docs.memberstack.com/hc/en-us/articles/18966275380123"
              showAsLink
              isExternal
              target="_blank"
              underline
            >
              Help Docs
            </Link>
            <p className="mt-4 font-bold text-black">Data Visibility</p>
            Decide if this data should be accessible via commenting UI.
          </div>
          <RadioButtonGroup
            label="Visibility"
            selectedValue={visibility}
            options={visibilityOptions}
            name="visibility"
            onChange={handleVisibilityChange}
            gap={8}
            labelClassName="font-bold text-body-sm"
          />
        </AccordionItem>
      </Accordion>
    </Modal>
  );
};
