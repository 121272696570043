import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddAppDomainMutationVariables = Types.Exact<{
  input: Types.AddDomainToAppInput;
}>;


export type AddAppDomainMutation = (
  { __typename?: 'Mutation' }
  & { addDomain: (
    { __typename?: 'Domain' }
    & Pick<Types.Domain, 'url'>
  ) }
);

export type UpdateDomainMutationVariables = Types.Exact<{
  input: Types.UpdateDomainInput;
}>;


export type UpdateDomainMutation = (
  { __typename?: 'Mutation' }
  & { updateDomain: (
    { __typename?: 'Domain' }
    & Pick<Types.Domain, 'id' | 'url' | 'mode'>
  ) }
);

export type ConnectStripeMutationVariables = Types.Exact<{
  input: Types.ConnectStripeAppInput;
}>;


export type ConnectStripeMutation = (
  { __typename?: 'Mutation' }
  & { connectStripe?: Types.Maybe<(
    { __typename?: 'App' }
    & Pick<Types.App, 'id'>
  )> }
);

export type DisconnectStripeMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DisconnectStripeMutation = (
  { __typename?: 'Mutation' }
  & { disconnectStripe?: Types.Maybe<(
    { __typename?: 'App' }
    & Pick<Types.App, 'id'>
    & { stripeConnection?: Types.Maybe<(
      { __typename?: 'AppStripeConnection' }
      & Pick<Types.AppStripeConnection, 'accountId'>
    )> }
  )> }
);

export type RemoveDomainMutationVariables = Types.Exact<{
  input: Types.RemoveDomainInput;
}>;


export type RemoveDomainMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'removeDomain'>
);

export type UpdateCustomDomainMutationVariables = Types.Exact<{
  input: Types.UpdateCustomDomainInput;
}>;


export type UpdateCustomDomainMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomDomain: (
    { __typename?: 'CustomDomain' }
    & Pick<Types.CustomDomain, 'rootDomain' | 'authSubdomain'>
  ) }
);


export const AddAppDomainDocument = gql`
    mutation AddAppDomain($input: AddDomainToAppInput!) {
  addDomain(input: $input) {
    url
  }
}
    `;
export type AddAppDomainMutationFn = Apollo.MutationFunction<AddAppDomainMutation, AddAppDomainMutationVariables>;

/**
 * __useAddAppDomainMutation__
 *
 * To run a mutation, you first call `useAddAppDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppDomainMutation, { data, loading, error }] = useAddAppDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAppDomainMutation(baseOptions?: Apollo.MutationHookOptions<AddAppDomainMutation, AddAppDomainMutationVariables>) {
        return Apollo.useMutation<AddAppDomainMutation, AddAppDomainMutationVariables>(AddAppDomainDocument, baseOptions);
      }
export type AddAppDomainMutationHookResult = ReturnType<typeof useAddAppDomainMutation>;
export type AddAppDomainMutationResult = Apollo.MutationResult<AddAppDomainMutation>;
export type AddAppDomainMutationOptions = Apollo.BaseMutationOptions<AddAppDomainMutation, AddAppDomainMutationVariables>;
export const UpdateDomainDocument = gql`
    mutation updateDomain($input: UpdateDomainInput!) {
  updateDomain(input: $input) {
    id
    url
    mode
  }
}
    `;
export type UpdateDomainMutationFn = Apollo.MutationFunction<UpdateDomainMutation, UpdateDomainMutationVariables>;

/**
 * __useUpdateDomainMutation__
 *
 * To run a mutation, you first call `useUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainMutation, { data, loading, error }] = useUpdateDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDomainMutation, UpdateDomainMutationVariables>) {
        return Apollo.useMutation<UpdateDomainMutation, UpdateDomainMutationVariables>(UpdateDomainDocument, baseOptions);
      }
export type UpdateDomainMutationHookResult = ReturnType<typeof useUpdateDomainMutation>;
export type UpdateDomainMutationResult = Apollo.MutationResult<UpdateDomainMutation>;
export type UpdateDomainMutationOptions = Apollo.BaseMutationOptions<UpdateDomainMutation, UpdateDomainMutationVariables>;
export const ConnectStripeDocument = gql`
    mutation ConnectStripe($input: ConnectStripeAppInput!) {
  connectStripe(input: $input) {
    id
  }
}
    `;
export type ConnectStripeMutationFn = Apollo.MutationFunction<ConnectStripeMutation, ConnectStripeMutationVariables>;

/**
 * __useConnectStripeMutation__
 *
 * To run a mutation, you first call `useConnectStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectStripeMutation, { data, loading, error }] = useConnectStripeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectStripeMutation(baseOptions?: Apollo.MutationHookOptions<ConnectStripeMutation, ConnectStripeMutationVariables>) {
        return Apollo.useMutation<ConnectStripeMutation, ConnectStripeMutationVariables>(ConnectStripeDocument, baseOptions);
      }
export type ConnectStripeMutationHookResult = ReturnType<typeof useConnectStripeMutation>;
export type ConnectStripeMutationResult = Apollo.MutationResult<ConnectStripeMutation>;
export type ConnectStripeMutationOptions = Apollo.BaseMutationOptions<ConnectStripeMutation, ConnectStripeMutationVariables>;
export const DisconnectStripeDocument = gql`
    mutation DisconnectStripe {
  disconnectStripe {
    id
    stripeConnection {
      accountId
    }
  }
}
    `;
export type DisconnectStripeMutationFn = Apollo.MutationFunction<DisconnectStripeMutation, DisconnectStripeMutationVariables>;

/**
 * __useDisconnectStripeMutation__
 *
 * To run a mutation, you first call `useDisconnectStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectStripeMutation, { data, loading, error }] = useDisconnectStripeMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectStripeMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectStripeMutation, DisconnectStripeMutationVariables>) {
        return Apollo.useMutation<DisconnectStripeMutation, DisconnectStripeMutationVariables>(DisconnectStripeDocument, baseOptions);
      }
export type DisconnectStripeMutationHookResult = ReturnType<typeof useDisconnectStripeMutation>;
export type DisconnectStripeMutationResult = Apollo.MutationResult<DisconnectStripeMutation>;
export type DisconnectStripeMutationOptions = Apollo.BaseMutationOptions<DisconnectStripeMutation, DisconnectStripeMutationVariables>;
export const RemoveDomainDocument = gql`
    mutation RemoveDomain($input: RemoveDomainInput!) {
  removeDomain(input: $input)
}
    `;
export type RemoveDomainMutationFn = Apollo.MutationFunction<RemoveDomainMutation, RemoveDomainMutationVariables>;

/**
 * __useRemoveDomainMutation__
 *
 * To run a mutation, you first call `useRemoveDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDomainMutation, { data, loading, error }] = useRemoveDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDomainMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDomainMutation, RemoveDomainMutationVariables>) {
        return Apollo.useMutation<RemoveDomainMutation, RemoveDomainMutationVariables>(RemoveDomainDocument, baseOptions);
      }
export type RemoveDomainMutationHookResult = ReturnType<typeof useRemoveDomainMutation>;
export type RemoveDomainMutationResult = Apollo.MutationResult<RemoveDomainMutation>;
export type RemoveDomainMutationOptions = Apollo.BaseMutationOptions<RemoveDomainMutation, RemoveDomainMutationVariables>;
export const UpdateCustomDomainDocument = gql`
    mutation UpdateCustomDomain($input: UpdateCustomDomainInput!) {
  updateCustomDomain(input: $input) {
    rootDomain
    authSubdomain
  }
}
    `;
export type UpdateCustomDomainMutationFn = Apollo.MutationFunction<UpdateCustomDomainMutation, UpdateCustomDomainMutationVariables>;

/**
 * __useUpdateCustomDomainMutation__
 *
 * To run a mutation, you first call `useUpdateCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomDomainMutation, { data, loading, error }] = useUpdateCustomDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomDomainMutation, UpdateCustomDomainMutationVariables>) {
        return Apollo.useMutation<UpdateCustomDomainMutation, UpdateCustomDomainMutationVariables>(UpdateCustomDomainDocument, baseOptions);
      }
export type UpdateCustomDomainMutationHookResult = ReturnType<typeof useUpdateCustomDomainMutation>;
export type UpdateCustomDomainMutationResult = Apollo.MutationResult<UpdateCustomDomainMutation>;
export type UpdateCustomDomainMutationOptions = Apollo.BaseMutationOptions<UpdateCustomDomainMutation, UpdateCustomDomainMutationVariables>;