import { gql } from "@apollo/client";

export const GET_CURRENT_APP = gql`
  query CurrentApp {
    currentApp {
      id
      name
      slug
      status
      stack
      image
      createdAt
      memberCountLimit
      stripeConnection {
        accountId
      }
      domains {
        id
        url
        verified
        mode
      }
      businessEntityName
      termsOfServiceURL
      privacyPolicyURL
      stripePriceId
      stripeProductId
      stripeSubscriptionId
      stripeSubscriptionStatus
      preventDisposableEmails
      captchaEnabled
      stripeIdealEnabled
      stripeTaxIdCollectionEnabled
      stripeBillingAddressCollectionEnabled
      stripeDisablePaymentModeInvoices
      stripePortalCancelImmediately
      stripePortalDisableCancel
      ssoSignupButtonURL
      apiVersion
      clientMessageTextEnabled
      disableConcurrentLogins
      memberSessionDurationDays
      apiKeys {
        publicKey
        secretKey
      }
      requireUser2FA
      webflowSiteId
      webflowSiteName
      socketAuthToken
      passwordlessEnabled
      defaultToPasswordless
      customDomain {
        rootDomain
        authSubdomain
      }
      lastStripeAppFeeChange
      stripeAppFeePercent
      testModeMemberLimitEnabled
      churnkeyAuthToken
      stripeCustomerId
      weeklyRoundupEmailEnabled
      dailyCommentingEmailEnabled
      sendWelcomeEmailAfterVerification
    }
  }
`;

export const CREATE_APP = gql`
  mutation CreateApp($input: CreateAppInput!) {
    createApp(input: $input) {
      id
      status
      stack
    }
  }
`;

export const DELETE_APP = gql`
  mutation DeleteApp($input: DeleteAppInput!) {
    deleteApp(input: $input) {
      id
      status
    }
  }
`;

export const RESTORE_APP = gql`
  mutation RestoreApp($input: RestoreAppInput!) {
    restoreApp(input: $input) {
      id
      status
    }
  }
`;

export const ADD_USER_TO_APP = gql`
  mutation AddUserToApp($input: AddUserToAppInput!) {
    addUserToApp(input: $input) {
      app {
        id
      }
      role
    }
  }
`;

export const REMOVE_USER_FROM_APP = gql`
  mutation RemoveUserFromApp($input: RemoveUserFromAppInput!) {
    removeUserFromApp(input: $input) {
      app {
        id
      }
      role
    }
  }
`;

export const TRANSFER_APP_OWNERSHIP = gql`
  mutation TransferAppOwnership($input: TransferAppOwnershipInput!) {
    transferAppOwnership(input: $input) {
      app {
        id
      }
      role
    }
  }
`;

export const UPDATE_APP = gql`
  mutation UpdateApp($input: UpdateAppInput!) {
    updateApp(input: $input) {
      id
      name
      stack
      domains {
        url
        verified
      }
      stripeTaxIdCollectionEnabled
      stripeBillingAddressCollectionEnabled
      stripeIdealEnabled
      stripePortalCancelImmediately
      stripeDisablePaymentModeInvoices
      stripePortalDisableCancel
    }
  }
`;

export const GENERATE_API_KEYS = gql`
  mutation GenerateApiKeys {
    generateAPIKeys {
      publicKey
      secretKey
    }
  }
`;

export const ADD_EMAIL_DOMAIN = gql`
  mutation AddEmailDomain($input: AddEmailDomainInput!) {
    addEmailDomain(input: $input) {
      from
      verified
      emailDNSSettings {
        DKIMHost
        DKIMTextValue
        ReturnPathCNAMEValue
      }
    }
  }
`;

export const REMOVE_EMAIL_DOMAIN = gql`
  mutation RemoveEmailDomain {
    removeEmailDomain {
      verified
      from
    }
  }
`;

export const VERIFY_EMAIL_DOMAIN = gql`
  mutation VerifyEmailDomain {
    verifyEmailDomain {
      verified
      from
    }
  }
`;

export const GET_EMAILS_SETTINGS = gql`
  query GetEmailSettings {
    getEmails {
      from
      verified
      emailDNSSettings {
        DKIMHost
        DKIMTextValue
        ReturnPathCNAMEValue
      }
    }
  }
`;
