import React, { useCallback, useMemo } from "react";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { InfoTooltip } from "components/Tooltip";
import Tag from "components/Tag";
import { useAccordionContext } from "./Accordion.context";
import {
  ExpandableSection,
  Item,
  StyledAccordionItem,
} from "./Accordion.styles";

interface Props {
  label: string;
  className?: string;
  value: string;
  children: React.ReactNode;
  rightComponent?: React.ReactNode;
  hasOuterBorder?: boolean;
  // we only show this component when the Accordion is open or active
  ghostComponent?: React.ReactNode;
  removeSectionPadding?: boolean;
  dataCy?: string;
  tooltipDescription?: string;
  isBeta?: boolean;
}

function AccordionItem({
  label,
  value,
  className,
  children,
  rightComponent,
  hasOuterBorder = true,
  ghostComponent,
  removeSectionPadding = false,
  dataCy,
  tooltipDescription,
  isBeta,
}: Props) {
  const { activeItem, setToggle, striped, disabled } = useAccordionContext();

  const renderChevron = useMemo(
    () =>
      activeItem === value ? (
        <ArrowDropDown className="chevron" />
      ) : (
        <ArrowRight className="chevron" />
      ),
    [activeItem, value]
  );

  const handleOnToggle = useCallback(() => {
    if (disabled) return;
    setToggle(value);
  }, [disabled, setToggle, value]);

  const isActive = activeItem === value;

  return (
    <Item
      hasBorder={striped}
      hasOuterBorder={hasOuterBorder}
      className={className}
    >
      <StyledAccordionItem
        aria-controls={`${value}-panel`}
        aria-disabled="false"
        aria-expanded={isActive}
        id={`${value}-header`}
        onClick={handleOnToggle}
        selected={isActive}
        data-cy={dataCy}
        disabled={disabled}
      >
        <div className="flex items-center">
          {renderChevron}
          {label}
          {isBeta && <Tag text="Beta" className="ml-2" variant="beta" />}
          {tooltipDescription && (
            <div className="ml-1">
              <InfoTooltip
                size="md"
                content={tooltipDescription}
                showArrow
                placement="top"
              />
            </div>
          )}
        </div>
        <div
          className="flex items-center"
          onClick={(e) => e.stopPropagation()}
          role="button"
          aria-hidden="true"
        >
          {rightComponent}
          {isActive && ghostComponent && <div tw="ml-4">{ghostComponent}</div>}
        </div>
      </StyledAccordionItem>
      <ExpandableSection
        aria-hidden={!isActive}
        aria-labelledby={`${value}-header`}
        expanded={isActive}
        $removeSectionPadding={removeSectionPadding}
        // hidden={activeItem !== value}
        id={`${value}-panel`}
      >
        {children}
      </ExpandableSection>
    </Item>
  );
}

export default AccordionItem;
