import React, { useState, useContext, useEffect } from "react";
import { useTransition } from "react-spring";
import { springConfigs } from "styles";
import { act } from "@testing-library/react";
import * as S from "../ToastAlert.styles";
import { Link } from "../../Link/Link";
import ToastAlert from "../ToastAlert";

interface Toast {
  message?: React.ReactNode | string;
  alertType?: "success" | "error";
  processing?: boolean;
}

interface DefaultContext {
  createToastAlert: (toast: Toast) => void;
}

const defaultContext: DefaultContext = {
  createToastAlert: () => {},
};

const ToastAlertContext = React.createContext(defaultContext);

const useToastAlertContext = () => {
  return useContext(ToastAlertContext);
};

const ToastAlertContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [toast, setToast] = useState<Toast>(null);
  const TIMEOUT = 5000;
  let timeoutId: null | ReturnType<typeof setTimeout>;

  const handleClose = () => setToast(null);

  const createToastAlert = (t: Toast) => {
    handleClose();
    setTimeout(() => {
      act(() => {
        setToast(t);
      });
    });
  };

  const transitions = useTransition(toast, null, {
    config: { ...springConfigs.mediumTension },
    from: { opacity: 0, bottom: -16, transform: "scale(0.9, 0.9)" },
    enter: { opacity: 1, bottom: 12, transform: "scale(1, 1)" },
    leave: { opacity: 0, bottom: -16, transform: "scale(0.9, 0.9)" },
  });

  useEffect(() => {
    if (toast) {
      let timeout = TIMEOUT;
      if (toast.alertType === "error") {
        timeout = TIMEOUT * 100;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutId = setTimeout(() => {
        handleClose();
      }, timeout);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [toast]);

  const getToastMessage = (alertType, message) => {
    if (alertType === "success") return message;
    return (
      <>
        {message}&nbsp;
        <Link
          isExternal
          target="_blank"
          underline
          tw="text-current"
          to="https://docs.memberstack.com/hc/en-us"
        >
          Contact support
        </Link>
      </>
    );
  };

  return (
    <ToastAlertContext.Provider value={{ createToastAlert }}>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <S.ToastContainer key={key} style={props}>
              <ToastAlert
                message={getToastMessage(item.alertType, item.message)}
                alertType={item.alertType}
                onClose={handleClose}
                processing={item.processing}
              />
            </S.ToastContainer>
          )
      )}
      {children}
    </ToastAlertContext.Provider>
  );
};

export { useToastAlertContext, ToastAlertContextProvider };
