import React, { useState, useMemo, useEffect } from "react";
import { UserAppRole, UserSessionAcr } from "generatedTypes";
import { useUpdateAppMutation } from "features";
import { Switch, Card } from "components";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { useAppDataContext } from "routes/AppDataContextProvider";
import TeamMemberTable from "./TeamMemberTable";
import AccessLog from "./AccessLog";
import AppEvents from "./AppEvents";

const TeamSettings = () => {
  const [enable2FA, setEnable2FA] = useState(false);

  const { currentUser } = useUsersContext();
  const { appData, refetch: refetchAppSettings } = useAppDataContext();

  const [updateRequire2FA, { loading: updating }] = useUpdateAppMutation();

  const {
    session: { acr },
    appConnections,
  } = currentUser;

  const requireUser2FA = appData?.requireUser2FA;
  const appId = appData?.id;

  useEffect(() => setEnable2FA(requireUser2FA), [requireUser2FA]);

  const isOwner = useMemo(() => {
    return appConnections?.some(
      (user) => user.app.id === appId && user?.role === UserAppRole.Owner
    );
  }, [appConnections, appId]);

  const { submit: handle2FAToggle } = useOnSubmit({
    action: updateRequire2FA,
    fields: { requireUser2FA: !enable2FA },
    refetch: refetchAppSettings,
    errorMsg: "Unable to enforce 2FA.",
    successMsg: !enable2FA
      ? "2FA has been successfully enforced."
      : "2FA has been successfully disabled.",
  });

  return (
    <div className="flex flex-col gap-5">
      {isOwner && acr === UserSessionAcr.Twofactor && (
        <Card hideShadow={false} className="p-4">
          <Switch
            id="enable2FA"
            label="Enforce two-factor authentication"
            isChecked={enable2FA}
            onChange={() => handle2FAToggle()}
            disabled={updating}
            dataCy="enable2FA"
            description={
              <p className="text-app-gray600 text-body-sm">
                Two-factor authentication requires users to enter a code
                generated by their authenticator app every time they log in.
              </p>
            }
          />
        </Card>
      )}
      <TeamMemberTable />
      <AppEvents />
      <AccessLog />
    </div>
  );
};

export default TeamSettings;
