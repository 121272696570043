export const generateID = (input) =>
  input
    .replace(/[']/g, "") // remove apostrophes
    .replace(/[\W_]+/g, "-") // replace all non-word characters with dashes
    .replace(/[-]$/g, "") // remove dashes at the end
    .replace(/[!]/g, "") // ! is a reserved word
    .replace(/member/g, "mbr") // member is a reserved word
    .replace(/verified/g, "vrf") // verified is a reserved word
    .replace(/password/g, "pwd") // password is a reserved word
    .replace(/^-/, "") // remove dashes at the beginning
    .toLowerCase();
