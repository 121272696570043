import React, { useMemo, useState } from "react";
import Tippy from "@tippyjs/react";
import { ExpandMore } from "@mui/icons-material";
import { modes } from "constants/domainModes";
import { useSpring } from "react-spring";
import {
  OptionsContainer,
  Option,
  SelectContainer,
} from "./DomainInput.styles";

interface Props {
  initialMode?: string;
  isLiveOptionDisabled?: boolean;
  onChange?: (mode: string) => void;
}

export const ModeSelector = ({
  initialMode,
  isLiveOptionDisabled,
  onChange,
}: Props) => {
  const [mode, setMode] = useState<string>(initialMode || modes[1]);
  const config = { tension: 300, friction: 15 };
  const initialStyles = { opacity: 0, transform: "scale(0.5)" };
  const [props, setSpring] = useSpring(() => initialStyles);

  const onMount = () =>
    setSpring({
      opacity: 1,
      transform: "scale(1)",
      onRest: () => {},
      config,
    });

  const onHide = ({ unmount }) =>
    setSpring({
      ...initialStyles,
      onRest: unmount,
      config: { ...config, clamp: true },
    });

  const contentOptions = useMemo(() => {
    const handleSelectedMode = (option) => () => {
      onChange(option);
      setMode(option);
    };
    return (
      <OptionsContainer style={props}>
        <Option
          aria-hidden="true"
          key="LIVE"
          role="button"
          onClick={handleSelectedMode("LIVE")}
          $isSelected={mode === "LIVE"}
          $isDisabled={isLiveOptionDisabled}
        >
          <span tw="block truncate capitalize">live mode</span>
        </Option>
        <Option
          aria-hidden="true"
          key="SANDBOX"
          role="button"
          onClick={handleSelectedMode("SANDBOX")}
          $isSelected={mode === "SANDBOX"}
        >
          <span tw="block truncate capitalize">test mode</span>
        </Option>
      </OptionsContainer>
    );
  }, [isLiveOptionDisabled, mode, onChange, props]);

  return (
    <Tippy
      content={contentOptions}
      placement="bottom"
      role="button"
      trigger="click"
      interactive
      animation
      onMount={onMount}
      onHide={onHide}
    >
      <SelectContainer>
        <span tw="block truncate capitalize">
          {mode.toLocaleLowerCase() === "sandbox" ? "test" : "live"} mode
        </span>
        <div tw="absolute inset-y-0 right-0 flex items-center pointer-events-none">
          <ExpandMore tw="h-4 w-4 text-app-gray500" aria-hidden="true" />
        </div>
      </SelectContainer>
    </Tippy>
  );
};
