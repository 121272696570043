import React, { useEffect, useMemo, useState } from "react";
import { Close, Construction } from "@mui/icons-material";
import { Plan, PriceStatus, PriceType } from "generatedTypes";
import { Card, CopyButton } from "components";
import Input from "components/Input";
import { Modal, ModalTypes } from "components/Modal";
import Select from "components/Select";
import Video from "components/Video";
import { Tooltip } from "components/Tooltip";
import PlanNameUpdateVideo from "assets/videos/plan_name_update.mp4";
import PlanValueUpdateVideo from "assets/videos/plan_value_update.mp4";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
`;

const ResponsiveCard = styled(Card)`
  max-width: 542px;
  @media (max-width: 990px) {
    max-width: 350px;
  }
`;

const Section = ({ name, id, description, title }) => (
  <div className="py-6 px-4 ">
    <h2 className="text-base font-bold mb-1">{title}</h2>
    <p className="text-app-gray600 text-sm mb-4">{description}</p>
    <Grid className="gap-8 gap-y-3">
      <label className="text-utility-tag-md block self-center font-bold">
        Name
      </label>
      <Input
        hideLabel
        hideIndicators
        value={name}
        readOnly
        trailing={
          <Tooltip
            placement="bottom-end"
            content={
              <video autoPlay loop muted playsInline>
                <source src={PlanNameUpdateVideo} type="video/mp4" />
              </video>
            }
          >
            <CopyButton
              value={name}
              inverted
              message="Installation Name copied."
            />
          </Tooltip>
        }
      />
      <label className="text-utility-tag-md block self-center font-bold">
        ID
      </label>
      <Input
        hideLabel
        hideIndicators
        value={id}
        readOnly
        trailing={
          <Tooltip
            placement="bottom-end"
            content={
              <video autoPlay loop muted playsInline>
                <source src={PlanValueUpdateVideo} type="video/mp4" />
              </video>
            }
          >
            <CopyButton value={id} inverted message="Installation ID copied." />
          </Tooltip>
        }
      />
    </Grid>
  </div>
);

interface Props extends ModalTypes {
  plan: Plan;
  // this is the id of the active price on page
  activePriceId?: string;
}

const PlanInstallModal = ({
  setShowModal,
  showModal,
  plan,
  activePriceId = null,
}: Props) => {
  const [priceOption, setPriceOption] = useState({
    label: "",
    value: "",
    type: PriceType.Onetime,
  });

  const { prices, isPaid } = plan;
  const isPaidWithPrices = prices?.length > 0 && isPaid;

  const activePricingOptions = useMemo(() => {
    if (!prices) return [];

    return prices
      .filter((price) => price && price.status === PriceStatus.Active)
      .map((price) => ({
        label: price.name,
        value: price.id,
        type: price.type,
      }));
  }, [prices]);

  const videoUrl = useMemo(() => {
    if (isPaidWithPrices) {
      return priceOption.type === PriceType.Subscription
        ? "https://www.youtube.com/embed/Uz5Iyjz7KvA?si=PdELWU1RdUrKKDAF"
        : "https://www.youtube.com/embed/IyV9lOmTD-U?si=6yrM1QUxS6SE4dwn";
    }
    return "https://www.youtube.com/embed/4-KO_k3-JRY?si=URakGtqIbD36E-EB";
  }, [isPaidWithPrices, priceOption.type]);

  useEffect(() => {
    if (activePricingOptions.length > 0) {
      if (activePriceId) {
        const activePrice = activePricingOptions.find(
          (price) => price.value === activePriceId
        );
        setPriceOption(activePrice || activePricingOptions[0]);
      } else {
        setPriceOption(activePricingOptions[0]);
      }
    }
  }, [activePricingOptions, activePriceId]);

  return (
    <Modal
      removePadding
      title="Install"
      setShowModal={setShowModal}
      showModal={showModal}
      isCustom
      width="960px"
    >
      <Grid className="p-1.5 gap-1.5 bg-app-gray50 rounded-lg">
        <ResponsiveCard>
          <div className="p-4 h-full flex flex-col">
            <Construction
              className="mb-1.5 text-app-blue400"
              style={{ fontSize: 24 }}
            />
            <div>
              <h1 className="text-h4 font-bold mb-1.5">
                Webflow Installation Instructions
              </h1>
              <p className="text-body-sm text-app-gray700 mt-2">
                Allow users to sign up for this paid plan by adding these
                attributes to your signup flow, pricing tables, upgrade pages,
                etc.
              </p>
            </div>
            <div className="flex-1" />
            <div>
              <h2 className="text-base font-bold mb-1.5 mt-3">
                How to Install
              </h2>
              <Video
                src={videoUrl}
                title="Webflow Installation Instructions"
                height={360}
                width="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </div>
          </div>
        </ResponsiveCard>
        <div className="relative w-full">
          <div
            className="p-1 cursor-pointer absolute top-2 right-2 hover:bg-app-gray100 rounded-full"
            onClick={() => setShowModal(false)}
            aria-hidden
          >
            <span className="sr-only">Close</span>
            <Close
              aria-hidden="true"
              style={{ height: 24, width: 24 }}
              className="!text-app-gray500 hover:(text-black)!"
            />
          </div>
          <dl className="divide-y divide-app-gray200">
            {isPaidWithPrices && (
              <div className="py-6 px-4">
                <h2 className="text-base font-bold mb-1">Choose Price</h2>
                <Select
                  options={activePricingOptions}
                  value={priceOption}
                  onChange={(value) => setPriceOption(value)}
                />
                <p className="text-utility-tag-md text-app-gray600 mt-1.5">
                  Select the plan you want to install.
                </p>
              </div>
            )}
            {isPaidWithPrices &&
              priceOption.type === PriceType.Subscription && (
                <Section
                  title="Update Price"
                  description="Launch the checkout onClick. The update attribute will replace their existing paid subscription."
                  name="data-ms-price:update"
                  id={priceOption.value}
                />
              )}

            <Section
              title={`Add ${isPaidWithPrices ? "Price" : "Plan"}`}
              description={
                isPaidWithPrices
                  ? "Launch the checkout onClick. This attribute allows members to have multiple paid plans at once. "
                  : "Add a free plan on click. Can be used before or after signup."
              }
              name={isPaidWithPrices ? "data-ms-price:add" : "data-ms-plan:add"}
              id={isPaidWithPrices ? priceOption.value : plan.id}
            />

            {!isPaidWithPrices && (
              <Section
                title="Remove Plan"
                description="Remove a free plan on click. Can be used before or after signup."
                name="data-ms-plan:remove"
                id={plan.id}
              />
            )}
          </dl>
        </div>
      </Grid>
    </Modal>
  );
};

export default PlanInstallModal;
