import { QuestionMark, Search, SmartToy, AutoStories, MarkChatRead, Send } from "@mui/icons-material";
import React from "react";
import useClickout from "hooks/useClickout";
import * as S from "./support-widget";


const SupportWidget = () => {


  const { ref, isVisible, setIsVisible } = useClickout(false);
  const showCommandBar = () => window.CommandBar?.open();

  const toggleWidgetMenu = () => setIsVisible(!isVisible);


  return (
    <div ref={ref} className="fixed bottom-4 right-5 z-50 font-normal">
      <S.Menu  isOpen={isVisible}>
        <S.MenuGroup>
          <S.FindAnythingButton onClick={showCommandBar}>
            <Search/>
            <div className="find-anything-text">Find anything</div>
            <div className="help-command-k-icon">⌘ + K</div>
          </S.FindAnythingButton>
        </S.MenuGroup>
        <S.MenuGroup>
          <S.MenuLink href="https://rey.memberstack.com" target="_blank" rel="noreferrer">
            <SmartToy/> Ask the AI<span>New</span>
          </S.MenuLink>
          <S.MenuLink href="https://docs.memberstack.com/hc/en-us" target="_blank" rel="noreferrer">
            <AutoStories/> Search Help Center
          </S.MenuLink>
          <S.MenuLink href="https://docs.memberstack.com/hc/en-us/community/posts" target="_blank" rel="noreferrer">
            <MarkChatRead/> Ask the Community</S.MenuLink>
          <S.MenuLink href="https://docs.memberstack.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">
            <Send/> Message Support</S.MenuLink>
        </S.MenuGroup>
        <hr/>
        <S.MenuGroup>
          <S.MenuLink href="https://memberstack.com/slack" target="_blank" rel="noreferrer">Slack Channel</S.MenuLink>
          <S.MenuLink href="https://docs.memberstack.com/hc/en-us/community/topics/10371192481435-Product-Wishlist"
            target="_blank" rel="noreferrer">Product Wishlist</S.MenuLink>
          <S.MenuLink href="https://docs.memberstack.com/hc/en-us/community/topics/10306226780571-General" target="_blank" rel="noreferrer">Report a
            Bug</S.MenuLink>
        </S.MenuGroup>
        <hr/>
        <S.MenuGroup>
          <S.MenuLink className="ms-widget__link-group--small" href="https://twitter.com/memberstack" target="_blank" rel="noreferrer">Twitter - @Memberstack</S.MenuLink>
          <S.MenuLink className="ms-widget__link-group--small" href="https://status.memberstack.com" target="_blank" rel="noreferrer">System Status</S.MenuLink>
        </S.MenuGroup>
        <hr/>
        <S.MenuGroup>
          <S.MenuLink className="ms-widget__link-group--help-text" href="https://docs.memberstack.com/hc/en-us/sections/11419228018587-Legal" target="_blank" rel="noreferrer">Terms & Privacy
            Policies</S.MenuLink>
        </S.MenuGroup>
      </S.Menu>
      <S.SupportButton type="button"  onClick={toggleWidgetMenu}>
        <QuestionMark/>
      </S.SupportButton>
  </div>
  );
}

export default SupportWidget;
