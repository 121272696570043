import React, { useMemo } from "react";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Edit } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";
import { Button } from "../Button";

interface TextComboProps {
  hasLabel?: boolean;
  isEditable?: boolean;
  className?: string;
  spaceOut?: boolean;
}

const TextComboLoading = ({
  hasLabel,
  className,
  isEditable = false,
  spaceOut = false,
}: TextComboProps) => {
  const renderAction = useMemo(() => {
    if (isEditable) {
      return (
        <Button
          buttonStyle="transparent"
          onClick={undefined}
          leftIcon={<Edit tw="ml-1" />}
        />
      );
    }

    return null;
  }, [isEditable]);
  return (
    <div className={className}>
      {hasLabel && <Skeleton width={70} height={24} className="mb-1" />}
      <div
        className={mergeClassNames(
          spaceOut && "justify-between",
          isEditable ? "block" : "flex items-center"
        )}
      >
        <Skeleton width={255.5} height={28} />
        {renderAction}
      </div>
    </div>
  );
};

export default TextComboLoading;
