import React, { useState } from "react";
import { useEnvContext } from "containers/App/contexts/Environment";
import { SectionCard } from "components/Card/Card";
import Spacer from "components/Spacer";
import { Button, CopyButton } from "components";
import SensitiveText from "components/SensitiveText";
import { ApiKeys } from "generatedTypes";
import CreateConfirmModal from "../modals/GenerateApiKeyModal";
import ResetSecretKeyModal from "../modals/ResetSecretKeyModal";

interface Props {
  apiKeys: ApiKeys;
}
const ApiKeysSection = ({ apiKeys }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showResetKeyModal, setShowResetKeyModal] = useState(false);
  const { publicKey, secretKey } = apiKeys;
  const { isSandboxEnv } = useEnvContext();

  return (
    <>
      <SectionCard
        title={isSandboxEnv ? "Test Mode API Keys" : "API Keys"}
        footerSection={
          <p className="text-utility-md">
            Never share your API Keys with anyone.{" "}
            <button
              className="text-app-blue400 font-bold"
              type="button"
              onClick={() => setShowResetKeyModal(true)}
            >
              Reset your Secret Key.
            </button>
          </p>
        }
      >
        {!publicKey && !secretKey ? (
          <div tw="flex flex-col items-center">
            <span tw="mr-3.5 text-base whitespace-nowrap font-bold">
              No keys generated
            </span>
            <Spacer isVertical spacing="small" />
            <Button text="Generate" onClick={() => setShowModal(true)} />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span>Public Key</span>
              <div className="flex items-center min-w-0">
                <span className="mr-3.5 whitespace-nowrap">{publicKey}</span>
                <CopyButton value={publicKey} />
              </div>
            </div>
            <SensitiveText
              secretText={secretKey}
              label="Secret Key"
              spaceOutContent
            />
          </div>
        )}
      </SectionCard>
      <ResetSecretKeyModal
        showModal={showResetKeyModal}
        setShowModal={setShowResetKeyModal}
      />
      <CreateConfirmModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default ApiKeysSection;
