import React, { useMemo } from "react";
import { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import Select from "components/Select";
import Text from "components/Text";
import { PriceExpirationInterval } from "generatedTypes";

const frequencyOptions = [
  { label: "days", value: PriceExpirationInterval.Days },
  { label: "months", value: PriceExpirationInterval.Months },
];

export const Expiration = ({
  count,
  interval,
  setPriceInputs,
  disabled,
  priceInputs,
}) => {
  const expirationInterval = useMemo(
    () => frequencyOptions.find((option) => option.value === interval),
    [interval]
  );

  const handleOnChange = ({ target: { name, value } }) =>
    setPriceInputs((prev) => ({ ...prev, [name]: parseInt(value, 10) }));

  return (
    <div className={disabled && "opacity-50"}>
      <TitleSection
        title="Expiration"
        titleHint="Set price expiration"
        divider={false}
        gutter="medium"
      >
        <div tw="flex justify-between items-center mb-5">
          <Text className="text-utility-md">Automatically cancel after</Text>
          <div tw="w-32">
            <Input
              value={count}
              name="expirationCount"
              onChange={(e) => handleOnChange(e)}
              trailingInline
              trailing={
                <div tw="flex">
                  <Select
                    label=""
                    value={expirationInterval}
                    onChange={(option) =>
                      setPriceInputs({
                        ...priceInputs,
                        expirationInterval: option.value,
                      })
                    }
                    options={frequencyOptions}
                    inline
                    isDisabled={disabled}
                    alternateDropdown
                  />
                </div>
              }
              hideIndicators
              disabled={disabled}
              inputPadding={{
                right: "0",
              }}
            />
          </div>
        </div>
      </TitleSection>
    </div>
  );
};
