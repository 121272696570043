import React, { useMemo, useState, useEffect } from "react";
import Input from "components/Input";
import { Modal, ModalTypes } from "components/Modal";
import { useToastAlertContext } from "components/toastAlert";
import { useAddEmailDomainMutation, useVerifyEmailDomainMutation } from "features";
import { EmailDnsSettings } from "generatedTypes";
import { Card, CodeBlock, CopyButton } from "components";

const SetupConfig = ({ emailDomain, setEmailDomain }) => (
  <Input 
  hideIndicators
  label="Email Sender Address"
  placeholder="e.g. support@mydomain.com"
  value={emailDomain}
  onChange={(e) => setEmailDomain(e.target.value)}
  description="This will allow you to send email from this address"
  />
);

const VerifyConfig = ({ emailSettings }) => {
  const settings = useMemo(() => [
    {
      hostname: "TXT",
      type: emailSettings.DKIMHost,
      value: emailSettings.DKIMTextValue,
    },
    {
      hostname: "CNAME",
      type: "pm-bounces",
      value: emailSettings.ReturnPathCNAMEValue,
    },
  ], [emailSettings]);

  return (
    <div className="flex flex-col gap-4">
      <p className="text-body-sm">Add these CNAME records to your DNS provider. Learn more.</p>
      {settings.map((setting) => (
        <Card key={setting.hostname}>
          <dl className="divide-y divide-gray-200">
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm leading-6 text-gray-900 font-bold">Hostname</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{setting.hostname}</dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm leading-6 text-gray-900 font-bold">Type</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                {setting.type} 
                <CopyButton value={setting.type} className="ml-1"/>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm leading-6 text-gray-900 font-bold">Value</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-all flex items-center">
                {setting.value}
                <CopyButton value={setting.value} className="ml-1"/>
              </dd>
            </div>
          </dl>
        </Card>
      ))}
    </div>
    )
  };

interface Props extends ModalTypes {
  step?: number;
  emailDnsSettings?: EmailDnsSettings;
  refetch?: () => void;
} 

const ConfigureEmailSenderAddressModal =({
  showModal,
  setShowModal,
  step,
  emailDnsSettings,
  refetch,
}: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [emailSettings, setEmailSettings] = useState<EmailDnsSettings>(emailDnsSettings)
  const [emailDomain, setEmailDomain] = useState<string>("");

  useEffect(() => {
    if (emailDnsSettings) {
      setEmailSettings(emailDnsSettings);
      setCurrentStep(2);
    }
  }, [emailDnsSettings]);
  
  const { createToastAlert } = useToastAlertContext();
  const [addEmailDomain, { loading: adding }] = useAddEmailDomainMutation();
  const [verifyEmailDomain, { loading: verifying }] = useVerifyEmailDomainMutation();

  const handleAddEmailDomain = async () => {
    try {
      await addEmailDomain({
        variables: {
          input: {
            email: emailDomain,
          },
        },
      }).then(({ data }) => {
        setEmailSettings(data.addEmailDomain.emailDNSSettings);
      });
      setCurrentStep(2);
      refetch();
      createToastAlert({
        alertType: "success",
        message: "Email domain added successfully",
      });
    } catch (error) {
      createToastAlert({
        message: "Error adding email domain",
        alertType: "error",
      });
    }
  };

  const handleVerifyEmailDomain = async () => {
    try {
      await verifyEmailDomain();
      createToastAlert({
        alertType: "success",
        message: "Email is being verified, this may take up to 24 hours",
      });
      setShowModal(false);
      refetch();
    } catch (error) {
      createToastAlert({
        message: "Error verifying email domain",
        alertType: "error",
      });
    }
  };

  const stepInfo = {
    1: {
      buttonText: "Continue",
      description: "Step 1 of 2: Enter your Email Sender Address",
      onConfirm: () => handleAddEmailDomain(),
    },
    2: {
      buttonText: "Verify",
      description: "Step 2 of 2: Verify your Email Sender Address",
      onConfirm: () => handleVerifyEmailDomain(),
    },
  };

  return(
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      width={currentStep === 1 ? "512px" : "640px"}
      title="Configure Email Sender Address"
      description={!emailDnsSettings && stepInfo[currentStep].description}
      showDivider
      actionButtons={{
        cancel: {
          label: "Cancel",
        },
        confirm: {
          label: stepInfo[currentStep].buttonText,
          isLoading: adding || verifying,
          onConfirm: () => stepInfo[currentStep].onConfirm(),
          isDisabled: currentStep === 1 && !emailDomain,
        },
      }}
    >
      {currentStep === 1 && (
        <SetupConfig emailDomain={emailDomain} setEmailDomain={setEmailDomain} />
      )}
      {currentStep === 2 && <VerifyConfig emailSettings={emailSettings} />}
    </Modal>
  )
};

export default ConfigureEmailSenderAddressModal;