import React from "react";
import { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Link } from "components";

export const SetupFee = ({
  setPriceInputs,
  priceInputs,
  disabled,
  amount,
  name,
}) => {
  const handleOnChange = ({ target: { name: fieldName, value } }) => {
    setPriceInputs((prev) => ({
      ...prev,
      [fieldName]: fieldName === "setupFeeAmount" ? parseFloat(value) : value,
    }));
  };
  return (
    <div className={mergeClassNames(disabled && "opacity-50")}>
      <TitleSection
        title="Setup fees"
        titleHint="Set plan setup fees"
        gutter="medium"
      >
        <Input
          label="Label"
          name="setupFeeName"
          placeholder="Enter a label"
          className="mb-4"
          value={name}
          onChange={(e) => handleOnChange(e)}
          disabled={disabled}
          hideIndicators
          description={
            <>
              Used in checkout and invoices{" "}
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/12126063797403"
                target="_blank"
                isExternal
                underline
              >
                Help Docs →
              </Link>
            </>
          }
        />
        <Input
          label="Amount"
          placeholder="Amount"
          name="setupFeeAmount"
          value={amount}
          onChange={(e) => handleOnChange(e)}
          leading="$"
          leadingInline
          type="number"
          disabled={disabled}
          hideIndicators
          description="One-time charge. "
        />
      </TitleSection>
    </div>
  );
};
