import React, { useMemo } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import * as S from "./Checkbox.styles";
import { CheckboxProps } from "./Checkbox.types";

export const Checkbox = ({
  className,
  checked,
  disabled,
  label,
  onChange,
  readOnly,
  required,
  value,
  id,
  name,
  description,
  type = "regular",
}: CheckboxProps) => {
  const visibilityCheckbox = useMemo(
    () => (
      <S.Visibility className="switch" $checked={checked}>
        <input
          type="checkbox"
          onClick={(e) => e.stopPropagation()}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          id={id}
          readOnly={readOnly}
          required={required}
          name={name}
          value={value}
        />
        {checked ? <VisibilityOff /> : <Visibility />}
        {label || label}
      </S.Visibility>
    ),
    [checked, disabled, id, label, name, onChange, readOnly, required, value]
  );

  const roundCheckbox = useMemo(
    () => (
      <>
        <S.RoundedCheckbox className="round">
          <input
            checked={checked}
            disabled={disabled}
            id={id}
            onChange={onChange}
            readOnly={readOnly}
            required={required}
            type="checkbox"
            name={name}
            value={value}
          />
          <label htmlFor={id} />
        </S.RoundedCheckbox>
        {label && (
          <label
            className="ml-2 block text-utility-md cursor-pointer"
            htmlFor={id}
          >
            {label}
          </label>
        )}
      </>
    ),
    [checked, disabled, id, label, name, onChange, readOnly, required, value]
  );

  const regularCheckbox = useMemo(
    () => (
      <>
        <S.RegularCheckbox
          $hasDescription={Boolean(description)}
          checked={checked}
          className="form-checkbox"
          disabled={disabled}
          id={id}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          type="checkbox"
          name={name}
          value={value}
        />
        {label && (
          <label className="pl-2 cursor-pointer" htmlFor={id}>
            <span
              className={mergeClassNames(
                "block",
                description ? "text-base" : "text-body-sm"
              )}
            >
              {label}
            </span>
            <p className="text-body-sm">{description}</p>
          </label>
        )}
      </>
    ),
    [
      checked,
      disabled,
      id,
      label,
      name,
      onChange,
      readOnly,
      required,
      value,
      description,
    ]
  );

  const renderCheckbox = useMemo(() => {
    switch (type) {
      case "regular":
        return regularCheckbox;
      case "rounded":
        return roundCheckbox;
      default:
        return visibilityCheckbox;
    }
  }, [regularCheckbox, roundCheckbox, type, visibilityCheckbox]);

  return (
    <S.Container $hasDescription={Boolean(description)} className={className}>
      {renderCheckbox}
    </S.Container>
  );
};
