import React, { useState } from "react";
import { Modal, ModalTypes } from "components/Modal";
import { Checkbox, AttributeInstallationBox } from "components";
import { useUpdateAppMutation } from "features";
import { useAppDataContext } from "routes/AppDataContextProvider";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";

export const EnableHCaptchaModal = ({
  showModal,
  setShowModal,
}: ModalTypes) => {
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const { refetch } = useAppDataContext();

  const [updateAppInfo, { loading }] = useUpdateAppMutation();

  const { submit } = useOnSubmit({
    action: updateAppInfo,
    fields: { captchaEnabled: true },
    cleanup: () => setShowModal(false),
    errorMsg: "Unable to enable hCaptcha.",
    refetch,
  });

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Enable hCaptcha"
      showDivider
      actionButtons={{
        confirm: {
          label: "Confirm",
          onConfirm: () => submit(),
          isLoading: loading,
          isDisabled: !isAcknowledged,
        },
      }}
    >
      <p tw="text-base mb-6">
        <span tw="font-bold">
          Custom signup forms will not work until you add the required hCaptcha
          element.
        </span>{" "}
        You don’t need to add anything if you’re using our data-ms-modals.
      </p>
      <AttributeInstallationBox
        description="Add this attribute to a <div> inside your signup forms. We will inject the hCaptcha here."
        docsLink="https://docs.memberstack.com/hc/en-us/articles/11398218093595-Prevent-Spam-with-hCaptcha"
        nameValue="data-ms-hCaptcha"
        idValue="light"
        className="mt-7"
      />
      <Checkbox
        tw="mt-6"
        label="I understand custom signup forms will not work until I add the required hCaptcha
          element as shown above."
        checked={isAcknowledged}
        id="hCaptcha-acknowledgement"
        onChange={() => setIsAcknowledged(!isAcknowledged)}
      />
    </Modal>
  );
};
