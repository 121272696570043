import { PlanStatus } from "generatedTypes";

const getActiveFreePlans = (plans) =>
  plans
    .filter((p) => p.status === PlanStatus.Active && !p?.prices.length)
    .map(({ name, id }) => ({
      value: id,
      label: name,
      planId: id,
    }));

export default getActiveFreePlans;
