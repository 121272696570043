import React from "react";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useGetHasPaidPlansQuery } from "features/plans";
import { StripeSettingsLoading } from "./StripeSettings.loader";
import StripeSettings from "./StripeSettings";

const StripeSettingsPage = () => {
  const { appData, isLoading } = useAppDataContext();
  const { loading, data } = useGetHasPaidPlansQuery();

  if (loading || isLoading) {
    return <StripeSettingsLoading />;
  }

  const hasPaidPlans = data?.getHasPaidPlans;
  const isStripeConnected = Boolean(
    appData && appData.stripeConnection?.accountId
  );

  const {
    stripeTaxIdCollectionEnabled,
    stripeBillingAddressCollectionEnabled,
    stripeIdealEnabled,
    stripePortalCancelImmediately,
    stripeDisablePaymentModeInvoices,
    stripePortalDisableCancel,
  } = appData || {};

  const stripeSettings = {
    stripeTaxIdCollectionEnabled,
    stripeBillingAddressCollectionEnabled,
    stripeIdealEnabled,
    stripePortalCancelImmediately,
    stripeDisablePaymentModeInvoices,
    stripePortalDisableCancel,
  };

  return (
    <StripeSettings
      stripeSettings={stripeSettings}
      appId={appData && appData.id}
      hasPaidPlans={hasPaidPlans}
      isStripeConnected={isStripeConnected}
      stripeAccountId={appData && appData.stripeConnection?.accountId}
    />
  );
};

export default StripeSettingsPage;
