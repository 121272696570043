import { OpenInNewOutlined } from "@mui/icons-material";
import Video from "components/Video";
import React from "react";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Link } from "../Link/Link";

interface Props {
  headerText: string;
  videoSrc: string;
  videoTitle: string;
  videoWidth: number;
  videoHeight: number;
  docsLink: string;
  docsTitle: string;
  fullPage?: boolean;
  centerItems?: boolean;
  className?: string;
}

const Onboarding = ({
  headerText,
  videoSrc,
  videoTitle,
  videoWidth,
  videoHeight,
  docsLink,
  docsTitle,
  fullPage = false,
  centerItems = true,
  className,
}: Props) => (
  <>
    <div
      className={mergeClassNames(
        "flex flex-col justify-center",
        centerItems && "items-center",
        fullPage && "left-0 right-0 mx-auto w-2/4 h-2/3",
        className
      )}
    >
      <h3 tw="text-h3 font-normal text-app-gray400 mb-3">{headerText}</h3>
      <Video
        src={videoSrc}
        allowFullScreen
        title={videoTitle}
        className="mb-3"
        width={videoWidth}
        height={videoHeight}
      />
      <Link
        className="flex items-center"
        isExternal
        to={docsLink}
        target="_blank"
        font="regular"
      >
        <span className="mr-1 text-base text-app-gray600">{docsTitle}</span>
        <OpenInNewOutlined className="text-app-gray600" />
      </Link>
    </div>
  </>
);

export default Onboarding;
