import styled, { css } from "styled-components";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link as L } from "components/Link/Link";

export const AppsContainer = styled.div`
  ${({ theme: { colors } }) => css`
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
    border: 1px solid ${colors.grey100};
    background: ${colors.white};
    width: 200px;
  `}
`;

export const SidebarSelector = styled.div`
  ${({ theme: { colors, variables } }) => css`
    background-color: ${colors.white};
    padding: 15px;
    border-radius: ${variables.mainBorderRadius};
    box-shadow: ${variables.heavyBoxShadow};
    min-width: 200px;
    display: flex;
    flex-direction: column;
  `}
`;

export const List = styled.ul`
  list-style: none;
  & > a :not([hidden]) ~ :not([hidden]) {
    /* margin-top: 8px; */
  }
`;

export const Link = styled(L)`
  display: block;
`;

export const Item = styled.li<{ $active: boolean }>`
  ${({ $active, theme: { colors } }) => css`
    padding: 12px;
    margin: 4px;
    border-radius: 4px;
    color: ${$active ? colors.white : colors.black};
    background-color: ${$active ? colors.blue400 : colors.white};
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      background-color: ${$active ? colors.blue400 : colors.grey050};
    }
  `}
`;

export const Empty = styled.div`
  ${({ theme: { colors, variables } }) => css`
    width: 100%;
    color: ${colors.grey4};
    font-size: ${variables.fontSize4};
    padding: 30px;
    box-sizing: border-box;
    text-align: center;
    background-color: ${colors.grey2};
    border-radius: ${variables.mainBorderRadius};
  `}
`;

export const Chevron = styled(ChevronRightIcon)`
  ${({ theme: { colors } }) => css`
    fill: ${colors.black};
    margin-left: auto;
    height: 16px;
    width: 16px;
    transform: rotate(90deg);
  `}
`;
