import React from "react";
import { AttributeInstallationBox, Link } from "components";
import { ConnectAuthProviders } from "./ConnectAuthProviders";
import { AuthPageContextProvider } from "./context";

const AuthProviders = () => {
  return (
    <>
      <h3 className="text-h3 font-bold mb-2">Connect Auth Providers</h3>
      <p className="text-body-sm text-app-gray600 whitespace-pre-line mb-4">
        Configure auth providers to add them to your sign up and login forms.
        <br />
        You can{" "}
        <Link to="application/#dns-settings" underline>
          add a custom domain
        </Link>{" "}
        to use your own your domain for social auth (e.g. continue to
        mydomain.com).
      </p>
      <ConnectAuthProviders />
      <div className="mt-4" />
      <AttributeInstallationBox
        isLinkOnly
        docsLink="https://docs.memberstack.com/hc/en-us/sections/9188390078491-Social-Authentication-SSO"
      />
    </>
  );
};

const AuthProvidersPage = () => (
  <AuthPageContextProvider>
    <AuthProviders />
  </AuthPageContextProvider>
);

export default AuthProvidersPage;
