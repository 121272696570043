import React, { useMemo, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import Text from "components/Text";
import { Button, EmptyState } from "components";
import { SectionCard } from "components/Card/Card";
import { useEnvContext } from "containers/App/contexts/Environment";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Add } from "@mui/icons-material";
import { SsoApp } from "generatedTypes";
import OnboardingModal from "components/Modal/OnboardingModal";
import RegisterNewAppModal from "./modals/RegisterNewAppModal";
import EditSSOAppModal from "./modals/EditSSOAppModal";
import { useDevtoolsContext } from "./context";

const defaultAppValues = {
  id: "",
  name: "",
  clientId: "",
  clientSecret: "",
  redirectUris: [""],
};

const SSOApplication = () => {
  const { isSandboxEnv } = useEnvContext();
  const { ssoApps } = useDevtoolsContext();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState<SsoApp>(defaultAppValues);

  const renderApplication = useMemo(() => {
    if (!ssoApps.length) {
      return (
        <EmptyState
          text="No Custom SSO Integrations added yet."
          description="Add Custom SSO Integration"
          buttonText="Add Integration"
          onDemoClick={() => setShowOnboardingModal(true)}
          onCreateClick={() => setShowCreateModal(true)}
        />
      );
    }

    return (
      <ul tw="list-none p-0 m-0">
        {ssoApps?.map((ssoApp, idx) => {
          const { name, id } = ssoApp;
          return (
            <div
              className={mergeClassNames(
                "flex justify-between items-center",
                idx < ssoApps.length - 1 && "mb-4"
              )}
              key={id}
            >
              <Text className="select-none">{name}</Text>
              <Button
                text="Edit"
                buttonStyle="skeleton"
                rightIcon={<CreateIcon />}
                onClick={() => {
                  setShowEditModal(true);
                  setSelectedApp(ssoApp);
                }}
              />
            </div>
          );
        })}
      </ul>
    );
  }, [ssoApps]);

  return (
    <>
      <SectionCard
        tooltipText="These integrations use OpenID Connect Apps"
        title={
          isSandboxEnv
            ? "Test Mode Custom SSO Integrations"
            : "Custom SSO Integrations"
        }
        buttonValues={{
          text: "Add Integration",
          onClick: () => setShowCreateModal(true),
          leftIcon: <Add />,
        }}
        maxHeight="38.4%"
      >
        {renderApplication}
      </SectionCard>
      <RegisterNewAppModal
        setShowModal={setShowCreateModal}
        showModal={showCreateModal}
      />
      <EditSSOAppModal
        setShowModal={setShowEditModal}
        showModal={showEditModal}
        ssoApp={selectedApp}
      />
      <OnboardingModal
        setShowModal={setShowOnboardingModal}
        showModal={showOnboardingModal}
        title="Add Custom SSO Integrations"
        docsLink="https://docs.memberstack.com/hc/en-us/articles/8161104982043-Custom-SSO-Integrations"
        videoSrc="https://www.loom.com/embed/cac84c65a215418fa1c4621bcd3ed909"
      />
    </>
  );
};

export default SSOApplication;
