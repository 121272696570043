import React, { useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { getId } from "helpers/cacheIds";
import { RouteName } from "enums/routes";
import { useUsersContext } from "containers/App/UsersContextProvider";

export const CreateRedirect = ({ url }) => {
  const id = getId("appId");
  if (!id) {
    return <Redirect to={`/${RouteName.apps}`} />;
  }
  return <Redirect to={url(id)} />;
};

const {
  members,
  plan,
  settings,
  emails,
  passwordResetEmail,
  emailVerification,
  redirects,
  permissions,
  devtools,
  gatedContent,
  billing,
  apps,
  installCode,
  gettingStarted,
  translations,
  connectWebflow,
  eventLog,
  dashboard,
} = RouteName;

export const shareableLinks = [
  {
    path: "/getting-started",
    url: (id) => `/${apps}/${id}/${gettingStarted}`,
  },
  {
    path: "/dashboard",
    url: (id) => `/${apps}/${id}/${dashboard}`,
  },
  {
    path: "/members",
    url: (id) => `/${apps}/${id}/${members}`,
  },
  {
    path: "/plans",
    url: (id) => `/${apps}/${id}/plans`,
  },
  {
    path: "/settings/emails/password-reset-email",
    url: (id) => `/${apps}/${id}/${settings}/${emails}/${passwordResetEmail}`,
  },
  {
    path: "/settings/emails/email-verification",
    url: (id) => `/${apps}/${id}/${settings}/${emails}/${emailVerification}`,
  },
  {
    path: "/plan/redirects",
    url: (id) => `/${apps}/${id}/${plan}/${redirects}`,
  },
  {
    path: "/devtools/permissions",
    url: (id) => `/${apps}/${id}/${devtools}/${permissions}`,
  },
  {
    path: "/gated-content",
    url: (id) => `/${apps}/${id}/${gatedContent}`,
  },
  {
    path: "/devtools",
    url: (id) => `/${apps}/${id}/${devtools}`,
  },
  {
    path: "/community",
    url: (id) => `/${apps}/${id}/community/comments`,
  },
  {
    path: "/community/comments",
    url: (id) => `/${apps}/${id}/community/comments`,
  },
  {
    path: "/event-log",
    url: (id) => `/${apps}/${id}/${eventLog}`,
  },
  {
    path: "/settings",
    url: (id) => `/${apps}/${id}/${settings}`,
  },
  {
    path: "/settings/application",
    url: (id) => `/${apps}/${id}/${settings}/application`,
  },
  {
    path: "/settings/install-code",
    url: (id) => `/${apps}/${id}/${settings}/${installCode}/webflow`,
  },
  {
    path: "/billing",
    url: (id) => `/${apps}/${id}/${settings}/${billing}`,
  },
  {
    path: "/billing/commenting-pricing",
    url: (id) => `/${apps}/${id}/${settings}/${billing}/commenting-pricing`,
  },
  {
    path: "/settings/billing",
    url: (id) => `/${apps}/${id}/${settings}/${billing}`,
  },
  {
    path: "/settings/translations",
    url: (id) => `/${apps}/${id}/${settings}/${translations}`,
  },
  {
    path: "/settings/install-code/webflow",
    url: (id) => `/${apps}/${id}/${settings}/${installCode}/webflow`,
  },
  {
    path: "/settings/connect-webflow",
    url: (id) => `/${apps}/${id}/${settings}/${connectWebflow}`,
  },
  {
    path: "/settings/install-code/react",
    url: (id) => `/${apps}/${id}/${settings}/${installCode}/react`,
  },
  {
    path: "/settings/install-code/vanilla",
    url: (id) => `/${apps}/${id}/${settings}/${installCode}/vanilla`,
  },
  {
    path: "/settings/team",
    url: (id) => `/${apps}/${id}/${settings}/team`,
  },
  {
    path: "/settings/notifications",
    url: (id) => `/${apps}/${id}/${settings}/notifications`,
  },
  {
    path: "/settings/emails",
    url: (id) => `/${apps}/${id}/${settings}/emails`,
  },
  {
    path: "/settings/payment-settings",
    url: (id) => `/${apps}/${id}/${settings}/payment-settings`,
  },
  {
    path: "/settings/danger-zone",
    url: (id) => `/${apps}/${id}/${settings}/danger-zone`,
  },
  {
    path: "/settings/design",
    url: (id) => `/${apps}/${id}/${settings}/design`,
  },
  {
    path: "/settings/stripe-settings",
    url: (id) => `/${apps}/${id}/${settings}/stripe-settings`,
  },
];

export const useSharableRoutes = () => {
  const history = useHistory();
  const location = useLocation();
  const { currentUser } = useUsersContext();

  useEffect(() => {
    if (currentUser?.id) {
      const shareableLink = shareableLinks.find(
        (link) => link.path === location.pathname
      );
      if (shareableLink) {
        let appId = getId("appId");
        if (!appId) {
          appId = currentUser?.appConnections?.[0]?.app?.id;
        }

        if (!appId) {
          history.push(`/${RouteName.apps}`);
        } else {
          history.push(shareableLink.url(appId));
        }
      }
    }
  }, [
    location.pathname,
    history,
    currentUser?.id,
    currentUser?.appConnections,
  ]);

  return shareableLinks;
};
