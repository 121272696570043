import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "styles";
import { IntlProvider } from "react-intl";
import { MainRoutes } from "routes";
import { getApolloContext } from "@apollo/client";
import { getClient } from "apolloClient";

import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";
import { useEnvContext } from "containers/Environment";
import { ToastAlertContextProvider } from "components/toastAlert";
import ErrorPage from "containers/ErrorPage";
import { translations } from "../../translations";

const App = () => {
  const [client, setClient] = useState(null);
  const { env } = useEnvContext();
  const ApolloContext = getApolloContext();
  const location = useLocation();

  useEffect(() => {
    logInDevelopmentEnvironment(
      `📣 env changed to ${env} mode - getting client`
    );
    getClient(env)
      .then(setClient)
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
  }, [env]);

  useEffect(() => {

    // changes URL from /apps/app_123/members to members, etc.
    let page = location.pathname.split("/").pop() as string
    if (!page || page === "apps") return;

    if (page.includes("pln_")) page = "plans";
    if (page.includes("prc_")) page = "prices";

    if (location.pathname.includes("community")) page = "community";

    try {
      window.revgems("trackPageView", { 
        occurredAt: new Date, 
        title: document.title, 
        referrer: document.referrer, 
        url: `${window.location.origin}/${page}`
      })
    } catch (err) {
      console.log(err)
    }
  }, [location]);

  const locale = navigator.language.split(/[-_]/)[0];
  const messages = translations[locale];

  if (!client)
    return (
      <ThemeProvider theme={theme}>
        <ErrorPage isFullScreen />
      </ThemeProvider>
    );

  return (
    <ApolloContext.Provider value={{ client }}>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={messages} defaultLocale="en">
          <ToastAlertContextProvider>
            <MainRoutes />
          </ToastAlertContextProvider>
        </IntlProvider>
      </ThemeProvider>
    </ApolloContext.Provider>
  );
};

export default App;
