import React from "react";
import styled, { css } from "styled-components";

type BannerType = "info" | "warning" | "error";
interface Props {
  text: React.ReactNode;
  description?: string;
  rightPlugin?: React.ReactNode;
  className?: string;
  leftPlugin?: React.ReactNode;
  type?: BannerType;
  children?: React.ReactNode;
}

const BannerContainer = styled.div<{
  $hasLeftPlugin: Boolean;
  $type: BannerType;
}>(
  ({ theme: { colors }, $hasLeftPlugin, $type }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 8px;
    background: ${$type === "info" ? colors.purple050 : colors.warningTagBg};
    & .text-container {
      font-size: 14px;
      line-height: 24px;
      margin-left: ${$hasLeftPlugin ? "12px" : "0px"};
    }
    & .description {
      font-size: 12px;
      line-height: 20px;
    }
    & .title {
      font-weight: 700;
    }
    & > div > svg {
      width: 24px;
      height: 24px;
    }
    & > * {
      color: ${$type === "info" ? colors.purple900 : colors.warningTagText};
      font-size: 14px;
      line-height: 24px;
    }
    ${$type === "info" &&
    css`
      background: ${colors.warningTagBg};
      & > * {
        color: ${colors.warningTagText};
      }
    `}
    ${$type === "error" &&
    css`
      background: ${colors.red050};
      & > * {
        color: ${colors.red900};
      }
    `}
  `
);

const Banner = ({
  text,
  rightPlugin,
  className,
  leftPlugin,
  description,
  type = "info",
  children,
}: Props) => {
  const hasLeftPlugin = Boolean(leftPlugin);
  return (
    <BannerContainer
      className={className}
      $hasLeftPlugin={hasLeftPlugin}
      $type={type}
      data-cy="test-mode-banner"
    >
      <div className="flex items-start">
        {leftPlugin && leftPlugin}
        <div className="text-container">
          <p className="title">{text}</p>
          {description && <p className="description">{description}</p>}
          {children && <div className="description">{children}</div>}
        </div>
      </div>
      {rightPlugin && rightPlugin}
    </BannerContainer>
  );
};

export default Banner;
