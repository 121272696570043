import React, { useMemo, useState } from "react";
import { Link as LinkIcon } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import useSearch from "hooks/useSearch";
import {
  REDIRECT_MASK,
  REDIRECT_MASK_MESSAGE,
  REDIRECT_MAP,
  FREE_PLANS_REDIRECT_MAP,
} from "constants/redirects";
import { InfoTooltip } from "components/Tooltip";
import EditRedirectModal from "containers/Plans/modals/EditRedirectModal";
import { Link } from "components";
import { useGetAppRedirectsQuery } from "features";
import Skeleton from "react-loading-skeleton";
import { Plan } from "generatedTypes";
import CreateRedirectModal from "containers/Plans/modals/CreateRedirectModal";
import { PlanAccordionItem } from "./PlanAccordionItem";

interface Props {
  plan: Plan;
  refetch: () => void;
}

const PlanRedirects = ({ plan, refetch }: Props) => {
  const { redirects, isPaid } = plan;

  const [workingRedirect, setWorkingRedirect] = useState(null);
  const [showCreateRedirect, setShowCreateRedirect] = useState<boolean>(false);
  const [showEditRedirectModal, setShowEditRedirectModal] =
    useState<boolean>(false);

  const { loading, data, error } = useGetAppRedirectsQuery();

  const availableRedirects = useMemo(
    () => (isPaid ? REDIRECT_MAP : FREE_PLANS_REDIRECT_MAP),
    [isPaid]
  );

  const appRedirects = useMemo(() => data?.getRedirects, [data]);

  const handleRedirectClick = (redirect) => {
    setWorkingRedirect(redirect);
    setShowEditRedirectModal(true);
  };

  const handleKeyDown = (event) => (redirect) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      event.stopPropagation();
      handleRedirectClick(redirect);
    }
  };

  const redirectsList = useMemo(() => {
    const isMask = (key, type) => type === "app" && REDIRECT_MASK.includes(key);
    if (!redirects || !appRedirects) {
      return [];
    }
    const redirectsDict = {};
    const iterate = (target, type) =>
      Object.keys(target).forEach((key) => {
        // Essentially allows any of the masked redirects to skip filter
        const value = isMask(key, type) ? REDIRECT_MASK_MESSAGE : target[key];
        if (!value.length || value === "/" || key.includes("__")) {
          return;
        }
        redirectsDict[key] = {
          ...(redirectsDict[key] || {}),
          [type]: value,
        };
      });
    iterate(redirects, "plan");
    iterate(appRedirects, "app");
    return Object.keys(redirectsDict).map((key) => ({
      key,
      value: redirectsDict[key],
    }));
  }, [appRedirects, redirects]);

  const {
    // searchString,
    // setSearchString,
    output: filteredRedirects = [],
  } = useSearch({
    input: redirectsList,
    map: (r) => r?.key,
  });

  if (loading && !appRedirects)
    return (
      <section data-testid="plansRedirect-skeleton">
        <Skeleton width={494} height={24} className="mb-4" />
        <div className="flex flex-col gap-2">
          <Skeleton height={54} />
          <Skeleton height={54} />
          <Skeleton height={54} />
        </div>
      </section>
    );

  const planActiveRedirects = Object.keys(plan?.redirects || {}).filter(
    (key) =>
      !key.includes("__") &&
      plan?.redirects[key] !== "/" &&
      plan?.redirects[key] !== ""
  );

  return (
    <PlanAccordionItem
      label="Redirects"
      value="redirects"
      total={planActiveRedirects.length}
      onAdd={() => setShowCreateRedirect(true)}
    >
      <section>
        <p className="text-body-sm text-app-gray600 mb-4">
          Override &nbsp;
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/7384608370715"
            target="_blank"
            isExternal
            underline
          >
            default redirects
          </Link>
          &nbsp; for members with this plan. &nbsp;
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/7384608370715#plans"
            target="_blank"
            isExternal
            underline
          >
            Help Docs →
          </Link>
        </p>
        <div className="flex flex-col gap-2">
          {filteredRedirects.length > 0 ? (
            filteredRedirects.map(({ key, value }) => (
              <div
                key={key}
                onKeyDown={(e) => handleKeyDown(e)({ key, value: value.plan })}
                role="button"
                tabIndex={0}
                className="cursor-pointer p-3 hover:bg-app-gray50 shadow-sm rounded-lg w-full border border-app-gray300"
                onClick={() => handleRedirectClick({ key, value: value.plan })}
              >
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <p className="text-base">{availableRedirects[key]}</p>
                    <InfoTooltip
                      content="Redirect upon event"
                      size="md"
                      className="ml-1"
                    />
                  </div>
                  {value.plan && (
                    <CreateIcon
                      fill="black"
                      className="hoverState"
                      tw="!h-4 !w-4"
                    />
                  )}
                </div>
                {value.plan && (
                  <div className="flex items-center text-app-gray500 gap-2">
                    <LinkIcon tw="h-4! w-4!" />
                    <p className="text-base">{value.plan}</p>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-base">No redirects found</p>
          )}
        </div>
        <EditRedirectModal
          showModal={showEditRedirectModal}
          setShowModal={setShowEditRedirectModal}
          refetch={refetch}
          planId={plan.id}
          redirect={workingRedirect}
        />
        <CreateRedirectModal
          showModal={showCreateRedirect}
          setShowModal={setShowCreateRedirect}
          refetch={refetch}
          planId={plan.id}
        />
      </section>
    </PlanAccordionItem>
  );
};

export default PlanRedirects;
