import React, { useMemo, useState, useCallback } from "react";
import EditPermissionModal from "features/permissions/components/EditPermissionModal";
import PermissionCard from "components/PermissionCard";
import {
  useLinkPermissionsToPlanMutation,
  useDetachPermissionFromPlanMutation,
  useGetPermissionsQuery,
} from "features/permissions";
import useSubmit from "hooks/useSubmit";
import { EmptyState } from "components";
import SearchBar from "components/SearchBar";
import useSearch from "hooks/useSearch";
import Skeleton from "react-loading-skeleton";
import { Plan } from "generatedTypes";
import CreatePermissionModal from "features/permissions/components/CreatePermissionModal";
import { PlanAccordionItem } from "./PlanAccordionItem";

interface Props {
  plan: Plan;
}

export const PlanPermissions = ({ plan }: Props) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [permission, setPermission] = useState<string>(null);

  const [link] = useLinkPermissionsToPlanMutation();
  const [detach] = useDetachPermissionFromPlanMutation();

  const { loading, data, refetch, error } = useGetPermissionsQuery();

  const permissions = useMemo(() => data?.getPermissions, [data]);

  const handleEditPermission = (id: string) => () => {
    setShowEditModal(true);
    setPermission(id);
  };

  const {
    searchString,
    setSearchString,
    output: filteredPermissions,
  } = useSearch({
    input: permissions,
    map: (p) => p?.name,
  });

  const { submit: submitLink, isSubmitting: linking } = useSubmit({
    action: link,
    refetch,
    error: "Unable to link permission to plan",
    success: "Successfully linked permission to plan",
  });

  const { submit: submitDetach, isSubmitting: detaching } = useSubmit({
    action: detach,
    refetch,
    error: "Unable to detach permission from plan",
    success: "Successfully detached permission to plan",
  });

  const handleCheck = useCallback(
    (id) => (checked) => {
      if (checked) {
        submitLink({ planId: plan.id, permissionIds: [id] });
      } else {
        submitDetach({ planId: plan.id, permissionId: id });
      }
    },
    [plan.id, submitDetach, submitLink]
  );

  if (loading && !permissions)
    return (
      <section data-testid="plansPermission-skeleton">
        <div className="flex flex-col gap-2">
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </div>
      </section>
    );

  return (
    <PlanAccordionItem
      label="Permissions"
      value="permissions"
      total={plan.permissions.length}
      onAdd={() => setShowCreateModal(true)}
      buttonLoading={linking || detaching}
    >
      <section>
        <div className="flex flex-col gap-2">
          {permissions.length > 5 && (
            <SearchBar
              onChange={(value) => setSearchString(value)}
              tw="mb-2 p-1"
              value={searchString}
            />
          )}
          {filteredPermissions.length > 0 ? (
            filteredPermissions.map((_permission, idx) => (
              <div key={_permission.id} aria-hidden>
                <PermissionCard
                  isEditable
                  className="mt-2"
                  permission={_permission}
                  checked={
                    !!plan?.permissions?.find((p) => p.id === _permission.id)
                  }
                  onTogglePermission={handleCheck(_permission.id)}
                  onClick={handleEditPermission(_permission.id)}
                />
              </div>
            ))
          ) : (
            <p className="text-base">No permissions found</p>
          )}
          {permissions.length === 0 && (
            <EmptyState
              text="Add a permission to get started"
              description="You can use Permissions to Gate Content in React applications or to secure Backend Requests. You probably don't need this feature if you are using Webflow."
              buttonText="Create Permission"
              onCreateClick={() => setShowCreateModal(true)}
              docsLink="https://docs.memberstack.com/hc/en-us/articles/7253255689755-Using-Permissions-and-Token-Verification-to-Secure-Your-Site"
            />
          )}
        </div>
        <EditPermissionModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          permissionId={permission}
        />
        <CreatePermissionModal
          showModal={showCreateModal}
          setShowModal={setShowCreateModal}
        />
      </section>
    </PlanAccordionItem>
  );
};
