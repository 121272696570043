import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetPlanRedirectsQueryVariables = Types.Exact<{
  planId: Types.Scalars['ID'];
}>;


export type GetPlanRedirectsQuery = (
  { __typename?: 'Query' }
  & { getPlan?: Types.Maybe<(
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id' | 'name'>
    & { redirects?: Types.Maybe<(
      { __typename?: 'Redirects' }
      & Pick<Types.Redirects, 'afterSignup' | 'afterLogin' | 'afterLogout' | 'afterPurchase' | 'afterCancel' | 'afterReplace' | 'verificationRequired'>
    )> }
  )> }
);

export type GetAppRedirectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAppRedirectsQuery = (
  { __typename?: 'Query' }
  & { getRedirects?: Types.Maybe<(
    { __typename?: 'Redirects' }
    & Pick<Types.Redirects, 'afterSignup' | 'afterLogin' | 'afterLogout' | 'afterPurchase' | 'afterCancel' | 'afterReplace' | 'verificationRequired'>
  )> }
);

export type UpdatePlanRedirectsMutationVariables = Types.Exact<{
  input: Types.UpdatePlanInput;
}>;


export type UpdatePlanRedirectsMutation = (
  { __typename?: 'Mutation' }
  & { updatePlan: (
    { __typename?: 'Plan' }
    & Pick<Types.Plan, 'id'>
    & { redirects?: Types.Maybe<(
      { __typename?: 'Redirects' }
      & Pick<Types.Redirects, 'afterSignup' | 'afterLogin' | 'afterLogout' | 'afterPurchase' | 'afterCancel' | 'afterReplace'>
    )> }
  ) }
);

export type UpdateDefaultRedirectsMutationVariables = Types.Exact<{
  input: Types.RedirectsInput;
}>;


export type UpdateDefaultRedirectsMutation = (
  { __typename?: 'Mutation' }
  & { updateDefaultRedirects: (
    { __typename?: 'Redirects' }
    & Pick<Types.Redirects, 'afterSignup' | 'afterLogin' | 'afterLogout' | 'afterPurchase' | 'afterCancel' | 'afterReplace' | 'verificationRequired'>
  ) }
);


export const GetPlanRedirectsDocument = gql`
    query GetPlanRedirects($planId: ID!) {
  getPlan(id: $planId) {
    id
    name
    redirects {
      afterSignup
      afterLogin
      afterLogout
      afterPurchase
      afterCancel
      afterReplace
      verificationRequired
    }
  }
}
    `;

/**
 * __useGetPlanRedirectsQuery__
 *
 * To run a query within a React component, call `useGetPlanRedirectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanRedirectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanRedirectsQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetPlanRedirectsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanRedirectsQuery, GetPlanRedirectsQueryVariables>) {
        return Apollo.useQuery<GetPlanRedirectsQuery, GetPlanRedirectsQueryVariables>(GetPlanRedirectsDocument, baseOptions);
      }
export function useGetPlanRedirectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanRedirectsQuery, GetPlanRedirectsQueryVariables>) {
          return Apollo.useLazyQuery<GetPlanRedirectsQuery, GetPlanRedirectsQueryVariables>(GetPlanRedirectsDocument, baseOptions);
        }
export type GetPlanRedirectsQueryHookResult = ReturnType<typeof useGetPlanRedirectsQuery>;
export type GetPlanRedirectsLazyQueryHookResult = ReturnType<typeof useGetPlanRedirectsLazyQuery>;
export type GetPlanRedirectsQueryResult = Apollo.QueryResult<GetPlanRedirectsQuery, GetPlanRedirectsQueryVariables>;
export const GetAppRedirectsDocument = gql`
    query GetAppRedirects {
  getRedirects {
    afterSignup
    afterLogin
    afterLogout
    afterPurchase
    afterCancel
    afterReplace
    verificationRequired
  }
}
    `;

/**
 * __useGetAppRedirectsQuery__
 *
 * To run a query within a React component, call `useGetAppRedirectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppRedirectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppRedirectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppRedirectsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppRedirectsQuery, GetAppRedirectsQueryVariables>) {
        return Apollo.useQuery<GetAppRedirectsQuery, GetAppRedirectsQueryVariables>(GetAppRedirectsDocument, baseOptions);
      }
export function useGetAppRedirectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppRedirectsQuery, GetAppRedirectsQueryVariables>) {
          return Apollo.useLazyQuery<GetAppRedirectsQuery, GetAppRedirectsQueryVariables>(GetAppRedirectsDocument, baseOptions);
        }
export type GetAppRedirectsQueryHookResult = ReturnType<typeof useGetAppRedirectsQuery>;
export type GetAppRedirectsLazyQueryHookResult = ReturnType<typeof useGetAppRedirectsLazyQuery>;
export type GetAppRedirectsQueryResult = Apollo.QueryResult<GetAppRedirectsQuery, GetAppRedirectsQueryVariables>;
export const UpdatePlanRedirectsDocument = gql`
    mutation UpdatePlanRedirects($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    id
    redirects {
      afterSignup
      afterLogin
      afterLogout
      afterPurchase
      afterCancel
      afterReplace
    }
  }
}
    `;
export type UpdatePlanRedirectsMutationFn = Apollo.MutationFunction<UpdatePlanRedirectsMutation, UpdatePlanRedirectsMutationVariables>;

/**
 * __useUpdatePlanRedirectsMutation__
 *
 * To run a mutation, you first call `useUpdatePlanRedirectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanRedirectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanRedirectsMutation, { data, loading, error }] = useUpdatePlanRedirectsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanRedirectsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanRedirectsMutation, UpdatePlanRedirectsMutationVariables>) {
        return Apollo.useMutation<UpdatePlanRedirectsMutation, UpdatePlanRedirectsMutationVariables>(UpdatePlanRedirectsDocument, baseOptions);
      }
export type UpdatePlanRedirectsMutationHookResult = ReturnType<typeof useUpdatePlanRedirectsMutation>;
export type UpdatePlanRedirectsMutationResult = Apollo.MutationResult<UpdatePlanRedirectsMutation>;
export type UpdatePlanRedirectsMutationOptions = Apollo.BaseMutationOptions<UpdatePlanRedirectsMutation, UpdatePlanRedirectsMutationVariables>;
export const UpdateDefaultRedirectsDocument = gql`
    mutation UpdateDefaultRedirects($input: RedirectsInput!) {
  updateDefaultRedirects(input: $input) {
    afterSignup
    afterLogin
    afterLogout
    afterPurchase
    afterCancel
    afterReplace
    verificationRequired
  }
}
    `;
export type UpdateDefaultRedirectsMutationFn = Apollo.MutationFunction<UpdateDefaultRedirectsMutation, UpdateDefaultRedirectsMutationVariables>;

/**
 * __useUpdateDefaultRedirectsMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultRedirectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultRedirectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultRedirectsMutation, { data, loading, error }] = useUpdateDefaultRedirectsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultRedirectsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultRedirectsMutation, UpdateDefaultRedirectsMutationVariables>) {
        return Apollo.useMutation<UpdateDefaultRedirectsMutation, UpdateDefaultRedirectsMutationVariables>(UpdateDefaultRedirectsDocument, baseOptions);
      }
export type UpdateDefaultRedirectsMutationHookResult = ReturnType<typeof useUpdateDefaultRedirectsMutation>;
export type UpdateDefaultRedirectsMutationResult = Apollo.MutationResult<UpdateDefaultRedirectsMutation>;
export type UpdateDefaultRedirectsMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultRedirectsMutation, UpdateDefaultRedirectsMutationVariables>;