import { gql } from "@apollo/client";

export const GET_PLAN_REDIRECTS = gql`
  query GetPlanRedirects($planId: ID!) {
    getPlan(id: $planId) {
      id
      name
      redirects {
        afterSignup
        afterLogin
        afterLogout
        afterPurchase
        afterCancel
        afterReplace
        verificationRequired
      }
    }
  }
`;

export const GET_APP_REDIRECTS = gql`
  query GetAppRedirects {
    getRedirects {
      afterSignup
      afterLogin
      afterLogout
      afterPurchase
      afterCancel
      afterReplace
      verificationRequired
    }
  }
`;

export const UPDATE_PLAN_REDIRECTS = gql`
  mutation UpdatePlanRedirects($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      id
      redirects {
        afterSignup
        afterLogin
        afterLogout
        afterPurchase
        afterCancel
        afterReplace
      }
    }
  }
`;

export const UPDATE_DEFAULT_REDIRECTS = gql`
  mutation UpdateDefaultRedirects($input: RedirectsInput!) {
    updateDefaultRedirects(input: $input) {
      afterSignup
      afterLogin
      afterLogout
      afterPurchase
      afterCancel
      afterReplace
      verificationRequired
    }
  }
`;
