import React from "react";
import { RadioButton, RadioButtonProps } from "./RadioButton";
import { GroupContainer } from "./styles";

type RadioOptions = Pick<RadioButtonProps, "label" | "value" | "description">;
interface RadioButtonGroupProps {
  label: string;
  name: string;
  options: RadioOptions[];
  disabled?: boolean;
  onChange: (value: string) => void;
  selectedValue?: string;
  gap?: number;
  labelClassName?: string;
}

export const RadioButtonGroup = ({
  name,
  label,
  options,
  disabled,
  onChange,
  selectedValue,
  gap,
  labelClassName,
}: RadioButtonGroupProps) => {
  return (
    <fieldset>
      <legend className="sr-only">{label}</legend>
      <GroupContainer role="radiogroup" gap={gap}>
        {options?.map((option) => (
          <RadioButton
            onChange={(e) => onChange(e.target.value)}
            label={option.label}
            description={option.description}
            value={option.value}
            disabled={disabled}
            key={option.value}
            name={name}
            selectedValue={selectedValue}
            labelClassName={labelClassName}
          />
        ))}
      </GroupContainer>
    </fieldset>
  );
};

RadioButtonGroup.defaultProps = {
  disabled: false,
  selectedValue: undefined,
};

export default RadioButtonGroup;
