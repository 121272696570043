import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateRestrictedUrlMutationVariables = Types.Exact<{
  input: Types.CreateRestrictedUrlInput;
}>;


export type CreateRestrictedUrlMutation = (
  { __typename?: 'Mutation' }
  & { createRestrictedUrl: (
    { __typename?: 'RestrictedUrl' }
    & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
  ) }
);

export type DeleteRestrictedUrlMutationVariables = Types.Exact<{
  input: Types.DeleteRestrictedUrlInput;
}>;


export type DeleteRestrictedUrlMutation = (
  { __typename?: 'Mutation' }
  & { deleteRestrictedUrl: (
    { __typename?: 'RestrictedUrl' }
    & Pick<Types.RestrictedUrl, 'id'>
  ) }
);

export type CreateRestrictedUrlGroupMutationVariables = Types.Exact<{
  input: Types.CreateRestrictedUrlGroupInput;
}>;


export type CreateRestrictedUrlGroupMutation = (
  { __typename?: 'Mutation' }
  & { createRestrictedUrlGroup: (
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id' | 'name' | 'redirect' | 'allowAllMembers'>
    & { urls: Array<Types.Maybe<(
      { __typename?: 'RestrictedUrl' }
      & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
    )>>, plans: Array<Types.Maybe<(
      { __typename?: 'Plan' }
      & Pick<Types.Plan, 'id'>
    )>> }
  ) }
);

export type UpdateRestrictedUrlGroupMutationVariables = Types.Exact<{
  input: Types.UpdateRestrictedUrlGroupInput;
}>;


export type UpdateRestrictedUrlGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateRestrictedUrlGroup: (
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id' | 'name' | 'redirect' | 'allowAllMembers'>
    & { urls: Array<Types.Maybe<(
      { __typename?: 'RestrictedUrl' }
      & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
    )>>, plans: Array<Types.Maybe<(
      { __typename?: 'Plan' }
      & Pick<Types.Plan, 'id'>
    )>> }
  ) }
);

export type UpdateRestrictedUrlMutationVariables = Types.Exact<{
  input: Types.UpdateRestrictedUrlInput;
}>;


export type UpdateRestrictedUrlMutation = (
  { __typename?: 'Mutation' }
  & { updateRestrictedUrl: (
    { __typename?: 'RestrictedUrl' }
    & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
  ) }
);

export type DeleteRestrictedUrlGroupMutationVariables = Types.Exact<{
  input: Types.DeleteRestrictedUrlGroupInput;
}>;


export type DeleteRestrictedUrlGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteRestrictedUrlGroup: (
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id'>
  ) }
);

export type LinkRestrictedUrlsToRestrictedUrlGroupMutationVariables = Types.Exact<{
  input: Types.LinkRestrictedUrlsToRestrictedUrlGroupInput;
}>;


export type LinkRestrictedUrlsToRestrictedUrlGroupMutation = (
  { __typename?: 'Mutation' }
  & { linkRestrictedUrlsToRestrictedUrlGroup: (
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id' | 'name' | 'redirect'>
    & { urls: Array<Types.Maybe<(
      { __typename?: 'RestrictedUrl' }
      & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
    )>>, plans: Array<Types.Maybe<(
      { __typename?: 'Plan' }
      & Pick<Types.Plan, 'id'>
    )>> }
  ) }
);

export type DetachRestrictedUrlsToRestrictedUrlGroupMutationVariables = Types.Exact<{
  input: Types.DetachRestrictedUrlsFromRestrictedUrlGroupInput;
}>;


export type DetachRestrictedUrlsToRestrictedUrlGroupMutation = (
  { __typename?: 'Mutation' }
  & { detachRestrictedUrlsFromRestrictedUrlGroup: (
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id' | 'name' | 'redirect'>
    & { urls: Array<Types.Maybe<(
      { __typename?: 'RestrictedUrl' }
      & Pick<Types.RestrictedUrl, 'id' | 'url' | 'filter'>
    )>>, plans: Array<Types.Maybe<(
      { __typename?: 'Plan' }
      & Pick<Types.Plan, 'id'>
    )>> }
  ) }
);

export type LinkPlansToRestrictedUrlGroupMutationVariables = Types.Exact<{
  input: Types.LinkPlansToRestrictedUrlGroupInput;
}>;


export type LinkPlansToRestrictedUrlGroupMutation = (
  { __typename?: 'Mutation' }
  & { linkPlansToRestrictedUrlGroup: (
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id'>
  ) }
);

export type DetachPlansFromRestrictedUrlGroupMutationVariables = Types.Exact<{
  input: Types.DetachPlansFromRestrictedUrlGroupInput;
}>;


export type DetachPlansFromRestrictedUrlGroupMutation = (
  { __typename?: 'Mutation' }
  & { detachPlansFromRestrictedUrlGroup: (
    { __typename?: 'RestrictedUrlGroup' }
    & Pick<Types.RestrictedUrlGroup, 'id'>
  ) }
);

export type CreateCustomContentMutationVariables = Types.Exact<{
  input: Types.CreateCustomContentExistingGroupInput;
}>;


export type CreateCustomContentMutation = (
  { __typename?: 'Mutation' }
  & { createCustomContent: (
    { __typename?: 'CustomContent' }
    & Pick<Types.CustomContent, 'id' | 'name' | 'content' | 'type'>
  ) }
);

export type UpdateCustomContentMutationVariables = Types.Exact<{
  input: Types.UpdateCustomContentInput;
}>;


export type UpdateCustomContentMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomContent: (
    { __typename?: 'CustomContent' }
    & Pick<Types.CustomContent, 'id' | 'name' | 'content' | 'type'>
  ) }
);

export type DeleteCustomContentMutationVariables = Types.Exact<{
  input: Types.DeleteCustomContentInput;
}>;


export type DeleteCustomContentMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomContent: (
    { __typename?: 'CustomContent' }
    & Pick<Types.CustomContent, 'id'>
  ) }
);


export const CreateRestrictedUrlDocument = gql`
    mutation CreateRestrictedUrl($input: CreateRestrictedUrlInput!) {
  createRestrictedUrl(input: $input) {
    id
    url
    filter
  }
}
    `;
export type CreateRestrictedUrlMutationFn = Apollo.MutationFunction<CreateRestrictedUrlMutation, CreateRestrictedUrlMutationVariables>;

/**
 * __useCreateRestrictedUrlMutation__
 *
 * To run a mutation, you first call `useCreateRestrictedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestrictedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestrictedUrlMutation, { data, loading, error }] = useCreateRestrictedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestrictedUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateRestrictedUrlMutation, CreateRestrictedUrlMutationVariables>) {
        return Apollo.useMutation<CreateRestrictedUrlMutation, CreateRestrictedUrlMutationVariables>(CreateRestrictedUrlDocument, baseOptions);
      }
export type CreateRestrictedUrlMutationHookResult = ReturnType<typeof useCreateRestrictedUrlMutation>;
export type CreateRestrictedUrlMutationResult = Apollo.MutationResult<CreateRestrictedUrlMutation>;
export type CreateRestrictedUrlMutationOptions = Apollo.BaseMutationOptions<CreateRestrictedUrlMutation, CreateRestrictedUrlMutationVariables>;
export const DeleteRestrictedUrlDocument = gql`
    mutation DeleteRestrictedUrl($input: DeleteRestrictedUrlInput!) {
  deleteRestrictedUrl(input: $input) {
    id
  }
}
    `;
export type DeleteRestrictedUrlMutationFn = Apollo.MutationFunction<DeleteRestrictedUrlMutation, DeleteRestrictedUrlMutationVariables>;

/**
 * __useDeleteRestrictedUrlMutation__
 *
 * To run a mutation, you first call `useDeleteRestrictedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestrictedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestrictedUrlMutation, { data, loading, error }] = useDeleteRestrictedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestrictedUrlMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestrictedUrlMutation, DeleteRestrictedUrlMutationVariables>) {
        return Apollo.useMutation<DeleteRestrictedUrlMutation, DeleteRestrictedUrlMutationVariables>(DeleteRestrictedUrlDocument, baseOptions);
      }
export type DeleteRestrictedUrlMutationHookResult = ReturnType<typeof useDeleteRestrictedUrlMutation>;
export type DeleteRestrictedUrlMutationResult = Apollo.MutationResult<DeleteRestrictedUrlMutation>;
export type DeleteRestrictedUrlMutationOptions = Apollo.BaseMutationOptions<DeleteRestrictedUrlMutation, DeleteRestrictedUrlMutationVariables>;
export const CreateRestrictedUrlGroupDocument = gql`
    mutation CreateRestrictedUrlGroup($input: CreateRestrictedUrlGroupInput!) {
  createRestrictedUrlGroup(input: $input) {
    id
    name
    redirect
    allowAllMembers
    urls {
      id
      url
      filter
    }
    plans {
      id
    }
  }
}
    `;
export type CreateRestrictedUrlGroupMutationFn = Apollo.MutationFunction<CreateRestrictedUrlGroupMutation, CreateRestrictedUrlGroupMutationVariables>;

/**
 * __useCreateRestrictedUrlGroupMutation__
 *
 * To run a mutation, you first call `useCreateRestrictedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestrictedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestrictedUrlGroupMutation, { data, loading, error }] = useCreateRestrictedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestrictedUrlGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateRestrictedUrlGroupMutation, CreateRestrictedUrlGroupMutationVariables>) {
        return Apollo.useMutation<CreateRestrictedUrlGroupMutation, CreateRestrictedUrlGroupMutationVariables>(CreateRestrictedUrlGroupDocument, baseOptions);
      }
export type CreateRestrictedUrlGroupMutationHookResult = ReturnType<typeof useCreateRestrictedUrlGroupMutation>;
export type CreateRestrictedUrlGroupMutationResult = Apollo.MutationResult<CreateRestrictedUrlGroupMutation>;
export type CreateRestrictedUrlGroupMutationOptions = Apollo.BaseMutationOptions<CreateRestrictedUrlGroupMutation, CreateRestrictedUrlGroupMutationVariables>;
export const UpdateRestrictedUrlGroupDocument = gql`
    mutation UpdateRestrictedUrlGroup($input: UpdateRestrictedUrlGroupInput!) {
  updateRestrictedUrlGroup(input: $input) {
    id
    name
    redirect
    allowAllMembers
    urls {
      id
      url
      filter
    }
    plans {
      id
    }
  }
}
    `;
export type UpdateRestrictedUrlGroupMutationFn = Apollo.MutationFunction<UpdateRestrictedUrlGroupMutation, UpdateRestrictedUrlGroupMutationVariables>;

/**
 * __useUpdateRestrictedUrlGroupMutation__
 *
 * To run a mutation, you first call `useUpdateRestrictedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestrictedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestrictedUrlGroupMutation, { data, loading, error }] = useUpdateRestrictedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRestrictedUrlGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestrictedUrlGroupMutation, UpdateRestrictedUrlGroupMutationVariables>) {
        return Apollo.useMutation<UpdateRestrictedUrlGroupMutation, UpdateRestrictedUrlGroupMutationVariables>(UpdateRestrictedUrlGroupDocument, baseOptions);
      }
export type UpdateRestrictedUrlGroupMutationHookResult = ReturnType<typeof useUpdateRestrictedUrlGroupMutation>;
export type UpdateRestrictedUrlGroupMutationResult = Apollo.MutationResult<UpdateRestrictedUrlGroupMutation>;
export type UpdateRestrictedUrlGroupMutationOptions = Apollo.BaseMutationOptions<UpdateRestrictedUrlGroupMutation, UpdateRestrictedUrlGroupMutationVariables>;
export const UpdateRestrictedUrlDocument = gql`
    mutation UpdateRestrictedUrl($input: UpdateRestrictedUrlInput!) {
  updateRestrictedUrl(input: $input) {
    id
    url
    filter
  }
}
    `;
export type UpdateRestrictedUrlMutationFn = Apollo.MutationFunction<UpdateRestrictedUrlMutation, UpdateRestrictedUrlMutationVariables>;

/**
 * __useUpdateRestrictedUrlMutation__
 *
 * To run a mutation, you first call `useUpdateRestrictedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestrictedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestrictedUrlMutation, { data, loading, error }] = useUpdateRestrictedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRestrictedUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestrictedUrlMutation, UpdateRestrictedUrlMutationVariables>) {
        return Apollo.useMutation<UpdateRestrictedUrlMutation, UpdateRestrictedUrlMutationVariables>(UpdateRestrictedUrlDocument, baseOptions);
      }
export type UpdateRestrictedUrlMutationHookResult = ReturnType<typeof useUpdateRestrictedUrlMutation>;
export type UpdateRestrictedUrlMutationResult = Apollo.MutationResult<UpdateRestrictedUrlMutation>;
export type UpdateRestrictedUrlMutationOptions = Apollo.BaseMutationOptions<UpdateRestrictedUrlMutation, UpdateRestrictedUrlMutationVariables>;
export const DeleteRestrictedUrlGroupDocument = gql`
    mutation DeleteRestrictedUrlGroup($input: DeleteRestrictedUrlGroupInput!) {
  deleteRestrictedUrlGroup(input: $input) {
    id
  }
}
    `;
export type DeleteRestrictedUrlGroupMutationFn = Apollo.MutationFunction<DeleteRestrictedUrlGroupMutation, DeleteRestrictedUrlGroupMutationVariables>;

/**
 * __useDeleteRestrictedUrlGroupMutation__
 *
 * To run a mutation, you first call `useDeleteRestrictedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestrictedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestrictedUrlGroupMutation, { data, loading, error }] = useDeleteRestrictedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestrictedUrlGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestrictedUrlGroupMutation, DeleteRestrictedUrlGroupMutationVariables>) {
        return Apollo.useMutation<DeleteRestrictedUrlGroupMutation, DeleteRestrictedUrlGroupMutationVariables>(DeleteRestrictedUrlGroupDocument, baseOptions);
      }
export type DeleteRestrictedUrlGroupMutationHookResult = ReturnType<typeof useDeleteRestrictedUrlGroupMutation>;
export type DeleteRestrictedUrlGroupMutationResult = Apollo.MutationResult<DeleteRestrictedUrlGroupMutation>;
export type DeleteRestrictedUrlGroupMutationOptions = Apollo.BaseMutationOptions<DeleteRestrictedUrlGroupMutation, DeleteRestrictedUrlGroupMutationVariables>;
export const LinkRestrictedUrlsToRestrictedUrlGroupDocument = gql`
    mutation LinkRestrictedUrlsToRestrictedUrlGroup($input: LinkRestrictedUrlsToRestrictedUrlGroupInput!) {
  linkRestrictedUrlsToRestrictedUrlGroup(input: $input) {
    id
    name
    redirect
    urls {
      id
      url
      filter
    }
    plans {
      id
    }
  }
}
    `;
export type LinkRestrictedUrlsToRestrictedUrlGroupMutationFn = Apollo.MutationFunction<LinkRestrictedUrlsToRestrictedUrlGroupMutation, LinkRestrictedUrlsToRestrictedUrlGroupMutationVariables>;

/**
 * __useLinkRestrictedUrlsToRestrictedUrlGroupMutation__
 *
 * To run a mutation, you first call `useLinkRestrictedUrlsToRestrictedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkRestrictedUrlsToRestrictedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkRestrictedUrlsToRestrictedUrlGroupMutation, { data, loading, error }] = useLinkRestrictedUrlsToRestrictedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkRestrictedUrlsToRestrictedUrlGroupMutation(baseOptions?: Apollo.MutationHookOptions<LinkRestrictedUrlsToRestrictedUrlGroupMutation, LinkRestrictedUrlsToRestrictedUrlGroupMutationVariables>) {
        return Apollo.useMutation<LinkRestrictedUrlsToRestrictedUrlGroupMutation, LinkRestrictedUrlsToRestrictedUrlGroupMutationVariables>(LinkRestrictedUrlsToRestrictedUrlGroupDocument, baseOptions);
      }
export type LinkRestrictedUrlsToRestrictedUrlGroupMutationHookResult = ReturnType<typeof useLinkRestrictedUrlsToRestrictedUrlGroupMutation>;
export type LinkRestrictedUrlsToRestrictedUrlGroupMutationResult = Apollo.MutationResult<LinkRestrictedUrlsToRestrictedUrlGroupMutation>;
export type LinkRestrictedUrlsToRestrictedUrlGroupMutationOptions = Apollo.BaseMutationOptions<LinkRestrictedUrlsToRestrictedUrlGroupMutation, LinkRestrictedUrlsToRestrictedUrlGroupMutationVariables>;
export const DetachRestrictedUrlsToRestrictedUrlGroupDocument = gql`
    mutation DetachRestrictedUrlsToRestrictedUrlGroup($input: DetachRestrictedUrlsFromRestrictedUrlGroupInput!) {
  detachRestrictedUrlsFromRestrictedUrlGroup(input: $input) {
    id
    name
    redirect
    urls {
      id
      url
      filter
    }
    plans {
      id
    }
  }
}
    `;
export type DetachRestrictedUrlsToRestrictedUrlGroupMutationFn = Apollo.MutationFunction<DetachRestrictedUrlsToRestrictedUrlGroupMutation, DetachRestrictedUrlsToRestrictedUrlGroupMutationVariables>;

/**
 * __useDetachRestrictedUrlsToRestrictedUrlGroupMutation__
 *
 * To run a mutation, you first call `useDetachRestrictedUrlsToRestrictedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachRestrictedUrlsToRestrictedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachRestrictedUrlsToRestrictedUrlGroupMutation, { data, loading, error }] = useDetachRestrictedUrlsToRestrictedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachRestrictedUrlsToRestrictedUrlGroupMutation(baseOptions?: Apollo.MutationHookOptions<DetachRestrictedUrlsToRestrictedUrlGroupMutation, DetachRestrictedUrlsToRestrictedUrlGroupMutationVariables>) {
        return Apollo.useMutation<DetachRestrictedUrlsToRestrictedUrlGroupMutation, DetachRestrictedUrlsToRestrictedUrlGroupMutationVariables>(DetachRestrictedUrlsToRestrictedUrlGroupDocument, baseOptions);
      }
export type DetachRestrictedUrlsToRestrictedUrlGroupMutationHookResult = ReturnType<typeof useDetachRestrictedUrlsToRestrictedUrlGroupMutation>;
export type DetachRestrictedUrlsToRestrictedUrlGroupMutationResult = Apollo.MutationResult<DetachRestrictedUrlsToRestrictedUrlGroupMutation>;
export type DetachRestrictedUrlsToRestrictedUrlGroupMutationOptions = Apollo.BaseMutationOptions<DetachRestrictedUrlsToRestrictedUrlGroupMutation, DetachRestrictedUrlsToRestrictedUrlGroupMutationVariables>;
export const LinkPlansToRestrictedUrlGroupDocument = gql`
    mutation LinkPlansToRestrictedUrlGroup($input: LinkPlansToRestrictedUrlGroupInput!) {
  linkPlansToRestrictedUrlGroup(input: $input) {
    id
  }
}
    `;
export type LinkPlansToRestrictedUrlGroupMutationFn = Apollo.MutationFunction<LinkPlansToRestrictedUrlGroupMutation, LinkPlansToRestrictedUrlGroupMutationVariables>;

/**
 * __useLinkPlansToRestrictedUrlGroupMutation__
 *
 * To run a mutation, you first call `useLinkPlansToRestrictedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPlansToRestrictedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPlansToRestrictedUrlGroupMutation, { data, loading, error }] = useLinkPlansToRestrictedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkPlansToRestrictedUrlGroupMutation(baseOptions?: Apollo.MutationHookOptions<LinkPlansToRestrictedUrlGroupMutation, LinkPlansToRestrictedUrlGroupMutationVariables>) {
        return Apollo.useMutation<LinkPlansToRestrictedUrlGroupMutation, LinkPlansToRestrictedUrlGroupMutationVariables>(LinkPlansToRestrictedUrlGroupDocument, baseOptions);
      }
export type LinkPlansToRestrictedUrlGroupMutationHookResult = ReturnType<typeof useLinkPlansToRestrictedUrlGroupMutation>;
export type LinkPlansToRestrictedUrlGroupMutationResult = Apollo.MutationResult<LinkPlansToRestrictedUrlGroupMutation>;
export type LinkPlansToRestrictedUrlGroupMutationOptions = Apollo.BaseMutationOptions<LinkPlansToRestrictedUrlGroupMutation, LinkPlansToRestrictedUrlGroupMutationVariables>;
export const DetachPlansFromRestrictedUrlGroupDocument = gql`
    mutation DetachPlansFromRestrictedUrlGroup($input: DetachPlansFromRestrictedUrlGroupInput!) {
  detachPlansFromRestrictedUrlGroup(input: $input) {
    id
  }
}
    `;
export type DetachPlansFromRestrictedUrlGroupMutationFn = Apollo.MutationFunction<DetachPlansFromRestrictedUrlGroupMutation, DetachPlansFromRestrictedUrlGroupMutationVariables>;

/**
 * __useDetachPlansFromRestrictedUrlGroupMutation__
 *
 * To run a mutation, you first call `useDetachPlansFromRestrictedUrlGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachPlansFromRestrictedUrlGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachPlansFromRestrictedUrlGroupMutation, { data, loading, error }] = useDetachPlansFromRestrictedUrlGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachPlansFromRestrictedUrlGroupMutation(baseOptions?: Apollo.MutationHookOptions<DetachPlansFromRestrictedUrlGroupMutation, DetachPlansFromRestrictedUrlGroupMutationVariables>) {
        return Apollo.useMutation<DetachPlansFromRestrictedUrlGroupMutation, DetachPlansFromRestrictedUrlGroupMutationVariables>(DetachPlansFromRestrictedUrlGroupDocument, baseOptions);
      }
export type DetachPlansFromRestrictedUrlGroupMutationHookResult = ReturnType<typeof useDetachPlansFromRestrictedUrlGroupMutation>;
export type DetachPlansFromRestrictedUrlGroupMutationResult = Apollo.MutationResult<DetachPlansFromRestrictedUrlGroupMutation>;
export type DetachPlansFromRestrictedUrlGroupMutationOptions = Apollo.BaseMutationOptions<DetachPlansFromRestrictedUrlGroupMutation, DetachPlansFromRestrictedUrlGroupMutationVariables>;
export const CreateCustomContentDocument = gql`
    mutation CreateCustomContent($input: CreateCustomContentExistingGroupInput!) {
  createCustomContent(input: $input) {
    id
    name
    content
    type
  }
}
    `;
export type CreateCustomContentMutationFn = Apollo.MutationFunction<CreateCustomContentMutation, CreateCustomContentMutationVariables>;

/**
 * __useCreateCustomContentMutation__
 *
 * To run a mutation, you first call `useCreateCustomContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomContentMutation, { data, loading, error }] = useCreateCustomContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomContentMutation, CreateCustomContentMutationVariables>) {
        return Apollo.useMutation<CreateCustomContentMutation, CreateCustomContentMutationVariables>(CreateCustomContentDocument, baseOptions);
      }
export type CreateCustomContentMutationHookResult = ReturnType<typeof useCreateCustomContentMutation>;
export type CreateCustomContentMutationResult = Apollo.MutationResult<CreateCustomContentMutation>;
export type CreateCustomContentMutationOptions = Apollo.BaseMutationOptions<CreateCustomContentMutation, CreateCustomContentMutationVariables>;
export const UpdateCustomContentDocument = gql`
    mutation UpdateCustomContent($input: UpdateCustomContentInput!) {
  updateCustomContent(input: $input) {
    id
    name
    content
    type
  }
}
    `;
export type UpdateCustomContentMutationFn = Apollo.MutationFunction<UpdateCustomContentMutation, UpdateCustomContentMutationVariables>;

/**
 * __useUpdateCustomContentMutation__
 *
 * To run a mutation, you first call `useUpdateCustomContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomContentMutation, { data, loading, error }] = useUpdateCustomContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomContentMutation, UpdateCustomContentMutationVariables>) {
        return Apollo.useMutation<UpdateCustomContentMutation, UpdateCustomContentMutationVariables>(UpdateCustomContentDocument, baseOptions);
      }
export type UpdateCustomContentMutationHookResult = ReturnType<typeof useUpdateCustomContentMutation>;
export type UpdateCustomContentMutationResult = Apollo.MutationResult<UpdateCustomContentMutation>;
export type UpdateCustomContentMutationOptions = Apollo.BaseMutationOptions<UpdateCustomContentMutation, UpdateCustomContentMutationVariables>;
export const DeleteCustomContentDocument = gql`
    mutation DeleteCustomContent($input: DeleteCustomContentInput!) {
  deleteCustomContent(input: $input) {
    id
  }
}
    `;
export type DeleteCustomContentMutationFn = Apollo.MutationFunction<DeleteCustomContentMutation, DeleteCustomContentMutationVariables>;

/**
 * __useDeleteCustomContentMutation__
 *
 * To run a mutation, you first call `useDeleteCustomContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomContentMutation, { data, loading, error }] = useDeleteCustomContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomContentMutation, DeleteCustomContentMutationVariables>) {
        return Apollo.useMutation<DeleteCustomContentMutation, DeleteCustomContentMutationVariables>(DeleteCustomContentDocument, baseOptions);
      }
export type DeleteCustomContentMutationHookResult = ReturnType<typeof useDeleteCustomContentMutation>;
export type DeleteCustomContentMutationResult = Apollo.MutationResult<DeleteCustomContentMutation>;
export type DeleteCustomContentMutationOptions = Apollo.BaseMutationOptions<DeleteCustomContentMutation, DeleteCustomContentMutationVariables>;