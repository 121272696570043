import React, { useEffect, useMemo } from "react";
import { Button, Card } from "components";
import {
  useGetWebflowAuthorizationUrlLazyQuery,
  useDeauthorizeWebflowMutation,
  useSetWebflowSiteMutation,
  useGetWebflowSitesLazyQuery,
} from "features";
import { useToastAlertContext } from "components/toastAlert";
import Select from "components/Select";
import Skeleton from "react-loading-skeleton";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useUsersContext } from "containers/App/UsersContextProvider";

const ConnectWebflowWebsiteLoader = () => (
  <Card className="p-4 flex flex-col">
    <h1 tw="text-h3 font-bold mb-5">Connect a webflow website</h1>
    <p tw="text-body-sm mb-1 font-bold">
      Select a webflow website to connect to your app
    </p>
    <Skeleton height={46} tw="w-full" />
    <Skeleton height={148} tw="w-full my-2" />
    <Button text="Connect this webflow site" isLoading tw="mt-5" />
  </Card>
);

const ConnectWebflowLoader = () => (
  <div tw="p-7 border-b">
    <h1 tw="text-h3 font-bold mb-5">Connect Webflow</h1>
    <Skeleton height={48} tw="w-full mb-7" />
    <Button text="Connect" onClick={undefined} isLoading />
  </div>
);

interface Props {
  webflowSiteId: string;
  webflowSiteName: string;
  appId: string;
  connectedToWebflow: boolean;
}

const ConnectWebflow = ({
  webflowSiteId,
  webflowSiteName,
  appId,
  connectedToWebflow,
}: Props) => {
  const [selectedSite, setSelectedSite] = React.useState({
    value: webflowSiteId,
    label: webflowSiteName,
  });

  const { createToastAlert } = useToastAlertContext();

  const [getWebflowSites, { loading, data: webflowSitesData }] =
    useGetWebflowSitesLazyQuery();

  useEffect(() => {
    if (connectedToWebflow) {
      getWebflowSites();
    }
  }, [connectedToWebflow, getWebflowSites]);

  const [authorizeWebflow, { loading: authorizing, data, error }] =
    useGetWebflowAuthorizationUrlLazyQuery();

  const [deAuthorizeWebflow, { loading: deAuthorizing }] =
    useDeauthorizeWebflowMutation({
      update(cache, { data: _data }) {
        cache.modify({
          id: cache.identify({ id: appId, __typename: "User" }),
          fields: {
            connectedToWebflow() {
              return _data?.deauthorizeWebflow?.session.connectedToWebflow;
            },
          },
        });
      },
    });

  const [setWebflowSite, { loading: settingWebflowSite }] =
    useSetWebflowSiteMutation({
      update(cache, { data: _data }) {
        cache.modify({
          id: cache.identify({ id: appId, __typename: "WebflowSite" }),
          fields: {
            webflowSiteId() {
              return _data?.setWebflowSite?.webflowSiteId;
            },
          },
        });
      },
    });

  const connectAction = connectedToWebflow
    ? deAuthorizeWebflow
    : authorizeWebflow;

  const handleConnect = async () => {
    try {
      await connectAction();
      if (connectedToWebflow) {
        createToastAlert({
          alertType: "success",
          message: "Successfully revoked Webflow access.",
        });
      }
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: err.message,
      });
    }
  };

  useEffect(() => {
    if (data) {
      window.open(data.getWebflowAuthorizationUrl, "_self");
    }
    if (error) {
      createToastAlert({ alertType: "error", message: error.message });
    }
  }, [error, data]);

  const { submit: handleSetWebflowSite } = useOnSubmit({
    action: setWebflowSite,
    // refetch,
    errorMsg: "Unable to connect to Webflow site.",
    successMsg: "Webflow site connected successfully.",
    fields: { appId, siteId: selectedSite.value },
  });

  const connectWebflowSiteUI = useMemo(() => {
    if (loading) {
      return <ConnectWebflowWebsiteLoader />;
    }

    if (webflowSitesData?.getWebflowSites) {
      const webflowSites = webflowSitesData?.getWebflowSites?.map((site) => ({
        value: site.id,
        label: site.name,
      }));

      return (
        <Card className="p-4 flex flex-col">
          <h1 tw="text-base font-bold mb-4">Connect a webflow website</h1>
          <Select
            menuIsOpen
            label="Select a webflow website to connect to your app"
            placeholder="Select a webflow website"
            options={webflowSites}
            onChange={(option) => setSelectedSite(option)}
            value={selectedSite.value === null ? null : selectedSite}
            isSearchable
            className="lg:w-custom-560 w-full"
          />
          <Button
            text="Connect this webflow site"
            isLoading={settingWebflowSite}
            className="w-max mt-4"
            onClick={() => handleSetWebflowSite()}
          />
        </Card>
      );
    }
    return null;
  }, [
    handleSetWebflowSite,
    loading,
    selectedSite,
    settingWebflowSite,
    webflowSitesData?.getWebflowSites,
  ]);

  const subText = connectedToWebflow
    ? "Need to authorize another project? Please revoke access and then reauthorize with your projects selected."
    : "Click the button below to authorize access to your Webflow account (Optional). We will automatically add your webflow.io domain and other published domains as options in your Application Domains. ";

  return (
    <div className="flex flex-col gap-4">
      <Card className="p-4 flex flex-col">
        <h1 className="text-base font-bold mb-4">Connect Webflow</h1>
        <p className="text-body-sm mb-4 lg:w-9/12 w-full">
          {subText}
          {!connectedToWebflow && (
            <>
              We plan to add more functionality in the future.{" "}
              <a
                tw="font-bold text-app-blue400"
                rel="noreferrer"
                href="https://docs.memberstack.com/hc/en-us/articles/16547760141979"
                target="_blank"
              >
                Learn more
              </a>
            </>
          )}
        </p>

        <Button
          text={connectedToWebflow ? "Revoke authorization" : "Connect"}
          buttonStyle={connectedToWebflow ? "danger" : "default"}
          onClick={() => handleConnect()}
          isDisabled={authorizing || deAuthorizing}
          className="w-max"
        />
      </Card>
      {connectedToWebflow && connectWebflowSiteUI}
    </div>
  );
};

const ConnectWebflowPage = () => {
  const { appData, isLoading } = useAppDataContext();
  const { currentUser } = useUsersContext();

  if (isLoading) {
    return <ConnectWebflowLoader />;
  }

  const {
    session: { connectedToWebflow },
  } = currentUser;

  return (
    <ConnectWebflow
      webflowSiteId={appData.webflowSiteId}
      webflowSiteName={appData.webflowSiteName}
      appId={appData?.id}
      connectedToWebflow={connectedToWebflow}
    />
  );
};

export default ConnectWebflowPage;
