import React, { useMemo } from "react";
import { Permission } from "generatedTypes";
import Text from "components/Text";
import Spacer from "components/Spacer";
import { useToastAlertContext } from "components/toastAlert";
import { useDeletePermissionMutation } from "features/permissions/mutations.generated";
import { Button } from "components";
import { usePermissionsContext } from "features/permissions/context";
import { usePlansContext } from "containers/Plans/context/Plans.context";

interface Props {
  permission: Permission;
  closeModal: () => void;
  onBack: () => void;
}

const DeletePermissionForm = ({ closeModal, permission, onBack }: Props) => {
  const { plans } = usePlansContext();
  const { refetchPermissions } = usePermissionsContext();
  const [deletePermission, { loading }] = useDeletePermissionMutation();

  const { createToastAlert } = useToastAlertContext();

  const handleDelete = async () => {
    createToastAlert({
      processing: true,
    });

    try {
      await deletePermission({
        variables: {
          input: {
            permissionId: permission?.id,
          },
        },
      });

      await refetchPermissions();

      createToastAlert({
        alertType: "success",
        message: "Permission was successfully deleted.",
      });

      closeModal();
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: `${err}`,
      });
    }
  };

  const linkedPlans = useMemo(() => {
    return plans?.filter((p) => {
      const planPermissionIds = p.permissions.map((perm) => perm.id);
      return planPermissionIds.includes(permission?.id);
    });
  }, [permission?.id, plans]);

  return (
    <form>
      <div className="flex flex-col p-5">
        {linkedPlans?.length > 0 ? (
          <>
            <Text>
              This permission is currently being used in {linkedPlans?.length}{" "}
              plans:
            </Text>
            <ul tw="list-disc pl-6">
              {linkedPlans.map((plan) => (
                <li key={plan?.id}>
                  <Text>{plan?.name}</Text>
                </li>
              ))}
            </ul>
            <Spacer spacing="medium" />
          </>
        ) : (
          <p>
            Are you sure? The <strong>{permission?.name}</strong> permission
            will no longer affect your project.
          </p>
        )}
      </div>
      <div className="flex justify-end items-center p-5 border-t">
        <div className="flex">
          <Button
            text="Back"
            buttonStyle="skeleton"
            onClick={onBack}
            tw="mr-2"
          />
          <Button
            text="Confirm Delete"
            onClick={handleDelete}
            type="submit"
            buttonStyle="danger"
            isLoading={loading}
            dataCy="delete-permission-confirm-button"
          />
        </div>
      </div>
    </form>
  );
};

export default DeletePermissionForm;
