import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocalStorageEnv } from "hooks/useLocalStorage";
import { useLocation, useHistory } from "react-router-dom";
import { MemberstackEnv } from "generatedTypes";

const formatLocation = (locationPath: string): string => {
  const locationParts = ["/pln", "/mem_"];
  let formattedLocation = locationPath;

  locationParts.forEach((part) => {
    if (formattedLocation.includes(part)) {
      [formattedLocation] = formattedLocation.split(part);
    }
  });

  return formattedLocation;
};

interface EnvContextType {
  env: MemberstackEnv;
  toggleEnv: () => void;
  switchToSandbox: () => void;
  isSandboxEnv: boolean;
}

const defaultEnvContext: EnvContextType = {
  env: MemberstackEnv.Live,
  toggleEnv: () => {},
  switchToSandbox: () => {},
  isSandboxEnv: false,
};

const EnvContext = React.createContext<EnvContextType>(defaultEnvContext);

const useEnvContext = () => useContext(EnvContext);

const EnvContextProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const location = useLocation();

  const { storedEnv, setStoredEnv } = useLocalStorageEnv(
    "_ms-env",
    MemberstackEnv.Live
  );
  const [env, setEnv] = useState(storedEnv);

  const setEnvAndStored = useCallback(
    (newEnv: MemberstackEnv) => {
      setEnv(newEnv);
      setStoredEnv(newEnv);
    },
    [setStoredEnv]
  );

  useEffect(() => {
    if (env !== MemberstackEnv.Sandbox) {
      setEnvAndStored(MemberstackEnv.Live);
    }
  }, []);

  const switchToSandbox = useCallback(() => {
    if (env !== MemberstackEnv.Sandbox) {
      setEnvAndStored(MemberstackEnv.Sandbox);
    }
  }, [env, setEnvAndStored]);

  const toggleEnv = useCallback(() => {
    try {
      const newMode =
        env === MemberstackEnv.Live
          ? MemberstackEnv.Sandbox
          : MemberstackEnv.Live;

      setEnvAndStored(newMode);

      const formattedLocation = formatLocation(location.pathname);

      history.push(formattedLocation);
    } catch (error) {
      console.error("Failed to toggle environment:", error);
    }
  }, [env, history, location.pathname, setEnvAndStored]);

  const isSandboxEnv = useMemo(() => env === MemberstackEnv.Sandbox, [env]);

  const contextValue = useMemo(
    () => ({
      env,
      toggleEnv,
      switchToSandbox,
      isSandboxEnv,
    }),
    [env, toggleEnv, switchToSandbox, isSandboxEnv]
  );

  return (
    <EnvContext.Provider value={contextValue}>{children}</EnvContext.Provider>
  );
};

export { useEnvContext, EnvContextProvider };
