import { styled } from "twin.macro";
import { colors } from "styles";

export const Plan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 580px;
  width: 440px !important;
  padding: 60px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
`;

const blurbVariants = {
  first: "311px",
  second: "181px",
};

export const PlanBlurb = styled.div<{ $variant: keyof typeof blurbVariants }>`
  width: ${({ $variant }) => blurbVariants[$variant]};
  height: 36px;
  background: ${colors.grey100};
  border-radius: 8px;
`;

export const DummyInput = styled.div`
  width: 319px;
  height: 54px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

export const DummyButton = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  position: static;
  width: 319px;
  height: 56px;
  left: 60px;
  top: 464px;
  background: ${colors.grey700};
  opacity: ${({ $selected }) => ($selected ? "1" : "0.2")};
  border-radius: 8px;
  font-size: 20.16px;
  line-height: 32px;
  /* identical to box height, or 159% */
  color: #ffffff;
`;
