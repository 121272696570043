import React, { useMemo } from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { Plan } from "generatedTypes";
import { isPlanActive } from "helpers/isPlanActive";
import getActiveFreePlans from "helpers/getActiveFreePlans";
import { OptionType } from "containers/Members/modals/CreateMemberModal";
import { GetPlansQuery, useGetPlansQuery } from "features/plans";

interface DefaultContext {
  activePlans: Plan[];
  activeFreePlans: OptionType[];
  archivedPlans: Plan[];
  freePlans: OptionType[];
  allPrices: OptionType[];
  refetchPlans: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  error: ApolloError;
  loadingPlans: boolean;
  plans: GetPlansQuery["getPlans"];
  findPlan: (id: string) => Plan;
}

const defaultContext: DefaultContext = {
  activePlans: undefined,
  activeFreePlans: undefined,
  freePlans: undefined,
  archivedPlans: undefined,
  loadingPlans: false,
  error: undefined,
  refetchPlans: undefined,
  plans: undefined,
  allPrices: undefined,
  findPlan: undefined,
};

const PlanContext = React.createContext(defaultContext);

const usePlansContext = () => {
  return React.useContext(PlanContext);
};

const PlansContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { error, refetch: refetchPlans, loading, data } = useGetPlansQuery({});

  const findPlan = (id: string) => {
    return data?.getPlans?.find((p) => id === p?.id);
  };

  const plans = useMemo(() => data?.getPlans || [], [data?.getPlans]);

  const plansOrdered = useMemo(() => {
    if (!plans) return [];
    const ordered = [...plans];
    ordered.sort((a, b) => a?.priority - b?.priority);
    return ordered;
  }, [plans]);

  const activePlans = plansOrdered?.filter((p) => isPlanActive(p?.status));
  const archivedPlans = plansOrdered?.filter((p) => !isPlanActive(p?.status));

  const activeFreePlans = getActiveFreePlans(plans);
  const freePlans = plans
    .filter((p) => !p?.prices.length)
    .map(({ name, id }) => ({
      value: id,
      label: name,
      planId: id,
    }));

  const allPrices = plans
    .map((p) => p?.prices)
    .flat()
    .map((p) => ({ value: p?.id, label: p?.name, type: p?.type }));

  return (
    <PlanContext.Provider
      value={{
        activePlans,
        activeFreePlans,
        archivedPlans,
        freePlans,
        plans,
        error,
        refetchPlans,
        loadingPlans: loading,
        findPlan,
        allPrices,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export { usePlansContext, PlansContextProvider };
