import { Card, ImageUpload } from "components";
import Input from "components/Input";
import TextCombo from "components/TextCombo";
import React from "react";
import { useParams } from "react-router-dom";

const ApplicationInformation = ({
  formValues,
  handleOnChange,
  handleOnImageChange,
  image,
}) => {
  const { appId } = useParams<{ appId: string }>();

  return (
    <Card className="p-4 flex flex-col">
      <h3 className="text-base font-bold mb-4">Application Information</h3>
      <div className="flex flex-col gap-7">
        <Input
          className="lg:w-custom-560 w-full"
          name="name"
          value={formValues?.name}
          onChange={handleOnChange("name")}
          label="App Display Name"
          hideIndicators
          description="Visible to members & teammates."
          dataCy="app-display-name"
        />
        <TextCombo label="Memberstack App ID" text={appId} withCopy />
        <ImageUpload
          description="This is your site’s initials + your brand color."
          text="Replace Image"
          onChange={handleOnImageChange}
          image={image}
          label="App Image"
        />
      </div>
    </Card>
  );
};

export default ApplicationInformation;
