import React from "react";

interface Props {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  showWavingEmoji?: boolean;
}

const TipBox = ({
  children,
  title,
  className,
  showWavingEmoji = true,
}: Props) => {
  return (
    <div tw="rounded p-3 bg-app-gray50 flex" className={className}>
      {showWavingEmoji && <div className="mr-2 text-h4">👋</div>}
      <div className="text-base">
        {title && <h4 className="text-h4 font-bold">{title}</h4>}
        {children}
      </div>
    </div>
  );
};

export default TipBox;
