import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import styled, { css } from "styled-components";

const Container = styled.div<{
  $scrollable?: boolean;
  $isRowsInteractive?: boolean;
  $isHeadersInteractive?: boolean;
  $headerCellPadding?: number;
}>(
  ({
    theme: { colors },
    $scrollable = true,
    $isRowsInteractive = true,
    $isHeadersInteractive = true,
    $headerCellPadding = 16,
  }) => css`
    height: ${$scrollable ? "100%" : "unset"};
    overflow: ${$scrollable ? "scroll" : "hidden"};
    table {
      min-width: 100%;
      font-size: 14px;
      line-height: 24px;
      & > thead {
        & > tr {
          border: none;
          & > th {
            padding: ${$headerCellPadding}px;
            text-align: left;
            font-weight: 700;
            white-space: nowrap;
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: white;
            &.plan {
              min-width: 321px;
            }
            ${$isHeadersInteractive &&
            css`
              :hover {
                & .more-options {
                  display: block;
                }
              }
            `}
          }
        }
      }

      & > tbody {
        .active {
          background: rgba(231, 241, 255, 0.5);
        }
        .selected {
          background: ${colors.primary050};
        }
        & > tr {
          cursor: ${$isRowsInteractive ? "pointer" : "default"};
          border-bottom: 1px solid ${colors.grey100};
          vertical-align: top;
          height: 61px;
          :last-child {
            border-bottom: none;
          }
          ${$isRowsInteractive &&
          css`
            :hover {
              background: ${colors.grey050};
            }
          `}
        }
      }
    }
    td {
      padding: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
      &.plans {
        min-width: 321px;
      }
      &.comment-table {
        max-width: none;
      }
      &.email {
        max-width: min-content;
      }
      &.selection {
        padding: 0px;
      }
    }
  `
);

interface TableProps {
  children: React.ReactNode;
  scrollable?: boolean;
  isRowsInteractive?: boolean;
  isHeadersInteractive?: boolean;
  dataTestId?: string;
  fetchMoreData?: () => void;
  hasMoreData?: boolean;
  numberOfData: number;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableProps?: any;
  headerCellPadding?: number;
}

export const Table = ({
  children,
  scrollable,
  isRowsInteractive,
  isHeadersInteractive,
  dataTestId,
  fetchMoreData,
  hasMoreData,
  numberOfData,
  className,
  tableProps,
  headerCellPadding,
}: TableProps) => (
  <Container
    $scrollable={scrollable}
    $isRowsInteractive={isRowsInteractive}
    $isHeadersInteractive={isHeadersInteractive}
    data-testid={dataTestId}
    className={className}
    $headerCellPadding={headerCellPadding}
  >
    <InfiniteScroll
      height="100%"
      dataLength={numberOfData}
      next={fetchMoreData}
      hasMore={hasMoreData}
      loader={<Skeleton width="100%" height={40} />}
    >
      <table className="border-solid border-app-gray100" {...tableProps}>
        {children}
      </table>
    </InfiniteScroll>
  </Container>
);
