import React, { useEffect, useRef, useState } from "react";
import { LineChart } from "components/LineChart/LineChart";
import { Card, DatePicker } from "components";
import { DateRange } from "react-day-picker";
import {
  defaultDateRange,
  PeriodTypes,
} from "components/DatePicker/datepicker.utils";
import { MemberMetricsInterval } from "generatedTypes";
import { LineChartLoading } from "components/LineChart/line-chart.loading";
import { useAppDataContext } from "containers/Layouts/AppsLayout/AppDataContextProvider";
import styled from "styled-components";
import useDashboardEvent from "hooks/useWebsockets/dashboard.event";
import { useEnvContext } from "containers/App/contexts/Environment";
import useGetMembersGraphMetrics from "hooks/useGetMembersGraphMetrics";
import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";

const DatePickerContainer = styled.div`
  margin-bottom: 24px;
  @media (max-width: 1204px) {
    margin-bottom: 8px;
  }
`;

const Container = styled(Card)<{ hasBorder: boolean }>`
  padding: ${({ hasBorder }) => (hasBorder ? "16px 16px 0" : "0")};
`;

interface SignupGraphProps {
  hasBorder?: boolean;
}

export const SignupGraph = ({ hasBorder }: SignupGraphProps) => {
  const [range, setRange] = useState<DateRange>(defaultDateRange);
  const [period, setPeriod] = useState<PeriodTypes>(PeriodTypes.LAST_7_DAYS);
  const [interval, setInterval] = useState(MemberMetricsInterval.Day);

  const { env } = useEnvContext();
  const { appData } = useAppDataContext();
  const dashboardEvents = useDashboardEvent();

  const { isReady, memberMetricsData, refetchMemberMetrics } =
    useGetMembersGraphMetrics({ range, interval });

  const previousEnv = useRef(env);

  useEffect(() => {
    if (env !== previousEnv.current) {
      refetchMemberMetrics();
    }
    previousEnv.current = env;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchMemberMetrics, interval, range?.from, range?.to, period]);

  useEffect(() => {
    const handleDashboardEvent = (_data) => {
      logInDevelopmentEnvironment(
        `🚨 ${_data.event} event received - trigger refetch member metrics`
      );
      refetchMemberMetrics();
    };
    dashboardEvents.subscribe("signup-graph", handleDashboardEvent);
    return () => {
      dashboardEvents.unsubscribe("signup-graph");
    };
  });

  return (
    <Container
      hasBorder={hasBorder}
      hideBorder={!hasBorder}
      hideShadow={!hasBorder}
    >
      <DatePickerContainer>
        <DatePicker
          allTimeFromDate={appData?.createdAt}
          mode="range"
          selected={range}
          onChange={(sDates, sPeriod, sInterval) => {
            setRange(sDates);
            setPeriod(sPeriod);
            setInterval(sInterval);
          }}
        />
      </DatePickerContainer>

      {!isReady ? (
        <LineChartLoading tw="h-[283px]" title="Signups" />
      ) : (
        <LineChart
          currentData={memberMetricsData?.currPeriod}
          previousData={memberMetricsData?.prevPeriod}
          title="Signups"
          interval={interval}
        />
      )}
    </Container>
  );
};
