import { Button, Card, Switch } from "components";
import { useToastAlertContext } from "components/toastAlert";
import { useUpdateAppMutation } from "features";
import useAppNavigator from "hooks/useAppNavigator";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useAppDataContext } from "routes/AppDataContextProvider";

const DEFAULT_SETTINGS = {
  weeklyRoundupEmailEnabled: false,
  dailyCommentingEmailEnabled: false,
};

function NotificationSwitch({
  id,
  name,
  label,
  description,
  isChecked,
  onChange,
}) {
  return (
    <Switch
      id={id}
      name={name}
      labelClassName="font-bold"
      dataCy={id}
      label={label}
      description={description}
      isChecked={isChecked}
      onChange={onChange}
    />
  );
}

const Notifications = () => {
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const { appData, isLoading, refetch } = useAppDataContext();

  const [updateEmails, { loading }] = useUpdateAppMutation();

  const { setHasUnsavedChanges } = useAppNavigator();
  const { createToastAlert } = useToastAlertContext();

  useEffect(() => {
    if (appData) {
      const { weeklyRoundupEmailEnabled, dailyCommentingEmailEnabled } =
        appData;
      setSettings({
        weeklyRoundupEmailEnabled: weeklyRoundupEmailEnabled || false,
        dailyCommentingEmailEnabled: dailyCommentingEmailEnabled || false,
      });
    }
  }, [appData]);

  const didFormChange =
    settings.weeklyRoundupEmailEnabled !== appData?.weeklyRoundupEmailEnabled ||
    settings.dailyCommentingEmailEnabled !==
      appData?.dailyCommentingEmailEnabled;

  useEffect(() => {
    if (didFormChange) {
      return setHasUnsavedChanges(true);
    }
    return setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didFormChange]);

  const handleUpdateEmails = async () => {
    const { weeklyRoundupEmailEnabled, dailyCommentingEmailEnabled } = settings;
    try {
      await updateEmails({
        variables: {
          input: {
            weeklyRoundupEmailEnabled,
            dailyCommentingEmailEnabled,
          },
        },
      });
      await refetch();
      createToastAlert({
        message: "Email settings updated successfully",
        alertType: "success",
      });
    } catch (error) {
      createToastAlert({
        message: "Failed to update email settings",
        alertType: "error",
      });
    }
  };

  return (
    <form onSubmit={handleUpdateEmails}>
      <Button
        type="submit"
        text="Save"
        tw="absolute right-5 top-5"
        onClick={handleUpdateEmails}
        isLoading={loading || isLoading}
        isDisabled={!didFormChange}
      />
      <Card className="p-4 flex items-start gap-6">
        {isLoading ? (
          <Skeleton width={250} height={28} />
        ) : (
          <h1 className="font-bold text-base w-custom-250">
            Email Notifications
          </h1>
        )}
        <div className="flex items-start gap-4 flex-col">
          {isLoading ? (
            <>
              <Skeleton width={444} height={45} />
              <Skeleton width={444} height={45} />
            </>
          ) : (
            <>
              <NotificationSwitch
                id="weeklyRoundupEmailEnabled"
                name="weeklyRoundupEmailEnabled"
                label="New Member Weekly Roundup Emails"
                description="Enable this if you want to receive the weekly roundup email."
                isChecked={settings.weeklyRoundupEmailEnabled}
                onChange={() =>
                  setSettings({
                    ...settings,
                    weeklyRoundupEmailEnabled:
                      !settings.weeklyRoundupEmailEnabled,
                  })
                }
              />
              <NotificationSwitch
                id="dailyCommentingEmailEnabled"
                name="dailyCommentingEmailEnabled"
                label="Commenting Daily Summary Email"
                description="Enable this if you want to receive the daily comments summary email."
                isChecked={settings.dailyCommentingEmailEnabled}
                onChange={() =>
                  setSettings({
                    ...settings,
                    dailyCommentingEmailEnabled:
                      !settings.dailyCommentingEmailEnabled,
                  })
                }
              />
            </>
          )}
        </div>
      </Card>
    </form>
  );
};

export default Notifications;
