import React, { useCallback, useState, useContext, Fragment } from "react";
import useAuth from "hooks/useAuth";
import { PersonOutline } from "@mui/icons-material";
import { Popover, Transition } from "@headlessui/react";
import LogoutIcon from "@mui/icons-material/Logout";
import useTranslations from "hooks/useTranslations";
import { getUIAvatarLink } from "helpers/getUIAvatarLink";
import { useUsersContext } from "containers/App/UsersContextProvider";
import styled, { css } from "styled-components";
import { SidebarContext } from "../context/sidebar.context";
import { Trigger } from "../AppSelector/Trigger";
import EditProfileModal from "./EditProfileModal";

const MenuItems = styled.div(
  ({ theme: { colors } }) => css`
    padding: 12px;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: ${colors.grey900};
    cursor: pointer;
    :hover {
      background-color: ${colors.grey050};
    }
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  `
);

const MenuItemContainer = styled.div(
  ({ theme: { colors } }) => css`
    background-color: ${colors.white};
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid ${colors.grey100};
    & > :not([hidden]) ~ :not([hidden]) {
      border-top-width: calc(1px * calc(1 - 0));
      border-bottom-width: calc(1px * 0);
      border-radius: 0px 0px 6px 6px;
      border-color: ${colors.grey200};
    }
  `
);

const ProfileMenu = () => {
  const { isExpanded } = useContext(SidebarContext);
  const { translate } = useTranslations();
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const handleShowModal = useCallback(() => setShowEditProfileModal(true), []);

  const { currentUser } = useUsersContext();
  const { logout } = useAuth();

  const { firstName, lastName } = currentUser?.profile;
  const fullName = `${firstName} ${lastName}`;

  const imageSrc = getUIAvatarLink({ name: fullName });

  return (
    <div className="p-3 pt-0">
      <hr className="w-auto" />
      <Popover className="relative">
        {({ open }) => {
          return (
            <>
              <Popover.Button as="div" data-testid="profile-button">
                <Trigger
                  appImageAlt={`${firstName}-profile-photo`}
                  appImageSrc={imageSrc}
                  appName={fullName}
                  isExpanded={isExpanded}
                  showExpandIcon={false}
                  tw="mt-1"
                />
              </Popover.Button>
              <Popover.Overlay
                className={`${
                  open ? "opacity-30 fixed inset-0 z-10" : "opacity-0"
                }`}
              />
              {open && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="fixed transform shadow-lg z-10 w-custom-228 bottom-14">
                    <MenuItemContainer>
                      <MenuItems
                        data-cy="profile-settings"
                        onClick={handleShowModal}
                      >
                        <PersonOutline />
                        Account
                      </MenuItems>
                      <MenuItems data-cy="logout" onClick={logout}>
                        <LogoutIcon />
                        {translate("sideBar.logout", "Logout")}
                      </MenuItems>
                    </MenuItemContainer>
                  </Popover.Panel>
                </Transition>
              )}
            </>
          );
        }}
      </Popover>
      <EditProfileModal
        showModal={showEditProfileModal}
        setShowModal={setShowEditProfileModal}
      />
    </div>
  );
};

export default ProfileMenu;
