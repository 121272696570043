import React, { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import usePrevious from "hooks/usePrevious";

import { useAppDataContext } from "routes/AppDataContextProvider";
import SupportWidget from "components/SupportWidget/SupportWidget";
import { TwoFactorAuthRequired } from "containers/2FactorAuth/TwoFactorAuthRequired";
import { useUsersContext } from "containers/App/UsersContextProvider";
import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";
import { useToastAlertContext } from "components/toastAlert";

const ContentContainer = styled.div<{ $blank: boolean }>(
  () =>
    ({ $blank, theme: { colors } }) => css`
      ${!$blank &&
      css`
        border: 1px solid ${(props) => props.theme.colors.grey100};
        border-radius: 12px 0 0 12px;
        background: ${colors.white};
      `};
    `
);

const Main = styled.main<{ $blank: boolean; $overflowMain: boolean }>(
  () =>
    ({ $blank, $overflowMain }) => css`
      overflow-y: ${$overflowMain ? "auto" : "hidden"};
      ${$blank &&
      css`
        display: flex;
        height: 100%;
      `}
    `
);

interface PrivateLayoutProps {
  children: React.ReactNode;
  blank?: boolean;
  overflowMain?: boolean;
}

export const PrivateLayout = ({
  children,
  blank = false,
  overflowMain,
}: PrivateLayoutProps) => {
  window.localStorage.removeItem("is_expanded"); // TODO: remove this line after 20223-01-01
  window.localStorage.removeItem("plans_accordion"); // TODO: remove this line after 20223-01-01
  window.localStorage.removeItem("member_pane_accordion"); // TODO: remove this line after 20223-01-01
  window.localStorage.removeItem("create_plan_accordion"); // TODO: remove this line after 20223-01-01
  window.localStorage.removeItem("storybookTest"); // TODO: remove this line after 20223-01-01

  const client = useApolloClient();
  const { appId } = useParams<{ appId: string }>();
  const prevAppId = usePrevious(appId);
  const history = useHistory();

  const { currentUser } = useUsersContext();
  const { error } = useAppDataContext();

  const { createToastAlert } = useToastAlertContext();

  const firstAppId = currentUser?.appConnections?.[0]?.app?.id;

  useEffect(() => {
    // @ts-ignore
    if (window.google) window.google?.accounts?.id?.cancel();
  }, []);

  /**
   * THIS LINE IS IMPORTANT
   *
   * TLDR: This will reset the cache whenever the App changes. This is
   * needed because of a caching issue with `currentApp`. Without it, if the
   * user changes from App1 to App2, then the Apollo will erroneously
   * retrieve the data for App1 rather than making an HTTP request to get
   * App2.
   *
   * NOTE: THERE ARE PROBABLY BETTER SOLUTIONS THAN THIS
   * https://stackoverflow.com/questions/65778693/apollo-client-erroneously-reads-from-cache-when-it-should-make-network-request
   * https://spectrum.chat/apollo/apollo-client/apollo-client-reads-from-cache-when-it-should-make-network-request~dcf7d067-19d9-49f5-9107-57e93c07fb8c
   */
  useEffect(() => {
    if (!prevAppId) return;
    if (prevAppId !== appId && currentUser) {
      logInDevelopmentEnvironment("🚨 app id was changed");
      client.resetStore();
    }
  }, [appId, client, prevAppId, currentUser]);

  useEffect(() => {
    if (!currentUser) return null;

    window.CommandBar?.boot(
      currentUser.id,
      {},
      { hmac: currentUser.auth?.commandBarHMAC }
    );

    return () => window.CommandBar?.shutdown();
  }, [currentUser]);

  const isAppNotFoundError =
    error?.graphQLErrors[0]?.extensions?.code === "validation/app-not-found";

  const hasAppValidationError =
    error?.graphQLErrors[0]?.extensions?.code ===
    "validation/user-2fa-required-to-access-app";

  // if app not found, redirect to apps page
  useEffect(() => {
    if (isAppNotFoundError) {
      history.push(`/apps/${firstAppId}/dashboard`);
      createToastAlert({
        alertType: "error",
        message: "App does not exist: You were redirected to a valid app.",
      });
    }
  }, [firstAppId, history, isAppNotFoundError]);

  return (
    <>
      <ContentContainer
        $blank={blank}
        className="flex flex-col w-0 flex-1 overflow-visible my-5 border-r-0"
      >
        <Main
          className="flex-1 relative z-0 focus:outline-none"
          $blank={hasAppValidationError ? true : blank}
          $overflowMain={overflowMain}
        >
          {hasAppValidationError ? <TwoFactorAuthRequired /> : children}
        </Main>
      </ContentContainer>
      <SupportWidget />
    </>
  );
};
