import styled, { css } from "styled-components";

export const ItemContainer = styled.div<{
  $hasIcon: boolean;
  $active: boolean;
  $disabled: boolean;
  $isDangerText: boolean;
  $optionsTextAlign: "left" | "right";
}>(
  ({
    $hasIcon,
    $active,
    $optionsTextAlign,
    $disabled,
    $isDangerText,
    theme: { colors },
  }) => css`
    display: block;
    padding: 8px 12px;
    color: ${$isDangerText ? colors?.errorFill : colors?.grey900};
    white-space: nowrap;
    cursor: pointer;
    background-color: ${$active && colors?.grey100};
    text-align: ${$optionsTextAlign};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
    :hover {
      background-color: ${colors?.grey050};
    }
    ${$hasIcon &&
    css`
      display: flex;
      align-items: center;
      & > svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        & > rect {
          width: 20px;
          height: 20px;
        }
      }
    `}
    ${$disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
  `
);

export const IconContainer = styled.span(
  ({ theme: { colors } }) => css`
    & > svg {
      height: 24px;
      width: 24px;
      fill: black;
      border-radius: 9999px;
      :hover {
        background-color: ${colors?.grey200};
      }
    }
  `
);

export const ItemsContainer = styled.div<{
  $isMenu: boolean;
  $itemWidth?: string;
  $hasLastChildDivider?: boolean;
}>(
  ({ $isMenu, $itemWidth, $hasLastChildDivider, theme: { colors } }) => css`
    padding: ${!$isMenu && "4px"};
    width: ${$itemWidth};
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;

    ${$hasLastChildDivider &&
    css`
      & > div:nth-last-child(2) {
        padding-bottom: 4px;
      }
      & > div:last-child {
        border-top-width: 1px;
        padding-top: 4px;
      }
    `}
    ${$isMenu &&
    css`
      & > :not([hidden]) ~ :not([hidden]) {
        border-top-width: calc(1px * calc(1 - 0));
        border-bottom-width: calc(1px * 0);
        border-radius: 0px 0px 6px 6px;
        border-color: ${colors.grey200};
      }
    `}
  `
);
