import React, { useContext, useMemo } from "react";
import useAppNavigator from "hooks/useAppNavigator";
import { NavLink } from "react-router-dom";
import {
  Home,
  Users,
  GalleryHorizontal,
  Folder,
  LayoutGrid,
  MessageSquare,
  List,
  CodeXml,
  Settings,
} from "lucide-react";
import useTranslations from "hooks/useTranslations";
import { RouteName } from "enums/routes";
import { SidebarContext } from "../context/sidebar.context";
import { NavContainer } from "../styles";

const NavItems = () => {
  const { component } = useAppNavigator();
  const { isExpanded } = useContext(SidebarContext);
  const { translate } = useTranslations();

  // a variable that reads a string and returns true if it contains the word "plan/" or "plans/"

  //   export const ItemsContainer = styled.div`

  //   /* Hide scrollbar for Chrome, Safari and Opera */
  //   ::-webkit-scrollbar {
  //     display: none;
  //   }
  // `;

  const items = useMemo(
    () => [
      {
        label: "Dashboard",
        icon: Home,
        link: RouteName.dashboard,
      },
      {
        label: translate("sideBar.members", "Members"),
        icon: Users,
        link: RouteName.members,
      },
      {
        label: translate("sideBar.plans", "Plans"),
        icon: GalleryHorizontal,
        link: "plans",
      },
      {
        label: "Gated Content",
        icon: Folder,
        link: RouteName.gatedContent,
      },
      {
        label: "Components",
        icon: LayoutGrid,
        link: RouteName.editor,
      },
      {
        label: "Community",
        icon: MessageSquare,
        link: RouteName.community,
      },
      {
        label: "Event Log",
        icon: List,
        link: RouteName.eventLog,
      },
      {
        label: translate("sideBar.devTools", "Dev Tools"),
        icon: CodeXml,
        link: RouteName.devtools,
      },
      {
        label: translate("sideBar.settings", "Settings"),
        icon: Settings,
        link: RouteName.settings,
      },
    ],
    [translate]
  );

  return (
    <div
      className="flex flex-col gap-1 overflow-x-hidden mt-4"
      style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      aria-label="sidebar"
      data-cy="sidebar-nav"
    >
      {items.map((item) => {
        return (
          <NavContainer
            key={item.label}
            isExpanded={isExpanded}
            label={item.label}
          >
            <NavLink
              data-cy={`nav-${item.label.replace(/\s+/g, "-").toLowerCase()}`}
              key={item.label}
              to={item.link}
              className={({ isActive }) =>
                `flex items-center p-2 text-body-sm rounded-lg whitespace-nowrap group hover:text-black hover:bg-app-gray50 focus:outline-none focus:text-black  group-[.active]:font-bold font-normal  ${isExpanded ? "justify-start" : "justify-center"} ${isActive ? "active bg-app-blue50 focus:bg-app-blue50 hover:bg-app-blue50 font-bold text-black" : ""}`
              }
            >
              <div className="flex justify-center p-1 rounded-sm group-[.active]:bg-white group-[.active]:shadow-sm group-[.justify-start]:mr-2">
                <item.icon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 text-app-gray600 group-hover:text-black group-focus:text-black group-[.active]:text-app-blue400"
                />
              </div>
              {isExpanded && item.label}
            </NavLink>
          </NavContainer>
        );
      })}
      {component}
    </div>
  );
};

export default NavItems;
