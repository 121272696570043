import React, { useEffect, useMemo, useRef, useState } from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import { Background, Button, Card, MarkdownEditor } from "components";
import { useAppDataContext } from "routes/AppDataContextProvider";
import { useUpdateEmailsMutation } from "features";
import { useToastAlertContext } from "components/toastAlert";
import { isError } from "helpers/typeguards";
import { useGetEmailsQuery } from "features/emails";
import { useGetBrandingQuery } from "features/branding";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import {
  CONTENT_PLACEHOLDER,
  INITIAL_PASSWORD_RESET_EMAIL_STATE,
  useHandleOnChange,
} from "../emails.utils";
import * as S from "../emails.styles";
import { EmailTemplateLoading } from "../emails.loading";

export const PasswordResetEmail = () => {
  const { appData, isLoading } = useAppDataContext();
  const [formValues, setFormValues] = useState<{ [key: string]: string }>(
    INITIAL_PASSWORD_RESET_EMAIL_STATE
  );
  const valRef = useRef(null);

  const { createToastAlert } = useToastAlertContext();

  const [updateEmails, { loading }] = useUpdateEmailsMutation();

  const { data: emails, loading: isFetching, refetch } = useGetEmailsQuery();
  const { data: branding, loading: loadingBranding } = useGetBrandingQuery();

  const brandColor = branding?.getBranding?.colors?.light.primaryButton;

  const passwordReset = useMemo(
    () =>
      getObjectWithKeys(
        emails?.getEmails?.passwordReset || {},
        Object.keys(INITIAL_PASSWORD_RESET_EMAIL_STATE)
      ),
    [emails?.getEmails?.passwordReset]
  );

  useEffect(() => {
    valRef.current = passwordReset;
    setFormValues({
      replyTo: valRef.current?.replyTo || "",
      subject: valRef.current?.subject || "",
      paragraph1: valRef.current?.paragraph1 || "",
      paragraph2: valRef.current?.paragraph2 || "",
    });
  }, [passwordReset]);

  const { handleOnChange } = useHandleOnChange({ setFormValues, formValues });

  const onSubmit = async (e) => {
    createToastAlert({ processing: true });
    e.preventDefault();
    try {
      await updateEmails({
        variables: {
          input: {
            passwordReset: { ...formValues },
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message:
          "Successfully updated content of the email password reset email",
      });
    } catch (error) {
      if (isError(error)) {
        createToastAlert({
          alertType: "error",
          message: error.message,
        });
      }
    } finally {
      await refetch();
    }
  };

  if (isFetching || loadingBranding || isLoading) {
    return <EmailTemplateLoading title="Edit Password Reset Email" />;
  }

  const didFormChange = !isObjectEqual(
    removeEmpty(valRef.current || {}),
    removeEmpty(trimObjectStringValues(formValues))
  );

  return (
    <Card className="flex flex-row" hideBorder>
      <SettingsBar>
        <TitleSection
          title="Edit Password Reset Email"
          backLink="/settings/emails"
          backLinkTitle="Emails"
          emphasize
          gutter="medium"
          buttons={
            <Button
              text="Save"
              onClick={(e) => onSubmit(e)}
              isLoading={loading}
              isDisabled={!didFormChange}
            />
          }
        />
        <form className="p-5" onSubmit={(e) => onSubmit(e)}>
          <Input
            name="replyTo"
            placeholder="email@somemail.com"
            value={formValues.replyTo}
            label="Reply-To-Email"
            infoHintText="Email address"
            tw="pb-5"
            description="Enter your customer support address."
            onChange={({ target: { value } }) =>
              handleOnChange({ type: "replyTo", value })
            }
          />
          <Input
            name="subject"
            placeholder="Your reset code is"
            value={formValues.subject}
            label="Subject"
            infoHintText="Subject of the email"
            tw="pb-5"
            hideIndicators
            description="Customize the email subject."
            onChange={({ target: { value } }) =>
              handleOnChange({ type: "subject", value })
            }
          />
          <MarkdownEditor
            label="Content"
            name="paragraph1"
            height={200}
            value={formValues.paragraph1}
            placeholder={CONTENT_PLACEHOLDER}
            description="Customize the body of your email. Markdown supported."
            onChange={(value) => handleOnChange({ type: "paragraph1", value })}
          />
        </form>
      </SettingsBar>
      <Background>
        <div className="p-5 max-h-full min-h-0 overflow-auto">
          <S.UploadLogoText />
          <S.ShowcaseCard>
            {appData?.image && (
              <div tw="pb-[30px]">
                <img
                  src={appData?.image}
                  height={43}
                  tw="h-[43px]"
                  alt={appData?.name}
                />
              </div>
            )}
            <div
              className="text-app-blue400 pb-[30px] text-[38px] leading-[48px]"
              style={{ color: brandColor }}
            >
              473927
            </div>
            <MarkdownEditor
              readonly
              value={formValues.paragraph1 || CONTENT_PLACEHOLDER}
            />
          </S.ShowcaseCard>
        </div>
      </Background>
    </Card>
  );
};
