import styled, { css } from "styled-components";

const text = {
  default: "white",
  skeleton: "black",
  transparent: "primary",
  danger: "white",
  inverted: "white",
};

const background = {
  default: "primary400",
  skeleton: "white",
  transparent: "transparent",
  danger: "red700",
  inverted: "black",
  icon: "transparent",
};

const backgroundState = {
  default: "primary700",
  skeleton: "grey050",
  transparent: "transparent",
  danger: "red800",
  inverted: "grey800",
  icon: "transparent",
};

interface ButtonsProps {
  variant:
    | "default"
    | "transparent"
    | "skeleton"
    | "danger"
    | "inverted"
    | "icon";
  disabled: boolean;
  noPadding: boolean;
  noOutline: boolean;
  isPulsing: boolean;
  isLoading: boolean;
  hideFocusedOutline: boolean;
}

const outlineValue = (variant: string, noOutline: boolean, color) => {
  if (noOutline || variant !== "skeleton") {
    return "none";
  }
  return `1px solid ${color}`;
};

export const Button = styled.button<ButtonsProps>(
  () =>
    ({
      theme: { colors = {} },
      variant,
      noOutline,
      isPulsing,
      noPadding,
      isLoading,
      hideFocusedOutline,
    }) =>
      css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border-radius: 4px;
        padding: ${variant === "transparent" || noPadding ? "0px" : "8px"};
        outline ${outlineValue(variant, noOutline, colors.grey200)};
        background-color: ${colors[background[variant]]};
        color: ${colors[text[variant]]};
        ${
          isPulsing &&
          css`
            animation: tourDot 2s infinite;

            @keyframes tourDot {
              0% {
                box-shadow: 0 0 0 0px rgba(41, 98, 255, 0.8);
              }
              100% {
                box-shadow: 0 0 0 25px rgba(41, 98, 255, 0);
              }
            }
          `
        }

        &:focus {
          background-color: ${colors[backgroundState[variant]]};
          outline-width: ${
            variant === "transparent" || hideFocusedOutline ? "0px" : "4px"
          };
          outline-style: ${
            variant === "transparent" || hideFocusedOutline ? "none" : "solid"
          };
          outline-color: ${
            ((variant === "transparent" || hideFocusedOutline) && "unset") ||
            (variant === "danger" && colors.red100) ||
            colors.blue100
          };
          border-color: transparent;
        }

        &:hover:not(:disabled) {
          color: ${
            (variant === "transparent" && colors.primary500) ||
            ((variant === "skeleton" || variant === "icon") && colors.black) ||
            colors.white
          };
          background-color: ${colors[backgroundState[variant]]};
          ${
            variant === "icon" &&
            css`
              outline-width: 2px;
              outline-style: solid;
              outline-color: ${colors.blue100};
            `
          }
        }

        &:disabled {
          cursor: default;
          opacity: 0.5;
        }

        & > span {
          color: ${colors[text[variant]]};
          transition: all 0.2s ease;
        }

        ${
          isLoading &&
          css`
            ::after {
              content: "";
              position: absolute;
              width: 16px;
              height: 16px;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              border: 4px solid transparent;
              border-top-color: ${colors[text[variant]]};
              border-right-color: ${colors[text[variant]]};
              border-radius: 50%;
              animation: spin 0.75s ease infinite;
            }
            @keyframes spin {
              from {
                transform: rotate(0turn);
              }
              to {
                transform: rotate(1turn);
              }
            }
            & > span {
              visibility: hidden;
              opacity: 0;
            }
            & > svg {
              visibility: hidden;
            }
          `
        }

        & > svg {
          height: 16px;
          width: 16px;
        }
      `
);
