import React from "react";
import { useTabsContext } from "./Tabs.context";

interface TabsProps {
  children: React.ReactNode;
}

const TabNav = ({ children }: TabsProps) => {
  const { showBottomBorder } = useTabsContext();

  return (
    <div aria-label="tabs">
      <div className="hidden sm:block">
        <div
          className={
            showBottomBorder
              ? "border-b border-app-gray200"
              : "border-app-gray200"
          }
        >
          <nav className="-mb-px flex">{children}</nav>
        </div>
      </div>
    </div>
  );
};

export default TabNav;
